<template>
    <div class="flex-rows space-between">
        <h2>Bookmarks</h2>
    </div>
    <section class="page-section margin-top">
        <section class="box frame grid-5-12">
            <accordion options="panels" size="medium">
                <accordion-panel icon="list" title="Company Search" isActive="true">
                    <section>
                        <h4>Filters:</h4>
                        <ul class="list-tags no-icon">
                            <li><tag type="filter" class="applied">Company</tag></li>
                            <li><tag type="filter" class="applied">Marketing</tag></li>
                            <li><tag type="filter" class="applied">Sell Interest</tag></li>
                            <li class="margin-left-min"><btn type="icon" icon="external-link-alt"></btn></li>
                        </ul>
                    </section>
                    <section class="margin-top-small">
                        <div class="flex-rows no-responsive margin-bottom-min">
                            <div class="menu-buttons">
                                <btn type="text">Select All</btn>
                            </div>
                            <div class="menu-buttons place-right">
                                <btn type="icon" icon="trash"></btn>
                                <btn type="icon" icon="sort-alt"></btn>
                            </div>
                        </div>
                        <ul class="list-blocks select">
                            <li class="list-item">
                                <div class="checkbox">
                                    <input type="checkbox" id="uniqueID02" name="uniqueID02">
                                    <label for="uniqueID02"></label>
                                    <span class="label" title="S&amp;P Global">S&amp;P Global</span>
                                    <span class="count">Company</span>
                                </div>
                            </li>
                            <li class="list-item">
                                <div class="checkbox">
                                    <input type="checkbox" id="uniqueID01" name="uniqueID01">
                                    <label for="uniqueID01"></label>
                                    <span class="label" title="">BNC Test 12</span>
                                    <span class="count">Company</span>
                                </div>
                            </li>
                            <li class="list-item">
                                <div class="checkbox">
                                    <input type="checkbox" id="uniqueID03" name="uniqueID03">
                                    <label for="uniqueID03"></label>
                                    <span class="label" title="">Ogilvy &amp; Mather</span>
                                    <span class="count">Company</span>
                                </div>
                            </li>
                        </ul>
                    </section>
                </accordion-panel>
                <accordion-panel icon="list" title="Another Cart Name">
                    Content here
                </accordion-panel>
                <accordion-panel icon="list" title="Third Cart name">
                    Content here
                </accordion-panel>
            </accordion>
        </section>
    </section>
</template>

<script>
    //import Modal from '../modals/Modal.vue'
    import Tag from '../tags/Tag.vue'
    import Accordion from '../accordions/Accordion.vue'
    import AccordionPanel from '../accordions/AccordionPanel.vue'


    export default {
        name: 'Guide',
        components: {
            Tag, Accordion, AccordionPanel
        },
        props: {

        },
        data: function () {
            return {
                modalOpen: false
            }
        },
        computed: {
            
        },
        methods: {
            
        },
        created() {
        }
    }
</script>