<template>
    <label v-if="label">{{ label }}:</label>
    <auto-complete v-model="model"
                   class="fullwidth"
                   :placeholder="placeholder ? placeholder : label"
                   :field="acField"
                   :suggestions="suggestedTerms"
                   :multiple="multiple"
                   :forceSelection="forceSelection"
                   :canTextSelect="canTextSelect"
                   :appendTo="appendTo"
                   @complete="search"
                   @textselect="onTextSelect"
                   @item-select="onItemSelect"
                   :disabled="disabled"
                   :inputClass="$attrs.class" 
                   :ref="(el)=>setItemRef(el,'autoCompleteInput')" 
                   />
    <div tabindex="0" :ref="(el)=>setItemRef(el,'loseFocus')" style="width: 1px; height:1px" />
</template>

<script>
    import { ref, computed, onMounted, onBeforeUpdate } from 'vue'
    import { useStore } from 'vuex'
    import { useToast, useItemRefs } from '../../composables/ModelWrapper.js'

    import AutoComplete from '../PrimeVue/AutoComplete.vue'
   
    export default {
        name: 'TermPicker',
        components: { AutoComplete },
        emits: ['update:modelValue', 'item-select'],
        props: {
            modelValue: { type: Array },
            termKey: { type: String },
            label: { type: String },
            placeholder: { type: String },
            inpopup: { type: Boolean, default: false },
            multiple: { type: Boolean, default: true },
            disabled: { type: Boolean, default: false },
            forceSelection: { type: Boolean, default: false },
            canTextSelect: { type: Boolean, default: true },
            textFieldName: { type: String, default: null },
            valueFieldName: { type: String, default: null }
        },
        setup(props, { emit }) {
            // tools
            const store = useStore()
            const toast = useToast()
            const { itemRefs, setItemRef, itemRefsReset } = useItemRefs()
            onBeforeUpdate(() => {
                itemRefsReset()
            })

            // model
            const model = computed({
                get: () => (props.multiple ? (props.modelValue ? props.modelValue : []) : (props.modelValue ? props.modelValue[0] : null)),
                set: (newModel) => {
                    if (props.multiple)
                        emit('update:modelValue', newModel)
                    else
                        emit('update:modelValue', [newModel])
                }
            })

            // data
            const suggestedTerms = ref([])
            const typedWord = ref(null)

            // computed
            const appendTo = computed(() => props.inpopup ? "self" : "body")
            const acField = computed(() => props.canTextSelect ? null : props.textFieldName)

            // methods
            const search = (event) => {
                if (event.query.length == 0 || !props.termKey) {
                    suggestedTerms.value = []
                    return
                }
                else {
                        typedWord.value = event.query.toLowerCase()
                        store.dispatch('search/getTermWords', { termType: props.termKey, word: typedWord.value })
                            .then((suggestions) => {
                                if (props.canTextSelect) {
                                    suggestedTerms.value = [event.query]
                                    if (suggestions) {
                                        suggestions.forEach((term) => {
                                            if (term && term[props.textFieldName] && !suggestedTerms.value.includes(term[props.textFieldName]))
                                                suggestedTerms.value.push(term[props.textFieldName])
                                        })
                                    }
                                }
                                else {
                                    suggestedTerms.value = suggestions
                                }
                                console.log('termpicker', suggestions, suggestedTerms.value, suggestedTerms.value.length, props.textFieldName)
                            }).catch(error => {
                                toast.add({ severity: 'error', summary: 'Error searching ' + props.termKey, detail: error, life: 3000 })
                                suggestedTerms.value = []
                            })
                }
            }
            const onItemSelect = (ev) => {
                console.log('onItemSelect')
                //this.hideOverlay(true)
                emit('item-select', ev)
                suggestedTerms.value = []
            }
            const onTextSelect = (term) => {
                console.log('onTextSelect')
                suggestedTerms.value = []
                hideOverlay(true)
                if (term) {
                    var newModel = model.value
                    if (props.multiple) {
                        newModel.push(term)
                    }
                    else {
                        newModel = term
                    }
                    model.value = newModel
                    emit('item-select', model.value)
                }
            }
            const focus = () => {
                if (itemRefs.value && itemRefs.value.autoCompleteInput)
                    itemRefs.value.autoCompleteInput.focus()
            }
            const hideOverlay = (firstTime) => {
                if (itemRefs.value && itemRefs.value.autoCompleteInput) {
                    console.log('hideOverlay', firstTime, itemRefs.value.autoCompleteInput.searching, itemRefs.value.autoCompleteInput.overlayVisible)
                    if (firstTime && itemRefs.value.autoCompleteInput.searching || itemRefs.value.autoCompleteInput.overlayVisible) {
                        itemRefs.value.autoCompleteInput.hideOverlay()
                        itemRefs.value.autoCompleteInput.blur()

                        if (itemRefs.value.loseFocus) {
                            itemRefs.value.loseFocus.setAttribute('tabindex', '-1');
                            itemRefs.value.loseFocus.focus()
                        }
                        setTimeout(() => () => { hideOverlay(false) }, 500)
                    }
                }
            }

            //lifecycle
            onMounted(() => {
                suggestedTerms.value = []
                typedWord.value = null
            })

            return {
                // tools
                itemRefs, setItemRef, itemRefsReset,
                // model
                model,
                // data
                suggestedTerms, typedWord,
                // computed
                appendTo, acField,
                // methods
                search, onItemSelect, onTextSelect, focus, hideOverlay
            }
        }
    }
</script>

<style scoped lang="scss">
    
</style>
