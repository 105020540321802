<template>
    <dropdown v-model="dropdownState" icon="calendar" :label="selectedDateRangeTitle" :direction="direction">
        <template #body>
            <menu-vertical options="panel options medium">
                <menu-item label="Date Ranges" :initIsOpen="true">
                    <template #children>
                        <menu-item v-for="dr in dateRangesPlus" :key="dr.key" :label="dr.description" @click="selectDateRange(dr.key)" :icon="dr.key == selectedKey ? 'check' : ''" />
                    </template>
                </menu-item>
            </menu-vertical>
        </template>
    </dropdown>
    <modal style="z-index: 9999999 !important" v-model="modalOpen" title="Custom Range" :actionbuttons="true" @save="saveCustom" @cancel="cancelCustom">
        <template #body>
            <Calendar style="height:425px; display:block;" v-model="customDateRange" selectionMode="range" :inline="true" :numberOfMonths="2" :yearNavigator="false" @date-select="customDateSelected" />
        </template>
    </modal>
</template>

<script>
    import { ref, computed, onMounted } from 'vue'
    import { useModelWrapper, useToast } from '../../composables/ModelWrapper.js'
    import { getDateRangeTools } from '../../composables/DateRangeTools.js'
    import { getTextTools } from '../../composables/TextTools.js'
    import { getMqTools } from '../../composables/MqTools.js'

    import MenuVertical from "../ui/menus/MenuVertical.vue"
    import MenuItem from "../ui/menus/MenuItem.vue"
    import Calendar from 'primevue/calendar/sfc'

    export default {
        name: 'DateRangePicker',
        components: {
            MenuVertical, MenuItem, Calendar
        },
        emits: ['update:modelValue','selected'],
        props: {
            modelValue: { type: Object },
            direction: { type: String, default: 'right' },
        },
        setup(props, { emit }) {
            // tools
            const toast = useToast()
            const { dateRangeMap, dateRangeKeys, dateRanges, encodeDateRange, decodeDateRange, defaultDateRange } = getDateRangeTools()
            const { toShortDateString } = getTextTools()
            const { isMobile } = getMqTools()

            // model
            const model = useModelWrapper(props, emit, 'modelValue', (newVal) => {
                if (newVal && newVal.key && !newVal.description) {
                    var newModel = decodeDateRange(newVal.key)
                    if (newModel)
                        emit('update:modelValue', newModel)
                }
            }, true)

            // data
            const dropdownState = ref('closed')
            const modalOpen = ref(false)
            const customDateRange = ref(null)

            // computed
            const dateRangesPlus = computed(() => {
                var drs = dateRanges.value
                drs.push({ key: 'custom', description: "Custom Range..." })
                return drs
            })
            const selectedKey = computed(() => model.value ? model.value.key : null)
            const selectedDateRangeTitle = computed(() => isMobile.value ? '' : (model.value ? model.value.description : 'All Time'))
            const customDateRangeTitle = computed(() => {
                var drTitle = selectedDateRangeTitle.value
                if (customDateRange.value) {
                    drTitle = toShortDateString(customDateRange.value[0])
                    if (customDateRange.value[1])
                        drTitle += ' to ' + toShortDateString(customDateRange.value[1])
                }
                return drTitle
            })

            // methods
            const selectDateRange = (key) => {
                if (key == 'custom') {
                    modalOpen.value = true
                    dropdownState.value = 'closed'
                }
                else if (key != selectedKey.value) {
                    model.value = (dateRangeMap.value[key] ? dateRangeMap.value[key] : defaultDateRange)
                    dropdownState.value = 'closed'
                    emit('selected', model.value.key)
                }
            }
            const saveCustom = () => {
                if (customDateRange.value) {
                    var dr = encodeDateRange({ startDate: customDateRange.value[0], endDate: customDateRange.value[1] })
                    console.log('saveCustom', customDateRange.value, dr)
                    if (dr) {
                        model.value = dr
                        emit('selected', model.value.key)
                        modalOpen.value = false
                    }
                    else {
                        toast.add({ severity: 'error', summary: 'Error saving custom date range', life: 5000 })
                    }
                }
                dropdownState.value = 'closed'
            }
            const cancelCustom = () => {
                dropdownState.value = 'closed'
            }
            const customDateSelected = (event) => {
                console.log(event)
            }

            // lifecycle
            onMounted(() => {
                if (!props.modelValue) {
                    model.value = defaultDateRange
                }
                dropdownState.value = 'closed'
                modalOpen.value = false
                customDateRange.value = [defaultDateRange.startDate.toJSDate(), defaultDateRange.endDate.toJSDate()]//null
            })

            return {
                // model
                model, defaultDateRange,
                // data
                dropdownState, modalOpen, customDateRange,
                // computed 
                dateRangeMap, dateRangeKeys, dateRanges, dateRangesPlus,
                selectedKey, selectedDateRangeTitle, customDateRangeTitle,
                // methods
                encodeDateRange, decodeDateRange, toShortDateString, selectDateRange, saveCustom,
                cancelCustom, customDateSelected
            }
        }
    }
</script>

<style lang="scss">
</style>
