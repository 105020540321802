<template>
        <record-head :obj="model"
                     :cardDisplayType="cardDisplayType"
                     :card="card"
                     :listIndex="listIndex"
                     :parentContext="parentContext"
                     :recordContext="recordContext"
                     @detailrequest="$emit('detailrequest', $event)"
                     @toggle-flag="handleToggleFlag" />
        <record-body v-if="cardDisplayType == 4 || cardDisplayType == 2"
                     v-model="model"
                     v-model:editing="isEditing"
                     v-model:dirty="isDirty"
                     v-model:selTab="selectedTab"
                     :cardDisplayType="cardDisplayType"
                     :editorID="editorID"
                     :parentContext="parentContext"
                     :recordContext="recordContext"
                     @editrequest="$emit('editrequest', $event)"
                     @filterrequest="$emit('filterrequest', $event)"
                     @editordone="$emit('editordone', $event)"
                     ref="recBody" />
</template>

<script>
    import RecordHead from '../Records/RecordHead.vue'
    import RecordBody from '../Records/RecordBody.vue'
    import { computed, provide } from 'vue'
    import { getRecordTools } from '../../composables/RecordTools.js'
    import { useModelWrapper } from '../../composables/ModelWrapper.js'

    export default {
        name: 'Record',
        components: {
            RecordHead,
            RecordBody
        },
        props: {
            'modelValue': { type: Object },
            'card': { type: Object, default: null }, /* this is used for a pin card */
            'cardDisplayType': { type: Number },
            'listIndex': { type: Number },
            'parentContext': { type: String, default: '' },
            'recordContext': { type: String, default: 'details' },
            'highlightText': { type: Array },
            'editing': { type: Object },
            'dirty': { type: Object },
            'editorID': { type: String },
            'selTab': { type: String, default: '' }
        },
        emits: ['detailrequest', 'filterrequest', 'editrequest', 'editordone', 'update:modelValue', 'update:editing'],
        setup(props, context) {

            //data
            const model = computed({
                get: () => {
                    return props.modelValue ? props.modelValue : { id: 0, objectType: null, objectKey: null }
                },
                set(newVal) {
                    context.emit('update:modelValue', newVal)
                }
            })

            const isEditing = useModelWrapper(props, context.emit, 'editing')
            const isDirty = useModelWrapper(props, context.emit, 'dirty')
            const selectedTab = useModelWrapper(props, context.emit, 'selTab')

            // const 
            const { recordContexts } = getRecordTools(props, 'modelValue')

            // methods
            const handleToggleFlag = (ev) => { console.log(ev) }

            provide('highlightText', computed(() => props.highlightText))

            return {
                model, isEditing, isDirty,
                recordContexts, selectedTab,
                // methods
                handleToggleFlag
            }
        },
        methods: {
            editorHasUnsavedChanges() {
                return this.$refs.recBody.editorHasUnsavedChanges();
            },
            openEditMode(obj) {
                this.$refs.recBody.openEditMode(obj)
            },
            toggleView() {
                this.$refs.recBody.toggleView()
            },
            saveEdits() {
                this.$refs.recBody.saveEdits()
            },
            cancelEdits() {
                this.$refs.recBody.cancelEdits()
            }
        }
    }
</script>

<style scoped lang="scss">
</style>