<template>
    <table-row v-model="isExpanded" :expansion="true" class="lob" :class="[{'is-editing': isEditing}]">
        <table-cell title="Details" :class="{ 'inactive-lob': !model.active }">
            {{ lobDescription }}
            <span v-if="model.primaryLOB" class="badge-circle primary">P</span>
            <span v-if="model.verified" class="badge-circle success"><i class="fas fa-check"></i></span>
            <template v-else>
                <span v-if="model.important" class="badge-circle tertiary">i</span>
            </template>
            <small>
                <i v-show="model.dirty" class="fa fa-asterisk"></i>
                <i v-show="isEditing" class="fa fa-edit hide"></i>
            </small>
            <span class="place-right">
                <template v-if="!disabled && isEditing">
                    <btn @click.stop="showDeleteModal = true" type="icon-box" size="small"><i class="far fa-trash-alt"></i></btn>
                </template>
                <span v-if="model.error" class="error-message">{{ model.error }}</span>
            </span>
        </table-cell>
        <table-cell title="Actions" :show="true">
            <btn type="icon-box"><i class="fas fa-chevron-down"></i></btn>
        </table-cell>
        <template #expansion>
            <div class="flex-columns two fullwidth">
                <div v-for="(col, idxCol) in columns" :key="idxCol" class="column">                   
                    <div v-for="lobDim in col" :key="lobDim" :class="colorClass" class="dimension-container">
                        <div class="lob-field">
                            <lob-picker v-model="pickerModel[lobDim]"
                                        :treeName="lobDim"
                                        :inpopup="inpopup"
                                        @change="lobDimChanged($event, lobDim)"
                                        @focus="setEditing"
                                        :disabled="!model.active || disabled" />
                        </div>
                    </div>
                </div>
            </div>
            <ul class="list-table columns margin-top-small">
                <li class="border-right" :class="checkboxClass">
                    <span class="label">Primary:</span>
                    <span class="l">
                        <checkbox v-model="model.primaryLOB" :disabled="!model.active || disabled" @focus="setEditing" />
                    </span>
                </li>
                <li class="no-border-top" :class="checkboxClass">
                    <span class="label">Verified:</span>
                    <span class="">
                        <checkbox v-model="model.verified" :disabled="!model.active || disabled" @focus="setEditing" />
                    </span>
                </li>
                <template v-if="advanced">
                    <li class="border-right" :class="checkboxClass">
                        <span class="label">Important:</span>
                        <span class="">
                            <checkbox v-model="model.important" :disabled="!model.active || disabled" @focus="setEditing" />
                        </span>
                    </li>
                    <li :class="checkboxClass">
                        <span class="label">Active:</span>
                        <span class="">
                            <checkbox v-model="model.active" :disabled="model.primaryLOB || disabled" @focus="setEditing" />
                        </span>
                    </li>
                    <li class="border-right" :class="checkboxClass">
                        <span class="label">Entry Date:</span>
                        <span class="">
                            {{ toShortDateString(model.entryDate) }} by {{ model.entryUserName }}
                        </span>
                    </li>
                    <li v-show="!(model.changeDate == '0001-01-01T00:00:00' ||  model.entryDate == model.changeDate)" :class="checkboxClass">
                        <span class="label">Change Date:</span>
                        <span class="">
                            {{ toShortDateString(model.changeDate) }} by {{ model.changeUserName }}
                        </span>
                    </li>
                </template>
            </ul>
        </template>
    </table-row>
    <modal ref="deleteModal" v-model="showDeleteModal" @save="deleteLOB" :title="'Delete LOB? ['+ lobDescription +']'" :actionbuttons="true" saveTitle="Delete"></modal>
</template>
<script>
    import { inject, computed } from 'vue'
    import { useToast, useModelWrapper } from '../../composables/ModelWrapper.js'
    import { useStore } from 'vuex'

    import LobPicker from './LobPicker.vue'
    import Checkbox from '../ui/forms/Checkbox.vue'
    import TableCell from '../ui/tables/TableCell.vue'
    import TableRow from '../ui/tables/TableRow.vue'

    export default {
        name: 'LobEditor',
        components: {
            LobPicker,
            Checkbox,
            TableCell,
            TableRow
        },
        props: {
            modelValue: { type: Object },
            inpopup: { type: Boolean, default: false },
            advanced: { type: Boolean, default: false },
            expanded: { type: Boolean, default: false },
            editing: { type: Boolean },
            disabled: { type: Boolean, default: false }
        },
        emits: ['delete', 'primarychange', 'update:modelValue', 'update:expanded', 'update:editing'],
        data: function () {
            return {
                showDeleteModal: false
            }
        },
        setup(props, { emit }) {
            var toast = useToast()
            const columns = [['audience', 'industry'], ['subject', 'attribute']]
            const store = useStore()
            const primaryChanging = inject('primaryChanging')
            const model = useModelWrapper(props, emit, 'modelValue')
            const isExpanded = useModelWrapper(props, emit, 'expanded', (newVal) => {
                if (newVal) {
                    isEditing.value = true
                }
            })
            const isEditing = useModelWrapper(props, emit, 'editing')
            
            // computed
            const NoSubjectID = computed(() => store.getters['ais/NoSubjectID'])
            const NoAttributeID = computed(() => store.getters['ais/NoAttributeID'])
            const treeNames = computed(() => store.getters['ais/treeNames'])

            return {
                // tools
                primaryChanging, columns, toast,
                // models
                model, isExpanded, isEditing,
                // computed
                NoSubjectID, NoAttributeID, treeNames
            }
        },
      
        computed: {
            pickerModel: {
                get() {
                    var pModel = {}
                    this.treeNames.forEach((dim) => {
                        var dimName = this.model[dim].indexOf(':') > -1 ? this.model[dim].substr(this.model[dim].indexOf(':') + 1) : this.model[dim]
                        pModel[dim] = [{ id: this.model[dim + 'ID'], name: dimName }]
                    })
                    return pModel
                },
                set(pModel) {
                    this.treeNames.forEach((dim) => {
                        if (pModel[dim] && pModel[dim].length > 0) {
                            this.model[dim] = pModel[dim][0].name
                            this.model[dim + 'ID'] = pModel[dim][0].id
                        }
                    })
                }
            },
            lobDescription() {
                if (this.model) {
                    if (this.model.description)
                        return this.model.description
                    else if (!!this.model.industryID && !!this.model.audienceID)
                        return (this.model.attributeID != this.NoAttributeID ? this.shortNodeName(this.model.attribute) + ' ' : '') +
                            (this.model.subjectID != this.NoSubjectID ? this.shortNodeName(this.model.subject) + ' ' : '') +
                            this.shortNodeName(this.model.industry) + ' for ' + this.shortNodeName(this.model.audience)
                }
                return null
            },
            colorClass() {
                var colorclass = ""
                if (this.model) {
                    if (!this.model.active) {
                        colorclass = "table-danger"
                    }
                    else if (this.model.verified && this.model.important) {
                        colorclass = "table-success"
                    }
                    else if (!this.model.verified) {
                        colorclass = "table-warning"
                    }
                    else if (!this.model.important) {
                        colorclass = "table-info"
                    }
                }
                return colorclass
            },
            checkboxClass() {
                return this.advanced ? 'grid-6-12' : 'grid-6-12'
            },
            dirtyProps() {
                return {
                    industryID: this.model.industryID, audienceID: this.model.audienceID, subjectID: this.model.subjectID, attributeID: this.model.attributeID,
                    primaryLOB: this.model.primaryLOB, active: this.model.active, important: this.model.important, verified: this.model.verified
                }
            }
        },
        methods: {
            deleteLOB() {
                if (this.model.primaryLOB) {
                    this.showDeleteModal = false
                    this.isEditing = true
                    this.toast.add({ severity: 'error', summary: 'Cannot delete primary LOB', detail: '', life: 3000 })
                }
                else {
                    this.model.delete = true
                    this.model.dirty = true
                    this.$emit('delete', this.model)
                    this.isEditing = true
                }
            },
            lobDimChanged(nodes, lobDim) {
                console.log('lobDimChanged', nodes, lobDim, this.model)
                this.model[lobDim] = nodes[0].name
                this.model[lobDim + 'ID'] = nodes[0].id
                this.model.description = null
                delete this.model['descentMappings']
            },
            shortNodeName(name) {
                if (!name) return name
                var colon = name.indexOf(':')
                if (colon <= 0)
                    return name
                return name.substr(colon + 1).trim()
            },
            setEditing(ev) {
                console.log(ev)
                this.isEditing = true
            }          
        },
        watch: {           
            'model.primaryLOB': {
                handler() {
                    if (!this.primaryChanging && this.isEditing) {
                        this.$emit("primarychange", this.model)
                    }
                }
            },
            "dirtyProps": {
                handler(val, oldValue) {
                    var changed = []
                    Object.keys(val).forEach((key) => {
                        if (val[key] != oldValue[key])
                            changed.push(key)
                    })

                    if (changed.length) {
                        console.log('model dirtyProps deep watch', this.model.id, changed)
                        this.model.dirty = true
                        this.$emit('validate')
                    }
                },
                deep: true
            }
        }
    }
</script>
<style lang="scss">
    .dimension-container + .dimension-container {
        margin-top: 12px;
    }

    .primary-badge {
        display: inline-block;
        width: 16px;
        height: 16px;
        padding: 0;
        border-radius: 100%;
        font-size: 10px;
        font-weight: 700;
        line-height: 16px;
        background-color: #415FFF;
        color: #FFF;
        text-align: center;
    }
    .important-badge {
        display: inline-block;
        width: 16px;
        height: 16px;
        padding: 0;
        border-radius: 100%;
        font-size: 10px;
        font-weight: 700;
        line-height: 16px;
        background-color: dodgerblue;
        color: #FFF;
        text-align: center;
    }
    .inactive-lob {
        text-decoration: line-through
    }
    .error-message{
        font-style: italic;
        color: darkred;
    }
</style>