<template>
    <header class="modal-header unselectable">
        <i v-show="icon" :class="iconClass"></i>
        <h3 class="modal-title" v-if="title">{{ title }}</h3>
        <slot name="modal-header"></slot>
    </header>
</template>

<script>
    export default {
        name: 'ModalHeader',
        props: {
            title: String,
            icon: String
        },
        components: {

        },
        data() {
            return {

            };
        },
        computed: {
            iconClass() {
                if (this.icon) {
                    return 'fas fa-' + this.icon
                } else {
                    return null;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .unselectable {
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
</style>