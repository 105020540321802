<template>
    <h3 class="panel-title">
        Origin: <span class="text-highlight">{{ seedName }}</span>
    </h3>
    <h3 class="panel-title">Enabled LOBs:</h3>
    <div class="check-list lob-container">
        <template v-for="lob in lobs" :key="lob.id">
            <checkbox v-model="lob.important" size="small" :label="lob.name" />
        </template>
    </div>
    <h3 class="panel-title margin-bottom-min">Tree Weights</h3>
    <div ref="chartRadarDiv" class="chart"></div>
    <ul class="list-options">
        <li v-for="tree in treeNames" :key="tree">
            <div class="frame-horizontal-small margin-top-min">
                <span style="font-weight:600;">{{ capitalizeFirst(tree) }}:</span> {{ trees[tree].valueName }} {{ trees[tree].coeff }}
                <div class="margin-top-min">
                    <slider v-model="trees[tree].coeff" :min="0.0" :max="1.5" :step="0.1" />
                </div>
            </div>
        </li>
    </ul>
</template>

<script>

    import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
    import _ from 'lodash'
    import { searchFilterProps, searchFilterEmits, searchFilterSetup, getSearchTools } from '../../composables/SearchTools.js'
    import { useStore } from 'vuex'

    import Checkbox from '../ui/forms/Checkbox.vue'
    import Slider from 'primevue/slider'
    import * as am5 from '@amcharts/amcharts5'
    import * as am5xy from '@amcharts/amcharts5/xy';
    import * as am5radar from '@amcharts/amcharts5/radar'
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

    _.noop(am5themes_Animated)

    export default {
        name: 'LobDistanceFilter',
        components: { Checkbox, Slider },
        props: {
            ...searchFilterProps('lobDistance'),
            seedKey: {type: Object }
        },
        emits: [...searchFilterEmits()],
        setup(props, { emit }) {
            const store = useStore()
            const { encodeOwnerKey } = getSearchTools()

            // const
            const initDims = {
                industry: { color: "red", coeff: 1.0, valueName: '', nodes: [] },
                audience: { color: "green", coeff: 1.5, valueName: '', nodes: [] },
                subject: { color: "blue", coeff: 0.5, valueName: '', nodes: [] },
                attribute: { color: "yellow", coeff: 0.1, valueName: '', nodes: [] }
            }

            // models
            const { model } = searchFilterSetup(props, emit)

            // data
            const chartRadarDiv = ref(null)
            const lobs = ref([])
            const trees = ref(initDims)

            // getters
            const treeNames = computed(() => store.getters['ais/treeNames'])
            const NoAttributeID = computed(() => store.getters['ais/NoAttributeID'])
            const NoSubjectID = computed(() => store.getters['ais/NoSubjectID'])

            // computed
            const defaultFilterVal = computed(() => {
                return {
                    seedKey: props.seedKey, seedName: seedName.value, industryCoeff: 1.0, audienceCoeff: 1.5, subjectCoeff: 0.5, attributeCoeff: 0.1, maxDistance: 1.0,
                    lobs: lobs.value, lobEnds: null
                }
            })

            const filterValue = computed({
                get: () => {
                    var val = (props.modelValue && props.modelValue.value ? JSON.parse(props.modelValue.value) : defaultFilterVal.value)
                    Object.keys(defaultFilterVal.value).forEach((prop) => {
                        if (!val[prop])
                            val[prop] = defaultFilterVal.value[prop]
                    })
                    return val
                },
                set: (val) => {
                    //console.log('filterValue-setter', val)
                    var mdl = model.value
                    mdl.value = JSON.stringify(val)
                    mdl.valueName = val.seedName
                    model.value = mdl
                }
            })

            const objectCache = computed(() => store.state.objectCache.objCache)
            const seedKeyComputed = computed(() => props.seedKey ?? (filterValue.value ? filterValue.value.seedKey : null))
            const cacheKey = computed(() => encodeOwnerKey(seedKeyComputed.value))
            const seedObject = computed(() => {
                if (cacheKey.value) {
                    if (objectCache.value[cacheKey.value])
                        return objectCache.value[cacheKey.value].obj
                    else
                        store.dispatch('objectCache/fetchObject', { objectKey: seedKeyComputed.value })
                }
                return null
            })
            const seedName = computed(() => (filterValue.value ? filterValue.value.seedName : null) ?? (seedObject.value ? seedObject.value.name : null))
            const seedLobs = computed(() => (filterValue.value ? filterValue.value.lobs : null) ??
                (seedObject.value && seedObject.value.lobs ? seedObject.value.lobs.filter((lob) => lob.active) : []))

            // chart
            var rootRadar = null
            var chartRadar = null
            var series = null
            var xAxis = null
            const radarChartData = computed(() => {
                return Object.keys(trees.value).map((tree) => {
                    return { direction: tree, value: trees.value[tree].coeff }
                })
            })

            const renderRadarChart = () => {
                //console.log('renderRadarChart', chartRadarDiv.value, chartRadar, rootRadar, radarChartData.value)
                if (chartRadar) {
                    chartRadar.dispose()
                }
                rootRadar = am5.Root.new(chartRadarDiv.value);
                // Create chart
                // https://www.amcharts.com/docs/v5/charts/radar-chart/
                chartRadar = rootRadar.container.children.push(am5radar.RadarChart.new(rootRadar, {
                    panX: false,
                    panY: false,
                    wheelX: false,
                    wheelY: false /*,
                     startAngle: -45,
                     endAngle: 315*/
                }));

                // Add cursor
                // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
                var cursor = chartRadar.set("cursor", am5radar.RadarCursor.new(rootRadar, {
                    behavior: "none"
                }));

                cursor.lineY.set("visible", true);
                cursor.lineX.set("visible", true);

                // Create axes and their renderers
                // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
                var xRenderer = am5radar.AxisRendererCircular.new(rootRadar, {});
                xRenderer.labels.template.setAll({
                    radius: 10
                });

                xAxis = chartRadar.xAxes.push(am5xy.CategoryAxis.new(rootRadar, {
                    maxDeviation: 0,
                    categoryField: "direction",
                    renderer: xRenderer
                }));

                var yAxis = chartRadar.yAxes.push(am5xy.ValueAxis.new(rootRadar, {
                    renderer: am5radar.AxisRendererRadial.new(rootRadar, {})
                }));

                // Create series
                // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series

                series = chartRadar.series.push(am5radar.RadarLineSeries.new(rootRadar, {
                    stacked: true,
                    name: "Series ",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    categoryXField: "direction",
                    stroke: am5.color(0x415fff),
                    fill: am5.color(0x415fff),
                    tooltip: am5.Tooltip.new(rootRadar, {
                        labelText: "{categoryX}: {valueY}"
                    })
                }));

                series.strokes.template.set("strokeWidth", 2);
                series.bullets.push(function () {
                    return am5.Bullet.new(rootRadar, {
                        sprite: am5.Circle.new(rootRadar, {
                            radius: 5,
                            fill: series.get("fill"),
                            strokeWidth: 2,
                            stroke: rootRadar.interfaceColors.get("background")
                        })
                    })
                })

                series.data.setAll(radarChartData.value);
                xAxis.data.setAll(radarChartData.value);

                var range1 = xAxis.createAxisRange(xAxis.makeDataItem({ category: "industry", endCategory: "industry" }));
                range1.get("axisFill").setAll({
                    visible: true,
                    fill: am5.color(0x415fff),
                    fillOpacity: 0.3
                })

                var range2 = xAxis.createAxisRange(xAxis.makeDataItem({ category: "subject", endCategory: "subject" }));
                range2.get("axisFill").setAll({
                    visible: true,
                    fill: am5.color(0x415fff),
                    fillOpacity: 0.3
                })


                chartRadar.radarContainer.children.moveValue(chartRadar.topGridContainer, 0);

                // Animate chart
                // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
                series.appear(1000);
                chartRadar.appear(1000, 100);
            }
            // Methods
            const cleanNodeName = (fullName) => {
                var name = fullName
                var i = fullName.indexOf(':')

                if (i > 0)
                    name = fullName.substr(i + 1)

                return name
            }

            //life cycle
            onMounted(() => {
                renderRadarChart()
            })

            onUnmounted(() => {
                if (chartRadar.value) {
                    chartRadar.value.dispose()
                }
            })

            const lobExcessProps = ['totalCount', 'atTag', 'cas', 'acl', 'changeDate', 'changeUserID', 'changeUserName', 'cloudDocCount', 'comments',
                'descentMappings', 'deactiveDate', 'deactiveReason', 'deactiveUserID', 'deactiveUserName', 'delete', 'detailAddress', 'dirty', 'dontValidate', 'effectiveACE',
                'entryDate', 'entrySource', 'entryUserID', 'entryUserName', 'esRelationship', 'mandAsoftStatusID', 'synchDate', 'tags', 'tagsFlat',
                'version','viewExistence']
            // watches
            watch(seedLobs, (newVal) => {
                //console.log('seedLobs-watch', newVal)
                var lobCopy = _.cloneDeep(newVal)
                lobCopy.forEach((lob) => {
                    lobExcessProps.forEach((prop) => { delete lob[prop] })
                })
                lobs.value = lobCopy
            }, { deep: true })
            watch(lobs, (newVal) => {
                if (newVal) {
                    treeNames.value.forEach((tree) => {
                        trees.value[tree].nodes = new Array()
                        trees.value[tree].valueName = ''

                        newVal.forEach((lob) => {
                            if (lob.active && lob.important) {
                                trees.value[tree].nodes.push(cleanNodeName(lob[tree]))
                            }
                        })

                        trees.value[tree].valueName = _.uniq(trees.value[tree].nodes).join()
                    })
                }
                var filterVal = filterValue.value
                filterVal.lobs = newVal
                filterValue.value = filterVal
                //console.log('lobs-watch', newVal, filterValue.value, trees.value)
            }, { deep: true })
            watch(filterValue, (newVal) => {
                if (newVal) {
                    treeNames.value.forEach((tree) => {
                        if (newVal[tree + 'Coeff'] && trees.value[tree].coeff != newVal[tree + 'Coeff'])
                            trees.value[tree].coeff = newVal[tree + 'Coeff']
                    })
                    //console.log('filterValue-watch', newVal, trees)
                }
            }, { deep: true })
            watch(trees, (newVal) => {
                if (newVal) {
                    var changed = false
                    var filterVal = filterValue.value
                    treeNames.value.forEach((tree) => {
                        if (newVal[tree].coeff != filterVal[tree + 'Coeff']) {
                            changed = true
                            filterVal[tree + 'Coeff'] = newVal[tree].coeff
                        }
                    })
                    if (changed) {
                        filterValue.value = filterVal
                        //console.log('trees-watch', newVal, filterValue)
                        series.data.setAll(radarChartData.value);
                        xAxis.data.setAll(radarChartData.value);
                    }
                }
            }, { deep: true })

            return {
                // models
                model, defaultFilterVal, filterValue, lobs,
                // data
                chartRadarDiv,
                // const
                initDims,
                // getters
                treeNames, NoSubjectID, NoAttributeID,
                // computed
                objectCache, seedKeyComputed, cacheKey, seedObject, seedName, seedLobs, trees,
                // methods
                cleanNodeName, encodeOwnerKey,
                // chart
                rootRadar, chartRadar, series, xAxis, radarChartData, renderRadarChart, 
            }

        }
    }
</script>

<style scoped lang="scss">
    .chart {
        height: 320px;
        width: 320px;
    }
</style>
