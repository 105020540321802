export default {
    data: function () {
        return {
            prodEnvironments: ["DebugProdData", "Production", "Release"]
        }
    },
    methods: {
        environment() {
            return process.env.NODE_ENV
        },
        isDebugMode() {
            return !this.prodEnvironments.includes(process.env.NODE_ENV)
        }
    }
}