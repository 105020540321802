<template>
  <ul :class="[menuClasses, size]">
    <slot />
  </ul>
</template>

<style>
</style>

<script>
    export default {
        name: 'MenuVertical',
        props: {
            options: String,
            size: String,
            makeHorizontal: {
                type: Boolean,
                default: false
            }
        },
        components: {

        },
        data() {
            return {

            }
        },
        computed: {
            menuClasses() {
                return this.makeHorizontal ? 'menu-horizontal' :'menu-vertical' + ' ' + this.options
            }
        }
    }
</script>