import { createApp } from 'vue'
import store from '../../store'
import mitt from 'mitt'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'

import FlagTool from './FlagTool.vue'
import SpinLoader from '../../components/baseComponents/SpinLoader.vue'
import Btn from '../../components/ui/base/Btn.vue'
import _ from 'lodash'


export default {
    install: (app, options) => {

        app.component("flag-tool", FlagTool)       

        const $flagBase = (opt) => {
            return new Promise((resolve, reject) => {
                if (!opt) {
                    reject('bad params')
                    return
                }
                const flagToolSfc = app.component('flag-tool')
                const mountEl = document.createElement('div')
                document.body.appendChild(mountEl)

                var multiFlag = opt.multiFlag ?? false
                var readOnly = opt.readOnly ?? false
                var modelValue = opt.modelValue ?? null
                var flagItem = _.cloneDeep(opt.flagItem) ?? null
                var flagAction = opt.flagAction ?? 'shareAction'
                var bookmarkContext = opt.bookmarkContext ?? null

                var flagToolInstance = createApp(flagToolSfc, {
                    // props
                    multiFlag,readOnly,modelValue,flagItem,flagAction,bookmarkContext,
                    // events are passed as props here with on[EventName]
                    onFlagchanged(result) {
                        resolve(result)
                        if (mountEl.parentNode) mountEl.parentNode.removeChild(mountEl)
                        if (flagToolInstance) {
                            flagToolInstance.unmount()
                            flagToolInstance = null
                        }
                    },
                    onCancelled(result) {
                        reject(result)
                        if (mountEl.parentNode) mountEl.parentNode.removeChild(mountEl)
                        if (flagToolInstance) {
                            flagToolInstance.unmount()
                            flagToolInstance = null
                        }
                    }
                })
                const emitter = mitt()
                flagToolInstance.provide('emitter', emitter)
                flagToolInstance.use(store)
                flagToolInstance.use(PrimeVue)
                flagToolInstance.use(ToastService)
                flagToolInstance.component('Toast', Toast)
                flagToolInstance.component('Btn', Btn)
                flagToolInstance.component('SpinLoader', SpinLoader)
                flagToolInstance.mount(mountEl)
            })
        }
        const $flagShare = (itemToShare) => {
            return $flagBase({ flagAction: 'shareAction', flagItem: itemToShare })
        }
       
        app.config.globalProperties.$flagBase = $flagBase
        app.config.globalProperties.$flagShare = $flagShare

        app.provide("flagBase", $flagBase)
        app.provide("flagShare", $flagShare)

        _.noop(options)
    }
}