<template>
    <div>
        <div class="box border" v-if="model">
            <div :class="'flex-rows frame-small '+ (model.sellInterest ? 'border-bottom' : '')">
                <h3 class="grow-one">
                    M&amp;A Interest:
                    <small>
                        <i v-show="model.dirty" class="fa fa-asterisk"></i>
                        <i v-show="isEditing" class="fa fa-edit"></i>
                        <span v-if="model.error" class="error-message">{{ model.error }}</span>
                    </small>
                </h3>
                <spin-loader :showMe="showSpinLoader" />
                <div class="grow-three flex-rows gutter-default " v-for="(interest, key) in visibleMandaInterest" :key="key">
                    <div class="flex-rows no-responsive">
                        <div v-if="model[key] != undefined" v-show="!interest.advanced || interest.advanced == advancedMode">
                            <checkbox v-model="model[key]" :label="interest.name"  :disabled="disabled" @click="isEditing = true"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="model.sellInterest" class="flex-rows frame-small timing-container">
                <h3 class="grow-one">Timing:</h3>
                <div class="grow-three form-group">
                    <select name="mandaTiming" v-model="model.timingID" @change="changeTiming" @focus="isEditing = true" :disabled="disabled">
                        <option v-for="timing in timings" :key="timing.id" :value="timing.id">{{ timing.name }}</option>
                    </select>
                </div>
            </div>
            <div v-show="eventCount + insertedEvents.length > 0" class="margin-left-medium margin-bottom-small">
                <h5 class="">Relevant Events:</h5>
                <record-events :obj="obj" 
                               eventSource="eventSearch" 
                               :parentVisible="true" 
                               searchKey="mandaInterest" 
                               :criteria="{ eventType: ['23','24'] }" 
                               :insertedEvents="insertedEvents"
                               eventView="ungrouped"
                               :renderLimit="30"
                               @eventsloaded="eventCount = $event" />
            </div>
        </div>
        <div class="flex-rows no-responsive fullwidth margin-top-small">
            <checkbox v-model="advancedMode" label="Advanced Mode" :showAsSwitch="true" :disabled="disabled" />
            <btn v-if="showSave && !disabled" el="button" title="Save" @click="saveChanges(false)" type="primary">Save</btn>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import { DateTime } from 'luxon'
    import { useStore } from 'vuex'
    import { computed, nextTick, inject, onMounted, onBeforeUnmount, ref } from 'vue'
    import { useToast } from '../../composables/ModelWrapper.js'
    import { editorProps, editorEmits, editorSetup } from '../../composables/Editors.js'

    import Checkbox from '../ui/forms/Checkbox.vue'
    import RecordEvents from '../Records/RecordEvents.vue'


    export default {
        name: 'MandaInterestEditor',
        components: { Checkbox, RecordEvents },
        props: {
            ...editorProps(),
            modelValue: { type: Object },
        },
        emits: [...editorEmits()],
        setup(props, { emit }) {
             // tools
            const store = useStore()
            const toast = useToast()            
            const emitter = inject("emitter")   // Inject event bus  

            const dirtiableProps = ['noInterest', 'sellInterest', 'buyInterest', 'financeInterest', 'valueInterest', 'divestInterest', 'timingID']

            // models, data, computed from common setup
            const getModel = () => {
                return _.cloneDeep(props.modelValue)
            }
            const model = ref(getModel())
            const { advancedMode, isEditing, errState, showSpinLoader, baseModel, savingEdits, dirtyProps, onSave, rollbackModel } = editorSetup(props, emit, dirtiableProps, model)
            const eventCount = ref(0)

            // constants
            const visibleMandaInterest = {
                "noInterest": {
                    advanced: false,
                    name: 'No Interest'
                },
                "sellInterest": {
                    advanced: false,
                    name: 'Sell'
                },
                "buyInterest": {
                    advanced: false,
                    name: 'Buy'
                },
                "financeInterest": {
                    advanced: false,
                    name: 'Finance'
                },
                "valueInterest": {
                    advanced: true,
                    name: 'Value'
                },
                "divestInterest": {
                    advanced: true,
                    name: 'Divestiture'
                },
            }
            const timings = [
                { id: 0, name: 'N/A', years: null },
                { id: 1, name: 'Unknown', years: null },
                { id: 2, name: 'In-play', years: 0 },
                { id: 3, name: 'Now', years: 0 },
                { id: 4, name: 'In 1 Year', years: 1 },
                { id: 5, name: 'In 2 Years', years: 2 },
                { id: 6, name: 'In 3 Years', years: 3 },
                { id: 7, name: 'Bring a Buyer', years: null }
            ]

            // computed
            const interestKeys = computed(() => { return Object.keys(visibleMandaInterest) })
            const hasInterest = computed(() => { return model.value ? (model.value.sellInterest || model.value.buyInterest || model.value.financeInterest || model.value.valueInterest || model.value.divestInterest) : false })            
            const insertedEvents = computed(() => {
                var events = []
               if (model.value.projectedDate) {
                   events.push({
                       name: "M&A Projected Date",
                       date: model.value.projectedDate,
                       eventTypeClass: eventCategoryClass('M&AProjectedDate'),
                       eventCategory: 'M&A',
                       eventCategoryClass: eventCategoryClass('M&A'),
                       eventType: -2, objectType: 'event', linkURL: '', htmlBody: false, weight: 1, description: null, eventActors: []
                   })
                }
                if (model.value.timingDate) {
                    events.push({
                        name: "Timing Recorded",
                        date: model.value.timingDate,
                        eventTypeClass: eventCategoryClass('TimingDate'),
                        eventCategory: 'Qualification',
                        eventCategoryClass: eventCategoryClass('Qualification'),
                        eventType: -3, objectType: 'event', linkURL: '', htmlBody: false, weight: 1, description: null, eventActors: []
                    })
                }
                return events
            })
            // methods
            const saveChanges = (suppressToastMessages) => {
                return new Promise((resolve, reject) => {
                    if (!model.value.dirty) {
                       // if (!suppressToastMessages)
                         //   toast.add({ severity: 'info', summary: 'M&A Interest not changed', detail: 'not saving', life: 3000 })
                        resolve({ changed: false })
                        return
                    }
                    showSpinLoader.value = true
                    store.dispatch('comments/saveMandaInterest', { mandaInterest: model.value }).then((results) => {
                        showSpinLoader.value = false

                        model.value = _.cloneDeep(results.mandaInterest)

                        if (!suppressToastMessages)
                            toast.add({ severity: 'info', summary: 'M&A Interest Saved', life: 3000 })

                        resolve({ changed: true })

                    }).catch(error => {
                        showSpinLoader.value = false
                        if (!suppressToastMessages)
                            toast.add({ severity: 'error', summary: 'Error saving M&A Interest', detail: error.message, life: 3000 })
                        reject('Error saving MandaInterest: ' + error.message)
                    })
                })
            }
            // methods
            const eventCategoryClass = (category) => {
                return category ? category.split(' ').join('-').replace('&', '').toLowerCase() : ''
            }

            // event bus
            const saveHandler = (req) => {
                onSave(req, 'mandaInterest', emitter, saveChanges)
            }
            const rollbackHandler = (req) => {
                if (req.editorID == props.editorID) {
                    rollbackModel()
                }
            }

            onMounted(() => {
                emitter.on("saveEditor", saveHandler);
                emitter.on("rollbackModel", rollbackHandler)
            })
            onBeforeUnmount(() => {
                emitter.off("saveEditor", saveHandler);
                emitter.off("rollbackModel", rollbackHandler)
            })

            return {
                // tools
                emitter,
                // models
                model, advancedMode, isEditing, errState,
                // data
                showSpinLoader, baseModel, savingEdits, eventCount,
                // constants
                visibleMandaInterest, timings,
                // computed
                interestKeys, hasInterest, dirtyProps, insertedEvents,
                // methods
                saveChanges, onSave, eventCategoryClass, getModel
            }
        },
        methods: {           
            validateModel() {
                this.errState = null;
               // this.errState = { errorCount: 0, errorMessages: [] }
                return this.errState
            },            
            changeTiming(event) {
                var timing = this.timings[event.target.value];
                if (timing)
                    this.model.timing = timing.name;
            },
            fetch(opt) {
                console.log('fetch Manda Interest', opt)
                this.showSpinLoader = true
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('comments/fetchMandaInterest', opt)
                        .then((results) => {
                            this.showSpinLoader = false
                            if (results && results.mandaInterest) {
                                this.model = _.cloneDeep(results.mandaInterest)
                            }
                            console.log('loaded mandaInterest', results.mandaInterest)
                            resolve()
                        }).catch(error => {
                            if (!opt.suppressToastMessages)
                                this.$toast.add({ severity: 'error', summary: 'Error fetching M&A Interest', detail: error, life: 3000 })
                            this.showSpinLoader = false
                            reject()
                        })
                })
            },
        },
        watch: {
            "modelValue": {
                handler() {
                    this.model = this.getModel()
                }
            },
            "model": {
                handler() {
                    this.isEditing = false
                    nextTick(() => {
                        this.model.dirty = false
                        this.baseModel = _.cloneDeep(this.model)
                    })
                }
            },
            "obj.objectKey": {
                handler(val) {
                    console.log(val)
                }
            },
            "dirtyProps": {
                handler(val, oldValue) {
                    if (this.model) {
                        var changed = []
                        Object.keys(val).forEach((key) => {
                            if (val[key] != oldValue[key])
                                changed.push(key)
                        })

                        if (changed.length) {
                            console.log('model dirtyProps deep watch', this.model.id, changed)
                            this.model.dirty = true
                            if (!this.showSpinLoader) {
                                this.validateModel()
                                this.$emit('dirtied', val)
                            }
                        }
                    }
                },
                deep: true
            },
            "model.noInterest": {
                handler(val) {
                    if (val && this.model && this.isEditing) {
                        this.interestKeys.forEach((key) => {
                            if (key != 'noInterest')
                                this.model[key] = false
                        })
                    }
                }
            },
            "hasInterest": {
                handler(val) {
                    if (val && this.model && this.isEditing) {
                        this.model.noInterest = false
                    }
                }
            },
            "model.timingID": {
                handler(val) {
                    if (this.model && this.isEditing) {
                        this.model.timingDate = new Date()
                        if (this.timings[val].years != null) {
                            this.model.projectedDate = DateTime.now().plus({ years: this.timings[val].years }).toJSON()
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
