<template>
    <menu-vertical options="panel options medium">
        <menu-item v-for="(option, idx) in sortOptions" :icon="option.key == model.key && option.asc == model.asc ? 'check' : ''" :label="option.label +' '+ (option.asc ? option.ascLabel : option.descLabel)" :key="idx" @click="selectSort(option)"/>
    </menu-vertical>
</template>

<script>
    import { useModelWrapper } from '../../composables/ModelWrapper.js'
    import _ from 'lodash'
    import MenuItem from '../ui/menus/MenuItem.vue'
    import MenuVertical from '../ui/menus/MenuVertical.vue'

    export default {
        name: 'SortMenu',
        components: {
            MenuItem, MenuVertical
        },
        emits: ['update:modelValue', 'sortselected'],
        props: {
            modelValue: { type: Object},
            options: { type: Array },
            //the objects in the options prop array only need a key field. all other fields are optional 
            //all fields: key, label, type, ascLabel, descLabel
            showDescSort: { type: Boolean, default: true }
            //sets whether the selector has descending options
        },
        computed: {
            sortOptions: function () {
                var me = this;
                var sortOptions = [];
                _.each(me.options, function (o) {
                    if (o && o.key) {
                        o.label = o.label ? o.label : o.key;
                        o.type = o.type ? o.type : null;
                        o.sort = _.isFunction(o.sort) ? o.sort : null;
                        o.asc = true;
                        o.ascLabel = me.getSortLabel(o, o.asc);
                        o.descLabel = me.getSortLabel(o, !o.asc);

                        if (me.showDescSort) {
                            var op = _.cloneDeep(o);
                            op.asc = false;
                            if (op.defaultAscending) {
                                sortOptions.push(o);
                                sortOptions.push(op);
                            }
                            else {
                                sortOptions.push(op);
                                sortOptions.push(o);
                            }
                        }
                        else
                            sortOptions.push(o);
                    }
                });
                return sortOptions;
            },
        },
        methods: {
            selectSort: function (option) {
                this.model.key = option.key;
                this.model.asc = option.asc;
                this.model.type = option.type;
                this.model.sort = option.sort;
                this.$emit('update:modelValue', this.model)
                this.$emit('sortselected', this.model)
            },
            getSortLabel: function (option, asc) {
                var label = asc ? '(Lowest to Highest)' : '(Highest to Lowest)';
                switch (option.type) {
                    case 'Default':
                    case 'Function':
                        if (option.ascLabel && option.descLabel)
                            label = asc ? option.ascLabel : option.descLabel;
                        break;
                    case 'Date':
                        label = asc ? '(oldest to recent)' : '(recent to oldest)';
                        break;
                    case 'String':
                        label = asc ? '(A-Z)' : '(Z-A)';
                        break;
                }
                return label;
            }
        },
        setup: function (props, { emit }) {
            const model = useModelWrapper(props, emit, "modelValue");

            return {
                model
            }
        }
    }
</script>

<style>
</style>