<template>
    <div class="manda-chart" ref="chartdiv">
    </div>
</template>

<script>
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    import am4themes_animated from "@amcharts/amcharts4/themes/animated";

    am4core.useTheme(am4themes_animated);

    export default {
        name: 'ChartPeStrategicPeriods',
        mounted() {
            let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
            chart.colors.step = 2;

            chart.legend = new am4charts.Legend()
            chart.legend.position = 'bottom'
            chart.legend.paddingBottom = 20
            chart.legend.labels.template.maxWidth = 95

            chart.colors.list = [
                am4core.color("#2f3d24"),
                am4core.color("#969a8d"),
            ];


            var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
            xAxis.dataFields.category = 'category'
            xAxis.renderer.cellStartLocation = 0.1
            xAxis.renderer.cellEndLocation = 0.9
            xAxis.renderer.grid.template.location = 0;
            xAxis.renderer.grid.template.strokeWidth = 0;


            var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
            yAxis.min = 0;
            yAxis.title.text = "Number Of Transactions";
            yAxis.renderer.line.strokeOpacity = .5;
            yAxis.renderer.line.strokeWidth = .5;
            yAxis.max = 1600

            function createSeries(value, name) {
                var series = chart.series.push(new am4charts.ColumnSeries())
                series.dataFields.valueY = value
                series.dataFields.categoryX = 'category'
                series.name = name

                series.events.on("hidden", arrangeColumns);
                series.events.on("shown", arrangeColumns);

                var bullet = series.bullets.push(new am4charts.LabelBullet())
                bullet.interactionsEnabled = false
                bullet.dy = 30;
                bullet.label.text = '{valueY}'
                bullet.label.fill = am4core.color('#000000')
                bullet.locationY = -.2;

                return series;
            }

            chart.data = [
                {
                    category: '2019 1st Half',
                    first: 1275,
                    second: 342,
                },
                {
                    category: '2019 2nd Half',
                    first: 1161,
                    second: 324,
                },
                {
                    category: '2020 1st Half',
                    first: 1116,
                    second: 276,
                },
                {
                    category: '2020 2nd Half',
                    first: 1204,
                    second: 382,
                },
                {
                    category: '2021 1st Half',
                    first: 1360,
                    second: 448,
                }

            ]


            createSeries('first', 'Strategic Transactions');
            createSeries('second', 'Financial Transactions');

            function arrangeColumns() {

                var series = chart.series.getIndex(0);

                var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndaaaan);
                if (series.dataItems.length > 1) {
                    var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
                    var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
                    var delta = ((x1 - x0) / chart.series.length) * w;
                    if (am4core.isNumber(delta)) {
                        var middle = chart.series.length / 2;

                        var newIndex = 0;
                        chart.series.each(function (series) {
                            if (!series.isHidden && !series.isHiding) {
                                series.dummyData = newIndex;
                                newIndex++;
                            }
                            else {
                                series.dummyData = chart.series.indexOf(series);
                            }
                        })
                        var visibleCount = newIndex;
                        var newMiddle = visibleCount / 2;

                        chart.series.each(function (series) {
                            var trueIndex = chart.series.indexOf(series);
                            var newIndex = series.dummyData;

                            var dx = (newIndex - trueIndex + middle - newMiddle) * delta


                            series.dataItems.template.locations.categoryX = 0.5;
                            series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                            series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                        })
                    }
                }
            }

            var title = chart.titles.create();
            title.text = "Transaction Type By Half Year";
            title.fontSize = 20;
            title.fontWeight = "bold";
            title.marginBottom = 30;

            this.chart = chart;
        },
        beforeUnmount() {
            if (this.chart) {
                this.chart.dispose();
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .manda-chart {
        width: 100%;
        height: 500px;
        font-family: Arial, Helvetica, sans-serif;
    }
</style>
