<template>
    <div v-if="active && isScrolled" :class="scrollToTopClasses" @click="handleScrollToTop">
        <i class="far fa-arrow-up"></i>
        <span class="label">Scroll To Top</span>
    </div>
</template>

<script>
    //
    import { ref, computed, onMounted, onBeforeUnmount, onUpdated } from 'vue'
    //import $ from 'jquery'
    import _ from 'lodash'
    import { scrollToTop, scrollToElementTop, computedSelector } from '../../../composables/ScrollTools.js'
    import { getMqTools } from '../../../composables/MqTools.js'

    export default {
        name: 'ScrollToTop',
        props: {
            containerRef: { type: Object },
            enabled: { type: Boolean, default: true },
            hideOnMobile: { type: Boolean, default: true },
            minScrollY: { type: Number, default: 10 },
            placement: { type: String, default: 'global' }
        },
        setup(props) {
            // tools
            const { isMobile } = getMqTools()

            // data
            const isScrolled = ref(false)
            const hasListener = ref(false)

            // computed
            const active = computed(() => !isMobile.value || !props.hideOnMobile)
            const containerSelector = computedSelector(props, 'containerRef')
            const scrollToTopClasses = computed(() => props.placement == 'footer' ? 'scroll-inside' : 'back-to-top')
            const isGlobal = computed(() => props.placement == 'global')

            // methods
            const handleScroll = (event) => {
                //console.log('handleScroll', isScrolled.value, isGlobal.value, props.minScrollY, props.containerRef)
                // Any code to be executed when the window is scrolled
                if (isGlobal.value)
                    isScrolled.value = window.scrollY > props.minScrollY
                else {
                    //console.log('calling handleScroll', event)
                    isScrolled.value = event.target.scrollTop > props.minScrollY
                }
                //console.log('handleScroll-complete', isScrolled.value)
            }
            const debouncedScroll = _.debounce(handleScroll, 50)
            const addListener = () => {
                if (active.value &&  !hasListener.value) {
                    if (isGlobal.value) {
                        window.addEventListener('scroll', debouncedScroll)
                        hasListener.value = true
                    }
                    else if (props.containerRef) {
                        props.containerRef.addEventListener('scroll', debouncedScroll)
                        hasListener.value = true
                    }
                }
            }

            const handleScrollToTop = () => {
                if (isGlobal.value)
                    scrollToTop(0)
                else {
                    //console.log('handleScrollToTop', containerSelector.value)
                    scrollToElementTop(containerSelector.value, 0)
                }
            }
          
            // Lifecycle
            onMounted(() => {
                addListener()
            })
            onUpdated(() => {
                addListener()
            })
            onBeforeUnmount(() => {
                if (hasListener.value) {
                    if (isGlobal.value)
                        window.removeEventListener('scroll', debouncedScroll)
                    else if (props.containerRef)
                        props.containerRef.removeEventListener('scroll', debouncedScroll)
                    hasListener.value = false
                }
            })

            return {
                // data
                isScrolled, hasListener,
                // computed
                active, isMobile, containerSelector, scrollToTopClasses, isGlobal,
                // methods
                scrollToTop, handleScroll, debouncedScroll, addListener, handleScrollToTop
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>