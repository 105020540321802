<template>
    <section class="card-section search-filter applied-filters">
        <ul class="list-tags no-icon suggestions lstFilters-appliedTags">
            <li v-for="(tag, tidx) in tagData" :key="tag.name + tidx">
                <tag-group v-if="tag.subTags"
                           :name="tag.name"
                           :type="tag.type ?? 'default'"
                           :title="tag.title"
                           :count="tag.count"
                           :actions="tag.actions"
                           :subTags="tag.subTags"
                           :class="tag.class"
                           @button-clicked="handleClick(tag.key, $event)"
                           @subtag-clicked="handleClick(tag.key, $event.action, $event.tag)" />
                <tag-basic v-else
                           :type="tag.type ?? 'keyword'"
                           :name="tag.name"
                           :label="tag.label"
                           :title="tag.title"
                           :icon="tag.icon"
                           :href="tag.href"
                           :count="tag.count"
                           :class="tag.class"
                           :actions="tag.actions"
                           @button-clicked="handleClick(tag.key, $event)" />
            </li>
        </ul>
        <label v-if="executionTime" :title="executionTimeDetails">{{ executionTime }}</label>
    </section>
</template>

<script>
    import { computed } from 'vue'
    import { useStore } from 'vuex'
    import _ from 'lodash'

    import { getSearchTagTools } from '../../composables/SearchTools.js'

    import TagBasic from '../ui/tags/TagBasic.vue'
    import TagGroup from '../ui/tags/TagGroup.vue'

    export default {
        name: 'AppliedFilters',
        emits: ['editfilter', 'deletefilter'],
        props: {
            criteria: { type: Object },
            advanced: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false },
            exTimes: { type: Object }
        },
        components: {
            TagBasic, TagGroup
        },
        setup(props, { emit }) {
            const store = useStore()
         
            const { actionMap, filterToTag } = getSearchTagTools()

            // computed
            const filterDefs = computed(() => store.getters["search/filterDefs"])
            const filterDefsLC = computed(() => filterDefs.value ? _.transform(filterDefs.value, function (result, val, key) { result[key.toLowerCase()] = val; }) : {})
            const displayMode = computed(() => props.advanced ? 1 : 0)
            const executionTime = computed(() => props.exTimes ? (props.exTimes.total / 1000).toFixed(2) + ' sec': null)
            const executionTimeDetails = computed(() => props.exTimes ?
                _.map(props.exTimes, function (value, name) {
                        return name + ': ' + (value / 1000).toFixed(2)
                    })
                : null
            )
            const appliedFilters = computed(() => {
                if (filterDefs.value && props.criteria) {
                    var filters = Object.keys(props.criteria).map((crit) => props.criteria[crit])
                    return filters.filter((crit) => {
                        return filterDefs.value[crit.filterName] && filterDefs.value[crit.filterName].displayMode <= displayMode.value
                    })
                }
             
                return []
            })
            const tagData = computed(() => {
                var tags = []
                if (filterDefsLC.value && props.criteria) {
                    var filters = Object.keys(props.criteria).map((crit) => props.criteria[crit])
                    filters.forEach((crit) => {
                        if (crit) {
                            var key = crit.filterName ? crit.filterName : crit.key;
                            if (key) {
                                var def = filterDefsLC.value[key.toLowerCase()]
                                if (!crit.new && def && def.displayMode <= displayMode.value) {
                                    var critTag = filterToTag(crit, (props.readOnly ? [] : ['edit', 'remove']))
                                    if (critTag)
                                        tags.push(critTag)
                                }
                            }
                        }
                    })
                }
                return tags
            })

            // methods
            const handleClick = (tagKey, action, subTag) => {
                _.noop(subTag)
                //console.log('handleClick', tagKey, action, subTag)
                emit(actionMap[action], tagKey)
            }

            return {
                // const
                actionMap,
                // computed
                filterDefs, displayMode, executionTime, executionTimeDetails,
                appliedFilters, tagData,
                // methods
                handleClick, filterToTag
            }

        }
    }
</script>

<style scoped lang="scss">
    .swap-filter {
        background-color: #c7fee3;
    }

    .applied-count {
        margin: 0px 5px
    }

    .filter-name {
        margin-right: 5px
    }
</style>
