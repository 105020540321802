<template>
    <auto-complete ref="autocomplete" v-model="selectedTags"
                   :suggestions="filteredTags"
                   @complete="search($event)"
                   :field="tagField"
                   :placeholder="placeholder ? placeholder : 'Type ' + tagSymbol"
                   :multiple="multiple"
                   :forceSelection="forceSelection"
                   :appendTo="appendTo"
                   @clear="tagSelected"
                   @item-select="tagSelected"
                   @item-unselect="tagSelected"
                   @focus="autocompleteFocused"
                   :canTextSelect="true"
                   :selectTextOnBlur="true"
                   @textselect="textSelect">
        <template #item="slotProps">
            <div :class="slotProps.item.newTag ? 'newTagSuggestion' : ''">
                {{ slotProps.item[tagField] }}
                <span v-if="tagType == 'hashtag' && slotProps.item.hitCount" class="suggest-hitcount">[{{ slotProps.item.hitCount }} hits]</span>
                <span v-if="tagType == 'attag' && slotProps.item.description" class="suggest-hitcount">({{ slotProps.item.description }})</span>
            </div>
        </template>
        <template v-if="tagType == 'attag'" #buttonSlot>
            <dropdown v-if="tagType == 'attag'" ref="filterDropDown" icon="filter" :iconSolid="!!filterObjectType" :label="filterObjectType" @openclose="closeAutocomplete">
                <template #body>
                    <section class="box float frame-vertical-min frame-horizontal-xsmall">
                        <radio-list v-model="filterObjectType"
                                    name="filterObjectType"
                                    :radioValues="radioValues"
                                    :labels="radioLabels"
                                    size="small"
                                    @selected="$refs.filterDropDown.closeTheDropDown()" />
                    </section>
                </template>
            </dropdown>
        </template>
    </auto-complete>

    <div class="input-group" style="display: none;">
        <div class="input-wrapper has-icon-pill">
            <i v-if="showIcon" :class="iconMap[tagType]"></i>

        </div>
    </div>
</template>

<script>
    import AutoComplete from '../PrimeVue/AutoComplete.vue'

    import RadioList from '../ui/forms/RadioList.vue'

    import { mapGetters } from 'vuex'
    import _ from 'lodash'

    export default {
        name: 'TagPicker',
        components: { AutoComplete, RadioList },
        emits: ['change'],
        props: {
            tagType: {
                type: String,
                default: 'hashtag'
            },
            initialFilterObjectType: {
                type: String,
                default: null
            },
            forceSelection: {
                type: Boolean,
                default: false
            },
            //text placeholder for search
            placeholder: {
                type: String,
                default: ""
            },
            //checks whether non-tags should be allowed in the component's tag values
           allowText: {
                type: Boolean,
                default: false
            },
            //checks whether the user's input should be treated as a new tag if a tag search returns with no hits
            allowNewTag: {
                type: Boolean,
                default: true
            },
            //tells the search whether the first char in the term is a sign 
            expectSign: {
                type: Boolean,
                default: true
            },
            showDescription: {
                type: Boolean,
                default: true
            },
            showIcon: {
                type: Boolean,
                default: true
            },
            limit: {
                type: Number,
                default: 10
            },
            multiple: {
                type: Boolean,
                default: false
            },
            inpopup: {
                type: Boolean,
                default: false
            },
            initialValue: null
        },
        data: function () {
            return {
                iconMap: { 'hashtag': 'fa fa-hashtag', 'attag': 'fa fa-at' },
                symbolMap: { 'hashtag': '#', 'attag': '@' },
                selectedTags: null,
                filteredTags: [],
                typedWord: '',
                autocompleteWordCount: 0,
                filterObjectType: null,
                radioValues: [null, 'user', 'company', 'person', 'acquisition'],
                radioLabels: ['No Filter', 'Users', 'Companies', 'Persons', 'Acquisitions']
            }
        },
        computed: {
            ...mapGetters({
                tagWords: 'comments/tagWords',
                tagFieldMap: 'comments/tagFieldMap'
            }),
            tagSymbol() {
                return this.symbolMap[this.tagType]
            },
            tagField() {
                return this.tagFieldMap[this.tagType]
            },
            appendTo() {
                return this.inpopup ? "self" : "body"
            },           
            selectedDescription() {
                if (!this.selectedTags)
                    return 'nothing selected'
                return this.multiple ? String.join(', ', this.selectedTags.map((w) => { return w[this.tagField] })) : this.selectedTags[this.tagField]
            },

            filterObjectTypeKey() { return this.filterObjectType ? this.filterObjectType : 'unfiltered' },
            selectedTagArray() {
                var selectedTagArray = []
                if (this.selectedTags) {
                    if (!this.multiple)
                        selectedTagArray.push(this.selectedTags)
                    else
                        selectedTagArray = this.selectedTags
                }
                return selectedTagArray
            },
            newTags() {
                return this.selectedTagArray.filter((tag) => { return tag.newTag })
            }
        },
        methods: {
            closeAutocomplete(open) {
                if (open && this.$refs.autocomplete) 
                    this.$refs.autocomplete.hideOverlay()
            },
            focus() {
                if (this.$refs.autocomplete) this.$refs.autocomplete.focus();
            },
            search(event) {
                console.log('search', event)
                if (event.query.length < (this.expectSign && event.query.startsWith(this.tagSymbol) ? 2 : 1)) {
                    this.filteredTags = []
                    return
                }
                this.typedWord = event.query.toLowerCase()
                console.log('search', this.tagType, this.filterObjectTypeKey, this.typedWord, this.tagWords[this.tagType][this.filterObjectTypeKey][this.typedWord])

                //if we need a symbol, if said symbol is not in the typedWord, and the if component allows for non-tag values,
                if (this.expectSign && !this.typedWord.startsWith(this.tagSymbol) && this.allowText) {
                    //then do not add the word as a new tag. the tagPicker will still allow the text to be entered (due to allowText) but we won't have to deal with the dropdown appearing
                    this.filteredTags = []

                    //old code where we add the tag to the dropdown:
                    //var tag = { newTag: true };
                    //tag[this.tagFieldMap[this.tagType]] = this.typedWord;
                    //this.filteredTags = [tag];
                }
                //if the search term starts with the correct tagSymbol, or if we don't expect any symbol - run a search
                else if (!this.expectSign || this.typedWord.startsWith(this.tagSymbol)) {
                    //but first try to get the result from the storage/getters if it's already there
                    if (this.tagWords[this.tagType][this.filterObjectTypeKey][this.typedWord])
                        this.filteredTags = this.tagWords[this.tagType][this.filterObjectTypeKey][this.typedWord]
                    //otherwise run the search
                    //after the search, if allowNewTag=true and the search returns no results, typeWord will be added as a new tag
                    else {
                        this.fetchTags()
                    }
                }
                else
                    this.filteredTags = [];
            },
            autocompleteFocused(event) {
                if(event.target.value != this.tagSymbol)
                    event.target.select()
            },
            textSelect(text) {
                    if (text && !text.includes("@") && text != '#') {
                        var tag = {};
                        tag[this.tagField] = text;
                        tag.newTag = true;
                        if (this.tagType == 'hashtag') {
                            tag.tagType = 'hashtag';
                            tag.hitCount = 0;
                        }

                        if (this.multiple)
                            this.selectedTags.push(tag);
                        else
                            this.selectedTags = tag;

                        this.tagSelected();
                    }
            },
            fetchTags() {
                console.log('fetchTags', this.typedWord)
                this.$store.dispatch('comments/getTagWords', { tagType: this.tagType, word: this.typedWord, filterObjectType: this.filterObjectType, expectSign: this.expectSign, limit: this.limit, allowNewTag: this.allowNewTag })
                    .then((tags) => {
                        console.log('fetchTags-fetched', tags)
                        if (tags)
                            this.filteredTags = tags
                        console.log('filteredTags', this.filteredTags)
                    }).catch(error => {
                        if (error.fatal)
                            this.$toast.add({ severity: 'error', summary: 'Error searching ' + this.tagType, detail: error.message, life: 3000 })
                    })
            },
            tagSelected() {
                this.$emit('change', this.selectedTagArray)
            },
            setSelectedTag(tags) {
                this.selectedTags = this.multiple ? [] : null
                if (this.multiple)
                tags.forEach((tag) => {
                    this.selectedTags.push(tag)
                })
                else
                    this.selectedTags = (_.isArray(tags) ? tags[0] : tags)
            }
        },
        mounted() {
            if (this.initialValue) {
                //console.log('tagpicker-mounted', this.initialValue)
                var tags = []
                if (this.multiple && _.isArray(this.initialValue)) {
                    this.initialValue.forEach((tag) => {
                        if (_.isObject(tag))
                            tags.push(tag)
                    })
                }
                else if (!this.multiple && _.isObject(this.initialValue)) {
                    tags.push(this.initialValue)
                }
                this.setSelectedTag(tags)
            }

            if (this.initialFilterObjectType) {
                this.filterObjectType = this.initialFilterObjectType
            }
        }
    }
</script>

<style lang="scss">
    .suggest-hitcount{
        font-style: italic;
        font-weight: 600;
        color: forestgreen;
    }
    .newTagSuggestion{
        font-weight:300;
        color:dodgerblue
    }
 
</style>
