<template>
    <div>
        <radio-list v-model="distance" name="geoDistance" :radioValues="radioValues" :labels="radioLabels" size="xsmall"/>
    </div>
    <div>
        <checkbox v-model="nearMe" size="small" label="Near Me" />
        <spin-loader :showMe="nearMeSearching" />
        <span vif="nearMe">lat: {{ filterValue.lat.toFixed(2) }}, lon: {{ filterValue.lon.toFixed(2) }}</span>
    </div>
    <div>
        <term-picker termKey="city"
                     v-model="cityValue"
                     label="City"
                     valueFieldName="id"
                     textFieldName="label"
                     :forceSelection="true"
                     :canTextSelect="false"
                     :multiple="false"
                     :inpopup="inpopup"
                     :disabled="nearMe"
                     @item-select="onItemSelect"
                     />
    </div>
</template>

<script>

    import { ref, computed, watch, onMounted } from 'vue'
    import _ from 'lodash'
    import { searchFilterProps, searchFilterEmits, searchFilterSetup } from '../../composables/SearchTools.js'
    import { useToast } from '../../composables/ModelWrapper.js'

    import RadioList from '../ui/forms/RadioList.vue'
    import Checkbox from '../ui/forms/Checkbox.vue'
    import TermPicker from './TermPicker.vue'

    export default {
        name: 'GeoDistanceFilter',
        components: { TermPicker, RadioList, Checkbox },
        props: {
            ...searchFilterProps(),
            filterKey: { type: String, default: 'geoDistance' },
            distanceMap: { type: Array }
        },
        emits: [...searchFilterEmits()],
        setup(props, { emit }) {
            const toast = useToast()
            // const
            const newFilterValue = { geoID: '', name: '', lat: 0.0, lon: 0.0, distance: '5mi' }
            const defaultMap = [{ "key": "5mi", "value": "5 Miles" },
                { "key": "15mi", "value": "15 Miles" },
            { "key": "30mi", "value": "30 Miles" },
            { "key": "50mi", "value": "50 Miles" }]

            // model
            const { model, initialFilterState } = searchFilterSetup(props, emit)

            const filterValue = computed({
                get: () => {
                    return props.modelValue && props.modelValue.value ? JSON.parse(props.modelValue.value) : newFilterValue
                },
                set: (newVal) => {
                    //console.log('filterValue-setter', newVal)
                    var newModel = model.value ?? initialFilterState.value
                    if (newVal) {
                        newModel.value = JSON.stringify(newVal)
                        newModel.valueName = 'within ' + newVal.distance + ' of ' + newVal.name
                        newModel.hasValue = !!newVal.geoID
                    }
                    else {
                        newModel.value = null
                        newModel.valueName = null
                        newModel.hasValue = false
                    }
                    model.value = newModel
                    //emit('update:modelValue', model.value)
                }
            })

            // data
            //const filterValue = ref(newFilterValue)
            const nearMe = ref(false)
            const cityValue = ref([])
            const nearMeSearching = ref(false)
            const distance = ref(newFilterValue.distance)
            const distMap = ref(defaultMap) 
            const radioValues = ref([])
            const radioLabels = ref([])

            // lifecycle
            onMounted(() => {
                nearMe.value = (filterValue.value.geoID == 'nearMe')
                cityValue.value = (filterValue.value.geoID != 'nearMe' ? [{ id: filterValue.value.geoID, label: filterValue.value.name }] : null)
                distance.value = filterValue.value.distance
                emit('update:modelValue', model.value)

                distMap.value = props.distanceMap ? props.distanceMap : defaultMap
                radioValues.value = distMap.value.map((d) => { return d.key })
                radioLabels.value = distMap.value.map((d) => { return d.value })
            })

            // watches
           watch(filterValue, (newVal) => {
               _.noop('filterValue-watch', newVal)          
            }, { deep: true })

            watch(distance, (newVal) => {
                //console.log('distance-watch', newVal)
                var newFilterVal = filterValue.value
                newFilterVal.distance = newVal
                filterValue.value = newFilterVal
            })

            watch(cityValue, (newVal) => {
                //console.log('cityValue-watch', newVal)
                var fVal = filterValue.value
                if (newVal) {
                    if (_.isArray(newVal) && newVal[0]) {
                        fVal.geoID = newVal[0].id
                        fVal.name = newVal[0].label
                    }
                    else {
                        fVal.geoID = newVal.id
                        fVal.name = newVal.label
                    }
                }
                filterValue.value = fVal
            })

            watch(nearMe, (isChecked) => {
                var newFilterVal = filterValue.value
                if (isChecked) {
                    if (navigator.geolocation) {
                        nearMeSearching.value = true
                        navigator.geolocation.getCurrentPosition(function (position) {

                            newFilterVal.geoID = 'nearMe'
                            newFilterVal.name = 'Near Me'
                            newFilterVal.lat = position.coords.latitude
                            newFilterVal.lon = position.coords.longitude
                            filterValue.value = newFilterVal
                            nearMeSearching.value = false
                        })
                    }
                    else {
                        toast.add({ severity: 'error', summary: 'Geolocation', detail: "Geolocation is not supported by this browser.", life: 0 })
                        nearMe.value = false
                    }
                }
                else {
                    newFilterVal.geoID = null
                    newFilterVal.name = null
                    newFilterVal.lat = 0.0
                    newFilterVal.lon = 0.0
                    filterValue.value = newFilterVal
                }              
            })

            // methods
            const onItemSelect = (newVal) => {
                _.noop('onItemSelect', newVal)
            }

            return {
                // const
                newFilterValue, defaultMap, initialFilterState,
                // model
                model, filterValue,
                // data
                nearMe, nearMeSearching, distance, radioValues, distMap, radioLabels, cityValue,
                // methods
                onItemSelect
            }

        }
    }
</script>

<style scoped lang="scss">
</style>
