<template>
    <auto-complete class="smart-select" v-model="selectedChoice" :suggestions="filteredChoices" @complete="search($event)" :field="labelfield" :dropdown="showdropdown"
                   :multiple="multiple" :forceSelection="true" :appendTo="appendTo">

    </auto-complete>
</template>

<script>
    //import AutoComplete from 'primevue/autocomplete/sfc';
    import AutoComplete from '../PrimeVue/AutoComplete.vue'

    export default {
        name: 'SmartSelect',
        components: {
            AutoComplete
        },
        emits: ['change'],
        props: {
            multiple: {
                type: Boolean,
                default: false
            },
            inpopup: {
                type: Boolean,
                default: false
            },
            choices: {
                type: Array,
                default: new Array()
            },
            labelfield: {
                type: String,
                default: 'Entry'
            },
            valuefield: {
                type: String,
                default: 'id'
            },
            showdropdown: {
                type: Boolean,
                default: true
            },
            value: null
        },
        data: function () {
            return {
                selectedChoice: [],
                filteredChoices: []
            }
        },
        computed: {
            appendTo: function () {
                return this.inpopup ? "self" : "body"
            }
        },
        methods: {
            dropdownClick(event) {
                event.originalEvent.stopPropagation();

            },
            search(event) {

                var input = event.query.toLowerCase()
                if (input.length < 1) {
                    this.filteredChoices = [...this.choices]
                }
                else {
                    this.filteredChoices = this.choices.filter(choice => {
                        return choice[this.labelfield].toLowerCase()
                            .startsWith(input)
                    })
                }
            },
        },
        mounted() {
            if (this.value && this.choices) {
                var initialValue = Array.isArray(this.value) ? this.value : [this.value];
                var choices = this.choices.filter(c => {
                    return initialValue.includes(c[this.valuefield])
                });

                if (this.multiple)
                    this.selectedChoice = choices.length ? choices : null
                else
                    this.selectedChoice = choices.length ? choices[0] : null
            }
        },
        watch: {
            selectedChoice() {
                if (typeof this.selectedChoice == 'object')
                    this.$emit('change', this.selectedChoice)
            }
        }
    }
</script>

<style lang="scss">
</style>
