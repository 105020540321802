<template>
    <template v-if="objectType == 'acquisition'">
        <section class="grid wall">
            <template v-if="acqSeller">
                <section class="box border frame-xsmall title-people">
                    <span class="name">
                        <a :href="'/Company/details/' + acqSeller.id" target="_blank">{{ acqSeller.originalName }}<span class="badge-circle success" title="Seller">S</span></a>
                        <span v-if="userResearch || showEdit" class="actions">
                            <btn el="link" target="_blank" :href="'/company/edit/' + acqSeller.id" title="Edit" type="icon"><i class="fas fa-edit"></i></btn>
                        </span>
                    </span>
                    <span class="record-ownership" v-if="acqSeller.parentID != acqSeller.id">
                        sub. of &nbsp;<a :href="'/Company/details/'+ acqSeller.parentID" target="_blank"> {{ acqSeller.parentOriginalName }}</a>
                    </span>
                    <template v-if="!simpleAcqs">
                        <div>
                            {{ sellerDescription }}
                        </div>
                        <span v-if="acqSeller.advisors">
                            <span class="badge-circle warning" title="Advisors">A</span>&nbsp;
                            <span v-for="adv in acqSeller.advisors" :key="adv.ownerName">
                                {{ adv.ownerName }}
                            </span>
                        </span>
                    </template>
                </section>
            </template>
            <template v-if="acqBuyers">
                <template v-for="buyer in acqBuyers" :key="buyer.id">
                    <section class="box border frame-xsmall title-people">
                        <span class="name">
                            <a :href="'/Company/details/' + buyer.id" target="_blank">{{ buyer.originalName }}<span class="badge-circle primary" title="Buyer">B</span></a>
                            <span v-if="userResearch" class="actions">
                                <btn el="link" target="_blank" :href="'/company/edit/' + buyer.id" title="Edit" type="icon"><i class="fas fa-edit"></i></btn>
                            </span>
                        </span>
                        <template v-if="!simpleAcqs">
                            <div>
                                {{ buyerDescriptions[buyer.id] ? buyerDescriptions[buyer.id] : 'No Description' }}
                            </div>
                            <span v-if="buyerAdvisors">
                                <span class="badge-circle warning" title="Advisors">A</span>&nbsp;
                                <span v-for="adv in buyerAdvisors" :key="adv.ownerName">
                                    {{ adv.ownerName }}
                                </span>
                            </span>
                        </template>
                    </section>
                </template>
            </template>

        </section>
    </template>
</template>
<script>
    import { computed } from 'vue'
    import { getRecordTools } from '../../composables/RecordTools.js'
    import { getTextTools } from '../../composables/TextTools.js'
    import { useStore } from 'vuex'
    import _ from 'lodash'

    export default {
        name: 'RecordBodyExtended',
        props: {
            'obj': { type: Object },
            'simpleAcqs': { type: Boolean },
            'showEdit': { type: Boolean }
        },
        setup(props) {
            // computed
            const store = useStore()

            const { objectType, highlightText } = getRecordTools(props)
            const acqSeller = computed(() => props.obj ? props.obj.seller : null)
            const acqBuyers = computed(() => props.obj ? props.obj.buyers : null)
            const user = computed(() => store.getters['userInfo/currentUser'])
            const buyerAdvisors = computed(() => {
                var advs = []
                if (acqBuyers.value) {
                    acqBuyers.value.forEach((buyer) => {
                        if (buyer.advisors) {
                            buyer.advisors.forEach((adv) => {
                                if (!_.some(advs, function (ba) { return adv.ownerID == ba.ownerID; }))
                                    advs.push(adv)
                            })
                        }
                    })
                }
                return advs.length ? advs : null
            })

            const buyerDescriptions = computed(() => {
                var buyers = {};
                if (acqBuyers.value) {
                    _.each(acqBuyers.value, function (b) {
                       buyers[b.id] = decorateHighlights({ body: b.shortDescription, tokens: highlightText ? highlightText.value : [] })
                    });
                }
                return buyers;
            })
            const buyerDescription = computed(() => decorateHighlights({ body: props.obj.buyerDescription, tokens: highlightText ? highlightText.value : [] }))
            const sellerDescription = computed(() => acqSeller.value ? decorateHighlights({ body: acqSeller.value.shortDescription, tokens: highlightText ? highlightText.value : []  }) : null)

            // methods
            const { decorateHighlights } = getTextTools()
            return {
                user,
                // computed
                objectType, highlightText, acqSeller, acqBuyers, buyerAdvisors, buyerDescription, buyerDescriptions, sellerDescription,
                // methods
                decorateHighlights
            }
        },
        computed: {
            userResearch() {
                return this.user && _.find(this.user.groups, function (g) { return g.id == 9 });
            },
        }
    }
</script>
<style scoped lang="scss">
</style>
