<template>
    <!-- deleted -->
    <div v-if="typeof (obj.active) != 'undefined' && !obj.active" class="record-status deleted">
        <i class="fas fa-exclamation-circle"></i>
        <span class="status-label">Deleted!</span>
        <span class="status-message">
            {{ toShortDateString(obj.deactiveDate) }}
            <span v-if="obj.deactiveUserName">by {{ obj.deactiveUserName }}</span>
            <span v-if="obj.deactiveReason"> - {{ obj.deactiveReason }}</span>
        </span>
    </div>
</template>

<script>
    import { getTextTools } from '../../composables/TextTools.js'

    export default {
        name: 'RecordDeleted',
        props: {
            'obj': { type: Object }
        },
        setup() {
            const { toShortDateString } = getTextTools()

            return { toShortDateString }
        }
    }
</script>

<style lang="scss">

</style>