<template>
  <teleport to="body">
      <transition name="fade">
          <aside class="outer-sidebar is-open" :class="size" v-show="model">
              <OnClickOutside @trigger="closeSidepanel">
                  <transition name="slide" appear>
                      <section class="sidebar-wrapper">
                          <sidebar-header v-on:close-panel="closeSidepanel" :title="title" :showNavigator="showNavigator" 
                                          @navigate="requestNavigate" 
                                          @titleclick="$emit('titleclick',$event)" 
                                          @titleeditclick="$emit('titleeditclick',$event)" />
                          <sidebar-body>
                              <template #sidebar-body>
                                  <slot name="body" />
                              </template>
                          </sidebar-body>
                          <sidebar-footer v-on:close-panel="closeSidepanel" v-if="footer" :actions="actions" :showCancel="showFooterCancel">
                              <template #sidebar-footer>
                                  <slot name="footer" />
                              </template>
                          </sidebar-footer>
                      </section>
                  </transition>
              </OnClickOutside>
          </aside>
      </transition>
  </teleport>
</template>

<script>
    import { ref, watch, inject, computed } from 'vue'
    import { useModelVirtualWrapper } from '../../../composables/ModelWrapper.js'
    import $ from 'jquery'

    import { OnClickOutside } from '@vueuse/components'
    import SidebarHeader from "./SidebarHeader.vue"
    import SidebarBody from "./SidebarBody.vue"
    import SidebarFooter from "./SidebarFooter.vue"

    export default {
        name: 'OuterSidebar',
        props: {
            modelValue: { type: Boolean, default: undefined },
            title: String,
            size: { type: String },
            footer: { type: Boolean, default: true },
            actions: { type: Boolean, default: true },
            showNavigator: { type: Boolean, default: false },
            showFooterCancel: { type: Boolean, default: false },
            ignoreClickOutsideClasses: { type: Array },
            editing: { type: Object },
            dirty: { type: Object },
        },
        components: {
            SidebarHeader, SidebarBody, SidebarFooter, OnClickOutside
        },
        emits: ['save', 'navigate', 'titleclick', 'titleeditclick', 'update:modelValue'],
        setup(props, { emit }) {
            const nextChoice = inject('nextChoice')

            // models
            const isShowing = ref(false)
            const model = useModelVirtualWrapper(props, emit, 'modelValue', isShowing)

            //computed
            const isEditing = computed(() => props.editing)
            const isDirty = computed(() => props.dirty)

            const hasEditing = computed(() => isEditing.value ? Object.keys(isEditing.value).filter((k) => { return isEditing.value[k] }).length > 0 : false)
            const hasChanges = computed(() => isDirty.value ? Object.keys(isDirty.value).filter((k) => { return isDirty.value[k] }).length > 0 : false)


            // methods
            const openSidepanel = () => {
                model.value =true
                //isShowing.value = model.value
            }
            const requestNavigate = (direction) => {
                emit('navigate', direction)
            }

            watch(model, (newVal) => {
                if (newVal) {
                    document.body.classList.add('modal-open')
                }
                else {
                    document.body.classList.remove('modal-open')
                }
            })

            return {
                // model
                nextChoice, model, isShowing, 
                //computed
                isEditing, isDirty, hasEditing, hasChanges,
                // methods
                openSidepanel, requestNavigate
            }
        },       
        methods: {
            sidebarKeepContent() {
                return new Promise((resolve) => {
                    if (this.hasEditing && this.hasChanges) {
                        this.nextChoice({
                            title: 'Unsaved Changes',
                            message: 'Unsaved changes in the sidebar will get lost. Save?',
                            choices: [
                                { text: "Return to Edit", res: 'cancel' },
                                { text: "Don't Save", res: 'no-save' },
                                { text: 'Save Changes', res: 'save' }]
                        }).then(choice => {
                            if (choice == 'save') {
                                this.$emit('save')
                                resolve(true)
                            }
                            else if (choice == 'no-save') {
                                resolve(false)
                            }
                            else
                                resolve(true)
                        })
                    }
                    else
                        resolve(false)
                });
            },
            closeSidepanel: function (ev) {
                if (this.model) {
                    //if we're clicking on classes to ignore, do not close
                    if (this.ignoreClickOutsideClasses) {
                        var matches = this.ignoreClickOutsideClasses.filter(c => { return $(ev.target).closest('.' + c).length > 0 });
                        if (matches.length > 0)
                            return
                    }
                    //if there are unsaved changes (ask the user)
                    if (this.hasChanges) {
                        this.sidebarKeepContent().then(res => {
                            if (!res)
                                this.model = false
                        })
                        return;
                    }

                    //otherwise close it
                    this.model = false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>