<template>

    <section class="page-content">
        <section class="search-bar-container">
            <div class="search-bar box card">
                <full-text-filter v-model="fullTextModel"
                                  :simpleMode="true"
                                  filterKey="fulltext"
                                  :counts="counts"
                                  :adHocMaps="adHocMaps"
                                  @applyfilter="applyFilter" />
                <spin-loader :showMe="searchRunning" />
                <search-filters-toggle v-model="sidebarOpen" :criteria="query.criteria" :advanced="advancedMode" />
            </div>
        </section>
        <section class="page-section has-sidebar">
            <aside class="search-sidebar" :class="{ 'is-open': sidebarOpen }">
                <header class="search-sidebar-header">
                    <h3 class="search-results-title">
                        Applied Filters
                    </h3>
                    <span class="place-right clear-all-filters" href="javascript:;" @click="searchRunning = !searchRunning">
                        Clear all
                    </span>
                </header>
                <div class="search-sidebar-content">
                    <applied-filters :criteria="query.criteria" :advanced="advancedMode" :exTimes="exTimes" @editfilter="logEdit" @deletefilter="logDelete" />
                    <accordion options="sidebar">
                        <accordion-panel title="Suggested Filters" ref="suggestionsPanel">
                            <suggested-filters :query="resultsSet.query"
                                               :docTypes="selectedDocTypes"
                                               :docCounts="docCounts"
                                               ref="suggestedFilters"
                                               @addfilter="addSuggestion"
                                               @suggestions-updated="onSuggestionsUpdated" />
                        </accordion-panel>
                        <accordion-panel title="LOB Distance">
                            <section>
                                <lob-distance-filter v-model="lobDistanceModel"
                                                     filterKey="lobDistance"
                                                     :seedObjectKey="seedObjectKey"
                                                     :counts="counts"
                                                     :adHocMaps="adHocMaps"
                                                     @applyfilter="applyFilter" />
                                <hr />
                                {{ lobDistanceModel }}
                            </section>
                        </accordion-panel>
                        <accordion-panel title="Tag Search">
                            <tag-filter v-model="hashTagModel"
                                        filterKey="hashTag"
                                        tagType="hashTag"
                                        :counts="counts"
                                        :adHocMaps="adHocMaps"
                                        @applyfilter="applyFilter" />
                            <hr />
                            {{ hashTagModel }}
                            <tag-filter v-model="atTagModel"
                                        filterKey="atTag"
                                        tagType="atTag"
                                        :counts="counts"
                                        :adHocMaps="adHocMaps"
                                        @applyfilter="applyFilter" />
                            <hr />
                            {{ atTagModel }}
                        </accordion-panel>
                        <accordion-panel title="Text Search">
                            <full-text-filter v-model="fullTextModel"
                                              filterKey="fulltext"
                                              :counts="counts"
                                              :adHocMaps="adHocMaps"
                                              @applyfilter="applyFilter" />
                            <hr />
                            {{ fullTextModel }}
                        </accordion-panel>
                        <accordion-panel title="LOBs">
                            <lob-combo-filter v-model="lobComboModel"
                                              filterKey="lobCombo"
                                              :counts="counts"
                                              :adHocMaps="adHocMaps"
                                              @applyfilter="applyFilter" />
                            <hr />
                            {{ lobComboModel }}
                        </accordion-panel>
                        <accordion-panel title="Geo. Radius Search">
                            <section>
                                <geo-distance-filter v-model="geoDistanceModel"
                                                     filterKey="geoDistance"
                                                     :counts="counts"
                                                     :adHocMaps="adHocMaps"
                                                     @applyfilter="applyFilter" />
                                <hr />
                                {{ geoDistanceModel }}
                            </section>
                        </accordion-panel>

                        <accordion-panel title="State / Country Search">
                            <section>
                                <auto-complete-filter v-model="stateProvModel"
                                                      filterKey="geoStateProv"
                                                      label="State/Prov"
                                                      termKey="stateProv"
                                                      :multiple="true"
                                                      :counts="counts"
                                                      :adHocMaps="adHocMaps"
                                                      @applyfilter="applyFilter" />
                                <hr />
                                {{ stateProvModel }}
                            </section>
                            <section>
                                <auto-complete-filter v-model="countryModel"
                                                      filterKey="geoCountry"
                                                      label="Country"
                                                      termKey="country"
                                                      :multiple="true"
                                                      :counts="counts"
                                                      :adHocMaps="adHocMaps"
                                                      @applyfilter="applyFilter" />
                                <hr />
                                {{ countryModel }}
                                <auto-complete-filter v-model="ultimateParentModel"
                                                      label="Ultimate Parent"
                                                      termKey="ultimateParent"
                                                      filterKey="ultimateParentID"
                                                      valueFieldName="id"
                                                      :multiple="true"
                                                      :counts="counts"
                                                      :adHocMaps="adHocMaps"
                                                      @applyfilter="applyFilter" />
                                <hr />
                                {{ ultimateParentModel }}
                            </section>
                        </accordion-panel>

                        <accordion-panel title="Rated">
                            <user-date-filter v-model="ratedModel"
                                              filterKey="rated"
                                              :counts="counts"
                                              :adHocMaps="adHocMaps"
                                              @applyfilter="applyFilter" />
                            <hr />
                            {{ ratedModel }}
                        </accordion-panel>
                        <accordion-panel title="Data Entry">
                            <user-date-filter v-model="enteredModel"
                                              filterKey="entered"
                                              :counts="counts"
                                              :adHocMaps="adHocMaps"
                                              @applyfilter="applyFilter" />
                            <hr />
                            {{ enteredModel }}
                        </accordion-panel>
                        <accordion-panel title="Lead Analysis">
                            <h4>Lead Analysys</h4>
                            <section>
                                <check-list-filter v-model="leadAnalysisModel"
                                                   filterKey="leadAnalysis"
                                                   :counts="counts"
                                                   :adHocMaps="adHocMaps"
                                                   @applyfilter="applyFilter" />
                                {{ leadAnalysisModel }}
                            </section>
                        </accordion-panel>
                        <accordion-panel title="People">
                            <h4>Contact Info</h4>
                            <section>
                                <radio-list-filter v-model="hasGoodPersonModel"
                                                   filterKey="hasGoodPerson"
                                                   :counts="counts"
                                                   :adHocMaps="adHocMaps"
                                                   @applyfilter="applyFilter" />
                                {{ hasGoodPersonModel }}
                            </section>
                        </accordion-panel>
                        <accordion-panel title="Revenue">
                            <section>
                                <money-range-filter v-model="revenueRangeModel"
                                                    filterKey="revenueRange"
                                                    :counts="counts"
                                                    :adHocMaps="adHocMaps"
                                                    @applyfilter="applyFilter" />
                                {{ revenueRangeModel }}
                            </section>
                        </accordion-panel>
                        <accordion-panel title="Employee Size">
                            <section>
                                <money-range-filter v-model="employeeSizeRangeModel"
                                                    filterKey="employeeSizeRange"
                                                    :counts="counts"
                                                    :adHocMaps="adHocMaps"
                                                    @applyfilter="applyFilter" />
                                {{ employeeSizeRangeModel }}
                            </section>
                        </accordion-panel>

                        <accordion-panel title="Recently Viewed">
                            <section class="">
                                <auto-complete v-model="selectedCountry" :suggestions="filteredCountriesBasic" @complete="searchCountry($event)" field="name" :multiple="true" placeholder="Enter username..." />
                            </section>
                            <section class="margin-top-min">
                                <date-range-picker direction="left" ref="drPicker" />
                            </section>
                        </accordion-panel>
                        <accordion-panel title="Industry Segments">
                            <ul class="list-options">
                                <li v-for="industry in industries" :key="industry.name">
                                    <checkbox :label="industry.name + ' (' + industry.count + ')'" size="xsmall" />
                                </li>
                            </ul>
                        </accordion-panel>
                        <accordion-panel title="Revenue 2">
                            <ul class="list-options">
                                <li v-for="rev in revenue" :key="rev.label">
                                    <radio :label="rev.label + ' (' + rev.count + ')'" name="revenue" size="xsmall" />
                                </li>
                                <li>
                                    <radio label="Custom Range" name="revenue" size="xsmall" />
                                    <div class="frame-horizontal-small margin-top-min">
                                        <input class="fullwidth" type="text" :value="sliderRange" />
                                        <div class="margin-top-small">
                                            <slider v-model="sliderRange" :min="0" :max="1000" :range="true" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </accordion-panel>
                        <accordion-panel title="Lead Analysis 2">
                            <section class="content-section">
                                <ul class="list-options">
                                    <li v-for="industry in industries" :key="industry.name">
                                        <checkbox :label="industry.name + ' (' + industry.count + ')'" size="xsmall" />
                                    </li>
                                </ul>
                            </section>
                            <section class="content-section">
                                <h4>Ownership</h4>
                                <ul class="list-options">
                                    <li v-for="industry in industries" :key="industry.name">
                                        <checkbox :label="industry.name + ' (' + industry.count + ')'" size="xsmall" />
                                    </li>
                                </ul>
                            </section>
                            <section class="content-section">
                                <h4>Stock Type</h4>
                                <ul class="list-options">
                                    <li v-for="industry in industries" :key="industry.name">
                                        <checkbox :label="industry.name + ' (' + industry.count + ')'" size="xsmall" />
                                    </li>
                                </ul>
                            </section>
                        </accordion-panel>


                        <accordion-panel title="Multiple Types">
                            <p>Hey, In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                        </accordion-panel>
                        <accordion-panel title="Advanced Options">
                            <checkbox v-model="advancedMode" label="Advanced Mode" :showAsSwitch="true" />
                        </accordion-panel>
                    </accordion>
                </div>
            </aside>
            <section class="search-results results-container card">
                <header class="search-results-header">
                    <div class="flex-rows justify-right no-responsive frame-horizontal-small">
                        <h2 class="search-results-title">
                            <span class="search-results-count">Search Results (0)</span>
                        </h2>
                        <dropdown type="kebab">
                            <template #body>
                                <menu-vertical options="panel options medium">
                                    <menu-item label="Save As..."></menu-item>
                                    <menu-item label="Share Results..."></menu-item>
                                    <menu-item label="Advanced Options..."></menu-item>
                                </menu-vertical>
                            </template>
                        </dropdown>
                    </div>
                </header>
                <section>
                    <tabs options="boxtabs">
                        <tab title="Details">
                            <h3>Details tab content</h3>
                            <p>Hey, In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                        </tab>
                        <tab title="Table">
                            <h3>Table tab content</h3>
                            <p>Hey, In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                        </tab>
                        <tab title="Tag Cloud">
                            <h3>Tag cloud tab content</h3>
                            <p>Hey, In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                        </tab>
                        <tab title="Statistics">
                            <h3>Statistics tab content</h3>
                            <p>Hey, In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                        </tab>
                        <tab title="Brands">
                            <h3>Brands tab content</h3>
                            <p>Hey, In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                        </tab>
                        <tab title="Graphs">
                            <h3>Graphs tab content</h3>
                            <p>Hey, In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                        </tab>
                        <tab title="Maps">
                            <h3>Maps tab content</h3>
                            <p>Hey, In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
                        </tab>
                    </tabs>
                    <pager name="SearchResults" v-model="pagerModel" :total="23041" />
                  
                </section>
            </section>
        </section>
    </section>
    <toast />
</template>
<script>
    import _ from 'lodash'
    import { mapGetters } from 'vuex'

    import Tabs from '../tabs/Tabs.vue'
    import Tab from '../tabs/Tab.vue'
    import Accordion from '../accordions/Accordion.vue'
    import AccordionPanel from '../accordions/AccordionPanel.vue'
    import MenuItem from '../menus/MenuItem.vue'
    import MenuVertical from '../menus/MenuVertical.vue'
    import AutoComplete from 'primevue/autocomplete/sfc'
    import DateRangePicker from '../../baseComponents/DateRangePicker.vue'
    import Checkbox from '../forms/Checkbox.vue'
    import Radio from '../forms/Radio.vue'
    import Slider from 'primevue/slider'
    import Pager from '../tables/Pager.vue'
    import AppliedFilters from '../../Search/AppliedFilters.vue'
    import SuggestedFilters from '../../Search/SuggestedFilters.vue'
    import SearchFiltersToggle from '../../Search/SearchFiltersToggle.vue'

    import CheckListFilter from '../../Search/CheckListFilter.vue'
    import RadioListFilter from '../../Search/RadioListFilter.vue'
    import MoneyRangeFilter from '../../Search/MoneyRangeFilter.vue'
    import FullTextFilter from '../../Search/FullTextFilter.vue'
    import UserDateFilter from '../../Search/UserDateFilter.vue'
    import LobComboFilter from '../../Search/LobComboFilter.vue'
    import AutoCompleteFilter from '../../Search/AutoCompleteFilter.vue'
    import GeoDistanceFilter from '../../Search/GeoDistanceFilter.vue'
    import TagFilter from '../../Search/TagFilter.vue'
    import LobDistanceFilter from '../../Search/LobDistanceFilter.vue'

    //import SmartSelect from '../../baseComponents/SmartSelect.vue'
    export default {
        name: 'SearchGuide',
        components: {
            Tabs, Tab, Accordion, AccordionPanel, MenuItem, MenuVertical, AutoComplete, DateRangePicker, Checkbox, Radio, Slider,
            Pager, AppliedFilters, SuggestedFilters, SearchFiltersToggle, CheckListFilter, RadioListFilter, TagFilter,
            MoneyRangeFilter, FullTextFilter, UserDateFilter, LobComboFilter, AutoCompleteFilter, GeoDistanceFilter, LobDistanceFilter
        },
        props: {

        },
        data: function () {
            return {
                selectedCountry: null,
                filteredCountries: null,
                filteredCountriesBasic: [],
                countries: [
                    { "name": "Afghanistan", "code": "AF" },
                    { "name": "Åland Islands", "code": "AX" },
                    { "name": "Albania", "code": "AL" },
                    { "name": "Algeria", "code": "DZ" },
                    { "name": "American Samoa", "code": "AS" },
                    { "name": "Andorra", "code": "AD" },
                    { "name": "Angola", "code": "AO" },
                    { "name": "Anguilla", "code": "AI" },
                    { "name": "Antarctica", "code": "AQ" },
                    { "name": "Antigua and Barbuda", "code": "AG" },
                    { "name": "Argentina", "code": "AR" },
                    { "name": "Armenia", "code": "AM" },
                    { "name": "Aruba", "code": "AW" },
                    { "name": "Australia", "code": "AU" },
                    { "name": "Austria", "code": "AT" },
                    { "name": "Azerbaijan", "code": "AZ" },
                    { "name": "Bahamas", "code": "BS" },
                    { "name": "Bahrain", "code": "BH" },
                    { "name": "Bangladesh", "code": "BD" },
                    { "name": "Barbados", "code": "BB" },
                    { "name": "Belarus", "code": "BY" },
                    { "name": "Belgium", "code": "BE" },
                    { "name": "Belize", "code": "BZ" },
                    { "name": "Benin", "code": "BJ" },
                    { "name": "Bermuda", "code": "BM" },
                    { "name": "Bhutan", "code": "BT" },
                    { "name": "Bolivia", "code": "BO" },
                    { "name": "Bosnia and Herzegovina", "code": "BA" },
                    { "name": "Botswana", "code": "BW" },
                    { "name": "Bouvet Island", "code": "BV" },
                    { "name": "Brazil", "code": "BR" },
                    { "name": "British Indian Ocean Territory", "code": "IO" },
                    { "name": "Brunei Darussalam", "code": "BN" },
                    { "name": "Bulgaria", "code": "BG" },
                    { "name": "Burkina Faso", "code": "BF" },
                    { "name": "Burundi", "code": "BI" },
                    { "name": "Cambodia", "code": "KH" },
                    { "name": "Cameroon", "code": "CM" },
                    { "name": "Canada", "code": "CA" },
                    { "name": "Cape Verde", "code": "CV" },
                    { "name": "Cayman Islands", "code": "KY" },
                    { "name": "Central African Republic", "code": "CF" },
                    { "name": "Chad", "code": "TD" },
                    { "name": "Chile", "code": "CL" },
                    { "name": "China", "code": "CN" },
                    { "name": "Christmas Island", "code": "CX" },
                    { "name": "Cocos (Keeling) Islands", "code": "CC" },
                    { "name": "Colombia", "code": "CO" },
                    { "name": "Comoros", "code": "KM" },
                    { "name": "Congo", "code": "CG" },
                    { "name": "Congo, The Democratic Republic of the", "code": "CD" },
                    { "name": "Cook Islands", "code": "CK" },
                    { "name": "Costa Rica", "code": "CR" },
                    { "name": "Cote D\"Ivoire", "code": "CI" },
                    { "name": "Croatia", "code": "HR" },
                    { "name": "Cuba", "code": "CU" },
                    { "name": "Cyprus", "code": "CY" },
                    { "name": "Czech Republic", "code": "CZ" },
                    { "name": "Denmark", "code": "DK" },
                    { "name": "Djibouti", "code": "DJ" },
                    { "name": "Dominica", "code": "DM" },
                    { "name": "Dominican Republic", "code": "DO" },
                    { "name": "Ecuador", "code": "EC" },
                    { "name": "Egypt", "code": "EG" },
                    { "name": "El Salvador", "code": "SV" },
                    { "name": "Equatorial Guinea", "code": "GQ" },
                    { "name": "Eritrea", "code": "ER" },
                    { "name": "Estonia", "code": "EE" },
                    { "name": "Ethiopia", "code": "ET" },
                    { "name": "Falkland Islands (Malvinas)", "code": "FK" },
                    { "name": "Faroe Islands", "code": "FO" },
                    { "name": "Fiji", "code": "FJ" },
                    { "name": "Finland", "code": "FI" },
                    { "name": "France", "code": "FR" },
                    { "name": "French Guiana", "code": "GF" },
                    { "name": "French Polynesia", "code": "PF" },
                    { "name": "French Southern Territories", "code": "TF" },
                    { "name": "Gabon", "code": "GA" },
                    { "name": "Gambia", "code": "GM" },
                    { "name": "Georgia", "code": "GE" },
                    { "name": "Germany", "code": "DE" },
                    { "name": "Ghana", "code": "GH" },
                    { "name": "Gibraltar", "code": "GI" },
                    { "name": "Greece", "code": "GR" },
                    { "name": "Greenland", "code": "GL" },
                    { "name": "Grenada", "code": "GD" },
                    { "name": "Guadeloupe", "code": "GP" },
                    { "name": "Guam", "code": "GU" },
                    { "name": "Guatemala", "code": "GT" },
                    { "name": "Guernsey", "code": "GG" },
                    { "name": "Guinea", "code": "GN" },
                    { "name": "Guinea-Bissau", "code": "GW" },
                    { "name": "Guyana", "code": "GY" },
                    { "name": "Haiti", "code": "HT" },
                    { "name": "Heard Island and Mcdonald Islands", "code": "HM" },
                    { "name": "Holy See (Vatican City State)", "code": "VA" },
                    { "name": "Honduras", "code": "HN" },
                    { "name": "Hong Kong", "code": "HK" },
                    { "name": "Hungary", "code": "HU" },
                    { "name": "Iceland", "code": "IS" },
                    { "name": "India", "code": "IN" },
                    { "name": "Indonesia", "code": "ID" },
                    { "name": "Iran, Islamic Republic Of", "code": "IR" },
                    { "name": "Iraq", "code": "IQ" },
                    { "name": "Ireland", "code": "IE" },
                    { "name": "Isle of Man", "code": "IM" },
                    { "name": "Israel", "code": "IL" },
                    { "name": "Italy", "code": "IT" },
                    { "name": "Jamaica", "code": "JM" },
                    { "name": "Japan", "code": "JP" },
                    { "name": "Jersey", "code": "JE" },
                    { "name": "Jordan", "code": "JO" },
                    { "name": "Kazakhstan", "code": "KZ" },
                    { "name": "Kenya", "code": "KE" },
                    { "name": "Kiribati", "code": "KI" },
                    { "name": "Korea, Democratic People\"S Republic of", "code": "KP" },
                    { "name": "Korea, Republic of", "code": "KR" },
                    { "name": "Kuwait", "code": "KW" },
                    { "name": "Kyrgyzstan", "code": "KG" },
                    { "name": "Lao People\"S Democratic Republic", "code": "LA" },
                    { "name": "Latvia", "code": "LV" },
                    { "name": "Lebanon", "code": "LB" },
                    { "name": "Lesotho", "code": "LS" },
                    { "name": "Liberia", "code": "LR" },
                    { "name": "Libyan Arab Jamahiriya", "code": "LY" },
                    { "name": "Liechtenstein", "code": "LI" },
                    { "name": "Lithuania", "code": "LT" },
                    { "name": "Luxembourg", "code": "LU" },
                    { "name": "Macao", "code": "MO" },
                    { "name": "Macedonia, The Former Yugoslav Republic of", "code": "MK" },
                    { "name": "Madagascar", "code": "MG" },
                    { "name": "Malawi", "code": "MW" },
                    { "name": "Malaysia", "code": "MY" },
                    { "name": "Maldives", "code": "MV" },
                    { "name": "Mali", "code": "ML" },
                    { "name": "Malta", "code": "MT" },
                    { "name": "Marshall Islands", "code": "MH" },
                    { "name": "Martinique", "code": "MQ" },
                    { "name": "Mauritania", "code": "MR" },
                    { "name": "Mauritius", "code": "MU" },
                    { "name": "Mayotte", "code": "YT" },
                    { "name": "Mexico", "code": "MX" },
                    { "name": "Micronesia, Federated States of", "code": "FM" },
                    { "name": "Moldova, Republic of", "code": "MD" },
                    { "name": "Monaco", "code": "MC" },
                    { "name": "Mongolia", "code": "MN" },
                    { "name": "Montserrat", "code": "MS" },
                    { "name": "Morocco", "code": "MA" },
                    { "name": "Mozambique", "code": "MZ" },
                    { "name": "Myanmar", "code": "MM" },
                    { "name": "Namibia", "code": "NA" },
                    { "name": "Nauru", "code": "NR" },
                    { "name": "Nepal", "code": "NP" },
                    { "name": "Netherlands", "code": "NL" },
                    { "name": "Netherlands Antilles", "code": "AN" },
                    { "name": "New Caledonia", "code": "NC" },
                    { "name": "New Zealand", "code": "NZ" },
                    { "name": "Nicaragua", "code": "NI" },
                    { "name": "Niger", "code": "NE" },
                    { "name": "Nigeria", "code": "NG" },
                    { "name": "Niue", "code": "NU" },
                    { "name": "Norfolk Island", "code": "NF" },
                    { "name": "Northern Mariana Islands", "code": "MP" },
                    { "name": "Norway", "code": "NO" },
                    { "name": "Oman", "code": "OM" },
                    { "name": "Pakistan", "code": "PK" },
                    { "name": "Palau", "code": "PW" },
                    { "name": "Palestinian Territory, Occupied", "code": "PS" },
                    { "name": "Panama", "code": "PA" },
                    { "name": "Papua New Guinea", "code": "PG" },
                    { "name": "Paraguay", "code": "PY" },
                    { "name": "Peru", "code": "PE" },
                    { "name": "Philippines", "code": "PH" },
                    { "name": "Pitcairn", "code": "PN" },
                    { "name": "Poland", "code": "PL" },
                    { "name": "Portugal", "code": "PT" },
                    { "name": "Puerto Rico", "code": "PR" },
                    { "name": "Qatar", "code": "QA" },
                    { "name": "Reunion", "code": "RE" },
                    { "name": "Romania", "code": "RO" },
                    { "name": "Russian Federation", "code": "RU" },
                    { "name": "RWANDA", "code": "RW" },
                    { "name": "Saint Helena", "code": "SH" },
                    { "name": "Saint Kitts and Nevis", "code": "KN" },
                    { "name": "Saint Lucia", "code": "LC" },
                    { "name": "Saint Pierre and Miquelon", "code": "PM" },
                    { "name": "Saint Vincent and the Grenadines", "code": "VC" },
                    { "name": "Samoa", "code": "WS" },
                    { "name": "San Marino", "code": "SM" },
                    { "name": "Sao Tome and Principe", "code": "ST" },
                    { "name": "Saudi Arabia", "code": "SA" },
                    { "name": "Senegal", "code": "SN" },
                    { "name": "Serbia and Montenegro", "code": "CS" },
                    { "name": "Seychelles", "code": "SC" },
                    { "name": "Sierra Leone", "code": "SL" },
                    { "name": "Singapore", "code": "SG" },
                    { "name": "Slovakia", "code": "SK" },
                    { "name": "Slovenia", "code": "SI" },
                    { "name": "Solomon Islands", "code": "SB" },
                    { "name": "Somalia", "code": "SO" },
                    { "name": "South Africa", "code": "ZA" },
                    { "name": "South Georgia and the South Sandwich Islands", "code": "GS" },
                    { "name": "Spain", "code": "ES" },
                    { "name": "Sri Lanka", "code": "LK" },
                    { "name": "Sudan", "code": "SD" },
                    { "name": "Suriname", "code": "SR" },
                    { "name": "Svalbard and Jan Mayen", "code": "SJ" },
                    { "name": "Swaziland", "code": "SZ" },
                    { "name": "Sweden", "code": "SE" },
                    { "name": "Switzerland", "code": "CH" },
                    { "name": "Syrian Arab Republic", "code": "SY" },
                    { "name": "Taiwan, Province of China", "code": "TW" },
                    { "name": "Tajikistan", "code": "TJ" },
                    { "name": "Tanzania, United Republic of", "code": "TZ" },
                    { "name": "Thailand", "code": "TH" },
                    { "name": "Timor-Leste", "code": "TL" },
                    { "name": "Togo", "code": "TG" },
                    { "name": "Tokelau", "code": "TK" },
                    { "name": "Tonga", "code": "TO" },
                    { "name": "Trinidad and Tobago", "code": "TT" },
                    { "name": "Tunisia", "code": "TN" },
                    { "name": "Turkey", "code": "TR" },
                    { "name": "Turkmenistan", "code": "TM" },
                    { "name": "Turks and Caicos Islands", "code": "TC" },
                    { "name": "Tuvalu", "code": "TV" },
                    { "name": "Uganda", "code": "UG" },
                    { "name": "Ukraine", "code": "UA" },
                    { "name": "United Arab Emirates", "code": "AE" },
                    { "name": "United Kingdom", "code": "GB" },
                    { "name": "United States", "code": "US" },
                    { "name": "United States Minor Outlying Islands", "code": "UM" },
                    { "name": "Uruguay", "code": "UY" },
                    { "name": "Uzbekistan", "code": "UZ" },
                    { "name": "Vanuatu", "code": "VU" },
                    { "name": "Venezuela", "code": "VE" },
                    { "name": "Viet Nam", "code": "VN" },
                    { "name": "Virgin Islands, British", "code": "VG" },
                    { "name": "Virgin Islands, U.S.", "code": "VI" },
                    { "name": "Wallis and Futuna", "code": "WF" },
                    { "name": "Western Sahara", "code": "EH" },
                    { "name": "Yemen", "code": "YE" },
                    { "name": "Zambia", "code": "ZM" },
                    { "name": "Zimbabwe", "code": "ZW" }],
                industries: [
                    { "name": "Software", "count": "178652" },
                    { "name": "Online & Wireless", "count": "60802" },
                    { "name": "Consumer", "count": "49164" },
                    { "name": "Media & Marketing Services", "count": "45439" },
                    { "name": "Finance", "count": "29995" },
                    { "name": "Healthcare", "count": "27481" },
                    { "name": "Managed Services & IT Consulting", "count": "23325" },
                    { "name": "Education", "count": "22460" },
                    { "name": "Human Capital", "count": "12709" }
                ],
                revenue: [
                    { "label": "Has Number", "count": "26059" },
                    { "label": "Under $5MM", "count": "8358" },
                    { "label": "$5MM - $10MM ", "count": "4797" },
                    { "label": "$10MM - $100MM ", "count": "8805" },
                    { "label": "$100MM - $500MM ", "count": "3092" },
                    { "label": "$500MM plus", "count": "3062" }
                ],
                revExpand: false,
                sliderRange: [0, 1000],
                sidebarOpen: false,
                pagerModel: { 'limit': 25, 'skip': 0 },
                resultsSet: {},
                query: {},
                searchRunning: false,
                advancedMode: true,
                hashTagModel: null,
                atTagModel: null,
                lobDistanceModel: null,
                seedObjectKey: 'company::6131',
                geoDistanceModel: { "key": "geoDistance", "filterName": "geoDistance", "name": null, "value": "{\"geoID\":38533,\"name\":\"TIJUANA - MEXICO\",\"lat\":0,\"lon\":0,\"distance\":\"5mi\"}", "valueName": "within 5mi of TIJUANA - MEXICO", "url": null, "isNotFilter": false },
                stateProvModel: { "key": "geoStateProv", "filterName": "geoStateProv", "name": null, "value": "[\"Alabama - USA\"]", "valueName": "Alabama - USA", "url": null, "isNotFilter": false },
                countryModel: { "key": "geoCountry", "filterName": "geoCountry", "name": null, "value": "[\"UGANDA\",\"Nigeria\"]", "valueName": "UGANDA,Nigeria", "url": null, "isNotFilter": false },
                ultimateParentModel: { "key": "ultimateParentID", "filterName": "ultimateParentID", "name": null, "value": "[103200566,103155504]", "valueName": "Johns Brothers Security,Ka Chun Garment Co., Ltd", "url": null, "isNotFilter": false },
                lobComboModel: { "key": "lobCombo", "filterName": "lobCombo", "name": null, "value": "{\"industry\":[{\"id\":\"351921\",\"name\":\"accounting firm\"}],\"attribute\":[{\"id\":\"352681\",\"name\":\"ASP\"}],\"audience\":[{\"id\":\"350763\",\"name\":\"academia\"},{\"id\":\"350820\",\"name\":\"games-master\"}],\"subject\":[{\"id\":\"354384\",\"name\":\"fable\"},{\"id\":\"354743\",\"name\":\"ablation\"}],\"lobDescents\":{},\"children\":[]}", "valueName": "academia,games-master - accounting firm - fable,ablation - ASP", "url": null, "isNotFilter": false },
                fullTextModel: { "key": "fulltext", "filterName": "fulltext", "name": null, "value": "{\"phrase\":[\"To be or not to be\"],\"mustMatch\":[\"shakespeare\"],\"mustNotMatch\":[\"spencer\"],\"shouldMatch\":[\"cervantes\"]}", "valueName": "phrase:To be or not to be", "url": null, "isNotFilter": false },
                ratedModel: { "key": "rated", "filterName": "rated", "name": null, "value": "{\"caseKey\":\"rated\",\"userDateSearchCase\":512,\"userIDs\":[2],\"dateRangeKey\":\"Last7Days\",\"dateRange\":{\"aggregateInterval\":3,\"description\":\"Last 7 Days\",\"endDate\":\"2021-10-18T23:59:00-04:00\",\"key\":\"Last7Days\",\"startDate\":\"2021-10-11T00:00:00-04:00\"},\"catIDs\":null,\"values\":[\"ThumbUp\"],\"notUserDate\":true}", "valueName": "keith.lubell Last 7 Days ,", "url": null, "isNotFilter": false },
                enteredModel: { "key": "entered", "filterName": "entered", "name": "Entered", "value": "{\"caseKey\":\"entered\",\"userDateSearchCase\":1,\"userIDs\":[2,167],\"dateRangeKey\":\"Last7Days\",\"dateRange\":{\"aggregateInterval\":3,\"description\":\"Last 7 Days\",\"endDate\":\"2021-10-18T23:59:00-04:00\",\"key\":\"Last7Days\",\"startDate\":\"2021-10-11T00:00:00-04:00\"},\"catIDs\":null,\"values\":[],\"notUserDate\":false}", "valueName": null, "url": null, "isNotFilter": false },
                leadAnalysisModel: { "key": "leadAnalysis", "filterName": "leadAnalysis", "name": null, "value": "[\"0\",\"2\",\"5\"]", "valueName": "Unknown, Large, Small, Target, Not Relevant", "url": null, "isNotFilter": false },
                hasGoodPersonModel: { "key": "hasGoodPerson", "filterName": "hasGoodPerson", "name": null, "value": "1", "valueName": "Has Contacts", "url": null, "isNotFilter": false },
                revenueRangeModel: { "key": "revenueRange", "filterName": "revenueRange", "name": null, "value": "5.000000E+006-1.000000E+007", "valueName": "$5MM - $10MM", "url": null, "isNotFilter": false },
                employeeSizeRangeModel: { "key": "employeeSizeRange", "filterName": "employeeSizeRange", "name": null, "value": "5.000000E+001-2.000000E+002", "valueName": "50 - 200", "url": null, "isNotFilter": false }
            }
        },
        computed: {
            ...mapGetters({
                user: 'userInfo/currentUser',
                panelDefs: 'search/panelDefs',
                filterDefs: 'search/filterDefs',
                aggPanelDefs: 'search/aggPanelDefs'
            }),
            selectedDocTypes() {
                if (this.query && this.query.criteria && this.query.criteria.docType && this.query.criteria.docType.value) {
                    if (_.isArray(this.query.criteria.docType.value))
                        return this.query.criteria.docType.value
                    if (this.query.criteria.docType.value.indexOf(']') > 0)
                        return JSON.parse(this.query.criteria.docType.value.toString())
                    return [this.query.criteria.docType.value.toString()]
                }
                return []
            },
            counts() {
                return this.resultsSet ? this.resultsSet.counts : []
            },
            docCounts() {
                return this.counts && this.counts.objectTypes ? this.counts.objectTypes : null
            },
            exTimes() {
                return this.resultsSet ? this.resultsSet.exTimes : null
            },
            criteria() {
                return this.query ? this.query.criteria : {}
            },
            availableFilters() {
                return this.resultsSet ? this.resultsSet.availableFilters : []
            },
            adHocMaps() {
                var adHocMaps = this.resultsSet && this.resultsSet.maps ? this.resultsSet.maps : {}
                if (this.availableFilters) {
                    if (this.availableFilters.includes('hasGoodPerson') && this.counts.hasGoodPersons) {
                        adHocMaps.hasGoodPersons =
                            _.map(this.counts.hasGoodPersons, function (value, key) {
                                return {
                                    id: (key == 'hasContacts').toString(),
                                    entry: key.replace(/([A-Z])/g, ' $1'),
                                    count: value,
                                    selected: false,//opt.selectedKey == key,
                                    key: (key == 'hasContacts').toString()
                                }
                            })
                    }
                    if (this.availableFilters.includes('acqTypeIDs') && this.counts.acqTypeIDs) {
                        adHocMaps.acqTypeIDs = _.filter(_.map(this.searchMaps.acqTypeIDs, function (value, key) {
                            return {
                                id: key,
                                entry: value.Key,
                                count: this.counts.acqTypeIDs[key],
                                selected: (value.Key == 'acquisitions'),
                                key: key,
                                level: value.Value
                            }

                        }), function (cnt) { return (cnt.count > 0) })
                    }
                    if (this.availableFilters.includes('excludeKeys') && this.criteria.excludeKeys && this.criteria.excludeKeys.value) {
                        var excludeKeys = JSON.parse(this.criteria.excludeKeys.value)
                        var excludeKeyNames = JSON.parse(this.criteria.excludeKeys.valueName)
                        adHocMaps.excludeKeys = []
                        for (var i = 0; i < excludeKeys.length; i++) {
                            adHocMaps.excludeKeys.push({
                                id: excludeKeys[i],
                                entry: excludeKeyNames[i],
                                count: 0,
                                selected: true,
                                url: this.urlFromKey(excludeKeys[i]),
                                key: excludeKeys[i]
                            })
                        }
                    }
                }
                return adHocMaps
            }
        },
        methods: {
            searchCountry(event) {
                var input = event.query
                if (input.length < 1) {
                    this.filteredCountriesBasic = this.countries
                }
                else {
                    this.filteredCountriesBasic = this.countries.filter(country => {
                        return country.name.toLowerCase()
                            .startsWith(input.toLowerCase())
                    })
                }
            },
            logEdit(ev) {
                console.log('edit', ev)
            },
            logDelete(ev) {
                console.log('delete', ev)
            },
            addSuggestion(ev)            {
                console.log('addSuggestion', ev)
            },
            onSuggestionsUpdated() {
                if (this.$refs.suggestionsPanel) {
                    this.$refs.suggestionsPanel.open()
                }
            },
            applyFilter(ev) {
                console.log('applyFilter', ev)
            }
        },
        mounted() {
            fetch("/assets/json/resultsSet.json")
                .then(response => response.json())
                .then(data => {
                    console.log('searchguide fetch', data)
                    this.resultsSet = data
                    this.query = _.cloneDeep(data.query)
                })
        }
    }
</script>