import axios from 'axios'
import _ from 'lodash'

const state = () => ({
})

// getters
const getters = {  
}

// actions
const actions = {
    fetchDashboard({ dispatch, state }, opt) {
        return new Promise((resolve, reject) => {
            if (opt.id) {
                axios.get('dashboard/' + opt.id)
                    .then(r => r.data)
                    .then(dash => {
                        if (dash) {
                            console.log('dash fetched')
                            dispatch('setDashboard', dash).then(() => {
                                resolve(state[opt.id])
                            });
                        }
                        else {
                            reject("Dashboard not found - ID: " + opt.id)
                        }
                    })
            }
            else
                reject('missing id')
        })
    },
    fetchDashboards({ dispatch }) {
        return new Promise((resolve) => {
            dispatch('userInfo/loadLayout', null, { root: true }).then(() => {
                dispatch('loadUserDashboards', null, { root: true }).then(() => {
                    resolve()
                })
            })
        })
    },
    setDashboard({ commit }, dashboard) {
        commit('SET_DASHBOARD', dashboard);
        commit('dashboardGroups/SET_DASHBOARD', dashboard, { root: true });
    },
    fetchCardData({ commit, state }, opt) {
        return new Promise((resolve, reject) => {
            if (opt && opt.dashboardID && state[opt.dashboardID] && state[opt.dashboardID].cards) {

                var cards = opt.cardIDs ? state[opt.dashboardID].cards.filter((card) => { return opt.cardIDs.includes(card.id) }) : state[opt.dashboardID].cards
                if (cards.length > 0) {
                    cards.forEach((cardData) => {
                        var card = _.cloneDeep(cardData);
                        card.data = {};
                        card.loaded = false;
                        commit('SET_DASHBOARDCARD', card)
                    })
                    var cardIDs = cards.map((card) => card.id)

                    var fetchUrl = 'dashboard/FetchCardData?ids=[' + cardIDs.join(',') + ']' + '&dashboardID=' + opt.dashboardID
                    axios.get(fetchUrl)
                        .then(r => r.data)
                        .then(response => {
                            console.log('card data fetched')
                            if (response.result.success && response.cards) {
                                response.cards.forEach((cardData) => {
                                    var card = _.cloneDeep(state[opt.dashboardID].findCard(cardData.id))
                                    if (cardData.hits) {
                                        var hits = []
                                        cardData.hits.forEach((jsonHit) => {
                                            if (jsonHit) {
                                                var hit = JSON.parse(jsonHit)
                                                if (hit.json) {
                                                    var jsonObj = JSON.parse(hit.json)
                                                    if (jsonObj) {
                                                        Object.keys(jsonObj).forEach((key) => {
                                                            if (!hit[key] && jsonObj[key])
                                                                hit[key] = jsonObj[key]
                                                        })
                                                    }
                                                }

                                                if (!hit.objectKey) hit.objectKey = hit.objectType + '::' + hit.id;

                                                hits.push(hit)
                                            }
                                        })
                                        cardData.hits = hits
                                    }
                                    card.loaded = true;
                                    card.data = cardData
                                    commit('SET_DASHBOARDCARD', card)
                                })
                                resolve({ dashboardID: opt.dashboardID, cards: response.cards } )
                            }
                            else {
                                cards.forEach((cardData) => {
                                    var card = _.cloneDeep(state[opt.dashboardID].findCard(cardData.id))
                                    card.loaded = true;
                                    card.data = { error: response.result.message }
                                    commit('SET_DASHBOARDCARD', card)
                                })
                                resolve({ dashboardID: opt.dashboardID, cards: response.cards })
                            }
                        })
                }
                else {
                    reject({ dashboardID: opt.dashboardID, message: 'no valid cards => dashboardID: ' + opt.dashboardID + ' / cardIDs: ' + opt.cardIDs.join(',') })
                }
            }
            else {
                reject({ dashboardID: opt.dashboardID, message: 'invalid dashboard ID: ' + opt.dashboardID })
            }
        })
    },
    moveDashboard({ state, dispatch }, opt) {
        return new Promise((resolve, reject) => {
            if (state[opt.id]) {
                axios.post('dashboard/MoveDashboard', { 'dashboardGroupID': state[opt.id].ownerID, 'id': opt.id, 'index': opt.index })
                    .then(r => r.data)
                    .then(result => {
                        if (result.success) {
                            dispatch('userInfo/loadLayout', null, { root: true }).then(() => {
                                dispatch('loadUserDashboards', null, { root: true }).then(() => {
                                    resolve()
                                })
                            })
                        }
                        else {
                            reject(result.message)
                        }
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            }
            else {
                reject('invalid dashboard ID: ' + opt.id)
            }
        })
    },
    deleteDashboard({ state, commit, dispatch }, opt) {
        return new Promise((resolve, reject) => {
            if (state[opt.id]) {
                axios.post('dashboard/DeleteDashboard', { 'dashboardGroupID': state[opt.id].ownerID, 'id': opt.id })
                    .then(r => r.data)
                    .then(result => {
                        if (result.success) {
                            commit('UNSET_DASHBOARD', opt.id)
                            dispatch('userInfo/loadLayout', null, { root: true }).then(() => {
                                dispatch('loadUserDashboards', null, { root: true }).then(() => {
                                    resolve()
                                })
                            })
                        }
                        else {
                            reject(result.message)
                        }
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            }
            else {
                reject('invalid dashboard ID: ' + opt.id)
            }
        })
    },
    renameDashboard({ dispatch, state }, opt) {
        return new Promise((resolve, reject) => {
            if (state[opt.id]) {
                axios.post('dashboard/RenameDashboard', { 'dashboardGroupID': state[opt.id].ownerID, 'id': opt.id, 'newName': opt.newName })
                    .then(r => r.data)
                    .then(result => {
                        if (result.success) {
                            var dash = _.cloneDeep(state[opt.id])
                            dash.name = opt.newName

                            dispatch('userInfo/loadLayout', null, { root: true }).then(() => {
                                dispatch('setDashboard', dash).then(() => {
                                    resolve()
                                })
                            })
                        }
                        else {
                            reject(result.message)
                        }
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            }
            else {
                reject('invalid dashboard ID: ' + opt.id)
            }
        })
    },
    copyDashboard({ state, dispatch, rootState }, opt) {
        return new Promise((resolve, reject) => {
            if (state[opt.id]) {
                var req = {
                    'dashboardGroupID': state[opt.id].ownerID,
                    'id': opt.id,
                    'newName': opt.newName,
                    'targetUserID': opt.targetUserID,
                    'index': 1,
                    'replaceFilters' : opt.replaceFilters
                }
                axios.post('dashboard/CopyDashboard', req)
                    .then(r => r.data)
                    .then(result => {
                        if (result.success) {
                            if (req.targetUserID == rootState.userInfo.user.userInfo.id) {
                                dispatch('userInfo/loadLayout', null, { root: true }).then(() => {
                                    dispatch('loadUserDashboards', null, { root: true }).then(() => {
                                        resolve(result)
                                    })
                                })
                            }
                        }
                        else {
                            reject(result.message)
                        }
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            } else {
                reject('invalid dashboard ID: ' + opt.id)
            }
        })
    },
    changeDateRangeDashboard({ state }, opt) {
        return new Promise((resolve, reject) => {
            console.log(opt)
            if (state[opt.id] && opt.dateRangeKey) {
                axios.post('dashboard/ChangeDashboardDateRange', { 'dashboardGroupID': state[opt.id].ownerID, 'id': opt.id, 'dateRangeKey': opt.dateRangeKey, 'changeLocked': false })
                    .then(r => r.data)
                    .then(result => {
                        if (result.success) {
                            resolve()
                        }
                        else {
                            reject(result.message)
                        }
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            }
            else {
                reject('invalid dashboard ID: ' + opt.id)
            }
        })
    },
    fetchCard({ commit }, opt) {
        return new Promise((resolve, reject) => {
            if (opt && opt.id && opt.dashboardID) {
                axios.get('dashboard/' + opt.dashboardID)
                    .then(r => r.data)
                    .then(dash => {
                        console.log('dash fetched')
                        if (dash) {
                            var card = dash.cards.find((card) => { card.id == opt.id })
                            if (card) {
                                commit('SET_DASHBOARDCARD', card)
                                resolve(card)
                            }
                            else
                                reject('card is missing id: ' + opt.id)
                        }
                        else
                            reject('dash is missing id: ' + opt.dashboardID)
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            }
            else {
                reject('invalid dashboard ID: ' + opt.dashboardID)
            }
        })
    },
    updateCard({ commit, state, dispatch }, opt) {
        return new Promise((resolve, reject) => {
            if (state[opt.ownerID] && state[opt.ownerID].findCard(opt.id)) {
                axios.post('dashboard/UpdateCard', opt)
                    .then(r => r.data)
                    .then(result => {
                        console.log(result)
                        if (result.success) {
                            var old_card = _.cloneDeep(state[opt.ownerID].findCard(opt.id));
                            var card = JSON.parse(result.jsonResult);
                            if (opt.dontFetchData) {
                                //if we're not fetching we can just use the old card's data
                                card.idx = old_card.idx;
                                card.data = old_card.data;

                                commit('SET_DASHBOARDCARD', card);
                                resolve()
                            }

                            else {
                                //otherwise call the fetch for the card
                                card.idx = old_card.idx;
                                card.data = {};

                                commit('SET_DASHBOARDCARD', card);
                                dispatch('fetchCardData', { 'dashboardID': opt.ownerID, 'cardIDs': [opt.id] })
                                    .then(() => {

                                        resolve()
                                    });
                            }
                        }
                        else {
                            reject(result.message)
                        }
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            }
            else {
                reject('invalid dashboard ID: ' + opt.ownerID + ' or invalid card ID: ' + opt.id)
            }
        })
    },
    createCards({ dispatch }, opt) {
        return new Promise((resolve, reject) => {
            var validCards = true;
            _.each(opt, function (card) {
                if ((!card.ownerID && !card.ownerName) || !card.name || !card.sourceID || !card.sourceObjectType)
                    validCards = false;
            });

            if (validCards) {
                axios.post('dashboard/CreateCards', opt)
                    .then(r => r.data)
                    .then(result => {
                        if (result.success) {
                            var newCard = JSON.parse(result.jsonResult);

                            dispatch('userInfo/loadLayout', null, { root: true });
                            dispatch('fetchDashboard', { id: newCard.ownerID }).then(() => {
                                resolve(newCard)
                            })
                        }
                        else
                            reject('Error creating cards')

                    }).catch(function (error) {
                        reject(error)
                    })
            }
            else
                reject('Invalid parameters');
        })
    },
    createCard(state, opt) {
        return new Promise((resolve, reject) => {
            if (opt && (opt.ownerID || opt.ownerName) && opt.name && opt.sourceID && opt.sourceObjectType) {
                axios.post('dashboard/CreateCard', opt)
                    .then(r => r.data)
                    .then(result => {
                        console.log(result)
                        resolve()
                    })
            }
            else
                reject('Invalid parameters');
        })
    },
    moveCard({ state, dispatch }, opt) {
        return new Promise((resolve, reject) => {
            if (state[opt.ownerID] && state[opt.ownerID].findCard(opt.id)) {
                var targetID = opt.newOwnerID || opt.newOwnerName ? opt.newOwnerID : opt.ownerID
                axios.post('dashboard/MoveCard', {
                    'id': opt.id,
                    'dashboardID': opt.ownerID,

                    'index': opt.index,
                    'targetDashboardID': targetID,
                    'newDashboardName': opt.newOwnerName
                })
                    .then(r => r.data)
                    .then(result => {
                        console.log(result)
                        if (result.success) {
                            var dashboardRes = JSON.parse(result.jsonResult);

                            //this will reorganize the cards inside the current dashboard
                            dispatch('setDashboard', dashboardRes.dashboard).then(() => {

                                //if we moved the card outside of the dashboard, re-fetch the new owner dashboard to get its new cards and counts
                                if (dashboardRes.dashboard.id != targetID) {

                                    //if the new owner is a new dashboard, find it in the dashList and then update the layout as well so it shows there
                                    if (targetID == null && dashboardRes.dashList) {
                                        var newDashboard = dashboardRes.dashList[dashboardRes.dashList.length - 1];
                                        targetID = newDashboard ? newDashboard.id : null;
                                        dispatch('userInfo/loadLayout', null, { root: true });
                                    }

                                    if (targetID) {
                                        dispatch('fetchDashboard', { id: targetID }).then(() => {
                                            resolve()
                                        })
                                    }
                                    else
                                        resolve();
                                }
                                else
                                    resolve()
                            })
                        }
                        else {
                            reject(result.message)
                        }
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            }
            else {
                reject('invalid dashboard ID: ' + opt.ownerID + ' or invalid card ID: ' + opt.id)
            }
        })
    },
    deleteCard({ state, dispatch }, opt) {
        return new Promise((resolve, reject) => {
            if (state[opt.ownerID] && state[opt.ownerID].findCard(opt.id)) {
                axios.post('dashboard/RemoveCard', {
                    'dashboardID': opt.ownerID,
                    'id': opt.id
                })
                    .then(r => r.data)
                    .then(result => {
                        console.log(result)
                        if (result.success) {
                            var dashboardRes = JSON.parse(result.jsonResult);

                            //this should go through the dashboard's cards in the state and just remove the one we deleted

                            dispatch('setDashboard', dashboardRes.dashboard);
                            resolve();
                        }
                        else {
                            reject(result.message)
                        }
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            }
            else {
                reject('invalid dashboard ID: ' + opt.ownerID + ' or invalid card ID: ' + opt.id)
            }
        })
    },
}

// mutations
const mutations = {
    SET_DASHBOARD(state, dashboard) {
        if (dashboard) {
            if (!dashboard.cards) dashboard.cards = [];

            dashboard.cards.forEach((card, index) => {
                card.idx = index
                card.data = state[dashboard.id] && state[dashboard.id].findCard(card.id) ? state[dashboard.id].findCard(card.id).data : {};
            })

            dashboard.findCard = function (id) { return dashboard.cards.find(c => c.id == id) }
            state[dashboard.id] = dashboard
        }
    },
    UNSET_DASHBOARD(state, dashboardID) {
        if (state[dashboardID]) {
            delete state[dashboardID]
        }
    },
    SET_DASHBOARDCARD(state, card) {
        if (!state[card.ownerID])
            state[card.ownerID] = {
                'cards': []
            }

        var cards = state[card.ownerID].cards;
        var index = cards.findIndex(c => c.id == card.id);

        if (index != -1) {
            if (card.idx != -1) {
                card.idx = index;
                cards[index] = card; //update
            }
            else
                cards = cards.filter(c => c.id != card.id); //delete
        }
        else if (card.idx != -1) {
            card.idx = cards.length;
            cards.push(card); //add
        }


        //update the state
        state[card.ownerID].cards = cards;
        state[card.ownerID].cards.sort((a, b) => a.idx > b.idx ? 1 : -1);
    },
    UPDATE_CARD_HIT(state, opt) {
        console.log('UPDATE_CARD_HIT', opt)
        state[opt.dashboardID].cards[opt.cardIDX].data.hits[opt.hitIDX] = opt.newHit
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
