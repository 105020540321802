<template>
  <section class="modal-body" :class="showOverflow ? 'showOverflow' : ''">
    <slot name="modal-body"></slot>
  </section>
</template>


<script>
    export default {
        name: 'ModalBody',
        components: {

        },
        props: {
            showOverflow: { type: Boolean, default: false }
        },
        data() {
            return {

            };
        },
    };
</script>

<style lang="scss" scoped>
    .showOverflow {
        overflow-x: visible;
        overflow-y: visible;
    }
</style>