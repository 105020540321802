import { ref } from 'vue'

export function isOpenSetup() {
    const isOpen = ref(false)
    const toggleIsOpen = () => {
        isOpen.value = !isOpen.value
    }
    return {
        // data
        isOpen,
        // methods
        toggleIsOpen
    }

}
