import axios from 'axios'
import _noop from 'lodash/noop'

const state = () => ({
    defaultFinancial: {
        'year': 0, 'isPublic': false, 'confidential': true, 'currency': { 'id': 1, 'entry': 'USD', 'toUSDRate': 1.0, 'rateDate': null, 'isoCode': 'USD' },
        'revenueUSD': null, 'revenueNative': null, 'revenueAnnualGrowth': null, 'ebitdaUSD': null, 'ebitdaNative': null, 'ebitdaRevenuePercentage': null,
        'ebitUSD': null, 'ebitNative': null, 'ebitRevenuePercentage': null, 'netIncomeUSD': null, 'netIncomeNative': null, 'netIncomeRevenuePercentage': null,
        'employeeSize': null, 'financialAccuracyID': 3, 'financialAccuracyName': 'Estimated', 'taxStatusID': 5, 'taxStatusName': 'C_CORP',
        'tickerSymbol': null, 'fiscalYearEndMonth': 12, 'tickerSymbolVerifyDate': null, 'tickerFailCount': null, 'outstandingShares': null,
        'stockPriceYearEndUSD': null, 'stockPriceYearEndNative': null, 'marketCapUSD': null, 'marketCapNative': null,
        'netDebtUSD': null, 'netDebtNative': null, 'preferredEquityUSD': null, 'preferredEquityNative': null, 
        'acl': [], 'active': true, 'changeDate': null, 'changeUserID': 0, 'changeUserName': null,
        'description': 'New Financial', 'entryDate': null, 'entryUserID': 0, 'entryUserName': null, 'id': 0, 'name': null, 'objectKey': null, 'objectType': 'financial10k',
        'ownerID': 0, 'verified': false, 'version': 0, 'delete': false, 'dirty': true
    },
    defaultCurrency: { 'id': 1, 'entry': 'USD', 'toUSDRate': 1.0, 'rateDate': null, 'isoCode': 'USD' },
    financialAccuracies: [ 'NA', 'Actual', 'Projected', 'Estimated', 'Guess' ],
    taxStatuses: ['NA', 'Unknown', 'SUB_S', 'REG_C', 'NFPROFIT', 'C_CORP', 'ESOP'],
    currencies: [],
    currenciesObject: {},
    currencyRate: {}
})


// getters
const getters = {
    financialAccuracies(state) { return state.financialAccuracies },
    financialAccuracyArray(state) { return state.financialAccuracies.map((fa, index) => { return { 'id': index, 'entry': fa } }) },
    taxStatuses(state) { return state.taxStatuses },
    taxStatusArray(state) { return state.taxStatuses.map((fa, index) => { return { 'id': index, 'entry': fa } }) },
    defaultFinancial(state) { return state.defaultFinancial },
    defaultCurrency(state) { return state.defaultCurrency },
    currencies(state) { return state.currencies },
    currenciesObject(state) { return state.currenciesObject }
}

// actions
const actions = {
    fetchCurrencies({ state, commit }, opt) {
        return new Promise((resolve, reject) => {
            if (state.currencies.length > 0)
                resolve(state.currencies)
            else {
                var url = 'FinancialData/Currencies' + (opt.active ? '?active=' + opt.active : '')
                axios.get(url)
                    .then(r => r.data)
                    .then(currencies => {
                        commit('SET_CURRENCIES', { active: opt.active, currencies: currencies })
                        resolve(currencies)
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    fetchCurrencyRate({ state, commit }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.currencyID || !opt.year || !opt.month || !opt.day) {
                reject('Bad Request Parameters')
                return
            }
            else {
                var dateKey = opt.year + '_' + opt.month + '_' + opt.day
                if (state.currencyRate[opt.currencyID] && state.currencyRate[opt.currencyID][dateKey])
                    resolve(state.currencyRate[opt.currencyID][dateKey])
                else {
                    var url = 'FinancialData/FetchCurrencyRate?currencyID=' + opt.currencyID + '&year=' + opt.year + '&month=' + opt.month + '&year=' + opt.day
                    axios.get(url)
                        .then(r => r.data)
                        .then(currencyRate => {
                            if (currencyRate) {
                                commit('SET_CURRENCYRATE', { currencyRate: currencyRate, dateKey: dateKey })
                                resolve(currencyRate)
                            }
                            else {
                                reject('currency rate not found')
                            }
                        }).catch(function (error) {
                            reject(error)
                        })
                }
            }

        })
    },
    fetchFinancials({ commit }, opt) {
        return new Promise((resolve, reject) => {
            var ownerKey = this.buildOwnerKey(opt)
            if (!ownerKey) {
                reject('Bad Request Parameters')
                return
            }
            else {
                var url = 'FinancialData/FetchFinancials?ownerKey=' + encodeURIComponent(ownerKey)
                axios.get(url)
                    .then(r => r.data)
                    .then(fins => {
                        commit('objectCache/SET_OBJECTFINANCIALS', { ownerKey: ownerKey, financial10Ks: fins }, { root: true })
                        resolve({ ownerKey: ownerKey, financial10Ks: fins })
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    saveFinancials({ commit }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt && !opt.financial10Ks) {
                reject('No Financial 10Ks in parameters')
                return
            }
            if (!opt.ownerKey)
                opt.ownerKey = 'company::' + opt.financial10Ks[0].ownerID
            var fins = { ownerKey: opt.ownerKey, financial10Ks: opt.financial10Ks}
            axios.post('FinancialData/UpdateFinancials', fins)
                .then(r => r.data)
                .then(results => {
                    if (results.success) {
                        var updateModel = JSON.parse(results.jsonResult)
                        results.financial10Ks = updateModel.financial10Ks
                        commit('objectCache/SET_OBJECTFINANCIALS', updateModel, { root: true })
                        resolve(results)
                    }
                    else
                        reject(results)
                }).catch(function (error) {
                    reject(error)
                })
        })
    },
    tickerSearch({ state }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.term) {
                reject('Bad Request Parameters')
                return
            }
            else {
                var url = 'Company/TickerSearch/?name=' + encodeURIComponent(opt.term)
                axios.get(url)
                    .then(r => r.data)
                    .then(results => {
                        resolve(results)
                    }).catch(function (error) {
                        reject(error)
                    })
            }
            _noop(state)
        })
    },
    tickerUpdate({ state }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.tickerSymbol) {
                reject('Bad Request Parameters')
                return
            }
            else {
                var url = 'Company/TickerUpdate/?ticker=' + encodeURIComponent(opt.tickerSymbol)
                axios.get(url)
                    .then(r => r.data)
                    .then(results => {
                        resolve(results)
                    }).catch(function (error) {
                        reject(error)
                    })
            }
            _noop(state)
        })
    },
    stockHistoryUpdate({ state }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.tickerSymbol || !opt.id) {
                reject('Bad Request Parameters')
                return
            }
            else {
                var url = '/Company/StockHistoryUpdate/?id='+opt.id+'&ticker=' + encodeURIComponent(opt.tickerSymbol)
                axios.get(url)
                    .then(r => r.data)
                    .then(results => {
                        resolve(results)
                    }).catch(function (error) {
                        reject(error)
                    })
            }
            _noop(state)
        })
    }
}



// mutations
const mutations = {
    INITIALIZE_STORE(state) {
        state.currencies = this.fetchLocal('financials_currencies',[])
        state.currenciesObject = this.fetchLocal('financials_currenciesObject')
       // console.log('fetching financials state from localstorage', state.currencies, state.currenciesObject)
    },
    DESTROY_STORE(state) {
        this.saveLocal('financials_currencies', null)
        this.saveLocal('financials_currenciesObject', null)
        state.currencies = []
        state.currenciesObject = {}
        //console.log('destroying financials state in localstorage')
    },
    SET_CURRENCIES(state, opt) {
        state.currencies = opt.currencies
        state.currenciesObject = {}
        state.currencies.forEach((c) => {
            state.currenciesObject[c.id] = c
        })
        this.saveLocal('financials_currencies', state.currencies)
        this.saveLocal('financials_currenciesObject', state.currenciesObject)
    },
    SET_CURRENCYRATE(state, opt) {
        //console.log('SET_CURRENCYRATE', opt)
        if (!state.currencyRate[opt.currencyRate.id])
            state.currencyRate[opt.currencyRate.id] = {}
        state.currencyRate[opt.currencyRate.id][opt.dateKey] = Object.freeze(opt.currencyRate)
        if (state.currenciesObject[opt.currencyRate.id].toUSDRate == 0)
            state.currenciesObject[opt.currencyRate.id].toUSDRate = opt.currencyRate.toUSDRate
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
