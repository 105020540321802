<template>
    <tag-editor v-model="filterValue" 
                :fullEditor="useFullEditor" 
                :inpopup="inpopup" 
                :showSave="false" 
                :ref="(el)=>setItemRef(el,'tagEditor')" />
</template>
<script>
    import { computed, onBeforeUpdate } from 'vue'
    import { searchFilterProps, searchFilterEmits, searchFilterSetup } from '../../composables/SearchTools.js'
    import { useItemRefs } from '../../composables/ModelWrapper.js'
    import _ from 'lodash'

    import TagEditor from '../Comment/TagEditor.vue'

    export default {
        name: 'TagFilter',
        components: { TagEditor },
        props: {
            ...searchFilterProps(null, false),
            tagType: { type: String, default: 'hashTag' }
        },
        emits: [...searchFilterEmits()],
        setup(props, { emit }) {
            // tools
            const { itemRefs, setItemRef, itemRefsReset } = useItemRefs()

            // models
            const { model } = searchFilterSetup(props, emit)

            // computed
            const useFullEditor = computed(() => props.tagType == 'hashTag')
            const filterValue = computed({
                get: () => {
                    var filterVal = props.modelValue && props.modelValue.value ? JSON.parse(props.modelValue.value) :
                        [{ name: props.tagType == 'hashTag' ? '' : 'atTag', values: [], userID: 0, date: new Date() }]
                    return _.isArray(filterVal) ? filterVal[0] : filterVal
                },
                set: (newVal) => {
                    var newModel = model.value
                    if ((props.tagType == 'hashTag' && newVal.name) || (props.tagType == 'atTag' && newVal.values && newVal.values[0].ownerID && newVal.values[0].ownerTypeID)) {
                        newModel.value = JSON.stringify([newVal])
                        newModel.valueName = itemRefs.value.tagEditor.tagText
                        newModel.hasValue = true
                    }
                    else {
                        newModel.value = null
                        newModel.valueName = null
                        newModel.hasValue = false
                    }
                    model.value = newModel
                    //console.log('filterValue-set', newModel, newVal)
                }
            })

            // methods
            const updateTag = (newVal) => {
                _.noop('updateTag', newVal)
            }

            // lifecycle
            onBeforeUpdate(() => { itemRefsReset() })

            return {
                // models
                model,
                // tools
                itemRefs, setItemRef, itemRefsReset,
                // computed
                useFullEditor, filterValue,
                // methods
                updateTag
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
