<template>
    <header class="main-header" :class="themeClass" >
        <div class="main-header-wrapper" :class="{ 'is-collapsed': stateCollapsed }">
            <div class="main-brand full-brand">
                <router-link :to="{ path: '/' }">
                    <img src="../assets/images/MandaSoft_Logo_ForDarkBackground.svg" width="150" height="100" />
                </router-link>
            </div>
            <div class="main-brand icon">
                <router-link :to="{ path: '/' }">
                    <img src="../assets/images/MandaSoft_AppIcon.svg" width="32" height="32" />
                </router-link>
            </div>
            <div class="menu-collapse-toggle" @click="toggleMenu"><i :class="(stateCollapsed ? 'far' : 'fas') + ' fa-thumbtack'"></i></div>
            <main-menu></main-menu>
        </div>
    </header>
</template>

<script>
    import { ref, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { getLocalStorageTools } from '../composables/LocalStorageTools.js'

    import MainMenu from './Menus/MainMenu.vue'
    import { RouterLink } from 'vue-router'
    export default {
        name: 'MainHeader',
        components: {
            MainMenu, RouterLink
        },
        setup() {
            // tools
            const store = useStore()
            const { fetchLocal, saveLocal } = getLocalStorageTools()
            const storageKey = 'mainheader_toggleState'

            // data
            const stateCollapsed = ref(true)
            // computed
            const userLayout = computed(() => store.state.userInfo.userLayout)
            const themeClass = computed(() =>
                'theme-' + (userLayout.value ? userLayout.value.skinColor : 'dark')
                + (stateCollapsed.value ? ' is-collapsed' : '')
            )

            // methods
            const toggleMenu = () => {
                stateCollapsed.value = !stateCollapsed.value
                saveLocal(storageKey, stateCollapsed.value)
                console.log('toggleMenu', stateCollapsed.value, themeClass.value)
            }

            // lifecycle
            onMounted(() => {
                //stateCollapsed.value = true
                stateCollapsed.value = fetchLocal(storageKey, true)
            })

            return {
                // data
                stateCollapsed,
                // computed
                userLayout, themeClass,
                // methods
                toggleMenu, fetchLocal, saveLocal
            }
        }
    }
</script>

<style scoped>
    
</style>