<template>
    <ul class="list-table" :class="name">
        <slot />
    </ul>
</template>

<script>
    export default {
        name: 'ListTable',
        props: {
            name: String
        },
        data: function () {
            return {
                
            }
        },
        provide: {
            type: 'table'
        },
    }
</script>

<style scoped lang="scss">
</style>