<template>
    <div class="radio" :class="radioClasses">
        <input type="radio" :id="realID" :name="name" :value="boundValue" v-model="radioModel" @input="$emit('update:modelValue', $event.target.value)">
        <label :for="realID">
            <template v-if="label">
                {{ label }}
            </template>
        </label>
    </div>
</template>

<script>
    import RandomID from '../../../mixins/randomID.js'

    export default {
        name: 'Radio',
        mixins: [RandomID],
        props: {
            label: String,
            name: String,
            radioValue: String,
            modelValue: String,
            size: {
                type: String,
                default: 'default'
            }
        },
        emits: ['update:modelValue'],
        data() {
            return {
                radioModel: this.modelValue
            }
        },
        computed: {
            boundValue() {
                if (this.radioValue) return this.radioValue
                return this.name
            },
            radioClasses() {
                return this.size !== 'default' ? this.size : null
            }
        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>
</style>