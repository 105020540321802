<template>
    <div :class="tagGroupClass" :title="title">
        <span :class="groupLabelClass" @click="handleLabelClick">{{ name }} <span v-if="count" class="badge">({{ count }})</span></span>
        <tag-basic v-for="(t, tindex) in validSubTags" 
                   :key="t.key ?? (t.name + tindex)" 
                   :type="t.type ?? 'keyword'" 
                   :name="t.name" 
                   :label="t.label"
                   :title="t.title"
                   :icon="t.icon"
                   :href="t.href"
                   :count="t.count"
                   :class="t.class"
                   :actions="t.actions"
                   @button-clicked="handleBasicTagClick($event, t)"
                   />
        <div class="group-actions" v-if="actions">
            <btn v-if="actions.includes('switch')" type="icon" icon="random" @click="$emit('button-clicked', 'switch')"></btn>
            <btn v-if="actions.includes('add')" type="icon" icon="plus" @click="$emit('button-clicked', 'add')"></btn>
            <btn v-if="actions.includes('edit') && defaultAction != 'edit'" type="icon" icon="edit" @click="$emit('button-clicked', 'edit')"></btn>
            <btn v-if="actions.includes('remove')" type="icon" icon="times" @click="$emit('button-clicked', 'remove')"></btn>
        </div>
    </div>
</template>

<script> 
    import { computed } from 'vue'
    import TagBasic from './TagBasic.vue'

    export default {
        name: 'TagGroup',
        components: { TagBasic },
        props: {
            name: String,
            title: { type: String },
            type: { type: String, default: 'default' },
            actions: { type: Array },
            disableDefaultAction: { type: Boolean, default: false },
            subTags: { type: Array },
            count: { type: String, default: null }
        },
        emits: ['button-clicked','subtag-clicked'],
        setup(props, { emit, attrs }) {
            // computed
            const validSubTags = computed(() => props.subTags ? props.subTags.filter((tag) => tag.name || tag.label) : [])
            const defaultAction = computed(() => props.actions ? props.actions[0] : null)
            const groupLabelClass = computed(() => !props.disableDefaultAction && defaultAction.value ? 'group-label group-label-action' : 'group-label')
            const tagGroupClass = computed(() => {
                var tgClasses = ['tag-group']
                if (!props.disableDefaultAction && defaultAction.value)
                    tgClasses.push('tag-group-action')
                if (attrs.class)
                    tgClasses.push(attrs.class)
                return tgClasses
            })

            // methods
            const handleLabelClick = () => {
                if (!props.disableDefaultAction && defaultAction.value)
                    emit('button-clicked', defaultAction.value)
            }
            const handleBasicTagClick = (action, subTag) => {
                //console.log('handleBasicTagClick', action, subTag, (subTag.key ?? subTag.name))
                if (action && subTag) {
                    emit('subtag-clicked', { action: action, tag: (subTag.key ?? subTag.name) })
                }
            }

            return {
                // computed
                validSubTags, defaultAction, groupLabelClass, tagGroupClass,
                // methods
                handleLabelClick, handleBasicTagClick
            }
        }
    }
</script>

<style scoped lang="scss">
    span.group-label-action {
        cursor: pointer;
        //color: #415FFF
    }
    .tag-group-action {
        //border-color: #415FFF
    }
</style>