import axios from 'axios'

const state = () => ({
    emailTemplates: [],
})


// getters
const getters = {
    emailTemplates(state) {
        return state.emailTemplates
    }
}

// actions
const actions = {
    loadEmailTemplates({  commit }) {
        return new Promise((resolve, reject) => {
            //console.log('loadEmailTemplates', state.emailTemplates)
            //if (state.emailTemplates && state.emailTemplates.length)
              //  resolve(state.emailTemplates)
            //else {
                axios.get('LetterWizard/MostRecentTemplates')
                    .then(r => r.data)
                    .then(templates => {
                        //console.log('LetterWizard/MostRecentTemplates', templates)
                        commit('SET_EMAILTEMPLATES', templates)
                        resolve(templates)
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            //}
        })
    }
}


const mutations = {
    INITIALIZE_STORE(state) {
        state.emailTemplates = this.fetchLocal('letterwizard_emailTemplates', [])
       // console.log('fetching letterwizard state from localstorage', state.emailTemplates)
    },
    DESTROY_STORE(state) {
        this.saveLocal('letterwizard_emailTemplates', null)
        state.emailTemplates = []
        //console.log('destroying search state in localstorage')
    },
    SET_EMAILTEMPLATES(state, emailTemplates) {
        state.emailTemplates = emailTemplates
        this.saveLocal('letterwizard_emailTemplates', emailTemplates)
      //  console.log('saved emailTemplates state in localstorage')
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
