<template>
    <ul class="list-options">
        <li v-for="(rValue, idx) in radioValues" :key="idx">
            <div class="radio" :class="radioClasses">
                <input type="radio" :id="realID + '-' + idx" :name="name" :value="rValue" v-model="radioModel" @input="selected(rValue)">
                <label :for="realID + '-' + idx">
                    {{ (labels ? labels[idx] : rValue) }}
                </label>
            </div>
        </li>
    </ul>
    <slot />
</template>

<script>
    import RandomID from '../../../mixins/randomID.js'

    export default {
        name: 'RadioList',
        mixins: [RandomID],
        components: { },
        props: {
            name: String,
            labels: Array,
            radioValues: Array,
            modelValue: String,
            size: {
                type: String,
                default: 'default'
            },
            makeHorizontal: {
                type: Boolean,
                default: false
            }
        },
        emits: ['update:modelValue', 'selected'],
        data() {
            return {
                radioModel: this.modelValue
            }
        },
        computed: {
            boundValue() {
                if (this.radioValue) return this.radioValue
                return this.name
            },
            radioClasses() {
                return this.size !== 'default' ? this.size : null
            }
        },
        methods: {
            selected: function (v) {
                this.$emit('selected', v)
                this.$emit('update:modelValue', v)
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>