<template>
    <template v-if="filterList && filterList.length > 0">
        <checkbox-list v-model="filterList" idField="id" labelField="label" selectField="selected" size="xsmall" />
    </template>   
</template>

<script>
    import { searchFilterProps, searchFilterEmits, searchFilterSetup } from '../../composables/SearchTools.js'

    import CheckboxList from '../ui/forms/CheckboxList.vue'

    export default {
        name: 'CheckListFilter',
        components: { CheckboxList },
        props: {
            ...searchFilterProps()
        },
        emits: [...searchFilterEmits()],
        setup(props, { emit }) {

            // models
            const { model, filterDef, filterList } = searchFilterSetup(props, emit)

            // methods
            const getLabel = (listItem) => {
                return listItem.entry + (!filterDef.value.hideCounts ? '(' + listItem.count + ')' : '')
            }

            return {
                // models
                model,
                // computed
                filterDef, filterList,
                // methods
                getLabel
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
