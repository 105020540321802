import $ from 'jquery'
import { computed } from 'vue'

export function scrollToTop() {
    $('html, body').animate({ scrollTop: 0 }, 'fast')
}

export function scrollToElement(element) {
    // only works on HTML refs
    if (element) {
        var top = element.offsetTop
        $('html, body').animate({ scrollTop: top }, 'fast')
    }
}
export function scrollIntoView(selector) {
    if ($(selector)) {
       setTimeout(function () {
            $(selector)[0].scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 200);
    }
}
export function scrollToElementTop(selector, top) {
    if ($(selector)) {
        setTimeout(function () {
            $(selector).animate({ scrollTop: top ? top : 0 }, 'fast')
        }, 200);
    }
}

export function computedSelector(props, name) {
    return computed(() => {
        if (!props[name]) return null

        if (props[name].id)
            return '#' + props[name].id
        else if (props[name].className)
            return props[name].tagName + '.' +
                (props[name].className.indexOf(' ') < 0 ? props[name].className : props[name].className.join('.'))
        return null
    })
}
export function computedSelectorRef(templateRef) {
    return computed(() => {
        if (!templateRef || !templateRef.value) return null

        if (templateRef.value.id)
            return '#' + templateRef.value.id
        else if (templateRef.value.className)
            return templateRef.value.tagName + '.' +
                (templateRef.value.className.indexOf(' ') < 0 ? templateRef.value.className : templateRef.value.className.join('.'))
        return null
    })
}