import axios from 'axios'
import _ from 'lodash'
import _noop from 'lodash/noop'


const state = () => ({
    commentLog: {},
    tagLog: {},
    tagWords: {
        'attag': {
            'unfiltered': {}, 'user': {}, 'company': {}, 'person': {}, 'acquisition': {} },
        'hashtag': { 'unfiltered': {} }
    },
    tagFieldMap: { 'hashtag': 'name', 'attag': 'value' },
    commentTypes: [
        { entry: 'Contact Made', id: 1, icon: 'fa fa-comments' },
        { entry: 'N D A Signed', id: 6, icon: 'fas fa-file-signature' },
        { entry: 'Message Left', id: 2, icon: 'fa fa-comment' },
        { entry: 'Deal Note', id: 4, icon: 'fa fa-puzzle-piece' },
        { entry: 'General Info', id: 5, icon: 'fa fa-info' },
    ]
})

// getters
const getters = {
    commentLog(state) { return state.commentLog },
    tagLog(state) { return state.tagLog },
    tagWords(state) { return state.tagWords },
    tagFieldMap(state) { return state.tagFieldMap },
    commentTypes(state) { return state.commentTypes }
}

// actions
const actions = {
    fetchComments({ commit }, opt) {
        return new Promise((resolve, reject) => {
            //we need either an ownerKey or an objectKey 
            if (!opt || (!opt.ownerKey && !(opt.obj && opt.obj.objectKey))) {
                reject({ fatal: false, message: 'Bad Comment fetch Options' })
                return
            }

            if (!opt.ownerKey)
                opt.ownerKey = opt.obj.objectKey

            var commentLogState = { 'total': 0, 'events': [], 'ownerKey': opt.ownerKey, 'preparedEvents': [], 'groupedEvents': [], 'sessionEvents': [], 'fetched': false, 'exTime': 0.0 }

            //this is in case we need to use the object's comments
            /*if (opt.obj && opt.obj.comments && opt.obj.comments.length > 0 && !opt.fetch) { 
                commentLogState.total = opt.obj.comments.length
                commentLogState.events = _.sortBy(opt.obj.comments, ['date']).reverse()
                commentLogState.preparedEvents = this.prepareEvents({ 'events': commentLogState.events, 'eventSource': 'comments', 'fetchedEvents': false, 'obj': opt.obj })
                commentLogState.groupedEvents = this.groupEvents({ 'events': commentLogState.preparedEvents, 'eventView': 'category' })

                commit('SET_COMMENTLOG', commentLogState)
                //commit('objectCache/SET_OBJECTPROP', { ownerKey: opt.ownerKey, key: 'comments', value: commentLogState.events }, { root: true })
                resolve({ 'fetched': false, 'total': commentLogState.total })
                return
            }*/

            var numKey = this.splitKey(opt.ownerKey)
            var url = "Correspondence/Communications/?ownerID=" + numKey.id + "&ownerTypeID=" + numKey.objectTypeID + "&justComments=true&search=true" + (opt.limit ? "&limit=" + opt.limit : "");
            //var url = "Comment/GetCommentsHits/?ownerID=" + numKey.id + "&ownerTypeID=" + numKey.objectTypeID + "&justComments=true&search=true" + (opt.limit ? "&limit=" + opt.limit : "");
            console.log('fetching comments for ' + opt.ownerKey + "...")
            axios.get(url)
                .then(r => r.data)
                .then(results => {
                    console.log('fetched comments for ' + opt.ownerKey)
                    commentLogState.fetched = true
                    if (results && results.resultsSet) {
                        commentLogState.exTime = results.resultsSet.exTimes ? results.resultsSet.exTimes.total : -1
                        commentLogState.total = results.resultsSet.total
                        commentLogState.events = [];
                        if (results.resultsSet.hits) {
                            results.resultsSet.hits.forEach((hit) => {
                                commentLogState.events.push(JSON.parse(hit.json))
                            })
                        }
                        commentLogState.events = _.sortBy(commentLogState.events, ['date']).reverse()
                        commentLogState.preparedEvents = this.prepareEvents({'events': commentLogState.events, 'eventSource': 'comments', 'fetchedEvents': true, 'obj': opt.obj})
                        commentLogState.groupedEvents = commentLogState.events

                        commit('SET_COMMENTLOG', commentLogState)
                        //commit('objectCache/SET_OBJECTPROP', { ownerKey: opt.ownerKey, key: 'comments', value: commentLogState.events }, { root: true })
                        resolve({ 'fetched': true, 'total': commentLogState.total })
                        return
                    }
                    else {
                        commit('SET_COMMENTLOG', commentLogState)
                        //commit('objectCache/SET_OBJECTPROP', { ownerKey: opt.ownerKey, key: 'comments', value: commentLogState.events }, { root: true })
                        reject({ fatal: true, message: "comments not found. id: " + opt.ownerKey })
                    }

                }).catch(function (error) {
                    commentLogState.fetched = true
                    commit('SET_COMMENTLOG', commentLogState)
                    //commit('objectCache/SET_OBJECTPROP', { ownerKey: opt.ownerKey, key: 'comments', value: commentLogState.events }, { root: true })
                    reject({ fatal: true, message: error })
                })
        })
    },
    fetchTags({ commit }, opt) {
        return new Promise((resolve, reject) => {
            console.log('fetch tags', opt)

            if (!(opt && opt.obj)) {
                reject({ fatal: false, message: 'Bad tag fetch Options' })
                return
            }

            if (!opt.ownerKey)
                opt.ownerKey = opt.obj.objectKey

            var events = [];
            //check users
            if (opt.obj.users) {
                events = opt.obj.users.filter((user) => { return this.eventSettings.flagMap[user.biCategory] })
            }
            //check tags
            if (opt.obj.tags) {
                var tags = _.cloneDeep(opt.obj.tags);
                tags.forEach((tag) => {
                    events.push(this.tagToFlagEvent(tag))
                })
            }

            //only add to the store if the obj has any tags
            if (events.length > 0) {
                var tagLogState = { 'total': 0, 'events': [], 'ownerKey': opt.ownerKey, 'preparedEvents': [], 'groupedEvents': [], 'sessionEvents': [], 'fetched': false, 'exTime': 0.0 }
                tagLogState.events = _.sortBy(events, ['date']).reverse()
                tagLogState.total = tagLogState.events.length
                tagLogState.preparedEvents = this.prepareEvents({ 'events': tagLogState.events, 'eventSource': 'tagsflags', 'fetchedEvents': false, 'obj': opt.obj })
                tagLogState.groupedEvents = this.groupEvents({ 'events': tagLogState.preparedEvents, 'eventView': 'category' })
                commit('SET_TAGLOG', tagLogState)
                resolve({ 'fetched': false, 'total': tagLogState.total })
            }
            else
                resolve({ 'fetched': false })
        })
    },
    fetchMandaInterest({ commit }, opt) {
        return new Promise((resolve, reject) => {
            var ownerKey = this.buildOwnerKey(opt)
            if (!ownerKey) {
                reject('Bad Request Parameters')
                return
            }
            else {
                var url = 'Comment/FetchMandaInterest?ownerKey=' + encodeURIComponent(ownerKey)
                axios.get(url)
                    .then(r => r.data)
                    .then(mandaInterest => {
                        commit('objectCache/SET_OBJECTPROP', { ownerKey: ownerKey, key: 'mandaInterest', value: mandaInterest }, { root: true })
                        resolve({ ownerKey: ownerKey, mandaInterest: mandaInterest })
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    saveMandaInterest({ commit }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.mandaInterest) {
                reject('No M&A interest in parameters')
                return
            }
            if (!opt.ownerKey)
                opt.ownerKey = 'company::' + opt.mandaInterest.id
            var req = { ownerKey: opt.ownerKey, mandaInterest: opt.mandaInterest }
            axios.post('Comment/UpdateMandaInterest', req)
                .then(r => r.data)
                .then(results => {
                    if (results.success) {
                        var updatedModel = JSON.parse(results.jsonResult)
                        results.mandaInterest = updatedModel.mandaInterest
                        commit('objectCache/SET_OBJECTPROP', { ownerKey: opt.ownerKey, key: 'mandaInterest', value: updatedModel.mandaInterest }, { root: true })
                        resolve(results)
                    }
                    else
                        reject(results)
                }).catch(function (error) {
                    reject(error)
                })
        })
    },
    fetchComment({ state }, opt) {
        return new Promise((resolve, reject) => {
            var numKey = this.splitKey(opt.objectKey);
            var url = 'Comment/' + (opt.id ? + opt.id : '0/?ownerID=' + numKey.id + '&ownerTypeID=' + numKey.objectTypeID)
            axios.get(url)
                .then(r => r.data)
                .then(result => {
                    if (result) {
                        result.ownerKey = opt.objectKey;
                        resolve(result);
                    }
                    else
                        reject({ message: "Error fetching the " + (opt.id ? 'comment' : 'template') });
                });
            _noop(state)
        });
    },
    saveComment({ dispatch }, opt) {
        return new Promise((resolve, reject) => {
            if (opt.id == 0) {
                console.log('saving comment...', opt)
                axios.post('Comment/', opt)
                    .then(r => r.data)
                    .then(result => {
                        console.log('saved comment', result, opt)
                        if (result) {
                            var comment = JSON.parse(result.jsonResult);
                            dispatch('setCommentByOwner', comment).then(() => {
                                resolve(result)
                            })
                        }
                        else
                            reject({ message: "Error saving new Comment" });
                    }).catch((error) => {
                        reject(error)
                    })
            }
            else {
                axios.put('Comment/' + opt.id + '/', opt)
                    .then(r => r.data)
                    .then(result => {
                        if (result) {
                            var comment = JSON.parse(result.jsonResult);
                            dispatch('setCommentByOwner', comment).then(() => {
                                resolve(result)
                            })
                        }
                        else
                            reject({ message: "Error saving Comment" });
                    }).catch((error) => {
                        reject(error)
                    })
            }
        })
    },
    deleteComment({ dispatch }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.id) {
                reject('no comment id')
                return
            }

            axios.delete('Comment/' + opt.id + '/', opt)
                .then(r => r.data)
                .then(result => {
                    if (result && result.success) {
                        opt.delete = true;
                        dispatch('setCommentByOwner', opt).then(() => {
                            resolve(result)
                        })
                    }
                    else
                        reject({ message: "Error deleting Comment" });
                }).catch((error) => {
                    reject(error)
                })
        })
    },
    getTagWords({ state, commit }, opt) {
        return new Promise((resolve, reject) => {
            var url = 'Search/TagSearch/?term=' + encodeURIComponent(opt.word) + '&tagType=' + opt.tagType
                + '&limit=' + (opt.limit ? opt.limit : '5')
                + (opt.expectSign ? '&expectSign=' + opt.expectSign.toString() : '')
                + (opt.filterObjectType ? '&filterObjectType=' + opt.filterObjectType : '')
            console.log('getTagWords', url)
            axios.get(url)
                .then(r => r.data)
                .then(tags => {
                    console.log('getTagWords', tags)
                    var exists = tags.find((tag) => { return opt.word.toLowerCase() == tag[state.tagFieldMap[opt.tagType]].toLowerCase() })

                    //do not allow new @ tags
                    if (!exists && opt.allowNewTag) {
                        var newTag = { tagType: opt.tagType, newTag: true, hitCount: 0 }
                        newTag[state.tagFieldMap[opt.tagType]] = opt.word
                        tags.unshift(newTag)
                    }
                    commit('SET_TAGWORDS', { tagType: opt.tagType, word: opt.word, tags: tags, filterObjectType: opt.filterObjectType })
                    resolve(tags)
                }).catch(function (error) {
                    reject(error)
                })
        })
    },
    // Legacy API
    saveCommentFull({ dispatch, state }, opt) {
        return new Promise((resolve, reject) => {
            axios.post('Comment/CommentInfo/', opt)
                .then(r => r.data)
                .then(results => {
                    if (results.success) {
                        if (opt.ownerKey) {
                            dispatch('fetchComments', { ownerKey: opt.ownerKey }).then(() => {
                                results.fetchedOther = true;
                                resolve(results)
                            });
                        }
                        else
                            resolve(results)
                    }
                    else
                        reject(results)

                }).catch(function (error) {
                    reject(error)
                })
            _noop(state)
        });
    },
    saveCommentCompany({ state }, opt) {
        return new Promise((resolve, reject) => {
            axios.post('Comment/UpdateCompany/', opt)
                .then(r => r.data)
                .then(results => {
                    if (results.success) {
                        resolve(results)
                    }
                    else
                        reject(results.message)
                }).catch(function (error) {
                    reject(error)
                })
            _noop(state)
        });
    },
    setCommentByOwner({ state, commit }, opt) {
        return new Promise((resolve) => {
            if (opt && opt.owners) {
                var ownerKeys = this.parseOwnersToKeys(opt.owners, true, 'ownerActive')
                ownerKeys.forEach((ownerKey) => {
                    var storeKey = this.encodeOwnerKey(ownerKey)
                    var commentLogState = _.cloneDeep(state.commentLog[storeKey])
                    if (commentLogState) {
                        if (opt.delete)
                            commentLogState.events = commentLogState.events.filter(function (c) { return c.id != opt.id });
                        else {
                            var existingIDX = commentLogState.events ? commentLogState.events.findIndex((o) => o.id == opt.id) : -1
                            if (existingIDX < 0) {
                                commentLogState.events.push(opt)
                                commentLogState.total++
                            }
                            else {
                                commentLogState.events[existingIDX] = opt
                            }
                        }
                        commentLogState.events = _.sortBy(commentLogState.events, ['date']).reverse()
                        commentLogState.preparedEvents = this.prepareEvents({
                            'events': commentLogState.events, 'eventSource': 'comments', 'fetchedEvents': true, 'obj': this.splitKey(ownerKey)
                        })
                        commentLogState.groupedEvents = commentLogState.events

                        commit('SET_COMMENTLOG', commentLogState)
                        //commit('objectCache/SET_OBJECTPROP', { ownerKey: ownerKey, key: 'comments', value: commentLogState.events }, { root: true })
                    }
                })
            }
            resolve()
        })
    }
}

// mutations
const mutations = {
    SET_COMMENTLOG(state, opt) {
        state.commentLog[this.encodeOwnerKey(opt.ownerKey)] = opt
    },
    UNSET_COMMENTLOG(state, opt) {
        delete state.commentLog[this.encodeOwnerKey(opt.ownerKey)]
    },
    SET_TAGLOG(state, opt) {
        state.tagLog[this.encodeOwnerKey(opt.ownerKey)] = opt
    },
    SET_TAGWORDS(state, opt) {
        if (!opt.filterObjectType)
            state.tagWords[opt.tagType]['unfiltered'][opt.word] = opt.tags
        else
            state.tagWords[opt.tagType][opt.filterObjectType][opt.word] = opt.tags
    }
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
