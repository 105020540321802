<template>
    <article class="table-row" :class="(expansion ? 'has-expansion' : '') + ' ' + $attrs.class" @click="propagateClick">
        <slot />
        <template v-if="expansion">
            <div v-show="model" class="table-cell expansion is-open" @click.stop="dontPropagateClick">
                <slot name="expansion" />
            </div>
        </template>
    </article>
</template>

<script>

    import _noop from 'lodash/noop'
    import { useModelVirtualWrapper } from '../../../composables/ModelWrapper.js'
    import { ref, computed, inject, onMounted } from 'vue'

    export default {
        name: 'TableRow',
        props: {
            modelValue: { type: Boolean, default: undefined },
            expansion: { type: Boolean, default: false }
        },
        emits: ['update:modelValue', 'update:cellTitles'],
        setup(props, { slots, emit }) {

            // models
            const isOpen = ref(false)
            const model = useModelVirtualWrapper(props, emit, 'modelValue', isOpen)

            // computed
            const cellTitles = computed(() => {
                var cells = slots.default().map((cell) => cell && cell.props ? cell.props.title : null)
                return cells.filter((t) => t != null)
            })
            const visibleCellTitles = computed(() => {
                var cells = slots.default().map((cell) => cell && cell.props && cell.props.show ? cell.props.title : null)
                return cells.filter((t) => t != null)
            })

           // methods
            const dontPropagateClick = (ev) => { _noop(ev) }
            const propagateClick = () => {
                //console.log('tableRow Expanded', model.value)
                model.value = !model.value
               // console.log('tableRow Expanded', model.value)
            }
            const setVisibleColumns = inject('setVisibleColumns')

            onMounted(() => {
                setVisibleColumns(visibleCellTitles.value)
            })

            return { isOpen, model, cellTitles, visibleCellTitles, dontPropagateClick, propagateClick, setVisibleColumns }
        },
        watch: {
            "visibleCellTitles": {
                handler(val) {
                    this.setVisibleColumns(val)
                }
            },
        }
    }
</script>

<style scoped lang="scss">
</style>