<template>
    <ul class="list-tags no-icon suggested-filters">
        <li v-for="(tag, tidx) in allSuggestionTags" :key="tidx">
            <tag-group v-if="tag.subTags"
                       :name="tag.name"
                       :type="tag.type ?? 'default'"
                       :title="tag.title"
                       :count="tag.count ? tag.count.toString() : ''"
                       :actions="tag.actions"
                       :subTags="tag.subTags"
                       :class="tag.class"
                       @button-clicked="handleClick(tag.key, $event)"
                       @subtag-clicked="handleClick(tag.key, $event.action, $event.tag)" />
            <tag-basic v-else
                       :type="tag.type ?? 'keyword'"
                       :name="tag.name"
                       :label="tag.label"
                       :title="tag.title"
                       :icon="tag.icon"
                       :href="tag.href"
                       :count="tag.count ? tag.count.toString() : ''"
                       :class="tag.class"
                       :actions="tag.actions"
                       @button-clicked="handleClick(tag.key, $event)" />
        </li>
        <li v-if="allSuggestionTags.length == 0">No suggestions</li>
    </ul>
    <spin-loader :showMe="findingSuggestions" />
    <template v-if="!findingSuggestions">
        <a v-if="lastTotal >= limit" href="javascript:;" class="suggestions-more" @click="findSuggestions({'goBack': false})">More Suggestions...</a>
        <a v-if="lastTotal < limit && skip > 0" href="javascript:;" class="suggestions-more" @click="findSuggestions({ 'goBack': true})">Review Earlier Suggestions...</a>
    </template>
    
</template>

<script>
    import { ref, computed, watchEffect, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import _ from 'lodash'
    import { getSearchTagTools } from '../../composables/SearchTools.js'
    import { useToast } from '../../composables/ModelWrapper.js'

    import TagBasic from '../ui/tags/TagBasic.vue'
    import TagGroup from '../ui/tags/TagGroup.vue'

    export default {
        name: 'SuggestedFilters',
        emits: ['addfilter', 'suggestions-updated'],
        props: {
            query: { type: Object },
            docTypes: { type: Array },
            docCounts: { type: Object },
            ignoreSearchInSearch: { type: Boolean },
            ignoreSwaps: { type: Boolean },
        },
        components: {
            TagBasic, TagGroup
        },
        setup(props, { emit }) {
            const store = useStore()
            const toast = useToast()
            const { actionMap, filterToTag } = getSearchTagTools()

            // data
            const foundSuggestions = ref([])
            const foundSuggestionTags = ref([])
            const limit = ref(3)
            const skip = ref(0)
            const lastTotal = ref(1)
            const findingSuggestions = ref(false)
            const criteriaKeysLast = ref('garbageToForceWatchEffect')

            // computed
            const criteria = computed(() => props.query ? props.query.criteria : null)
            const criteriaKeys = computed(() => criteria.value ? Object.keys(criteria.value)  : [])
            const swapList = computed(() => {
                var swaplist = []
                if (props.docCounts) {
                    if (!props.docTypes && !props.ignoreSwaps) {
                        swaplist = [
                            { name: 'Companies', key: { value: 'Company', flipPath: null }, count: props.docCounts['company'] },
                            { name: 'Acquisitions', key: { value: 'Acquisition', flipPath: null }, count: props.docCounts['acquisition'] },
                            { name: 'Persons', key: { value: 'Person', flipPath: null }, count: props.docCounts['person'] },
                            { name: 'Comments', key: { value: 'Comment', flipPath: null }, count: props.docCounts['comment'] },
                            { name: 'Industry Segments', key: { value: 'LobDescent', flipPath: null}, count: props.docCounts['lobdescent'] },
                            { name: 'Events', key: { value: 'Event', flipPath: null }, count: props.docCounts['event'] }]
                    }
                    else {
                        if (!props.docTypes.includes('Company')) {
                            if(!props.ignoreSwaps) swaplist.push({ name: 'Companies', key: { value: 'Company', flipPath: null } })
                            if (props.docTypes.includes('Acquisition') && !props.ignoreSearchInSearch) {
                                swaplist.push({ name: 'Buyers', key: { value: 'Company', flipPath: 'buyers.id' } })
                                swaplist.push({ name: 'Sellers', key: { value: 'Company', flipPath: 'seller.id' } })
                            }
                        }

                        if (!props.docTypes.includes('Acquisition')) {
                            if (!props.ignoreSwaps) swaplist.push({ name: 'Acquisitions', key: { value: 'Acquisition', flipPath: null } })
                            if (props.docTypes.includes('Company') && !props.ignoreSearchInSearch) {
                                swaplist.push({ name: 'Acquisitions', key: { value: 'Acquisition', flipPath: 'acquisition.id' } })
                                swaplist.push({ name: 'Persons', key: { value: 'Person', flipPath: 'person.id' } })
                                swaplist.push({ name: 'Comments', key: { value: 'Comment', flipPath: 'comment.id' } })
                                swaplist.push({ name: 'Ultimate Parents', key: { value: 'Company', flipPath: 'parent.id' } })
                            }
                        }

                        if (!props.docTypes.includes('Person'))
                            if (!props.ignoreSwaps) swaplist.push({ name: 'Persons', key: { value: 'Person', flipPath: null } })

                        if (props.docTypes.includes('Company') || props.docTypes.includes('Acquisition') || props.docTypes.includes('Person')) {
                            swaplist.push({ name: 'Events', key: { value: 'event', flipPath: 'events' } })
                            if (!props.ignoreSwaps) swaplist.push({ name: 'Comments', key: { value: 'Comment', flipPath: null } })
                        }

                        swaplist.forEach((swap, idx) => {
                            swaplist[idx].type = 'filter'
                            swaplist[idx].class = 'switch'
                            swaplist[idx].label = swaplist[idx].key && swaplist[idx].key.flipPath ? 'Search in Search' : 'Switch To'
                            swaplist[idx].actions = ['switch']
                            swaplist[idx].defaultAction = 'switch'
                        })
                    }
                }
                return swaplist
            })
            const allSuggestionTags = computed(() => foundSuggestionTags.value.concat(swapList.value))

            // methods
            const findSuggestions = (opt) => {
                if (!props.query)
                    return
                if (opt && opt.goBack) {
                    skip.value = 0
                    lastTotal.value = 1
                }
                findingSuggestions.value = true
                var req = { query: props.query, limit: limit.value, skip: skip.value, lastTotal: lastTotal.value }
                store.dispatch('search/getSearchSuggestions', req).then((results) => {
                    foundSuggestions.value = results ? results : []
                    var fSuggestionTags = []
                    foundSuggestions.value.forEach((item, idx) => {
                        var suggTag = filterToTag(item, ['add'], idx)
                        if (suggTag)
                            fSuggestionTags.push(suggTag)
                    })

                    foundSuggestionTags.value = fSuggestionTags
                    lastTotal.value = foundSuggestions.value.length
                    if (lastTotal.value >= limit.value)
                        skip.value = skip.value + limit.value
                    findingSuggestions.value = false
                    emit('suggestions-updated', lastTotal.value + swapList.value.length)
                }).catch(error => {
                    if (opt && !opt.suppressToastMessages)
                        toast.add({ severity: 'error', summary: 'Error getting suggestions', detail: error.message, life: 3000 })
                    findingSuggestions.value = false
                    emit('suggestions-updated', swapList.value.length)
                })
            }
            const handleClick = (tagKey, action, subTag) => {
                //console.log('handleClick', tagKey, action, subTag)
                _.noop(subTag)
                if (action == 'switch')
                    emit(actionMap[action], { name: 'docType', key: 'docType', isEditable: true, value: JSON.stringify([tagKey.value]), flipPath: tagKey.flipPath })
                else if (foundSuggestions.value[tagKey])
                    emit(actionMap[action], foundSuggestions.value[tagKey])
            }

            // watch
            watchEffect(() => {
                var newKeys = criteriaKeys.value.join(',')
                if (criteriaKeysLast.value != newKeys) {
                    //console.log('watchEffect', newKeys)
                    criteriaKeysLast.value = newKeys
                    skip.value = 0
                    findSuggestions({ suppressToastMessages: false })
                }
            }, { 'flush': 'post' })

            // lifecycle
            onMounted(() => {
                emit('suggestions-updated', lastTotal.value + swapList.value.length)
            })

            return {
                // data
                foundSuggestions, foundSuggestionTags, limit, skip, lastTotal, findingSuggestions, criteriaKeysLast,
                // computed
                criteria, criteriaKeys, swapList, allSuggestionTags,
                // methods
                findSuggestions, filterToTag, handleClick
            }
        }
    }
</script>

<style scoped lang="scss">
    
</style>
