<template>
    <div>
        <header v-if="showHeader" class="section-header flex-rows margin-top margin-bottom-small">
            <h3 class="">Financials</h3>
            <span v-if="dirtyCount"> {{ dirtyCount + (dirtyCount > 1 ? ' years' : ' year') }} changed</span>
            <i v-show="isEditing" class="fa fa-edit hide"></i>
            <spin-loader :showMe="showSpinLoader" />
            <span class="flex-rows place-right no-responsive">
                <financial-actions :finYears="activeItems" @addyear="onAddYear" @showstockhistory="showStockHistory" />
            </span>
        </header>
        <table-flex class="financials">
            <template v-for="(fin, idx) in model" :key="fin.id">
                <financial10k-editor v-if="!fin.delete"
                                     v-model="model[idx]"
                                     :inpopup="inpopup"
                                     :advanced="advancedMode"
                                     :showNative="showNative"
                                     :ownerName="obj ? obj.name : ''"
                                     :previousYear="findPreviousYear"
                                     @update:showNative="showNative = $event"
                                     @tickersearch="openTickerSearch"
                                     :showTickerNeedsUpdate="tickerNeedsUpdate"
                                     v-model:expanded="expanded[fin.id]"
                                     v-model:editing="editingPerItem[fin.id]"
                                     @updateotheryear="onUpdateOtherYear"
                                     :disabled="disabled"
                                     @validate="validateModel"
                                     @ownershipchange="handleOwnershipChange"
                                     @update:editing="isEditing = true" />
            </template>

        </table-flex>
        <div class="flex-rows no-responsive fullwidth margin-top-small">
            <checkbox v-model="showNative" label="Show in Native Currency" :showAsSwitch="true" />
            <checkbox v-model="advancedMode" label="Advanced Mode" :showAsSwitch="true" />
            <btn v-if="showSave && !disabled" el="button" title="Save" @click="saveChanges(false)" type="primary">Save</btn>
        </div>
        <ticker-search v-model:isOpen="TickerSearchIsOpen" :ownerName="obj ? obj.name : ''" @tickerfound="handleTickerFound" />
        <modal v-model="StockHistoryIsOpen" :title="'Stock History' + (stockHistory && stockHistory.ticker ? ' [' + stockHistory.ticker + ']' : '')">
            <template #body>
                <ul v-if="stockHistory && stockHistory.data" class="list-icons list-vertical">
                    <li v-for="(year, idx) in stockHistory.data" :key="idx">
                        <div v-if="year.date">
                            {{ year.date.toLocaleDateString() }} - {{ year.val }}
                        </div>
                    </li>
                </ul>
            </template>
        </modal>
    </div>
</template>

<script>

    import { mapGetters, useStore } from 'vuex'
    import _ from 'lodash'
    import { DateTime } from 'luxon'
    import { ref, computed, nextTick, inject, onMounted, onBeforeUnmount } from 'vue'
    import { useToast } from '../../composables/ModelWrapper.js'
    import { editorProps, editorEmits, editorSetup } from '../../composables/Editors.js'

    import Financial10kEditor from './Financial10kEditor.vue'
    import FinancialActions from './FinancialActions.vue'
    import TickerSearch from './TickerSearch.vue'
    import TableFlex from '../ui/tables/TableFlex.vue'
    import Checkbox from '../ui/forms/Checkbox.vue'
    
    export default {
        name: 'FinancialsEditor',
        components: {
            Financial10kEditor, FinancialActions, TableFlex, Checkbox, TickerSearch
        },
        props: {
            ...editorProps(),
            modelValue: { type: Array }
        },
        emits: [...editorEmits()],
        setup(props, { emit }) {
            // Tools
            const store = useStore()
            const toast = useToast()
            const emitter = inject("emitter")   // Inject event bus  

            const defaultFinancial = computed(() => store.state.financials.defaultFinancial)
            const createFin = (year) => {
                var newFin = _.cloneDeep(defaultFinancial.value)

                newFin.version = year
                newFin.id = year
                newFin.year = year
                newFin.ownerID = props.obj ? props.obj.id : 0
                newFin.delete = false
                newFin.entryUserID = 0 // This tells the service it is a new financial
                newFin.entryDate = new Date()
                newFin.changeDate = new Date()
                newFin.changeUserID = 0
                newFin.dirty = false
                return newFin
            }

            // models
            const getModel = () => {
                var initialModel = []
                if (props.modelValue) {
                    initialModel = _.cloneDeep(props.modelValue)
                    initialModel.forEach((fin) => {
                        if (fin.revenueUSD) {
                            var prevYear = initialModel.find((yr) => { return yr.id == fin.id - 1 })
                            fin.revenueAnnualGrowth = prevYear && prevYear.revenueUSD ? (fin.revenueUSD - prevYear.revenueUSD) / prevYear.revenueUSD : null
                        }
                        else
                            fin.revenueAnnualGrowth = null
                    })
                }
                return initialModel
            }
            const model = ref(getModel())
            
            // models, data, computed from common setup
            const { isEditing, advancedMode, errState, showSpinLoader, baseModel, expanded, editingPerItem, savingEdits, activeItems, dirtyCount, deletedCount, onSave, rollbackModel } = editorSetup(props, emit, null, model)

            // data
            const StockHistoryIsOpen = ref(false)
            const stockHistory = ref(null);
            const TickerSearchIsOpen = ref(false)
            const showNative = ref(false)
            const tickerSearchYear = ref(null)
            const loadingTickerResults = ref(false)
            var oKey = 'company::' + (props.obj ? props.obj.id : (model.value[0] ? model.value[0].ownerID : 0))
            const ownerKey = ref(oKey)

            // constants
            const tickerExcludeKeys = ['id', 'year', 'version', 'ownerID', 'employeeSize', 'entryUserID', 'entryUserName', 'changeUserID', 'changeUserName', 'entryDate', 'changeDate', 'detailAddress']
            const currencyFields = ['revenue', 'ebitda', 'ebit', 'netIncome', 'stockPriceYearEnd', 'marketCap', 'netDebt', 'preferredEquity', 'enterpriseValue']

            // computed

            const notInUsd = computed(() => {
                if (model.value && model.value.length > 0)
                    return model.value.filter((fin) => { return fin.currency && fin.currency.id != 1 }).length > 0
                return false
            })

            // methods
            const hasData = (financial) => {
                return ((financial.revenueUSD ?? 0) != 0) || ((financial.ebitdaUSD ?? 0) != 0) || ((financial.ebitUSD ?? 0) != 0) ||
                    ((financial.netIncomeUSD ?? 0) != 0) || (financial.employeeSize > 0) || !(financial.tickerSymbol) ||
                    ((financial.marketCapUSD ?? 0) != 0) || ((financial.outstandingShares ?? 0) != 0) || ((financial.stockPriceYearEndUSD ?? 0) != 0) ||
                    ((financial.netDebtUSD ?? 0) != 0) || ((financial.preferredEquityUSD ?? 0) != 0) || ((financial.earningsPerShareUSD ?? 0) != 0)
            }

            const validateModel = () => {
                errState.value = null
                var errorCount = 0
                var allErrorMessages = [];
                model.value.forEach((fin) => {
                    if (!fin.delete && hasData(fin)) {
                        var errMessages = []
                        if (!fin.id) errMessages.push('missing year')
                        if (!fin.description) errMessages.push('missing reliabilty')
                        if (errMessages.length > 0) {
                            errorCount++
                            allErrorMessages.push((fin.id ? 'financial(' + fin.id + '): ' : 'financial(????): ') + errMessages.join(', '))
                            fin.error = errMessages.join(', ')
                        }
                        else
                            fin.error = null
                    }
                })
                errState.value = errorCount ? { errorCount: errorCount, errorMessages: allErrorMessages } : null
                return errState.value
            }
            const saveChanges = (suppressToastMessages) => {
                return new Promise((resolve, reject) => {
                    console.log('saveChanges', dirtyCount.value, deletedCount.value)
                    if ((dirtyCount.value + deletedCount.value) == 0) {
                       // if (!suppressToastMessages)
                         //   toast.add({ severity: 'info', summary: 'Financials not changed', detail: 'not saving', life: 3000 })
                        resolve({ changed: false })
                        return
                    }
                    if (errState.value && errState.value.errorCount) {
                        if (!suppressToastMessages)
                            toast.add({ severity: 'error', summary: 'Financials not valid', detail: errState.value.errorCount + ' invalid Financials', life: 3000 })
                        reject('Financials not valid: ' + errState.value.errorCount + ' invalid Financials')
                        return
                    }
                    else {
                        showSpinLoader.value = true
                        store.dispatch('financials/saveFinancials', { 'ownerKey': ownerKey.value, 'financial10Ks': model.value }).then((results) => {
                            showSpinLoader.value = false
                            model.value = _.cloneDeep(results.financial10Ks)

                            if (!suppressToastMessages)
                                toast.add({ severity: 'info', summary: 'Financials Saved', life: 3000 })
                            resolve({ changed: true })
                        }).catch(error => {
                            showSpinLoader.value = false
                            if (!suppressToastMessages)
                                toast.add({ severity: 'error', summary: 'Error saving Financials', detail: error.message, life: 3000 })
                            reject('Error saving Financials: ' + error.message)
                        })
                    }
                })
            }
                
            const fetch = (opt) => {
                    console.log('fetchFinancials', opt)
                    showSpinLoader.value = true
                    return new Promise((resolve, reject) => {
                        store.dispatch('financials/fetchFinancials', opt)
                            .then((results) => {
                                showSpinLoader.value = false
                                if (results && results.financial10Ks) {
                                    ownerKey.value = results.ownerKey
                                    model.value = _.cloneDeep(results.financial10Ks)
                                }
                                console.log('loaded Financials', results.financial10Ks)
                                resolve()
                            }).catch(error => {
                                showSpinLoader.value  = false
                                if (!opt.suppressToastMessages)
                                    toast.add({ severity: 'error', summary: 'Error fetching Financials', detail: error, life: 3000 })
                                reject(error)
                            })
                    })
                }
            // startup code
            store.dispatch('financials/fetchCurrencies', {}).then((currencies) => { if (currencies) console.log('currencies', currencies) })

            const saveHandler = (req) => {
                onSave(req, 'financials', emitter, saveChanges)
            }
            const rollbackHandler = (req) => {
                if (req.editorID == props.editorID) {
                    rollbackModel()
                }
            }


           onMounted(() => {
               emitter.on("saveEditor", saveHandler);
               emitter.on("rollbackModel", rollbackHandler)
            })
            onBeforeUnmount(() => {
                emitter.off("saveEditor", saveHandler);
                emitter.off("rollbackModel", rollbackHandler)
            })

            return {
                // tools
                emitter,
                // models
                model, advancedMode, isEditing, errState,
                // data
                showSpinLoader, baseModel, savingEdits, expanded, editingPerItem, ownerKey, showNative,
                TickerSearchIsOpen, tickerSearchYear, loadingTickerResults, stockHistory, StockHistoryIsOpen,
                // constants
                tickerExcludeKeys, currencyFields,
                // computed
                dirtyCount, deletedCount, activeItems, notInUsd, defaultFinancial, 
                // methods
                saveChanges, fetch, createFin, onSave, getModel, validateModel, hasData
            }
        },
        watch: {
            "modelValue": {
                handler() {
                    this.model = this.getModel()
                }
            },
            "model": {
                handler() {
                    this.editingPerItem = {}
                    this.expanded = {}
                    nextTick(() => {
                        this.model.forEach((item) => {
                            item.dirty = false
                        })
                        this.baseModel = _.cloneDeep(this.model)
                    })
                }
            },
            "dirtyCount": {
                handler(newValue, oldValue) {
                    if (!oldValue && newValue > 0 && !this.showSpinLoader) {
                        console.log('dirtyCount Watch', newValue, this.showSpinLoader)
                        this.$emit('dirtied', newValue)
                    }
                    else if (oldValue && newValue == 0) {
                        this.errState = null
                        this.$emit('cleaned', newValue)
                    }
                }
            },
        },
        computed: {
            ...mapGetters({
                financialAccuracies: 'financials/financialAccuracies',
                defaultFinancial: 'financials/defaultFinancial',
                user: 'userInfo/currentUser'
            }),
            latestYear() {
                if (this.model && this.model.length > 0)
                    return _.sortBy(this.model, function (f) { return f.id * -1 })[0]
                return null
            },
            ownerID() {
                if (this.obj && this.obj.id)
                    return this.obj.id
                if (this.model && this.model.length > 0)
                    return this.model[0].ownerID
                if (this.ownerKey) {
                    var keySplit = this.$store.splitKey(this.ownerKey)
                    if (keySplit)
                        return keySplit.id
                }
                return null
            },
            ownerType() {
                if (this.ownerKey) {
                    var keySplit = this.$store.splitKey(this.ownerKey)
                    if (keySplit)
                        return keySplit.objectType
                }
                return 'company'
            },
            tickerSymbol() {
                return this.latestYear ? this.latestYear.tickerSymbol : null
            },
            tickerNeedsUpdate() {
                if (this.latestYear && this.tickerSymbol) {
                    var month = this.latestYear.fiscalYearEndMonth ? this.latestYear.fiscalYearEndMonth : 1;
                    var fiscalMonthFormatted = (month + "").length == 1 ? '0' + month : '' + month;
                    var fiscalDate = DateTime.fromFormat(this.latestYear.year + '-' + fiscalMonthFormatted + '-01', 'yyyy-MM-dd');
                    var dayDiff = fiscalDate.diffNow('days').days * -1;
                    if (dayDiff > 60)
                        return true;
                }
                return false;
            }
        },
        methods: {
            isDirty() {
                if (this.model) {
                    return this.model.filter((f) => { return f.dirty }).length > 0
                }
                return false
            },
            createFinancial(opt) {
                var newFin = {}

                //make sure the year is not a string
                opt.year = +opt.year;

                if (opt.yearToClone)
                    newFin = _.cloneDeep(opt.yearToClone)
                else if (this.model && this.model.length > 0) 
                    newFin = _.cloneDeep(_.sortBy(_.filter(this.model, function (f) { return !f.delete }), function (f) { return Math.abs(f.id - opt.year) })[0])
                else
                    newFin = _.cloneDeep(this.defaultFinancial)

                newFin.version = opt.year
                newFin.id = opt.year
                newFin.year = opt.year
                newFin.ownerID = this.ownerID
                newFin.dirty = true
                newFin.delete = false
                newFin.entryUserID = 0 // This tells the service it is a new financial
                newFin.entryUserName = this.user.name
                newFin.entryDate = new Date()
                newFin.changeUserID = 0
                newFin.changeUserName = this.user.name
                newFin.changeDate = new Date()
                newFin.description = 'Added by ' + this.user.name + ' from Vue'
                newFin.revenueAnnualGrowth = null
                newFin.revenueUSD = null
                newFin.revenueNative = null

                //if we already have the next year in the grid, prioritize its ebitda to calculate the current one
                var nextYear = this.model ? this.model.find(function (fin) { return (fin.id == newFin.year + 1) }) : null
                if (nextYear) 
                    newFin.ebitdaUSD = nextYear.ebitdaUSD * (1 / (1 + (nextYear.ebitdaRevenuePercentage ? nextYear.ebitdaRevenuePercentage : 0)))

                if (newFin.year > DateTime.now().year) {
                    newFin.financialAccuracyID = 3;
                    newFin.financialAccuracyName = 'Projected';
                }

                return newFin
            },
            onAddYear(opt) {
                if (opt.year) this.addFinancial(opt)
            },
            addFinancial(opt) {
                //create financial and massage fields
                var newFin = (opt.newFin ?? this.createFinancial(opt))

                // add financial to model
                this.model.push(newFin)
                this.model.sort(function (f1, f2) { return f2.id - f1.id });
                nextTick(() => {
                    //update component fields
                    //this.expanded[newFin.id] = true
                    this.editingPerItem[newFin.id] = true
                    this.isEditing = true;
                })
            },
            async getPublicFinancialData(opt) {
                if (!opt)
                    opt = {};

                //if we don't have a tickerSymbol in opt, try to find it in the financials
                var tickerSymbol = opt.tickerSymbol ? opt.tickerSymbol : (this.latestYear ? this.latestYear.tickerSymbol : '')

                if (tickerSymbol) {
                    //then load the financials
                    this.showSpinLoader = true
                    this.$store.dispatch('financials/tickerUpdate', { 'tickerSymbol': tickerSymbol }).then((results) => {
                        console.log('getPublicFinancialData-fetched', results)
                        this.showSpinLoader = false
                        if ((!results || results.length == 0) && !opt.suppressToastMessages)
                            this.$toast.add({ severity: 'error', summary: 'No financial data found', detail: tickerSymbol, life: 3000 })
                        else {
                            this.loadingTickerResults = true
                            //this will stop the revenue update prompts for the user
                            this.isEditing = false;

                            // Copy the data from tickerUpdate to the model
                            var yearsToReplace = []
                            var yearsToAdd = []
                            results.forEach((t) => {
                                var existingYear = this.model.find((f) => { return f.year == t.year })
                                var finYear = null
                                if (existingYear)
                                    finYear = existingYear
                                else
                                    finYear = this.createFinancial({ 'year': t.year, 'yearToClone': this.defaultFinancial })
                                //console.log(finYear)

                                Object.keys(t).forEach((key) => {
                                    if (!this.tickerExcludeKeys.includes(key) && t[key])
                                        finYear[key] = t[key]
                                })
                                //console.log(finYear)

                                if (t.employeeSize && !finYear.employeeSize)
                                    finYear.employeeSize = t.employeeSize
                                finYear.dirty = true
                                finYear.delete = false
                                finYear.financialAccuracyName = this.financialAccuracies[finYear.financialAccuracyID]

                                if (existingYear)
                                    yearsToReplace.push(finYear)
                                else
                                    yearsToAdd.push(finYear)
                            })


                            if (yearsToAdd.length + yearsToReplace.length) {
                                console.log(yearsToAdd, yearsToReplace, this.model)
                                // replace existing years
                                yearsToReplace.forEach((fin) => {
                                    var idx = _.findIndex(this.model, function (y) { return y.id == fin.id })
                                    if (idx > -1)
                                        this.model[idx] = fin
                                });
                       
                                //add new years
                                yearsToAdd.forEach((fin) => {
                                    this.model.push(fin);
                                });

                                this.model.sort(function (f1, f2) { return f2.id - f1.id });

                            }
                            this.isEditing = true;
                            this.loadingTickerResults = false
                        }
                    }).catch(error => {
                        this.showSpinLoader = false
                        if (!opt.suppressToastMessages)
                            this.$toast.add({ severity: 'error', summary: 'Error getting Financial Data', detail: error.message, life: 3000 })

                    })
                }
            },
            findPreviousYear(thisYear) {
                return _.filter(this.model, function (f) { return !f.delete }).find((yr) => { return yr.id == thisYear - 1 })
            },
            onUpdateOtherYear(req) {
                console.log('onUpdateOtherYear', req)
                if (req.year && req.val) {
                    //create the year if it doesn't exist
                    var finYear = this.model.find((yr) => { return yr.id == req.year })
                    var newYear = finYear == null
                    if (newYear) finYear = this.createFinancial({ year: req.year })

                    //fix the revenue and adjust the native revenue
                    finYear[req.field + 'USD'] = req.val
                    if (finYear.currency && finYear.currency.id != 1 && (finYear.currency.toUSDRate ?? 0) != 0) 
                        finYear[req.field + 'Native'] = req.val / finYear.currency.toUSDRate
                    else 
                        finYear[req.field + 'Native'] = req.val

                    //fix the ebitda
                    if (finYear.revenueUSD && finYear.ebitdaRevenuePercentage)
                        finYear.ebitdaUSD = finYear.revenueUSD * finYear.ebitdaRevenuePercentage

                    //dirty the year
                    finYear.dirty = true

                    //add the year to the grid if it didn't exist already
                    if (newYear) {
                        this.addFinancial({ newFin: finYear })
                    }
                }
            },
            openTickerSearch(fin) {
                if (fin.tickerSymbol) {
                    this.getPublicFinancialData({ 'tickerSymbol': fin.tickerSymbol })
                }
                else {
                    this.tickerSearchYear = fin.id
                    this.TickerSearchIsOpen = true
                }
            },
            handleTickerFound(tickerSymbol) {
                var tickYear = this.tickerSearchYear ? this.tickerSearchYear : this.latestYear.id
                this.tickerSearchYear = null
                if (tickerSymbol) {
                    this.model.forEach((finYear, index) => {
                        if (finYear.id == tickYear) {
                            this.model[index].tickerSymbol = tickerSymbol
                        }
                    })
                    this.getPublicFinancialData({ 'tickerSymbol': tickerSymbol })
                }
            },
            showStockHistory(opt) {
                if (!opt)
                    opt = {};

                if (this.stockHistory && this.stockHistory.ticker && this.stockHistory.data && !opt.forceFetch) {
                    this.StockHistoryIsOpen = true;
                }
                else {
                    var companyID = opt.id ? opt.id : (this.latestYear ? this.latestYear.ownerID : 0)
                    var tickerSymbol = opt.tickerSymbol ? opt.tickerSymbol : (this.latestYear ? this.latestYear.tickerSymbol : '')
                    if (tickerSymbol) {
                        this.showSpinLoader = true
                        this.$store.dispatch('financials/stockHistoryUpdate', { 'id': companyID, 'tickerSymbol': tickerSymbol }).then((results) => {
                            this.showSpinLoader = false
                            if ((!results || results.length == 0) && !opt.suppressToastMessages) {
                                this.stockHistory = null;
                                this.$toast.add({ severity: 'error', summary: 'No stock history found', detail: tickerSymbol, life: 3000 })
                            }
                            else {
                                var chartData = _.map(results, function (sh) {
                                    var theDate = new Date(sh.date);
                                    return { date: theDate, val: sh.closePrice };
                                }).reverse();

                                this.stockHistory = { ticker: tickerSymbol, data: chartData };
                                this.StockHistoryIsOpen = true;


                                /*me.$('.pnlStockHistory').removeClass('d-none');
                                    // render the results
                                    var chartData = _.map(results, function (sh) {
                                        var theDate = new Date(sh.date);
                                        return { date: theDate, val: sh.closePrice };
                                    }).reverse();
    
                                    me.stockChart = new AmCharts.AmStockChart();
                                    me.stockChart.pathToImages = "/assets/js/plugins/amcharts/amstockcharts/images/";
    
                                    var dataSet = new AmCharts.DataSet();
                                    dataSet.dataProvider = chartData;
                                    dataSet.fieldMappings = [{ fromField: "val", toField: "value" }];
                                    dataSet.categoryField = "date";
    
                                    me.stockChart.dataSets = [dataSet];
    
                                    var stockPanel = new AmCharts.StockPanel();
                                    me.stockChart.panels = [stockPanel];
    
                                    var panelsSettings = new AmCharts.PanelsSettings();
                                    panelsSettings.startDuration = 3; // 3 second animation
                                    me.stockChart.panelsSettings = panelsSettings;
    
                                    var graph = new AmCharts.StockGraph();
                                    graph.valueField = "value";
                                    graph.type = "column";
                                    graph.fillAlphas = 1; // Makes the columns opaque
                                    graph.title = tickerSymbol;
                                    stockPanel.addStockGraph(graph);
    
                                    me.stockChart.write("chtStockHistory");*/
                            }
                        }).catch(error => {
                            this.showSpinLoader = false
                            if (!opt.suppressToastMessages)
                                this.$toast.add({ severity: 'error', summary: 'Error getting Stock History', detail: error.message, life: 3000 })

                        })
                    }
                }
            },
            handleOwnershipChange(opt) {
                if (opt.year == this.latestYear.id)
                    this.$emit('ownershipchange', opt)
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
