<template>
    <header class="record-head">
        <div :class="recordHeadClasses">
            <h3 v-if="simpleRecordContext" class="record-title grow-full">
                <template v-if="canRequestSidebar">
                    <div class="detail-requested">
                        <a href="javascript:;" :title="recordTitle" @click="detailRequested">
                            <span v-html="shortHighlightedTitle" />
                        </a>
                        <!--<a class="margin-left-xsmall margin-right-xsmall" target="_blank" :href="editURL" style="font-size:0.9em"><i class="fa fa-edit"></i></a>-->
                    </div>
                </template>
                <template v-else>
                    <a target="_blank" :href="titleURL">
                        <span v-html="shortHighlightedTitle" />
                    </a>
                </template>
                <template v-if="isBuyersListComment">
                    <div class="bnc-client">
                        <span class="bnc-label">Client:</span>
                        <a v-for="client in obj.clients" :key="client.ownerID" :href="'/BuyersList/Details/' + client.ownerID" target="_blank"><strong>{{ client.ownerName }}</strong></a>
                    </div>
                </template>
            </h3>
            <div v-if="recordContext == 'detailspage'" class="record-subheader">
                <!-- Details Page Only -->
                <div>
                    <h3 class="record-title grow-full">
                        <span>{{ highlightedTitle }}</span>
                        <template v-if="isBuyersListComment">
                            <div class="bnc-client">
                                <span class="bnc-label">Client:</span>
                                <a v-for="client in obj.clients" :key="client.ownerID" :href="'/BuyersList/Details/' + client.ownerID" target="_blank"><strong>{{ client.ownerName }}</strong></a>
                            </div>
                        </template>

                        <span class="record-title-links">
                            <a class="margin-left-xsmall margin-right-xsmall" target="_blank" :href="editURL" style="font-size:0.8em"><i class="fa fa-edit"></i></a>
                        </span>
                    </h3>
                    <div class="record-contact">
                        <record-subtitle :obj="obj" :recordContext="recordContext" />
                    </div>
                    <record-deleted :obj="obj" />
                </div>
                <record-image-simple :noText="true" :obj="obj" class="icon-logo" />
            </div>
            <record-deleted v-else :obj="obj" />

            <div v-if="simpleRecordContext" class="flex-rows no-responsive">
                <template v-if="formattedDate && formattedDateMobile">
                    <span class="record-date show-on-mobile" :class="eventType">
                        {{ formattedDateMobile }}
                    </span>
                    <span class="record-date hide-on-mobile" :class="eventType">
                        {{ formattedDate }}
                    </span>
                </template>
                <span v-else-if="formattedDate" class="record-date" :class="eventType">
                    {{ formattedDate }}
                </span>
                <!-- Record Actions NOT Sidebar -->
                <div class="flex-rows no-responsive record-head-actions">
                    <record-flags :obj="obj" :parentContext="parentContext" :bookmarkContext="bookmarkContext" @toggle-flag="$emit('toggle-flag', $event)" />
                    <div v-if="recordContext == 'pin-card' && card" class="cardOptions">
                        <card-options :dashboard="dashboard" :card="card"></card-options>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="recordContext == 'details' || recordContext == 'sidebar'">
            <record-subtitle :obj="obj" :recordContext="recordContext" />
        </div>
        <div class="flex-rows no-responsive record-head-actions">
            <div class="flex-rows" style="flex-grow: 1">
                <div class="record-url-container">
                    <div v-if="userResearch && obj.objectType =='acquisition'">
                        <a class="margin-right-xsmall" target="_blank" :href="titleURL" @click="openSideLinks(true)" title="Open Detail pages for Acquisition, Seller, and Buyer(s)"><i class="fa fa-external-link"></i>...</a>
                        <a class="margin-right-xsmall" target="_blank" :href="editURL" @click="openSideLinks()" title="Open Edit pages for Acquisition, Seller, and Buyer(s)"><i class="fa fa-edit"></i>...</a>
                    </div>
                    <a v-if="recordURL" class="record-url" :href="'https://' + recordURL" target="_blank">
                        <span class="record-url-text" :class="urlClass"> {{  ellipseString( recordURL, 60, true ) }} </span>
                    </a>
                </div>
                <a v-if="primaryAddressLink" class="record-location" :href="'http://maps.google.com/maps?q=' + primaryAddressLink" target="_blank">
                    <i class="fas fa-map-marker-alt"></i>
                    <span>{{ primaryAddress }}</span>
                </a>
                <div v-if="!simpleRecordContext" class="flex-rows no-responsive">
                    <a v-if="primaryPostalCode" class="record-weather" :href="'http://www.weather.com/weather/today/' + primaryPostalCode" target="_blank">
                        <i class="fas fa-cloud" title="Weather"></i>
                    </a>
                    <a v-if="socials.facebook" :href="socials.facebook" target="_blank">
                        <i class="fa fa-facebook-square" title="Facebook"></i>
                    </a>
                    <a v-if="socials.twitter" :href="socials.twitter" target="_blank">
                        <i class="fa fa-twitter-square" title="Twitter"></i>
                    </a>
                    <a v-if="socials.linkedIn" :href="socials.linkedIn" target="_blank">
                        <i class="fa fa-linkedin-square" title="LinkedIn"></i>
                    </a>
                    <a v-if="normalizedName" :href="'https://www.google.com/search?q=site%3Acrunchbase.com+' + encodeURIComponent(recordTitle)" target="_blank">
                        <img src="/assets/images/768px-Crunchbase_favicon.svg.png" title="Crunchbase" style="width: 14px; margin-bottom:-1px;" />
                    </a>
                </div>
            </div>
            <record-flags :parentContext="parentContext" v-if="!simpleRecordContext" :obj="obj" :bookmarkContext="bookmarkContext" @toggle-flag="$emit('toggle-flag', $event)" :recordContext="recordContext" />
        </div>
    </header>
</template>

<script>
    import RecordImageSimple from './RecordImageSimple.vue'
    //import RecordEvents from './RecordEvents.vue'
    import RecordFlags from './RecordFlags.vue'
    import CardOptions from '..//Dashboards/CardOptions.vue'
    import RecordSubtitle from './RecordSubtitle.vue'
   // import RecordTags from './RecordTags.vue'
    //import RecordBodyExtended from './RecordBodyExtended.vue'
    import RecordDeleted from './RecordDeleted.vue'
    //import LobTags from '../ais/LobTags.vue'
    import { ref, computed, inject } from 'vue'
    import { useStore } from 'vuex'
    import { getRecordTools } from '../../composables/RecordTools.js'
    //import TableCell from '../ui/tables/TableCell.vue'
    //import TableRow from '../ui/tables/TableRow.vue'
    //import TableFlex from '../ui/tables/TableFlex.vue'
    import _ from 'lodash'

    export default {
        name: 'RecordHead',
        components: {
            RecordImageSimple, CardOptions, RecordSubtitle, RecordFlags, RecordDeleted
        },
        props: {
            'obj': { type: Object },
            'card': { type: Object }, /* this is used for a pin card */
            'cardDisplayType': { type: Number },
            'listIndex': { type: Number },
            'parentContext': { type: String, default: '' },
            'recordContext': { type: String, default: 'details' },
        },
        emits: ['detailrequest', 'toggle-flag'],
        setup(props, { emit }) {
            // tools
            const store = useStore()
            const query = inject('query')

            // data
            const isVisible = ref(false)
             
            // const
            const nonEventTypes =  ['company', 'person', 'acquisition', 'query']

            // computed
            const user = computed(() => store.getters['userInfo/currentUser'])
            const { objectType, objectID, objectKey, objectName, recordTitle, titleURL, editURL,
                highlightedTitle, shortHighlightedTitle, formattedDate, formattedDateMobile, isFullInfoContext, eventType } = getRecordTools(props)
            const description = computed(() => !props.obj ? null : (props.obj.shortDescription ?? props.obj.description))
            const simpleRecordContext = computed(() => !['sidebar', 'detailspage'].includes(props.recordContext))
            const canRequestSidebar = computed(() => ['details', 'table', 'brands',].includes(props.recordContext))
            const showViews = computed(() => isFullInfoContext)
            const ignoredEvents = computed(() => (props.cardDisplayType == 2 ? [37] : []))
            const recordHeadClasses = computed(() =>{
                if (simpleRecordContext.value) {
                    return 'flex-rows space-between'
                } if (props.recordContext == 'detailspage') {
                    return 'details-page'
                } else {
                    return 'flex-rows no-responsive justify-right'
                }
            })
            const isBuyersListComment = computed(() => objectType.value == 'comment' && props.obj.clients && props.obj.clients.length > 0)
            const recordURL = computed(() =>  (props.obj ? props.obj.url : null))
            const urlClass = computed(() => props.obj && props.obj.primaryMXdomain && props.obj.primaryMXdomain.failureCount > 0 ? 'type-error' : ''       )
            const bookmarkContext = computed(() => query && query.value ?
                     { targetID: query.value.id, targetName: query.value.name, targetTypeID: 18 } : null)

            // methods
            const detailRequested = () => {
                emit('detailrequest', {
                    'obj': props.obj,
                    'listIndex': props.listIndex,
                    'cardID': 0,
                    'queryID': query && query.value ? query.value.id : 0
                })
            }
            const visibilityChanged = (newVisiblity, entry) => {
                isVisible.value = newVisiblity
                _.noop(entry)
            }

            const openSideLinks = (details) => {
                if (props.obj && objectType.value == 'acquisition') {
                    if (props.obj.seller) {
                        window.open("/company/" + (details ? 'details' : 'edit') + '/' + props.obj.seller.id, "_blank");
                    }
                    if (props.obj.buyers) {
                        _.each(props.obj.buyers, function (b) {
                            window.open("/company/" + (details ? 'details' : 'edit') + '/' + b.id, "_blank");
                        });
                    }
                }
            }

            return {
                // tools and const
                query, nonEventTypes,
                // data
                isVisible,
                // computed
                user, objectType, objectID, objectKey, objectName, recordTitle, highlightedTitle, shortHighlightedTitle,
                description, formattedDate, formattedDateMobile, titleURL, editURL, simpleRecordContext, canRequestSidebar, showViews, ignoredEvents,
                recordHeadClasses, isBuyersListComment, recordURL, urlClass, eventType, bookmarkContext, isFullInfoContext,
                // methods
                detailRequested, openSideLinks, visibilityChanged
            }
        },
        computed: {
            userResearch() {
               return this.user && _.find(this.user.groups, function (g) { return g.id == 9 });
            },
            primaryAddressLink() {
                var result = null
                if (this.obj && this.obj.primaryAddress) {
                    var qs = []
                    if (this.obj.primaryAddress.address1) qs.push(this.obj.primaryAddress.address1)
                    if (this.obj.primaryAddress.address2) qs.push(this.obj.primaryAddress.address2)
                    if (this.obj.primaryAddress.city) qs.push(this.obj.primaryAddress.city)
                    if (this.obj.primaryAddress.stateProv) qs.push(this.obj.primaryAddress.stateProv)
                    if (this.obj.primaryAddress.postalCode) qs.push(this.obj.primaryAddress.postalCode)
                    if (this.obj.primaryAddress.country) qs.push(this.obj.primaryAddress.country)
                    result = (qs.length > 0 ? qs.join('+') : null)
                }
                return result
            },
            primaryAddress() {
                var address = null
                if (this.obj && this.obj.primaryAddress) {
                    if (this.obj.primaryAddress.city || this.obj.primaryAddress.stateProv || this.obj.primaryAddress.country) {
                        address = (this.obj.primaryAddress.city ? (this.obj.primaryAddress.city + ',') : '') +
                            (this.obj.primaryAddress.stateProv ? ' ' + this.obj.primaryAddress.stateProv : '') +
                            (this.obj.primaryAddress.country && this.obj.primaryAddress.country != 'USA' ? ' ' + this.obj.primaryAddress.country : '')
                    }
                }
                return address
            },
            primaryPostalCode() {
                var postalCode = null
                if (this.obj && this.obj.primaryAddress) {
                    if (this.obj.primaryAddress.country == 'USA' && this.obj.primaryAddress.postalCode) {
                        postalCode = this.obj.primaryAddress.postalCode
                        if (postalCode.includes('-'))
                            postalCode = postalCode.substr(0, postalCode.indexOf('-'))
                    }
                }
                return postalCode
            },
            normalizedName() {
                return this.obj && this.obj.normalizedName ? encodeURIComponent(this.obj.normalizedName) : null
            },
            socials() {
                let twitter = this.obj && this.obj.socialnetworks && this.obj.socialnetworks.find(social => social.socialID === 'TwitterMain');
                let facebook = this.obj && this.obj.socialnetworks && this.obj.socialnetworks.find(social => social.socialID === 'FacebookMain');
                let linkedIn = this.obj && this.obj.socialnetworks && this.obj.socialnetworks.find(social => social.socialID === 'LinkedInMain');
                let socials = {
                    twitter: twitter ? 'https://twitter.com/' + twitter.address : undefined,
                    facebook: facebook ? 'https://www.facebook.com/' + facebook.address : undefined
                };

                if (linkedIn) {
                    socials.linkedIn = 'https://www.linkedin.com/company/' + linkedIn.address;
                } else if (this.obj && this.obj.objectType === 'person') {
                    socials.linkedIn = 'https://www.google.com/search?q=site:linkedin.com+' + encodeURIComponent(this.obj.firstName) + '+' + encodeURIComponent(this.obj.lastName);
                    if (this.obj.titles && this.obj.titles.length > 0) {
                        socials.linkedIn += '+' + encodeURIComponent(this.obj.titles[0].companyName);
                    }
                }

                return socials;
            },
        }
    }
</script>

<style scoped lang="scss">

    a.old-view-link {
        margin: 0 10px;
        font-size: small;
        color: grey;
        font-style: italic;
    }

    a.old-view-link:link {
        margin-left: 10px;
        font-size: small;
        color: grey;
        font-style: italic;
    }
    a:link .text-highlight {
        color: darkgoldenrod
    }
    a:visited .text-highlight {
        color: darkgoldenrod
    }
</style>