<template>
    <div class="flex-rows no-responsive">
        <div v-if="canApplyFilter && userBounds" class="margin-bottom-xsmall place-right"><btn @click="$emit('applygeofilter', geoFilter)">Filter by Results in View</btn></div>
    </div>

    <div v-observe-visibility="{ callback: setMapZoom, once: false }" class="map_container" ref="mapContainer" style="min-height: 60vh !important; height: 100% !important;"></div>

</template>

<script>
    import { ref, computed, inject, onMounted, watch } from 'vue'
    import { isOpenSetup } from '../../composables/isOpen.js'
    import _ from 'lodash'
    import { useStore } from 'vuex'

    export default {
        name: 'GeoMap',
        props: ['carddata', 'canApplyFilter'],
        emits: ['detailrequest', 'applygeofilter'],
        setup(props, { emit }) {

            // tools
            const store = useStore()
            const imageSite = inject('imageSite')

            // const
            const imageOptions = '&height=40&generateimage=True&frameit=True&addwings=True'

            // data
            const mapContainer = ref(null)
            const loading = ref(true)
            const hasData = ref(false)
            const { isOpen } = isOpenSetup()
            const map = ref(null)
            const clusterer = ref(null);
            const markerCount = ref(0)
            const mapBounds = ref(null)
            const userBounds = ref(null)

            // computed
            const user = computed(() => store.state.userInfo.user)
            const searchMaps = computed(() => store.state.search.searchMaps)
            const openClass = computed(() => isOpen.value ? 'collapse show' : '')
            const hits = computed(() => props.carddata && props.carddata.hits ? props.carddata.hits  : [])
            const geoFilter = computed(() => {
                var filter = {
                    key: 'geoViewport', name: 'Geo Viewport', filter: 'geoViewport'
                };

                if (userBounds.value) {
                    var ne = userBounds.value.getNorthEast(); 
                    var sw = userBounds.value.getSouthWest(); 
                    var coords = {
                        lat: ne.lat(),
                        lon: sw.lng(),

                        lat2: sw.lat(),
                        lon2: ne.lng()
                    };

                    filter.filterVal = JSON.stringify(coords);
                }

                return filter;
            })

            // methods
            const setupMap = () => {
                if (window && window.google && window.google.maps) {
                    map.value = new window.google.maps.Map(mapContainer.value)

                    var markers = prepareMapMarkers(hits.value);
                    setMapMarkerCount(markers);
                    setMapBounds(markers);
                    setMapClusterer(markers);

                    setMapZoom(true)

                    window.google.maps.event.addListener(map.value, "idle", function () {
                        userBounds.value = map.value.getBounds()
                    })
                }
            }
            const prepareMapMarkers = (hits) => {
                var markers = [];
                _.each(hits, function (hit, index) {

                    var parsedHit = !hit.primaryAddress && hit.json && _.isString(hit.json) ? JSON.parse(hit.json) : hit
                    var srLoc = parsedHit.location ? { location: parsedHit.location } : parsedHit.primaryAddress

                    if (srLoc && srLoc.location) {
                        var latlng = { lat: srLoc.location.lat, lng: srLoc.location.lon }
                        var name = parsedHit.objectType == "websession" ? parsedHit.ipAddress : parsedHit.name

                        var marker = new window.google.maps.Marker({
                            position: latlng,
                            title: name,
                            icon: icon(parsedHit)
                        })

                        marker.addListener('click', function () {
                            emit('detailrequest', { 'obj': parsedHit, 'listIndex': index, 'geoRequest': true })
                        })
                        markers.push(marker);
                    }
                })
                return markers;
            }
            const setMapMarkerCount = (markers) => {
                if (map.value && markers)
                    markerCount.value = markers.length;
            }
            const setMapBounds = (markers) => {
                if (map.value && markers) {
                    mapBounds.value = new window.google.maps.LatLngBounds()

                    _.each(markers, function (marker) {
                        mapBounds.value.extend(marker.position)
                    })
                }
            }
            const setMapClusterer = (markers) => {
                if (map.value && markers && window.markerClusterer) {
                    clusterer.value = new window.markerClusterer.MarkerClusterer({
                        map: map.value,
                        markers: markers,
                        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
                    });
                }
            }
            const setMapZoom = (isVisible, entry) => {
                if (map.value && isVisible) {
                    //look at the markers on the map to figure out zoom and center
                    if (markerCount.value && mapBounds.value) {
                        map.value.setZoom(10)
                        map.value.fitBounds(mapBounds.value)

                        //if there's only one marker then we need to zoom it out manually otherwise it will zoom in too close
                        if (markerCount.value == 1) {
                            map.value.setZoom(5)
                        }
                    }
                    //if there are no markers on the map then we show the entire map with the max zoom, and we center on the center of the map
                    else {
                        map.value.setCenter({ lat: 0, lng: 0 })
                        map.value.setZoom(2)
                    }
                }
                _.noop(entry)
            }
            const icon = (obj) => {
                var result = ""
                switch (obj.objectType) {
                    case 'company':
                        result = imageSite + '/Image/CompanyLogo?id=' + obj.id + imageOptions + "&width=60"
                        break
                    case 'acquisition':
                        result = imageSite + '/Image/Tombstone?id=' + obj.id + imageOptions
                        break
                }
                return result
            }

            // lifecycle
            onMounted(() => setupMap())

            // watch 
            watch(hits, () => { setupMap() })

            return {
                imageOptions, imageSite,
                // data
                loading, hasData, isOpen, map, markerCount, clusterer, mapBounds, mapContainer, userBounds,
                // computed
                user, searchMaps, openClass, hits, geoFilter,
                // methods
                setupMap, prepareMapMarkers, setMapMarkerCount, setMapZoom, setMapBounds, setMapClusterer, icon
            }
        }      
    };
</script>

<style scoped lang="scss">
</style>