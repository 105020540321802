<template>
    <header class="search-sidebar-header">
        <h3 class="search-results-title">
            Applied Filters
        </h3>
        <span class="place-right clear-all-filters" href="javascript:;" @click="resetFilters">
            Clear all
        </span>
    </header>
    <section class="search-sidebar-viewer" ref="elSearchSideBar">
        <div class="search-sidebar-content">
            <applied-filters :criteria="originalCriteria" :advanced="advancedModel" :exTimes="exTimes" @editfilter="editFilter" @deletefilter="deleteFilter" />
            <accordion options="sidebar">
                <accordion-panel title="Suggested Filters" v-model="panelStates['SuggestedFilters']">
                    <suggested-filters :query="originalQuery"
                                       :docTypes="selectedDocTypes"
                                       :docCounts="docCounts"
                                       :ignoreSearchInSearch="true"
                                       @addfilter="addSuggestion"
                                       @suggestions-updated="onSuggestionsUpdated" />
                </accordion-panel>
                <span class="filterPanelTop" ref="elfilterPanelTop"></span>
                <accordion-panel v-for="pnl in renderedPanels" :key="pnl.key" :title="pnl.name" v-model="panelStates[pnl.key]" :class="pnl.key + 'Panel'">
                    <section v-for="fltr in pnl.filters" :key="fltr.key" class="content-section margin-bottom-small">
                        <h4 v-if="fltr.name">{{ fltr.name }}</h4>
                        <template v-if="fltr.filterType == 'fulltext'">
                            <full-text-filter v-model="model[fltr.key]"
                                              :filterKey="fltr.key"
                                              @applyfilter="applyFilter" />
                        </template>
                        <template v-if="fltr.key == 'lobDistance'">
                            <lob-distance-filter v-model="model[fltr.key]"
                                                 :filterKey="fltr.key"
                                                 @applyfilter="applyFilter" />
                        </template>
                        <template v-if="fltr.filterType == 'lobCombo'">
                            <lob-combo-filter v-model="model[fltr.key]"
                                              :filterKey="fltr.key"
                                              @applyfilter="applyFilter" />
                        </template>
                        <template v-if="fltr.inputType == 'autocomplete' || fltr.inputType == 'lookup'">
                            <auto-complete-filter v-model="model[fltr.key]"
                                                  :filterKey="fltr.key"
                                                  :multiple="true"
                                                  :valueFieldName="fltr.filterType == 'lookup' ? 'id' : null"
                                                  :termKey="fltr.mapName"
                                                  @applyfilter="applyFilter" />
                        </template>
                        <template v-if="fltr.filterType == 'userdate'">
                            <user-date-filter v-model="model[fltr.key]"
                                              :filterKey="fltr.key"
                                              :advanced="advancedModel"
                                              @applyfilter="applyFilter" />
                        </template>
                        <template v-if="fltr.filterType == 'moneyrange'">
                            <money-range-filter v-model="model[fltr.key]"
                                                :filterKey="fltr.key"
                                                @applyfilter="applyFilter" />
                        </template>
                        <template v-if="fltr.inputType == 'checklist'">
                            <check-list-filter v-model="model[fltr.key]"
                                               :filterKey="fltr.key"
                                               @applyfilter="applyFilter" />
                        </template>
                        <template v-if="fltr.filterType == 'boolean' || fltr.inputType == 'radiolist'">
                            <radio-list-filter v-model="model[fltr.key]"
                                               :filterKey="fltr.key"
                                               @applyfilter="applyFilter" />
                        </template>
                        <template v-if="fltr.inputType == 'geodistance'">
                     
                            <geo-distance-filter v-model="model[fltr.key]"
                                                 :filterKey="fltr.key"
                                                 @applyfilter="applyFilter" />
                        </template>
                        <template v-if="fltr.inputType == 'tag'">
                            <tag-filter v-model="model[fltr.key]"
                                        :filterKey="fltr.key"
                                        :tagType="fltr.key"
                                        @applyfilter="applyFilter" />

                        </template>
                        <div v-if="fltr.isEdit" class="margin-top-small place-right">
                            <btn size="small" type="secondary" @click="cancelEdit(fltr.key)">Cancel Edit</btn>
                        </div>
                    </section>
                </accordion-panel>
                <accordion-panel title="Search In Search" v-model="panelStates['SearchInSearch']">
                    <suggested-filters :ignoreSwaps="true"
                                       :docTypes="selectedDocTypes"
                                       :docCounts="docCounts"
                                       @addfilter="addSuggestion"/>
                </accordion-panel>
                <accordion-panel title="Advanced Options" v-model="panelStates['AdvancedOptions']">
                    <div class="margin-bottom-small">
                        <checkbox v-model="advancedModel" label="Advanced Mode" :showAsSwitch="true" @focus="handleAdvancedClick" />
                    </div>
                    <div>
                        <label>
                            ES Code
                            <btn size="small" type="secondary" @click="copyEsCode">Copy</btn>
                        </label>
                        <textarea v-model="esCode" disabled rows="10"></textarea>
                    </div>
                </accordion-panel>
            </accordion>
        </div>
    </section>
    <section class="card-footer actions">
        <div class="flex-rows no-responsive">
            <div>
                <strong>Search Results</strong>
                <span class="margin-left-min">{{ resultCountFormatted }}</span>
            </div>
            <btn class="place-right" size="default" @click="applyFilter">Apply</btn>
        </div>
    </section>
</template>
<script>
    import Accordion from '../ui/accordions/Accordion.vue'
    import AccordionPanel from '../ui/accordions/AccordionPanel.vue'
    import Checkbox from '../ui/forms/Checkbox.vue'
    import FullTextFilter from './FullTextFilter.vue'
    import CheckListFilter from './CheckListFilter.vue'
    import RadioListFilter from './RadioListFilter.vue'
    import AutoCompleteFilter from './AutoCompleteFilter.vue'
    import GeoDistanceFilter from './GeoDistanceFilter.vue'
    import LobComboFilter from './LobComboFilter.vue'
    import LobDistanceFilter from './LobDistanceFilter.vue'
    import MoneyRangeFilter from './MoneyRangeFilter.vue'
    import UserDateFilter from './UserDateFilter.vue'
    import TagFilter from './TagFilter.vue'

    import AppliedFilters from './AppliedFilters.vue'
    import SuggestedFilters from './SuggestedFilters.vue'

    import { ref, computed, nextTick, inject, provide, onMounted, onBeforeUnmount } from 'vue'
    import { useStore } from 'vuex'
    import _ from 'lodash'
    import { useModelWrapper, useToast } from '../../composables/ModelWrapper.js'
    import { getSearchTools } from '../../composables/SearchTools.js'
    import { scrollToElementTop, scrollIntoView, computedSelectorRef } from '../../composables/ScrollTools.js' //,

    export default {
        name: 'SearchFilters',
        emits: ['update:modelValue', 'update:advanced', 'runsearch', 'resetquery'],
        components: {
            Accordion, AccordionPanel, Checkbox, AppliedFilters, SuggestedFilters,
            FullTextFilter, CheckListFilter, RadioListFilter, AutoCompleteFilter, GeoDistanceFilter,
            LobDistanceFilter, LobComboFilter, MoneyRangeFilter, UserDateFilter, TagFilter
        },
        props: {
            modelValue: Object, // this is the criteria object
            resultsSet: Object,
            advanced: { type: Boolean },
            EditCountSearch: { type: Function }// parent function
        },
        setup(props, { emit }) {
            // tools
            const emitter = inject("emitter")   // Inject event bus 
            const store = useStore()
            const toast = useToast()
            const nextConfirm = inject('nextConfirm')

            // models
            const model = useModelWrapper(props, emit, 'modelValue')
            const advancedModel = useModelWrapper(props, emit, 'advanced')

            // data
            const elSearchSideBar = ref(null)
            const editingFilters = ref([])
            const panelStates = ref({})

            // const
            const filterTypes = ['lookup', 'boolean', 'moneyrange', 'userdate', 'lobnode', 'fulltext', 'custom', 'typeahead']

            // state
            const virtualFilterNames = computed(() => store.state.search.virtualFilterNames)
            // getters
            const { user, panelDefs, filterDefs, aggPanelDefs, searchMaps } = getSearchTools()

            // computed
            const scrollContainerSelector = computedSelectorRef(elSearchSideBar)
            const originalQuery = computed(() => props.resultsSet ? props.resultsSet.query : null)
            const originalCriteria = computed(() => originalQuery.value && originalQuery.value.criteria ? originalQuery.value.criteria : null)
            const selectedDocTypes = computed(() => {
                if (model.value && model.value.docType && model.value.docType.value) {
                    if (_.isArray(model.value.docType.value))
                        return model.value.docType.value
                    if (model.value.docType.value.indexOf(']') > 0)
                        return JSON.parse(model.value.docType.value.toString())
                    return [model.value.docType.value.toString()]
                }
                return []
            })
            const originalCriteriaKeys = computed(() => originalCriteria.value ? Object.keys(originalCriteria.value) : [])
            const appliedFiltersCount = computed(() => originalCriteriaKeys.value.length)
            const exTimes = computed(() => props.resultsSet ? props.resultsSet.exTimes : null)
            const resultCount = computed(() => props.resultsSet && props.resultsSet.total > -1 ? props.resultsSet.total : 0)
            const resultCountFormatted = computed(() => resultCount.value > -1 ? "(" + resultCount.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ")" : null)
            const availableSorts = computed(() => props.resultsSet ? props.resultsSet.availableSorts : [])
            const availableFilters = computed(() => props.resultsSet ? props.resultsSet.availableFilters : [])
            const renderableFilters = computed(() => editingFilters.value.concat(availableFilters.value))
            const counts = computed(() => props.resultsSet ? props.resultsSet.counts : {})
            const esCode = computed(() => props.resultsSet && props.resultsSet.query ? props.resultsSet.query.detailAddress : "")
            const docCounts = computed(() => counts.value && counts.value.objectTypes ? counts.value.objectTypes : null)

            const adHocMaps = computed(() => {
                var adHocMaps = props.resultsSet && props.resultsSet.maps ? _.cloneDeep(props.resultsSet.maps) : {}
                if (renderableFilters.value) {
                    if (renderableFilters.value.includes('hasGoodPerson') && counts.value.hasGoodPersons) {
                        adHocMaps.hasGoodPersons =
                            _.map(counts.value.hasGoodPersons, function (value, key) {
                                return {
                                    id: (key == 'hasContacts').toString(),
                                    entry: key.replace(/([A-Z])/g, ' $1'),
                                    count: value,
                                    selected: false,//opt.selectedKey == key,
                                    key: (key == 'hasContacts').toString()
                                }
                            })
                    }
                    if (renderableFilters.value.includes('acqTypeIDs') && counts.value.acqTypeIDs) {
                        adHocMaps.acqTypeIDs = _.filter(_.map(searchMaps.value.acqTypeIDs, function (value, key) {
                            return {
                                id: key,
                                entry: value.Key,
                                count: counts.value.acqTypeIDs[key],
                                selected: (value.Key == 'acquisitions'),
                                key: key,
                                level: value.Value
                            }
                        }), function (cnt) { return (cnt.count > 0) })
                    }
                    //console.log('adhocMaps', model.value)
                    if (renderableFilters.value.includes('excludeKeys') && model.value.excludeKeys && model.value.excludeKeys.value) {
                        var excludeKeys = JSON.parse(model.value.excludeKeys.value)
                        var excludeKeyNames = model.value.excludeKeys.valueName && model.value.excludeKeys.valueName.startsWith('{') ? JSON.parse(model.value.excludeKeys.valueName) : (model.value.excludeKeys.valueName ? model.value.excludeKeys.valueName : excludeKeys)
                        adHocMaps.excludeKeys = []
                        for (var i = 0; i < excludeKeys.length; i++) {
                            adHocMaps.excludeKeys.push({
                                id: excludeKeys[i],
                                entry: excludeKeyNames[i],
                                count: 0,
                                selected: true,
                                url: urlFromKey(excludeKeys[i]),
                                key: excludeKeys[i]
                            })
                        }
                    }
                }
                return adHocMaps
            })

            const docTypes = computed(() => {
                return docCounts.value ?
                    _.sortBy(
                        _.filter(_.map(docCounts.value, function (value, key) {
                            return {
                                id: searchMaps.value.docTypes[key.toLowerCase()],
                                entry: searchMaps.value.docTypeDescriptions[key.toLowerCase()],
                                count: value,
                                selected: (key == 'AllDocuments'),
                                key: searchMaps.value.docTypes[key.toLowerCase()],
                                commentTypes: _.filter(commentTypes.value, function (ct) { return (ct.docType && ct.docType.toLowerCase() == key) })
                            }
                        }),
                            function (item) { return item.entry }),
                        function (docType) { return (docType.id == "AllDocuments" ? -10000000000 : docType.count * -1) })
                    : null
            })
            const commentTypes = computed(() => {
                // I am not sure about this - I think this was built for both the communications view and the search view
                if (!model.value.commentType || model.value.commentType.value == 'AllComments') {
                    var matchingDocType = _.find(docTypes.value, function (dt) { return dt.id == model.value.docType.value })
                    if (matchingDocType) {
                        var docCommentTypes = matchingDocType.commentTypes
                        if (docCommentTypes) {
                            return docCommentTypes
                        }
                    }
                }
                return (counts.value && counts.value.commentTypes) ?
                    _.filter(
                        _.map(counts.value.commentTypes, function (value, key) {
                            return {
                                id: key,
                                key: key,
                                entry: key.replace(/([A-Z])/g, ' $1'),
                                count: value,
                                selected: false,//value > 0,
                                docType: searchMaps.value.commentTypeDocumentMap[key]
                            }
                        }),
                        function (item) { return item.entry })
                    : null
            })
            const renderedPanels = computed(() => {
                var panels = []
                if (panelDefs.value && renderableFilters.value && renderableFilters.value.length > 0) {
                    Object.keys(panelDefs.value).forEach((pkey) => {
                        var pnlDef = panelDefs.value[pkey]
                        // console.log('renderedPanels', pnlDef, advancedModel.value)
                        var panel = { name: pnlDef.name, key: pnlDef.key, filters: [], openState: pnlDef.openState, editCount: 0 }
                        // console.log('renderedPanels', pnlDef )
                        pnlDef.filterNames.forEach((fltrName) => {
                            if (renderableFilters.value.includes(fltrName)) {
                                //    console.log('renderedPanels-filters', fltrName, renderableFilters.value.includes(fltrName), filterDefs.value[fltrName])
                                if (filterIsVisible(filterDefs.value[fltrName], counts.value)) {
                                    var filter = _.cloneDeep(filterDefs.value[fltrName])
                                    if (panel.filters.length == 0 && filter.name == panel.name)
                                        filter.name = ""
                                    filter.isEdit = editingFilters.value.includes(fltrName)
                                    if (filter.isEdit)
                                        panel.editCount++
                                    panel.filters.push(filter)
                                }
                            }
                        })
                        //  console.log(panels)
                        if (panel.filters.length > 0) {
                            if (panel.editCount) {
                                panel.filters.sort(value => {
                                    return value.isEdit ? -1 : 1 // `true` values first
                                })
                            }
                            // only show panel if allowed or has an editCount
                            if (panel.editCount || pnlDef.displayMode <= (advancedModel.value ? 1 : 0)) {
                                panels.push(panel)
                            }
                        }
                    })
                }
                // sort by edit
                return _.orderBy(panels, ['editCount'], ['desc'])
            })
            const renderedFilters = computed(() => {
                var rFilters = {}
                renderedPanels.value.forEach((pnl) => {
                    pnl.filters.forEach((fltr) => {
                        rFilters[fltr.key] = fltr
                    })
                })
                return rFilters
            })
            const filterPanelMap = computed(() => {
                var panelMap = {}
                if (panelDefs.value) {
                    Object.keys(panelDefs.value).forEach((pkey) => {
                        var pnlDef = panelDefs.value[pkey]
                        pnlDef.filterNames.forEach((fltrName) => {
                            panelMap[fltrName] = pkey
                        })
                    })
                }
                return panelMap
            })

            // Methods
            const filterIsVisible = (fltrDef, counts) => {
                if (fltrDef && [0, 2].includes(fltrDef.version)) {
                    if (fltrDef.key == 'lobDistance') {
                        return !!model.value[fltrDef.key]
                    }
                    // if always editable or not aggregate based
                    else if (fltrDef.alwaysEditable || !fltrDef.aggregate || !counts)
                        return true
                    // check aggregate is there and there are more than one key
                    return counts[fltrDef.aggregate] && Object.keys(counts[fltrDef.aggregate].length > 1)
                }
                return false
            }
            const mergeFilters = (newCriteria) => {
                if (newCriteria && _.isObject(newCriteria)) {

                    editingFilters.value = []
                    if (!model.value)
                        model.value = {}
                    Object.keys(newCriteria).forEach((key) => {
                        if (newCriteria[key])
                            model.value[key] = newCriteria[key]
                    })
                    virtualFilterNames.value.forEach((key) => {
                        if (model.value[key])
                            delete model.value[key]
                    })
                    emit('update:modelValue', model.value)
                    nextTick(() => {
                        scrollToElementTop(scrollContainerSelector.value, 0)
                    })
                }
            }
            const updateFilterModel = (key, newVal) => {
                console.log('updateFilterModel', key, newVal)
                model.value[key] = newVal
            }
            const applyFilter = (filtr) => {
                if (filtr && filtr.key && filtr.hasValue) {
                    model.value[filtr.key] = filtr
                }
                emit('update:modelValue', model.value)
                nextTick(() => {
                    emit('runsearch')
                })
            }
            const editFilter = (key) => {
                if (filterDefs.value[key].aggregate) {
                    if (props.EditCountSearch) {
                        props.EditCountSearch(key).then(() => {
                            openFilterEdit(key)
                        }).catch(error => {
                            console.log('editFilter-error', error)
                        })
                    }
                }
                else {
                    openFilterEdit(key)
                }
            }
            const openFilterEdit = (key) => {
                var filterModel = _.cloneDeep(model.value[key])
                filterModel.isEditing = true
                console.log('openFilterEdit', filterModel)
                editingFilters.value = []
                editingFilters.value.push(key)
                nextTick(() => {
                    panelStates.value['SuggestedFilters'] = false
                    panelStates.value[filterPanelMap.value[key]] = true
                    nextTick(() => {
                        model.value[key] = filterModel
                        nextTick(() => {
                            scrollIntoView('.filterPanelTop')
                        })
                    })
                })
            }
            const cancelEdit = (key) => {
                var filterModel = _.cloneDeep(props.resultsSet.query.criteria[key])
                editingFilters.value = editingFilters.value.filter((k) => k != key)
                panelStates.value[filterPanelMap.value[key]] = false
                nextTick(() => {
                    model.value[key] = filterModel
                    scrollToElementTop(scrollContainerSelector.value, 0)
                })
            }
            const deleteFilter = (key) => {
                console.log('deleteFilter', key, model.value[key])

                if (model.value[key]) {
                    if (key == 'excludeKeys') {
                        nextConfirm("Remove Filter", "Are you sure you want to stop excluding these results?", "warning").then((res) => {
                            if (res) {
                                delete model.value[key];
                                emit('update:modelValue', model.value)
                                nextTick(() => { emit('runsearch') })
                            }
                        });
                    }
                    else {
                        delete model.value[key];
                        emit('update:modelValue', model.value)
                        nextTick(() => { emit('runsearch') })
                    }
                }
            }
            const resetFilters = () => {
                console.log('resetFilters')
                if (model.value) {
                    model.value = {}
                    emit('update:modelValue', model.value)
                    nextTick(() => {
                        emit('resetquery')
                    })
                }
            }
            const addSuggestion = (suggestion) => {
                console.log('addSuggestion', suggestion)
                if (suggestion) {
                    if (!suggestion.flipPath) {
                        suggestion.new = true
                        suggestion.hasValue = true
                        model.value[suggestion.key] = suggestion
                        if (suggestion.related) {
                            suggestion.related.forEach((rel) => {
                                rel.hasValue = true
                                model.value[rel.key] = rel
                            })
                        }
                        applyFilter()
                    }
                    else {
                        emit('runpivotsearch', suggestion)
                    }
                }
            }
            const urlFromKey = (key) => {
                var url = null
                var keyArr = key.split('::')
                if (keyArr.length > 1)
                    url = "/" + keyArr[0] + "/details/" + keyArr[1]
                return url
            }
            const onSuggestionsUpdated = (suggestions) => {
                if (suggestions) {
                    panelStates.value['SuggestedFilters'] = true
                }
            }
            const addNodeID = (opt) => {
                if (opt && opt.nodeID) {
                    emitter.emit('addNodeID', opt)
                }
            }
            const copyEsCode = () => {
                navigator.clipboard.writeText(esCode.value).then(() => {
                    toast.add({ severity: 'success', summary: 'ElasticSearch Code Copied',life: 3000 })
                })                   
            }

            const clickTimer = ref(null)
            const handleAdvancedClick = () => {
                clearTimeout(clickTimer.value)
                    clickTimer.value = setTimeout(() => {
                        applyFilter()
                    }, 500)
            }


            // lifecycle
            onMounted(() => {
                if (!model.value) {
                    model.value = {}
                    emit('update:modelValue', model.value)
                }
                editingFilters.value = []
                panelStates.value = {}
                emitter.on("mergeFilters", mergeFilters)
            })

            onBeforeUnmount(() => {
                emitter.off("mergeFilters", mergeFilters)
            })

            provide('counts', counts)
            provide('originalCriteriaKeys', originalCriteriaKeys)
            provide('adHocMaps', adHocMaps)

            return {
                // models
                model, advancedModel,
                // data
                editingFilters, panelStates, elSearchSideBar,
                // const
                filterTypes,
                // computed
                virtualFilterNames, user, panelDefs, filterDefs, aggPanelDefs, searchMaps,
                scrollContainerSelector, originalQuery, originalCriteria, originalCriteriaKeys, selectedDocTypes, appliedFiltersCount,
                exTimes, resultCount, resultCountFormatted,
                availableSorts, availableFilters, renderableFilters, counts, esCode, docCounts, docTypes,
                adHocMaps, commentTypes, renderedPanels, renderedFilters, filterPanelMap,
                // Methods
                filterIsVisible, mergeFilters, updateFilterModel, applyFilter, editFilter, openFilterEdit, handleAdvancedClick,
                cancelEdit, deleteFilter, resetFilters, addSuggestion, urlFromKey, onSuggestionsUpdated, addNodeID, copyEsCode
            }
        }
    }
</script>
<style scoped lang="scss">
</style>
