import _ from 'lodash'
import { DateTime } from 'luxon'

export default {
    storeUtils: {
        newElementID: DateTime.now() + 'object',
        storageKey: "storedObjects",

        storeGetElements: function () {
            return JSON.parse(localStorage.getItem(this.storageKey))
        },
        storeSet: function (value) {
            var success = false
            try {
                localStorage.setItem(this.storageKey, JSON.stringify(value))
                success = true
            }
            catch (error) {
                console.log('storeSet', error)
            }

            return success
        },
        storeClear: function () {
            localStorage.clear();
        },
        storeElement: function (element, elementKey) {
            var me = this;

            //take the current list from storage 
            var storedElements = me.storeGetElements();
            if (storedElements) {
                //give the new element an id field, and add it to the table and put the table back onto storage. if it didn't exist, this will create it
                element.savedOn = DateTime.now().toFormat('LLLL dd yyyy, h:mm:ss a');
                storedElements[elementKey ? elementKey : me.newElementID] = element;

                //clean up the stored elements, make sure we stay under the limit
                if (Object.keys(storedElements).length > 10) {
                    var cleanStoredElements = {};
                    var keysToKeep = _.slice(Object.keys(storedElements), -10);
                    for (var i = 0; i < keysToKeep.length; i++) {
                        var key = keysToKeep[i];
                        cleanStoredElements[key] = storedElements[key];
                    }
                    storedElements = cleanStoredElements;
                }

                //put it back in the local storage
                if (!me.storeSet(storedElements)) {
                    console.log('storedElements is too big');
                    storedElements = {};
                    storedElements[elementKey ? elementKey : me.newElementID] = element;
                    me.storeSet(storedElements);
                }
            }
        },
        deleteAllElements: function () {
            var me = this;
            var storedElements = me.storeGetElements();
            if (storedElements) {
                me.storeSet({});
            }
        },
        deleteElement: function (id) {
            var me = this;
            if (id) {
                var storedElements = me.storeGetElements();
                if (storedElements) {
                    delete storedElements[id];
                    me.storeSet(storedElements);
                }
            }
        },
        initStorage: function (objectType) {
            var me = this;
            if (objectType) {
                me.storageKey = "stored" + objectType.charAt(0).toUpperCase() + objectType.slice(1) + "s";
                me.newElementID = DateTime.now() + objectType;

                if (!me.storeGetElements() || _.isString(me.storeGetElements()))
                    me.storeSet({});
            }
        },
    }
}