<template>
    <auto-complete v-model="selectedUsers" :suggestions="filteredUsers" @complete="search($event)" field="name"
                   :multiple="multiple" :forceSelection="true" :appendTo="appendTo" @item-select="guiSelect">
        <template #buttonSlot v-if="addMeVisible">
            <btn el="button" title="addMeTitle" @click="addMe" type="secondary" size="small">{{ addMeTitle }}</btn>
        </template>
    </auto-complete>
</template>
<script>
    import { nextTick, ref, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import _ from 'lodash'
    import { useModelWrapper } from '../../composables/ModelWrapper.js'

    import AutoComplete from '../PrimeVue/AutoComplete.vue'

    export default {
        name: 'UserSelect',
        components: {
            AutoComplete
        },
        emits: ['update:modelValue','selected'],
        props: {
            modelValue: { type: Object },
            showInactive: { type: Boolean, default: false },
            multiple: { type: Boolean, default: false },
            inpopup: { type: Boolean, default: false }
        },
        setup(props, { emit }) {
            // tools
            const store = useStore()

            // model
            const model = useModelWrapper(props, emit)

            // data
            //const selectedUsers = ref(null)
            const filteredUsers = ref([])

            // computed
            const currentUser = computed(() => store.getters['userInfo/currentUser'])
            const activeUsers = computed(() => store.getters['userInfo/activeUsers'])
            const allUsers = computed(() => store.getters['userInfo/allUsers'])
            const selectedUsers = computed({
                get: () => {
                    return props.multiple ? (model.value ? model.value : []) : (model.value ? model.value[0] : null)
                },
                set: (newVal) => {
                    if (props.multiple)
                        model.value = (newVal ? newVal : [])
                    else
                        model.value = (newVal ? [newVal] : [])
                }
            })
            /*const selectedUsersFromProps = computed(() => props.multiple ? (props.modelValue ? props.modelValue : [])
                : (props.modelValue && props.modelValue[0] ? props.modelValue[0] : null))*/
            const appendTo = computed(() => props.inpopup ? "self" : "body")
            const addMeVisible = computed(() => selectedUsers.value ?
                ((props.multiple ? (!selectedUsers.value.map((u) => u.id).includes(currentUser.value.id))
                    : (selectedUsers.value.id != currentUser.value.id)))
                : true)
            const addMeTitle = computed(() => props.multiple ? 'Add Me' : 'Pick Me')

            // methods
            const addMe = () => {
                if (props.multiple) {
                    if (!selectedUsers.value.map((u) => u.id).includes(currentUser.value.id)) {
                        var newVal = selectedUsers.value
                        newVal.push(currentUser.value)
                        selectedUsers.value = newVal
                    }
                }
                else
                    selectedUsers.value = currentUser.value

                nextTick(() => {
                    emit('selected', selectedUsers.value)
                })
            }
            const search = (event) => {
                var input = event.query.toLowerCase()
                if (input.length < 1) {
                    filteredUsers.value = [...[]]
                }
                else {
                    filteredUsers.value = props.showInactive ?
                        allUsers.value.filter(user => {
                            return user.name.toLowerCase().startsWith(input)
                        }) :
                        activeUsers.value.filter(user => {
                            return user.name.toLowerCase().startsWith(input)
                        })
                }
            }
            const guiSelect = (newVal) => {
                _.noop(newVal)
                console.log('guiSelect', newVal)

                nextTick(() => {
                    emit('selected', selectedUsers.value)
                })
            }

            // watches
           /* watch(selectedUsers, (newUsers) => {
                model.value = props.multiple ? newUsers : (newUsers ? [newUsers] : [])
              
            }, { deep: true })

            watch(selectedUsersFromProps, (newVal) => {
                selectedUsers.value = newVal
            }, { deep: true })
            */
            // lifecycle
            onMounted(() => {
                //if (!allUsers.value || allUsers.value.length == 0) store.dispatch('userInfo/fetchUsers');
                //selectedUsers.value = selectedUsersFromProps.value
                filteredUsers.value = []
            })

            return {
                // models
                model,
                // data
                filteredUsers,
                // computed
                currentUser, activeUsers, allUsers,
                selectedUsers, appendTo, addMeVisible, addMeTitle,
                // methods
                addMe, search, guiSelect
            }
        }
    }
</script>
<style lang="scss">
</style>