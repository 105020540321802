<template>
    <ul class="list-options">
        <template v-if="filterList && filterList.length > 0">
            <radio-list v-model="radioModel" :radioValues="radioValues" :labels="labels" size="xsmall" />
        </template>
        <li>
            <div class="frame-horizontal-small margin-top-min">
                <input class="fullwidth" type="text" :value="sliderRangeDescription" disabled/>
                <div class="margin-top-small">
                    <slider v-model="sliderRange" :min="sliderMin" :max="sliderMax" :range="true" :disabled="sliderDisabled"  :step="sliderStep" />
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
    import { ref, computed, onMounted, watch } from 'vue'
    import { searchFilterProps, searchFilterEmits, searchFilterSetup } from '../../composables/SearchTools.js'
    import { useStore } from 'vuex'
    import _ from 'lodash'

    import Slider from 'primevue/slider'
    import RadioList from '../ui/forms/RadioList.vue'

    export default {
        name: 'MoneyRangeFilter',
        components: { RadioList, Slider },
        props: {
            ...searchFilterProps()
        },
        emits: [...searchFilterEmits()],
        setup(props, { emit }) {
            const store = useStore()
            // models
            const { model, selectedValue, settingValue, filterDef, aggregate, aggregateCounts, apply, matchesSelectedValue } = searchFilterSetup(props, emit)

            // data 
            const sliderRange = ref([0, 500])
            const radioSelection = ref(null)
            const usingCustom = ref(false)

            // computed
            const rangeMaps = computed(() => store.state.search.searchMaps.rangeMaps)
            const moneyRanges = computed(() => store.getters['search/moneyRanges'])
            const sliderMin = computed(() => 0) // TBD should adjust to filter type
            const sliderMax = computed(() => 500)
            const sliderStep = computed(() => 10)
            const sliderDisabled = computed(() => !usingCustom.value)
            const sliderSelection = computed(() => (radioModel.value == 'custom' ? encodeNumberRange(sliderRange.value[0], sliderRange.value[1], sliderMax.value) : null))
            const radioValues = computed(() => {
                if (filterList.value) {
                    var vals = filterList.value.map((item) => { return item.id })
                    vals.push('custom')
                    return vals
                }
                return []
            })
            const labels = computed(() => {
                if (filterList.value) {
                    var labels = filterList.value.map((item) => { return item.label })
                    labels.push('Custom Range')
                    return labels
                }
                return []
            })
            const radioModel = computed({
                get: () => {
                    if (usingCustom.value) {
                        return 'custom'
                    }
                    var selected = filterList.value && model.value ? filterList.value.find((item) => { return item.id == model.value.value }) : null
                    //var selected = filterList.value ? filterList.value.find((item) => { return item.selected }) : null
                    return selected ? selected.id : null
                },
                set(newVal) {
                    //console.log('radioModel-setter', newVal)
                    var newModel = model.value
                    if (newVal == 'custom') {
                        usingCustom.value = true
                        if (sliderRange.value && sliderRange.value[0] == undefined)
                            sliderRange.value[0] = rangeLow.value
                        if (sliderRange.value && sliderRange.value[1] == undefined)
                            sliderRange.value[1] = rangeHigh.value


                        if (sliderSelection.value) {
                            newModel.value = sliderSelection.value.id
                            newModel.valueName = sliderSelection.value.entry
                            newModel.hasValue = true
                            model.value = newModel
                        }
                    }
                    else if (filterList.value && model.value) {
                        usingCustom.value = false
                        settingValue.value = true
                        var selected = filterList.value.find((item) => { return item.id == newVal })
                        if (!selected)
                            selected = sliderSelection.value

                        if (selected)
                            selected.selected = true

                        newModel.value = selected ? selected.id : null
                        newModel.valueName = selected ? selected.entry : null
                        newModel.hasValue = (selected && selected.id)
                        model.value = newModel
                        emit('applyfilter', model.value)
                        settingValue.value = false
                    }
                }
            })
            const filterMap = computed(() => rangeMaps.value[(filterDef.value.mapName ?? 'moneyRangeMap')])
            const canAutoApply = computed(() => true)
            const rangeLow = computed(() => filterDef.value.rangeLow ? filterDef.value.rangeLow : 20)
            const rangeHigh = computed(() => filterDef.value.rangeHigh ? filterDef.value.rangeHigh : 90)
            const rangeUnits = computed(() => filterDef.value.rangeUnits ? filterDef.value.rangeUnits : '$MM')
            const rangePrefix = computed(() => rangeUnits.value == '$MM' ? '$' : '')
            const rangeSuffix = computed(() => {
                if (rangeUnits.value == '$MM')
                    return 'MM'
                else if (rangeUnits.value == 'percent')
                    return '%'
                return ' ' + rangeUnits.value
            })
            const sliderRangeDescription = computed(() => sliderSelection.value ? sliderSelection.value.label : null)//[0] + ' - ' + sliderRange.value[1] + ' ' + this.rangeUnits
            const scaler = computed(() => {
                if (rangeUnits.value == '$MM')
                    return 1000000.0
                else if (rangeUnits.value == 'percent')
                    return 0.01
                return 1.0
            })
            const filterList = computed({
                get: () => {
                    //console.log('filterList-getter', props.counts, aggregate.value, aggregateCounts.value, filterMap.value)
                    if (aggregateCounts.value && filterMap.value) {
                        var list = Object.keys(aggregateCounts.value).map((key) => {
                            return {
                                id: key,
                                entry: filterMap.value[key].description,
                                count: aggregateCounts.value[key],
                                low: filterMap.value[key].low,
                                high: filterMap.value[key].high,
                                selected: matchesSelectedValue(key),
                                label: filterMap.value[key].description + (!filterDef.value.hideCounts ? ' (' + aggregateCounts.value[key] + ')' : '')
                            }
                        })
                        var sortedList = _.sortBy(list, function (item) { return (item.id == 'HasValue' ? -10000000000.0 : item.low) })
                        return sortedList
                    }
                    return []
                },
                // setter
                set: (newValue) => {
                    _.noop(newValue)
                    //console.log('filterList-setter', newValue)
                }
            })

            // methods
            const encodeNumberRange = (low, high, max) => {

                // normalize to valid moment

                var drObj = moneyRanges.value ? moneyRanges.value.find((dr) => { return (dr.low == low && dr.high == high) }) : null
                try {
                    if (!drObj) {
                        if (high == max)
                            high = "*"
                        var numRangeKey = (low > sliderMin.value ? (low * scaler.value).toExponential() : "*")
                            + "-" + (high < sliderMax.value ? (high * scaler.value).toExponential() : "*")
                        var numRangeEntry = (low > sliderMin.value ? rangePrefix.value + low.toFixed(0) + rangeSuffix.value : "Under ")
                            + (low > sliderMin.value && high < sliderMax.value ? " - " : "")
                            + (high < sliderMax.value ? rangePrefix.value + high.toFixed(0) + rangeSuffix.value : " plus")
                        drObj = {
                            id: numRangeKey,
                            entry: numRangeEntry,
                            count: 0,
                            low: low * scaler.value,
                            high: high * scaler.value,
                            label: numRangeEntry
                        }
                    }
                }
                catch (err) {
                    console.log('encodeNumberRange-err', err)
                    return null
                }
                return drObj
            }
            const formatNumber = (num) => {
                return rangePrefix.value + num.toFixed(0) + rangeSuffix.value
            }
            // watches
            watch(sliderRange, (newVal) => {
               //console.log('slideRange-watch', newVal)
                if (usingCustom.value && sliderSelection.value) {
                    var range = encodeNumberRange(newVal[0], newVal[1], sliderMax.value)
                    if (range) {
                        var newModel = model.value
                        newModel.value = range.id
                        newModel.valueName = range.entry
                        newModel.hasValue = true
                        model.value = newModel
                    }
                }
            })

            // lifecycle
            onMounted(() => {
                sliderRange.value = [rangeLow.value, rangeHigh.value]
                radioSelection.value = selectedValue.value
            })

            return {
                // model
                model, selectedValue, radioModel, filterList,
                // data
                sliderRange, radioSelection, usingCustom, settingValue,
                // computed
                rangeMaps, filterDef, aggregate, aggregateCounts, filterMap, sliderMin, sliderMax, sliderStep, sliderDisabled,
                radioValues, labels, rangeLow, rangeHigh, rangeUnits, rangePrefix, rangeSuffix,
                sliderRangeDescription, scaler, canAutoApply,
                // methods
                encodeNumberRange, formatNumber, apply, matchesSelectedValue
            }

        }
    }
</script>

<style scoped lang="scss">
    
</style>
