import { computed } from 'vue'
import { useStore } from 'vuex'
import { DateTime } from 'luxon'
import _ from 'lodash'

export function getDateRangeTools() {
    const store = useStore()

    // constants
    const edgeDates = {
        start: DateTime.local().startOf('day'),
        end: DateTime.local().plus({days:1}).startOf('day')
        //start: DateTime.fromSQL("1900-01-01"),
        //end: DateTime.local().endOf('year')
    }
    const defaultDateRange = { key: 'AllTime', description: "All Time", startDate: edgeDates.start, endDate: edgeDates.end }

    // computed
    const dateRangeMap = computed(() => store.getters["search/dateRangeMap"])
    const dateRangeKeys = computed(() => dateRangeMap.value ? Object.keys(dateRangeMap.value) : [])
    const dateRanges = computed(() => dateRangeKeys.value ? dateRangeKeys.value.map((key) => dateRangeMap.value[key]) : [])

    // methods
    const normDate = (theDate, defaultDate) => {
        var lDate = DateTime.isDateTime(theDate) ? theDate : (_.isDate(theDate) ? DateTime.fromJSDate(theDate) : DateTime.fromSQL(theDate))
        if (!lDate.isValid) { lDate = (defaultDate ? defaultDate : edgeDates.start) }
        return lDate
    }
    const decodeDateRange = (dateRangeKey) => {
        if (!dateRangeKey) {
            return null
        }

        try {
            var drObj = dateRangeMap.value[dateRangeKey]
            if (drObj) {
                return drObj
            }
            else {
                // parse start-end
                var sDateStrings = dateRangeKey.split("_")
                var startDate = DateTime.fromSQL(sDateStrings[0])
                var endDate = DateTime.fromSQL(sDateStrings[1])
                var startIsEdge = startDate.toMillis() === edgeDates.start.toMillis() // ToMillis() equivalence
                var endIsEdge = +endDate === +(edgeDates.end) // Coercion equivalence
                var dateRangeEntry = (!startIsEdge ? startDate.toLocaleString(DateTime.DATE_SHORT).replace(', 12:00:00 AM', '') + ' - ' : "Before ")
                    + (!endIsEdge ? endDate.toLocaleString(DateTime.DATE_SHORT).replace(', 12:00:00 AM', '') : " and later");

                return {
                    key: dateRangeKey,
                    description: dateRangeEntry,
                    startDate: startDate.toJSDate(),
                    endDate: endDate.toJSDate()
                }
            }
        }
        catch (err) {
            console.log('decodeDateRange-error', err)
            return null
        }
    }
    const encodeDateRange = (opt) => {
        if (opt == undefined || opt == null || opt.startDate == undefined || opt.startDate == null ||
            opt.endDate == undefined || opt.endDate == null) {
            console.log('encodeDateRange-error', 'null dates')
            return null
        }
        else {
            var startDate = normDate(opt.startDate, edgeDates.start)
            var endDate = normDate(opt.endDate, edgeDates.end)

            var drObj = dateRanges.value.find((dr) => {
                return DateTime.fromJSDate(dr.startDate).toMillis() == startDate.toMillis()
                    && DateTime.fromJSDate(dr.end).toMillis() == endDate.toMillis()
            })

            try {
                if (drObj) {
                    return drObj
                }
                else {
                    var startIsEdge = startDate.toMillis() === edgeDates.start.toMillis() // ToMillis() equivalence
                    var endIsEdge = +endDate === +(edgeDates.end) // Coercion equivalence
                    var dateRangeKey = (!startIsEdge ? startDate.toISODate() : "null") + "_" + (!endIsEdge ? endDate.toISODate() : "null")
                    var dateRangeEntry = (!startIsEdge ? startDate.toLocaleString(DateTime.DATE_SHORT).replace(', 12:00:00 AM', '') + ' - ' : "Before ")
                        + (!endIsEdge ? endDate.toLocaleString(DateTime.DATE_SHORT).replace(', 12:00:00 AM', '') : " and later");

                    return {
                        key: dateRangeKey,
                        description: dateRangeEntry,
                        startDate: startDate.toJSDate(),
                        endDate: endDate.toJSDate()
                    }
                }
            }
            catch (err) {
                console.log('encodeDateRange-error', err)
                return null
            }

        }
    }

    return {
        // constants
        edgeDates, defaultDateRange,
        // computed
        dateRangeMap, dateRangeKeys, dateRanges,
        // methods
        normDate, decodeDateRange, encodeDateRange
    }
}