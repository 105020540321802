<template>
    <section class="page-section flex-rows margin-top">
        <dropdown label="Date Range" name="test">
            <template #body>
                <menu-vertical options="panel options medium">
                    <menu-item label="Dashboard Options">
                        <template #children>
                            <menu-item @click.prevent="openShareModal" label="Share Dashboard" />
                            <menu-item label="Rename">
                                <template #children>
                                    <menu-item>
                                        <form class="form-attributes-edit">
                                            <div class="input-group nowrap">
                                                <div class="input-wrapper">
                                                    <div class="input-field">
                                                        <input class="style-alternate newDashboardName" name="name" type="text" value="JAB Company" autocomplete="off">
                                                    </div>
                                                </div>
                                                <button class="button-primary btnRenameDashboard">Save</button>
                                            </div>
                                        </form>
                                    </menu-item>
                                </template>
                            </menu-item>
                            <menu-item label="Copy Dashboard" />
                            <menu-item @click.prevent="$emit(myEvent)" label="Refresh" />
                            <menu-item label="My Alerts" />
                        </template>
                    </menu-item>
                    <menu-item label="Move">
                        <template #children>
                            <menu-item label="To Front" />
                            <menu-item label="Forward" />
                            <menu-item label="Backward" />
                            <menu-item label="To Back" />
                        </template>
                    </menu-item>
                </menu-vertical>
            </template>
        </dropdown>
        <dropdown label="Date Range" icon="calendar">
            <template #body>
                <menu-vertical options="panel options medium">
                    <menu-item label="Dashboard Options">
                        <template #children>
                            <menu-item @click.prevent="openShareModal" label="Share Dashboard" />
                            <menu-item label="Rename">
                                <template #children>
                                    <menu-item>
                                        <form class="form-attributes-edit">
                                            <div class="input-group nowrap">
                                                <div class="input-wrapper">
                                                    <div class="input-field">
                                                        <input class="style-alternate newDashboardName" name="name" type="text" value="JAB Company" autocomplete="off">
                                                    </div>
                                                </div>
                                                <button class="button-primary btnRenameDashboard">Save</button>
                                            </div>
                                        </form>
                                    </menu-item>
                                </template>
                            </menu-item>
                            <menu-item label="Copy Dashboard" />
                            <menu-item @click.prevent="$emit(myEvent)" label="Refresh" />
                            <menu-item label="My Alerts" />
                        </template>
                    </menu-item>
                    <menu-item label="Move">
                        <template #children>
                            <menu-item label="To Front" />
                            <menu-item label="Forward" />
                            <menu-item label="Backward" />
                            <menu-item label="To Back" />
                        </template>
                    </menu-item>
                </menu-vertical>
            </template>
        </dropdown>
        <dropdown type="kebab" name="test2">
            <template #body>
                <menu-vertical options="panel options medium">
                    <menu-item label="Dashboard Options">
                        <template #children>
                            <menu-item @click.prevent="openShareModal" label="Share Dashboard" />
                            <menu-item label="Rename">
                                <template #children>
                                    <menu-item>
                                        <form class="form-attributes-edit">
                                            <div class="input-group nowrap">
                                                <div class="input-wrapper">
                                                    <div class="input-field">
                                                        <input class="style-alternate newDashboardName" name="name" type="text" value="JAB Company" autocomplete="off">
                                                    </div>
                                                </div>
                                                <button class="button-primary btnRenameDashboard">Save</button>
                                            </div>
                                        </form>
                                    </menu-item>
                                </template>
                            </menu-item>
                            <menu-item label="Copy Dashboard" />
                            <menu-item @click.prevent="$emit(myEvent)" label="Refresh" />
                            <menu-item label="My Alerts" />
                        </template>
                    </menu-item>
                    <menu-item label="Move">
                        <template #children>
                            <menu-item label="To Front" />
                            <menu-item label="Forward" />
                            <menu-item label="Backward" />
                            <menu-item label="To Back" />
                        </template>
                    </menu-item>
                </menu-vertical>
            </template>
        </dropdown>
        <btn @click="testAlert('alert')">Alert</btn>
        <btn @click="testAlert('confirm')">Confirm</btn>
        <btn @click="testAlert('choice')">Choice</btn>

        <modal name="sample-modal" ref="shareModal" title="Share" v-model="modalOpen">
            <template #body>
                <section class="box options">
                    <header class="box-header">
                        <i class="fas fa-paper-plane"></i>
                        <span class="option-label">Send to</span>
                    </header>
                    <section class="box-body">
                        <a class="menu-option" href="javascript:;">
                            <form>
                                <div class="input-group">
                                    <div class="input-wrapper">
                                        <input type="text">
                                    </div>
                                    <button class="button-primary">Send</button>
                                </div>
                            </form>
                        </a>
                        <textarea class="descShareCommentEx style-alternate margin-top-small" placeholder="Add a note"></textarea>
                    </section>
                </section>
            </template>
            <template #actions>

            </template>
        </modal>

    </section>
    <section class="page-section flex-rows">
        <btn @click="$refs.shareModal.showTheModal()">Open Modal $ref</btn>
        <btn @click="modalOpen = true">Open Modal v-model</btn>
        <btn @click="openCustomAlert">Open Custom Alert</btn>
        <btn @click="openCustomConfirm">Open Custom Confirm</btn>
    </section>

    <section class="page-section margin-top hide">
        <btn @click="suggestionsPanelOpen = !suggestionsPanelOpen">Toggle Accordion</btn>
        <div class="grid-4-12 box frame-small margin-top-small">
            <accordion options="sidebar">
                <accordion-panel title="Suggested Filters" v-model="suggestionsPanelOpen">
                    suggested-filters
                </accordion-panel>
            </accordion>
        </div>
    </section>

    <section class="page-section margin-top-medium grid-5-12  card frame-small">
        <header class="flex-rows space-between margin-bottom">
            <h4 class="margin-bottom-small">Create New Card:</h4>
        </header>
        <section class="box options">
            <input class="style-alt fullwidth" type="text" placeholder="Enter title for new card..." />
            <ul class="list-horizontal distribute-evenly margin-top-min">
                <li>
                    <div class="checkbox style-box">
                        <input type="checkbox" id="cardtype01" name="cardtype01" />
                        <label for="cardtype01">Table</label>
                    </div>
                </li>
                <li>
                    <div class="checkbox style-box">
                        <input type="checkbox" id="cardtype02" name="cardtype02" />
                        <label for="cardtype02">Details</label>
                    </div>
                </li>
                <li>
                    <div class="checkbox style-box">
                        <input type="checkbox" id="cardtype03" name="cardtype03" />
                        <label for="cardtype03">Statistics</label>
                    </div>
                </li>
                <li>
                    <div class="checkbox style-box">
                        <input type="checkbox" id="cardtype04" name="cardtype04" />
                        <label for="cardtype04">Brands</label>
                    </div>
                </li>
                <li>
                    <div class="checkbox style-box">
                        <input type="checkbox" id="cardtype05" name="cardtype05" />
                        <label for="cardtype05">Tag Cloud</label>
                    </div>
                </li>
                <li>
                    <div class="checkbox style-box">
                        <input type="checkbox" id="cardtype06" name="cardtype06" />
                        <label for="cardtype06">Map</label>
                    </div>
                </li>
                <li>
                    <div class="checkbox style-box">
                        <input type="checkbox" id="cardtype07" name="cardtype07" />
                        <label for="cardtype07">Graph</label>
                    </div>
                </li>
            </ul>
            <p class="ui-hint">Select multiple types to create multiple cards</p>
            <div class="checkbox">
                <input type="checkbox" id="livedata" name="livedata" checked />
                <label for="livedata">Use live data <span class="ui-hint help" title="Explain what live data means in the context of the card"></span></label>
            </div>
        </section>
        <section class="margin-top-small">
            <label>Choose in which Dashboard you want to save your card(s)</label>
            <section class="box options">
                <section class="box-section no-border">
                    <ul class="menu-flex fullwidth">
                        <li class="menu-item has-input">
                            <a class="menu-option" href="javascript:;">
                                <i class="fas fa-columns" aria-hidden="true"></i>
                                <input type="text" placeholder="Enter name for new dashboard..."/>
                                <button class="option-apply button-primary small">Create Card</button>
                            </a>
                        </li>
                    </ul>
                </section>
                <section class="box-section no-border margin-bottom-min">
                    <div class="type-note align-center">or choose from existing dashboards</div>
                </section>
                <section class="box-section no-border">
                    <ul class="menu-flex fullwidth dashboardItemList"><li class="menu-item"><a class="menu-option" href="javascript:;"><i class="fas fa-columns" aria-hidden="true"></i><span class="option-label">Main</span><span class="option-count">6 Cards</span><button class="option-apply button-primary small">Create Card</button><!--v-if--></a></li><li class="menu-item"><a class="menu-option" href="javascript:;"><i class="fas fa-columns" aria-hidden="true"></i><span class="option-label">Companies</span><span class="option-count">2 Cards</span><button class="option-apply button-primary small">Create Card</button><!--v-if--></a></li><li class="menu-item"><a class="menu-option" href="javascript:;"><i class="fas fa-columns" aria-hidden="true"></i><span class="option-label">JAB Main</span><span class="option-count">8 Cards</span><button class="option-apply button-primary small">Create Card</button><!--v-if--></a></li><li class="menu-item"><a class="menu-option" href="javascript:;"><i class="fas fa-columns" aria-hidden="true"></i><span class="option-label">JAB News</span><span class="option-count">10 Cards</span><button class="option-apply button-primary small">Create Card</button><!--v-if--></a></li><li class="menu-item"><a class="menu-option" href="javascript:;"><i class="fas fa-columns" aria-hidden="true"></i><span class="option-label">JAB Action Items</span><span class="option-count">7 Cards</span><button class="option-apply button-primary small">Create Card</button><!--v-if--></a></li><li><button class="option-apply button-primary small">Show more</button></li></ul>
                </section>
            </section>
        </section>
    </section>

    <section class="page-section card frame-small margin-top-medium">
        <header class="flex-rows space-between margin-bottom">
            <h4 class="margin-bottom">Snapshot component:</h4>

        </header>
        <div class="grid-8-12">
            <div class="margin-bottom">
                <div class="record-status deleted">
                    <i class="fas fa-exclamation-circle"></i>
                    <span class="status-label">Deleted</span>
                    <span class="status-message">8/20/2002 by SSF - Unreachable</span>
                </div>
            </div>
            <div class="snapshot">
                <h4 class="label"><span>2006</span></h4>
                <ul>
                    <li><span class="label">Ebitda: </span><span>4.7M</span></li>
                    <li><span class="label">Rev: </span><span>17.12M</span></li>
                    <li><span class="label">Ebit: </span><span>17.12M</span></li>
                    <li><span class="label">Ebitda: </span><span>11.42M</span></li>
                </ul>
            </div>
            <div class="snapshot">
                <h4 class="label">Balance Sheet</h4>
                <ul>
                    <li><span class="label">Price & EV: </span><span>41.7M</span></li>
                    <li><span class="label">Rev: </span><span>55.12M</span></li>
                    <li><span class="label">Ebitda: </span><span>17.12M</span></li>
                    <li><span class="label">EV/Rev: </span><span>2.44</span></li>
                    <li><span class="label">EV/Ebitda: </span><span>12.44</span></li>
                </ul>
            </div>
            <div class="snapshot">
                <h4 class="label">M&A Interest</h4>
                <ul>
                    <li><span>Financing</span></li>
                    <li><span class="label">In 3 Years</span><span>(2/10/2021)</span></li>
                </ul>
            </div>
        </div>
    </section>

    <section class="page-section card frame-small margin-top-medium">
        <header class="flex-rows space-between margin-bottom">
            <h4 class="margin-bottom">Tag components:</h4>
            <dropdown label="Sort by: Changed" icon="sort-alt">
                <template #body>
                    <menu-vertical options="panel options medium" size="small">
                        <menu-item label="Entered">
                            <template #actions>
                                <btn type="icon-box" icon="long-arrow-up" size="small" />
                                <btn type="icon-box" icon="long-arrow-down" size="small" />
                            </template>
                        </menu-item>
                        <menu-item label="Changed" class="selected is-ascending">
                            <template #actions>
                                <btn type="icon-box" icon="long-arrow-up" size="small" class="selected" />
                                <btn type="icon-box" icon="long-arrow-down" size="small" />
                            </template>
                        </menu-item>
                        <menu-item label="Ultimate Parent">
                            <template #actions>
                                <btn type="icon-box" icon="long-arrow-up" size="small" />
                                <btn type="icon-box" icon="long-arrow-down" size="small" />
                            </template>
                        </menu-item>
                        <menu-item label="Revenue">
                            <template #actions>
                                <btn type="icon-box" icon="long-arrow-up" size="small" />
                                <btn type="icon-box" icon="long-arrow-down" size="small" />
                            </template>
                        </menu-item>
                        <menu-item label="Ebitda">
                            <template #actions>
                                <btn type="icon-box" icon="long-arrow-up" size="small" />
                                <btn type="icon-box" icon="long-arrow-down" size="small" />
                            </template>
                        </menu-item>
                        <menu-item isLoadToggle="true" />
                    </menu-vertical>

                </template>
            </dropdown>
        </header>
        <ul class="list-horizontal">
            <li><tag>Default Tag</tag></li>
            <li><tag type="keyword" icon="tag" :isRemovable="true">Keyword Tag</tag></li>
            <li><tag type="filter" action="add">Add Filter Tag</tag></li>
            <li><tag type="filter" action="remove">Remove Filter Tag</tag></li>
            <li><tag type="filter" action="switch">Switch Filter Tag</tag></li>
            <li><tag type="lob" class="primary" href="https://google.com">Lob Tag Primary</tag></li>
            <li><tag type="lob">Lob Tag Secondary</tag></li>
            <li><tag-basic type="keyword" :actions="['edit','remove']" name="basic" description="tag" @button-clicked="alertTest('button-clicked:' + $event)" /></li>
            <li><tag-basic type="keyword" :actions="['edit','remove']" :useEditButton="true" name="edit button" description="tag" @button-clicked="alertTest('button-clicked:' + $event)" /></li>
        </ul>
        <div class="margin-top">
            <h4 class="margin-bottom margin-top-medium">Tag Groups:</h4>
            <div class="flex-rows align-top">
                <div class="grid-4-12">
                    <ul class="list-tags">
                        <li><tag type="keyword" action="remove"><span class="desc">Revenue: </span><span>Has Number [1111]</span></tag></li>
                        <li><tag type="keyword" action="remove"><span class="desc">Lead Analysis: </span><span>Target [698]</span></tag></li>
                        <li><tag type="keyword" action="remove"><span>"technology"</span></tag></li>
                        <li><tag type="keyword" action="remove"><span class="desc">Country: </span><span>USA</span></tag></li>
                        <li><tag type="keyword" action="remove"><span>"marketing"</span></tag></li>
                    </ul>
                    <div class="tag-group margin-top-small">
                        <span class="group-label">Industry Segments:</span>
                        <tag type="keyword" action="remove">Education</tag>
                        <tag type="keyword" action="remove">Healthcare</tag>
                        <tag type="keyword" action="remove">Finance</tag>
                        <tag type="keyword" action="remove">Software</tag>
                    </div>
                    <div class="tag-group margin-top-small">
                        <span class="group-label">M&A Interest:</span>
                        <tag type="keyword" action="remove">Sell</tag>
                        <tag type="keyword" action="remove">Buy</tag>
                        <tag type="keyword" action="remove">Finance</tag>
                    </div>
                    <tag-group class="margin-top-small"
                               name="Recently Viewed:"
                               count="3,128"
                               :actions="['add']"
                               :subTags="[{name:'Last 7 days', icon:'calendar-alt'},{name:'aime.menendez'},{name:'james.berkery'}]"
                               @button-clicked="alertTest('button-clicked:' + $event)" />
                    <tag-group class="margin-top-small"
                               name="Recently Viewed:"
                               :actions="['edit', 'remove']"
                               :subTags="[{name:'Last 30 days', icon:'calendar-alt', actions:['remove']},{name:'aime.menendez', actions:['remove']},{name:'james.berkery', actions:['remove']}]"
                               @button-clicked="alertTest('button-clicked:' + $event)"
                               @subtag-clicked="alertTest('subtag-clicked:' + $event.action + ' ' + $event.tag)" />
                    <div class="tag-group margin-top-small">
                        <span class="group-label">Recently Viewed:</span>
                        <tag type="keyword" icon="calendar-alt">All Time:</tag>
                        <tag type="keyword">aime.menendez</tag>
                        <div class="group-actions">
                            <btn type="icon" icon="times"></btn>
                        </div>
                    </div>
                    <div class="tag-group margin-top-small">
                        <span class="group-label">Recently Viewed <span class="badge">(3128)</span></span>
                        <tag type="keyword" icon="calendar-alt">This Year:</tag>
                        <tag type="keyword">james.berkery</tag>
                        <btn type="icon-box" icon="plus"></btn>
                    </div>
                    <div class="tag-group margin-top-small">
                        <span class="group-label">Recently Viewed</span>
                        <tag type="keyword" icon="calendar-alt">This Year:</tag>
                        <tag type="keyword">james.berkery</tag>
                        <btn type="icon-box" icon="edit"></btn>
                        <btn type="icon-box" icon="times"></btn>
                    </div>
                    <div class="tag-group margin-top-small">
                        <span class="group-label">Recently Viewed</span>
                        <tag type="keyword" icon="calendar-alt">This Year:</tag>
                        <tag type="keyword">james.berkery</tag>
                        <btn type="icon-box" icon="times"></btn>
                    </div>
                </div>
                <div class="grid-10-12 frame-left-large">
                    <tag-group class="horizontal"
                               name="2021:"
                               :subTags="[{ name:'$', title:'in dollars'},{description: 'Rev', name:'16,57B'},{description: 'Ebitda', name:'266,90M'},{description: 'Emp', name:'23,000'}]" />
                    <div class="tag-group horizontal">
                        <span class="group-label">2021:</span>
                        <tag type="keyword">$</tag>
                        <tag type="keyword"><span class="desc">Rev: </span><span>16,576.07 <span class="ammount-denominator">B</span></span></tag>
                        <tag type="keyword"><span class="desc">Ebitda: </span><span>266,900.19 <span class="ammount-denominator">M</span></span></tag>
                        <tag type="keyword"><span class="desc">Emp: </span><span>23,000</span></tag>
                    </div>
                    <div class="tag-group horizontal not-boxed margin-top">
                        <span class="group-label">2021:</span>
                        <tag type="keyword">$</tag>
                        <tag type="keyword"><span class="desc">Rev: </span><span>16,576.07 <span class="ammount-denominator">B</span></span></tag>
                        <tag type="keyword"><span class="desc">Ebitda: </span><span>266,900.19 <span class="ammount-denominator">M</span></span></tag>
                        <tag type="keyword"><span class="desc">Emp: </span><span>23,000</span></tag>
                    </div>

                    <div class="tag-group grid-6-12 margin-top">
                        <span class="group-label">Recently Viewed:</span>
                        <tag type="keyword" icon="calendar">All Time:</tag>
                        <tag type="keyword">james.berkery, keith.lubell, aime.menendez</tag>
                        <div class="group-actions">
                            <btn type="icon" icon="plus"></btn>
                        </div>
                    </div>
                    <div class="grid-12-12 margin-top hide">
                        <div class="tag-group grid-6-12">
                            <span class="group-label">Recently Viewed (All Time):</span>
                            <tag type="keyword" count="12">james.berkery, keith.lubell, aime.menendez</tag>
                            <div class="group-actions">
                                <btn type="icon-box" icon="trash"></btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="pager margin-top-medium">
        <div class="page-view">
            <select class="drpPageSize select2-hidden-accessible" style="width:100%;" data-select2-id="select2-data-21-mebo" tabindex="-1" aria-hidden="true"><option value="5" data-select2-id="select2-data-23-kgs2">5 Entries</option><option value="15" data-select2-id="select2-data-24-j5yf">15 Entries</option><option value="30" data-select2-id="select2-data-25-ffom">30 Entries</option></select><span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="select2-data-22-1dbu" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-9rkw-container"><span class="select2-selection__rendered" id="select2-9rkw-container" role="textbox" aria-readonly="true" title="30 Entries">30 Entries</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
        </div>
        <div class="page-selection">
            <ul class="menu-horizontal pagination">
                <li class="page previous pagerPage" data-skip="0">
                    <i class="fas fa-chevron-left"></i>
                </li>

                <li class="page pagerCurrent active" data-skip="0">1</li>

                <li class="page pagerPage" data-skip="30">2</li>

                <li class="page pagerPage" data-skip="60">3</li>

                <li class="page pagerPage" data-skip="90">4</li>

                <li class="page pagerPage" data-skip="120">5</li>

                <li class="page pagerPage" data-skip="150">6</li>

                <li class="page pagerPage" data-skip="180">7</li>

                <li class="page pagerPage" data-skip="210">8</li>

                <li class="page pagerPage" data-skip="240">9</li>

                <li class="page pagerPage" data-skip="270">10</li>

                <li class="page next pagerPage" data-skip="30">
                    <i class="fas fa-chevron-right"></i>
                </li>
            </ul>
        </div>
    </div>

    <section class="page-section margin-top-small card frame-small">
        <h4 class="margin-bottom">List components:</h4>
        <list-table name="customname1">
            <list-item v-for="datum in tabData" :key="datum.title">
                <template #label>
                    {{ datum.title }}
                </template>
                <template #content>
                    {{ datum.body }}
                </template>
            </list-item>
        </list-table>
        <div class="frame-top"></div>
        <list-blocks direction="horizontal">
            <list-item v-for="datum in tabData" :key="datum.title">
                {{ datum.body }}
            </list-item>
        </list-blocks>
    </section>
    <section class="page-section margin-top-small card frame-small">
        <h4 class="margin-bottom">Table components:</h4>
        <table-flex name="table-new">
            <table-row name="estimated financial10KInfo" :expansion="true">
                <table-cell title="Year" name="year">
                    <h3 data-name="year">
                        2019
                        <span data-name="financialAccuracyID" class="tag-accuracy estimated hide">E</span>
                    </h3>
                </table-cell>
                <table-cell title="Revenue">
                    <div class="financials">
                        <span class="flex-rows no-responsive">
                            <span class="value">$6,501.00</span>
                            <span class="trend-indicator up"></span>
                        </span>
                        <span class="percentage">3.9%</span>
                    </div>
                </table-cell>
                <table-cell title="Ebitda">
                    <div class="financials">
                        <span class="flex-rows no-responsive">
                            <span class="value">$3,256.00</span>
                        </span>
                        <span class="percentage">50.1%</span>
                    </div>
                </table-cell>
                <table-cell title="Net Income" hideon="smallscreen">
                    <div class="financials">
                        <span class="flex-rows no-responsive">
                            <span class="value">$2,093.00</span>
                        </span>
                        <span class="percentage">32.2%</span>
                    </div>
                </table-cell>
                <table-cell title="Ebit" hideon="smallscreen">
                    <div class="financials">
                        <span class="flex-rows no-responsive">
                            <span class="value">$3,048.00</span>
                        </span>
                        <span class="percentage">46.9%</span>
                    </div>
                </table-cell>
                <table-cell title="Emp" hideon="smallscreen">
                    <span class="value large">21,200</span>
                </table-cell>
                <table-cell title="Actions">
                    <btn type="icon-box" size="small"><i class="fas fa-trash-alt"></i></btn>
                    <btn type="icon-box" size="small"><i class="fas fa-chevron-down"></i></btn>
                </table-cell>
                <template #expansion>
                    <div class="cell-section">
                        <ul class="column list-table">
                            <li>
                                <span class="label">Accuracy:</span>
                                <span class="financial-field data flex-rows no-responsive">
                                    <select name="financialAccuracy"><option value="0">NA</option><option value="1">Actual</option><option value="2">Projected</option><option value="3">Estimated</option><option value="4">Guess</option></select>
                                    <span data-name="financialAccuracyID" class="tag-accuracy estimated">E</span>
                                </span>
                            </li>
                            <li>
                                <span class="label">Currency:</span>
                                <span class="financial-field data flex-rows no-responsive">
                                    <select name="currency"><option value="1">U.S.$</option><option value="2">U.K.£</option><option value="3">SFranc</option><option value="4">¥en</option><option value="5">Euro</option><option value="8">CAD $</option><option value="9">AUD $</option><option value="10">South African Rand</option><option value="11">Indian Rupee</option><option value="13">Hong Kong Dollar</option><option value="14">Chinese Yuan</option><option value="16">Brazilian Real</option><option value="17">Danish Kroner</option><option value="18">Malaysian Ringgit</option><option value="19">Mexican Peso</option><option value="20">N.Z. $</option><option value="21">Norway Krone</option><option value="22">South Korean Won</option><option value="23">Swedish Krona</option><option value="24">Taiwan $</option><option value="25">Thai Baht</option><option value="26">Singapore $</option><option value="27">Sri Lankan Rupee</option><option value="28">Venezuelian Bolivar</option></select>
                                    <span class="currency"><i class="fas fa-dollar-sign type-medium"></i></span>
                                </span>
                            </li>
                            <li>
                                <span class="label">Confidential:</span>
                                <span class="financial-field data">
                                    <div class="checkbox">
                                        <input type="checkbox" data-pk="2010" data-name="confidential" id="c923-confidential" name="confidential" value="confidential" class="drpConfidential" checked="">
                                        <label for="c923-confidential"></label>
                                    </div>
                                </span>
                            </li>
                            <li>
                                <span class="label">Public:</span>
                                <span class="financial-field data">
                                    <div class="checkbox">
                                        <input type="checkbox" data-pk="2010" data-name="financialIsPublic" id="c923-financialIsPublic" name="financialIsPublic" value="financialIsPublic" class="drpFinancialIsPublic">
                                        <label for="c923-financialIsPublic"></label>
                                    </div>
                                </span>
                            </li>
                            <li>
                                <span class="label"><button type="button" class="button-secondary btnUpdateTicker d-none" title="Ticker financial data possibly out of date. Click to automatically update financials."><strong> ! </strong></button><button type="button" class="button-tertiary btnShowTickerSearch" title="Click to look up public income statements.">Ticker:</button></span>
                                <span class="financial-field data">
                                    <input size="5" type="text" data-pk="2010" data-name="tickerSymbol" class="style-alt" value="">
                                </span>
                            </li>
                        </ul>
                        <ul class="column list-table only-on-smallscreen">
                            <li class="">
                                <span class="label">EBIT:</span>
                                <span class="financial-field data">
                                    <input size="5" type="text" data-pk="2010" data-name="ebit" class="data-edit inline txtMillions numeric" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">% of Rev:</span>
                                <span class="financial-field data">
                                    <input size="5" type="text" data-pk="2010" data-name="ebit" class="data-edit inline txtRevenuePercentage numeric" value="0.0%">
                                </span>
                            </li>
                            <li class="">
                                <span class="label">Net Income:</span>
                                <span class="financial-field data">
                                    <input size="5" type="text" data-pk="2010" data-name="netIncome" class="data-edit inline txtMillions numeric" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">% of Rev:</span>
                                <span class="financial-field data">
                                    <input size="5" type="text" data-pk="2010" data-name="netIncome" class="data-edit inline txtRevenuePercentage numeric" value="0.0%">
                                </span>
                            </li>
                        </ul>
                        <ul class="column list-table">
                            <li>
                                <span class="label">Tax Status:</span>
                                <span class="data">
                                    <dropdown label="Unknown" name="test">
                                        <template #body>
                                            <menu-vertical options="panel options medium">
                                                <menu-item label="Unknown" />
                                                <menu-item label="SUB_S" />
                                                <menu-item label="REG_C" />
                                                <menu-item label="NFPROFIT" />
                                                <menu-item label="CCORP" />
                                                <menu-item label="ESOP" />
                                            </menu-vertical>
                                        </template>
                                    </dropdown>
                                </span>
                            </li>
                            <li>
                                <span class="label">Last Edited:</span>
                                <span class="data">

                                    11/09/2020, 12:39 by JEB

                                </span>
                            </li>
                            <li class="block">
                                <div class="entry-notes"> </div>
                                <span class="label">Reliability:</span>
                                <span class="fullwidth">
                                    <textarea data-type="textarea" data-pk="2010" data-name="description" class="txtDescription style-alt" rows="3">Last Changed: 02/04/11 - JEB</textarea>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="cell-section">
                        <div style="flex: 0 0 100%;">
                            <h5>Public Financial Data</h5>
                        </div>
                        <ul class="column list-table public-financial-data fullwidth">
                            <li>
                                <span class="label">Stock Price:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="text" data-pk="2010" data-name="stockPriceYearEnd" class="style-alt" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">Outstanding Shares:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="number" data-pk="2010" data-name="outstandingShares" class="style-alt" value="0">
                                </span>
                            </li>
                            <li>
                                <span class="label">Market Cap:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="text" data-pk="2010" data-name="marketCap" class="style-alt" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">Net Debt:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="text" data-pk="2010" data-name="netDebt" class="style-alt" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">Preferred Equity:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="text" data-pk="2010" data-name="preferredEquity" class="style-alt" value="0.00">
                                </span>
                            </li>
                        </ul>
                        <ul class="column list-table public-financial-data fullwidth">
                            <li>
                                <span class="label">Enterprise Value:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="text" data-pk="2010" data-name="enterpriseValue" class="style-alt" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">Revenue Multiple:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="number" data-pk="2010" data-name="revenueMultiple" class="style-alt" value="">
                                </span>
                            </li>
                            <li>
                                <span class="label">EBITDA Multiple:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="number" data-pk="2010" data-name="ebitdaMultiple" class="style-alt" value="">
                                </span>
                            </li>
                            <li>
                                <span class="label">Ticker Fail Count:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="number" data-pk="2010" data-name="tickerFailCount" class="style-alt" value="0">
                                </span>
                            </li>
                            <li>
                                <span class="label">Ticker Verify Date Count:</span>
                                <span class="financial-field data">

                                </span>
                            </li>
                        </ul>
                    </div>
                </template>
            </table-row>
            <table-row name="estimated financial10KInfo" :expansion="true">
                <table-cell title="Year" name="year">
                    <h3 data-name="year">
                        2018
                        <span data-name="financialAccuracyID" class="tag-accuracy estimated hide">E</span>
                    </h3>
                </table-cell>
                <table-cell title="Revenue">
                    <div class="financials">
                        <span class="flex-rows no-responsive">
                            <span class="value">$6,258.00</span>
                            <span class="trend-indicator up"></span>
                        </span>
                        <span class="percentage">3.2%</span>
                    </div>
                </table-cell>
                <table-cell title="Ebitda">
                    <div class="financials">
                        <span class="flex-rows no-responsive">
                            <span class="value">$3,167.00</span>
                        </span>
                        <span class="percentage">50.6%</span>
                    </div>
                </table-cell>
                <table-cell title="Net Income" hideon="smallscreen">
                    <div class="financials">
                        <span class="flex-rows no-responsive">
                            <span class="value">$1,958.00</span>
                        </span>
                        <span class="percentage">31.3%</span>
                    </div>
                </table-cell>
                <table-cell title="Ebit" hideon="smallscreen">
                    <div class="financials">
                        <span class="flex-rows no-responsive">
                            <span class="value">$2,961.00</span>
                        </span>
                        <span class="percentage">47.3%</span>
                    </div>
                </table-cell>
                <table-cell title="Emp" hideon="smallscreen">
                    <span class="value large">21,200</span>
                </table-cell>
                <table-cell title="Actions">
                    <btn type="icon-box" size="small"><i class="fas fa-trash-alt"></i></btn>
                    <btn type="icon-box" size="small"><i class="fas fa-chevron-down"></i></btn>
                </table-cell>
                <template #expansion>
                    <div class="cell-section">
                        <ul class="column list-table">
                            <li>
                                <span class="label">Accuracy:</span>
                                <span class="financial-field data flex-rows no-responsive">
                                    <select name="financialAccuracy"><option value="0">NA</option><option value="1">Actual</option><option value="2">Projected</option><option value="3">Estimated</option><option value="4">Guess</option></select>
                                    <span data-name="financialAccuracyID" class="tag-accuracy estimated">E</span>
                                </span>
                            </li>
                            <li>
                                <span class="label">Currency:</span>
                                <span class="financial-field data flex-rows no-responsive">
                                    <select name="currency"><option value="1">U.S.$</option><option value="2">U.K.£</option><option value="3">SFranc</option><option value="4">¥en</option><option value="5">Euro</option><option value="8">CAD $</option><option value="9">AUD $</option><option value="10">South African Rand</option><option value="11">Indian Rupee</option><option value="13">Hong Kong Dollar</option><option value="14">Chinese Yuan</option><option value="16">Brazilian Real</option><option value="17">Danish Kroner</option><option value="18">Malaysian Ringgit</option><option value="19">Mexican Peso</option><option value="20">N.Z. $</option><option value="21">Norway Krone</option><option value="22">South Korean Won</option><option value="23">Swedish Krona</option><option value="24">Taiwan $</option><option value="25">Thai Baht</option><option value="26">Singapore $</option><option value="27">Sri Lankan Rupee</option><option value="28">Venezuelian Bolivar</option></select>
                                    <span class="currency"><i class="fas fa-dollar-sign type-medium"></i></span>
                                </span>
                            </li>
                            <li>
                                <span class="label">Confidential:</span>
                                <span class="financial-field data">
                                    <div class="checkbox">
                                        <input type="checkbox" data-pk="2010" data-name="confidential" id="c923-confidential" name="confidential" value="confidential" class="drpConfidential" checked="">
                                        <label for="c923-confidential"></label>
                                    </div>
                                </span>
                            </li>
                            <li>
                                <span class="label">Public:</span>
                                <span class="financial-field data">
                                    <div class="checkbox">
                                        <input type="checkbox" data-pk="2010" data-name="financialIsPublic" id="c923-financialIsPublic" name="financialIsPublic" value="financialIsPublic" class="drpFinancialIsPublic">
                                        <label for="c923-financialIsPublic"></label>
                                    </div>
                                </span>
                            </li>
                            <li>
                                <span class="label"><button type="button" class="button-secondary btnUpdateTicker d-none" title="Ticker financial data possibly out of date. Click to automatically update financials."><strong> ! </strong></button><button type="button" class="button-tertiary btnShowTickerSearch" title="Click to look up public income statements.">Ticker:</button></span>
                                <span class="financial-field data">
                                    <input size="5" type="text" data-pk="2010" data-name="tickerSymbol" class="style-alt" value="">
                                </span>
                            </li>
                        </ul>
                        <ul class="column list-table only-on-smallscreen">
                            <li class="">
                                <span class="label">EBIT:</span>
                                <span class="financial-field data">
                                    <input size="5" type="text" data-pk="2010" data-name="ebit" class="data-edit inline txtMillions numeric" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">% of Rev:</span>
                                <span class="financial-field data">
                                    <input size="5" type="text" data-pk="2010" data-name="ebit" class="data-edit inline txtRevenuePercentage numeric" value="0.0%">
                                </span>
                            </li>
                            <li class="">
                                <span class="label">Net Income:</span>
                                <span class="financial-field data">
                                    <input size="5" type="text" data-pk="2010" data-name="netIncome" class="data-edit inline txtMillions numeric" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">% of Rev:</span>
                                <span class="financial-field data">
                                    <input size="5" type="text" data-pk="2010" data-name="netIncome" class="data-edit inline txtRevenuePercentage numeric" value="0.0%">
                                </span>
                            </li>
                        </ul>
                        <ul class="column list-table">
                            <li>
                                <span class="label">Tax Status:</span>
                                <span class="data">
                                    <dropdown label="Unknown" name="test">
                                        <template #body>
                                            <menu-vertical options="panel options medium">
                                                <menu-item label="Unknown" />
                                                <menu-item label="SUB_S" />
                                                <menu-item label="REG_C" />
                                                <menu-item label="NFPROFIT" />
                                                <menu-item label="CCORP" />
                                                <menu-item label="ESOP" />
                                            </menu-vertical>
                                        </template>
                                    </dropdown>
                                </span>
                            </li>
                            <li>
                                <span class="label">Last Edited:</span>
                                <span class="data">

                                    11/09/2020, 12:39 by JEB

                                </span>
                            </li>
                            <li>
                                <div class="entry-notes"> </div>
                                <span class="label">Reliability:</span>
                                <span class="data">
                                    <textarea data-type="textarea" data-pk="2010" data-name="description" class="txtDescription style-alt" rows="3">Last Changed: 02/04/11 - JEB</textarea>
                                </span>
                            </li>
                            <li>
                                <span class="label hide"></span>
                                <span class="data align-left">
                                    <button data-name="deleteFinancialButton" class="button-secondary small btnDelete" type="button" data-pk="2010">Delete</button>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="cell-section hide">
                        <div style="flex: 0 0 100%;">
                            <h5>Public Financial Data</h5>
                        </div>
                        <ul class="column list-table public-financial-data fullwidth">
                            <li>
                                <span class="label">Stock Price:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="text" data-pk="2010" data-name="stockPriceYearEnd" class="style-alt" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">Outstanding Shares:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="number" data-pk="2010" data-name="outstandingShares" class="style-alt" value="0">
                                </span>
                            </li>
                            <li>
                                <span class="label">Market Cap:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="text" data-pk="2010" data-name="marketCap" class="style-alt" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">Net Debt:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="text" data-pk="2010" data-name="netDebt" class="style-alt" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">Preferred Equity:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="text" data-pk="2010" data-name="preferredEquity" class="style-alt" value="0.00">
                                </span>
                            </li>
                        </ul>
                        <ul class="column list-table public-financial-data fullwidth">
                            <li>
                                <span class="label">Enterprise Value:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="text" data-pk="2010" data-name="enterpriseValue" class="style-alt" value="0.00">
                                </span>
                            </li>
                            <li>
                                <span class="label">Revenue Multiple:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="number" data-pk="2010" data-name="revenueMultiple" class="style-alt" value="">
                                </span>
                            </li>
                            <li>
                                <span class="label">EBITDA Multiple:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="number" data-pk="2010" data-name="ebitdaMultiple" class="style-alt" value="">
                                </span>
                            </li>
                            <li>
                                <span class="label">Ticker Fail Count:</span>
                                <span class="financial-field data">
                                    <input size="5" disabled="" type="number" data-pk="2010" data-name="tickerFailCount" class="style-alt" value="0">
                                </span>
                            </li>
                            <li>
                                <span class="label">Ticker Verify Date Count:</span>
                                <span class="financial-field data">

                                </span>
                            </li>
                        </ul>
                    </div>
                </template>
            </table-row>
        </table-flex>
    </section>
    <section class="page-section margin-top-small card frame-small">
        <h4 class="margin-bottom">Table components:</h4>
        <table-flex name="table-financial">
            <table-row name="estimated financial10KInfo" :expansion="true">
                <table-cell title="Year" name="year">
                    <h3 data-name="year">
                        2011
                        <span data-name="financialAccuracyID" class="tag-accuracy estimated">E</span>
                    </h3>
                    <a class="button-secondary" href="">More details</a>
                </table-cell>
                <table-cell title="Revenue">
                    <span class="label">Revenue:</span>
                    <span class="financial-field data">
                        <CurrencyInput v-model="value" :options="{ currency: 'USD' }" class="value data-edit inline txtMillions numeric" />
                        <span class="trend-indicator "></span>
                        <span class="financial-field value-percentage" style="opacity: 100;">
                            <input data-pk="2010" data-name="revenue" class="txtRevenueAnnualGrowth numeric data-edit inline" type="text" value="0.0%" size="3">
                        </span>
                    </span>
                </table-cell>
                <table-cell title="Ebitda">
                    <span class="label">EBITDA:</span>
                    <span class="financial-field data">
                        <input size="12" type="text" data-pk="2010" data-name="ebitda" class="value data-edit inline txtMillions numeric" value="0.00">
                        <span class="trend-indicator"></span>
                        <span class="financial-field value-percentage" style="opacity: 100;">
                            <input data-type="text" data-pk="2010" data-name="ebitda" class="txtRevenuePercentage data-edit inline" type="text" value="0.0%" size="3">
                        </span>
                    </span>
                </table-cell>
                <table-cell title="Emp">
                    <span class="label">Employees:</span>
                    <span class="financial-field data">
                        <input size="5" type="number" data-pk="2010" data-name="employeeSize" class="data-edit inline txtEmployeeSize numeric" value="10">
                    </span>
                </table-cell>
                <template #expansion>
                    <ul class="column list-table">
                        <li>
                            <span class="label">Accuracy:</span>
                            <span class="financial-field data flex-rows no-responsive">
                                <smart-select @selected="selectXselected($event)" labelfield="name" valuefield="code" :choices="countries" initialchoice="FI" />
                                <span data-name="financialAccuracyID" class="tag-accuracy estimated">E</span>
                            </span>
                        </li>
                        <li>
                            <span class="label">Currency:</span>
                            <span class="financial-field data flex-rows no-responsive">
                                <smart-select @selected="selectXselected($event)" labelfield="name" valuefield="code" :choices="countries" initialchoice="FI" />
                                <span class="currency"><i class="fas fa-dollar-sign type-medium"></i></span>
                            </span>
                        </li>
                        <li>
                            <span class="label">Confidential:</span>
                            <span class="financial-field data">
                                <div class="checkbox">
                                    <input type="checkbox" data-pk="2010" data-name="confidential" id="c923-confidential" name="confidential" value="confidential" class="drpConfidential" checked="">
                                    <label for="c923-confidential"></label>
                                </div>
                            </span>
                        </li>
                        <li>
                            <span class="label">Public:</span>
                            <span class="financial-field data">
                                <div class="checkbox">
                                    <input type="checkbox" data-pk="2010" data-name="financialIsPublic" id="c923-financialIsPublic" name="financialIsPublic" value="financialIsPublic" class="drpFinancialIsPublic">
                                    <label for="c923-financialIsPublic"></label>
                                </div>
                            </span>
                        </li>
                        <li>
                            <span class="label"><button type="button" class="button-secondary btnUpdateTicker d-none" title="Ticker financial data possibly out of date. Click to automatically update financials."><strong> ! </strong></button><button type="button" class="button-tertiary btnShowTickerSearch" title="Click to look up public income statements.">Ticker:</button></span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="tickerSymbol" class="data-edit inline txtTickerSymbol" value="">
                            </span>
                        </li>
                    </ul>
                    <ul class="column list-table">
                        <li class="">
                            <span class="label">EBIT:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="ebit" class="data-edit inline txtMillions numeric" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">% of Rev:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="ebit" class="data-edit inline txtRevenuePercentage numeric" value="0.0%">
                            </span>
                        </li>
                        <li class="">
                            <span class="label">Net Income:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="netIncome" class="data-edit inline txtMillions numeric" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">% of Rev:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="netIncome" class="data-edit inline txtRevenuePercentage numeric" value="0.0%">
                            </span>
                        </li>
                    </ul>
                    <ul class="column list-table public-financial-data d-none">
                        <li>
                            <span class="label">Public Financial Data</span>
                        </li>
                        <li>
                            <span class="label">Stock Price:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="stockPriceYearEnd" class="lock-disable txtMoney numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Outstanding Shares:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="outstandingShares" class="lock-disable txtOutstandingShares numeric data-edit inline" value="0">
                            </span>
                        </li>
                        <li>
                            <span class="label">Market Cap:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="marketCap" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Net Debt:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="netDebt" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Preferred Equity:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="preferredEquity" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Enterprise Value:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="enterpriseValue" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Revenue Multiple:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="revenueMultiple" class="lock-disable txtRevMultiple numeric data-edit inline" value="">
                            </span>
                        </li>
                        <li>
                            <span class="label">EBITDA Multiple:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="ebitdaMultiple" class="lock-disable txtEbitdaMultiple numeric data-edit inline" value="">
                            </span>
                        </li>
                        <li>
                            <span class="label">Ticker Fail Count:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="tickerFailCount" class="lock-disable numeric data-edit inline" value="0">
                            </span>
                        </li>
                        <li>
                            <span class="label">Ticker Verify Date Count:</span>
                            <span class="financial-field data">

                            </span>
                        </li>
                    </ul>
                    <ul class="column list-table">
                        <li>
                            <span class="label">Tax Status:</span>
                            <span class="data">
                                <dropdown label="Unknown" name="test">
                                    <template #body>
                                        <menu-vertical options="panel options medium">
                                            <menu-item label="Unknown" />
                                            <menu-item label="SUB_S" />
                                            <menu-item label="REG_C" />
                                            <menu-item label="NFPROFIT" />
                                            <menu-item label="CCORP" />
                                            <menu-item label="ESOP" />
                                        </menu-vertical>
                                    </template>
                                </dropdown>
                            </span>
                        </li>
                        <li>
                            <span class="label">Last Edited:</span>
                            <span class="data">

                                11/09/2020, 12:39 by JEB

                            </span>
                        </li>
                        <li>
                            <div class="entry-notes"> </div>
                            <span class="label">Reliability:</span>
                            <span class="data">
                                <textarea data-type="textarea" data-pk="2010" data-name="description" class="txtDescription" rows="3">Last Changed: 02/04/11 - JEB</textarea>
                            </span>
                        </li>
                        <li>
                            <span class="label hide"></span>
                            <span class="data align-left">
                                <button data-name="deleteFinancialButton" class="button-secondary small btnDelete" type="button" data-pk="2010">Delete</button>
                            </span>
                        </li>
                    </ul>
                </template>
            </table-row>
            <table-row name="estimated financial10KInfo" :expansion="true">
                <table-cell title="Year" name="year">
                    <h3 data-name="year">
                        2010
                        <span data-name="financialAccuracyID" class="tag-accuracy estimated">E</span>
                    </h3>
                    <a class="button-secondary" href="">More details</a>
                </table-cell>
                <table-cell title="Revenue">
                    <span class="label">Revenue:</span>
                    <span class="financial-field data">
                        <input size="5" class="value data-edit inline txtMillions numeric" type="text" data-pk="2010" data-name="revenue" value="10.00">
                        <span class="trend-indicator "></span>
                        <span class="financial-field value-percentage" style="opacity: 100;">
                            <input data-pk="2010" data-name="revenue" class="txtRevenueAnnualGrowth numeric data-edit inline" type="text" value="0.0%" size="3">
                        </span>
                    </span>
                </table-cell>
                <table-cell title="Ebitda">
                    <span class="label">EBITDA:</span>
                    <span class="financial-field data">
                        <input size="12" type="text" data-pk="2010" data-name="ebitda" class="value data-edit inline txtMillions numeric" value="0.00">
                        <span class="trend-indicator"></span>
                        <span class="financial-field value-percentage" style="opacity: 100;">
                            <input data-type="text" data-pk="2010" data-name="ebitda" class="txtRevenuePercentage data-edit inline" type="text" value="0.0%" size="3">
                        </span>
                    </span>
                </table-cell>
                <table-cell title="Emp">
                    <span class="label">Employees:</span>
                    <span class="financial-field data">
                        <input size="5" type="number" data-pk="2010" data-name="employeeSize" class="data-edit inline txtEmployeeSize numeric" value="10">
                    </span>
                </table-cell>
                <template #expansion>
                    <ul class="column list-table">
                        <li>
                            <span class="label">Accuracy:</span>
                            <span class="financial-field data flex-rows no-responsive">
                                <smart-select @selected="selectXselected($event)" labelfield="name" valuefield="code" :choices="countries" initialchoice="FI" />
                                <span data-name="financialAccuracyID" class="tag-accuracy estimated">E</span>
                            </span>
                        </li>
                        <li>
                            <span class="label">Currency:</span>
                            <span class="financial-field data flex-rows no-responsive">
                                <smart-select @selected="selectXselected($event)" labelfield="name" valuefield="code" :choices="countries" initialchoice="FI" />
                                <span class="currency"><i class="fas fa-dollar-sign type-medium"></i></span>
                            </span>
                        </li>
                        <li>
                            <span class="label">Confidential:</span>
                            <span class="financial-field data">
                                <div class="checkbox">
                                    <input type="checkbox" data-pk="2010" data-name="confidential" id="c923-confidential" name="confidential" value="confidential" class="drpConfidential" checked="">
                                    <label for="c923-confidential"></label>
                                </div>
                            </span>
                        </li>
                        <li>
                            <span class="label">Public:</span>
                            <span class="financial-field data">
                                <div class="checkbox">
                                    <input type="checkbox" data-pk="2010" data-name="financialIsPublic" id="c923-financialIsPublic" name="financialIsPublic" value="financialIsPublic" class="drpFinancialIsPublic">
                                    <label for="c923-financialIsPublic"></label>
                                </div>
                            </span>
                        </li>
                        <li>
                            <span class="label"><button type="button" class="button-secondary btnUpdateTicker d-none" title="Ticker financial data possibly out of date. Click to automatically update financials."><strong> ! </strong></button><button type="button" class="button-tertiary btnShowTickerSearch" title="Click to look up public income statements.">Ticker:</button></span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="tickerSymbol" class="data-edit inline txtTickerSymbol" value="">
                            </span>
                        </li>
                    </ul>
                    <ul class="column list-table">
                        <li class="">
                            <span class="label">EBIT:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="ebit" class="data-edit inline txtMillions numeric" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">% of Rev:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="ebit" class="data-edit inline txtRevenuePercentage numeric" value="0.0%">
                            </span>
                        </li>
                        <li class="">
                            <span class="label">Net Income:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="netIncome" class="data-edit inline txtMillions numeric" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">% of Rev:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="netIncome" class="data-edit inline txtRevenuePercentage numeric" value="0.0%">
                            </span>
                        </li>
                    </ul>
                    <ul class="column list-table public-financial-data d-none">
                        <li>
                            <span class="label">Public Financial Data</span>
                        </li>
                        <li>
                            <span class="label">Stock Price:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="stockPriceYearEnd" class="lock-disable txtMoney numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Outstanding Shares:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="outstandingShares" class="lock-disable txtOutstandingShares numeric data-edit inline" value="0">
                            </span>
                        </li>
                        <li>
                            <span class="label">Market Cap:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="marketCap" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Net Debt:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="netDebt" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Preferred Equity:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="preferredEquity" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Enterprise Value:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="enterpriseValue" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Revenue Multiple:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="revenueMultiple" class="lock-disable txtRevMultiple numeric data-edit inline" value="">
                            </span>
                        </li>
                        <li>
                            <span class="label">EBITDA Multiple:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="ebitdaMultiple" class="lock-disable txtEbitdaMultiple numeric data-edit inline" value="">
                            </span>
                        </li>
                        <li>
                            <span class="label">Ticker Fail Count:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="tickerFailCount" class="lock-disable numeric data-edit inline" value="0">
                            </span>
                        </li>
                        <li>
                            <span class="label">Ticker Verify Date Count:</span>
                            <span class="financial-field data">

                            </span>
                        </li>
                    </ul>
                    <ul class="column list-table">
                        <li>
                            <span class="label">Tax Status:</span>
                            <span class="data">
                                <dropdown label="Unknown" name="test">
                                    <template #body>
                                        <menu-vertical options="panel options medium">
                                            <menu-item label="Unknown" />
                                            <menu-item label="SUB_S" />
                                            <menu-item label="REG_C" />
                                            <menu-item label="NFPROFIT" />
                                            <menu-item label="CCORP" />
                                            <menu-item label="ESOP" />
                                        </menu-vertical>
                                    </template>
                                </dropdown>
                            </span>
                        </li>
                        <li>
                            <span class="label">Last Edited:</span>
                            <span class="data">

                                11/09/2020, 12:39 by JEB

                            </span>
                        </li>
                        <li>
                            <div class="entry-notes"> </div>
                            <span class="label">Reliability:</span>
                            <span class="data">
                                <textarea data-type="textarea" data-pk="2010" data-name="description" class="txtDescription" rows="3">Last Changed: 02/04/11 - JEB</textarea>
                            </span>
                        </li>
                        <li>
                            <span class="label hide"></span>
                            <span class="data align-left">
                                <button data-name="deleteFinancialButton" class="button-secondary small btnDelete" type="button" data-pk="2010">Delete</button>
                            </span>
                        </li>
                    </ul>
                </template>
            </table-row>
            <table-row name="estimated financial10KInfo" :expansion="true">
                <table-cell title="Year" name="year">
                    <h3 data-name="year">
                        2009
                        <span data-name="financialAccuracyID" class="tag-accuracy estimated">E</span>
                    </h3>
                    <a class="button-secondary" href="">More details</a>
                </table-cell>
                <table-cell title="Revenue">
                    <span class="label">Revenue:</span>
                    <span class="financial-field data">
                        <input size="6" class="value data-edit inline txtMillions numeric" type="text" data-pk="2010" data-name="revenue" value="500.00">
                        <span class="trend-indicator "></span>
                        <span class="financial-field value-percentage" style="opacity: 100;">
                            <input data-pk="2010" data-name="revenue" class="txtRevenueAnnualGrowth numeric data-edit inline" type="text" value="0.0%" size="3">
                        </span>
                    </span>
                </table-cell>
                <table-cell title="Ebitda">
                    <span class="label">EBITDA:</span>
                    <span class="financial-field data">
                        <input size="12" type="text" data-pk="2010" data-name="ebitda" class="value data-edit inline txtMillions numeric" value="0.00">
                        <span class="trend-indicator"></span>
                        <span class="financial-field value-percentage" style="opacity: 100;">
                            <input data-type="text" data-pk="2010" data-name="ebitda" class="txtRevenuePercentage data-edit inline" type="text" value="0.0%" size="3">
                        </span>
                    </span>
                </table-cell>
                <table-cell title="Emp">
                    <span class="label">Employees:</span>
                    <span class="financial-field data">
                        <input size="5" type="number" data-pk="2010" data-name="employeeSize" class="data-edit inline txtEmployeeSize numeric" value="10">
                    </span>
                </table-cell>
                <template #expansion>
                    <ul class="column list-table">
                        <li>
                            <span class="label">Accuracy:</span>
                            <span class="financial-field data flex-rows no-responsive">
                                <smart-select @selected="selectXselected($event)" labelfield="name" valuefield="code" :choices="countries" initialchoice="FI" />
                                <span data-name="financialAccuracyID" class="tag-accuracy estimated">E</span>
                            </span>
                        </li>
                        <li>
                            <span class="label">Currency:</span>
                            <span class="financial-field data flex-rows no-responsive">
                                <smart-select @selected="selectXselected($event)" labelfield="name" valuefield="code" :choices="countries" initialchoice="FI" />
                                <span class="currency"><i class="fas fa-dollar-sign type-medium"></i></span>
                            </span>
                        </li>
                        <li>
                            <span class="label">Confidential:</span>
                            <span class="financial-field data">
                                <div class="checkbox">
                                    <input type="checkbox" data-pk="2010" data-name="confidential" id="c923-confidential" name="confidential" value="confidential" class="drpConfidential" checked="">
                                    <label for="c923-confidential"></label>
                                </div>
                            </span>
                        </li>
                        <li>
                            <span class="label">Public:</span>
                            <span class="financial-field data">
                                <div class="checkbox">
                                    <input type="checkbox" data-pk="2010" data-name="financialIsPublic" id="c923-financialIsPublic" name="financialIsPublic" value="financialIsPublic" class="drpFinancialIsPublic">
                                    <label for="c923-financialIsPublic"></label>
                                </div>
                            </span>
                        </li>
                        <li>
                            <span class="label"><button type="button" class="button-secondary btnUpdateTicker d-none" title="Ticker financial data possibly out of date. Click to automatically update financials."><strong> ! </strong></button><button type="button" class="button-tertiary btnShowTickerSearch" title="Click to look up public income statements.">Ticker:</button></span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="tickerSymbol" class="data-edit inline txtTickerSymbol" value="">
                            </span>
                        </li>
                    </ul>
                    <ul class="column list-table">
                        <li class="">
                            <span class="label">EBIT:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="ebit" class="data-edit inline txtMillions numeric" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">% of Rev:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="ebit" class="data-edit inline txtRevenuePercentage numeric" value="0.0%">
                            </span>
                        </li>
                        <li class="">
                            <span class="label">Net Income:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="netIncome" class="data-edit inline txtMillions numeric" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">% of Rev:</span>
                            <span class="financial-field data">
                                <input size="5" type="text" data-pk="2010" data-name="netIncome" class="data-edit inline txtRevenuePercentage numeric" value="0.0%">
                            </span>
                        </li>
                    </ul>
                    <ul class="column list-table public-financial-data d-none">
                        <li>
                            <span class="label">Public Financial Data</span>
                        </li>
                        <li>
                            <span class="label">Stock Price:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="stockPriceYearEnd" class="lock-disable txtMoney numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Outstanding Shares:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="outstandingShares" class="lock-disable txtOutstandingShares numeric data-edit inline" value="0">
                            </span>
                        </li>
                        <li>
                            <span class="label">Market Cap:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="marketCap" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Net Debt:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="netDebt" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Preferred Equity:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="preferredEquity" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Enterprise Value:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="text" data-pk="2010" data-name="enterpriseValue" class="lock-disable txtMillions numeric data-edit inline" value="0.00">
                            </span>
                        </li>
                        <li>
                            <span class="label">Revenue Multiple:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="revenueMultiple" class="lock-disable txtRevMultiple numeric data-edit inline" value="">
                            </span>
                        </li>
                        <li>
                            <span class="label">EBITDA Multiple:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="ebitdaMultiple" class="lock-disable txtEbitdaMultiple numeric data-edit inline" value="">
                            </span>
                        </li>
                        <li>
                            <span class="label">Ticker Fail Count:</span>
                            <span class="financial-field data">
                                <input size="5" disabled="" type="number" data-pk="2010" data-name="tickerFailCount" class="lock-disable numeric data-edit inline" value="0">
                            </span>
                        </li>
                        <li>
                            <span class="label">Ticker Verify Date Count:</span>
                            <span class="financial-field data">

                            </span>
                        </li>
                    </ul>
                    <ul class="column list-table">
                        <li>
                            <span class="label">Tax Status:</span>
                            <span class="data">
                                <dropdown label="Unknown" name="test">
                                    <template #body>
                                        <menu-vertical options="panel options medium">
                                            <menu-item label="Unknown" />
                                            <menu-item label="SUB_S" />
                                            <menu-item label="REG_C" />
                                            <menu-item label="NFPROFIT" />
                                            <menu-item label="CCORP" />
                                            <menu-item label="ESOP" />
                                        </menu-vertical>
                                    </template>
                                </dropdown>
                            </span>
                        </li>
                        <li>
                            <span class="label">Last Edited:</span>
                            <span class="data">

                                11/09/2020, 12:39 by JEB

                            </span>
                        </li>
                        <li>
                            <div class="entry-notes"> </div>
                            <span class="label">Reliability:</span>
                            <span class="data">
                                <textarea data-type="textarea" data-pk="2010" data-name="description" class="txtDescription" rows="3">Last Changed: 02/04/11 - JEB</textarea>
                            </span>
                        </li>
                        <li>
                            <span class="label hide"></span>
                            <span class="data align-left">
                                <button data-name="deleteFinancialButton" class="button-secondary small btnDelete" type="button" data-pk="2010">Delete</button>
                            </span>
                        </li>
                    </ul>
                </template>
            </table-row>
        </table-flex>
    </section>
    <section class="page-section">
        <button @click.prevent="openPanel" class="button-primary margin-right">Open Sidepanel</button>
        <button @click.prevent="openInnerPanel" class="button-primary">Open Inner Sidebar</button>
        <div class="margin-top-medium">
            <div class="grid-12-12 card frame-small">
                <auto-complete v-model="selectedCountry" :suggestions="filteredCountriesBasic" @complete="searchCountry($event)" field="name" :multiple="true" />
            </div>
        </div>

        <div class="margin-top-medium">
            <smart-select @selected="selectXselected($event)" labelfield="name" valuefield="code" :choices="countries" initialchoice="FI" />
        </div>
    </section>
    <section class="page-section">
        <tabs>
            <tab title="One">
                <h3>Hello from tab one</h3>
                <p>Hey, In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
            </tab>
            <tab title="Two">
                <h3>Hello from tab two</h3>
                <p>Hey, In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
            </tab>
            <tab title="Three">
                <h3>Hello from tab three</h3>
                <p>Hey, In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
            </tab>
        </tabs>
    </section>
    <section class="page-section">
        <outer-sidebar ref="outerSidebar" title="Hello, I'm a sidebar panel" :footer="true">
            <template #body>
                <header class="record-head">
                    <h3>BOA S.A. 4</h3>
                    <h4>Portfolio of <a href="">Veritas Capital</a> since 5/14/2018</h4>
                    <ul class="list-horizontal">
                        <li class="tag-default"><a href="">Target</a></li>
                        <li class="tag-default">Sell Buy</li>
                        <li class="tag-default">Rev: 147.00</li>
                    </ul>
                </header>
                <div class="record-description">
                    <p>
                        Poland-based BOA is a consulting company that provides specialized advisory services to all areas of
                        company operations. BOA creates strategies from scratch, implements them, and customizes them to
                        each company’s particular needs, such as building company value for its shareholders and owners.
                    </p>
                </div>
                <div class="record-events">
                    <ul class="list-events">
                        <li class="event-item user-activity">
                            <span class="event-marker"></span>
                            <i class="event-icon"></i>
                            <span class="event-label">Viewed</span>
                            <span class="event-details">by A0M 11/14/2019 4:36pm</span>
                        </li>
                        <li class="event-item outreach event-group">
                            <span class="event-marker">4</span>
                            <i class="event-icon"></i>
                            <span class="event-label">Correspondence Sent</span>
                            <span class="event-details">by JWB TPO 11/14/2019 4:36pm</span>
                            <ul class="list-events">
                                <li class="event-item outreach">
                                    <i class="event-icon"></i>
                                    <span class="event-label">Correspondence Sent</span>
                                    <span class="event-details">by JWB TPO 11/12/2019 4:36pm</span>
                                </li>
                                <li class="event-item outreach">
                                    <i class="event-icon"></i>
                                    <span class="event-label">Correspondence Sent</span>
                                    <span class="event-details">by JWB TPO 11/03/2019 4:36pm</span>
                                </li>
                                <li class="event-item outreach">
                                    <i class="event-icon"></i>
                                    <span class="event-label">Correspondence Sent</span>
                                    <span class="event-details">by JWB TPO 11/01/2019 4:36pm</span>
                                </li>
                            </ul>
                        </li>
                        <li class="event-item data-entry">
                            <span class="event-marker"></span>
                            <i class="event-icon"></i>
                            <span class="event-label">Website Entered</span>
                            <span class="event-details">by Admin 11/14/2019 4:36pm</span>
                        </li>
                        <li class="event-item data-validation">
                            <span class="event-marker"></span>
                            <i class="event-icon"></i>
                            <span class="event-label">Email Address Failed</span>
                            <span class="event-details">by Admin 11/14/2019 4:36pm</span>
                        </li>
                    </ul>
                </div>
            </template>
            <template #footer>
                <btn>Save</btn>
            </template>
        </outer-sidebar>
    </section>
    <section class="page-section">
        <inner-sidebar ref="innerSidebar" title="Bookmarks" :footer="true">
            <template #body>
                Hello
            </template>
            <template #footer>
                Hello again
            </template>
        </inner-sidebar>
    </section>

    <section class="page-section margin-top hide">
        <p>Window Size: {{$mq}}</p>
        <br />
        <mq-responsive target="desktop">
            <span> Display on lg </span>
        </mq-responsive>
        <br />
        <button class="button-tertiary" @click="loadTabData">Load Dynamic Data</button>
    </section>
</template>

<script>
    import Modal from '../modals/Modal.vue'
    import Tabs from '../tabs/Tabs.vue'
    import Tab from '../tabs/Tab.vue'
    import MenuItem from '../menus/MenuItem.vue'
    import MenuVertical from '../menus/MenuVertical.vue'
    import ListTable from '../lists/ListTable.vue'
    import ListBlocks from '../lists/ListBlocks.vue'
    import ListItem from '../lists/ListItem.vue'
    import TableFlex from '../tables/TableFlex.vue'
    import TableRow from '../tables/TableRow.vue'
    import TableCell from '../tables/TableCell.vue'
    import AutoComplete from 'primevue/autocomplete/sfc'
    import OuterSidebar from '../sidebars/OuterSidebar.vue'
    import InnerSidebar from '../sidebars/InnerSidebar.vue'
    import Tag from '../tags/Tag.vue'
    import TagBasic from '../tags/TagBasic.vue'
    import TagGroup from '../tags/TagGroup.vue'
    import SmartSelect from '../../baseComponents/SmartSelect.vue'
    import CurrencyInput from '../../Financials/CurrencyInput.vue'
    import Accordion from '../accordions/Accordion.vue'
    import AccordionPanel from '../accordions/AccordionPanel.vue'

    import { inject } from 'vue'
    //import { nextAlert } from '../../../composables/nextAlertService.js'

    export default {
        name: 'Guide',
        components: {
            Tabs, Tab, Tag, TagBasic, TagGroup, Accordion, AccordionPanel,
            Modal, MenuItem, MenuVertical, ListTable, ListBlocks, ListItem, TableFlex, TableRow, TableCell, OuterSidebar, InnerSidebar, AutoComplete, SmartSelect, CurrencyInput
        },
        props: {

        },
        setup() {

            const nextAlert = inject('nextAlert')
            const nextConfirm = inject('nextConfirm')
            const nextChoice = inject('nextChoice')

            const testAlert = (alertType) => {
                if (alertType == 'alert') {
                    nextAlert('Testing Alert').then((result) => {
                        window.alert('nextAlert-result:' + result)
                    })
                }
                else if (alertType == 'confirm') {
                    nextConfirm('Confirm Title', 'Testing Confirm', 'check', 'Oui', 'Non', [{text:'Peut-Etre', res: 'maybe'}]).then((result) => {
                        window.alert('nextConfirm-result:' + result)
                    })
                }
                else if (alertType == 'choice') {
                    nextChoice('Choice Title', 'Testing Choice', [{ text: 'A', res: 'a' }, { text: 'B', res: 'b' }]).then((result) => {
                        window.alert('nextChoice-result:' + result)
                    })
                }
            }

            return { nextAlert, nextConfirm, nextChoice, testAlert }
        },
        data: function () {
            return {
                modalOpen: false,
                suggestionsPanelOpen: false,
                selectedCountry: null,
                filteredCountries: null,
                filteredCountriesBasic: [],
                countries: [
                    { "name": "Afghanistan", "code": "AF" },
                    { "name": "Åland Islands", "code": "AX" },
                    { "name": "Albania", "code": "AL" },
                    { "name": "Algeria", "code": "DZ" },
                    { "name": "American Samoa", "code": "AS" },
                    { "name": "Andorra", "code": "AD" },
                    { "name": "Angola", "code": "AO" },
                    { "name": "Anguilla", "code": "AI" },
                    { "name": "Antarctica", "code": "AQ" },
                    { "name": "Antigua and Barbuda", "code": "AG" },
                    { "name": "Argentina", "code": "AR" },
                    { "name": "Armenia", "code": "AM" },
                    { "name": "Aruba", "code": "AW" },
                    { "name": "Australia", "code": "AU" },
                    { "name": "Austria", "code": "AT" },
                    { "name": "Azerbaijan", "code": "AZ" },
                    { "name": "Bahamas", "code": "BS" },
                    { "name": "Bahrain", "code": "BH" },
                    { "name": "Bangladesh", "code": "BD" },
                    { "name": "Barbados", "code": "BB" },
                    { "name": "Belarus", "code": "BY" },
                    { "name": "Belgium", "code": "BE" },
                    { "name": "Belize", "code": "BZ" },
                    { "name": "Benin", "code": "BJ" },
                    { "name": "Bermuda", "code": "BM" },
                    { "name": "Bhutan", "code": "BT" },
                    { "name": "Bolivia", "code": "BO" },
                    { "name": "Bosnia and Herzegovina", "code": "BA" },
                    { "name": "Botswana", "code": "BW" },
                    { "name": "Bouvet Island", "code": "BV" },
                    { "name": "Brazil", "code": "BR" },
                    { "name": "British Indian Ocean Territory", "code": "IO" },
                    { "name": "Brunei Darussalam", "code": "BN" },
                    { "name": "Bulgaria", "code": "BG" },
                    { "name": "Burkina Faso", "code": "BF" },
                    { "name": "Burundi", "code": "BI" },
                    { "name": "Cambodia", "code": "KH" },
                    { "name": "Cameroon", "code": "CM" },
                    { "name": "Canada", "code": "CA" },
                    { "name": "Cape Verde", "code": "CV" },
                    { "name": "Cayman Islands", "code": "KY" },
                    { "name": "Central African Republic", "code": "CF" },
                    { "name": "Chad", "code": "TD" },
                    { "name": "Chile", "code": "CL" },
                    { "name": "China", "code": "CN" },
                    { "name": "Christmas Island", "code": "CX" },
                    { "name": "Cocos (Keeling) Islands", "code": "CC" },
                    { "name": "Colombia", "code": "CO" },
                    { "name": "Comoros", "code": "KM" },
                    { "name": "Congo", "code": "CG" },
                    { "name": "Congo, The Democratic Republic of the", "code": "CD" },
                    { "name": "Cook Islands", "code": "CK" },
                    { "name": "Costa Rica", "code": "CR" },
                    { "name": "Cote D\"Ivoire", "code": "CI" },
                    { "name": "Croatia", "code": "HR" },
                    { "name": "Cuba", "code": "CU" },
                    { "name": "Cyprus", "code": "CY" },
                    { "name": "Czech Republic", "code": "CZ" },
                    { "name": "Denmark", "code": "DK" },
                    { "name": "Djibouti", "code": "DJ" },
                    { "name": "Dominica", "code": "DM" },
                    { "name": "Dominican Republic", "code": "DO" },
                    { "name": "Ecuador", "code": "EC" },
                    { "name": "Egypt", "code": "EG" },
                    { "name": "El Salvador", "code": "SV" },
                    { "name": "Equatorial Guinea", "code": "GQ" },
                    { "name": "Eritrea", "code": "ER" },
                    { "name": "Estonia", "code": "EE" },
                    { "name": "Ethiopia", "code": "ET" },
                    { "name": "Falkland Islands (Malvinas)", "code": "FK" },
                    { "name": "Faroe Islands", "code": "FO" },
                    { "name": "Fiji", "code": "FJ" },
                    { "name": "Finland", "code": "FI" },
                    { "name": "France", "code": "FR" },
                    { "name": "French Guiana", "code": "GF" },
                    { "name": "French Polynesia", "code": "PF" },
                    { "name": "French Southern Territories", "code": "TF" },
                    { "name": "Gabon", "code": "GA" },
                    { "name": "Gambia", "code": "GM" },
                    { "name": "Georgia", "code": "GE" },
                    { "name": "Germany", "code": "DE" },
                    { "name": "Ghana", "code": "GH" },
                    { "name": "Gibraltar", "code": "GI" },
                    { "name": "Greece", "code": "GR" },
                    { "name": "Greenland", "code": "GL" },
                    { "name": "Grenada", "code": "GD" },
                    { "name": "Guadeloupe", "code": "GP" },
                    { "name": "Guam", "code": "GU" },
                    { "name": "Guatemala", "code": "GT" },
                    { "name": "Guernsey", "code": "GG" },
                    { "name": "Guinea", "code": "GN" },
                    { "name": "Guinea-Bissau", "code": "GW" },
                    { "name": "Guyana", "code": "GY" },
                    { "name": "Haiti", "code": "HT" },
                    { "name": "Heard Island and Mcdonald Islands", "code": "HM" },
                    { "name": "Holy See (Vatican City State)", "code": "VA" },
                    { "name": "Honduras", "code": "HN" },
                    { "name": "Hong Kong", "code": "HK" },
                    { "name": "Hungary", "code": "HU" },
                    { "name": "Iceland", "code": "IS" },
                    { "name": "India", "code": "IN" },
                    { "name": "Indonesia", "code": "ID" },
                    { "name": "Iran, Islamic Republic Of", "code": "IR" },
                    { "name": "Iraq", "code": "IQ" },
                    { "name": "Ireland", "code": "IE" },
                    { "name": "Isle of Man", "code": "IM" },
                    { "name": "Israel", "code": "IL" },
                    { "name": "Italy", "code": "IT" },
                    { "name": "Jamaica", "code": "JM" },
                    { "name": "Japan", "code": "JP" },
                    { "name": "Jersey", "code": "JE" },
                    { "name": "Jordan", "code": "JO" },
                    { "name": "Kazakhstan", "code": "KZ" },
                    { "name": "Kenya", "code": "KE" },
                    { "name": "Kiribati", "code": "KI" },
                    { "name": "Korea, Democratic People\"S Republic of", "code": "KP" },
                    { "name": "Korea, Republic of", "code": "KR" },
                    { "name": "Kuwait", "code": "KW" },
                    { "name": "Kyrgyzstan", "code": "KG" },
                    { "name": "Lao People\"S Democratic Republic", "code": "LA" },
                    { "name": "Latvia", "code": "LV" },
                    { "name": "Lebanon", "code": "LB" },
                    { "name": "Lesotho", "code": "LS" },
                    { "name": "Liberia", "code": "LR" },
                    { "name": "Libyan Arab Jamahiriya", "code": "LY" },
                    { "name": "Liechtenstein", "code": "LI" },
                    { "name": "Lithuania", "code": "LT" },
                    { "name": "Luxembourg", "code": "LU" },
                    { "name": "Macao", "code": "MO" },
                    { "name": "Macedonia, The Former Yugoslav Republic of", "code": "MK" },
                    { "name": "Madagascar", "code": "MG" },
                    { "name": "Malawi", "code": "MW" },
                    { "name": "Malaysia", "code": "MY" },
                    { "name": "Maldives", "code": "MV" },
                    { "name": "Mali", "code": "ML" },
                    { "name": "Malta", "code": "MT" },
                    { "name": "Marshall Islands", "code": "MH" },
                    { "name": "Martinique", "code": "MQ" },
                    { "name": "Mauritania", "code": "MR" },
                    { "name": "Mauritius", "code": "MU" },
                    { "name": "Mayotte", "code": "YT" },
                    { "name": "Mexico", "code": "MX" },
                    { "name": "Micronesia, Federated States of", "code": "FM" },
                    { "name": "Moldova, Republic of", "code": "MD" },
                    { "name": "Monaco", "code": "MC" },
                    { "name": "Mongolia", "code": "MN" },
                    { "name": "Montserrat", "code": "MS" },
                    { "name": "Morocco", "code": "MA" },
                    { "name": "Mozambique", "code": "MZ" },
                    { "name": "Myanmar", "code": "MM" },
                    { "name": "Namibia", "code": "NA" },
                    { "name": "Nauru", "code": "NR" },
                    { "name": "Nepal", "code": "NP" },
                    { "name": "Netherlands", "code": "NL" },
                    { "name": "Netherlands Antilles", "code": "AN" },
                    { "name": "New Caledonia", "code": "NC" },
                    { "name": "New Zealand", "code": "NZ" },
                    { "name": "Nicaragua", "code": "NI" },
                    { "name": "Niger", "code": "NE" },
                    { "name": "Nigeria", "code": "NG" },
                    { "name": "Niue", "code": "NU" },
                    { "name": "Norfolk Island", "code": "NF" },
                    { "name": "Northern Mariana Islands", "code": "MP" },
                    { "name": "Norway", "code": "NO" },
                    { "name": "Oman", "code": "OM" },
                    { "name": "Pakistan", "code": "PK" },
                    { "name": "Palau", "code": "PW" },
                    { "name": "Palestinian Territory, Occupied", "code": "PS" },
                    { "name": "Panama", "code": "PA" },
                    { "name": "Papua New Guinea", "code": "PG" },
                    { "name": "Paraguay", "code": "PY" },
                    { "name": "Peru", "code": "PE" },
                    { "name": "Philippines", "code": "PH" },
                    { "name": "Pitcairn", "code": "PN" },
                    { "name": "Poland", "code": "PL" },
                    { "name": "Portugal", "code": "PT" },
                    { "name": "Puerto Rico", "code": "PR" },
                    { "name": "Qatar", "code": "QA" },
                    { "name": "Reunion", "code": "RE" },
                    { "name": "Romania", "code": "RO" },
                    { "name": "Russian Federation", "code": "RU" },
                    { "name": "RWANDA", "code": "RW" },
                    { "name": "Saint Helena", "code": "SH" },
                    { "name": "Saint Kitts and Nevis", "code": "KN" },
                    { "name": "Saint Lucia", "code": "LC" },
                    { "name": "Saint Pierre and Miquelon", "code": "PM" },
                    { "name": "Saint Vincent and the Grenadines", "code": "VC" },
                    { "name": "Samoa", "code": "WS" },
                    { "name": "San Marino", "code": "SM" },
                    { "name": "Sao Tome and Principe", "code": "ST" },
                    { "name": "Saudi Arabia", "code": "SA" },
                    { "name": "Senegal", "code": "SN" },
                    { "name": "Serbia and Montenegro", "code": "CS" },
                    { "name": "Seychelles", "code": "SC" },
                    { "name": "Sierra Leone", "code": "SL" },
                    { "name": "Singapore", "code": "SG" },
                    { "name": "Slovakia", "code": "SK" },
                    { "name": "Slovenia", "code": "SI" },
                    { "name": "Solomon Islands", "code": "SB" },
                    { "name": "Somalia", "code": "SO" },
                    { "name": "South Africa", "code": "ZA" },
                    { "name": "South Georgia and the South Sandwich Islands", "code": "GS" },
                    { "name": "Spain", "code": "ES" },
                    { "name": "Sri Lanka", "code": "LK" },
                    { "name": "Sudan", "code": "SD" },
                    { "name": "Suriname", "code": "SR" },
                    { "name": "Svalbard and Jan Mayen", "code": "SJ" },
                    { "name": "Swaziland", "code": "SZ" },
                    { "name": "Sweden", "code": "SE" },
                    { "name": "Switzerland", "code": "CH" },
                    { "name": "Syrian Arab Republic", "code": "SY" },
                    { "name": "Taiwan, Province of China", "code": "TW" },
                    { "name": "Tajikistan", "code": "TJ" },
                    { "name": "Tanzania, United Republic of", "code": "TZ" },
                    { "name": "Thailand", "code": "TH" },
                    { "name": "Timor-Leste", "code": "TL" },
                    { "name": "Togo", "code": "TG" },
                    { "name": "Tokelau", "code": "TK" },
                    { "name": "Tonga", "code": "TO" },
                    { "name": "Trinidad and Tobago", "code": "TT" },
                    { "name": "Tunisia", "code": "TN" },
                    { "name": "Turkey", "code": "TR" },
                    { "name": "Turkmenistan", "code": "TM" },
                    { "name": "Turks and Caicos Islands", "code": "TC" },
                    { "name": "Tuvalu", "code": "TV" },
                    { "name": "Uganda", "code": "UG" },
                    { "name": "Ukraine", "code": "UA" },
                    { "name": "United Arab Emirates", "code": "AE" },
                    { "name": "United Kingdom", "code": "GB" },
                    { "name": "United States", "code": "US" },
                    { "name": "United States Minor Outlying Islands", "code": "UM" },
                    { "name": "Uruguay", "code": "UY" },
                    { "name": "Uzbekistan", "code": "UZ" },
                    { "name": "Vanuatu", "code": "VU" },
                    { "name": "Venezuela", "code": "VE" },
                    { "name": "Viet Nam", "code": "VN" },
                    { "name": "Virgin Islands, British", "code": "VG" },
                    { "name": "Virgin Islands, U.S.", "code": "VI" },
                    { "name": "Wallis and Futuna", "code": "WF" },
                    { "name": "Western Sahara", "code": "EH" },
                    { "name": "Yemen", "code": "YE" },
                    { "name": "Zambia", "code": "ZM" },
                    { "name": "Zimbabwe", "code": "ZW" }],
                tabSourceData: []
            }
        },
        computed: {
            tabData() {
                return this.tabSourceData
            },

        },
        methods: {
            openShareModal: function () {
                this.$refs.shareModal.showTheModal() // This is a function IN THE MODAL compponent that sets the showModal value to true in that component
            },
            searchCountry(event) {
                var input = event.query
                if (input.length < 1) {
                    this.filteredCountriesBasic = this.countries
                }
                else {
                    this.filteredCountriesBasic = this.countries.filter(country => {
                        return country.name.toLowerCase()
                            .startsWith(input.toLowerCase())
                    })
                }
            },
            loadTabData() {
                this.tabSourceData = [{ title: "First Title", subtitle: "primero subtitle", body: "la premiera es la buena", active: true },
                { title: "Second Title", subtitle: "segundo subtitle", body: "la segunda es la mejor", active: false },
                { title: "Third Title", subtitle: "tercero subtitle", body: "la tercera es otra cosa", active: false },
                { title: "Fourth Title", subtitle: "cuarto subtitle", body: "la cuarta es otra cosa tambien", active: false }
                ]
            },
            openCustomAlert() {
                this.$nextAlert({ message: 'Yes this is an alert', type: 'exclamation' }).then(result => { window.alert(result) })
            },
            openCustomConfirm() {
                this.$nextConfirm({ title: 'Confirm Title', message: 'Do you like this confirm', type: 'question', yesText: 'Yes Please', noText: 'No thank you', otherActions: [{ text: 'Maybe I\'ll think on it', res: 'maybe' }] }).then(result => { window.alert(result) })
            },
            alertTest(msg) {
                alert(msg)
            }
        },
        created() {
        }
    }
</script>

<style scoped lang="scss">
    
</style>