import { inject, ref, nextTick } from 'vue'
import { useStore } from 'vuex'
import { getID } from './Editors.js'
import { useToast } from './ModelWrapper.js'

export function sidebarSetup(opt = { cancelEditEventName: 'cancelEditsRecordBody', editRequestEventName: 'editCommentRecordBody', objectUpdateEventName: 'objectUpdate' } ) {
    // tools
    const store = useStore()
    const toast = useToast()
    const emitter = inject("emitter")   // Inject event bus  
    // data
    const detailsRequest = ref({})
    const editorID = ref(getID('sidebar'))
    const sidebarShowing = ref(false)

    // methods
    const newSidebarContent = (request) => {
        return !detailsRequest.value.obj ||
            detailsRequest.value.cardID != request.cardID ||
            detailsRequest.value.obj.objectKey != request.obj.objectKey ||
            detailsRequest.value.editRequest != request.editRequest
    }
    const openSidebar = (request) => {
        if (request) {
            emitter.emit(opt.cancelEditEventName, { editorID: editorID.value })
            detailsRequest.value = request
            fetchDetailsRequest()
        }

        sidebarShowing.value = true
        nextTick(() => {
            if (request && request.editRequest)
                emitter.emit(opt.editRequestEventName, { obj: request.editRequest, editorID: editorID.value })
        })
    }

    const fetchDetailsRequest = () => {
        if (detailsRequest.value && detailsRequest.value.obj && detailsRequest.value.obj.objectKey) {
            store.dispatch('objectCache/fetchObject', { objectKey: detailsRequest.value.obj.objectKey, fullFetch: true }).then((newHit) => {
                console.log('objectUpdate: ', newHit)
                if (newHit && newHit.obj) {
                    if (detailsRequest.value && detailsRequest.value.obj && detailsRequest.value.obj.objectKey == newHit.obj.objectKey)
                        detailsRequest.value.obj = newHit.obj
                    emitter.emit(opt.objectUpdateEventName, { objectKey: newHit.obj.objectKey, newObject: newHit.obj })
                }
            }).catch(errorFetch => {
                console.log('itemFetch failed', errorFetch)
            })
        }
    }
    const sidebarNavigate = (direction, refSideBar, getHit, resultsLength) => {
        var newIndex = direction == 'forward' ? detailsRequest.value.listIndex + 1 : detailsRequest.value.listIndex - 1
        if (newIndex < 0) {
            toast.add({ severity: 'warning', summary: 'You are at the beginning of the page', life: 1000 })
        }
        else if (newIndex >= resultsLength) {
            toast.add({ severity: 'warning', summary: 'You are at the end of the page', life: 1000 })
        }
        else {
            // Request new Hit Info
            var request = getHit(newIndex)
            if (request && refSideBar) {
                refSideBar.sidebarKeepContent().then((res) => {
                    if (!res)
                        openSidebar(request)
                })
            }
        }
    }
    return {
        // data
        detailsRequest, editorID, sidebarShowing,
        // methods
        sidebarNavigate, openSidebar, fetchDetailsRequest, newSidebarContent
    }

}
