<template>
    <div class="snapshot" :title="name">
        <h4 class="textlabel" @click="handleLabelClick">{{ name }} <span v-if="count" class="badge">({{ count }})</span></h4>
        <ul>
            <li v-for="(t, tindex) in validSubTags"
                :key="t.key ?? (t.name + tindex)"
                :title="t.title"
                :class="t.class"
                @button-clicked="handleBasicTagClick($event, t)">
                <span v-if="t.label" class="text-label">{{t.label}}: </span>
                <span>{{ t.name }}</span>
                <span class="icon" v-if="t.icon">
                    <i class="margin-left-min" :class="'fas fa-' + t.icon"></i>
                </span>
            </li>
        </ul>
    </div>

</template>

<script> 
    import { computed } from 'vue'

    export default {
        name: 'TagSnapshot',
        props: {
            name, // no type it then can take numbers and convert to strings or take strings
            title: { type: String },
            type: { type: String, default: 'default' },
            actions: { type: Array },
            disableDefaultAction: { type: Boolean, default: false },
            subTags: { type: Array },
            count: { type: String, default: null }
        },
        emits: ['button-clicked','subtag-clicked'],
        setup(props, { emit, attrs }) {
            // computed
            const validSubTags = computed(() => props.subTags ? props.subTags.filter((tag) => tag.name || tag.label) : [])
            const defaultAction = computed(() => props.actions ? props.actions[0] : null)
            const groupLabelClass = computed(() => !props.disableDefaultAction && defaultAction.value ? 'group-label group-label-action' : 'group-label')
            const tagGroupClass = computed(() => {
                var tgClasses = ['tag-group']
                if (!props.disableDefaultAction && defaultAction.value)
                    tgClasses.push('tag-group-action')
                if (attrs.class)
                    tgClasses.push(attrs.class)
                return tgClasses
            })

            // methods
            const handleLabelClick = () => {
                if (!props.disableDefaultAction && defaultAction.value)
                    emit('button-clicked', defaultAction.value)
            }
            const handleBasicTagClick = (action, subTag) => {
                console.log('handleBasicTagClick', action, subTag, (subTag.key ?? subTag.name))
                if (action && subTag) {
                    emit('subtag-clicked', { action: action, tag: (subTag.key ?? subTag.name) })
                }
            }

            return {
                // computed
                validSubTags, defaultAction, groupLabelClass, tagGroupClass,
                // methods
                handleLabelClick, handleBasicTagClick
            }
        }
    }
</script>

<style scoped lang="scss">
</style>