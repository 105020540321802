import axios from 'axios'

const state = () => ({
    objCache: {},
    imageState: {}
})

// getters
const getters = {
    cache(state) { return state.objCache }
}


// actions
const actions = {
    findObject({ state }, opt) {
        return new Promise((resolve, reject) => {
            if (!(opt && opt.objectKey)) {
                reject({ fatal: false, message: 'Bad fetch object Options' })
                return
            }
            resolve(state.objCache[this.encodeOwnerKey(opt.objectKey)])
        })
    },
    fetchObject({ commit, dispatch }, opt) {
        return new Promise((resolve, reject) => {
            console.log('fetch object request', opt)
            if (!(opt && opt.objectKey)) {
                reject({ fatal: false, message: 'Bad fetch object Options' })
                return
            }

            var keyObj = this.splitKey(opt.objectKey)
            var objState = { 'fetchTime': null, 'obj': {}, 'objectKey': opt.objectKey, 'id': keyObj.id, 'objectType': keyObj.objectType }

            if (objState.objectType && ["company", "acquisition", "person", "lob", "ais"].includes(objState.objectType.toLowerCase())) {
                axios.get(objState.objectType + '/FetchObject/' + objState.id)
                    .then(r => r.data)
                    .then(result => {
                        console.log('fetchObject', result)
                        if (result) {
                            if (result.success) {
                                objState.fetchTime = new Date()
                                objState.obj = JSON.parse(result.jsonResult)
                                console.log('fetchedObject', opt.objectKey)

                                //clean up and fetch data
                                if (objState.obj) {
                                    //all objs
                                    dispatch('comments/fetchTags', { obj: objState.obj }, { root: true });
                                    dispatch('eventLog/fetchEvents', { obj: objState.obj, fetch: true }, { root: true });

                                    if (opt.fullFetch) {
                                        dispatch('emails/fetchEmails', { obj: objState.obj, fetch: true }, { root: true });
                                        dispatch('comments/fetchComments', { obj: objState.obj, fetch: true }, { root: true });
                                    }

                                    //by obj type
                                    if (objState.obj.objectType == 'company') {
                                        if (opt.fullFetch) dispatch('acquisitions/fetchAcquisitions', { obj: objState.obj, fetch: true  }, { root: true });

                                        if (!objState.obj.financials)
                                            objState.obj.financials = []
                                        if (!objState.obj.lobs)
                                            objState.obj.lobs = []
                                        if (!objState.obj.people)
                                            objState.obj.people = []
                                    }
                                    else if (objState.obj.objectType == 'acquisition') {
                                        if (opt.fullFetch) dispatch('acquisitions/fetchArticles', { obj: objState.obj, fetch: true  }, { root: true });
                                    }
                                    else if (objState.obj.objectType == 'person') {
                                        if (!objState.obj.titles)
                                            objState.obj.titles = []
                                    }
                                }
                                commit('SET_OBJECT', objState)
                                resolve({ 'fetched': true, 'objectKey': objState.objectKey, 'obj': objState.obj })
                            }
                            else {
                                reject({ fatal: true, message: "object fetch error: " + result.message })
                            }
                        }
                        else {
                            reject({ fatal: true, message: "object not found. id: " + objState.objectKey })
                        }
                    })
            }
            else
                reject({ fatal: true, message: "Unable to fetch objectType: " + objState.objectType })
        })
    },
    synchObject({ dispatch }, opt) {
        return new Promise((resolve, reject) => {
            console.log('synch object request', opt)
            if (!(opt && opt.objectKey)) {
                reject({ fatal: false, message: 'Bad fetch object Options' })
                return
            }

            var keyObj = this.splitKey(opt.objectKey)

            axios.post(keyObj.objectType + '/Synch/' + keyObj.id)
                .then(r => r.data)
                .then(result => {
                    console.log('synchObject', result)
                    if (result) {
                        if (result.success) {
                            opt.fullFetch = true;
                            dispatch('fetchObject', opt).then(fResult => {
                                resolve({ success: true, fetchResult: fResult })
                            }).catch(function (error) {
                                reject(error)
                            })
                        }
                        else {
                            reject({ fatal: true, message: "Could not sync object. Id:" + result.message })
                        }
                    }
                    else {
                        reject({ fatal: true, message: "Could not sync object. Id: " + opt.objectKey })
                    }

                })
        })
    },
    viewObject(state, opt) {
        return new Promise((resolve, reject) => {
            if (!(opt && opt.objectKey)) {
                reject({ fatal: false, message: 'Bad View object Options' })
                return
            }

            var keyObj = this.splitKey(opt.objectKey)
           // if (keyObj.objectType && state.objTypes.includes(keyObj.objectType.toLowerCase())) {
                axios.post(keyObj.objectType + '/Viewed/?id=' + keyObj.id)
                    .then(r => r.data)
                    .then(result => {
                        resolve(result);
                    }).catch(() => {
                        reject('Could not register Viewed event.')
                    });
           // }
          //  else
            //    reject('Cannot view object type: ' + keyObj.objectType)
        });
    },
    fetchImageState({ commit }, opt) {
        return new Promise((resolve, reject) => {
            console.log('fetch image State request', opt)
            if (!(opt && opt.objectKey)) {
                reject({ fatal: false, message: 'Bad fetch image state Options' })
                return
            }

            var keyObj = this.splitKey(opt.objectKey)
            var imageState = { 'fetchTime': null, 'hasImage': false, 'objectKey': opt.objectKey, 'id': keyObj.id, 'objectType': keyObj.objectType }

            axios.get('image/HasImage?OwnerID=' + keyObj.id + '&OwnerTypeID=' + keyObj.objectTypeID)
                .then(r => r.data)
                .then(result => {
                    console.log('fetchImageState', opt.objectKey, result)
                    if (result) {
                        if (result.success) {
                            imageState.fetchTime = new Date()
                            imageState.hasImage = result.jsonResult == "true";
                            console.log('fetchedImageState', opt.objectKey)
                            
                            commit('SET_IMAGESTATE', imageState)
                            resolve({ 'fetched': true, 'objectKey': imageState.objectKey, 'imageState': imageState })
                        }
                        else {
                            reject({ fatal: true, message: "imageState fetch error: " + result.message })
                        }
                    }
                    else {
                        reject({ fatal: true, message: "imageState not found id: " + imageState.objectKey })
                    }
                })
        })
    }
}

// mutations
const mutations = {
    SET_OBJECT(state, opt) {
        state.objCache[this.encodeOwnerKey(opt.objectKey)] = opt
    },
    SET_OBJECTFINANCIALS(state, opt) {
        if (opt && opt.ownerKey && opt.financial10Ks) {
            var ownerKey = this.encodeOwnerKey(opt.ownerKey)
            if (state.objCache[ownerKey] && state.objCache[ownerKey].obj) {
                state.objCache[ownerKey].obj.financials = opt.financial10Ks
                state.objCache[ownerKey].obj.lastFinancial = opt.financial10Ks[0]
            }
        }
    },
    SET_OBJECTPROP(state, opt) {
        if (opt && opt.ownerKey && opt.key && opt.value) {
            var ownerKey = this.encodeOwnerKey(opt.ownerKey)
            if (state.objCache[ownerKey] && state.objCache[ownerKey].obj) {
                console.log('SET_OBJECTPROP', opt)
                state.objCache[ownerKey].obj[opt.key] = opt.value
            }
        }
    },
    SET_IMAGESTATE(state, opt) {
        state.imageState[this.encodeOwnerKey(opt.objectKey)] = opt
    },
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
