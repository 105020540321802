<template>
    <div>
        <section class="grid wall">
            <template v-for="(title, idx) in model" :key="title.id">
                <title-editor v-if="!title.delete && (title.active || advancedMode)"
                              :ref="'title-' + title.id"
                              v-model="model[idx]"
                              :inpopup="inpopup"
                              :advancedMode="advancedMode"
                              :objectContext="obj.objectType"
                              :editing="editing"
                              :showEdit="showEdit"
                              @primarychange="onPrimaryChange({index: idx, node: $event})" />
            </template>
        </section>
        <section v-if="showAddData" class="title-people margin-top-small">
            <btn v-if="obj.objectType == 'company'" el="link" title="Add Person" target="_blank" :href="'https://cmdm.berkerynoyes.com/Person/Create/?CompanyID=' + obj.id" type="primary"><i class="far fa-plus-circle"></i> Add Person</btn>
            <btn v-else el="link" title="Add Company" target="_blank" :href="'/Person/Edit/' + obj.id" type="primary"><i class="far fa-plus-circle"></i> Add Company</btn>
        </section>
        <div class="flex-rows no-responsive fullwidth margin-top-small">
            <checkbox v-if="showAdvanced" v-model="advancedMode" label="Advanced Mode" :showAsSwitch="true" />
        </div>
    </div>
</template>
<script>
    import { useModelWrapper } from '../../composables/ModelWrapper.js'
    import { mapState } from 'vuex'
    import _ from 'lodash'
    import { nextTick, ref } from 'vue'

    import TitleEditor from './TitleEditor.vue'
    import Checkbox from '../ui/forms/Checkbox.vue'

    export default {
        name: 'TitlesEditor',
        components: {
            TitleEditor, Checkbox
        },
        props: {
            modelValue: { type: Array },
            ownerName: String,
            inpopup: {
                type: Boolean,
                default: false
            },
            showAdvanced: {
                type: Boolean,
                default: true
            },
            showHeader: {
                type: Boolean,
                default: true
            },
            showAddData: {
                type: Boolean,
                default: true
            },
            showSave: {
                type: Boolean,
                default: false
            },
            advanced: { type: Boolean },
            obj: { type: Object },
            showEdit: {
                type: Boolean,
                default: false
            },
        },
        emits: ['delete', 'update:modelValue', 'update:advanced'],
        computed: {
            ...mapState({
                iconMap: state => state.ais.iconMap,
                defaultTitle: state => state.ais.defaultLOB
            }),            
            ownerID() {
                if (this.model && this.model.length > 0)
                    return this.model[0].ownerID
                return null
            },
            dirty() {
                if (this.model) {
                    return this.model.filter((title) => { return title.dirty }).length > 0
                }
                return false
            },
            visibleCount() {
                return this.model.filter((title) => { return !title.delete && (title.active || this.advancedMode) }).length
            },
            lastBoxClass() {
                return ((this.visibleCount + 1) % 3 == 2) ? 'last-box' : ''
            }
        },
        methods: {
            async addPerson() {
                var newTitle = _.cloneDeep(this.defaultTitle)
                newTitle.contactID = this.nextID(this.model.map((peop) => peop.contactID))
                newTitle.ownerID = this.ownerID
                newTitle.companyID = this.ownerID
                this.model.push(newTitle)
                await nextTick()
                this.$refs['title-' + newTitle.id].openCloseEditor(true)
            },
            nextID(ids) {
                var minID = _.min(ids)
                if (minID > 0)
                    return 0
                return minID - 1
            },
            onPrimaryChange(ev) {
                if (ev.node.primaryContact) {
                    this.model.forEach((title) => {
                        if (!title.delete && (title.active || this.advancedMode)) {
                            if (title.primaryContact && title.id != ev.node.id) {
                                this.$refs['title-' + title.id].setPrimary(false)
                            }
                        }
                    })
                }
                else if (this.model) {
                    var titleSet = _.orderBy(this.model, ['active', 'verified', 'important'], ['desc', 'desc', 'desc'])[0]
                    this.$refs['title-' + titleSet.id].setPrimary(true)
                }
            },
            validateModel() {
                var errorCount = 0
                this.model.forEach((title) => {
                    if (!title.delete) {
                        var errMessages = []
                        if (!title.title) errMessages.push('missing title')
                        if (errMessages.length > 0) {
                            errorCount++
                            this.$refs['title-' + title.id].setProp('error', errMessages.join(', '))
                        }
                    }
                })
                return errorCount
            },
            saveChanges(suppressToastMessages) {
                return new Promise((resolve, reject) => {
                    var dirtyCount = this.model.filter((lob) => lob.dirty).length
                    var delCount = this.model.filter((lob) => { return !lob.delete && lob.id > 0 }).length
                    if (!(dirtyCount + delCount)) {
                       // if (!suppressToastMessages)
                         //   this.$toast.add({ severity: 'info', summary: 'Nothing Changed', detail: 'not saving', life: 3000 })
                        resolve({ changed: false })
                    }
                    var errorCount = this.validateModel()
                    if (errorCount) {
                        if (!suppressToastMessages)
                            this.$toast.add({ severity: 'error', summary: 'People not valid', detail: errorCount + ' invalid persons', life: 3000 })
                        reject('People not valid: ' + errorCount + ' invalid persons')
                    }
                    else {
                        this.$store.dispatch('ais/saveLOBs', this.model).then((results) => {
                            console.log('savedlobs', results)
                            if (results.changed) {
                                this.model = results.lobs
                                if (!suppressToastMessages)
                                    this.$toast.add({ severity: 'info', summary: 'People Saved', life: 3000 })
                                resolve({ changed: true })
                            }
                            else {
                               // if (!suppressToastMessages)
                                 //   this.$toast.add({ severity: 'info', summary: 'Nothing Changed', detail: 'not saved', life: 3000 })
                                resolve({ changed: false })
                            }
                        }).catch(error => {
                            if (!suppressToastMessages)
                                this.$toast.add({ severity: 'error', summary: 'Error saving People', detail: error.message, life: 3000 })
                            reject('Error saving People: ' + error.message)
                        })
                    }
                })
            },
            fetch(opt) {
                console.log('fetchPeople', opt)
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('company/fetchTitles', { 'ownerID': opt.ownerID, 'lobTypeID': opt.lobTypeID })
                        .then((results) => {
                            console.log('fetchPeople Results', results)
                            if (results && results.people) {
                                console.log('loadedpeople', results.people)
                                this.model = results.people
                            }
                            resolve()
                        }).catch(error => {
                            if (!opt.suppressToastMessages)
                                this.$toast.add({ severity: 'error', summary: 'Error fetching People', detail: error, life: 3000 })
                            reject()
                        })
                })
            }
        },       
        setup(props, context) {
            const getModel = () => {
                return props.modelValue ? _.cloneDeep(props.modelValue) : []
            }
            const model = ref(getModel())

            model.value.forEach((title) => {
                title.delete = false
                title.dirty = false
            })


            const editing = ref(false)
            const advancedMode = useModelWrapper(props, context.emit, 'advanced')

            return { getModel, model, advancedMode, editing }

        },
        watch: {
            "modelValue": {
                handler() {
                    this.model = this.getModel()
                }
            },
        }
    }
</script>
<style scoped lang="scss">
    .last-box {
        position: absolute;
        left: calc(33.33% + 12px);
    }
</style>
