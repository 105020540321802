import { computed } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'

export function getLobTools() {
    const store = useStore()

    // const
    const defaultLobFilterVal = {
        industry: [], attribute: [], audience: [], subject: [], lobDescents: {}, children: []
    }

    // computed
    const treeMapRev = computed(() => store.getters['ais/treeMapRev'])

    // methods
    const addNodeIDToFilter = (opt) => {
        return new Promise((resolve, reject) => {
            console.log('addNodeID', opt)
            if (!opt && opt.nodeID) {
                reject('bad params')
                return
            }
            var nodeIDs = []
            if (_.isArray(opt.nodeID))
                nodeIDs = opt.nodeID
            else {
                if (_.isString(opt.nodeID) && opt.nodeID.indexOf(']') > 0)
                    nodeIDs = JSON.parse(opt.nodeID)
                else
                    nodeIDs.push(opt.nodeID)
            }
            console.log('addNodeID', opt, nodeIDs)
            if (nodeIDs) {
                getNodeIDsPromise(nodeIDs).then(() => {
                    var newFilterVal = opt.filterVal ?? defaultLobFilterVal
                    nodeIDs.forEach((nID) => {
                        var node = store.state.ais.nodeData[nID]
                        if (node && node.treeID) {
                            var treeName = treeMapRev.value[node.treeID]
                            if (treeName && newFilterVal[treeName]) {
                                var treeVals = newFilterVal[treeName]
                                if (treeVals.filter((n) => n.id == opt.nodeID).length == 0) {
                                    treeVals.push({ id: node.id, entry: node.name })
                                    newFilterVal[treeName] = treeVals
                                }
                            }
                        }
                    })
                    resolve(newFilterVal)
                }).catch(error => {
                    reject(error)
                })               
            }
            else
                reject('bad params')
        })
    }

    const getNodeIDsPromise = (nodeIDs) => {
        console.log('loading cards')
        let promises = []
        for (let i = 0; i < nodeIDs.length; i++) {
            promises.push(new Promise((resolve, reject) => {
                store.dispatch('ais/getNode', { id: nodeIDs[i] }).then(() => {
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
            )
        }
        return Promise.all(promises)
    }

    return {
        // const
        defaultLobFilterVal,
        // computed
        treeMapRev,
        // methods
        addNodeIDToFilter
    }
}