<template>
    <template v-if="el === 'link'">
        <a :href="href" :class="[buttonClasses,size,$attrs.class]" :title="title" :icon="icon" :target="target" :disabled="disabled">
            <i v-if="icon" :class="iconClass"></i>
            <span v-if="count" class="count">{{ count }}</span>
            <slot />
        </a>
    </template>
    <template v-if="el === 'button'">
        <button :class="[buttonClasses,size,$attrs.class]" :title="title" :icon="icon" @click="$emit('click', $event)" :disabled="disabled">
            <i v-if="icon" :class="iconClass"></i>
            <span v-if="count" class="count">{{ count }}</span>
            <slot />
        </button>
    </template>
</template>

<script>export default {
        name: 'Btn',
        props: {
            el: { type: String, default: 'button' },
            type: { type: String, default: 'primary' },
            event: { type: String, default: 'button' },
            size: { type: String, default: 'default' },
            href: { type: String, default: 'javascript:void(0);' },
            title: { type: String, default: '' },
            icon: { type: String, default: '' },
            target: { type: String, default: '_blank' },
            disabled: { type: Boolean, default: false },
            count: { default: null } // No type checking so that we can set it either to a number or a string
        },
        emits: ['click'],
        computed: {
            buttonClasses() {
                return {
                    'button-primary': this.type === 'primary',
                    'button-secondary': this.type === 'secondary',
                    'button-tertiary': this.type === 'tertiary',
                    'button-error': this.type === 'error',
                    'button-icon': this.type === 'icon',
                    'button-icon box': this.type === 'icon-box',
                    'button-text': this.type === 'text',
                    'button-box': this.type === 'box'
                }
            },
            iconClass() {
                if (this.icon) {
                    return 'fas' + ' fa-' + this.icon
                } else {
                    return null
                }
            }
        }
    }
    </script>

<style scoped lang="scss">
</style>