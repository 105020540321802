import axios from 'axios'

const state = () => ({
    comments: {},
    flags: {
        "bookmark": { key: 'bookmark', hashtag: 'bookmark', biCategory: 2048, title: 'Bookmark', titleset: 'Bookmark', titleclear: 'Bookmarked', fa_icon: 'fa-bookmark', icon: 'icon-bookmark', prevFlag: 'You bookmarked this on' },
        "thumbsUp": { key: 'thumbsUp', hashtag: 'rating', biCategory: 256, value: 'ThumbUp', title: 'Like', titleset: 'Like it', titleclear: 'Liked', fa_icon: 'fa-thumbs-up', icon: 'icon-thumb-up', prevFlag: 'You liked this company on' },
        "thumbsDown": { key: 'thumbsDown', hashtag: 'rating', biCategory: 256, value: 'ThumbDown', title: 'Dislike', titleset: 'Dislike it', titleclear: 'Disliked', fa_icon: 'fa-thumbs-down', icon: 'icon-thumb-down', prevFlag: 'You disliked this company on' },
        "flagAction": { key: 'flagAction', hashtag: 'flag', biCategory: 128, titleset: 'Set Reminder', title: 'Reminder', titleclear: 'Reminder set', fa_icon: 'fa-flag', icon: 'icon-flag', prevFlag: 'You set a reminder about this company on', prevReminderFlag: 'You were reminded about this company on', nextReminderFlag: 'You are set to be reminded about this company on' },
        "bellAction": { key: 'bellAction', hashtag: 'research', biCategory: 512, title: 'Notification', titleset: 'Notify Research', titleclear: 'Research notified', fa_icon: 'fa-bell', icon: 'icon-bell', prevFlag: 'You notified research about this company on' },
        "shareAction": { key: 'shareAction', hashtag: 'share', biCategory: 1024, title: 'Share', titleset: 'Share it', titleclear: 'Shared', fa_icon: 'fa-share-square', icon: 'icon-send', prevFlag: 'You shared this company on' }
    }
})

// getters
const getters = {
    flags(state) { return state.flags }
}

// actions
const actions = {
    flag(state, opt) {
        return new Promise((resolve, reject) => {
            if (!opt) {
                reject('bad parameters')
                return
            }
            axios.post('/Flag/OwnerUserFlagSetUnset/', opt)
                .then(r => r.data)
                .then(result => {
                    if (result.success) {
                        resolve(result.jsonResult);
                    }
                    else
                        reject(result.message);
                });
        });
    }
}

// mutations
const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
