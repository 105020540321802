<template>
  <ul class="menu-horizontal" :class="options">
    <slot />
  </ul>
</template>

<style>
</style>

<script>
export default {
  props: ['options'],
  components: {

  },
  data() {
    return {

    }
  },
}
</script>