export default {
    data() {
        return {
            isShowing: false
        }
    },
    methods: {
        openInnerPanel: function() {
            this.$refs.innerSidebar.openInnerSidebar();
        }
    }
};