<template>
    <template v-if="aggPanels.length">
        <section class="card-section">
            <header class="section-header">
                <nav>
                    <ul class="menu-horizontal pills nav nav-tabs aggTabs">
                        <li class="menu-item" v-for="(pnl, idx) in aggPanels" :key="pnl.aggTabID + '_tab' + idx">
                            <a href="javascript:void(0)" @click=" activePanelIndex = idx" :class="idx == activePanelIndex ? 'active' : ''">{{ pnl.statFamily }}</a>
                        </li>
                    </ul>
                </nav>
            </header>
            <section class="section-body tabs-panels aggTabPanels">
                <div v-for="(pnl, idx) in aggPanels" :key="pnl.aggTabID + '_panel' + idx" v-show="idx == activePanelIndex" class="tab-panel show" :class="idx == activePanelIndex ? 'active' : ''">
                    <ul class="list-stats">
                        <li v-for="stat in pnl.statFacts" :key="stat.statName" class="record-data">
                            <span class="label">{{ stat.statDesc }}</span>
                            <span class="data">
                                <a v-if="carddata && carddata.id" :href="`/Search?cardid=${carddata.id}&dashboardid=${carddata.ownerID}&filtername=${stat.filterName}&filterval=${stat.filterVal ? stat.filterVal : 'HasValue'}`" title="Filter by these records" target="_blank">
                                    {{ stat.statVal }}
                                </a>
                                <a v-else href="javascript:void(0)" @click="applyFilter({ 'filter': stat.filterName, 'filterVal': (stat.filterVal ? stat.filterVal : 'HasValue')})" title="Filter by these records">{{ stat.statVal }}</a>
                            </span>
                        </li>
                    </ul>
                </div>
            </section>
        </section>
    </template>
    <template v-else>
        <div>Stats Data Unavailable.</div>
    </template>
</template>

<script>
    import { ref, computed, inject, onMounted } from 'vue'
    import { getSearchTools, searchAggregateSetup } from '../../composables/SearchTools.js'
    import _ from 'lodash'

    export default {
        name: 'AggregateStats',
        props: {
            carddata: { type: Object },
            aggPanelName: { type: String }
        },
        setup(props) {
            // tools
            const emitter = inject("emitter")   // Inject event bus 

            // Data
            const loading = ref(true)
            const hasData = ref(false)
            const activePanelIndex = ref(0)

            // Computed
            const { searchMaps, aggDefs, aggPanelDefs } = getSearchTools()
            const panelDef = computed(() => aggPanelDefs.value && props.aggPanelName ? aggPanelDefs.value[props.aggPanelName] : null)
            const percentiles = computed(() => props.carddata ?
                (_.isString(props.carddata.percentiles) ? JSON.parse(props.carddata.percentiles) : props.carddata.percentiles) : null)
            const counts = computed(() => props.carddata ?
                    ( _.isString(props.carddata.counts) ? JSON.parse(props.carddata.counts) : props.carddata.counts) : [])
            const aggPanels = computed(() => {
                var panels = []
                if (panelDef.value && panelDef.value.Value && panelDef.value.Value.aggregateNames) {
                    panelDef.value.Value.aggregateNames.forEach((aggName) => {
                        var aggDef = aggDefs.value[aggName]
                        if (aggDef) {
                            var tabData = {
                                aggTabID: 'tab_' + queryID.value ?? 'search' + "_" + aggName,
                                statFamily: aggDef.name,
                                statFacts: []
                            }
                            //format the data
                            if (aggDef.aggType == 'lookup')
                                tabData.statFacts = getLookupStat(aggDef, props.carddata.total)
                            else if (aggDef.aggType == 'percentile')
                                tabData.statFacts = getPercentileStat(aggDef)

                            if (tabData.statFacts.length) {
                                hasData.value = true
                                panels.push(tabData)
                            }
                        }
                    })
                }
                return panels
            })
            const { queryID, query, docType, applyFilter } = searchAggregateSetup(props, emitter)

            // Methods
            const getLookupStat = (aggDef, total) => {
                if (counts.value && counts.value[aggDef.key] && Object.keys(counts.value[aggDef.key]).length > 1) {
                    //console.log('getLookupStat', aggDef, aggDef.map, counts.value[aggDef.key])
                    var filterMap = (aggDef.map ? (aggDef.map[0] === '{' ? JSON.parse(aggDef.map) : aggDef.map) : searchMaps.value[aggDef.mapName])
                    var valueMap = (aggDef.valueMap ? (aggDef.valueMap[0] === '{' ? JSON.parse(aggDef.valueMap) : aggDef.valueMap) : searchMaps.value[aggDef.valueMap])

                    if (!filterMap && searchMaps.value && searchMaps.value[aggDef.mapName])
                        filterMap = searchMaps.value[aggDef.mapName]

                    if (!filterMap && props.carddata && props.carddata.adHocmaps && props.carddata.adHocmaps[aggDef.mapName])
                        filterMap = props.carddata.adHocmaps[aggDef.mapName]

                    var stats = Object.keys(counts.value[aggDef.key]).map((key) => {
                        return {
                            statFamily: aggDef.name,
                            statName: key,
                            statDesc: (filterMap ? filterMap[key] : key), // No map just use the key
                            statVal: counts.value[aggDef.key][key],
                            docCount: total,
                            statOrder: 0,
                            filterName: aggDef.filter,
                            filterVal: (valueMap ? valueMap[key] : key),
                            queryid: queryID.value
                        }
                    })
                    return stats.filter((item) => { return item.statName && item.statName.length > 0 })
                }
                return []
            }
            const getPercentileStat = (aggDef) => {
                if (percentiles.value == null)
                    return []
                var statFacts = []
                var rangeUnits = aggDef.rangeUnits
                if (rangeUnits == null) rangeUnits = ""

                var scale = (rangeUnits == '$MM' ? 1000000 : 1)
                var prefix = (rangeUnits == '$MM' ? "$" : '')
                var suffix = (rangeUnits == '$MM' ? "MM" : ' ' + rangeUnits)
                var midd = (rangeUnits == '$MM' ? "MM - $" : ' - ')

                if (percentiles.value[aggDef.key] != null) {

                    var docCount = percentiles.value[aggDef.key].DocCount

                    var median = (percentiles.value[aggDef.key]["50"] / scale).toFixed(2)
                    var third = (percentiles.value[aggDef.key]["33"] / scale).toFixed(2)
                    var twothirds = (percentiles.value[aggDef.key]["67"] / scale).toFixed(2)

                    statFacts.push({ statFamily: aggDef.name, statName: aggDef.key + 'Median', statDesc: aggDef.name + ' Median', statVal: prefix + median + suffix, docCount: docCount, statOrder: 0, filterName: aggDef.filter, queryid: props.carddata.id, filterVal: null })
                    statFacts.push({ statFamily: aggDef.name, statName: aggDef.key + 'OneThird', statDesc: aggDef.name + ' Middle 1/3', statVal: prefix + third + midd + twothirds + suffix, docCount: docCount, statOrder: 1, filterName: aggDef.filter, queryid: props.carddata.id, filterVal: null });
                    statFacts.push({ statFamily: aggDef.name, statName: aggDef.key + 'DocCount', statDesc: aggDef.name + ' Count', statVal: docCount, docCount: docCount, statOrder: 2, filterName: aggDef.filter, queryid: props.carddata.id, filterVal: null });

                }
                return statFacts;
            }

            // lifecycle
            onMounted(() => {
                loading.value = true
                hasData.value = false
                activePanelIndex.value = 0
            })

            return {
                // inject
                emitter,
                // getters
                searchMaps, aggDefs, aggPanelDefs,
                // Data
                loading, hasData, activePanelIndex,
                // Computed
                panelDef, percentiles, counts, queryID, query, docType, aggPanels,
                // Methods
                getLookupStat, getPercentileStat, applyFilter
            }
        }

    }
    
</script>

<style scoped lang="scss">
</style>