//import { ref } from 'vue'

export function getLocalStorageTools() {


    const fetchLocal = (key, defaultValue) => {
        //console.log('fetchLocal',key)
        if (defaultValue == undefined) defaultValue = {}
        try {
            var val = localStorage.getItem(key)
            return val ? JSON.parse(val) : defaultValue
        }
        catch {
            return defaultValue
        }
    }
    const saveLocal = (key, val) => {
        //console.log('saveLocal', key)
        var success = false
        if (val === null)
            localStorage.removeItem(key)
        else {
            try {
                localStorage.setItem(key, JSON.stringify(val))
                success = true
            }
            catch (error) {
                console.log('saveLocal', error)
            }
        }
        return success
    }
    const saveLocalPromise = (key, val) => {
        //console.log('saveLocalPromise', key)
        return new Promise((resolve, reject) => {
            if (val === null)
                localStorage.removeItem(key)
            else {
                try {
                    localStorage.setItem(key, JSON.stringify(val))
                    resolve()
                }
                catch (error) {
                    console.log('saveLocal', error)
                    reject(error)
                }
            }
        })
    }

    
    return {
        fetchLocal, saveLocal, saveLocalPromise
    }
}
/*
export function getIndexDBTools(store_name) {

    const DB_VERSION = 1
    const DB_NAME = 'cmdmDB'

    const db = ref(null)
    const ready = ref(false)

    const setupDb = async() => {
        db = await getDb();
        this.cats = await this.getCatsFromDb();
        this.ready = true;
    }
    const getDb = async ()=> {
        return new Promise((resolve, reject) => {

            let request = window.indexedDB.open(DB_NAME, DB_VERSION ?? 1);

            request.onerror = e => {
                console.log('Error opening db', e);
                reject('Error');
            };

            request.onsuccess = e => {
                resolve(e.target.result);
            };

            request.onupgradeneeded = e => {
                console.log('onupgradeneeded');
                let db = e.target.result;
                let objectStore = db.createObjectStore(store_name, { autoIncrement: true, keyPath: 'id' });
            };
        });
    }
    const fetchFromDb = async (key)=> {
        return new Promise((resolve, reject) => {
            let theVal = null;

            let trans = db.value.transaction([key], 'readonly');
            trans.oncomplete = e => {
                resolve(cats);
            };

            let store = trans.objectStore(key);
            let theVal = [];

            store.openCursor().onsuccess = e => {
                let cursor = e.target.result;
                if (cursor) {
                    cats.push(cursor.value)
                    cursor.continue();
                }
            };

        });
    },

    return { ready, setupDb, setupDb, getDb }
}
*/