import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'es6-promise/auto'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import Vue3Mq from 'vue3-mq'
import AuthHelper from './AuthHelper.js'
import { ObserveVisibility } from 'vue-observe-visibility'
import mitt from 'mitt'                // Import mitt

//Global UI Components:
import Btn from './components/ui/base/Btn.vue'
import Modal from './components/ui/modals/Modal.vue'
import Dropdown from './components/ui/dropdowns/Dropdown.vue'
import Checkbox from './components/ui/forms/Checkbox.vue'
import SpinLoader from './components/baseComponents/SpinLoader.vue'
import Toast from 'primevue/toast'
//import CustomAlert from './plugins/CustomAlert'
import NextAlert from './plugins/NextAlert'
import FlagTool from './plugins/FlagTool'

import showTheModal from './mixins/showTheModal'
import mqHelper from './mixins/mqHelper'
import openSidepanel from './mixins/openSidepanel'
import openSidebar from './mixins/openSidebar'
import formatText from './mixins/formatText'
import debugMode from './mixins/debugMode'

const prodEnvironments = [ "DebugProdData", "Production", "Release" ]
const debug = !prodEnvironments.includes(process.env.NODE_ENV)

var app = createApp(App)
app.use(VueAxios, axios)
axios.defaults.baseURL = debug ? '/api/' : 'https://cmdm-beta.berkerynoyes.com/api/'
console.log('isDebugmode', debug, axios.defaults.baseURL)
axios.defaults.withCredentials = true
axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    //let originalRequest = error.config
    console.log(error.toJSON())
    if (error.response.status === 401) {
        console.log(error)
        var currentLoc = encodeURIComponent(window.location)
        if (!AuthHelper.isAuthenticated()) {
            if (!error.config.url.startsWith('account/')) {
                window.alert('You have been logged out. Please Login again\r\n' + error.config.url)
                window.location.href = '/Account/login?returnURL=' + currentLoc
            }
        }
        else {
            if (!error.config.url.startsWith('account/')) {
                window.alert('You have been logged out. Please Login again\r\n' + error.config.url)
                window.location.href = '/Account/login?returnURL=' + currentLoc
            }
        }
    }
    return Promise.reject(error)
})

app.use(store)
app.use(router)
app.use(PrimeVue)
app.use(Vue3Mq, { breakpoints: { min: 375, mobile: 660, smallscreen: 960, tablet: 1024, laptop: 1200, desktop: 1440, xlarge: Infinity } })
app.use(ToastService)
//app.use(CustomAlert)
app.use(NextAlert)
app.use(FlagTool)

app.directive('observe-visibility', {
    beforeMount: (el, binding, vnode) => {
        vnode.context = binding.instance;
        ObserveVisibility.bind(el, binding, vnode);
    },
    update: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind,
})

app.component('Btn', Btn)
app.component('Modal', Modal)
app.component('Dropdown', Dropdown)
app.component('Checkbox', Checkbox)
app.component('SpinLoader', SpinLoader)
app.component('Toast', Toast)

app.config.productionTip = false
app.mixin(showTheModal)
app.mixin(openSidepanel)
app.mixin(openSidebar)
app.mixin(formatText)
app.mixin(mqHelper)
app.mixin(debugMode)

// Initialize the Event Bus
const emitter = mitt()
app.provide('emitter', emitter)
app.provide('imageSite','https://cmdm-beta.berkerynoyes.com')

app.mount('#app')
