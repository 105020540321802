<template>
    <template v-for="(lobDim, idx) in treeNames" :key="lobDim">
        <section :class="idx > 0 ? 'margin-top-min' : ''" style="display:block">
            <label>{{ capitalizeFirst(lobDim) }}:</label>
            <lob-picker v-model="model[lobDim]" :treeName="lobDim" :multiple="multiple" :inpopup="inpopup" @change="lobDimChanged($event, lobDim)" />
        </section>
    </template>
</template>
<script>
    import { ref, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { getTextTools } from '../../composables/TextTools.js'

    import LobPicker from './LobPicker.vue'

    export default {
        name: 'LobEditor',
        components: {
            LobPicker
        },
        props: {
            modelValue: { type: Object },
            inpopup: { type: Boolean, default: false },
            multiple: { type: Boolean, default: false },
            advancedMode: { type: Boolean, default: false }
        },
        emits: ['update:modelValue'],
        setup(props, { emit }) {
            const store = useStore()
            const { capitalizeFirst } = getTextTools()

            // const
            const newModelTemp = {
                industry: [], attribute: [], audience: [], subject: [], lobDescents: {}, children: [], anding: false
            }

            // model
            const model = computed({
                get: () => props.modelValue ? props.modelValue : newModelTemp,
                set: (newModel) => {
                    console.log('setting Model', newModel, new Date())
                    emit('update:modelValue', newModel)
                }
            })
            const pickerModel = computed({
                get: () => {
                    var pModel = {}
                    treeNames.value.forEach((dim) => {
                        pModel[dim] = []
                        if (model.value && model.value[dim]) {
                            model.value[dim].forEach((node) => {
                                if (!node.name && nodeData.value[node.id]) {
                                    var dimName = nodeData.value[node.id].name
                                    dimName = dimName.indexOf(':') > -1 ? dimName.substr(dimName.indexOf(':') + 1) : dimName
                                    pModel[dim].push({ id: node.id, name: dimName })
                                }
                                else
                                    pModel[dim].push(node)
                            })
                        }
                    })
                    return pModel
                },
                set: (newModel) => {
                    console.log('setting Model', newModel, new Date())
                    treeNames.value.forEach((dim) => {
                        model.value[dim] = newModel[dim]
                    })
                    emit('update:modelValue', model)
                }
            })

            // data
            const wordBuffer = ref([])

            // computed
            const treeNames = computed(() => store.getters['ais/treeNames'])
            const nodeData = computed(() => store.getters['ais/node'])

            // methods
            const lobDimChanged = (nodes, lobDim) => {
                console.log('lobDimChanged', nodes, lobDim, new Date())
                var newModel = model.value
                newModel[lobDim] = nodes ? nodes : []
                model.value = newModel
            }
            const getNodeFetchPromises = (nodeIDs) => {
                console.log('getting node fetches')
                wordBuffer.value = []
                let promises = []
                for (let i = 0; i < nodeIDs.length; i++) {
                    promises.push(new Promise((resolve, reject) => {
                        store.dispatch('ais/getNode', { id: nodeIDs[i] })
                            .then(node => {
                                if (node) {
                                    console.log(node)
                                }
                                resolve()
                            }).catch(error => {
                                reject(error)
                            })
                    }))
                }
                return Promise.all(promises)
            }
          // lifecycle
            onMounted(() => {
                wordBuffer.value = []
                var nodeIDs = []
                treeNames.value.forEach((dim) => {
                    if (model.value[dim])
                        nodeIDs = nodeIDs.concat(model.value[dim].filter((node) => !node.name).map((node) => node.id))
                })
                console.log('onMounted', nodeIDs)
                if (nodeIDs.length > 0) {
                    getNodeFetchPromises(nodeIDs).then(() => {
                        console.log('all node words fetched', wordBuffer.value)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })

            return {
                // model
                model, pickerModel,
                // data 
                wordBuffer,
                // computed
                nodeData, treeNames,
                // methods
                lobDimChanged, getNodeFetchPromises, capitalizeFirst
            }
        }
    }
</script>
<style lang="scss">
   
</style>