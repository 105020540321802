<template>
    <spin-loader :showMe="loading" />
    <dropdown v-if="!loading" v-model="dropDownOpen" type="kebab" class="dashboardOptions">
        <template #body>
            <menu-vertical options="panel options medium">
                <menu-item label="Dashboard Options" :initIsOpen="true">
                    <template #children>
                        <menu-item v-if="!userIsOwner" :label="'Owned by ' + dashboardOwnerName" icon="user" />
                        <menu-item @click.prevent="share" label="Share Dashboard..." />
                        <menu-item v-if="userIsOwner" label="Rename Dashboard">
                            <form class="form-attributes-edit">
                                <div class="input-group nowrap">
                                    <div class="input-wrapper">
                                        <input class="style-alternate" type="text" v-model="dashboardName" autocomplete="new-password">
                                    </div>
                                    <spin-loader :showMe="loading" />
                                    <button v-if="!loading" class="button-primary" @click.prevent="rename">Save</button>
                                </div>
                            </form>
                        </menu-item>
                        <menu-item label="Copy Dashboard">
                            <form class="form-attributes-edit">
                                <fieldset>
                                    <label>New Dashboard Name</label>
                                    <input class="style-alternate form-control fullwidth" required="" v-model="targetNewName" type="text" autocomplete="new-password">
                                </fieldset>
                                <fieldset class="">
                                    <!--<label>Target User</label>
                                    <user-select :inpopup="true" v-model="copyTargetUser" :multiple="false" />-->
                                    <fieldset class="checkbox margin-top-xsmall">
                                        <input v-model="copyReplaceFilters" name="replaceFilters" :id="'copyDash-replaceFilters'" type="checkbox">
                                        <label :for="'copyDash-replaceFilters'">Replace the UserDate filters</label>
                                    </fieldset>
                                </fieldset>
                                <spin-loader :showMe="loading" />
                                <fieldset class="" v-if="!loading">
                                    <button @click.prevent="copy" class="button-tertiary fullwidth">Copy Dashboard</button>
                                </fieldset>
                            </form>
                        </menu-item>

                        <menu-item @click.prevent="refresh" label="Refresh Cards" />
                        <menu-item v-if="!loading && userIsOwner" label="Delete Dashboard" @click.prevent="deleteDashboard" />
                    </template>
                </menu-item>
                <menu-item label="Move" v-if="showMove">
                    <template #children>
                        <spin-loader :showMe="loading" />
                        <template v-if="dashboard.idx > 0 && !loading">
                            <menu-item label="To Front" icon="angle-double-left" @click="move(0)" />
                            <menu-item label="Forward" icon="angle-left" @click="move(dashboard.idx - 1)" />
                        </template>
                        <template v-if="dashboard.idx != dashboards.length - 1 && !loading">
                            <menu-item label="Backward" icon="angle-right" @click="move(dashboard.idx + 1)" />
                            <menu-item label="To Back" icon="angle-double-right" @click="move(dashboards.length - 1)" />
                        </template>
                    </template>
                </menu-item>
            </menu-vertical>
        </template>
    </dropdown>
</template>

<script>
    import { ref, computed, onMounted, inject } from 'vue'
    import { useStore } from 'vuex'
    import { useToast } from '../../composables/ModelWrapper.js'
    import { getTextTools } from '../../composables/TextTools.js'

    import MenuVertical from "../ui/menus/MenuVertical.vue"
    import MenuItem from "../ui/menus/MenuItem.vue"
    //import UserSelect from '../baseComponents/UserSelect.vue'

    import { useRouter } from 'vue-router'

    export default {
        name: 'DashboardOptions',
        components: {
            MenuVertical, MenuItem
        },
        props: ['dashboard', 'dashboards', 'dashboardGroup'],
        emits: ['refreshcards'],
        setup(props, { emit }) {
            const store = useStore()
            const toast = useToast()
            const flagShare = inject("flagShare")
            const router = useRouter()

            // data
            const dropDownOpen = ref('closed')
            const loading = ref(false)
            //const copyTargetUser = ref(null)
            const dashboardName = ref(null)
            const targetNewName = ref(null)
            const copyReplaceFilters = ref(false)

            const nextConfirm = inject('nextConfirm')

            // computed
            const dashboardOwnerName = computed(() => props.dashboard && props.dashboard.entryUserName ? props.dashboard.entryUserName : '?')
            const currentUserID = computed(() => store.getters['userInfo/currentUserID'])
            const userIsOwner = computed(() => props.dashboard ? currentUserID.value == props.dashboard.entryUserID : false)
            const userIsGroupOwner = computed(() => props.dashboardGroup ? currentUserID.value == props.dashboardGroup.entryUserID : false)
            const showMove = computed(() => userIsGroupOwner.value && props.dashboard && props.dashboard.idx >= 0)

            // Methods
            const { dashboardNameCollision } = getTextTools()
            const share = () => {
                if (props.dashboard) {
                    flagShare(props.dashboard).then((res) => { console.log('shareCompleted', res) }).catch((err) => { console.log('shareError', err) })
                }
            }
            const rename = () => {
                if (!dashboardName.value) {
                    toast.add({ severity: 'error', summary: "The name can't be blank", life: 3000 })
                    return;
                }
                else if (dashboardNameCollision(dashboardName.value, props.dashboards)) {
                    toast.add({ severity: 'error', summary: "The name is already in use by another Dashboard", life: 3000 });
                    return;
                }

                loading.value = true
                store.dispatch('dashboards/renameDashboard', { 'id': props.dashboard.id, 'newName': dashboardName.value }).then(() => {
                    dashboardName.value = props.dashboard.name
                    loading.value = false
                    dropDownOpen.value = 'closed'
                    toast.add({ severity: 'success', summary: 'Renamed Dashboard', detail: 'Renamed to ' + dashboardName.value, life: 2000 })
                }).catch(error => {
                    loading.value = false
                    toast.add({ severity: 'error', summary: 'Error renaming Dashboard', detail: error, life: 3000 })
                })
            }
            const copy = () => {

                if (!targetNewName.value) {
                    toast.add({ severity: 'error', summary: 'No name for dashboard', detail: 'The copied dashboard needs a name', life: 3000 });
                    return;
                }
                else if (dashboardNameCollision(targetNewName.value, props.dashboards)) {
                    toast.add({ severity: 'error', summary: "The name is already in use by another Dashboard", life: 3000 });
                    return;
                }

                /*var selUser = copyTargetUser.value && copyTargetUser.value[0] ? copyTargetUser.value[0] : null;
                if (!selUser) {
                    toast.add({ severity: 'error', summary: 'No user selected', detail: 'Select an user to copy the dashboard to', life: 3000 });
                    return;
                }*/

                loading.value = true;
                store.dispatch('dashboards/copyDashboard', {
                    'id': props.dashboard ? props.dashboard.id : 0,
                    'newName': targetNewName.value,
                    'targetUserID': currentUserID.value,
                    'replaceFilters': copyReplaceFilters.value
                }).then((result) => {
                    loading.value = false;
                    if (result.id) {
                        dropDownOpen.value = 'closed'
                        toast.add({ severity: 'success', summary: 'Copied Dashboard', detail: "Copied to your dashgroup", life: 3000 })

                        window.open("/dashboard/" + result.id, "_blank");
                    }
                    else
                        toast.add({ severity: 'error', summary: 'Error', detail: 'Error copying Dashboard', life: 3000 });
                })

            }
            const move = (idx) =>{
                loading.value = true;
                store.dispatch('dashboards/moveDashboard', {
                    'id': props.dashboard.id,
                    'index': idx
                }).then(() => {
                    loading.value = false;
                    dropDownOpen.value = 'closed'
                    toast.add({ severity: 'success', summary: 'Moved Dashboard', detail: 'Moved to ' + idx, life: 3000 })
                })
            }

            const deleteDashboard = () => {
                nextConfirm('Delete dashboard?').then((res) => {
                    if (res) {
                        loading.value = true
                        store.dispatch('dashboards/deleteDashboard', {
                            'id': props.dashboard.id
                        }).then(() => {
                            loading.value = false;
                            dropDownOpen.value = 'closed'
                            toast.add({ severity: 'success', summary: 'Deleted Dashboard', detail: 'Returning to Main dashboard...', life: 3000 })

                            setTimeout(function () {
                                router.push('/dashboard/home')
                            }, 3000)

                        }).catch((error) => {
                            loading.value = false;
                            toast.add({ severity: 'error', summary: 'Error Deleting Dashboard', detail: error, life: 3000 })
                        })
                    }
                })
            }

            const refresh = () => {
                emit('refreshcards')
                dropDownOpen.value = 'closed'
            }
            const saveGlobalOptions = () => {
                toast.add({ severity: 'warn', summary: 'Not Implemented', detail: 'Save Global Options not implemented', life: 3000 });
            }

            onMounted(() => {
                dropDownOpen.value = 'closed'
                loading.value = false
                //copyTargetUser.value = null
                dashboardName.value = props.dashboard ? props.dashboard.name : ''
                targetNewName.value = props.dashboard ? 'Copy of ' + props.dashboard.name : 'New Dashboard'
                copyReplaceFilters.value = false;
            })

            return {
                // tools
                flagShare,
                // data
                dropDownOpen, loading, dashboardName, targetNewName, copyReplaceFilters,
                // computed
                currentUserID, userIsOwner, userIsGroupOwner, showMove, dashboardOwnerName,
                // methods
                share, rename, move, copy, refresh, saveGlobalOptions, dashboardNameCollision, deleteDashboard
            }
        }
    }
</script>

<style scoped lang="scss">
    
</style>