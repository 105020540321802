import _random from 'lodash/random'

export default {
    props: {
        id: {
            type: String,
            default: null
        }
    },
    computed: {       
        realID() {
            if (this.id)
                return this.id
            return 'el-' + _random(0, (new Date() * 10000) + 621355968000000000)
        }
    },
    methods: {
        getID(seed) {
            return 'el-' + _random(0, (new Date() * 10000) + 621355968000000000) + seed
        }
    }
}