<template>
    <template v-if="canHaveImage">
        <template v-if="useTextImage">
            <div class="no-image" :title="imgSource"  v-observe-visibility="{ callback: visibilityChanged,  once: true}">{{ imgName }}</div>
        </template>
        <template v-else>
            <span :class="$attrs.class">
                <img v-if="!imgMissing" 
                     :title="recordTitle" 
                     class="logo thumbnail img-responsive" 
                     :alt="recordTitle" 
                     :src="imgSource"
                    >
            </span>
        </template>
    </template>   
</template>

<script>
    import { ref, computed } from 'vue'
    import { useStore } from 'vuex'
    import _ from 'lodash'
    import { getRecordTools } from '../../composables/RecordTools.js'

    export default {
        name: 'RecordImageSimple',
        props: {
            'obj': { type: Object },
            'noText': { type: Boolean, default: false }
        },
        setup(props) {
            // tools
            var store = useStore()
            // const
            const imageOwners = ['company', 'acquisition']
            // data
            const imgData = ref(null)
            const isVisible = ref(false)

            // computed
            const { objectType, objectID, objectKey, storeKey, objectName, recordTitle } = getRecordTools(props)
            const imgSource = computed(() => {
                var result = '/Image/missing.jpg'
                if (props.obj && (isVisible.value || !useTextImage.value)) {
                    if (props.obj.imageUrl) {
                        result = "https://" + props.obj.imageUrl
                    }
                    else {
                        switch (objectType.value) {
                            case 'company':
                                if (imgData.value)
                                    result = imgData.value
                                else 
                                    fetchImageState()
                                //result = '/Image/CompanyLogo?id=' + objectID.value + '&height=100&addwings=True&generateimage=True'
                                break
                            case 'acquisition':
                                result = '/Image/Tombstone?id=' + objectID.value
                                break
                        }
                    }
                }
                return result
            })
            const imgMissing = computed(() => imgSource.value == '/Image/missing.jpg')
            const imgName = computed(() => {
                var result = "n a"
                if (props.obj) {
                    switch (objectType.value) {
                        case 'company':
                            result = props.obj.normalizedName ? props.obj.normalizedName : (objectName.value ?? result)
                            break
                        default:
                            result = objectName.value ?? result
                            break
                    }
                }
                return result.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
            })

            const canHaveImage = computed(() => imageOwners.includes(objectType.value))
            const useTextImage = computed(() => objectType.value == 'company' && imgMissing.value && !props.noText)
            const iconClass = computed(() => 'icon-' + objectType.value)
            const imageStates = computed(() => store.state.objectCache.imageState)

            //methods
            const visibilityChanged = (newVisiblity, entry) => {
                //console.log(entry)
                _.noop(entry)
                isVisible.value = newVisiblity
            }
            const fetchImageState = () => {
                if (!isVisible.value) return
                if (imageStates.value[storeKey] != undefined) {
                    imgData.value = imageStates.value[storeKey].hasImage ? '/Image/CompanyLogo?id=' + objectID.value + '&height=100&addwings=True&generateimage=False' : '/Image/missing.jpg'
                }
                else {
                    store.dispatch('objectCache/fetchImageState', { objectKey: objectKey.value })
                        .then((result) => {
                            imgData.value = result.imageState.hasImage ? '/Image/CompanyLogo?id=' + objectID.value + '&height=100&addwings=True&generateimage=False' : '/Image/missing.jpg'
                        }).catch(error => {
                            console.log('fetchImageState', error)
                            imgData.value = '/Image/missing.jpg'
                        })
                }
            }

            return {
                // data
                imgData, isVisible,
                // const
                imageOwners,
                // computed
                objectType, objectID, objectKey, storeKey, objectName, recordTitle, 
                canHaveImage, useTextImage, iconClass, imgName, imgSource, imgMissing,
                imageStates,
                // methods
                visibilityChanged, fetchImageState
            }
        }

    }
</script>

<style scoped lang="scss">
    .text-danger {
        color: red;
    }
</style>