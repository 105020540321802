<template>
    <header class="welcome-header">
        <div class="main-brand full-brand">
            <a href="">
                <img src="../assets/images/MandaSoft_Logo_Standard.svg" width="180" height="130" />
            </a>
            <p class="brand-tagline">{{ subTitle }}</p>
        </div>
        <div v-if="user">
            <div class="margin-bottom">Welcome {{ user.description }}</div>
            <btn el="link" href="/dashboard/home" target="_self">Let's Start</btn>
        </div>
        <div v-else class="login">
            <btn size="large" el="link" href="/account/login" target="_self">Login</btn>
        </div>
    </header>

    <section class="welcome-body">
        <h2>M&amp;A trends for the last 6 months</h2>
        <div class="card frame margin-top-large">
            <chart-trends chartDefinition="PeStrategicStacked" :chartData="PeStrategicStackedData"></chart-trends>
        </div>
        <div class="card frame margin-top">
            <chart-pe-strategic-periods></chart-pe-strategic-periods>
        </div>
    </section>

    <footer class="welcome-footer margin-top">
        <ul class="menu-horizontal align-center justify-center">
            <li class="">
                <a href="https://berkerynoyes.com/aboutus/privacy-policy/" target="_blank">Privacy</a>
            </li>
            <li class="">
                <a href="https://mandasoft.com/Home/TermsOfUse" target="_blank">Terms of Use</a>
            </li>
            <li class="">
                <a id="jiraTrigger" href="javascript:void(0);">Create Support Issue</a>
            </li>

            <li>
                <a href="https://mandasoft.com" target="_blank">MandaSoft Public Site</a>
            </li>
            <li>
                <a href="https://twitter.com/berkerymanda" target="_blank">Twitter</a>
            </li>
        </ul>
    </footer>
</template>

<script>

    import ChartTrends from "./charts/ChartTrends.vue"// "..//charts/ChartMandaDynamics.vue"
    import ChartPeStrategicPeriods from "./charts/ChartPeStrategicPeriods.vue"
    import { mapGetters } from 'vuex'

    export default {
        name: 'Welcome',
        components: { ChartTrends, ChartPeStrategicPeriods },
        props: {
            name: { type: String, default: 'CMDM' },
            subTitle: { type: String, default: 'The Genome of the Information Industry' }
        },
        data: function () {
            return {
                PeStrategicStackedData: [{
                    category: "Value",
                    value1: 99.51,
                    value2: 169.44,
                },
                {
                    category: "Volume",
                    value1: 2937,
                    value2: 1338,
                }
                ]
            }
        },
        computed: {
            ...mapGetters({
                user: 'userInfo/currentUser'
            })
        },
        mounted() {
            document.title = 'MandAsoft - The Genome of the Information Industry'
        }
    }
</script>

<style scoped lang="scss">

</style>
