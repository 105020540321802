import axios from 'axios'
//import _ from 'lodash'
import _ from 'lodash'

const state = () => ({
    bookmarks: { members: [], subsets : [], fetched: false },
})

// getters
const getters = {
    bookmarks(state) { return state.bookmarks },
    bookmarkMembers(state) { return state.bookmarks.members },
    bookmarkCount(state) { return state.bookmarks.members.length }
}

// actions
const actions = {
    fetchBookmarks({ state, commit, rootState }, opt) {
        return new Promise((resolve, reject) => {
            //fix the opt to make sure we can still do the call
            if (!opt)
                opt = {};
            if (!opt.id)
                opt.id = rootState.userInfo && rootState.userInfo.user && rootState.userInfo.user.userInfo ? rootState.userInfo.user.userInfo.activeFlagListID : 0;

            //default to state unless specified otherwise
            if (opt.keepStore && state.bookmarks && state.bookmarks.fetched)
                resolve(state.bookmarks)
            else if (opt.id) {
                axios.get('/List/GetListMembers?id=' + opt.id + '&limit=30')
                    .then(r => r.data)
                    .then(result => {
                        if (result) {
                            result.fetched = true;
                            commit('SET_BOOKMARKS', result)
                            resolve(result);
                        }
                        else
                            reject("Error fetching the list");
                    });
            }
        });
    },
    updateSubset({ dispatch, state }, opt) {
        return new Promise((resolve, reject) => {
            axios.post('List/UpdateSubset/', opt)
                .then(r => r.data)
                .then(result => {
                    if (result && result.success) {
                        dispatch('fetchBookmarks', { id: opt.ownerID }).then(() => {
                            resolve()
                        })
                    }
                    else
                        reject("Error:" + result.message);
                });
            _.noop(state);
        });
    },
    addToDashboardCards({ dispatch }, opt) {
        return new Promise((resolve, reject) => {
            var validRequests = true;
            _.each(opt, function (req) {
                if (!req.items || req.items.length == 0 || (!req.cardID && !req.cardName) || (!req.dashboardID && !req.dashboardName) || (req.cardID && !req.dashboardID))
                    validRequests = false;
            });

            if (validRequests) {
                axios.post('List/AddItemsToCards/', opt)
                    .then(r => r.data)
                    .then(result => {
                        if (result.success) {
                            var newCard = JSON.parse(result.jsonResult);

                            dispatch('userInfo/loadLayout', null, { root: true });
                            dispatch('dashboards/fetchDashboard', { id: newCard.ownerID }, { root: true }).then(() => {
                                resolve(newCard)
                            })
                        }
                        else
                            reject('Error adding items to cards')

                    }).catch(function (error) {
                        reject(error)
                    });
            }
            else
                reject('Invalid parameters');
        });
    },
    addItemsToList({ state }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.items || opt.items.length == 0 || (!opt.listName && !opt.listID))
                reject("Bad parameters");

            axios.post('List/AddItemsToList', opt)
                .then(r => r.data)
                .then(result => {
                    if (result && result.success) {
                        var list = JSON.parse(result.jsonResult);
                        resolve(list);
                    }
                    else
                        reject("Error adding to list");
                });
            _.noop(state);
        });
    }
    
}



// mutations
const mutations = {
    SET_BOOKMARKS(state, opt) {
        if (!opt.members) opt.members = [];
        if (!opt.subsets) opt.subsets = [];

        state.bookmarks = opt
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
