<template>
    <section class="card-section">
        <ul v-if="tagCloudName == 'significantLOBNode'" class="menu-horizontal cat-filters">
            <li v-for="tree in treeNames" 
                :key="tree" 
                class="category event-item" 
                :class="activeFilter && activeFilter != tree ? 'hide-cat' : ''"
                @click="tagCloudFilterCategory(tree)"  >
                <i :class="treeIconMap[tree]"></i><span> {{  capitalizeFirst(tree) }}</span>
            </li>      
        </ul>
        <div class="row-tag_cloud list">
            <ul class="list-tags no-icon cloud margin-top-xsmall">
                <li v-for="tagc in tagClouds"
                    :key="tagc.id"
                    :class="'tag-keyword cloud list-item ' + tagc.lob_cat">
                    <span @click="applyFilter({ 'filter': tagc.filterName, 'filterVal': tagc.id != -1 ? tagc.id : tagc.tag})" :style="{ 'cursor': 'pointer', 'font-size': tagc.fontSize}" :title="tagc.score + ' score: ' + tagc.hcount + ' of ' + tagc.bg_count">
                        <i v-if="tagc.icon" :class="tagc.icon"></i> &nbsp;
                        {{ tagc.tag }}
                    </span>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    /*<tag-basic :icon="tagc.icon"
                               :name="tagc.tag"
                               :title="tagc.score + ' score: ' + tagc.hcount + ' of ' + tagc.bg_count"
                               @button-click="applyFilter(tagc.filterName, tagc.id != -1 ? tagc.id : tagc.tag)" />
      import TagBasic from '../ui/tags/TagBasic.vue'
        components: { TagBasic },

    */
    import { ref, inject, computed } from 'vue'
    import { useStore } from 'vuex'
    import { isOpenSetup } from '../../composables/isOpen.js'
    import { getTextTools } from '../../composables/TextTools.js'
    import { searchAggregateSetup } from '../../composables/SearchTools.js'
    import _ from 'lodash'

    
    export default {
        name: 'TagCloud',
        props: ['carddata'],
        setup(props) {
            // tools
            const store = useStore()
            const emitter = inject("emitter")   // Inject event bus 
            const minSize = 80.0
            const maxSize = 180.0

            // data
            const { isOpen } = isOpenSetup()
            const activeFilter = ref(null)

            // computed
            const user = computed(() => store.state.userInfo.user)
            const searchMaps = computed(() => store.state.search.searchMaps)
            const treeIconMap = computed(() => store.state.ais.iconMap)
            const treeNames = computed(() => store.getters['ais/treeNames'])
            const treeMapRev = computed(() => store.getters['ais/treeMapRev'])
            const significantTerms = computed(() => props.carddata ? (_.isString(props.carddata.significantTerms) ? JSON.parse(props.carddata.significantTerms) : props.carddata.significantTerms) : null)
            const tagCloudName = computed(() => props.carddata ? props.carddata.tagCloudName : null)
            const openClass = computed(() => isOpen.value ? 'collapse show' : '')
            const tagClouds = computed(() => {
                var tagdata = []

                if (significantTerms.value && significantTerms.value[tagCloudName.value]) {

                    //get the data
                    tagdata = significantTerms.value[tagCloudName.value].map(term => (
                        {
                            "tag": term.name,
                            "tscore": term.score * 10000,
                            "id": term.id.toString(),
                            "lobDescentID": term.lobDescentID,
                            "hcount": term.count,
                            "score": term.score,
                            "bg_count": term.bg_count,
                            "lob_cat": term.tag ? treeMapRev.value[term.tag] : '',
                            "icon": term.tag && treeMapRev.value[term.tag] ? treeIconMap.value[treeMapRev.value[term.tag]] : '',
                            "filterName": term.filter ? term.filter : (term.id > 0 ? 'nodeID' : 'term')
                        }))
                }

                //set the font size
                if (tagdata.length) {

                    //only for the current category if there's a filter
                    if (activeFilter.value)
                        tagdata = tagdata.filter((tag) => { return tag.lob_cat == activeFilter.value })

                    var minScore = _.minBy(tagdata, function (tag) { return tag.score; }).score
                    var maxScore = _.maxBy(tagdata, function (tag) { return tag.score; }).score
                    var scoreDiff = (maxScore - minScore)
                    var difference = scoreDiff > 0.000001
                    var ratio = (maxSize - minSize) / scoreDiff
                    console.log('tagClouds', ratio, minScore, maxScore, scoreDiff,  minSize, maxSize)

                    tagdata.forEach((tag) => {
                        tag.fontSize = (difference ? (minSize + tag.score * ratio).toFixed(0) : "100") + "%"
                    })
                    tagdata = _.shuffle(tagdata)
                }

                return tagdata
            })
            const hasData = computed(() => tagClouds.value.length > 0)
            const { queryID, query, docType, applyFilter } = searchAggregateSetup(props, emitter)

            // methods
            const { capitalizeFirst } = getTextTools()
            const tagCloudFilterCategory = (category) => {
                if (tagCloudName.value == 'significantLOBNode' && activeFilter.value != category)
                    activeFilter.value = category
                else
                    activeFilter.value = null
            }

            return {
                // constants and tools
                minSize, maxSize, emitter,
                // data
                isOpen, activeFilter,
                // computed
                user, searchMaps, treeIconMap, treeNames, treeMapRev,
                significantTerms, tagCloudName, queryID, query, docType, openClass, tagClouds, hasData,
                // methods
                capitalizeFirst, applyFilter, tagCloudFilterCategory
            }
        }
    }
</script>

<style scoped lang="scss">
</style>