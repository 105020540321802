<template>
    <section class="page-section">
        <h5>radar Chart</h5>
        <div class="hello" ref="chartRadar"></div>
        {{radarChartData}}
        <h5>Simple Chart</h5>
        <h1>{{ msg }}</h1>
        <div class="hello" ref="chartDiv">
        </div>
        <button @click="exportToSvg">Export</button>
    </section>
    <img id="myImage" src="" />
    {{ chartData }}
</template>

<script>
    import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
    import _ from 'lodash'
    import * as am5 from '@amcharts/amcharts5';
    import * as am5xy from '@amcharts/amcharts5/xy';
    import * as am5radar from '@amcharts/amcharts5/radar'
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import * as am5_exporting from "@amcharts/amcharts5/plugins/exporting";
    

    export default {
        name: 'ChartGuide',
        components: {

        },
        props: {
            msg: { type: String, default: 'Chart Demo' }
        },
        setup() {

            const chartDiv = ref(null)
            const chartRadar = ref(null)
            // Define data
            const chartData = ref([{
                category: "Research",
                value1: 1000,
                value2: 588
            },
            {
                category: "Marketing",
                value1: 1200,
                value2: 1800
            }, {
                category: "Sales",
                value1: 850,
                value2: 1230
            }
            ])
            const chartJSON = computed(() => null)

            var root = null
            var exporting = null

            const renderChart = () => {
                console.log(chartDiv.value, root)
                root = am5.Root.new(chartDiv.value);

                root.setThemes([am5themes_Animated.new(root)]);

                let chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        panY: false,
                        layout: root.verticalLayout
                    })
                );



                // Create Y-axis
                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {})
                    })
                );

                // Create X-Axis
                let xAxis = chart.xAxes.push(
                    am5xy.CategoryAxis.new(root, {
                        renderer: am5xy.AxisRendererX.new(root, {}),
                        categoryField: "category"
                    })
                );
                xAxis.data.setAll(chartData.value);

                // Create series
                let series1 = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: "Series",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value1",
                        categoryXField: "category"
                    })
                );
                series1.data.setAll(chartData.value);

                let series2 = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: "Series",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value2",
                        categoryXField: "category"
                    })
                );
                series2.data.setAll(chartData.value);

                // Add legend
                let legend = chart.children.push(am5.Legend.new(root, {}));
                legend.data.setAll(chart.series.values);

                // Add cursor
                chart.set("cursor", am5xy.XYCursor.new(root, {}));

                exporting = am5_exporting.Exporting.new(root, {
                    //menu: am5_exporting.ExportingMenu.new(root, {}),
                    dataSource: chartData.value
                });

            }

            const exportToSvg = () => {
                _.noop(am5_exporting)
                exporting.export("png").then((imgData) => {
                    document.getElementById("myImage").src = imgData;
                })
            }

            var rootRadar = null
           
            const initDims = {
                industry: { color: "red", coeff: 1.0, valueName: '', nodes: [] },
                audience: { color: "green", coeff: 1.5, valueName: '', nodes: [] },
                subject: { color: "blue", coeff: 0.5, valueName: '', nodes: [] },
                attribute: { color: "yellow", coeff: 0.1, valueName: '', nodes: [] }
            }

            const radarChartData = computed(() => {
                return Object.keys(initDims).map((tree) => {
                    return { direction: tree, color: initDims[tree].color, value: initDims[tree].coeff }
                })
            })

            const renderRadarChart = () => {
                rootRadar = am5.Root.new(chartRadar.value);
                // Create chart
                // https://www.amcharts.com/docs/v5/charts/radar-chart/
                var chart = rootRadar.container.children.push(am5radar.RadarChart.new(rootRadar, {
                    panX: false,
                    panY: false,
                    wheelX: "panX",
                    wheelY: "zoomX"/*,
                    startAngle: -45,
                    endAngle: 315*/
                }));

                // Add cursor
                // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
                var cursor = chart.set("cursor", am5radar.RadarCursor.new(rootRadar, {
                    behavior: "none"
                }));

                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);

                // Create axes and their renderers
                // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
                var xRenderer = am5radar.AxisRendererCircular.new(rootRadar, {});
                xRenderer.labels.template.setAll({
                    radius: 20
                });

                var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(rootRadar, {
                    maxDeviation: 0,
                    categoryField: "direction",
                    renderer: xRenderer
                }));

                var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(rootRadar, {
                    renderer: am5radar.AxisRendererRadial.new(rootRadar, {})
                }));

                // Create series
                // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series

                var series = chart.series.push(am5radar.RadarLineSeries.new(rootRadar, {
                    stacked: true,
                    name: "Series ",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    categoryXField: "direction",
                    stroke: am5.color(0x415fff),
                    fill: am5.color(0x415fff),
                    tooltip: am5.Tooltip.new(rootRadar, {
                        labelText: "{categoryX}: {valueY}"
                    })
                }));

                series.strokes.template.set("strokeWidth", 2);
                series.bullets.push(function () {
                    return am5.Bullet.new(rootRadar, {
                        sprite: am5.Circle.new(rootRadar, {
                            radius: 5,
                            fill: series.get("fill"),
                            strokeWidth: 2,
                            stroke: rootRadar.interfaceColors.get("background")
                        })
                    })
                })

                series.data.setAll(radarChartData.value);
                xAxis.data.setAll(radarChartData.value);

               /* var range0 = xAxis.createAxisRange(xAxis.makeDataItem({ category: "audience", endCategory: "audience" }));
                range0.get("axisFill").setAll({
                    visible: true,
                    fill: am5.color(0x0000ff),
                    fillOpacity: 0.1
                })*/

                var range1 = xAxis.createAxisRange(xAxis.makeDataItem({ category: "industry", endCategory: "industry" }));
                range1.get("axisFill").setAll({
                    visible: true,
                    fill: am5.color(0x415fff),
                    fillOpacity: 0.3
                })

                var range2 = xAxis.createAxisRange(xAxis.makeDataItem({ category: "subject", endCategory: "subject" }));
                range2.get("axisFill").setAll({
                    visible: true,
                    fill: am5.color(0x415fff),
                    fillOpacity: 0.3
                })
                

                chart.radarContainer.children.moveValue(chart.topGridContainer, 0);

                // Animate chart
                // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
                series.appear(1000);
                chart.appear(1000, 100);
            }

            onMounted(() => {
                renderChart()
                renderRadarChart()
            })

            onBeforeUnmount(() => {
                if (root) {
                    root.dispose()
                }
                if (rootRadar) {
                    rootRadar.dispose()
                }
            })

            return {
                chartDiv, root, exporting, chartData, chartJSON, renderChart, exportToSvg,
                rootRadar, chartRadar, renderRadarChart, initDims, radarChartData
            }
        }
    }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped  lang="scss">
    .hello {
        width: 100%;
        height: 500px;
    }

</style>