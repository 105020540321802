<template>
    <section v-if="cardDisplayType == 3" class="grid images 1 objectListTable">
        <record-image v-for="(hit, listIndex) in cardDataHits"
                      :key="hit.objectKey"
                      :obj="hit"
                      :listIndex="listIndex"
                      @detailrequest="$emit('detailrequest', $event)" />
    </section>
    <section v-else-if="cardDisplayType == 9" class="list-records-grid details-view" :class="gridClass">
        <div>&nbsp;</div>
        <table-flex :colTitles="gridHeaders">
            <record-grid-row v-for="(hit, listIndex) in cardDataHits"
                             :key="hit.objectKey"
                             :obj="hit"
                             :listIndex="listIndex"
                             @detailrequest="$emit('detailrequest', $event)" />
        </table-flex>
    </section>
    <ul v-else class="list-records details-view">
        <li v-for="(hit, listIndex) in cardDataHits" :key="hit.objectKey" :class="(listIndex == 0 ? 'first-query-item' : '') + ' record'">
            <record v-model="cardDataHits[listIndex]"
                    :parentContext="parentContext"
                    :listIndex="listIndex"
                    :cardDisplayType="cardDisplayType"
                    :recordContext="recordContext"
                    :highlightText="highlightText"
                    @detailrequest="$emit('detailrequest', $event)"
                    @editrequest="$emit('detailrequest', { 'obj': hit, 'listIndex': listIndex, 'editRequest' : $event })" />
        </li>
    </ul>
</template>

<script>
    import TableFlex from '../ui/tables/TableFlex.vue'
    import Record from '../Records/Record.vue'
    import RecordImage from '../Records/RecordImage.vue'
    import RecordGridRow from '../Records/RecordGridRow.vue'

    import { computed } from 'vue'
    import { getRecordTools } from '../../composables/RecordTools.js'



    export default {
        name: 'RecordsList',
        components: {
            TableFlex, Record, RecordImage, RecordGridRow,
        },
        props: {
            'carddata': { type: Object },
            'cardDisplayType': { type: Number },
            'highlightText': { type: Array },
            'parentContext': { type: String, default: '' },
        },
        emits: ['detailrequest', 'update:modelValue'],
        setup(props) {

            const { cardDisplayTypeRecordContextMap } = getRecordTools(props)
            const recordContext = computed(() => (cardDisplayTypeRecordContextMap[props.cardDisplayType] ?? 'table'))
            const cardDataHits = computed(() => {
                if (props.carddata && props.carddata.hits) {
                    if (props.carddata.limit)
                        return props.carddata.hits.slice(0, props.carddata.limit);
                    else
                        return props.carddata.hits
                }
                else return []
            })
            const gridHeaders = computed(() => {
                if (props.carddata && props.carddata.hits && props.carddata.hits.length > 0) {
                    if (props.carddata.hits[0].objectType == 'acquisition') return ['Date', 'Status', 'Seller', 'Type', 'Buyer', ''];
                    if (props.carddata.hits[0].objectType == 'company') return ['Name', 'Lead Type', 'Financials', 'Interest', ''];
                    if (props.carddata.hits[0].objectType == 'person') return ['Name', 'Email', 'Phone', 'Companies', ''];
                }
                return ['Name', 'Description'];
            })
            const gridClass = computed(() => {
                if (props.carddata && props.carddata.hits && props.carddata.hits.length > 0) {
                    return 'list-records-grid-' + props.carddata.hits[0].objectType;
                }
                return '';
            })

            return { cardDisplayTypeRecordContextMap, recordContext, cardDataHits, gridHeaders, gridClass }
        }
    }
</script>

<style lang="scss">
    .list-records-grid {
        .snapshot {
            box-shadow: none;
            margin: 0 !important;
            padding: 0 !important;
        }

        .ma-tag .textlabel {
            display: none;
        }

        .tag-keyword {
            background: none;
            padding: 0 !important;
            margin: 0 !important;

            a {
                font-size: 1rem !important;
            }
        }
    }
    .list-records-grid-acquisition,
    .list-records-grid-company,
    .list-records-grid-person {
        .table-head ul li:last-child {
            flex: 0 1 68px;
        }

        .table-cell:last-child {
            flex: 0 1 68px;
        }
    }
</style>
<style scoped lang="scss">
</style>