<template>
  <section class="accordion-section" :class="{ active: model }">
      <header class="accordion-section-header" @click="toggleAccordion">
          <i v-if="icon" :class="iconClass"></i>
          <h3>{{ title }}</h3>
          <span v-if="optionstitle" class="place-right margin-right-small badge-default" >{{ optionstitle }}</span>
      </header>
    <section class="accordion-section-body">
      <div class="content">
        <slot />
      </div>
    </section>
  </section>
</template>

<script>

    export default {
        props: {
            active: { type: Boolean, default: false },
            modelValue: { type: Boolean, default: false },
            icon: { type: String },
            title: String,
            optionstitle: String
        },
        emits: ['update:modelValue'],
        data: function () {
            return {
                isOpen: this.active
            }
        },
        computed: {
            model: {
                get() {
                    return this.modelValue || this.isOpen
                },
                set(value) {
                    this.isOpen = value;
                    this.$emit('update:modelValue', value)
                }
            },
            iconClass() {
                if (this.icon) {
                    return 'fas' + ' fa-' + this.icon
                } else {
                    return null
                }
            }
        },
        methods: {
            toggleAccordion: function () {
                this.model = !this.model
            },
            open() {
                this.model = true
            }
        }
    }

</script>

<style lang="scss" scoped>

</style>