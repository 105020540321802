import axios from 'axios'
import _ from 'lodash'

//import _noop from 'lodash/noop'
import { DateTime, Interval } from 'luxon'

const state = () => ({
    treeWords: {
        1: {}, 2: {}, 3: {}, 4: {}
    },
    nodeTree: {
        1: { 'root': [] }, 2: { 'root': [] }, 3: { 'root': [] }, 4: { 'root': [] }
    },
    nodeData: {},
    nodePaths: {},
    treeMap: { 'audience': 2, 'industry': 3, 'subject': 4, 'attribute': 1 },
    treeMapRev: { 1: "attribute", 2: "audience", 3: "industry", 4: "subject" },
    iconMap: { 'attribute': 'far fa-feather-alt', 'subject': 'far fa-book-open', 'industry': 'far fa-industry', 'audience': 'far fa-user-astronaut' },
    defaultLOB: {
        'attribute': 'Attributes:No Attribute', 'attributeID': 355825, 'audience': 'Audience:Consumer', 'audienceID': 350835, 'descentMappings': [],
        'important': true, 'industry': 'Industry:Holding Company', 'industryID': 354713, 'lobClassID': null, 'lobTypeID': 1, 'primaryLOB': false,
        'subject': 'Subject:No Subject', 'subjectID': 355824, 'acl': [], 'active': true, 'changeDate': null, 'changeUserID': 0, 'changeUserName': null,
        'description': 'New LOB', 'entryDate': null, 'entryUserID': 0, 'entryUserName': null, 'id': 0, 'name': null, 'objectKey': null, 'objectType': 'lob',
        'ownerID': 0, 'verified': false, 'version': 0, 'delete': false, 'dirty': true
    },
    NoIndustryID: 354713,
    NoAudienceID: 350835,
    NoAttributeID: 355825,
    NoSubjectID: 355824
})


// getters
const getters = {
    treeIDs(state) { return Object.keys(state.treeMapRev) },
    treeNames(state) { return Object.keys(state.treeMap) },
    treeWords(state) { return state.treeWords },
    nodeTree(state) { return state.nodeTree },
    node(state) { return state.nodeData },
    treeMap(state) { return state.treeMap },
    iconMap(state) { return state.iconMap },
    treeMapRev(state) { return state.treeMapRev },
    NoAttributeID(state) { return state.NoAttributeID },
    NoSubjectID(state) { return state.NoSubjectID },
    defaultLOB(state) { return state.defaultLOB }
}

// actions
const actions = {
    getTreeChildren({ commit }, opt) {
        return new Promise((resolve, reject) => {
            console.log('getTreeChildren', opt)
            axios.get('/AIS/GetNodeTree/?vuex=true&treeID=' + opt.treeID + '&id=' + opt.nodeID).then(r => r.data)
                .then(nodes => {
                    console.log('getTreeChildren', opt.treeID, opt.nodeID, nodes)
                    commit('SET_TREENODES', { treeID: opt.treeID, parentID: opt.nodeID, nodes: nodes })
                    resolve(nodes)
                }).catch(function (error) {
                    reject(error)
                })
        })
    },
    getTreeWords({ state, commit }, opt) {
        return new Promise((resolve, reject) => {
            if (state.treeWords[opt.treeID][opt.word]) {
                resolve(state.treeWords[opt.treeID][opt.word])
            }
            else {
                this.saveLocal('ais_treeWords', state.treeWords)
                axios.get('AIS/GetTreeWords/?vuex=true&id=' + opt.treeID + '&searchTerm=' + opt.word)
                    .then(r => r.data)
                    .then(words => {
                        commit('SET_TREEWORDS', { treeID: opt.treeID, word: opt.word, words: words })
                        resolve(words)
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    getNode({ state, commit }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt.id) {
                reject('bad parameters')
                return
            }
            else {
                if (state.nodeData[opt.id])
                    resolve(state.nodeData[opt.id])
                else {
                    axios.get('AIS/FetchObject/' + opt.id)
                        .then(r => r.data)
                        .then(node => {
                            if (node && node.id) {
                                commit('SET_NODE', { node: node })
                                resolve(node)
                            }
                            else
                                reject('can\'t find node id: ' + opt.id)
                        }).catch(function (error) {
                            reject(error)
                        })
                }
            }
        })
    },
    getNodePath({ state, commit }, opt) {
        return new Promise((resolve, reject) => {
            if (state.nodePaths[opt.id]) {
                var arrPath = state.nodePaths[opt.id].split('/')
                if (arrPath[0] == opt.treeID)
                    arrPath = arrPath.slice(1)
                resolve(arrPath)
            }
            else {
                axios.get('AIS/GetNodePath/' + opt.id)
                    .then(r => r.data)
                    .then(path => {
                        var pathArray = path.slice(1).map((p) => p.parentid)
                        var pathString = opt.treeID + '/' + pathArray.join('/')
                        console.log('getNodePath', path, pathArray, pathString )
                        commit('SET_NODEPATH', { nodeID: opt.id, nodePath: pathString })
                        resolve(pathArray)
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    loadNodePath({ state, dispatch }, opt) {
        return new Promise((resolve, reject) => {
            dispatch('getNodePath', { id: opt.id, treeID: opt.treeID }).then(path => {
                if (path && opt.treeID) {
                    //var treePrefix = opt.treeID + '/'
                    var heritageIDs = path// (path.startsWith(treePrefix) ? path.substr(treePrefix.length) : path)
                    dispatch('loadChild', { heritageIDs: heritageIDs, children: state.nodeTree[opt.treeID].root, treeID: opt.treeID })
                        .then((child) => resolve({ node: child, path: path }))
                        .catch(function (error) {
                            reject(error)
                        })
                }
                else
                    reject('no path')
            }).catch(function (error) {
                console.log('loadNodePath', error)
                reject(error)
            })
        })
    },
    loadChild({ dispatch }, opt) {
        return new Promise((resolve, reject) => {
            console.log('loadChild', opt)
            if (opt.children) {
                var child = opt.children.find((n) => { return n.key == opt.heritageIDs[0] })
                if (child != null) {
                    if (opt.heritageIDs.length == 1)
                        resolve(child)
                    else
                        dispatch('loadChild', { heritageIDs: opt.heritageIDs.slice(1), children: child.children, treeID: opt.treeID, parentID: opt.heritageIDs[0] })
                            .then((child) => resolve(child))
                }
                else
                    reject('cannot find child: ' + opt.heritageIDs[0])
            }
            else {
                console.log('loadChild', 'getTreeChildren', opt)
                dispatch('getTreeChildren', { nodeID: opt.parentID, treeID: opt.treeID })
                    .then((children) => {
                        var child = children.find((n) => { return n.key == opt.heritageIDs[0] })
                        if (child != null) {
                            if (opt.heritageIDs.length == 1)
                                resolve(child)
                            else
                                dispatch('loadChild', { heritageIDs: opt.heritageIDs.slice(1), children: child.children, treeID: opt.treeID, parentID: opt.heritageIDs[0]})
                                    .then((child) => resolve(child))
                        }
                        else
                            reject('cannot find child: ' + opt.heritageIDs[0])
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            }
            //reject('not found')
        })
    },
    fetchLOBSet({ commit }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.ownerID || !opt.lobTypeID) {
                reject('Bad Request Parameters')
                return
            }
            else {
                var url = 'AIS/FetchLOBSet?id=' + opt.ownerID + '&lobTypeID=' + opt.lobTypeID + (opt.addBlankWhenNone ? '&addBlankWhenNone=' + opt.addBlankWhenNone : '')
                axios.get(url)
                    .then(r => r.data)
                    .then(lobSet => {
                        commit('objectCache/SET_OBJECTPROP', { ownerKey: this.buildOwnerKey({ ownerID: lobSet.id, ownerTypeID: lobSet.lobTypeID }), key: 'lobs', value: lobSet.lobs }, { root: true })
                        resolve(lobSet)
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    saveLOBs({ commit }, lobs) {
        return new Promise((resolve, reject) => {
            if (!lobs) {
                reject('No lobs in set')
                return
            }
            var lobSet = { id: lobs[0].ownerID, lobTypeID: lobs[0].lobTypeID, lobs: lobs, changed: false, descentMappings: null }
            axios.post('ais/UpdateLOBs', lobSet)
                .then(r => r.data)
                .then(results => {
                    if (results.success) {
                        var lobResults = JSON.parse(results.jsonResult)
                        lobResults.lobs = _.filter(lobResults.lobs, function (l) { return !l.delete });
                        results.lobs = lobResults.lobs;
                        commit('objectCache/SET_OBJECTPROP', { ownerKey: this.buildOwnerKey({ ownerID: lobResults.id, ownerTypeID: lobResults.lobTypeID }), key: 'lobs', value: lobResults.lobs }, { root: true })
                        resolve(results)
                    }
                    else
                        reject(results)
                }).catch(function (error) {
                    reject(error)
                })
        })
    }
}

// mutations
const mutations = {
    INITIALIZE_STORE(state) {
        state.treeWords = this.fetchLocal('ais_treeWords', { 1: {}, 2: {}, 3: {}, 4: {} })
        state.nodeTree = this.fetchLocal('ais_nodeTree', { 1: { 'root': [] }, 2: { 'root': [] }, 3: { 'root': [] }, 4: { 'root': [] } })
        state.nodeData = this.fetchLocal('ais_nodeData')
        state.nodePaths = this.fetchLocal('ais_nodePaths')
        //console.log('fetching ais state from localstorage', state.users, state.userLayout)
    },
    DESTROY_STORE(state) {
        this.saveLocal('ais_treeWords', null)
        this.saveLocal('ais_nodeTree', null)
        this.saveLocal('ais_nodeData', null)
        this.saveLocal('ais_nodePaths', null)
        state.treeWords = { 1: {}, 2: {}, 3: {}, 4: {} }
        state.nodeTree = { 1: { 'root': [] }, 2: { 'root': [] }, 3: { 'root': [] }, 4: { 'root': [] } }
        state.nodeData = {}
        state.nodePaths = { }
        //console.log('destroying ais state in localstorage')
    },
    SET_TREEWORDS(state, opt) {
        state.treeWords[opt.treeID][opt.word] = opt.words
        this.saveLocal('ais_treeWords', state.treeWords)
    },
    SET_NODE(state, opt) {
        state.nodeData[opt.node.id] = Object.freeze(opt.node)
        this.saveLocal('ais_nodeData', state.nodeData)
    },
    SET_NODES(state, opt) {
        opt.nodes.forEach((node) => {
            state.nodeData[node.id] = Object.freeze(node)
        })
        this.saveLocal('ais_nodeData', state.nodeData)
    },
    SET_TREENODES(state, opt) {
        if (state.treeMapRev[opt.treeID]) {
            console.log('SET_TREENODES start', opt.treeID, opt)
            const start = DateTime.now();
            var nodePaths = _.cloneDeep(state.nodePaths)
            var nodeTree = _.cloneDeep(state.nodeTree[opt.treeID])

            if (opt.parentID == 'root') {
                var rootNodes = []
                var existingKeys = nodeTree.root.map((n) => n.key)
                opt.nodes.forEach((node) => {
                    if (!existingKeys.includes(node.key)) {
                        nodePaths[node.key] = opt.treeID + '/' + node.key
                        delete node.data

                        if (node.leaf)
                            node = Object.freeze(node)
                        rootNodes.push(node)
                    }
                })
                nodeTree.root = nodeTree.root.concat(rootNodes)
            }
            else {
                var parentPath = nodePaths[opt.parentID]
                console.log('SET_TREENODES', parentPath, opt.parentID)
                var parentPathIDs = parentPath.split('/').map((n) => parseInt(n))
                var firstParent = getChild(parentPathIDs.slice(1), nodeTree.root)
                var nodes = []
                opt.nodes.forEach((node) => {
                    nodePaths[node.key] = parentPath + '/' + node.key
                    delete node.data

                    if (node.leaf)
                        node = Object.freeze(node)
                    nodes.push(node)
                })
                if (!firstParent.children)
                    firstParent.children = nodes
                else
                    firstParent.children = firstParent.children.concat(nodes)
            }
            state.nodeTree[opt.treeID] = nodeTree
            this.saveLocal('ais_nodeTree', state.nodeTree)
            const now = DateTime.now()
            
            console.log('SET_TREENODES end', Interval.fromDateTimes(start, now).length('seconds'))
            state.nodePaths = nodePaths
            this.saveLocal('ais_nodePaths', state.nodePaths)
           // commit('SET_NODES', { nodes: nodeData })
            const later = DateTime.now()
            console.log('SET_TREENODES commit NodePaths', Interval.fromDateTimes(now, later).length('seconds'))

        }
       
        function getChild(heritageIDs, children) {
            var child = children.find((n) => { return n.key == heritageIDs[0] })
            if (child != null) {
                if (heritageIDs.length == 1)
                    return child
                else
                    return getChild(heritageIDs.slice(1), child.children)
            }
            return null
        }

        /*function setNodeData(nodes) {
            nodes.forEach((node) => {
                state.nodeData[node.key] = Object.freeze(node.data)
            })
        }*/
    },
    SET_NODEPATH(state, opt) {
        state.nodePaths[opt.nodeID] = opt.nodePath
        this.saveLocal('ais_nodePaths', state.nodePaths)
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
