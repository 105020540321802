<template>
    <lob-combo-picker v-model="filterVal" :multiple="true" :inpopup="true" />
</template>

<script>
    import { computed, onMounted, onBeforeUnmount, inject, nextTick } from 'vue'
    import { useStore } from 'vuex'
    import { searchFilterProps, searchFilterEmits, searchFilterSetup } from '../../composables/SearchTools.js'
    import { getLobTools } from '../../composables/LobTools.js'
    import { useToast } from '../../composables/ModelWrapper.js'

    import LobComboPicker from '../ais/LobComboPicker.vue'

    export default {
        name: 'LobComboFilter',
        components: { LobComboPicker },
        props: {
            ...searchFilterProps('lobCombo'),
        },
        emits: [...searchFilterEmits()],
        setup(props, { emit }) {
            // tools
            const store = useStore()
            const toast = useToast()
            const emitter = inject("emitter")   // Inject event bus
            const { defaultLobFilterVal, treeMapRev, addNodeIDToFilter } = getLobTools()

            // models
            const { model } = searchFilterSetup(props, emit)

            // computed
            const treeNames = computed(() => store.getters['ais/treeNames'])
            const filterVal = computed({
                get: () => {
                    var val = props.modelValue && props.modelValue.value ? JSON.parse(props.modelValue.value) : defaultLobFilterVal
                    treeNames.value.forEach((dim) => {
                        val[dim] = val[dim].map((node) => (node.name ? node : { id: node.id, name: node.entry }))
                    })
                    return val
                },
                set: (newVal) => {
                    var nodeCount = 0
                    if (newVal) {
                        treeNames.value.forEach((dim) => {
                            newVal[dim] = newVal[dim].map((node) => ({ id: node.id, entry: node.name }))
                            nodeCount += newVal[dim].length
                        })
                    }
                    var newModel = model.value
                    newModel.value = JSON.stringify(newVal)
                    newModel.valueName = getValueName(newVal)
                    newModel.hasValue = nodeCount > 0
                    model.value = newModel
                    console.log('setting Value', newVal, model.value.value)
                }
            })            
            const getValueName = (newVal) => {
                var name = ''
                if (newVal) {
                    var names = []
                    treeNames.value.forEach((dim) => {
                        if (newVal[dim])
                            names.push(newVal[dim].map((n) => { return n.name }).join(","))
                    })
                    name = names.join(' | ')
                }
                return name
            }

            const addNodeID = (opt) => {
                if (opt && opt.nodeID) {
                    console.log('addNodeID', opt)
                    addNodeIDToFilter({ nodeID: opt.nodeID, filterVal: filterVal.value }).then((newFilterVal) => {
                        filterVal.value = newFilterVal
                        nextTick(() => {
                            emitter.emit('runSearch', { 'sender': model.value.key })
                        })
                    }).catch(error => {
                        toast.add({ severity: 'error', summary: 'Error Adding NodeID to Filter', detail: error, life: 0 })
                    })
                }
            }

            const applyFilter = (req) => {
                console.log('applyFilter-lobcombofilter', req)
                if (req.filter == 'nodeID') {
                    addNodeID({ nodeID: req.filterVal })
                }
            }

            onMounted(() => {
                emitter.on("applyFilter", applyFilter)
            })
            onBeforeUnmount(() => {
                emitter.off("applyFilter", applyFilter)
            })

            return {
                // model
                model, defaultLobFilterVal, filterVal,
                // computed
                treeNames, treeMapRev,
                // methods
                getValueName, addNodeID, applyFilter, addNodeIDToFilter
            }

        }

    }
</script>

<style scoped lang="scss">
    
</style>
