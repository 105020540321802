<template>
    <span :class="tagClasses" :title="toolTip" :style="tagStyle">
        <span class="icon" v-if="icon || type == 'hashtag' || type== 'at'">
            <i :class="iconClass"></i>
        </span>
        <span class="textlabel" @click="handleLabelClick">
            <template v-if="href">
                <a :href="href" :target="target">
                    <slot>
                        <span v-if="label" class="desc">{{ label }}: </span>
                        <span>{{ clippedName }}</span>
                    </slot>
                </a>
            </template>
            <template v-else>
                <span v-if="label" class="desc">{{ label }}: </span>
                <span>{{ name }}</span>
            </template>
            <span class="count" v-if="count">
                ({{count}})
            </span>
        </span>
        <span class="action" v-if="actions">
            <span v-if="actions.includes('edit') && useEditButton" @click="$emit('button-clicked', 'edit')"><i class="far fa-edit"></i></span>
            <span v-if="actions.includes('switch')" class="switch-filter" @click="$emit('button-clicked', 'switch')"><i class="far fa-random"></i></span>
            <span v-if="actions.includes('remove')" @click="$emit('button-clicked', 'remove')"><i class="far fa-times"></i></span>
            <span v-if="actions.includes('add')" @click="$emit('button-clicked', 'add')"><i class="far fa-plus"></i></span>
        </span>
    </span>
</template>

<script>
    import { computed } from 'vue'

    export default {
        name: 'TagBasic',
        props: {
            name: String,
            label: String,
            type: { type: String, default: 'default' },
            title: { type: String },
            actions: { type: Array },
            href: { type: String },
            target: { type: String, default: '_blank' },
            icon: { type: String, default: null },
            iconSolid: { type: Boolean, default: true },
            disableDefaultAction: { type: Boolean, default: false },
            count: { type: String, default: null },
            useEditButton: { type: Boolean, default: false },
            useFontAwesome: { type: Boolean, default: true },
            fontSize: { type: String, default: null },
            maxLength: {type: Number, default: 50 }
        },
        emits: ['button-clicked'],
        setup(props, { emit, attrs }) {

            // computed
            const clippedName = computed(() => !props.name || props.name.length <= props.maxLength || props.maxLength == 0
                ? props.name : props.name.substr(0, props.maxLength - 2) + '..')
            const toolTip = computed(() => props.title ? props.title : props.name)
            const tagClasses = computed(() => {
                var arrClasses = []
                if (attrs.class)
                    arrClasses.push(attrs.class)

                arrClasses.push('tag-' + (props.type === 'default' ? 'default' : props.type))
                if (props.href)
                    arrClasses.push('is-clickable')
                else
                    arrClasses.push('is-selectable')

                if (props.actions && props.actions.includes('edit'))
                    arrClasses.push('has-action')

                return arrClasses
            })
            const tagStyle = computed(() => props.fontSize ? "{'font-size': " + props.fontSize+ " }": null)
            const iconClass = computed(() => {
                var glyph = '';

                if (props.type === 'hashtag') {
                    glyph = 'hashtag';
                } else if (props.type === 'at') {
                    glyph = 'at';
                } else {
                    glyph = props.icon
                }

                return (props.useFontAwesome ? (props.iconSolid ? 'fas' : 'far') + ' fa-' : '') + glyph
            })
            const defaultAction = computed(() => props.actions ? props.actions[0] : null)

            // methods
            const handleLabelClick = () => {
                if (!props.disableDefaultAction && defaultAction.value)
                    emit('button-clicked', defaultAction.value)
            }

            return {
                // computed
                clippedName, toolTip, tagClasses, iconClass, defaultAction, tagStyle,
                // methods
                handleLabelClick
            }
        }
    }
</script>

<style scoped lang="scss">
    .is-selectable {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
</style>