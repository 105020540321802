<template>
    <span :class="tagClasses" :title="title">
        <span class="icon" v-if="icon || type == 'hashtag' || type== 'at'">
            <i :class="iconClass"></i>
        </span>
        <span class="textlabel">
            <template v-if="href">
                <a :href="href">
                    <slot />
                </a>
            </template>
            <template v-else>
                <slot />
            </template>
            <span class="count" v-if="count">
                ({{count}})
            </span>
        </span>
        <span class="action" v-if="isRemovable || action">
            <template v-if="isRemovable">
                <span class="remove" @click="$emit('remove', $event)"><i class="fas fa-times"></i></span>
            </template>
            <template v-if="action == 'add'">
                <span class="add-filter" @click="$emit('add', $event)"><i class="far fa-plus"></i></span>
            </template>
            <template v-if="action == 'remove'">
                <span class="remove-filter" @click="$emit('remove', $event)"><i class="far fa-times"></i></span>
            </template>
            <template v-if="action == 'switch'">
                <span class="switch-filter" @click="$emit('switch', $event)"><i class="far fa-random"></i></span>
            </template>
        </span>
    </span>
</template>

<script>
    import { computed } from 'vue'

    export default {
        name: 'Tag',
        props: {
            name: String,
            type: { type: String, default: 'default' },
            title: { type: String },
            action: { type: String },
            href: { type: String },
            isRemovable: { type: Boolean, default: false },
            icon: { type: String, default: null },
            iconSolid: { type: Boolean, default: true },
            count: { type: String, default: null },
            useFontAwesome: { type: Boolean, default: true }
        },
        emits: ['add', 'remove', 'switch'],
        setup(props) {
            const actions = ['add', 'remove', 'switch', 'custom']

            const tagClasses = computed(() => {
                var arrClasses = []

                arrClasses.push('tag-' + (props.type === 'default' ? 'default' : props.type))
                if (props.href)
                    arrClasses.push('is-clickable')
                if (actions.includes(props.action))
                    arrClasses.push('has-action')

                return arrClasses
            })

            const iconClass = computed(() => {
                var glyph = '';

                if (props.type === 'hashtag') {
                    glyph = 'hashtag';
                } else if (props.type === 'at') {
                    glyph = 'at';
                } else {
                    glyph = props.icon
                }

                return (props.useFontAwesome ? (props.iconSolid ? 'fas' : 'far') + ' fa-' : '') + glyph
            })

            return { actions, tagClasses, iconClass }
        }
    }
</script>

<style scoped lang="scss">

</style>