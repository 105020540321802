import { useStore } from 'vuex'
import { ref, computed, inject } from 'vue'
import { getID } from './Editors.js'
//import { useModelWrapper } from './ModelWrapper.js'

import _ from 'lodash'

export function getSearchTools() {

    const store = useStore()
   
    // computed
    const baseMaps = computed(() =>store.state.search.searchMaps)
    const searchMaps = computed(() =>store.state.search.searchMaps.maps)
    const user = computed(() => store.state.userInfo.user.userInfo)

    const panelDefs = computed(() => store.getters['search/panelDefs'])
    const filterDefs = computed(() => store.getters['search/filterDefs'])
    const aggPanels = computed(() => store.getters["search/aggPanels"])
    const aggPanelDefs = computed(() => store.getters["search/aggPanelDefs"])
    const aggDefs = computed(() => store.getters["search/aggDefs"])
    const graphAggs = computed(() => store.getters["search/graphAggs"])
    const significantTermAggs = computed(() => store.getters["search/significantTermAggs"])

    // computed
    const objectIDToObjectTypeMap = computed(() => {
        return searchMaps.value && searchMaps.value.ownerTypeMap ? searchMaps.value.ownerTypeMap : {}
    })
    const objectTypeToObjectIDMap = computed(() => {
        return searchMaps.value && searchMaps.value.ownerTypeRevMap ? searchMaps.value.ownerTypeRevMap : {}
    })
    const userMap = computed(() => {
        return searchMaps.value && searchMaps.value.users ? searchMaps.value.users : {}
    })

    // methods
    const objectURL = (opt) => {
        //console.log('objectURL', opt)
        if (opt.id && (opt.objectType || opt.objectTypeID)) {
            var objectType = (!opt.objectType && opt.objectTypeID && objectIDToObjectTypeMap.value[opt.objectTypeID]) ? objectIDToObjectTypeMap.value[opt.objectTypeID] : opt.objectType
            return '/' + objectType + '/' + (opt.page ?? 'details') + '/' + opt.id
        }
        return null
    }
    const updateUserName = (usr) => {
        if (usr && usr.id) {
            usr.name = userMap.value && userMap.value[usr.id] ? userMap.value[usr.id] : usr.name
        }
        return usr
    }
    const getUserName = (usr) => {
        if (usr && usr.id) {
            return userMap.value && userMap.value[usr.id] ? userMap.value[usr.id] : usr.name
        }
        return usr ? usr.name : null
    }
    const encodeOwnerKey = (ownerKey) => {
        return ownerKey ? ownerKey.replace('::', "__") : ownerKey
    }
    const decodeOwnerKey = (ownerKey) => {
        return ownerKey.replace("__", '::')
    }

    return {
        // store state
        user, baseMaps, searchMaps,
        // store getters
        panelDefs, filterDefs, aggPanels, aggPanelDefs, aggDefs, graphAggs, significantTermAggs,
        // computed
        objectIDToObjectTypeMap, objectTypeToObjectIDMap, userMap,
        // methods
        objectURL, updateUserName, getUserName, encodeOwnerKey, decodeOwnerKey
    }

}

export const searchFilterProps = (filterKeyDefault, multipleDefault) => ({
    modelValue: { type: Object },
    filterKey: { type: String, default: filterKeyDefault },
    advanced: { type: Boolean, default: false },
    inpopup: { type: Boolean, default: false },
    multiple: { type: Boolean, default: multipleDefault ?? true }
})

export const searchFilterEmits = () => (['update:modelValue', 'applyfilter'])

const filterTemplate = { key: null, filterName: null, name: null, value: null, valueName: null, url: null, isNotFilter: false, new: true, hasValue: false, isEditing: false }
export const  defaultFilterTemplate = () => (filterTemplate)

export function searchFilterSetup(props, emit) {
    // tools
    const store = useStore()
   
    // models
    const initialFilterState = computed(() => {
        return { key: props.filterKey, filterName: props.filterKey, name: null, value: null, valueName: null, url: null, isNotFilter: false, new: isNew.value, hasValue: false, isEditing: false }
    })

    const model = computed({
        // this forces the model to always have a value at all times and why we don't use modelWrapper
        get: () => props.modelValue ?? initialFilterState.value,
        set: (value) => {
            //console.log('update Filter Model Wrapper', value)
            emit('update:modelValue', value)
        }
    })

    // data
    const settingValue = ref(false)
    const id = ref(getID(props.filterKey))

    //computed 
    const counts = inject('counts')
    const originalCriteriaKeys = inject('originalCriteriaKeys')
    const adHocMaps = inject('adHocMaps')
    const searchMaps = computed(() => store.state.search.searchMaps.maps)
    const isNew = computed(() => {
        //console.log('isNew', originalCriteriaKeys, props.filterKey)
        return !(originalCriteriaKeys && originalCriteriaKeys.value && originalCriteriaKeys.value.includes(props.filterKey))
    })
    const filterDefs = computed(() => store.getters['search/filterDefs'])
    const filterDef = computed(() => props.filterKey && filterDefs.value ? filterDefs.value[props.filterKey] : null)
    const filterType = computed(() => filterDef.value ? filterDef.value.filterType : null)
    const aggregate = computed(() => filterDef.value ? filterDef.value.aggregate : null)
    const aggregateCounts = computed(() => aggregate.value && counts && counts.value ? counts.value[aggregate.value] : null)
    const canAutoApply = computed(() => filterDef.value ? filterDef.value.autoApply : null)
    const alwaysEditable = computed(() => filterDef.value ? filterDef.value.alwaysEditable : false)
    const filterMap = computed(() => {
        var filterMap = {}
        if (filterDef.value) {
            if (filterDef.value.map)
                filterMap = filterDef.value.map
            else if (searchMaps.value[filterDef.value.mapName])
                filterMap = searchMaps.value[filterDef.value.mapName]
            else if (adHocMaps && adHocMaps.value[filterDef.value.mapName])
                filterMap = adHocMaps.value[filterDef.value.mapName]
            else if (aggregateCounts.value) {
                _.each(aggregateCounts.value, function (count, key) { filterMap[key] = key })
            }
        }
        //console.log('filterMap', filterMap)
        return filterMap
    })
    const selectedValue = computed(() => {
        _.noop(model.value) // trying to force reactivity
        //console.log('selectedValue', model.value ? model.value.value : null, props.modelValue ? props.modelValue.value : null)
        if (props.modelValue && props.modelValue.value) {
            if (_.isArray(props.modelValue.value))
                return props.modelValue.value
            if (props.modelValue.value.indexOf(']') > 0 || props.modelValue.value.indexOf('}') > 0)
                return JSON.parse(props.modelValue.value.toString())
            return [props.modelValue.value]
        }
        return null
    })
    const filterList = computed({
        // getter
        get: () => {
            _.noop(model.value, props.modelValue, selectedValue.value) // trying to force reactivity
            //console.log('filterList-getter', aggregateCounts.value, filterMap.value)
            if (aggregateCounts.value && filterMap.value) {
                var list = Object.keys(aggregateCounts.value).map((key) => {
                    var isSelected = false 
                    if (selectedValue.value) {
                        //console.log('matches', value, selValue)
                        if (_.isArray(selectedValue.value))
                            isSelected = selectedValue.value.includes(key)
                        else
                            isSelected = (selectedValue.value == key)
                    }
                    return {
                        id: key,
                        entry: filterMap.value[key],
                        count: aggregateCounts.value[key],
                        selected: isSelected, //matchesSelectedValue(key, selectedValue.value),
                        label: filterMap.value[key] + (!filterDef.value.hideCounts ? ' (' + aggregateCounts.value[key] + ')' : '')
                    }
                })
                var filteredList = list.filter((item) => { return item.entry })
                var sortedList = _.sortBy(filteredList, function (item) { (item.id == -1 ? -10000000000 : item.count * -1) })
                return sortedList
            }
            return []
        },
        // setter
        set: (newValue) => {
            //console.log('filterList-setter', newValue)
            processListSelection(newValue)
        }
    })

    // methods
    const matchesSelectedValue = (value, selValue) => {
        // must pass selectedValue to make this reactive
        if (selValue) {
            //console.log('matches', value, selValue)
            if (_.isArray(selValue))
                return selValue.includes(value)
            else
                return (selValue == value)
        }
        return false
    }
    const autoApply = () => {
        if (canAutoApply.value && model.value && model.value.value) {
            console.log('autoApply', model.value, filterDef.value)
            emit('applyfilter', model.value)
        }
    }
    const apply = () => {
        if (model.value && model.value.value) {
            console.log('applyfilter', model.value)
            emit('applyfilter', model.value)
        }
    }
    const processListSelection = (newValue) => {
        if (model.value) {
            //console.log('processListSelection', model.value, newValue)
            settingValue.value = true
            var selected = newValue.filter((item) => { return item.selected })
            var ids = selected.map((item) => item.id)
            var names = selected.map((item) => item.entry)
            var newModel = model.value
            newModel.value = JSON.stringify(ids)
            newModel.valueName = names.join(", ")
            newModel.hasValue = ids.length > 0
            model.value = newModel
            emit('update:modelValue', model.value)
            autoApply()
            settingValue.value = false
        }
    }
    const setModelProp = (name, val) => {
        //console.log('setModelProp', name, val)
        var newModel = model.value
        newModel[name] = val
        model.value = newModel
    }

    return {
        // models
        model,
        // data
        settingValue, id,
        // computed
        counts, adHocMaps, originalCriteriaKeys, initialFilterState, isNew,
        searchMaps, filterDefs, filterDef, filterType,
        aggregate, aggregateCounts, canAutoApply, filterMap, selectedValue, filterList, alwaysEditable,
        // methods
        matchesSelectedValue, autoApply, apply, processListSelection, setModelProp
    }

}

export function getSearchTagTools() {
    const store = useStore()

    const noLabelFilters = ['docType','doctype', 'fulltext']
    const actionMap = { 'add': 'addfilter', 'switch': 'addfilter', 'remove': 'deletefilter', 'edit': 'editfilter' }

    const dateRangeNames = computed(() => store.getters["search/dateRangeNames"])

    const filterToTag = (crit, actions, key) => {
        var critTags = []

        var name = crit.name ? crit.name : crit.key;
        var valueName = crit.valueName ? crit.valueName : (crit.value && !crit.value.startsWith('{') ? crit.value : "");
        var valueGroups = valueName ? valueName.split('|').map((vg) => vg.trim()) : []

        valueGroups.forEach((vg) => {
            if (vg.indexOf(',') < 0)
                critTags.push(vg)
            else
                critTags = critTags.concat(vg.split(',').map((vgs) => vgs.trim()))
        })
        var relatedTags = []
        if (crit.related) {
            crit.related.forEach((rel) => {
                var relTag = filterToTag(rel)
                if (relTag != null)
                    relatedTags = relatedTags.push(relTag)
            })
        }

        if (critTags.length == 0)
            return null
        else if (critTags.length == 1 && relatedTags.length == 0)
            return {
                name: critTags[0],
                key: key != undefined ? key : crit.key,
                type: 'filter',
                class: crit.key == 'docType' ? 'switch' : null,
                count: crit.appliedCount,
                title: crit.popularity ? 'Used ' + crit.popularity + ' times' : '',
                actions: actions,
                label: !noLabelFilters.includes(crit.key) ? name : null,
                icon: tagIcon(critTags[0])
            }
        else {
            var groupedTag = {
                name: name,
                key: key != undefined ? key : crit.key,
                type: 'filter',
                count: crit.appliedCount,
                title: crit.popularity ? 'Used ' + crit.popularity + ' times' : '',
                actions: actions,
                label: !noLabelFilters.includes(crit.key) ? name : null,
                subTags: critTags.map((ct) => { return { name: ct, icon: tagIcon(ct), key: crit.key } })
            }
            if (relatedTags.length > 0) {
                relatedTags.forEach((relTag) => {
                    groupedTag.subTags.push(relTag)
                })
            }
            return groupedTag
        }
    }
    const tagIcon = (tagName) => {
        if (dateRangeNames.value.includes(tagName))
            return "calendar-alt"
        return null
    }

    return { actionMap, noLabelFilters, dateRangeNames, filterToTag, tagIcon }
}

export function searchAggregateSetup(props, emitter) {
    const queryID = computed(() => props.carddata && props.carddata.id ? props.carddata.id : (props.carddata && props.carddata.query ? props.carddata.query.id : null))
    const query = computed(() => props.carddata ? props.carddata.query : null)
    const docType = computed(() => props.carddata && props.carddata.query && props.carddata.query.criteria && props.carddata.query.criteria.docType ? props.carddata.query.criteria.docType.value : null)

    const applyFilter = (opt) => {
        console.log('applyFilter-aggregate', opt, props.carddata, docType.value)
        if (opt && opt.filter && opt.filterVal) {
            emitter.emit('applyFilter', { filter: opt.filter, filterVal: opt.filterVal, docType: docType.value })
        }
    }

    return { queryID, query, docType, applyFilter }

}




