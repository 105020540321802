import { ref, computed, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue'

export function useMq() {
    const internalInstance = getCurrentInstance()
    const mq = internalInstance.appContext.config.globalProperties.$mq
    return mq
}
export function getMqTools(screenBreakPoint = 'smallscreen') {
 
    // data
    const windowSize = ref({ width: 0, height: 0 })
    // const
    const smallscreens = ['smallscreen', 'mobile', 'min']
    const mqBreakpoints = { min: 375, mobile: 660, smallscreen: 960, tablet: 1024, laptop: 1200, desktop: 1440, xlarge: Infinity }

    // computed
    const isMobile = computed(() => windowSize.value.width <= mqBreakpoints.smallscreen)
    const screenSizes = computed(() => Object.keys(mqBreakpoints))
    const mqBreakpointArray = computed(() => {
        var bpArray = []
        screenSizes.value.forEach((ss) => {
            if (!isNaN(screenSizes[ss]))
                bpArray.push({ size: screenSizes[ss], screen: ss })
        })
        bpArray.sort((a, b) => { return a.size - b.size })
        return bpArray
    })
    const thisScreenOrSmallerComputed = computed(()  => {
        if (screenSizes.value.includes(screenBreakPoint)) {
            return windowSize.value.width <= mqBreakpoints[screenBreakPoint]
        }
        return false
    })

    const thisScreenOrLargerComputed = computed(() => {
        if (screenSizes.value.includes(screenBreakPoint)) {
            return windowSize.value.width >= mqBreakpoints[screenBreakPoint]
        }
        return false
    })
    // methods   
    const handleResize = () => {
        windowSize.value.width = window.innerWidth
        windowSize.value.height = window.innerHeight
    }

    onMounted(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
    })

    onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize)
    })

    return {
        // data
        windowSize,
        // const
        smallscreens, mqBreakpoints,
        // computed
        mqBreakpointArray, isMobile, screenSizes, thisScreenOrSmallerComputed, thisScreenOrLargerComputed,
        // methods
        handleResize
    }
}
