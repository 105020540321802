<template>
    <template v-for="year in buttonYearsToAdd" :key="year">
        <btn el="button" :title="'Add year'" @click="addYear(year.id)" type="primary"><i class="far fa-plus-circle"></i> Add {{ year.label }} ({{ year.id }}) </btn>
    </template>
    <dropdown type="kebab" v-model="dropDownState">
        <template #body>
            <menu-vertical options="panel options medium">
                <menu-item label="Financial Actions" :initIsOpen="true">
                    <template #children>
                        <template v-for="year in dropDownYearsToAdd" :key="year">
                            <menu-item :label="'Add ' + year.label + ' (' + year.id + ')'" icon="plus-circle" @click.prevent="addYear(year.id)" />
                        </template>
                        <template v-if="isPublic">
                            <menu-item label="Show Stock History" icon="tape" @click.prevent="showStockHistory" />
                            <menu-item v-show="yahooLink" label="Goto Yahoo Financials" icon="yahoo" iconType="fab" @click.prevent="openYahooLink"/>
                        </template>
                    </template>
                </menu-item>
            </menu-vertical>
        </template>
</dropdown>

</template>

<script>
    import MenuVertical from "../ui/menus/MenuVertical.vue"
    import MenuItem from "../ui/menus/MenuItem.vue"
    import { DateTime } from 'luxon'

    export default {
        name: 'FinancialActions',
        components: {
            MenuVertical, MenuItem
        },
        emits: ['showstockhistory', 'addyear'],
        props: {
            finYears: { type: Array }
        },
        data: function () {
            return {
                dropDownState: 'closed'
            }
        },
        computed: {
            isPublic() {
                return this.finYears.length ? this.finYears[0].isPublic : false
            },
            tickerSymbol() {
                return this.finYears.length && this.finYears[0].isPublic ? this.finYears[0].tickerSymbol : null
            },
            yahooLink() {
                return this.tickerSymbol ? 'https://finance.yahoo.com/quote/' + this.tickerSymbol + '/financials' : null
            },
            yearsInOrder() {
                return this.finYears.length ? this.finYears.map((fin) => { return fin.id }) : []
            },
            thisYear() {
                return DateTime.now().year
            },
            yearsToAdd() {
                var yearsToTest;
                if (DateTime.now().month < 6)
                    yearsToTest = [{ id: this.thisYear - 1, label: 'Last Year' }, { id: this.thisYear, label: 'This Year' }, { id: this.thisYear + 1, label: 'Next Year' }];
                else
                    yearsToTest = [{ id: this.thisYear, label: 'This Year' }, { id: this.thisYear + 1, label: 'Next Year' }, { id: this.thisYear - 1, label: 'Last Year' }];

                var years = yearsToTest.filter((yr) => { return !this.yearsInOrder.includes(yr.id) })


                // check for missing years from oldest Year
                var yearIDs = years.map((y) => y.id)
                var oldestYear = this.yearsInOrder[this.yearsInOrder.length - 1]
                if (oldestYear < this.thisYear - 2) {
                    for (var yr = this.thisYear - 2; yr > oldestYear; yr--) {
                        if (!this.yearsInOrder.includes(yr) && !yearIDs.includes(yr))
                            years.push({ id: yr, label: 'missing' })
                    }
                }

                // add some extra years 
                if (this.yearsInOrder.length) {
                    yearIDs = years.map((y) => y.id)
                    //extra projected
                    var projYear = this.yearsInOrder[0] + 1;
                    if (!yearIDs.includes(projYear)) years.push({ id: projYear, label: 'Projected' });

                    //extra historical
                    for (var hYear = oldestYear - 1; hYear > oldestYear - 5; hYear--) {
                        if (!yearIDs.includes(hYear)) years.push({ id: hYear, label: 'Historical' });
                    }
                }

                return years
            },
            buttonYearsToAdd() {
                return this.yearsToAdd && this.yearsToAdd.length > 0 ? [this.yearsToAdd[0]] : []
            },
            dropDownYearsToAdd() {
                return this.yearsToAdd && this.yearsToAdd.length > 1 ? this.yearsToAdd.slice(1,4) : []
            }
        },
        methods: {
            openYahooLink() {
                this.dropDownState = 'closed'
                window.open(this.yahooLink)
            },
            addYear(year) {
                this.$emit('addyear', { 'year': year, 'openIt': true })
            },
            showStockHistory() {
                this.dropDownState = 'closed'
                this.$emit('showstockhistory')
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
