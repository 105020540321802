<template>       
    <div :class="checkboxClasses">
        <!--for text input: <input type="text" :id="realID" :name="realID" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"> -->
        <template v-if="labelInFront">
            <label :for="realID" @click="onClick">
                <template v-if="label">
                    {{ label }}
                </template>
            </label>
        </template>
        <label v-if="this.showAsSwitch" @click="onClick">{{ label }}</label>
        <input type="checkbox" 
               :id="realID" 
               :name="realID" 
               :checked="modelValue" 
               @change="$emit('update:modelValue', $event.target.checked)" 
               @click="onClick"
               :disabled="disabled"
               ref="theCheckbox">
        <template v-if="!labelInFront">
            <label :for="realID" @click="onClick">
                <template v-if="label">
                    {{ (!this.showAsSwitch ? label : '') }}
                </template> 
            </label>
        </template>
    </div>
</template>

<script>

    import RandomID from '../../../mixins/randomID.js'

    export default {
        name: 'Checkbox',
        emits: ['update:modelValue', 'focus'],
        mixins: [ RandomID ],
        props: {
            label: String,
            size: { type: String, default: 'default' },
            modelValue: Boolean,
            labelInFront: { type: Boolean, default: false },
            showAsSwitch: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
        },
        computed: {
            checkboxClasses() {
                return (!this.showAsSwitch ? 'checkbox ' + (this.size !== 'default' ? this.size : '')
                    : 'input-switch place-right')
            }
        },
        methods: {
            setCheckBox(newVal) {
                console.log('setCheckBox', newVal, this.modelValue)
                if (newVal != this.modelValue) {
                    this.$refs.theCheckbox.click()
                }
            },
            onClick(ev) {
                this.$emit('focus', ev, this.modelValue)
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>