import axios from 'axios'

const state = () => ({
    acqLog: {},
    articleLog: {}
})

// getters
const getters = {
    acqLog(state) { return state.acqLog },
    articleLog(state) { return state.articleLog }
}

// actions
const actions = {
    fetchAcquisitions({ commit }, opt) {
        return new Promise((resolve, reject) => {
            //console.log('fetch acquisitions', opt)
            if (!(opt && (opt.ownerKey || (opt.obj && opt.obj.id)) && opt.obj.objectType == 'company')) {
                reject({ fatal: false, message: 'Bad acq fetch Options' })
                return
            }

            if (!opt.ownerKey)
                opt.ownerKey = opt.obj.objectKey

            var acqLogState = { 'total': 0, 'events': [], 'ownerKey': opt.ownerKey, 'preparedEvents': [], 'groupedEvents': [], 'sessionEvents': [], 'fetched': false, 'exTime': 0.0 }
            var url = "Acquisition/ByCompany/?id=" + opt.obj.id + (opt.limit ? "&limit=" + opt.limit : "")
            axios.get(url)
                .then(r => r.data)
                .then(results => {
                    if (results) {
                        acqLogState.fetched = true
                        acqLogState.total = results.length
                        //acqLogState.exTime = results.resultsSet.exTimes ? results.resultsSet.exTimes.total : -1
                        acqLogState.events = results
                        acqLogState.preparedEvents = this.prepareEvents({
                            'events': acqLogState.events, 'eventSource': 'acquisitions', 'fetchedEvents': true, 'obj': opt.obj
                        })
                        acqLogState.groupedEvents = acqLogState.preparedEvents
                        commit('SET_ACQLOG', acqLogState)
                        resolve({ 'fetched': true, 'total': acqLogState.total })
                    }
                    else {
                        resolve({ 'fetched': true, 'total': 0 })
                       // reject({ fatal: true, message: "events not found id: " + opt.ownerKey })
                    }

                })
        })
    },
    fetchArticles({ commit }, opt) {
        return new Promise((resolve, reject) => {
            console.log('fetch articles', opt)
            if (!(opt && (opt.ownerKey || (opt.obj && opt.obj.id)) && opt.obj.objectType == 'acquisition')) {
                reject({ fatal: false, message: 'Bad article fetch Options' })
                return
            }

            if (!opt.ownerKey)
                opt.ownerKey = opt.obj.objectKey

            var artLogState = { 'total': 0, 'events': [], 'ownerKey': opt.ownerKey, 'preparedEvents': [], 'groupedEvents': [], 'sessionEvents': [], 'fetched': false, 'exTime': 0.0 }
            var url = "/AcquisitionInfo/" + opt.obj.id //+ (opt.limit ? "&limit=" + opt.limit : "")
            axios.get(url)
                .then(r => r.data)
                .then(results => {
                    if (results) {
                        artLogState.fetched = true
                        artLogState.events = []
                        if (results.closedPR && results.closedPR.url) artLogState.events.push(results.closedPR);
                        if (results.announcedPR && results.announcedPR.url) artLogState.events.push(results.announcedPR);
                        artLogState.total = artLogState.events.length
                        //artLogState.exTime = results.resultsSet.exTimes ? results.resultsSet.exTimes.total : -1

                        artLogState.preparedEvents = this.prepareEvents({
                            'events': artLogState.events, 'eventSource': 'articles', 'fetchedEvents': true, 'obj': opt.obj
                        })
                        artLogState.groupedEvents = artLogState.preparedEvents
                        commit('SET_ARTICLELOG', artLogState)
                        resolve({ 'fetched': true, 'total': artLogState.total })
                    }
                    else {
                        resolve({ 'fetched': true, 'total': 0 })
                       // reject({ fatal: true, message: "events not found id: " + opt.ownerKey })
                    }

                })
        })
    }
}


// mutations
const mutations = {
    SET_ACQLOG(state, opt) {
        state.acqLog[this.encodeOwnerKey(opt.ownerKey)] = opt
    },
    SET_ARTICLELOG(state, opt) {
        state.articleLog[this.encodeOwnerKey(opt.ownerKey)] = opt
    }
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
