<template>
    <teleport to="body">
      <section v-if="model" v-bind="$attrs" :class="'show modal ' + (dragging ? 'dragging' : '')" @mousemove="dragModal" @mouseup="dragEnd" >
          <OnClickOutside @trigger="closeMe">
              <section ref="dialog" class="modal-dialog" role="dialog">
                  <span v-if="hasClose" class="modal-close" @click="closeMe">
                      <i class="fa fa-times"></i>
                  </span>
                  <modal-header :title="title" :icon="icon" v-if="hasHeaderSlot || title" :class="draggable ? 'draggable' : ''"
                                @mousedown="dragStart">
                      <template #modal-header>
                          <slot name="header" />
                      </template>
                  </modal-header>
                  <modal-body :showOverflow="showOverflow">
                      <template #modal-body>
                          <slot name="body" />
                      </template>
                  </modal-body>
                  <modal-footer v-if="hasFooterSlot">
                      <slot name="footer" />
                  </modal-footer>
                  <spin-loader :showMe="loading" />
                  <modal-footer v-if="!hasFooterSlot && actionbuttons" v-show="!loading" @save="saveMe" @cancel="cancelMe" :saveTitle="saveTitle">
                  </modal-footer>
              </section>
          </OnClickOutside>
      </section>
    </teleport>
</template>

<script>
    import $ from 'jquery'

    import ModalHeader from "./ModalHeader.vue"
    import ModalBody from "./ModalBody.vue"
    import ModalFooter from "./ModalFooter.vue"
    import { OnClickOutside } from '@vueuse/components'
    import { Comment } from 'vue'

    export default {
        components: {
            ModalHeader,
            ModalBody,
            ModalFooter,
            OnClickOutside
        },
        inheritAttrs: false,
        emits: ['save', 'cancel', 'close', 'update:modelValue'],
        props: {
            loading: { type: Boolean, default: false },
            modelValue: { type: Boolean, default: false },
            title: String,
            icon: String,
            actionbuttons: Boolean,
            saveTitle: { type: String, default: 'Save' },
            draggable: { type: Boolean, default: true },
            hasClose: { type: Boolean, default: true },
            ignoreClickOutsideClasses: { type: Array, default: new Array("customAlert", "autoCompletePanel", "dropdownPanel") },
            showOverflow: { type: Boolean, default: false }
        },
        data: function () {
            return {
                dragging : false,
                dragOffsetTop: 0,
                dragOffsetLeft: 0,
                isOpen: false
            }
        },
        computed: {
            hasHeaderSlot() {
                return (this.$slots.header && this.$slots.header().findIndex(o => o.type !== Comment) !== -1)
            },
            hasFooterSlot() {
                return (this.$slots.footer && this.$slots.footer().findIndex(o => o.type !== Comment) !== -1)
            },
            model: {
                get() {
                    return this.modelValue || this.isOpen
                },
                set(value) {
                    this.isOpen = value;
                    this.$emit('update:modelValue', value)
                }
            }
        },
        methods: {
            showTheModal() {
                this.dragging = false;
                this.model = true
            },
            closeTheModal(ev) {
                if (this.ignoreClickOutsideClasses && ev) {
                    var matches = this.ignoreClickOutsideClasses.filter(c => {
                        return $(ev.target).closest('.' + c).length > 0
                    });
                    if (matches.length > 0)
                        return
                }

                this.dragging = false;
                this.model = false
            },
            closeMe(ev) {
                this.closeTheModal(ev)
                this.$emit('close')
            },
            saveMe() {
                this.$emit('save')
            },
            cancelMe() {
                this.closeTheModal()
                this.$emit('cancel')
            },
            dragStart(e) {
                if (this.draggable) {
                    this.dragging = true;
                    // e.target.style.cursor = 'grabbing';
                    var dialog = this.$refs.dialog;
                    var rect = dialog.getBoundingClientRect();
                    //var win = dialog.ownerDocument.defaultView;

                    //save the offset so that the mouse cursor remains relative to the spot of the element we're dragging from
                    this.dragOffsetTop = e.pageY - rect.top // + win.pageYOffset;
                    this.dragOffsetLeft = e.pageX - rect.left // + win.pageXOffset;
                    //these windows offsets were being problematic when scrolled into the page so let's remove them for now. they shouldn't be needed
                }
            },
            dragModal(e) {
                if (this.draggable) {
                    var me = this;
                    if (me.dragging) {
                        //e.target.style.cursor = 'grabbing';
                        var dialog = me.$refs.dialog;
                        var style = getComputedStyle(dialog);

                        //after dragging the modal gets resized for some reason so we need to save its size pre-move - unsure why
                        var h = parseInt(style.height, 10);
                        var w = parseInt(style.width, 10);

                        dialog.style.position = "absolute";

                        //use the previously saved offset to keep the mouse cursor relative to the spot of the element we dragged from
                        //offset h/2 and w/2 because the new position is relative to the center of the element instead of its top left corner - unsure why
                        dialog.style.top = e.pageY - me.dragOffsetTop + (h / 2) + 'px';
                        dialog.style.left = e.pageX - me.dragOffsetLeft + (w / 2) + 'px';

                        //restore the initial size
                        //dialog.style.height = h + 'px';
                        //dialog.style.width = w + 'px';
                    }
                }
            },
            dragEnd() {
                this.dragging = false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal:not(.dragging) .draggable {
        cursor: grab
    }
    .dragging {
        cursor: grabbing 
    }
</style>