<template>
    <menu-horizontal options="space-between">
        <menu-item v-if="canHaveComment && !isEditing" @click="sendEventBus('editCommentRecordBody', { obj: { id: 0, objectType: 'comment'}, editorID: editorID })" title="New Comment">
            <i class="fas fa-comment-alt-lines"></i>
        </menu-item>
        <menu-item v-else-if="isEditing" :active="true" @click="sendEventBus('toggleCommentRecordBody', { editorID: editorID })" title="View Open Comment">
            <i class="fas fa-comment-alt-lines"></i>
        </menu-item>

        <menu-item v-if="!isEditing">
            <dropdown :title="'Sharing options'" v-model="shareToolsOpen" type="icon" icon="share" direction="up-center">
                <template #header>
                </template>
                <template #body>
                    <menu-vertical options="panel options medium">
                        <menu-item v-if="!isEditing" icon="share" label="Share with User(s)" @click="openFlagModal('shareAction')" />
                        <menu-item v-if="!isEditing" label="Send to Research" icon="bell" @click="openFlagModal('bellAction')" />
                        <menu-item v-if="!isEditing" :label="'Set reminder'" icon="flag" @click="openFlagModal('flagAction')" />
                    </menu-vertical>
                </template>
            </dropdown>
        </menu-item>
        <menu-item v-if="canHaveCloudDocs" @click="handleCloudFileClick">
            <btn :title="cloudDocTitle" type="icon" size="medium" :icon="cloudDocIcon" :count="cloudDocCount" :class="cloudDocCount > 0 ? 'is-active' : ''" />
        </menu-item>
        <menu-item title="Tools">
            <dropdown v-if="!loading" v-model="toolsOpen" type="icon" icon="cogs" direction="up-right">
                <template #header>
                </template>
                <template #body>
                    <menu-vertical options="panel options medium">
                        <menu-item label="Sync Data" icon="sync" @click="synch" />
                        <menu-item v-if="hasEditPage && editURL" label="Open Edit Page" icon="edit" :href="editURL" target="_blank" />
                        <menu-item label="See Email Templates" icon="envelope" @click="showEmailOptions"></menu-item>
                        <menu-item label="Save to List" icon="list" @click="openSaveToListModal" />
                        <menu-item label="Pin to Dashboard" icon="thumbtack" @click="pinToDashboard" />
                        <menu-item v-if="queryContext" label="Remove from Search" icon="trash-alt" @click="sendEventBus('removeFromQuery', {'obj': obj, 'queryContext': queryContext })" />
                        <menu-item v-if="detailsContext && titleURL" label="Open old Details Page" icon="external-link" :href="'https://cmdm.berkerynoyes.com' + titleURL.replace('detailspage', 'details') + '?ignoreRedirect=true'" />
                    </menu-vertical>
                </template>
            </dropdown>
            <template v-else>
                <spin-loader :showMe="loading" />
            </template>
        </menu-item>
        <template v-if="isEditing">
            <menu-item>
                <btn type="secondary" @click="sendEventBus('cancelEditsRecordBody', { editorID: editorID })" el="button">Cancel</btn>
            </menu-item>
            <menu-item>
                <btn type="primary" @click="sendEventBus('saveEditsRecordBody', { editorID: editorID })" el="button">Save</btn>
            </menu-item>
        </template>
    </menu-horizontal>
    <modal v-model="emailTemplateSelectOpen" title="Email Templates">
        <template #body>
            <email-template-menu :objectType="objectType" :objectID="objectID" />
        </template>
    </modal>
    <dashboard-select-modal v-model="dashboardSelectOpen" :obj="{ id: objectID, name: objectName, objectType: objectType }" title="Pin to Dashboard" action="Pin"></dashboard-select-modal>
    <flag-modal ref="flagModal" :flagItem="flag.flagItem" :flagAction="flag.flagAction" :readOnly="flag.delete" :multiFlag="true" />
    <save-to-list-modal v-model="saveToListOpen" title="Save to List..." @save="saveSelectedToList" />
</template>

<style>
</style>

<script>
    import { ref, computed, inject, nextTick, onMounted } from 'vue'
    import _ from 'lodash'
    import { getRecordTools } from '../../composables/RecordTools.js'
    import { useToast } from '../../composables/ModelWrapper'
    import { useStore } from 'vuex'

    import MenuHorizontal from "../ui/menus/MenuHorizontal.vue"
    import MenuVertical from '../ui/menus/MenuVertical.vue'
    import MenuItem from "../ui/menus/MenuItem.vue"
    import EmailTemplateMenu from '../Menus/EmailTemplateMenu.vue'
    import DashboardSelectModal from '../Modals/DashboardSelectModal.vue'
    import FlagModal from '../Comment/FlagModal.vue'
    import SaveToListModal from '../Modals/SaveToListModal.vue'

    export default {
        props: {
            recordContext: { type: String, default: 'details' },
            parentContext: { type: String, default: '' },
            obj: { type: Object },
            editing: { type: Object },
            editorID: { type: String }
        },
        emits: ['button-clicked'],
        components: {
            MenuHorizontal, MenuItem, EmailTemplateMenu, DashboardSelectModal, MenuVertical, FlagModal, SaveToListModal
        },
        setup(props) {
            // tools
            const toast = useToast()
            const store = useStore()
            const query = inject('query')
            const emitter = inject("emitter")   // Inject event bus 

            // data
            const dashboardSelectOpen = ref(false)
            const saveToListOpen = ref(false)
            const emailTemplateSelectOpen = ref(false)
            const shareToolsOpen = ref('closed')
            const toolsOpen = ref('closed')
            const loading = ref(false)
            const flag = ref({
                flagItem: {},
                flagAction: '',
                delete: false,
            })


            // computed
            const { objectID, objectType, objectKey, objectName, editURL, titleURL } = getRecordTools(props)
            const isEditing = computed(() => { return props.editing ? Object.keys(props.editing).filter(function (k) { return props.editing[k] }).length > 0 : false })

            const hasEditPage = computed(() => ['company', 'person','acquisition'].includes(objectType.value))
            const canHaveComment = computed(() => ['company', 'person'].includes(objectType.value))
            const canHaveCloudDocs = computed(() => ['company'].includes(objectType.value))
            const cloudDocCount = computed(() => props.obj && props.obj.cloudDocCount ? props.obj.cloudDocCount : 0)
            const cloudDocURL = computed(() => '/CloudFile/?id=' + objectID.value + '&objectType=' + objectType.value + '&name=' + encodeURIComponent(objectName.value))
            const cloudDocTitle = computed(() => cloudDocCount.value ? 'See ' + cloudDocCount.value + ' files' : 'Add ' + objectType.value + ' Files')
            const cloudDocIcon = computed(() => (cloudDocCount.value ? 'fa' : 'fas') + ' fa-folder')
            const queryContext = computed(() => props.parentContext == 'search' && query && query.value ? { targetID: query.value.id, targetName: query.value.name, targetTypeID: 18 } : null)
            const detailsContext = computed(() => props.parentContext == 'detailspage')

            //methods
            const sendEventBus = (eventName, more) => {
                var req = { 'editorID': props.editorID, origin: 'record-menu' }
                if (more && _.isObject(more)) {
                    Object.keys(more).forEach((key) => { req[key] = more[key] })
                }
                emitter.emit(eventName, req)
                toolsOpen.value = 'closed'
            }
            const handleCloudFileClick = () => {
                window.open('https://cmdm.berkerynoyes.com'+ cloudDocURL.value, '_blank')
            }
            const saveToList = () => {
                toast.add({ severity: 'error', summary: 'Save to List', detail: 'Not Implemented', life: 3000 })
                toolsOpen.value = 'closed'
            }
            const synch = () => {
                if (objectKey.value) {
                    toolsOpen.value = 'closed'
                    loading.value = true
                    store.dispatch('objectCache/synchObject', { objectKey: objectKey.value })
                        .then(result => {
                            if (result.success)
                                toast.add({ severity: 'success', summary: 'Synch Complete', life: 3000 })
                            else
                                toast.add({ severity: 'error', summary: 'Synch Failed', life: 3000 })
                            loading.value = false
                            toolsOpen.value = 'closed'

                            /*if (props.recordContext == 'details') {
                                window.location.reload()
                            }*/

                        }).catch(error => {
                            loading.value = false
                            toast.add({ severity: 'error', summary: 'Error during synch', detail: error.message, life: 3000 })
                        })
                }
            }
            const pinToDashboard = () => {
                toolsOpen.value = 'closed'
                dashboardSelectOpen.value = true
            }
            const showEmailOptions = () => {
                toolsOpen.value = 'closed'
                emailTemplateSelectOpen.value = true
            }

            onMounted(() => {
                toolsOpen.value = 'closed'
            })

            return {
                // tools
                emitter, query,
                // data
                dashboardSelectOpen, emailTemplateSelectOpen, loading, toolsOpen, shareToolsOpen, flag, saveToListOpen,
                // computed
                objectID, objectType, objectName, objectKey, editURL, isEditing, titleURL,
                hasEditPage, canHaveComment, canHaveCloudDocs, cloudDocCount, cloudDocIcon, cloudDocTitle, cloudDocURL, queryContext, detailsContext,
                // methods
                sendEventBus, handleCloudFileClick, saveToList, synch, pinToDashboard, showEmailOptions,
            }
        },
        methods: {
            openSaveToListModal: function () {
                this.toolsOpen = 'closed'
                this.saveToListOpen = true;
            },
            saveSelectedToList: function (opt) {
                var me = this;
                if (["acquisition", "person", "company"].includes(me.obj.objectType)) {
                    opt.items = [{ id: me.obj.id, memberTypeID: me.obj.objectType == 'acquisition' ? 2 : (me.obj.objectType == 'person' ? 3 : (me.obj.objectType == 'company' ? 1 : 0)) }];

                    me.loading = true;
                    me.$store.dispatch('bookmarks/addItemsToList', opt).then((result) => {
                        me.loading = false;
                        me.$toast.add({ severity: 'info', summary: me.obj.objectType + ' Added to List' + (result.subsets[0] ? ' and Subset' : ''), life: 3000 })
                        //window.open('https://cmdm.berkerynoyes.com/List/Details/' + result.id + (result.subsets[0] ? '?subsetID=' + result.subsets[0].id : ''));

                    }).catch(error => {
                        me.loading = false;
                        me.$toast.add({ severity: 'error', summary: 'Error Adding ' + me.obj.objectType + " to List", detail: error, life: 5000 })
                    });
                }
                else
                    me.$toast.add({ severity: 'error', summary: 'Cannot Add ' + me.obj.objectType  + ' to List', detail: "List operation not supported", life: 5000 })
            },
            openFlagModal: async function (flagAction) {
                this.flag = {
                    flagItem: this.obj,
                    flagAction: flagAction,
                    delete: false
                };
                this.shareToolsOpen = 'closed'

                await nextTick();
                this.$refs['flagModal'].showTheModal();
            },
        }
    }
</script>