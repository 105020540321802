import $ from 'jquery'
import _ from 'lodash'
import axios from 'axios'

export default {
    tagUtils: {
        //functions to check tag type
        isAtTag: function (value) {
            return (value.name == 'atTag');
        },
        isHashTagwithAtTag: function (value) {
            if (this.isAtTag(value.name)) return false;
            return !(!value.ownerTypeID);
        },

        //function to build tags
        buildTagSpan: function(tag, id) {
            if (tag.type == 'hashtag')
                return this.buildHashTagSpan(tag.name, tag.value, id);
            else
                return this.buildAtTagSpan(tag.value, tag.ownerID, tag.ownerTypeID, id);
        },

        //function to generate span from atTag data
        buildAtTagSpan: function (tagName, tagID, tagType, id) {
            if (!tagName) return '';
            var $tag = $('<span class="cmdmAtTag cmdmTag"></span>');
            $tag.attr('data-tagname', tagName);
            $tag.attr('data-tagid', tagID);
            $tag.attr('data-tagtype', tagType);
            $tag.text('@' + tagName);

            if(id)
                $tag.attr('id', id);

            $tag.wrap('<div>');
            return $tag.parent().html();
        },

        //function to generate span from hashTag data
        buildHashTagSpan: function (tagName, tagValues, id) {
            if (!tagName) return '';
            if (tagName[0] == '#') tagName = tagName.substr(1);
            var $tag = $('<span class="cmdmHashTag cmdmTag" data-tagvalue="" data-tagid="" data-tagtype=""></span>');

            var tagText = '#' + tagName;

            if (!Array.isArray(tagValues)) 
                tagValues = [ tagValues ];

            for (var i = 0; i < tagValues.length; i++) {
                var tagValue = tagValues[i];
                if (tagValue) {
                    if (i == 0) { tagText += ':'; }

                    if (typeof tagValue == 'object' && tagValue.tagID) {
                        $tag.attr('data-tagvalue' + (i ? i : ""), tagValue.tagName);
                        $tag.attr('data-tagid' + (i ? i : ""), tagValue.tagID);
                        $tag.attr('data-tagtype' + (i ? i : ""), tagValue.tagType);
                        tagText += this.buildAtTagSpan(tagValue.tagName, tagValue.tagID, tagValue.tagType);
                    }
                    else {
                        $tag.attr('data-tagvalue' + (i ? i : ""), tagValue);
                        $tag.attr('data-tagid' + (i ? i : ""), '');
                        $tag.attr('data-tagtype' + (i ? i : ""), '');
                        tagText += tagValue.tagName ? tagValue.tagName : tagValue;
                    }

                    tagText += ',';
                }
            }
            if (tagText.slice(-1) == ',') { tagText = tagText.slice(0, -1); }

            $tag.attr('data-tagname', tagName);
            $tag.html(tagText);

            if (id)
                $tag.attr('id', id);

            $tag.wrap('<div>');
            return $tag.parent().html();
        },
        //function to read data from any span
        getTagDataFromSpan: function ($tag) {

            if ($tag.hasClass('cmdmAtTag') && $tag.parent().hasClass('cmdmHashTag')) 
                return this.getHashTagDataFromSpan($tag.parent());
            
            else if ($tag.hasClass('cmdmAtTag') && !$tag.parent().hasClass('cmdmHashTag')) 
                return this.getAtTagDataFromSpan($tag);
            
            else if ($tag.hasClass('cmdmHashTag')) 
                return this. getHashTagDataFromSpan($tag);
        },

        // function to read hashTag data from atTag span
        getAtTagDataFromSpan: function ($tag) {
            return {
                name: 'atTag',
                values: [{
                    value: ($tag.data('tagname') ? $tag.data('tagname') : null),
                    ownerID: ($tag.data('tagid') ? $tag.data('tagid') : null),
                    ownerTypeID: ($tag.data('tagtype') ? $tag.data('tagtype') : null)
                }]
            };
        },
        //function to get hashTag data from hashTag span
        getHashTagDataFromSpan: function ($tag) {
            var values = [];
            var i = 0;

            while ($tag.data('tagvalue' + (i ? i : ""))) {
                values.push({
                    value: ($tag.data('tagvalue' + (i ? i : "")) ? $tag.data('tagvalue' + (i ? i : "")) : ""),
                    ownerID: ($tag.data('tagid' + (i ? i : "")) ? $tag.data('tagid' + (i ? i : "")) : null),
                    ownerTypeID: ($tag.data('tagtype' + (i ? i : "")) ? $tag.data('tagtype' + (i ? i : "")) : null)
                });
                i++;
            }

            if (values.length == 0) {
                values.push({
                    value: "",
                    ownerID: null,
                    ownerTypeID: null
                });
            }

            return {
                name: $tag.data('tagname'),
                values: values
            }
        },
        deepCompareTags: function (tag1, tag2) {
            if (typeof tag1 === 'string' && typeof tag2 === 'string') return this.deepCompareHtmlTags(tag1, tag2);
            else return this.deepCompareObjTags(tag1, tag2);
        },

        //function to compare tags
        deepCompareObjTags: function (tag1, tag2) {
            if (tag1.name != tag2.name) return false;

            if (!tag1.values || tag1.values.length == 0) { tag1.values = [{ value: tag1.value, ownerTypeID: tag1.ownerTypeID, ownerID: tag1.ownerID }]; }
            if (!tag2.values || tag2.values.length == 0) { tag2.values = [{ value: tag2.value, ownerTypeID: tag2.ownerTypeID, ownerID: tag2.ownerID }]; }

            if (tag1.values.length == tag2.values.length) {

                var sortCond = function (a, b) {
                    var nameA = a.value.toLowerCase(), nameB = b.value.toLowerCase()
                    if (nameA < nameB)
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0
                };

                var tag1ValuesOrdered = tag1.values.sort(sortCond);
                var tag2ValuesOrdered = tag2.values.sort(sortCond);

                for (var j = 0; j < tag2ValuesOrdered.length; j++) {
                    if (((tag1ValuesOrdered[j].value ? tag1ValuesOrdered[j].value : "") == (tag2ValuesOrdered[j].value ? tag2ValuesOrdered[j].value : "")) &&
                        tag1ValuesOrdered[j].ownerID == tag2ValuesOrdered[j].ownerID &&
                        tag1ValuesOrdered[j].ownerTypeID == tag2ValuesOrdered[j].ownerTypeID) { return true; }
                }

            }
            return false;
        },

        //function to compare tags
        deepCompareHtmlTags: function (taghtml1, taghtml2) {
            if (taghtml1.length == taghtml2.length) {
                var id_check, tagvalue_check, tagtype_check, tagname_check, cmdmtag_check;

                //check type
                if (taghtml1.includes('cmdmHashTag') && taghtml2.includes('cmdmHashTag'))
                    tagvalue_check = taghtml1.split('data-tagvalue=')[1].match(/".*?"/)[0] == taghtml2.split('data-tagvalue=')[1].match(/".*?"/)[0];
                else if (taghtml1.includes('cmdmAtTag') && taghtml2.includes('cmdmAtTag'))
                    tagvalue_check = true;
                else
                    return false;

                id_check = taghtml1.split('data-tagid=')[1].match(/".*?"/)[0] == taghtml2.split('data-tagid=')[1].match(/".*?"/)[0];
                tagtype_check = taghtml1.split('data-tagtype=')[1].match(/".*?"/)[0] == taghtml2.split('data-tagtype=')[1].match(/".*?"/)[0];
                tagname_check = taghtml1.split('data-tagname=')[1].match(/".*?"/)[0] == taghtml2.split('data-tagname=')[1].match(/".*?"/)[0];
                cmdmtag_check = taghtml1.includes("cmdmTag") == taghtml2.includes("cmdmTag");

                return (id_check && tagtype_check && tagname_check && cmdmtag_check && tagvalue_check);
            }
            return false;
        },
  
        //function to orchestrate validation, parsing and resolution of tags in HTML content
        parseContent: function (contentToParse, callback) {
            var validResponse = this.validateHashTags(contentToParse, []);   
            var parseResponse = this.parseTags(validResponse.html, validResponse.tags);
            this.resolveTags(parseResponse.html, parseResponse.tags, callback);
        },
          
        // function that reads HTML to see if there are any atTags or hashTags and check that they are well formed
        validateHashTags: function (taggedText, tags) {
            var me = this;
            if (!tags) tags = new Array();
            if (!taggedText || (taggedText.indexOf('#') < 0 && taggedText.indexOf('@') < 0)) return { html: taggedText, tags: tags };

            tags = new Array();
            var $text = $('<div>' + taggedText.replace('&nbsp;', ' ') + '</div>');

            var $hashtags = $text.find('.cmdmHashTag');
            $hashtags.each(function () {
                var $tag = $(this);
                $tag.removeClass('invalidTag');
                $tag.removeAttr('id');

                var tagData = me.getHashTagDataFromSpan($tag);
                // validate tag by seeing if the tag data renders same as the tag
                var tagHTML;
                if (tagData.values && tagData.values.length > 0) {
                    var tagValues = [];
                    for (var i = 0; i < tagData.values.length; i++) {
                        tagValues.push(tagData.values[i].ownerID == null ? tagData.values[i].value : { tagName: tagData.values[i].value, tagID: tagData.values[i].ownerID, tagType: tagData.values[i].ownerTypeID });
                    }
                    tagHTML = me.buildHashTagSpan(tagData.name, tagValues);
                }
                var $newTag = $tag.clone()
                $newTag.wrap('<div>');
                // if good tag
                if (tagHTML == $newTag.parent().html() || me.deepCompareTags(tagHTML, $newTag.parent().html()))
                    tags.push(tagData);
                else
                    $tag.addClass('invalidTag');
            });

            var $attags = $text.find('.cmdmAtTag');
            $attags.each(function () {
                var $tag = $(this);
                $tag.removeClass('invalidTag');
                $tag.removeAttr('id');

                if (!$tag.parent().hasClass('cmdmHashTag')) {
                    var tagData = me.getAtTagDataFromSpan($tag);
                    var tagHTML = me.buildAtTagSpan(tagData.values[0].value, tagData.values[0].ownerID, tagData.values[0].ownerTypeID);
                    var $newTag = $tag.clone()
                    $newTag.wrap('<div>');
                    // if good tag
                    if (tagHTML == $newTag.parent().html() || me.deepCompareTags(tagHTML, $newTag.parent().html()))
                        tags.push(tagData);
                    else {
                        $tag.addClass('invalidTag');
                    }
                }
            });
            var validatedText = $text.html();

            return { html: validatedText, tags: tags };
        },

        // function that parse HTML to see if there are any atTags or hashTags that are not encapsulated into a well formed span
        parseTags: function (taggedText, tags) {
            var me = this;

            if (!tags) tags = new Array();
            if (!taggedText || (taggedText.indexOf('#') < 0 && taggedText.indexOf('@') < 0)) return { html: taggedText, tags: tags };

            var $text = $('<div>' + taggedText.replace('&nbsp;', ' ') + '</div>');

            // lets find hash tags that have not been parsed
            var $hashCandidates = $text.find(':contains("#")');
            var hashTagPat = /([\s]#(?![\da-fA-F]{6}\b)[^<\s]+)/g;
            $hashCandidates.each(function () {
                var $tag = $(this);
                if (!$tag.hasClass('cmdmHashTag') && !$tag.hasClass('cmdmTag')) {
                    var foundTags = new Array();
                    var tagHTML = ' ' + $tag.html() + ' ';
                    if (!$($.parseHTML(tagHTML)).hasClass('cmdmTag')) {
                        var match = hashTagPat.exec(tagHTML);
                        while (match != null) {
                            // matched text: match[0]
                            var rawTag = match[0].trim();
                            console.log(rawTag);
                            var hTag = rawTag.substr(1);
                            var iColon = hTag.indexOf(':');
                            if (iColon == -1) // no colon
                                foundTags.push({
                                    name: hTag,
                                    tag: rawTag,
                                    values: [{ value: null }]
                                });
                            else if (iColon == hTag.length - 1) // colon at end of string
                                foundTags.push({
                                    name: hTag.substr(0, iColon),
                                    tag: rawTag,
                                    values: [{
                                        value: null,
                                        ownerID: null,
                                        ownerTypeID: null
                                    }]
                                });
                            else
                                // -1 indicates we need to resolve the atTag
                                foundTags.push({
                                    name: hTag.substr(0, iColon),
                                    tag: rawTag,
                                    values: [{
                                        value: hTag.substr(iColon + 1),
                                        ownerID: (hTag[iColon + 1] == '@' ? -1 : null),
                                        ownerTypeID: null
                                    }]
                                });
                            // match start: match.index
                            // capturing group n: match[n]
                            match = hashTagPat.exec(tagHTML);
                        }
                        // replace found tags with parsed tag 
                        tagHTML = $tag.html();
                        for (var j = 0; j < foundTags.length; j++) {
                            var fTag = foundTags[j];
                            tagHTML = tagHTML.replace(fTag.tag, ' ' + me.buildHashTagSpan(fTag.name, fTag.values[0].value) + ' '); // addwhite space
                            delete fTag.tag;
                            tags.push(fTag);
                        }
                        $tag.html(tagHTML);
                    }
                }
            });

            var $atCandidates = $text.find(':contains("@")');
            var atTagPat = /([\s:]@[^<\s]+)/g;
            $atCandidates.each(function () {
                var $tag = $(this);
                if (!$tag.hasClass('cmdmAtTag') && !$tag.hasClass('cmdmTag')) {
                    var foundTags = new Array();
                    var tagHTML = ' ' + $tag.html() + ' ';
                    if (!$($.parseHTML(tagHTML)).hasClass('cmdmTag')) {
                        var match = atTagPat.exec(tagHTML);
                        while (match != null) {
                            // matched text: match[0]
                            var rawTag = match[0].trim();
                            var inHashTag = (rawTag[0] == ':');
                            if (inHashTag) rawTag = rawTag.substr(1);
                            //console.log(rawTag);
                            var aTag = rawTag.substr(1);
                            foundTags.push({
                                name: 'atTag',
                                tag: rawTag,
                                inHashTag: inHashTag,
                                values: [{
                                    value: aTag,
                                    ownerID: -1,
                                    ownerTypeID: null
                                }]
                            });
                            match = atTagPat.exec(tagHTML);
                        }
                        // replace found tags with parsed tag 
                        tagHTML = $tag.html();
                        for (var j = 0; j < foundTags.length; j++) {
                            var fTag = foundTags[j];
                            tagHTML = tagHTML.replace((fTag.inHashTag ? ':' : '') + fTag.tag, (fTag.inHashTag ? ':' : ' ') + me.buildAtTagSpan(fTag.values[0].value, -1, null) + (fTag.inHashTag ? '' : ' ')); // addwhite space
                            delete fTag.tag;
                            delete fTag.inHashTag;
                            tags.push(fTag);
                        }
                        $tag.html(tagHTML);
                    }
                }
            });

            var markedText = $text.html();

            return { html: markedText, tags: tags };
        },

        //function to resolve atTags from parsed tags from parseTags function; it runs asynchronously
        resolveTags: function (taggedText, tags, callback) {
            var me = this;
            if (!tags) tags = new Array();

            if (!taggedText || (taggedText.indexOf('@') < 0)) {
                callback({ html: taggedText, tags: tags });
                return;
            }

            var $text = $('<div>' + taggedText + '</div>');
            // lets find atTags that have not been resolved
            var $candidates = $text.find(".cmdmAtTag[data-tagid='-1']");

            if ($candidates.length == 0) {
                callback({ html: taggedText, tags: tags });
                return;
            }
            var maxId = $candidates.length - 1;
            processGuess(-1, null);

            function processGuess(trackingID, tagData) {
                var $tag;
                if (trackingID >= 0) {
                    // process the result
                    $tag = $($candidates[trackingID]);
                    if (tagData == null) {
                        $tag.addClass('invalidTag');
                        if ($tag.parent().hasClass('cmdmHashTag')) {
                            $tag.parent().addClass('invalidTag');
                        }
                    }
                    else {
                        $tag.attr('data-tagname', tagData.values[0].value);
                        $tag.attr('data-tagid', tagData.values[0].ownerID);
                        $tag.attr('data-tagtype', tagData.values[0].ownerTypeID);
                        if ($tag.parent().hasClass('cmdmHashTag')) {
                            // Update parent Hash Tag
                            //would like to add support for multitags eventually
                            $tag.parent().attr('data-tagvalue', tagData.values[0].value);
                            $tag.parent().attr('data-tagid', tagData.values[0].ownerID);
                            $tag.parent().attr('data-tagtype', tagData.values[0].ownerTypeID);
                            // find parent tag in tag array and update the resolved info
                            var ptags = _.filter(tags, function (t) { return t.values[0].value == '@' + tagData.values[0].value && t.values[0].ownerID == -1; });

                            if (ptags) {
                                for (var i = 0; i < ptags.length; i++) {
                                    ptags[i].values[0].value = tagData.values[0].value;
                                    ptags[i].values[0].ownerID = tagData.values[0].ownerID;
                                    ptags[i].values[0].ownerTypeID = tagData.values[0].ownerTypeID;
                                }
                            }
                            else {
                                console.log('cant find parent tag in tag array');
                            }
                        }
                        else
                            tags.push(tagData);
                    }
                }
                // Process next unresolved tag
                trackingID++;
                if (trackingID <= maxId) {
                    $tag = $($candidates[trackingID]);
                    me.guessAtTagData($tag.text(), trackingID, processGuess);
                }
                else {
                    // return all the results
                    // render html from $text
                    //$text.parent().append('&nbsp;');
                    var markedText = $text.html();

                    callback({ html: markedText, tags: tags });
                }
            }
        },

        // function to make asyncronous calls to web server to resolve atTags for parsing
        guessAtTagData: function (text, trackingID, callback) {

            if (!text || (text.length == 1 && text[0] == '@')) {
                callback(trackingID, null); return;
            }

            if (text[0] != '@') text = '@' + text;

            axios.get("/Search/AtTagSearch?term=" + text + "&Limit=5&ExpectAtSign=true", { timeout: 2000 })
                .then(r => r.data)
                .then(results => {
                    if (results && results.length && results[0].tag == text) {
                        callback(trackingID, {
                            name: 'atTag',
                            values: [{
                                value: results[0].tag.substr(1),
                                ownerID: results[0].id,
                                ownerTypeID: results[0].type
                            }]
                        });
                    }
                    else
                        callback(trackingID, null);

                }).catch(function () {
                    callback(trackingID, null);
                })
        },

        //cleans up white space
        cleanUpWhiteSpace: function (white_space, html) {
            //we're going to check the string starting from the back
            //until we can't find the white space anymore
            var continue_checking = true;

            while (continue_checking && html && html.length > white_space.length) {
                var final_characters = html.substring(html.length - white_space.length, html.length);

                if (final_characters == white_space)
                    html = html.substring(0, html.length - white_space.length - 1).trim();
                else
                    continue_checking = false;
            }

            return html;

        },
    }
}