<template>
    <OnClickOutside @trigger="closeTheDropDown">
        <div class="input-group" v-observe-visibility="{ callback: visibilityChanged,  once: true}">
            <div class="input-wrapper" :class="!multiple ? 'has-icon-pill' : ''">
                <i v-if="!multiple" :class="iconMap[treeName]"></i>
                <auto-complete v-model="autoCompleteWords"
                               :suggestions="filteredWords"
                               @complete="search($event)"
                               field="name"
                               :placeholder="'Type ' + treeLabel"
                               :multiple="multiple"
                               :forceSelection="true"
                               :appendTo="appendTo"
                               :disabled="disabled"
                               @item-select="wordSelected"
                               @item-unselect="wordSelected"
                               @focus="autocompleteFocused"
                               :virtualScrollerOptions="null">
                    <template #buttonSlot>
                        <dropdown v-model="treeDropdownState" icon="sitemap" @openclose="handleDropDownOpenClose" :disableClickOutside="true">
                            <template #body>
                                <div :id="realID" class="p-fluid tree-dropdown">
                                    <tree :value="nodeTreeRoot"
                                          :selectionMode="multiple ? 'multiple' : 'single'"
                                          v-model:selectionKeys="selectedTreeNode"
                                          :metaKeySelection="false"
                                          :expandedKeys="expandedKeys"
                                          :loading="treeLoading"
                                          scrollHeight="flex"
                                          :disabled="disabled"
                                          @focus="$emit('focus',$event)"
                                          @nodeExpand="onNodeExpand"
                                          @node-select="onNodeSelect"
                                          @node-unselect="onNodeUnselect"
                                          @nodeCollapse="onNodeCollapse"
                                          @before-show="treeNodeBeforeShow" />
                                </div>
                            </template>
                        </dropdown>
                    </template>
                </auto-complete>
            </div>
        </div>
    </OnClickOutside>
</template>

<script>

    import { computed, onMounted, nextTick, ref } from 'vue'
    import { useStore } from 'vuex'
    import { useModelVirtualWrapper, useToast } from '../../composables/ModelWrapper.js'
    import { getTextTools } from '../../composables/TextTools.js'
    import { getID } from '../../composables/Editors.js'
    import _ from 'lodash'
    import jQuery from 'jquery'

    import AutoComplete from '../PrimeVue/AutoComplete.vue'
    import Tree from 'primevue/tree/sfc'
    import { OnClickOutside } from '@vueuse/components'

    export default {
        name: 'LobPicker',
        components: { AutoComplete, Tree, OnClickOutside },
        emits: ['change', 'update:modelValue', 'focus'],
        props: {
            modelValue: { type: Array }, // Array of id and name
            treeName: { type: String, default: 'industry' },
            showInactive: { type: Boolean, default: false },
            multiple: { type: Boolean, default: false },
            inpopup: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false }
        },
        setup(props, { emit }) {
            var store = useStore()
            var toast = useToast()
            const { capitalizeFirst } = getTextTools()

            // model
            const virtualModel = ref([])
            const model = useModelVirtualWrapper(props, emit, 'modelValue', virtualModel)

            // data
            const autoCompleteWords = ref(props.multiple ? virtualModel.value : (virtualModel.value ? virtualModel.value[0] : null))
            const filteredWords = ref([])
            const selectedTreeNode = ref({})
            const expandedKeys = ref({})
            const treeLoading = ref(false)
            const treeDropdownState = ref('closed')
            const typedWord = ref('')
            const autocompleteWordCount = ref(0)
            const treeNodeCount = ref(0)
            const requireTreeSynch = ref(true)
            const isVisible = ref(false)
            const wordBuffer = ref([])
            const nodePathBuffer = ref([])
            const realID = ref(null)

            // computed
            const iconMap = computed(() => store.getters['ais/iconMap'])
            const treeWords = computed(() => store.getters['ais/treeWords'])
            const nodeTree = computed(() => store.getters['ais/nodeTree'])
            const treeMap = computed(() => store.getters['ais/treeMap'])
            const treeMapRev = computed(() => store.getters['ais/treeMapRev'])

            const treeID = computed(() => treeMap.value[props.treeName])
            const treeLabel = computed(() => capitalizeFirst(props.treeName))
            const appendTo = computed(() => "self") //this.inpopup ? "self" : "body"
            const nodeTreeRoot = computed(() => nodeTree.value[treeID.value].root)
            const selectedDescription = computed(() => {
                if (!autoCompleteWords.value)
                    return 'nothing selected'
                return props.multiple ? String.join(', ', autoCompleteWords.value.map((w) => { return w.name })) : autoCompleteWords.value.name
            })
            const selectedTreeNodeIDs = computed(() => {
                var selectedNodeIDs = []
                if (selectedTreeNode.value) {
                    Object.keys(selectedTreeNode.value).forEach((key) => {
                        if (selectedTreeNode.value[key] == true)
                            selectedNodeIDs.push(key)
                    })
                }
                return selectedNodeIDs
            })

            // methods
            const autocompleteFocused = (event) => {
                emit('focus', event)
                event.target.select()
            }
            const fetchTreeWords = () => {
                store.dispatch('ais/getTreeWords', { treeID: treeID.value, word: typedWord.value }).then((words) => {
                    if (words)
                        filteredWords.value = words
                }).catch(error => {
                    if (error.fatal)
                        toast.add({ severity: 'error', summary: 'Error searching ' + treeLabel.value + ' Words', detail: error.message, life: 3000 })
                })
            }
            const search = (event) => {
                typedWord.value = event.query.toLowerCase()
                if (typedWord.value.length < 1)
                    filteredWords.value = []
                if (treeWords.value[treeID.value][typedWord.value])
                    filteredWords.value = treeWords.value[treeID.value][typedWord.value]
                else {
                    fetchTreeWords()
                }
            }
            const wordSelected = () => {
                updateModel()
                if (treeDropdownState.value  == 'open')
                    synchControls('autocomplete')
                else
                    requireTreeSynch.value = true
            }
            const updateModel = () => {
                var words = autoCompleteWords.value
                console.log('updateModel', words, new Date())
                var nodes = []
                if (words) {
                    if (!props.multiple)
                        nodes.push({ id: words.ownerID ? words.ownerID : words.id, name: words.name })
                    else
                        nodes = words.map((w) => {
                            return { id: w.ownerID ? w.ownerID : w.id, name: w.name }
                        })
                }
                model.value = nodes
                emit('change', nodes)
            }
            const getTreeChildren = (nodeID) => {
                treeLoading.value = true
                store.dispatch('ais/getTreeChildren', { 'treeID': treeID.value, 'nodeID': nodeID })
                    .then(nodes => {
                        nodes.forEach((node) => {
                            expandedKeys.value[node.key] = false
                        })
                        treeLoading.value = false
                    })
                    .catch(error => {
                        if (error.fatal)
                            toast.add({ severity: 'error', summary: 'Error loading ' + treeLabel.value + ' Tree', detail: error.message, life: 3000 })
                        treeLoading.value = false
                    })
            }
            const getNodeFetchPromises = (nodeIDs) => {
                console.log('getting node fetches')
                wordBuffer.value = []
                let promises = []
                for (let i = 0; i < nodeIDs.length; i++) {
                    promises.push(new Promise((resolve, reject) => {
                        store.dispatch('ais/getNode', { id: nodeIDs[i] })
                            .then(node => {
                                if (node) {
                                    var word = null
                                    if (node.words.length == 1)
                                        word = node.words[0]
                                    else {
                                        word = node.words.find((w) => { return w.id == node.primaryWordID })
                                    }
                                    if (word) {
                                        wordBuffer.value.push(word)
                                    }
                                }
                                resolve()
                            }).catch(error => {
                                reject(error)
                            })
                    }))
                }
                return Promise.all(promises)
            }
            const setSelectedNode = (nodeIDs) => {
                console.log('setSelectedNode', nodeIDs)
                getNodeFetchPromises(nodeIDs).then(() => {
                    console.log('all node words fetched', wordBuffer.value)
                    autoCompleteWords.value = wordBuffer.value.length > 0 ? (props.multiple ? wordBuffer.value : wordBuffer.value[0]) : null
                    updateModel()
                }).catch(error => {
                    if (error.fatal)
                        toast.add({ severity: 'error', summary: 'Error searching ' + treeLabel.value + ' Tree', detail: error.message, life: 3000 })
                })
            }
            const onNodeExpand = (node) => {
                if (!node.children) {
                    if (node && !node.leaf && !node.children) {
                        getTreeChildren(node.key)
                        expandedKeys.value[node.key] = true
                    }
                }
            }
            const onNodeCollapse = (node) => {
                expandedKeys.value[node.key] = false
            }
            const onNodeSelect = (node) => {
                console.log('onNodeSelect', node)
                var nodeIDs = props.multiple ? model.value : []
                var words = props.multiple ? autoCompleteWords.value : []
                nodeIDs.push(node.key)
                var name = node.label.substr(0, node.label.indexOf('[') - 1)
                words.push({ id: node.key, name: name })
                setSelectedNode(nodeIDs)
                emit('change', words)
            }
            const onNodeUnselect = (node) => {
                console.log('onNodeUnselect', node)
                var nodeIDs = props.multiple ? model.value : []
                var words = props.multiple ? autoCompleteWords.value : []
                if (!props.multiple)
                    autoCompleteWords.value = null
                else {
                    nodeIDs = nodeIDs.filter((n) => { return n != node.key })
                    words = words.filter((w) => { return w.id != node.key })
                    setSelectedNode(nodeIDs)
                }
                emit('change', words)
            }
            const treeNodeBeforeShow = (ev) => {
                console.log('treeNodeBeforeShow', ev)
                store.dispatch('ais/getTreeWords', { treeID: treeID.value, word: typedWord.value }).then((words) => {
                    console.log('words', words)
                    if (words)
                        filteredWords.value = words
                    console.log('filteredWords', filteredWords.value)
                }).catch(error => {
                    if (error.fatal)
                        toast.add({ severity: 'error', summary: 'Error searching ' + treeLabel.value + ' Words', detail: error.message, life: 3000 })
                })
            }
            const handleDropDownOpenClose = (opened) => {
                if (opened)
                    synchControls('autocomplete')
                else
                    scrollToSelected()
            }
            const controlsInSynch = () => {
                if (!selectedTreeNodeIDs.value && !model.value)
                    return true
                if (selectedTreeNodeIDs.value.length != model.value.length)
                    return false
                var treeNodeIDs = selectedTreeNodeIDs.value
                var wordNodeIDs = model.value
                var notMatched = wordNodeIDs.find((w) => { return !treeNodeIDs.includes(w) })
                if (notMatched)
                    return false
                notMatched = treeNodeIDs.find((w) => { return !wordNodeIDs.includes(w) })
                return !notMatched
            }
            const getNodePathPromises = () => {
                console.log('getting node fetches')
                nodePathBuffer.value = []
                let promises = []
                for (let i = 0; i < model.value.length; i++) {
                    promises.push(new Promise((resolve, reject) => {
                        console.log('getNodewPathPromises', model.value[i], model.value[i].id, treeID.value)
                        store.dispatch('ais/loadNodePath', { id: model.value[i].id, treeID: treeID.value })
                            .then(result => {
                                if (result) {
                                    nodePathBuffer.value.push(result)
                                }
                                resolve()
                            }).catch(error => {
                                reject(error)
                            })
                    }))
                }
                return Promise.all(promises)
            }
            const synchControls = (source) => {
                console.log('synchControls', source)
                if (controlsInSynch())
                    return
                if (source == 'autocomplete') {
                    treeLoading.value = true
                    getNodePathPromises().then(() => {
                        treeLoading.value = false
                        if (nodePathBuffer.value.length > 0) {
                            if (!props.multiple)
                                selectedTreeNode.value = {}

                            nodePathBuffer.value.forEach((result) => {
                                if (result.path) {
                                    result.path.slice(0, result.path.length - 1).forEach((parentID) => {
                                        expandedKeys.value[parentID.toString()] = true
                                    })
                                }
                                selectedTreeNode.value[result.node.key.toString()] = true
                            })
                            scrollToSelected()
                        }
                    }).catch(error => {
                        toast.add({ severity: 'error', summary: 'Error synching controls', detail: error })
                        treeLoading.value = false
                    })
                }
                else if (source == 'tree') {
                    if (selectedTreeNodeIDs.value)
                        setSelectedNode(selectedTreeNodeIDs.value)
                    else
                        autoCompleteWords.value = null
                }
            }
            const scrollToSelected = () => {
                nextTick(() => {
                    var treeWrapper = jQuery('#' + realID.value).find(".p-tree-wrapper")
                    var selectedNode = treeWrapper.find('.p-treenode > .p-highlight')
                    if (selectedNode.length && treeWrapper.length)
                        treeWrapper.scrollTop(treeWrapper.scrollTop() - treeWrapper.offset().top + selectedNode.offset().top)
                })
            }
            const closeTheDropDown = (ev) => {
                if (ev.path && ev.path[0] && ev.path[0].className && String(ev.path[0].className).includes('p-autocomplete'))
                    return
                treeDropdownState.value = 'closed'
            }
            const visibilityChanged = (newVal, entry) => {
                if (!isVisible.value && newVal)
                    initializeControl()
                isVisible.value = newVal
                _.noop(entry)
                //console.log('visibilityChanged', entry)
            }
            const initializeControl = () => {
                console.log('initializeControl', model.value)
                setSelectedNode(model.value.map((entry) => entry.id))
                if (!nodeTree.value.treeID)
                    getTreeChildren('root')
            }

            // lifecycle
            onMounted(() => {
                virtualModel.value = []
                filteredWords.value = []
                selectedTreeNode.value = {}
                expandedKeys.value = {}
                treeLoading.value = false
                treeDropdownState.value = 'closed'
                typedWord.value = ''
                autocompleteWordCount.value = 0
                treeNodeCount.value = 0
                requireTreeSynch.value = true
                isVisible.value = false
                wordBuffer.value = []
                nodePathBuffer.value = []
                realID.value = getID()

                // this needs to be updated in a watch
                autoCompleteWords.value = props.multiple ? virtualModel.value : (virtualModel.value ? virtualModel.value[0] : null)
            })

            return {
                // model
                virtualModel, model,
                // data
                autoCompleteWords, filteredWords, selectedTreeNode, expandedKeys, treeLoading, treeDropdownState, realID,
                typedWord, autocompleteWordCount, treeNodeCount, requireTreeSynch, isVisible, wordBuffer, nodePathBuffer,
                // computed
                iconMap, treeWords, nodeTree, treeMap, treeMapRev,
                treeID, treeLabel, appendTo, nodeTreeRoot, selectedDescription, selectedTreeNodeIDs,
                // methods
                capitalizeFirst, getID, autocompleteFocused, fetchTreeWords, search,
                wordSelected, updateModel, getTreeChildren, getNodeFetchPromises, setSelectedNode, onNodeExpand, onNodeCollapse, onNodeSelect, onNodeUnselect,
                treeNodeBeforeShow, handleDropDownOpenClose, controlsInSynch, getNodePathPromises, synchControls, scrollToSelected, closeTheDropDown, visibilityChanged, initializeControl
            }
        }
    }
</script>

<style lang="scss">
    
    .tree-nonselectable {
        color: palevioletred;
    }
    .tree-multiparent{
        color: dodgerblue;
        font-weight: 700;
    }
    .tree-incomplete {
        color: dimgrey;
        font-weight: 100
    }
    .tree-dropdown {
        height: 300px
    }
    /*
        p-treeselect	Container element.
        p-treeselect-label-container	Container of the label to display selected items.
        p-treeselect-label	Label to display selected items.
        p-treeselect-trigger	Dropdown button.
        p-treeselect-panel	Overlay panel for items.
        p-treeselect-items-wrapper	List container of items.
    */
</style>
