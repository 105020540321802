<template>
        <transition name="fade">
            <section class="tab-panel" :class="{ active: showMe }">
                <slot />
            </section>
        </transition>
</template>

<script>
    import { inject, computed } from 'vue';

    export default {
        props:
        {
            'title': { type: String },
            'show': { type: Boolean, default: true },
            'badge': { type: String, default: null },
        },
        setup(props) {
            const selectedTab = inject('selectedTab')
            const showMe = computed(() => props.title == selectedTab.value && (props.show != undefined ? props.show : true))

            return {
                selectedTab, showMe
            }
        }
    }
</script>

<style lang="scss" scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s ease;
    }

    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
   
</style>