
const state = () => ({
})

// getters
const getters = {   
}

// actions
const actions = {
}

// mutations
const mutations = {
    SET_DASHBOARDGROUP(state, dashboardGroup) {
        if (!dashboardGroup.dashboards) dashboardGroup.dashboards = [];

        dashboardGroup.dashboards.forEach((dash, index) => {
            dash.idx = index
        })

        dashboardGroup.findDashboard = function (id) { return dashboardGroup.dashboards.find(c => c.id == id) }
        state[dashboardGroup.id] = dashboardGroup        
    },
    SET_DASHBOARD(state, dashboard) {
        var dashboardGroup = state[dashboard.ownerID];
        if (dashboardGroup) {
            var dashboards = dashboardGroup.dashboards;

            var index = dashboards.findIndex(c => c.id == dashboard.id);
            if (index != -1) {
                if (dashboard.idx != -1) {
                    dashboard.idx = index;
                    dashboards[index] = dashboard; //update
                }
                else
                    dashboards = dashboards.filter(c => c.id != dashboard.id); //delete
            }
            else if (dashboards.idx != -1) {
                dashboard.idx = dashboards.length;
                dashboards.push(dashboard); //add
            }

            dashboardGroup.dashboards = dashboards;
            state[dashboard.ownerID] = dashboardGroup
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
