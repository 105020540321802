<template>
    <modal v-model="modalIsOpen" :title="title">
        <template #body>
            <p v-html="question">
            </p>
            <div v-if="showTextArea" class="margin-top">
                <label v-if="textLabel">{{ textLabel }}:</label>
                <textarea v-model="model"></textarea>
            </div>
        </template>
        <template #footer>
            <button v-for="(choice, idx) in choicesNormalized" :key="idx" class="button-primary" @click="selected(choice.value)">{{ choice.label }}</button>
            <button v-if="showCancel" class="button-secondary" @click="selected(cancelChoice)">Cancel</button>
        </template>
    </modal>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: 'ChoiceModal',
        emits: ['selected', 'update:modelValue', 'update:isOpen'],
        props: {
            isOpen: { type: Boolean, default: false },
            title: String,
            question: String,
            modelValue: String,
            choices: Array,
            cancelChoice: Object,
            showCancel: Boolean,
            showTextArea: { type: Boolean, default: false },
            textLabel: String
        },
        computed: {
            model: {
                get() { return this.modelValue },
                set(val) { this.$emit('update:modelValue', val) }
            },
            modalIsOpen: {
                get() { return this.isOpen },
                set(val) { this.$emit('update:isOpen', val) }
            },
            choicesNormalized() {
                var result = []
                if (this.choices) {
                    this.choices.forEach((choice) => {
                        if (_.isString(choice))
                            result.push({ 'label': choice, 'value': choice })
                        else if (_.isObject(choice))
                            result.push(choice)
                    })
                }
                else
                    result.push({ 'label': 'Ok', 'value': 'Ok' })

                return result
            }
        },
        methods: {
            showTheModal: function () {
                this.modalIsOpen = true
            },
            selected(val) {
                this.model = val
                this.$emit('selected', val)
                this.modalIsOpen = false
            }
        }
    }
</script>

<style lang="scss">
</style>
