<template>
    <template v-if="lobData">
        <ul class="list-horizontal">
            <template v-if="showOnlyPrimary && thePrimaryLOB">
                <li v-for="node in lobData" :key="node.id" :title="'Filter by LOB ' + node.category">
                    <tag v-if="node.primaryLOB" type="lob" :icon="iconMap[node.category.toLowerCase()]" :class="node.primaryLOB ? 'primary' : ''">
                        <a @click="applyFilter([node.id])"  href="javascript:;">
                            {{ node.name }}
                        </a>
                    </tag>
                </li>
            </template>
            <template v-else>
                <li v-if="filterPrimaryEnabled && thePrimaryLOB" :title="'Filter by ' + (onlyPrimary ? 'All ' : 'Primary ') + (thePrimaryLOB.description ? thePrimaryLOB.description : 'LOB')">
                    <tag type="lob" class='primary' icon="filter" action="custom" @click="applyFilter(thePrimaryLOB.nodes)">
                        {{ onlyPrimary ? 'All' : 'Pri. ' }}
                        <a style="display: none;" @click="applyFilter(thePrimaryLOB.nodes)" href="javascript:;">

                        </a>
                    </tag>
                </li>
                <li v-for="node in lobData" :key="node.id" :title="'Filter by LOB ' + node.category">
                    <tag type="lob" :icon="iconMap[node.category.toLowerCase()]" :class="node.primaryLOB ? 'primary' : ''">
                        <a @click="applyFilter([node.id])"  href="javascript:;">
                            {{ node.name }}
                        </a>
                    </tag>
                </li>
            </template>
        </ul>
    </template>
</template>

<script>
    import { inject, computed } from 'vue'
    import { getRecordTools } from '../../composables/RecordTools.js'
    import _ from 'lodash'
    import { useStore } from 'vuex'

    import Tag from '../ui/tags/Tag.vue'

    export default {
        name: 'LobTags',
        components: {
            Tag
        },
        props: {
            'obj': { type: Object },
            'filterPrimaryEnabled': { type: Boolean, default: true },
            'showOnlyPrimary': { type: Boolean }
        },
        setup(props) {
            const store = useStore()
            const emitter = inject("emitter")   // Inject event bus 
            const { objectType, objectLobs } = getRecordTools(props)

            // computed
            const iconMap = computed(() => store.state.ais.iconMap)
            const lobData = computed(() => {
                var lobTags = null
                if (objectLobs.value) {
                    lobTags = []
                    var lobExists = { attribute: [], subject: [], industry: [], audience: [] }
                    objectLobs.value.forEach((lob) => {
                        if (lob) {
                            if (lob.attribute && lob.attribute.indexOf("No Attribute") < 0 && !lobExists.attribute.includes(lob.attribute)) {
                                lobExists.attribute.push(lob.attribute)
                                lobTags.push({ primaryLOB: lob.primaryLOB, category: 'Attribute', id: lob.attributeID, name: toLOBShortName(lob.attribute) })
                            }
                            if (lob.subject && lob.subject.indexOf("No Subject") < 0 && !lobExists.subject.includes(lob.subject)) {
                                lobExists.subject.push(lob.subject)
                                lobTags.push({ primaryLOB: lob.primaryLOB, category: 'Subject', id: lob.subjectID, name: toLOBShortName(lob.subject) })
                            }
                            if (lob.industry && !lobExists.industry.includes(lob.industry)) {
                                lobExists.industry.push(lob.industry)
                                lobTags.push({ primaryLOB: lob.primaryLOB, category: 'Industry', id: lob.industryID, name: toLOBShortName(lob.industry) })
                            }
                            if (lob.audience && !lobExists.audience.includes(lob.audience)) {
                                lobExists.audience.push(lob.audience)
                                lobTags.push({ primaryLOB: lob.primaryLOB, category: 'Audience', id: lob.audienceID, name: toLOBShortName(lob.audience) })
                            }
                        }
                    })
                }
                return lobTags
            })
            const onlyPrimary = computed(() => objectLobs.value && objectLobs.value.length == 1)
            const thePrimaryLOB = computed(() => {
                var primary = null
                if (objectLobs.value) {
                    var lobP = objectLobs.value.find(lob => lob.primaryLOB)
                    if (lobP) {
                        primary = _.cloneDeep(lobP)
                        primary.nodes = []
                        if (primary.attribute && primary.attribute.indexOf("No Attribute") < 0)
                            primary.nodes.push(primary.attributeID)
                        if (primary.subject && primary.subject.indexOf("No Subject") < 0)
                            primary.nodes.push(primary.subjectID)
                        if (primary.industry)
                            primary.nodes.push(primary.industryID)
                        if (primary.audience)
                            primary.nodes.push(primary.audienceID)
                    }
                }
                return primary
            })

            // methods
            const toLOBShortName = (fullname) => {
                if (fullname.indexOf(':') < 0) return fullname
                return (fullname.substr(fullname.indexOf(':') + 1))
            }
            const applyFilter = (nodeIDs) => {
                console.log('applyFilter-lobtags', nodeIDs)
                if (nodeIDs) {
                    emitter.emit('applyFilter', { filter: 'nodeID', 'filterVal': JSON.stringify(nodeIDs) })
                }
            }
        
            return {
                // computed
                iconMap, objectType, objectLobs, lobData, onlyPrimary, thePrimaryLOB,
                // methods
                toLOBShortName, applyFilter
            }
        }
    }
</script>

<style lang="scss">
    .tag-default{
        margin-right: 3px;
    }
    .primaryLOB{
        color: #fff;
        background-color:#24B76E;
    }
    .primaryLOB a:link {
        color: #fff;
    }

    .primaryLOB a:visited {
        color: #fff;
    }

    .primaryLOB a:hover {
        /*text-decoration: underline;*/
        color: #fff;
    }

    .primaryLOB a:active {
        color: #fff;
    }
    
</style>