<template>
    <div class="manda-chart" ref="chartdiv">
    </div>
</template>

<script>
    import * as am4core from "@amcharts/amcharts4/core"
    import * as am4charts from "@amcharts/amcharts4/charts"
    import am4themes_animated from "@amcharts/amcharts4/themes/animated"
    import chartDefs from "./chartDefs.json";

    am4core.useTheme(am4themes_animated);

    export default {
        name: 'ChartTrends',
        props: {
            chartDefinition: { type: String, default: 'PeStrategicStacked' },
            chartData: { type: Object }
        },
        data: function () {
            return {
                chartData2: null
            }
        },
        computed: {
            chartDataConfig(){
                var config = chartDefs.definitions[this.chartDefinition]
                config.data = this.chartData
                return config
            }
        },
        mounted() {
            let chart = am4core.createFromConfig(this.chartDataConfig, this.$refs.chartdiv, am4charts.XYChart)
            this.chart = chart;
        },
        beforeUnmount() {
            if (this.chart) {
                this.chart.dispose();
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .manda-chart {
        width: 100%;
        height: 500px;
        font-family: Arial, Helvetica, sans-serif;
    }
</style>
