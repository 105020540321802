import { DateTime } from 'luxon'
import _ from 'lodash'

export function getTextTools() {
    const dashboardNameCollision = (name, dashboards) => {
        var dupeName = false;
        if (name && dashboards) {
            dupeName = _.find(dashboards, function (d) {
                return d.name == name
            });
        }

        return dupeName;
    }
    const decorateHighlights = (opt) => {
        var modBody = opt ? opt.body : null
        if (modBody && opt.tokens && _.isArray(opt.tokens) && opt.tokens.length > 0 ) {

            var tokens = opt.tokens;
            var highlightContainer = (opt.highlightContainer ? opt.highlightContainer : "span")
            var highlightClass = (opt.highlightClass ? opt.highlightClass : "text-highlight")

            tokens.forEach((token) => {
                modBody = modBody.replace(new RegExp('(' + token + ')(?!([^<]+)?>)', 'gi'), '<' + highlightContainer + ' class="' + highlightClass + '">$1</' + highlightContainer + '>');
            });
        }
        return modBody
    }
    const ellipseString = (theString, limit, addEllipsis, isHtml) => {
        if (isHtml) {
            var noHtmlString = theString.replace(/(<([^>]+)>)/ig, '');
            var htmlBuffer = theString.length - noHtmlString.length;
            limit = limit + htmlBuffer;
        }

        if (!theString || theString.length <= limit) return theString

        if (theString.lastIndexOf(' ') > 0)
            return theString.substr(0, theString.lastIndexOf(' ', limit)) + (addEllipsis ? "..." : "")
        return theString.substr(0, limit) + (addEllipsis ? "..." : "")
    }
    const toShortDateString = (theDate) => {
        try {
            var dt = toLuxonDate(theDate)
            if (dt && dt.isValid)
                return dt.toLocaleString(DateTime.DATE_SIMPLE)//.replace(', 12:00:00 AM', '')
        }
        catch (error) {
            console.error(error);
        }
        return null
    }
    const toShortTimeString = (theDate) => {
        try {
            var dt = toLuxonDate(theDate)
            if (dt && dt.isValid)
                return dt.toLocaleString(DateTime.TIME_SIMPLE)
        }
        catch (error) {
            console.error(error);
        }
        return null
    }
    const toYear = (theDate) => {
        try {
            var dt = toLuxonDate(theDate)
            if (dt && dt.isValid)
                return dt.year
        }
        catch (error) {
            console.error(error);
        }
        return null
    }
    const luxonDate = (date) => {
        var convDate = convDate = date ? DateTime.fromJSDate(new Date(date)).toFormat('yyyy-LL-dd') : DateTime.now().toFormat('yyyy-LL-dd')
        return convDate;
    }
    const toLuxonDate = (theDate) => {
        var result = null
        switch (typeof theDate) {
            case 'string':
                result = DateTime.fromISO(theDate)
                break
            case 'object':
                result = DateTime.fromJSDate(theDate)
                break
        }
        //console.log(result.invalidReason)

        return result;
    }
    const sinceDate = (theDate) => {
        try {
            var dt = toLuxonDate(theDate)
            if (dt && dt.isValid)
                return dt.toRelative({ base: DateTime.local() })
        }
        catch (error) {
            console.error(error);
        }
        return null
    }
    const toEventDate = (theDate) => {
        try {
            var dt = toLuxonDate(theDate)
            if (dt && dt.isValid) {
                if (dt.hasSame(DateTime.now(), 'day')) {
                    return dt.toLocaleString({
                        hour: 'numeric',
                        minute: 'numeric'
                    })
                } else if (dt.hasSame(DateTime.now(), 'year')) {
                    return dt.toLocaleString({
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    });
                }
                return dt.toLocaleString({ // is different year
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                })
            }
            else
                return dt.invalidReason
        }
        catch (error) {
            console.error(error)
        }
        return null
    }
    const toEventDateMobile = (theDate) => {
        try {
            var dt = toLuxonDate(theDate)
            if (dt && dt.isValid) {
                if (dt.hasSame(DateTime.now(), 'day')) {
                    return dt.toLocaleString({
                        hour: 'numeric',
                        minute: 'numeric'
                    })
                } else if (dt.hasSame(DateTime.now(), 'year')) {
                    return dt.toLocaleString({
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    });
                }
                return dt.toLocaleString({
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                })
            }
            else
                return dt.invalidReason
        }
        catch (error) {
            console.error(error)
        }
        return null
    }
    const capitalizeFirst = (text) => {
        return (text ? text.charAt(0).toUpperCase() + text.slice(1) : "")
    }
    const fromCamelCase = (theString) => {
        var result = theString;
        if (theString !== undefined && theString != null && theString.length > 0) {
            result = theString.replace(/([A-Z])/g, " $1")
            result = result.charAt(0).toUpperCase() + result.slice(1) // capitalize the first letter - as an example.
        }
        return result
    }
    const userInitials = (userName) => {
        return (!userName ? null : (userName.indexOf('.') > 0 ? userName[0] + userName.split(".")[1][0] : (userName[2] ? userName[0] + userName[2] : userName.substring(0, 3))).toUpperCase())
    }
    const numDigits = (x) => {
        return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1
    }
    const scoreClass = (factor) => {
        var rtClass = 'danger'

        if (factor > 0.79)
            rtClass = 'success'
        else if (factor > 0.59)
            rtClass = 'info'
        else if (factor > 0.39)
            rtClass = 'warning'

        return rtClass
    }
    const changeIsSignificant = (newVal, oldVal, delta = 0.0001) => {
        return (Math.abs(newVal - oldVal) / oldVal) > delta
    }
    const formatMillions = (amt) => {
        var result = amt
        if (amt >= 1000000000.0)
            result = (amt / 1000000000.0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'B'
        else if (amt >= 1000000.0)
            result = (amt / 1000000.0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'M'
        else
            result = (amt).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return result
    }

    return {
        decorateHighlights, ellipseString,
        toShortDateString, toShortTimeString, toYear, luxonDate, toLuxonDate, sinceDate, toEventDate, toEventDateMobile,
        capitalizeFirst, fromCamelCase, userInitials, numDigits, scoreClass,
        changeIsSignificant, formatMillions, dashboardNameCollision
    }
}