import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState({
            user: state => state.userInfo.user.userInfo,
            baseMaps: state => state.search.searchMaps,
            searchMaps: state => state.search.searchMaps.maps,
            dateRangeMaps: state => state.search.searchMaps.dateRangeMaps
        }),
        ...mapGetters({
            aggPanels: "search/aggPanels",
            graphAggs: "search/graphAggs",
            significantTermAggs: 'search/significantTermAggs'
        }),
        objectIDToObjectTypeMap() {
            return this.searchMaps && this.searchMaps.ownerTypeMap ? this.searchMaps.ownerTypeMap : { }
        },
        objectTypeToObjectIDMap() {
            return this.searchMaps && this.searchMaps.ownerTypeRevMap ? this.searchMaps.ownerTypeRevMap : {}
        }
    },
    methods: {
        updateUserName(usr) {
            if (usr && usr.id) {
                usr.name = this.searchMaps &&  this.searchMaps.users[usr.id] && this.searchMaps.users[usr.id] ? this.searchMaps.users[usr.id] : usr.name
            }
            return usr
        },
        getUserName(usr) {
            if (usr && usr.id) {
                console.log('getUserName', this.searchMaps &&  this.searchMaps.users[usr.id], usr.name)
                return this.searchMaps && this.searchMaps.users[usr.id] && this.searchMaps.users[usr.id] ? this.searchMaps.users[usr.id] : usr.name
            }
            return usr.name 
        },
        encodeOwnerKey(ownerKey) {
            return ownerKey.replace('::', "__")
        },
        decodeOwnerKey(ownerKey) {
            return ownerKey.replace("__", '::')
        }
    }
}