import { computed, getCurrentInstance, ref } from 'vue'
export function useModelWrapper(props, emit, name = 'modelValue', sideEffect = null, logging = false) {
    return computed({
        get: () => props[name],
        set: (value) => {
            if (sideEffect)
                sideEffect(value)
            if (logging)
                console.log('update Model Wrapper', name, value)
            emit(`update:${name}`, value)
        }
    })
}
//this can't be used with Boolean models normally - have to set the Boolean prop's default value to undefined in the component
export function useModelVirtualWrapper(props, emit, name = 'modelValue', virtualValue = null, sideEffect = null, logging = false) {
    return computed({
        get: () => props[name] != undefined ? props[name] : (virtualValue != null ? virtualValue.value : null),
        set: (value) => {
            if (virtualValue != null)
                virtualValue.value = value
            if (sideEffect)
                sideEffect(value)
            if (logging)
                console.log('update Model Wrapper Virtual', name, value, virtualValue)
            emit(`update:${name}`, value)
        }
    })
}
export function useToast() {
    const internalInstance = getCurrentInstance()
    const toast = internalInstance.appContext.config.globalProperties.$toast
    return toast
}
export function useItemRefs() {
    const itemRefs = ref({})

    // sample usage (don't forget the ':' before the ref)
    //   :ref="(el)=>setItemRef(el,'mainTabs')"
    const setItemRef = (el, name) => {
        if (el && name) {
            itemRefs.value[name] = el
            // console.log('setItemRef', el, name, itemRefs.value)
        }
    }
    const itemRefsReset = () => {
        itemRefs.value = {}
    }

    return { itemRefs, setItemRef, itemRefsReset }
}