<template>
    <template v-if="isMobile">
        <menu-item :label="'See Cart: ' + countMessage + ' items'" @click="openBookmarkSidebar" :level="level" />
    </template>
    <template v-else>
        <div v-if="user" class="bookmarks-bucket is-active" @click="openBookmarkSidebar">
            <div class="bookmark-toggle is-active">
                <i class="far fa-bookmark"></i>
            </div>
            <div class="bookmarks-count">{{ countMessage }}</div>
        </div>
    </template>
    <bookmarks-sidebar ref="bookmarkSidebar" :fetching="loading"></bookmarks-sidebar>
</template>
    <script>
        import { mapState } from 'vuex'
        import MenuItem from '../ui/menus/MenuItem.vue'
        import BookmarksSidebar from './BookmarksSidebar.vue'

        export default {
            name: 'bookmarksMenu',
            emits: ['open-cart'],
            components: {
                MenuItem, BookmarksSidebar
            },
            props: {
                level: { type: Number, default: 1 }
            },
            data: function () {
                return {
                    loading: false,
                }
            },
            computed: {
                ...mapState({
                    listID: state => state.userInfo.user.userInfo.activeFlagListID,
                    user: state => state.userInfo.user,
                    bookmarks: state => state.bookmarks.bookmarks
                }),
                bookmarkMembers: function () {
                    return this.bookmarks && this.bookmarks.members ? this.bookmarks.members : []
                },
                countMessage: function () {
                    if (!this.bookmarks.fetched) return '?';

                    return this.bookmarkMembers.length <= 9 ? this.bookmarkMembers.length : "9+"
                },
            },
            methods: {
                openBookmarkSidebar: function () {
                    this.$refs.bookmarkSidebar.openPanel();
                    this.$refs.bookmarkSidebar.resetState();

                    this.fetchBookmarkList();
                },
                fetchBookmarkList: function () {
                    var me = this;
                    me.loading = true;
                    me.$store.dispatch('bookmarks/fetchBookmarks', { id: this.listID, keepStore: true }).then(() => {
                        me.loading = false;

                    }).catch(error => {
                        me.loading = false;
                        me.$toast.add({ severity: 'error', summary: 'Error fetching bookmarks', detail: error, life: 3000 })
                    })

                },
            }
        
        };
    </script>

    <style scoped lang="scss">
    </style>
