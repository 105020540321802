<template>
  <section class="accordion" :class="[optionsClasses,size]">
    <slot />
  </section>
</template>

<script>
    export default {
        name: 'Accordion',
        props: {
            options: {
                Type: String
            },
            size: {
                Type: String
            },
        },
        computed: {
            optionsClasses() {
                if (!this.options) {
                    return 'single-box'
                } else {
                    return this.options
                }
            }
        }
    }
</script>