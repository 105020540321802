<template>
    <teleport to=".main-wrapper">
        <transition name="fade">
            <aside class="inner-sidebar is-open" v-show="isShowing">
                <transition name="slide" appear>
                    <section class="sidebar-wrapper">
                        <sidebar-header v-on:close-panel="closeSidepanel" :title="title" :showNavigator="showNavigator" @navigate="requestNavigate" @titleclick="$emit('titleclick',$event)" />
                        <sidebar-body>
                            <template #sidebar-body>
                                <slot name="body" />
                            </template>
                        </sidebar-body>
                        <sidebar-footer v-on:close-panel="closeSidepanel" v-if="footer" :actions="actions" :showCancel="showFooterCancel">
                            <template #sidebar-footer>
                                <slot name="footer" />
                            </template>
                        </sidebar-footer>
                    </section>
                </transition>
            </aside>
        </transition>
    </teleport>
</template>

<script>
    import SidebarHeader from "./SidebarHeader.vue"
    import SidebarBody from "./SidebarBody.vue"
    import SidebarFooter from "./SidebarFooter.vue"
    export default {
        name: 'OuterSidebar',
        props: {
            title: String,
            footer: {
                type: Boolean,
                default: true
            },
            actions: {
                type: Boolean,
                default: true
            },
            showNavigator: {
                type: Boolean,
                default: false
            },
            showFooterCancel: {
                type: Boolean,
                default: false
            }
        },
        emits: ['navigate', 'titleclick'],
        components: {
            SidebarHeader, SidebarBody, SidebarFooter
        },
        data() {
            return {
                isShowing: false
            };
        },
        methods: {
            openInnerSidebar: function () {
                this.isShowing = true;
            },
            closeInnerSidebar: function () {
                this.isShowing = false
            },
            requestNavigate(direction) {
                this.$emit('navigate', direction)
            }
        }
    }</script>

<style lang="scss" scoped>
    
</style>