<template>
    <section class="pager">
        <div class="page-view">
            <select style="width: 130px;" :value="limit" @change="pageSelected('limit', $event.target.value)">
                <option v-for="choice in pageChoices" :key="choice" :value="choice" >{{ pageChoiceLabel(choice) }}</option>
            </select>
        </div>
        <div class="page-selection">
            <ul class="menu-horizontal pagination">
                <li class="page previous pagerPage" @click="pageSelected('skip', Math.max(0, skip-limit))">
                    <i class="fas fa-chevron-left"></i>
                </li>
                <li v-for="iPage in pages" :key="iPage" class="page" :class="(skip==iPage*limit ? 'pagerCurrent active': 'pagerPage')" @click="pageSelected('skip', iPage*limit)">
                    {{ (iPage + 1) }}
                </li>
                <li class="page next pagerPage" @click="pageSelected('skip', Math.min((pageCount-1)*limit, skip+limit))">
                    <i class="fas fa-chevron-right"></i>
                </li>
            </ul>
        </div>
    </section>
</template>
<script>

    import { ref, computed, onMounted, onBeforeMount } from 'vue'
    import { getLocalStorageTools } from '../../../composables/LocalStorageTools.js'
    import { useModelWrapper } from '../../../composables/ModelWrapper.js'

    export default {
        name: 'Pager',
        props: {
            'name': String,
            'modelValue': Object,
            'total': { type: Number, default: 0 },
            'maxPageChoices': { type: Number, default: 10 },
            'canChange': { type: Boolean, default: true },
            'infinityScroll': { type: Boolean, default: false }
        },
        emits: ['update:modelValue', 'pagechange'],
        setup(props, { emit }) {
            // const
            const defaultValue = { 'limit': 30, 'skip': 0 }
            const defaultPageChoices = [15, 30, 50, 100]
            // model
            const model = useModelWrapper(props, emit)

            // data
            const pageChoices = ref(defaultPageChoices)

            // computed
            const skip = computed(() => model.value ? model.value.skip : 0)
            const limit = computed(() => model.value ? model.value.limit : defaultValue.limit)
            const currentPage = computed(() => skip.value / limit.value)
            const pageCount = computed(() => Math.floor(props.total / limit.value) + (props.total % limit.value != 0 ? 1 : 0))
            const pageStart = computed(() => Math.max(0, currentPage.value - Math.floor(props.maxPageChoices / 2)))
            const pageEnd = computed(() => Math.min(pageCount.value, pageStart.value + props.maxPageChoices))
            const pages = computed(() => {
                var pages = []
                for (var i = pageStart.value; i < pageEnd.value; i++) { pages.push(i) }
                return pages
            })

            // methods
            const { saveLocal, fetchLocal } = getLocalStorageTools()
            const pageSelected = (type, value) => {
                if (props.canChange) {
                    if (type == 'limit') model.value.limit = parseInt(value)
                    if (type == 'skip') model.value.skip = value
                    if (props.name) saveLocal('pager_' + props.name, limit.value)

                    emit('pagechanged', { skip: skip.value, limit: limit.value })
                }
            }
            const pageChoiceLabel = (pageSize) => {
                return pageSize > 0 ? pageSize + ' Entries' : 'Infinite Scroll'
            }


            // lifecycle
            onBeforeMount(() => {
                if (model.value && props.name) {
                    model.value.limit = fetchLocal('pager_' + props.name, defaultValue.limit)
                }
            })
            onMounted(() => {
                var pgChoices = defaultPageChoices
                if (!pgChoices.includes(model.value.limit)) {
                    pgChoices.push(model.value.limit)
                    pgChoices.sort(function (a, b) { return a - b })
                }
                /*if (props.infinityScroll)
                    pgChoices.push(0)
                pageChoices.value = pgChoices*/
            })

            return {
                // const
                defaultValue, defaultPageChoices,
                // model
                model,
                // data
                pageChoices,
                // computed
                skip, limit, currentPage, pageCount, pageStart, pageEnd, pages,
                // methods
                pageSelected, pageChoiceLabel, saveLocal, fetchLocal
            }
        }
    }
</script>

<style scoped lang="scss">
    
</style>