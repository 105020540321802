<template>
    <h1 class="margin-top margin-left"><b style="color: #415FFF;">404</b> Page not found</h1>
    <span class="margin-left">Go back to the <a href="/">homepage</a> or try opening the page on the <a :href="'https://cmdm.berkerynoyes.com' + page">CMDM site</a>.</span>
</template>

<script>
    export default {
        name: 'Not Found',
        props: {
            'page': { type: String }
        },
        data: function () {
            return { }
        },
        mounted() {
            document.title = 'MandAsoft - Page Not Found'
        }
    }
</script>

<style scoped lang="scss">

</style>
