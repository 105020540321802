<template>
    <section class="sidebar-header">
        <div class="sidebar-title">
            <h3 v-if="title" @click="$emit('titleclick')">{{ title }}</h3>
            <slot />
        </div>
        <!--<div style="order:1">
        <i style="cursor:pointer" class="margin-left-xsmall margin-right-xsmall fa fa-edit" @click="$emit('titleeditclick')"></i>
    </div>-->
        <div class="sidebar-navigator">
            <template v-if="showNavigator">
                <span class="navigate-back" @click="requestNavigate('back')">
                    <i class="far fa-arrow-left"></i>
                </span>
                <span class="navigate-forwar" @click="requestNavigate('forward')">
                    <i class="far fa-arrow-right"></i>
                </span>
            </template>
        </div>
        <div @click="$emit('close-panel', $event)" class="sidebar-close">
            <i class="far fa-times"></i>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'SidebarHeader',
        props: {
            title: String,
            showNavigator: {
                type: Boolean,
                default: false
            }
        },
        emits: ['navigate', 'titleclick', 'titleeditclick', 'close-panel'],
        components: {

        },
        data() {
            return {

            };
        },
        methods: {
            requestNavigate(direction) {
                this.$emit('navigate', direction)
            }
        }
    }
</script>

<style lang="scss" scoped>
   h3 {
        cursor: pointer;
    }
</style>