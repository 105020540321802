<template>
    <ul v-if="checkboxValues" :class="!showAsBox ? 'list-options' : ''">
        <li v-for="(item, idx) in checkboxValues" :key="idx">
            <div :class="checkboxClasses">
                <label v-if="showAsSwitch">{{ item.label }}</label>
                <input type="checkbox" :id="item.id" :value="item.value" v-model="checkedNames" @change="changeHandler(item)"/>
                <label :for="item.id">
                    <template v-if="item.label">
                        {{ (!showAsSwitch ? item.label : '') }}
                    </template>
                </label>
                <a v-if="item.url" :href="item.url" target="_blank"><i class="fa fa-external-link text-success"></i></a>
            </div>
        </li>
    </ul>   
</template>

<script>
    import { computed } from 'vue'
    import { useModelWrapper } from '../../../composables/ModelWrapper.js'
    import { getID } from '../../../composables/Editors.js'

    export default {
        name: 'CheckboxList',
        emits: ['update:modelValue'],
        props: {
            labelField: String,
            idField: String,
            selectField: String,
            size: { type: String, default: 'default' },
            modelValue: Array,
            multiple: { type: Boolean, default: true },
            showAsBox: { type: Boolean, default: false },
            showAsSwitch: { type: Boolean, default: false }
        },
        setup(props, { emit }) {
            // model
            const model = useModelWrapper(props, emit)

            // data 
            //const checkedNames = ref([])
            //const settingCheckedNames = ref(false)           

            // computed
            const checkedNames = computed({
                get: () => {
                    var items = checkboxValues.value.filter((item) => { return item.checked })
                    return items.map((item) => item.value)
                },
                set: (newVal) => {
                    var newModel = model.value

                    newModel.forEach((item, idx) => {
                        newModel[idx][props.selectField] = newVal.includes(item[props.idField])
                    })
                    model.value = newModel
                }
            })
            const checkboxClasses = computed(() => ( !props.showAsSwitch ? ('checkbox ' + (props.size !== 'default' ? props.size + ' ' : '') + (props.showAsBox ? 'style-box' : '' )) : 'input-switch place-right'))
            const checkboxValues = computed(() => {
                if (model.value) {
                    var i = 0
                    if (props.idField && props.labelField && props.selectField && model.value) {
                        return model.value.map((item) => {
                            return {
                                label: item[props.labelField],
                                value: item[props.idField],
                                id: getID(i++),
                                url: item.url,
                                checked: item[props.selectField]
                            }
                        })
                    }
                    return model.value.map((item) => {
                        return {
                            label: item,
                            value: item,
                            id: getID(i++),
                            url: null,
                            checked: false
                        }
                    })
                }
                return []
            })
            const changeHandler = (selItem) => {
                if (!props.multiple) {
                    model.value.forEach((item) => {
                        if (selItem.value != item[props.idField]) item[props.selectField] = false
                    })
                }
            }

            // methods
       /*     const setCheckedNames = () => {
                var items = checkboxValues.value.filter((item) => { return item.checked })
                //console.log('setCheckedNames', items)
                settingCheckedNames.value = true
                checkedNames.value = items.map((item) => item.value)
                settingCheckedNames.value = false
            }

            // watches
            watch(checkedNames, (newVal) => {
                if (settingCheckedNames.value) return
                model.value.forEach((item, idx) => {
                    model.value[idx][props.selectField] = newVal.includes(item[props.idField])
                })
                emit('update:modelValue', model.value)
            })
            watch(checkboxValues, () => {
                setCheckedNames()
            }, { deep: true })

            // lifecycle
            onMounted(() => {
                checkedNames.value = []
                settingCheckedNames.value = false
                setCheckedNames()
            })*/

            return {
                // model
                model,
                // computed
                checkedNames, checkboxClasses, checkboxValues, changeHandler
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>