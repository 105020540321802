import { createStore } from 'vuex'
import axios from 'axios'
import _ from 'lodash'

import acquisitions from './modules/acquisitions'
import ais from './modules/ais'
import bookmarks from './modules/bookmarks'
import comments from './modules/comments'
import emails from './modules/emails'
import dashboards from './modules/dashboards'
import dashboardGroups from './modules/dashboardGroups'
import eventLog from './modules/eventLog'
import financials from './modules/financials'
import flags from './modules/flags'
import letterwizard from './modules/letterwizard'
import lists from './modules/lists'
import objectCache from './modules/objectCache'
import search from './modules/search'
import userInfo from './modules/userInfo'

import { eventDataPrep } from './plugins/eventData.js'
import { localStoragePlugin } from './plugins/localStorage.js'

//import createLogger from '../../../src/plugins/logger'

const debug = process.env.NODE_ENV !== 'production'

const mvcService = axios.create({ baseURL: '/' } )

export
    default createStore({
        actions: {
            initializeStores({ commit }) {
                return new Promise((resolve, reject) => {
                    try {
                        commit('userInfo/INITIALIZE_STORE')
                        commit('search/INITIALIZE_STORE')
                        commit('financials/INITIALIZE_STORE')
                        commit('ais/INITIALIZE_STORE')
                        commit('letterwizard/INITIALIZE_STORE')
                        commit('lists/INITIALIZE_STORE')
                        resolve()
                    }
                    catch (error) { reject(error) }
                })
            },
            destroyStores({ commit }) {
                return new Promise((resolve, reject) => {
                    try {
                        commit('userInfo/DESTROY_STORE')
                        commit('search/DESTROY_STORE')
                        commit('financials/DESTROY_STORE')
                        commit('ais/DESTROY_STORE')
                        commit('letterwizard/DESTROY_STORE')
                        commit('lists/DESTROY_STORE')
                    }
                    catch (error) { reject(error) }
                })
            },
            reloadDotNetCache({ dispatch }) {
                axios.post('account/ReloadCache')
                    .then(r => r.data)
                    .then((response) => {
                        if (response && response.success) {
                            dispatch('destroyStores')
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            loadUserDashboards({ state, commit }) {
                return new Promise((resolve, reject) => {
                    if (state.userInfo && state.userInfo.user && state.userInfo.user.userInfo && state.userInfo.user.userInfo.id) {
                        axios.get('dashboard/Group/User/' + state.userInfo.user.userInfo.id)
                            .then(r => r.data)
                            .then(dashboardGroup => {
                                commit('dashboardGroups/SET_DASHBOARDGROUP', dashboardGroup)
                                dashboardGroup.dashboards.forEach((dash) => {
                                    commit('dashboards/SET_DASHBOARD', dash)
                                })
                                commit('userInfo/SET_DASHBOARDGROUPID', dashboardGroup.id)
                                resolve()
                            })
                    }
                    else {
                        commit('userInfo/SET_DASHBOARDGROUPID', null)
                        reject('user not logged in')
                    }
                })
            },
            reAuthorize({ state }) {
                return new Promise((resolve, reject) => {
                    mvcService.get('comment/index').then(r => {
                        _.noop(state)
                        console.log('reauthorize', r)
                        resolve(r)
                    }).catch(function (error) {
                        reject(error)
                    })
                })
            }
        },
        modules: {
            acquisitions,
            ais,
            bookmarks,
            comments,
            emails,
            dashboards,
            dashboardGroups,
            eventLog,
            financials,
            flags,
            letterwizard,
            lists,
            objectCache,
            search,
            userInfo
        },
        namespaced: true,
        strict: debug,
        plugins: [eventDataPrep, localStoragePlugin] // debug ? [createLogger()] : []
    })
    