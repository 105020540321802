<template>
    <section :class="tabsClass">
        <div class="tabs-navigator">
            <div ref="prevSlide" v-if="previousVisible" class="previous" @click="slide('previous')">
                <i class="fas fa-chevron-left"></i>
            </div>
            <div ref="tabsNavigator" :class="{'tabs-slider-holder': enableMobileSlider }" v-on:scroll="scrollHandler">
                <ul class="menu-horizontal tabs" :class="{'tabs-slider': enableMobileSlider }">
                    <template v-for="title in tabTitles" :key="title">
                        <li v-show="visibleTabs && visibleTabs[title]" class="menu-tab" @click="selectedTab = title">
                            <a @click.prevent="" :class="{ active: title == selectedTab }">
                                {{ title }}
                                <span v-if="typeof badges[title] != 'undefined'" class="badge">{{ badges[title] }}</span>
                            </a>
                        </li>
                    </template>
                </ul>
            </div>
            <div v-if="nextVisible" class="next" @click="slide('next')">
                <i class="fas fa-chevron-right"></i>
            </div>
        </div>
        <section class="tabs-panels">
            <slot />
        </section>
    </section>
</template>

<script>
    import { ref, provide, computed, onMounted, nextTick } from 'vue'
    import _ from 'lodash'
    import { useModelVirtualWrapper } from '../../../composables/ModelWrapper.js'

    export default {
        props: {
            modelValue: { type: String },
            prevSelectedTab: { type: String },
            shownTabs: { type: Object },
            name: { Type: String },
            options: { Type: String },
            enableMobileSlider: { Type: Boolean, default: false },
            firstActiveTab: { Type: String } // Obsolete
        },
        emits: ['update:modelValue', 'update:prevSelectedTab', 'update:shownTabs'],
        setup(props, { slots, emit }) {

            // data
            const virtualValue = ref(null)
            const virtualPrevValue = ref(null)
            const virtualShownTabs = ref(null)

            // model
            var selectedTab = useModelVirtualWrapper(props, emit, 'modelValue', virtualValue, (newVal) => {
                prevSelectedTabModel.value = newVal
            })
            var prevSelectedTabModel = useModelVirtualWrapper(props, emit, 'prevSelectedTab', virtualPrevValue)
            var shownTabsModel = useModelVirtualWrapper(props, emit, 'shownTabs', virtualShownTabs)

            // computed
            const tabTitles = computed(() => {
                var tabs = slots.default().map((tab) => tab && tab.props ? tab.props.title : null)
                return tabs.filter((t) => t != null)
            })
            const arrTabs = computed(() => {
                var tabs = slots.default().map((tab) => {
                    return tab && tab.props ? {
                        title: tab.props.title,
                        shown: tab.props.show != undefined ? tab.props.show : true,
                        badge: tab.props.badge
                    } : null
                })
                return tabs.filter((t) => t != null)
            })
            const visibleTabs = computed(() => {
                var st = _.chain(arrTabs.value)
                    .keyBy('title')
                    .mapValues('shown')
                    .value()
                return st
            })
            const renderedTabsSize = computed(() => {
                var tabs = 0;
                _.each(tabTitles.value, function (title) {
                    if (visibleTabs.value && visibleTabs.value[title])
                        tabs++;
                })
                return tabs;
            })

            //refs for the nav elements && their sizes

            const tabsNavigator = ref(null)
            const prevSlide = ref(null)

            const showPrev = ref(false);
            const showNext = ref(false);

            const previousVisible = computed(() => props.enableMobileSlider && showPrev.value)
            const nextVisible = computed(() => props.enableMobileSlider && showNext.value)

            // methods
            const slide = (direction) => {
                //move 60% of the total size with each slide

                var slider = tabsNavigator.value.clientWidth * 0.6;
                if (direction == 'previous')
                    tabsNavigator.value.scrollBy(-slider, 0);
                    //tabsNavigator.value.scrollBy({ left: -slider, top: 0, behavior: 'smooth' });
                else if(direction == 'next')
                    tabsNavigator.value.scrollBy(slider, 0);
                   // tabsNavigator.value.scrollBy({ left: slider, top: 0, behavior: 'smooth' });
            }
            const scrollHandler = () => {
                //showPrev
                if (!tabsNavigator.value.scrollLeft)
                    showPrev.value = false;
                else
                    showPrev.value = true;

                //showNext
                nextTick(() => {
                    var prevSize = prevSlide.value ? prevSlide.value.clientWidth : 0;
                    if (tabsNavigator.value.scrollWidth == tabsNavigator.value.clientWidth || tabsNavigator.value.scrollWidth - tabsNavigator.value.clientWidth < tabsNavigator.value.scrollLeft + prevSize)
                        showNext.value = false;
                    else
                        showNext.value = true;
                })
            }

            // obsolete
            const selectTab = (title) => {
                if (tabTitles.value.includes(title)) {
                    prevSelectedTabModel.value = selectedTab.value
                    selectedTab.value = title
                }
            }

            provide('selectedTab', selectedTab)

            onMounted(() => {
                var _initialTabIdx = (props.firstActiveTab && tabTitles.value.includes(props.firstActiveTab)
                    ? tabTitles.value.indexOf(props.firstActiveTab) : 0)

                virtualValue.value = tabTitles.value[_initialTabIdx]
                virtualPrevValue.value = null
                virtualShownTabs.value = visibleTabs.value

                scrollHandler();
            })

            return {
                // model
                selectedTab, prevSelectedTabModel, shownTabsModel,
                // data
                virtualValue, virtualPrevValue, virtualShownTabs,
                tabsNavigator, prevSlide, scrollHandler,
                // computed
                tabTitles, arrTabs, visibleTabs, renderedTabsSize, previousVisible, nextVisible,
                // methods
                slide, selectTab
            }
        },
        watch: {
            "renderedTabsSize": {
                handler() {
                    this.scrollHandler();
                }
            },
            "visibleTabs": {
                handler(val) {
                    this.shownTabsModel = val
                },
                deep: true
            }
        },
        computed: {
            badges() {
                var bd = _.chain(this.arrTabs)
                    .keyBy('title')
                    .mapValues('badge')
                    .value()
                return bd
            },
            optionsClass() {
                return ['boxtabs', 'boxed', 'searchpage'].includes(this.options) ? 'boxed' : ''
            },
            tabsClass() {
                return ["tabs", this.optionsClass, this.$attrs.class, this.name].join(' ')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .badge {
        margin-left: 3px;
        display: inline-block;
        padding: 0 5px;
        border-radius: 3px;
        background-color: #EAEEFF;
        font-size: 14px;
        font-weight: 500;
        color: #415FFF;
    }
    .tabs-slider-holder {
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        overflow-y: hidden;
        display: flex;

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .tabs-slider-holder::-webkit-scrollbar {
        display: none;
    }

    .tabs-slider {
        flex-wrap: nowrap;
        position: relative;
        justify-content: flex-start;
    }
    .menu-horizontal.tabs.tabs-slider > li > a {
        white-space: nowrap;
    }
</style>