<template>
    <section class="sidebar-body">
        <slot name="sidebar-body" />
    </section>
</template>

<script>
    export default {
      name: 'SidebarBody',
      props: {

      },
      components: {

      },
      data() {
        return {

        };
      },
    };
</script>

<style lang="scss" scoped>
</style>