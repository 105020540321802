import axios from 'axios'
import { DateTime } from 'luxon'
import _ from 'lodash'

const initialTermSearch = {
    ultimateParent: { url: 'Company/UltimateParentSuggestions?', results: {} },//term=%QUERY&Limit=10',
    city: { url: 'Person/CitySuggestions?', results: {} },
    stateProv: { url: 'Person/StateProvSuggestions?', results: {} },
    country: { url: 'Person/CountrySuggestions?', results: {} },
    lists: { url: 'List/masterListSuggestions?', results: {} },
    subsets: { url: 'List/subsetSuggestions?', results: {} },
    phrase: { url: 'Search/QueryTermSuggestions?field=phrase&', results: {} },
    mustMatch: { url: 'Search/QueryTermSuggestions?field=mustMatch&', results: {} },
    shouldMatch: { url: 'Search/QueryTermSuggestions?field=shouldMatch&', results: {} },
    mustNotMatch: { url: 'Search/QueryTermSuggestions?field=mustNotMatch&', results: {} }
}

const state = () => ({
    searchHistory: {},
    resultsSets: {},
    searchMaps: {},
    searchDefinitions: {},
    navigatorResults: [],
    eventTypeRenderMap: {},
    resultsViewMode: 'details',
    termSearches: initialTermSearch,
    hitLimit: 30,
    distances: [{ id: '5mi', key: '5mi', entry: '5 Miles', count: 0, selected: true }, { id: '15mi', key: '15mi', entry: '15 Miles', count: 0, selected: false },{ id: '30mi', key: '30mi', entry: '30 Miles', count: 0, selected: false }, { id: '50mi', key: '50mi', entry: '50 Miles', count: 0, selected: false }],
    virtualFilterNames: ['term', 'nodeID']
})


// getters
const getters = {
    aggPanels(state) {
        return state.searchMaps && state.searchMaps.aggPanelDefs ? Object.keys(state.searchMaps.aggPanelDefs).map((key) => key) : null
    },
    graphAggs(state) {
        return state.searchMaps && state.searchMaps.aggDefs ? Object.keys(state.searchMaps.aggDefs).map((key) => key) : null
    },
    searchMaps(state) { return state.searchMaps ? state.searchMaps.maps : null },
    dateRangeMap(state) { return state.searchMaps && state.searchMaps.dateRangeMaps ? state.searchMaps.dateRangeMaps.all : null },
    dateRanges(state) {
        return state.searchMaps && state.searchMaps.dateRangeMaps && state.searchMaps.dateRangeMaps.all ?
            Object.keys(state.searchMaps.dateRangeMaps.all).map((key) => state.searchMaps.dateRangeMaps.all[key])
            : null
    },
    dateRangeNames(state) {
        return state.searchMaps && state.searchMaps.dateRangeMaps && state.searchMaps.dateRangeMaps.all ?
            Object.keys(state.searchMaps.dateRangeMaps.all).map((key) => state.searchMaps.dateRangeMaps.all[key].description)
            : null
    },
    dateFilters(state) { return state.searchMaps && state.searchMaps.maps ? state.searchMaps.maps.dateFilters : [] },
    cardDisplayTypes(state) { return state.searchMaps && state.searchMaps.maps && state.searchMaps.maps.cardDisplayTypes ? state.searchMaps.maps.cardDisplayTypes : [] },
    docTypes(state) { return state.searchMaps && state.searchMaps.maps ? state.searchMaps.maps.docTypes : null },
    objectTypeIdToStringMap(state) { return state.searchMaps && state.searchMaps.maps ? state.searchMaps.maps.ownerTypeMap : null },
    objectTypeStringToIdMap(state) { return state.searchMaps && state.searchMaps.maps ? state.searchMaps.maps.ownerTypeRevMap : null },
    docTypeIds(state) { return state.searchMaps && state.searchMaps.maps ? state.searchMaps.maps.docTypeIds : null},
    docTypeDescriptions(state) { return state.searchMaps && state.searchMaps.maps ? state.searchMaps.maps.docTypeDescriptions : null },
    filterDefs(state) { return state.searchDefinitions ? state.searchDefinitions.filterDefs : null },
    panelDefs(state) { return state.searchDefinitions ? state.searchDefinitions.panelDefs : null },
    sortDefs(state) { return state.searchDefinitions ? state.searchDefinitions.sortDefs : null },
    aggDefs(state) { return state.searchDefinitions ? state.searchDefinitions.aggDefs : null },
    significantTermAggs(state) {
        var sigTerms = []
        if (state.searchDefinitions && state.searchDefinitions.aggPanelDefs)
            Object.keys(state.searchDefinitions.aggPanelDefs).forEach((def) => {
                var panelDef = state.searchDefinitions.aggPanelDefs[def];
                if (panelDef) {
                    var panelDefValue = panelDef.Value ? panelDef.Value : panelDef;
                    if (panelDefValue.panelType == '6')
                        sigTerms.push(def)
                }
            })
        return sigTerms
    },
    aggPanelDefs(state) { return state.searchDefinitions ? state.searchDefinitions.aggPanelDefs : null },
    objectDefs(state) { return state.searchDefinitions ? state.searchDefinitions.objectDefs : null },
    moneyRanges(state) { return state.searchMaps ? state.searchMaps.moneyRanges : null }
}

const jsonMaps = ['aggDefs', 'aggPanelDefs', 'filterDefs', 'panelDefs', 'sortDefs', 'objectDefs']
const edgeDates = {
    start: DateTime.fromSQL("1900-01-01"),
    end: DateTime.local().endOf('year')
}
// actions
const actions = {
    loadSearchMaps({ commit }) {
        return new Promise((resolve, reject) => {
            //if (state.searchMaps && Object.keys(state.searchMaps).length)
              //  resolve(state.searchMaps)
            //else {
                axios.get('search/searchmaps')
                    .then(r => r.data)
                    .then(maps => {
                        jsonMaps.forEach((mapName) => {
                            if (maps[mapName]) {
                                Object.keys(maps[mapName]).forEach((key) => {
                                    if (_.isString(maps[mapName][key])) {
                                        var def = JSON.parse(maps[mapName][key])
                                        maps[mapName][key] = def
                                    }

                                })
                            }
                        })
                        commit('SET_SEARCHMAPS', maps)
                        if (maps.maps.eventTypeRenderMap)
                            commit('SET_EVENTTYPERENDERMAP', maps.maps.eventTypeRenderMap)
                        resolve()
                    })
                    .catch(function (error) {
                        reject(error)
                    })
           // }
        })
    },
    runSearchInSearch({ commit }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.queryID || !opt.targetType || !opt.sourceFieldPath)
                reject('bad parameters')
            else {
                axios.post('search/SearchInSearch', opt)
                    .then(r => r.data)
                    .then(results => {
                        if (results && results.success)
                            resolve(results)
                        else
                            reject('Unsuccessful search in search')

                        _.noop(commit)
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    runSearch({ commit }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.query)
                reject('bad parameters')
            else {
                axios.post('search/RunQuery', opt.query)
                    .then(r => r.data)
                    .then(resultsSet => {
                        if (resultsSet) {
                            if (resultsSet.query) {
                                if (!opt.overrideHistory) {
                                    if (opt.linkedCard)
                                        resultsSet.query.linkedCard = opt.linkedCard

                                    commit('SET_SEARCHHISTORY', resultsSet.query)
                                }
                            }
                            if (resultsSet.counts) {
                                console.log('runQuery', resultsSet.counts)
                            }
                            // Parse jsonObject
                            if (resultsSet.hits) {
                                resultsSet.hits.forEach((hit, idx) => {
                                    try {
                                        var parsedHit = JSON.parse(hit.json)
                                        parsedHit.key = hit.key
                                        parsedHit.total = hit.total
                                        parsedHit.score = hit.score
                                        parsedHit.provenance = hit.provenance
                                        parsedHit.inner_hits = hit.inner_hits
                                        resultsSet.hits[idx] = parsedHit
                                    }
                                    catch (error) {
                                        console.log('searchResults-parse error', hit, error)
                                    }
                                })
                            }
                            if (opt.paging)
                                commit('SET_RESULTSSET_HITS', { id: opt.id, hits: resultsSet.hits })
                            else
                                commit('SET_RESULTSSET', { id: opt.id, resultsSet: resultsSet })


                            //get the geo markers
                            console.log(opt.query.id, resultsSet.query.id)
                            if (!opt.paging && resultsSet.hits && resultsSet.hits.length && resultsSet.query && resultsSet.query.id) {
                                commit('SET_RESULTSSET_GEOMARKERS', { id: opt.id, queryID: resultsSet.query.id, geoMarkers: { fetched: false, hits: [] } })
                                console.log('Searching GeoMarkers...')
                                axios.post('search/RunQueryGeoMarkers', opt.query)
                                    .then(r => r.data)
                                    .then(geoMarkers => {
                                        console.log('Geomarkers found:' + (geoMarkers ? geoMarkers.total : 0))
                                        if (geoMarkers && geoMarkers.hits) {
                                            _.each(geoMarkers.hits, function (h) {
                                                if (!h.objectKey) h.objectKey = h.objectType + '::' + h.id;
                                            });

                                            commit('SET_RESULTSSET_GEOMARKERS', { id: opt.id, queryID: resultsSet.query.id, geoMarkers: { fetched: true, hits: geoMarkers.hits } })
                                        }
                                        else
                                            commit('SET_RESULTSSET_GEOMARKERS', { id: opt.id, queryID: resultsSet.query.id, geoMarkers: { fetched: true, hits: [] } })
                                    }).catch(function () {
                                        commit('SET_RESULTSSET_GEOMARKERS', { id: opt.id, queryID: resultsSet.query.id, geoMarkers: { fetched: true, hits: [] } })
                                    })
                            }
                        }
                        resolve(resultsSet)
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    removeSourceKey({ state }, opt) {
        return new Promise((resolve, reject) => {
            _.noop(state)
            //console.log('runSearch', opt)
            if (!opt || !opt.key || !opt.sourceQueryID || !opt.targetDocumentType )
                reject('bad parameters')
            else {
                axios.post('search/RemoveSourceKey', opt)
                    .then(r => r.data)
                    .then(results => {
                        if (results.success) {
                            resolve(results)
                        }
                        else {
                            reject(results)
                        }
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    searchEditValues({ commit }, opt) {
        return new Promise((resolve, reject) => {
            //console.log('runSearch', opt)
            _.noop(commit);
            if (!opt || !opt.queryID || !opt.filterName || !opt.id)
                reject('bad parameters')
            else {
                // console.log('runSearch', opt.query)
                axios.post('search/SearchEditValues', { queryID: opt.queryID, filterName: opt.filterName })
                    .then(r => r.data)
                    .then((results) => {
                    //console.log('runSearch-results', results)
                    /*if (results && (results.counts || results.values)) {
                        // Save the results
                        if (results.counts) {
                            commit('UPDATE_RESULTSSET_COUNTS', { id: opt.id, counts: results.counts })
                        }
                        if (results.values) {
                            // special case for filters "ownerKeys", "excludeKeys"
                            //console.log('searchEditValues-values', 'TO BE DONE')
                        }
                    }*/
                    resolve(results)
                }).catch(function (error) {
                    reject(error)
                })
            }
        })
    },
    loadSearchDefinitions({ state, commit }) {
        return new Promise((resolve, reject) => {
            if (state.searchDefinitions && state.searchDefinitions.length)
                resolve(state.searchDefinitions)
            else {
                axios.get('search/searchDefinitions')
                    .then(r => r.data)
                    .then(maps => {
                        jsonMaps.forEach((mapName) => {
                            if (maps[mapName]) {
                                Object.keys(maps[mapName]).forEach((key) => {
                                    if (_.isString(maps[mapName][key])) {
                                        var def = JSON.parse(maps[mapName][key])
                                        maps[mapName][key] = def
                                    }

                                })
                            }
                        })
                        commit('SET_SEARCHDEFINITIONS', maps)
                        resolve(maps)
                    })
                    .catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    navigatorSearch({ commit, state }, opt) {
        return new Promise((resolve, reject) => {
            var requestDate = new Date();
            commit('SET_NAVIGATORSTAMP', requestDate)
            axios.get('search/CMDMSearch?term=' + encodeURIComponent(opt.term))
                .then(r => r.data)
                .then(response => {
                    if (requestDate >= state.navigatorStamp) {
                        commit('SET_NAVIGATORSTAMP', requestDate)
                        commit('SET_NAVIGATORRESULTS', response)
                    }
                    resolve
                }).catch(function (error) {
                    reject(error)
                })
        })
    },
    decodeDateRange: function ({ getters }, dateRangeKey) {
        return new Promise((resolve, reject) => {
            if (!dateRangeKey) {
                reject('null dates')
            }

            try {
                var drObj = getters.dateRangeMap[dateRangeKey]
                if (drObj) {
                    resolve(drObj)
                }
                else {
                    // parse start-end
                    var sDateStrings = dateRangeKey.split("_")
                    var startDate = DateTime.fromSQL(sDateStrings[0])
                    var endDate = DateTime.fromSQL(sDateStrings[1])
                    var startIsEdge = startDate.toMillis() === edgeDates.start.toMillis() // ToMillis() equivalence
                    var endIsEdge = +endDate === +(edgeDates.end) // Coercion equivalence
                    var dateRangeEntry = (!startIsEdge ? startDate.toLocaleString(DateTime.DATE_SHORT).replace(', 12:00:00 AM', '') + ' - ' : "Before ")
                        + (!endIsEdge ? endDate.toLocaleString(DateTime.DATE_SHORT).replace(', 12:00:00 AM', '') : " and later");

                    resolve({
                        key: dateRangeKey,
                        description: dateRangeEntry,
                        startDate: startDate.toJSDate(),
                        endDate: endDate.toJSDate()
                    })
                }
            }
            catch (err) {
                reject(err)
            }
        })
    },
    encodeDateRange: function ({ getters }, opt) {
        return new Promise((resolve, reject) => {
            if (opt == undefined || opt == null || opt.startDate == undefined || opt.startDate == null || 
                opt.endDate == undefined || opt.endDate == null) {
                reject('null dates')
            }
            else {
                var startDate = normDate(opt.startDate, edgeDates.start)
                var endDate = normDate(opt.endDate, edgeDates.end)

                var drObj = getters.dateRanges.find((dr) => {
                    return DateTime.fromJSDate(dr.startDate).toMillis() == startDate.toMillis()
                        && DateTime.fromJSDate(dr.end).toMillis() == endDate.toMillis()
                })

                try {
                    if (drObj) {
                        resolve(drObj)
                    }
                    else {
                        var startIsEdge = startDate.toMillis() === edgeDates.start.toMillis() // ToMillis() equivalence
                        var endIsEdge = +endDate === +(edgeDates.end) // Coercion equivalence
                        var dateRangeKey = (!startIsEdge ? startDate.toISODate() : "null") + "_" + (!endIsEdge ? endDate.toISODate() : "null")
                        var dateRangeEntry = (!startIsEdge ? startDate.toLocaleString(DateTime.DATE_SHORT).replace(', 12:00:00 AM', '') + ' - ' : "Before ")
                            + (!endIsEdge ? endDate.toLocaleString(DateTime.DATE_SHORT).replace(', 12:00:00 AM', '') : " and later");

                        resolve({
                            key: dateRangeKey,
                            description: dateRangeEntry,
                            startDate: startDate.toJSDate(),
                            endDate: endDate.toJSDate()
                        })
                    }
                }
                catch (err) {
                    reject(err)
                }
                
            }
        })
    },
    formatDate: function ({ state }, opt) { // Not used
        return new Promise((resolve, reject) => {
            try {
                _.noop(state)
                var dateFormat = "All Time"
                var startDate = normDate(opt.startDate, edgeDates.start)
                var endDate = normDate(opt.endDate, edgeDates.end)

                var timeDiff = startDate.diff
                if (timeDiff.isValid) {
                    if (timeDiff.as('days') < 1) {
                        dateFormat = startDate.toFormat("MMM d")
                    }
                    else if (timeDiff.as('days') < 365) {
                        dateFormat = (startDate != edgeDates.start ? startDate.toFormat("MMM d") + ' - ' : "Before ")
                            + (endDate != edgeDates.end ? endDate.toFormat("MMM d") : " and later");
                    }
                    else if (timeDiff.as('days') > 40000) {
                        dateFormat = "All Time"
                    }
                    else {
                        dateFormat = (startDate != edgeDates.start ? startDate.toFormat("MMM d, YYYY") + ' - ' : "Before ")
                            + (endDate != edgeDates.end ? endDate.toFormat("MMM d, YYYY") : " and later")
                    }
                }
                resolve(dateFormat)
            }
            catch (err) {
                reject(err)
            }
        })
    },
    getSearchSuggestions: function ({ state }, opt) {
        return new Promise((resolve, reject) => {
            _.noop(state)
            if (!opt || !opt.query) {
                reject('bad parameters')
                return
            }
            var req = {
                query: opt.query,
                limit: (opt.limit ? opt.limit : 3),
                skip: (opt.skip ? opt.skip : 0),
                lastTotal: (opt.lastTotal ? opt.lastTotal : 0)
            }
            axios.post("Search/SuggestedFilters", req)
                .then(r => r.data)
                .then(results => {
                    resolve(results)
                    return
                })
                .catch(function (error) {
                    reject(error)
                    return
                })
        })
    },
    fetchSearchVueModel: function ({ commit }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt)
                reject('bad parameters')
            var url = "Search/fetchSearchVueModel"
            //console.log('fetchSearchVueModel', opt)
            if (opt.queryString) {
                if (_.isArray(opt.queryString)) {
                    var qs = opt.queryString.map((param) => { return param.key + '=' + encodeURIComponent(param.value) })
                    //console.log('fetchSearchVueModel', qs)
                    if (qs)
                        url += "?" + qs.join("&")
                }
                else if (_.isString(opt.queryString))
                    url += "?" + opt.queryString
            }
            //console.log('fetchSearchVueModel', url)
            axios.get(url)
                .then(r => r.data)
                .then(results => {
                    commit('SET_RESULTSSET', { id: opt.id, resultsSet: results.resultsSet })
                    resolve(results)
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    },
    setTermUrl({ state, commit }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.url || !opt.termType || !state.termSearches[opt.termType])
                reject('bad parameters')

            commit('SET_TERMURL', {
                termType: opt.termType, url: opt.url
            })
           
        })
    },
    getTermWords({ state, commit }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.word || !opt.termType || !state.termSearches[opt.termType])
                reject('bad parameters')

            var url = state.termSearches[opt.termType].url + 'term=' + encodeURIComponent(opt.word) + '&limit=' + (opt.limit ? opt.limt : 10)
            axios.get(url)
                .then(r => r.data)
                .then(words => {
                    commit('SET_TERMWORDS', { termType: opt.termType, word: opt.word, words: words })
                    resolve(words)
                }).catch(function (error) {
                    reject(error)
                })

        })
    },
    createSearchSnapshot(state, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.query || (!opt.listName && !opt.listID))
                reject('Invalid parameters');

            var newOpt = {
                listID: opt.listID ?? 0,
                listName: opt.listName ?? '',
                subsetID: opt.subsetID ?? 0,
                subsetName: opt.subsetName ?? '',
                listTypeID: 0,
                listEntryStatusID: 2,
                deepSave: opt.deepSave ?? false,
                autoPopulate: opt.autoPop ?? false,
                query: opt.query
            };

            var url = opt.createQuery ? 'search/CreateSnapshotQuery' : 'search/SaveResults';
            axios.post(url, newOpt)
                .then(r => r.data)
                .then(result => {
                    if (result && result.success) {
                        resolve(result)
                    }
                    else
                        reject(result.message);
                })
        })
    },
}

function normDate(theDate, defaultDate) {
    var lDate = DateTime.isDateTime(theDate) ? theDate : (_.isDate(theDate) ? DateTime.fromJSDate(theDate) : DateTime.fromSQL(theDate))
    if (!lDate.isValid) { lDate = (defaultDate ? defaultDate :  edgeDates.start) }
    return lDate
}

const mutations = {
    INITIALIZE_STORE(state) {
        state.searchMaps = this.fetchLocal('search_searchMaps')
        state.searchDefinitions = this.fetchLocal('search_searchDefinitions')
        state.eventTypeRenderMap = this.fetchLocal('search_eventTypeRenderMap')
        state.resultsViewMode = this.fetchLocal('search_resultsViewMode', 'details')
        state.termSearches = initialTermSearch
        state.hitLimit = this.fetchLocal('search_hitLimit', 30)
        state.searchHistory = this.fetchLocal('search_searchHistory', {})
        this.saveLocal('search_searchStack', null) //clear out legacy info
    },
    DESTROY_STORE(state) {
        this.saveLocal('search_searchMaps', null)
        this.saveLocal('search_searchDefinitions', null)
        this.saveLocal('search_eventTypeRenderMap', null)
        this.saveLocal('search_resultsViewMode', null)
        this.saveLocal('search_termSearches', null)
        this.saveLocal('search_searchHistory', null)
        state.search_searchMaps = null
        state.searchDefinitions = null
        state.search_eventTypeRenderMap = null
        state.resultsViewMode = null
        state.termSearches = null
        state.searchHistory = {}
        //console.log('destroying search state in localstorage')
    },
    SET_SEARCHMAPS(state, searchmaps) {
        state.searchMaps = searchmaps 
        this.saveLocal('search_searchMaps', searchmaps)
    },
    SET_SEARCHDEFINITIONS(state, searchDefinitions) {
        state.searchDefinitions = searchDefinitions
        this.saveLocal('search_searchDefinitions', searchDefinitions)
    },
    SET_NAVIGATORRESULTS(state, navigatorResults) {
        state.navigatorResults = navigatorResults
    },
    SET_NAVIGATORSTAMP(state, term) {
        state.navigatorStamp = term
    },
    SET_EVENTTYPERENDERMAP(state, eventTypeRenderMap) {
        if (eventTypeRenderMap) {
            var parsedMap = {}
            Object.keys(eventTypeRenderMap).forEach((key) => {
                parsedMap[key] = JSON.parse(eventTypeRenderMap[key])
            })
            state.eventTypeRenderMap = parsedMap
            this.saveLocal('search_eventTypeRenderMap', parsedMap)
        }
    },
    SET_RESULTSVIEWMODE(state, resultsViewMode) {
        state.resultsViewMode = resultsViewMode
        this.saveLocal('search_resultsViewMode', resultsViewMode)
    },
    SET_TERMURL(state, opt) {
        state.termSearches[opt.termType].url = opt.url
        state.termSearches[opt.termType].results = {}
    },
    SET_TERMWORDS(state, opt) {
        state.termSearches[opt.termType].results[opt.word] = opt.words
    },
    SET_RESULTSSET_GEOMARKERS(state, opt) {
        if (opt.queryID && state.resultsSets[opt.id].query && state.resultsSets[opt.id].query.id == opt.queryID)
            state.resultsSets[opt.id].geoMarkers = opt.geoMarkers
    },
    SET_RESULTSSET(state, opt) {
        state.resultsSets[opt.id] = opt.resultsSet
    },
    SET_RESULTSSET_HITS(state, opt) {
        state.resultsSets[opt.id].hits = opt.hits
    },
    UPDATE_SEARCHRESULT_HIT(state, opt) {
        state.resultsSets[opt.id].hits[opt.hitIDX] = opt.newHit
    },
    UPDATE_RESULTSSET_COUNTS(state, opt) {
        if (!state.resultsSets[opt.id].counts)
            state.resultsSets[opt.id].counts = {}
        Object.keys(opt.counts).forEach((key) => {
            if (key != 'objectTypes' && opt.counts[key])
                state.resultsSets[opt.id].counts[key] = opt.counts[key]

        })
    },
    SET_SEARCHHISTORY(state, query) {
        if (query && query.id) {
            state.searchHistory[query.id] = query
            if (Object.keys(state.searchHistory).length > 15) {
                var sortedHistory = _.sortBy(_.values(state.searchHistory), function (o) {
                    return new Date(o.date) * -1;
                });
                state.searchHistory = {};

                var historyToKeep = _.slice(sortedHistory, 0, 15);
                for (var i = 0; i < historyToKeep.length; i++) {
                    var q = historyToKeep[i];
                    state.searchHistory[q.id] = q;
                }
            }

            if (!this.saveLocal('search_searchHistory', state.searchHistory)) {
                console.log('searchHistory is too big')
                state.searchHistory = {}
                state.searchHistory[query.id] = query
                this.saveLocal('search_searchHistory', state.searchHistory)
            }
        }
    },
    SET_HITLIMIT(state, limit) {
        state.hitLimit = limit
        this.saveLocal('search_hitLimit', state.hitLimit)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
