<template>
    <input-number :suffix='suffix' 
                  v-model="model" 
                  :minFractionDigits="minFractionDigits" 
                  :maxFractionDigits="minFractionDigits"  
                  :title="title" 
                  :size="size" 
                  :min="minVal"
                  :max="maxVal"
                  :disabled="disabled" 
                   @focus="$emit('focus',$event)"
                  :class="$attrs.class" />
</template>

<script>

    import { computed } from 'vue'
    import InputNumber from 'primevue/inputnumber/sfc'

    export default {
        name: 'ScaledInput',
        components: { InputNumber },
        emits: ['update:modelValue', 'focus'],
        props: {
            modelValue: Number,
            suffix: String,
            title: String,
            size: { type: Number, default: 3 },
            scale: { type: Number, default: 0 },
            minFractionDigits: { type: Number, default: 1 },
            advanced: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
            min: Number,
            max: Number
        },        
        setup(props, { emit }) {
            const multiplier = computed(() => Math.pow(10, props.scale))
            const minVal = computed(() => props.min != null ? (props.min / multiplier.value) : null)
            const maxVal = computed(() =>props.max != null ? (props.max / multiplier.value) : null)


            const model = computed({
                get: () => props.modelValue && props.modelValue != 0.0 ? (props.modelValue / multiplier.value) : null,
                set: (val) => {
                    var scaledModel = props.modelValue && props.modelValue != 0.0 ? (props.modelValue / multiplier.value) : null
                    console.log('scaledModel', props.modelValue, scaledModel, val)
                    if ((val ?? 0.0).toFixed(props.minFractionDigits) != (scaledModel ?? 0.0).toFixed(props.minFractionDigits)) {
                        console.log('scaledModel-emit', val && val != 0.0 ? val * multiplier.value : null)
                        emit('update:modelValue', val && val != 0.0 ? val * multiplier.value : null)
                    }
                }
            })

            return {
                multiplier,
                minVal,
                maxVal,
                model
            }
        }
    }
</script>
