<template>
  <footer class="modal-footer test">
    <slot/>
    <template v-if="!hasSlot">
        <button class="button-secondary" @click="cancelMe">Cancel</button>
        <button class="button-primary" @click="saveMe">{{ saveTitle }}</button>
    </template>
  </footer>
</template>

<script>
    import { Comment } from 'vue'

    export default {
        name: 'ModalFooter',
        components: {

        },
        emits: ['save', 'cancel'],
        props: {
            saveTitle: { type: String, default: 'Save' }
        },
        computed: {
            hasSlot() {
                return (this.$slots.default && this.$slots.default().findIndex(o => o.type !== Comment) !== -1)
            }
        },
        data() {
            return {

            }
        },
        methods: {
            saveMe() {
                this.$emit('save')
            },
            cancelMe() {
                this.$emit('cancel')
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>