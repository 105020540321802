<template>
    <OnClickOutside @trigger="handleCloseOutside" class="dropdown-wrapper">        
        <div class="dropdown" :class="[$attrs.class, openState, dropdownClasses]">
            <div @click="handleDropButtonClick" class="dropdown-toggle" :class="toggleClasses" :title="title">
                <template v-if="iconClass">
                    <i :class="iconClass"></i>
                </template>
                <span class="label" v-if="label">
                    {{ label }}
                </span>
                <slot name="toggle" />
            </div>
            <section v-show="openState == 'open'" class="dropdown-menu" :class="menuClasses">
                <div class="dropdown-header">
                    <slot name="header" />
                    <span class="dropdown-menu-close only-on-mobile" @click="closeTheDropDown">
                        <i class="far fa-times"></i>
                    </span>
                </div>
                <div class="dropdown-body">
                    <slot name="body" />
                </div>
                <div class="dropdown-footer d-none">
                    <div class="actions flex-rows no-responsive">
                        <button class="button-secondary dropdown-menu-close">Cancel</button>
                        <button class="button-primary place-right">Save</button>
                    </div>
                </div>
            </section>
        </div>
    </OnClickOutside>
</template>

<script>
    import { ref, computed } from 'vue'
    import { OnClickOutside } from '@vueuse/components'
    import { useModelVirtualWrapper } from '../../../composables/ModelWrapper.js'

    export default {
        name: 'Dropdown',
        components: {
            OnClickOutside
        },
        props: {
            modelValue: { type: String },
            label: { type: String },
            title: { type: String },
            type: { type: String },
            icon: { type: String },
            iconSolid: { type: Boolean, default: true },
            useFontAwesome: { type: Boolean, default: true },
            direction: {
                type: String,
                default: 'right'
            },
            disableClickOutside: { type: Boolean, default: false }
        },
        emits: ['openclose', 'update:modelValue'],
        setup(props, { emit }) {
            // models
            const openState = ref('closed')
            const model = useModelVirtualWrapper(props, emit, 'modelValue', openState)
                
            // const
            const iconToggles = ['icon', 'kebab', 'meatball', 'bars']
            const plainToggles = ['thumbnail', 'plain', 'blank']
            const iconMap = {
                kebab: 'fa-ellipsis-v',
                meatball: 'fa-ellipsis-h',
                bars: 'fa-bars'
            }

            // computed
            const dropdownClasses = computed(() => {
               
                var val = {
                    'icon': iconToggles.includes(props.type),
                    'menu-bars': props.type === 'bars',
                    'style-button': props.type === 'button',
                    'style-box': props.type === 'box'
                }

                //console.log('dropdownClasses', val, model)
                return val
            })
            const toggleClasses = computed(() => {
                return {
                    'blank': plainToggles.includes(props.type),
                    'border': !plainToggles.includes(props.type)
                }
            })
            const menuClasses = computed(() =>  {
                return {
                    'pointer-up-right': iconToggles.includes(props.type),
                    'to-right': props.direction === 'right',
                    'to-left': props.direction === 'left',
                    'to-up': props.direction === 'up',
                    'to-up-center pointer-down-center': props.direction === 'up-center',
                    'to-up-right pointer-down-right': props.direction === 'up-right',
                    'pointer-left-up': props.direction === 'left-up'
                }
            })
            const iconClass = computed(() => {
                var glyph = null;

                if (props.icon) {
                    glyph = props.icon
                }
                else if (props.type === 'kebab') {
                    glyph = "ellipsis-v"
                } else if (props.type === 'meatball') {
                    glyph = "ellipsis-h"
                } else if (props.type === 'bars') {
                    glyph = "bars"
                } else {
                    return null
                }

                return (props.useFontAwesome ? (props.iconSolid ? 'fas' : 'far') + ' fa-' : '') + glyph
            })

            // methods
            const handleDropButtonClick =()=> {
                model.value = openState.value == 'open' ? 'closed' : 'open'
                emit('openclose', openState.value == 'open')
            }
            const closeTheDropDown = () =>{
                model.value = 'closed'
                openState.value = model.value
            }
            const handleCloseOutside = () => {
                if (!props.disableClickOutside)
                    model.value = 'closed'
            }

            return {
                model, openState, iconToggles, plainToggles, iconMap,
                // computed
                dropdownClasses, toggleClasses, menuClasses, iconClass,
                // methods
                handleDropButtonClick, closeTheDropDown, handleCloseOutside
            }
        },
        watch: {
            "modelValue":
            {
                handler(newVal) {
                    this.model = newVal
                    this.openState = newVal
                }
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
