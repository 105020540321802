import axios from 'axios'
import _ from 'lodash'

const state = () => ({
    buyersListSummaries: [],
})

// getters
const getters = {
    buyersListSummaries(state) { return state.buyersListSummaries }
}

// actions
const actions = {
    fetchBuyersLists({ state, commit }, opt) {
        return new Promise((resolve, reject) => {
            var optKey = "all"
            if (opt) {
                optKey = Object.keys(opt).map((key) => { return key + "-" + opt[key] }).join("_")
            }
            if (state.buyersListSummaries[optKey].length > 0)
                resolve(state.buyersListSummaries[optKey])
            else {
                var url = 'buyerslist/FetchLists'
                if (opt) {
                    var params = Object.keys(opt).map((key) => { return key + "=" + opt[key] })
                    url += "?" + params.join("&")
                }
                axios.get(url)
                    .then(r => r.data)
                    .then(results => {
                        console.log('buyerslists Fetched', results)
                        if (results.success) {
                            var lists = JSON.parse(results.jsonResult)
                            commit('SET_BUYERSLISTSSUMMARIES', { lists: lists, key: optKey })
                            resolve()
                        }
                        else
                            reject(results.message)
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    addNewMemberByID({ state }, opt) {
        return new Promise((resolve, reject) => {
            if (!opt || !opt.listID || !opt.companyID) {
                _.noop(state)
                reject('bad parameters')
                return
            }
            var url = 'buyerslist/AddNewMemberByID?listID=' + opt.listID + '&companyID=' + opt.companyID
            if (opt.personID)
                url += '&personID=' + opt.personID
            axios.post(url).then(r => r.data)
                .then(results => {
                    console.log('Added BuyersList Members Fetched', results)
                    if (results.success) {
                        var member = JSON.parse(results.jsonResult)
                        resolve(member)
                    }
                    else
                        reject(results.message)
                }).catch(function (error) {
                    reject(error)
                })
        })
    },
    syncList({ state }, opt) {
        return new Promise((resolve, reject) => {
            _.noop(state)

            if (!opt || !opt.listID) {
                reject('bad parameters')
                return
            }

            axios.post('list/Synch/'+opt.listID+'&subsetID='+opt.subsetID).then(r => r.data)
                .then(results => {
                    if (results.success)
                        resolve()
                    else
                        reject()

                }).catch(function () {
                    reject()
                })
        })
    }

}


// mutations
const mutations = {
    INITIALIZE_STORE(state) {
        state.buyersListSummaries = this.fetchLocal('lists_buyersListSummaries', { all: [] })
    },
    DESTROY_STORE(state) {
        this.saveLocal('lists_buyersListSummaries', null)
        state.buyersListSummaries = { all: [] }
    }, 
    SET_BUYERSLISTSSUMMARIES(state, opt) {
        state.buyersListSummaries[opt.key] = opt.lists
        this.saveLocal('lists_buyersListSummaries', state.buyersListSummaries)
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
