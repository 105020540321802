<template>
    <section class="main-content" :class="mainContentClasses" ref="divContent">
        <header class="content-header flex-rows no-responsive" v-if="showMenus">
            <template v-if="isMobile">
                <div class="main-brand icon">
                    <router-link to="/">
                        <img src="../assets/images/MandaSoft_AppIcon.svg" width="40" height="40" />
                    </router-link>
                </div>
            </template>
            <navigator-box v-once></navigator-box>
            <div v-if="!isMobile" class="site-info flex-rows no-responsive vertical-align-items">
                <user-menu v-once></user-menu>
                <bookmarks-menu v-if="user"></bookmarks-menu>
            </div>
            <template v-if="isMobile">
                <main-menu></main-menu>
            </template>
        </header>
        <section class="content-body">
            <router-view v-slot="{ Component }">
                <transition name="fade">
                    <div :key="routeKey">
                        <component :is="Component" />
                    </div>
                </transition>
            </router-view>
        </section>
        <scroll-to-top />
    </section>
    <Toast />
</template>

<script>

    import { ref, computed, onMounted, inject, watch } from 'vue'
    import { useStore } from 'vuex'
    import { useRoute } from 'vue-router'

    import NavigatorBox from './Menus/NavigatorBox.vue'
    import UserMenu from './Menus/UserMenu.vue'
    import BookmarksMenu from './Bookmarks/BookmarksMenu.vue'
    import MainMenu from './Menus/MainMenu.vue'
    import ScrollToTop from './ui/base/scroll-to-top.vue'

    export default {
        name: 'MainContent',
        components: {
            UserMenu, NavigatorBox, BookmarksMenu, MainMenu, ScrollToTop
        },
        setup() {
            const store = useStore()
            const route = useRoute()
            const emitter = inject("emitter")   // Inject event bus 

            // data
            const divContent = ref(null)
            const searchCount = ref(0)

            // const
            const fullwidthLayouts = ['Welcome']
            const scrollToTopLayouts = ['Dashboard']

            // computed
            const user = computed(() => store.getters['userInfo/currentUser'])
            const mainContentClasses = computed(() => {
                return { 'fullwidth': fullwidthLayouts.includes(route.name) }
            })
            const showMenus = computed(() => route.name != 'Welcome' || user.value)
            const hasScrollToTop = computed(() => {
                var matches = scrollToTopLayouts.filter((lo) => route.name.startsWith(lo))
                //console.log('hasScrollToTop', matches)
                return matches.length > 0
            })
            const routeKey = () => {
                if (route.fullPath.startsWith('/Search/vue'))
                    searchCount.value = searchCount.value + 1
                var rKey = route.fullPath.toLowerCase().startsWith('/search/vue') ? '/search/vue/' + searchCount.value.toString() : route.fullPath
                //console.log('routeKey', route.fullPath, rKey, searchCount.value)
                return rKey
            }

            // lifecycle
            onMounted(() => {
                document.title = 'MandAsoft'
            })

            watch(route, (newVal, oldVal) => {
                console.log('route-watch-maincontent', newVal, oldVal)
                emitter.emit('routeChange', newVal.fullPath)
            })

            return {
                // const 
                fullwidthLayouts, scrollToTopLayouts, emitter,
                // data
                divContent, searchCount,
                // computed
                user, mainContentClasses, showMenus, hasScrollToTop, routeKey
            }
        }
    }
</script>

<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 1.0s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>