<template>
    <div v-if="shouldDisplay" class="event-item event" :class="containerClass">

        <i class="event-icon" :class="iconClass"></i>
        <span class="event-label">{{eventLabel}}</span>

        <template v-if="obj.objectType == 'emaillog' && email">
            @&nbsp;
            <span v-if="email.toUrl" class="event-label"><a :href="email.toUrl">{{ email.to }}</a><span v-if="email.toLength>1"> and {{email.toLength}} more</span></span>
            <span v-else class="event-label">Unknown ({{ email.to }})</span>
            by&nbsp;
            <span v-if="email.fromUrl"><a :href="email.fromUrl" style="color:#464163 !important">{{ email.from }}</a><span v-if="email.fromLength>1"> and {{email.fromLength}} more</span></span>
            <span v-else style="color:#464163 !important">Unknown ({{ email.from }})</span>
        </template>

        <template v-else>
            <span v-if="limitNames && persons && persons.length > 1">
                @&nbsp;<a :href="person.url">{{ person.name }}</a> and {{persons.length - 1}} more
            </span>
            <span v-else-if="persons" class="event-label">
                @&#32;
                <template v-for="(person, i) in persons" :key="person.id">
                    <template v-if="i > 0">,&#32;</template>
                    <a :href="person.url">{{ person.name }}</a>
                </template>
            </span>
            by&nbsp;
            <span><a :href="'/user/'+obj.entryUserID" style="color:#464163 !important">{{ obj.entryUserName }}</a></span>
        </template>

    </div>
</template>

<script>
    import { computed } from 'vue';
    import { useStore } from 'vuex'
    import { getObjectTools } from '../../composables/ObjectTools.js';
    import { getRecordTools } from '../../composables/RecordTools.js';
    export default {
        name: 'RecordEventSubtitle',
        props: {
            'obj': { type: Object },
            'isFullInfoContext': {type: Boolean }
        },
        setup(props) {
            var store = useStore()
            const { objectIDToObjectTypeMap } = getObjectTools(props);
            const { getTitleURL } = getRecordTools(props);
            const commentTypes = computed(() => store.getters['comments/commentTypes'])
            const owners = computed(() => {
                var owners = props.obj && props.obj.owners ? props.obj.owners.map((o) => { return { id: o.ownerID, name: o.ownerName, objectType: objectIDToObjectTypeMap.value[o.ownerTypeID], objectTypeID: o.ownerTypeID, active: o.ownerActive } }) : []
                return owners;
            })
            const email = computed(() => {
                // this data is different enough from other event types that it is simpler to just handle it all separately 
                var email = props.obj.emails && props.obj.emails.length ? props.obj && props.obj.emails[0] : props.obj;
                var preparedEmail = {
                    name: email.name ? email.name : props.obj.name,
                    outbound: email.outbound,
                    to: email.to,
                    from: email.from
                }

                let userUrl = '/user/' + email.entryUserID;
                let persons = [], person, company, ownerUrl;
                if (owners.value.length > 0) {
                    persons = owners.value.filter(o => o.objectType == 'person');
                    company = owners.value.find(o => o.objectType == 'company');
                }
                if (persons.length > 0) {
                    person = persons[0];
                    ownerUrl = getTitleURL(person);
                }

                if (!preparedEmail.to)
                    preparedEmail.to = "Unknown email";
                if (!preparedEmail.from)
                    preparedEmail.from = "Unknown email";

                //to fix
                if (preparedEmail.outbound && preparedEmail.to.includes(";")) {
                    var emailsTo = preparedEmail.to.split(";");
                    preparedEmail.to = emailsTo[0] + " and " + (emailsTo.length - 1) + " more";
                }

                //from fix
                if (!preparedEmail.outbound && preparedEmail.from.includes(";")) {
                    var emailsFrom = preparedEmail.from.split(";");
                    preparedEmail.from = emailsFrom[0] + " and " + (emailsFrom.length - 1) + " more";
                }

                if (email.outbound) {
                    return {
                        name: email.name ? email.name : props.obj.name,
                        outbound: true,
                        to: person ? person.name : preparedEmail.to,
                        toLength: persons.length,
                        toUrl: ownerUrl,
                        from: email.entryUserName,
                        fromUrl: userUrl,
                        company: company
                    }
                } else {
                    return {
                        name: email.name ? email.name : props.obj.name,
                        outbound: false,
                        to: email.entryUserName,
                        toUrl: userUrl,
                        from: person ? person.name : preparedEmail.from,
                        fromLength: persons.length,
                        fromUrl: ownerUrl,
                        company: company
                    }
                }
            })
            const shouldDisplay = computed(() => {
                if (props.obj.objectType == 'comment') {
                    return true;
                } else if (props.obj.objectType == 'emaillog') {
                    return !props.isFullInfoContext;
                } else {
                    return false;
                }
            })
            const limitNames = computed(() => {
                if (props.obj.objectType == 'emaillog') {
                    return true;
                } else {
                    return false;
                }
            })
            const persons = computed(() => {
                if (owners.value.length > 0) {
                    let persons = owners.value.filter(o => o.objectType == 'person');
                    if (persons.length > 0) return persons.map(person => {
                        return {
                            url: '/person/detailspage/' + person.id,
                            name: person.name
                        }
                    });
                }
                return null;
            })
            const eventLabel = computed(() => {
                if (props.obj.objectType == 'comment') {
                    let type = commentTypes.value.find(t => t.id == props.obj.commentType);
                    if (type) return type.entry;
                    else return 'comment';
                } else if (props.obj.objectType == 'emaillog') {
                    return email.value.outbound ? 'Outbound Email' : 'Inbound Email';
                } else {
                    return '';
                }
            })
            const containerClass = computed(() => {
                return eventLabel.value.split(' ').join('-').replace('&', '').toLowerCase();
            })
            const iconClass = computed(() => {
                if (props.obj.objectType == 'comment') {
                    return containerClass.value;
                } else {
                    return 'fa-comments';
                }
            })
            return { getTitleURL, email, persons, limitNames, shouldDisplay, containerClass, iconClass, eventLabel }
        }
    }
</script>

<style lang="scss">

</style>