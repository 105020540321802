import axios from 'axios'

const state = () => ({
    user: null,
    userLayout: { navigation: [] },
    dashboardGroupID: null,
    users: []
})

// getters
const getters = {
    loggedIn(state) { return state.user ? true : false },
    currentUserInfo(state) { return state.user },
    currentUser(state) { return (state.user ? state.user.userInfo  : null) },
    currentUserID(state) { return (state.user && state.user.userInfo ? state.user.userInfo.id : 0) },
    currentUserLayout(state) { return state.userLayout; },
    currentUserBookmarkListID(state) { return (state.user && state.user.userInfo ? state.user.userInfo.activeFlagListID : 0) },
    dashboardGroup(state, getters, rootState) {
        //console.log(rootState)
        if (state.dashboardGroupID && rootState.dashboardGroups[state.dashboardGroupID])
            return rootState.dashboardGroups[state.dashboardGroupID]
        return null;
    },
    dashboardFirst(state, getters, rootState) {
        if (state.dashboardGroupID && rootState.dashboardGroups[state.dashboardGroupID] && rootState.dashboardGroups[state.dashboardGroupID].dashboards)
            return rootState.dashboardGroups[state.dashboardGroupID].dashboards[0]
        return null;
    },
    allUsers(state) {
        return state.users
    },
    activeUsers(state) {
        return state.users.filter(user => {
            return user.active
        })
    }
}

// actions
const actions = {
    loadUser({ state, commit }) {
        return new Promise((resolve, reject) => {
            if (state.user && state.user.userInfo) {
                resolve()
            }
            else {
                console.log('loadUser-from AJAX', state.user)
                axios.get('account/me')
                    .then(r => r.data)
                    .then(user => {
                        commit('SET_USER', user)
                        if (user)
                            resolve()
                        else
                            reject('not logged in')
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    loadLayout({ state, commit }, opt) {
        return new Promise((resolve, reject) => {
            if (opt && opt.skipFetch && state.userLayout && state.userLayout.navigation && state.userLayout.navigation.length > 0)
                resolve(state.userLayout)
            else {
                axios.get('account/UserLayout')
                    .then(r => r.data)
                    .then(layout => {
                        commit('SET_USERLAYOUT', layout)
                        resolve(layout)
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    },
    logOut({ commit }) {
        return new Promise((resolve, reject) => {
            console.log('logging out')
            axios.post('account/LogOut')
                .then((response) => {
                    console.log('logged out')
                    commit('SET_USER', null)
                    commit('SET_DASHBOARDGROUPID', null)
                    commit('SET_USERLAYOUT', { navigation: [] })
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    },
    clearUserState({ commit }) {
        commit('SET_USER', null)
        commit('SET_DASHBOARDGROUPID', null)
        commit('SET_USERLAYOUT', { navigation: [] })
    },
    fetchUsers({  state, commit }) {
        return new Promise((resolve, reject) => {
            if (state.users.length > 0)
               resolve(state.users)
            else {
                axios.get('account/FetchUserSummariesAll')
                    .then(r => r.data)
                    .then(users => {
                        //console.log(users)
                        commit('SET_USERS', users)
                        resolve(users)
                    }).catch(function (error) {
                        reject(error)
                    })
            }
        })
    }
}


// mutations
const mutations = {
    INITIALIZE_STORE(state) {
        state.user = this.fetchLocal('userInfo_user', null)
        state.users = [] //this.fetchLocal('userInfo_users', [])
        state.userLayout = this.fetchLocal('userInfo_userLayout', { navigation: [] })
        state.dashboardGroupID = this.fetchLocal('userInfo_dashboardGroupID', null)
        
        //console.log('fetching userInfo state from localstorage', state.users, state.userLayout)
    },
    DESTROY_STORE(state) {
        this.saveLocal('userInfo_user', null)
        this.saveLocal('userInfo_users', null)
        this.saveLocal('userInfo_userLayout', null)
        this.saveLocal('userInfo_dashboardGroupID', null)
        state.user = null
        state.loggedIn = false
        state.users = []
        state.userLayout = { navigation: [] }
        state.dashboardGroupID = null
      //  console.log('destroying userInfo state in localstorage')
    },
    SET_USER(state, user) {
        state.user = user
        this.saveLocal('userInfo_user', state.user)
    },
    SET_USERLAYOUT(state, userLayout) {
        state.userLayout = userLayout
        this.saveLocal('userInfo_userLayout', state.userLayout)
    },
    SET_USERS(state, users) {
        state.users = users
        //this.saveLocal('userInfo_users', state.users)
    },
    SET_DASHBOARDGROUPID(state, dashboardGroupID) {
        state.dashboardGroupID = dashboardGroupID
        this.saveLocal('userInfo_dashboardGroupID', state.dashboardGroupID)
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
