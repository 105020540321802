import { useStore } from 'vuex'
import { computed } from 'vue'

export function getObjectTools(props, objectProp = 'obj') {

    const store = useStore()
    const searchMaps = computed(() => store.state.search.searchMaps.maps)

    // computed
    const objectID = computed(() => (props[objectProp] ? props[objectProp].id : null))
    const objectName = computed(() => (props[objectProp] ? props[objectProp].name : null))
    const objectType = computed(() => {
      return   (props[objectProp] ? props[objectProp].objectType : null)
    })
    const objectKey = computed(() => (props[objectProp] ? props[objectProp].objectKey : (objectID.value && objectID.value ? objectID.value + '::' + objectID.value : null)))
    const storeKey = computed(() => (objectKey.value ? encodeOwnerKey(objectKey.value): null))
    
    const objectIDToObjectTypeMap = computed(() => {
        return searchMaps.value && searchMaps.value.ownerTypeMap ? searchMaps.value.ownerTypeMap : {}
    })
    const objectTypeToObjectIDMap = computed(() => {
        return searchMaps.value && searchMaps.value.ownerTypeRevMap ? searchMaps.value.ownerTypeRevMap : {}
    })

    // methods
    const objectURL = (opt) => {
        console.log('objectURL', opt)
        if (opt.id && (opt.objectType || opt.objectTypeID)) {
            var objectType = (!opt.objectType && opt.objectTypeID && objectIDToObjectTypeMap.value[opt.objectTypeID]) ? objectIDToObjectTypeMap.value[opt.objectTypeID] : opt.objectType
            return '/' + objectType + '/' + (opt.page ?? 'details') + '/' + opt.id
        }
        return null
    }
    const encodeOwnerKey = (ownerKey) => {
        return ownerKey.replace('::', "__")
    }
    const decodeOwnerKey = (ownerKey) => {
        return ownerKey.replace("__", '::')
    }

    return {
        // store state
        searchMaps, 
        // computed
        objectID, objectType, objectKey, objectName, storeKey, objectIDToObjectTypeMap, objectTypeToObjectIDMap,
        // methods
        objectURL, encodeOwnerKey, decodeOwnerKey
    }

}

export function getDataObjectTools(dataVal) {

    const store = useStore()
    const searchMaps = computed(() => store.state.search.searchMaps.maps)

    // computed
    const objectID = computed(() => (dataVal.value ? dataVal.value.id : null))
    const objectName = computed(() => (dataVal.value ? dataVal.value.name : null))
    const objectType = computed(() => {
        return (dataVal.value ? dataVal.value.objectType : null)
    })
    const objectKey = computed(() => (dataVal.value ? dataVal.value.objectKey : (objectID.value && objectID.value ? objectID.value + '::' + objectID.value : null)))
    const storeKey = computed(() => (objectKey.value ? encodeOwnerKey(objectKey.value) : null))

    const objectIDToObjectTypeMap = computed(() => {
        return searchMaps.value && searchMaps.value.ownerTypeMap ? searchMaps.value.ownerTypeMap : {}
    })
    const objectTypeToObjectIDMap = computed(() => {
        return searchMaps.value && searchMaps.value.ownerTypeRevMap ? searchMaps.value.ownerTypeRevMap : {}
    })

    // methods
    const objectURL = (opt) => {
        console.log('objectURL', opt)
        if (opt.id && (opt.objectType || opt.objectTypeID)) {
            var objectType = (!opt.objectType && opt.objectTypeID && objectIDToObjectTypeMap.value[opt.objectTypeID]) ? objectIDToObjectTypeMap.value[opt.objectTypeID] : opt.objectType
            return '/' + objectType + '/' + (opt.page ?? 'details') + '/' + opt.id
        }
        return null
    }
    const encodeOwnerKey = (ownerKey) => {
        return ownerKey.replace('::', "__")
    }
    const decodeOwnerKey = (ownerKey) => {
        return ownerKey.replace("__", '::')
    }

    return {
        // store state
        searchMaps,
        // computed
        objectID, objectType, objectKey, objectName, storeKey, objectIDToObjectTypeMap, objectTypeToObjectIDMap,
        // methods
        objectURL, encodeOwnerKey, decodeOwnerKey
    }

}

export function objectUpdateFunction() {
    const store = useStore()
    const objectUpdate = (obj) => {
        return new Promise((resolve, reject) => {

            if (obj && obj.objectKey) {
                store.dispatch('objectCache/findObject', { objectKey: obj.objectKey }).then((updatedObj) => {
                    console.log('objectUpdate: ', updatedObj)
                    resolve(updatedObj)
                }).catch(error => {
                    reject(error)
                })
            }
            else
                reject('bad ObjectKey')
        })
    }
    return { objectUpdate }
}

// not used
export function getObjectUpdateTools(detailsRequest, resultsSet, dashboard, realID) {

    const store = useStore()

    const objectUpdate = (obj) => {
        if (obj.objectKey) {
            store.dispatch('objectCache/findObject', { objectKey: obj.objectKey }).then((newHit) => {
                console.log('objectUpdate: ', newHit)
                if (newHit && newHit.obj) {
                    if (detailsRequest.value && detailsRequest.value.obj && detailsRequest.value.obj.objectKey == obj.objectKey)
                        detailsRequest.value.obj = newHit.obj

                    if (resultsSet.value) searchHitsUpdate(obj.objectKey, newHit)
                    if (dashboard.value) dashboardHitsUpdate(obj.objectKey, newHit)
                }
            })
        }
    }
    const searchHitsUpdate = (objectKey, newHit) => {
        if (resultsSet.value && resultsSet.value.hits && realID.value) {
            resultsSet.value.hits.forEach((hit, idx) => {
                if (hit && hit.objectKey == objectKey) {
                    store.commit('search/UPDATE_SEARCHRESULT_HIT', { id: realID.value, hitIDX: idx, newHit: newHit.obj })
                }
            })
        }
    }
    const dashboardHitsUpdate = (objectKey, newHit) => {
        if (dashboard.value && dashboard.value.cards) {
            dashboard.value.cards.forEach((card, idx) => {
                if (card && card.data && card.data.hits) {
                    card.data.hits.forEach((hit, jdx) => {
                        if (hit && hit.objectKey == objectKey) {
                            store.commit('dashboards/UPDATE_CARD_HIT', { dashboardID: dashboard.value.id, cardIDX: idx, hitIDX: jdx, newHit: newHit.obj })
                        }
                    })
                }
            })
        }
    }

    return {
        // methods
        objectUpdate, searchHitsUpdate, dashboardHitsUpdate
    }
}