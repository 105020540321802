<template>
    <footer class="sidebar-footer">
        <template v-if="actions">
            <div class="menu-horizontal">
                <div class="menu-item button-secodnary" v-if="showCancel" @click.prevent="$emit('close-panel', $event)">Cancel</div>
                <slot name="sidebar-footer" />
            </div>
        </template>
    </footer>
</template>

<script>
    export default {
        name: 'SidebarFooter',
        props: {
            actions: {
                type: Boolean,
                default: true
            },
            showCancel: {
                type: Boolean,
                default: false
            },
        },
        components: {

        },
        emits: ['close-panel'],
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>
</style>