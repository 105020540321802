<template>
    <modal v-model="model" :title="title" :actionbuttons="true" :saveTitle="hasQueryChanges ? 'Apply and Save' : 'Save'" @save="save">
        <template #body>
            <span v-show="card" class="cardIndicator margin-bottom-small" :title="linkedCardDashName"><i class="far fa-tachometer-alt-fast"></i> <b>{{card.name }}</b> {{card.ownerName ? '(' + card.ownerName + ')' : '' }}</span>
            <section v-if="hasQueryChanges" class="fullwidth">
                <p>Do you want to apply the following changes to your Card? </p>
                <ul class="list-blocks single-box" style="width: 100%;">
                    <template v-for="(value, key) in queryChanges" v-bind:key="key">
                        <li v-if="value.newValue == null">Removed <span class="tag-filter"><span>{{value.name}}{{value.value ? ':' : '' }} {{value.value}}</span></span></li>
                        <li v-else-if="value.value == null">Added <span class="tag-filter"><span>{{value.name}}{{value.newValue ? ':' : '' }} {{value.newValue}}</span></span></li>
                        <li v-else>Changed <span class="tag-filter"><span>{{value.name}}</span></span> to <span class="tag-filter"><span>{{value.newValue}}</span></span></li>
                    </template>
                </ul>
            </section>
            <section v-else class="fullwidth">
                No unsaved changes found in the Card's query.
            </section>
        </template>
        </modal>
</template>

<script>

    import { computed } from 'vue'
    import { useModelWrapper } from '../../composables/ModelWrapper.js'


    export default {
        name: 'QueryChanges',
        emits: ['save', 'update:modelValue'],
        props: {
            title: { type: String },
            card: { type: Object },
            modelValue: { type: Boolean, default: false },
            loading: Boolean,
            queryChanges: { type: Object },
        },
        setup(props, { emit }) {

            const model = useModelWrapper(props, emit)

            // computed
            const hasQueryChanges = computed(() => props.queryChanges ? Object.keys(props.queryChanges).length > 0 : false)
            const linkedCardDashName = computed(() => {
                if (props.card)
                    return (props.card.ownerID && props.card.ownerName
                        ? 'Card is in the "' + props.card.ownerName + '" dashboard'
                        : 'Card does not belong to any dashboard')
                return null
            })
            // methods
            const save = () => {
                emit('save')
                model.value = false
            }

            return {
                model,
                // computed
                hasQueryChanges, linkedCardDashName,
                // methods
                save
            }
        }
    }
</script>

<style scoped lang="scss">
</style>