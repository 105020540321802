<template>
    <section v-if="hasUser" class="">
        <user-select v-model="selectedUsers" :showInactive="showInactive" :multiple="true" :inpopup="inpopup" @selected="userSelect" />
    </section>
    <section v-if="checklistArray" class="margin-top-min">
        <checkbox-list v-model="checklistArray" idField="id" labelField="label" selectField="selected" size="xsmall" />
    </section>
    <section v-if="advanced" class="margin-top-min">
        <checkbox v-model="showInactive" label="Show Inactive Users" :showAsSwitch="true" />
    </section>
    <section v-if="hasDate" class="margin-top-min">
        <date-range-picker v-model="dateRangeModel" direction="left" @selected="dateSelect" />
    </section>
    <section v-if="canBool" class="margin-top-min">
        <checkbox v-model="notSelected" :label="notLabel" />
    </section>
</template>

<script>
    import { ref, computed, onMounted, nextTick } from 'vue'
    import { useStore } from 'vuex'
    import { searchFilterProps, searchFilterEmits, searchFilterSetup } from '../../composables/SearchTools.js'
    import { getDateRangeTools } from '../../composables/DateRangeTools.js'
    
    import DateRangePicker from '../baseComponents/DateRangePicker.vue'
    import UserSelect from '../baseComponents/UserSelect.vue'
    import Checkbox from '../ui/forms/Checkbox.vue'
    import CheckboxList from '../ui/forms/CheckboxList.vue'

    export default {
        name: 'UserDateFilter',
        components: { UserSelect, DateRangePicker, Checkbox, CheckboxList },
        props: {
            ...searchFilterProps()
        },
        emits: [...searchFilterEmits()],
        setup(props, { emit }) {
            const store = useStore()
            const { defaultDateRange } = getDateRangeTools()

            // const
            const userDateSearchCaseMap = {
                'unknown': 0, 'entered': 1, 'changed': 2, 'sourceUser': 4, 'recentView': 8, 'contacted': 16, 'alerts': 32,
                'businessIntelligence': 64, 'acquisitions': 128, 'flagged': 256, 'rated': 512, 'messageLeft': 8196,
                'belled': 16392, 'eventLog': 32784, 'events': 65568
            }
           


            // models
            const { model, filterDef, filterMap, apply, setModelProp, initialFilterState } = searchFilterSetup(props, emit)

            // data
            const showInactive = ref(false)
            const dateSelected = ref(false)
            const userSelected = ref(false)

            // computed
            const user = computed(() => store.state.userInfo.user.userInfo)
            const users = computed(() => store.state.userInfo.users)
            const allUsers = computed(() => store.getters['userInfo/allUsers'])

            const newFilterValue = computed(() => {
                return {
                    caseKey: props.filterKey,
                    userDateSearchCase: filterDef.value ? filterDef.value.userDateSearchCase : 0,
                    userIDs: [],
                    dateRangeKey: defaultDateRange.key,
                    dateRange: defaultDateRange,
                    catIDs: !hasValues.value ? [] : null,
                    values: hasValues.value ? [] : null,
                    notUserDate: false
                }
            })
            const hasDate = computed(() => filterDef.value ? filterDef.value.hasDate : false)
            const hasUser = computed(() => filterDef.value ? filterDef.value.hasUser : false)
            const hasValues = computed(() => filterDef.value ? filterDef.value.hasValue : false)
            const canBool = computed(() => filterDef.value ? filterDef.value.canBool : false)
            const notLabel = computed(() => filterDef.value ? "Don't match '" + filterDef.value.name + "' conditions" : 'Does not match conditions above')
            const filterValue = computed({
                get: () => {
                    return props.modelValue && props.modelValue.value ? JSON.parse(props.modelValue.value) : newFilterValue
                },
                set: (newVal) => {
                    //console.log('filterValue-setter', newVal)
                    var newModel = model.value ?? initialFilterState.value
                    if (newVal) {
                        newModel.value = JSON.stringify(newVal)
                        newModel.valueName = getValueName(newVal)
                        newModel.hasValue = (newVal.dateRangeKey && newVal.dateRangeKey != defaultDateRange.key) ||
                            (newVal.userIDs && newVal.userIDs.length > 0)
                    }
                    else {
                        newModel.value = null
                        newModel.valueName = null
                        newModel.hasValue = false
                    }
                    model.value = newModel
                    //emit('update:modelValue', model.value)
                }
            })
            const selectedUsers = computed({
                get: () => {
                    return toUsers(filterValue.value.userIDs)
                },
                set: (newVal) => {
                    var newFilterVal = filterValue.value
                    newFilterVal.userIDs = newVal ? newVal.map((user) => { return user.id }) : []
                    filterValue.value = newFilterVal
                    applyIfPossible()
                }
            })
            const notSelected = computed({
                get: () => {
                    return filterValue.value.notUserDate
                },
                set: (newVal) => {
                    var newFilterVal = filterValue.value
                    newFilterVal.notUserDate = newVal
                    filterValue.value = newFilterVal
                }
            })
            const dateRangeModel = computed({
                get: () => {
                    return filterValue.value.dateRange ?? defaultDateRange
                },
                set: (newVal) => {
                    var cleanVal = newVal ?? defaultDateRange
                    var newFilterVal = filterValue.value
                    newFilterVal.dateRange = cleanVal
                    newFilterVal.dateRangeKey = cleanVal.key
                    filterValue.value = newFilterVal
                    applyIfPossible()
                }
            })
            const selectedCheckList = computed({
                get: () => {
                    return hasValues.value ? filterValue.value.values : filterValue.value.catIDs
                },
                set: (newVal) => {
                    var newFilterVal = filterValue.value
                    if (hasValues.value)
                        newFilterVal.values = newVal
                    else
                        newFilterVal.catIDs = newVal
                    filterValue.value = newFilterVal
                }
            })
            const checklistArray = computed({
                get: () => toCheckList(selectedCheckList.value, filterMap.value),
                set: (newVal) => {
                    var selected = newVal.filter((item) => { return item.selected })
                    selectedCheckList.value = selected.map((item) => { return item.id })
                }
            })
            const inActiveUserSelected = computed(() => selectedUsers.value ? selectedUsers.value.filter((u) => !u.active).length > 0 : false)

            // Methods            
            const getValueName = (newVal) => {
                if (!newVal) return null
                var list = toCheckList((hasValues.value ? newVal.values : newVal.catIDs), filterMap.value)
                return (newVal.dateRangeKey && newVal.dateRangeKey != defaultDateRange.key ? newVal.dateRange.description + ' | ' : '') +
                    (newVal.notUserDate ? "NOT | " : "") +
                    (newVal.userIDs && newVal.userIDs.length > 0 ? (toUsers(newVal.userIDs).map((u) => { return u.name }).join(",") + " | ") : "") +
                    (list.filter((item) => { return item.selected }).map((u) => { return u.name })).join(",")
            }
            const toCheckList = (values, valMap) => {
                var list = null
                if (valMap) {
                    list = Object.keys(valMap).map((key) => {
                        return {
                            id: key,
                            entry: valMap[key],
                            selected: values && values.includes(key),
                            label: valMap[key]
                        }
                    })
                }
                return list
            }
            const toUsers = (userIDs) => {
                return userIDs ? userIDs.map((id) => {
                    var usr = allUsers.value.find((u) => { return u.id == id })
                    return (usr ? usr : { id: id, name: 'unknown' })
                }) : []
            }
            const dateSelect = () => {
                dateSelected.value = true
                if (model.value && !model.value.hasValue)
                    setModelProp('hasValue', true)
            }
            const userSelect = () => {
                userSelected.value = true
                if (model.value && !model.value.hasValue)
                    setModelProp('hasValue', true)
            }
            const applyIfPossible = () => {
                nextTick(() => {
                    if (dateSelected.value && userSelected.value)
                        apply()
                })
            }

            // lifecycle
            onMounted(() => {
                dateSelected.value = false
                userSelected.value = false
            })

            return {
                // const
                userDateSearchCaseMap, defaultDateRange,
                // models
                model,
                // data
                selectedUsers, selectedCheckList, notSelected, dateRangeModel, showInactive, 
                // computed
                filterDef, user, users, newFilterValue, hasDate, hasUser, hasValues, canBool, notLabel, userSelected, dateSelected,
                filterValue, checklistArray, inActiveUserSelected, initialFilterState,
                // methods
                apply, getValueName, toCheckList, toUsers, userSelect, dateSelect, applyIfPossible
            }

        }
    }
</script>

<style scoped lang="scss">
    
</style>
