import { DateTime } from 'luxon'

export default {
    methods: {
        decorateHighlights: function (opt) {
            var modBody = opt.body
            if (modBody && opt.tokens) {

                var tokens = opt.tokens;
                var highlightContainer = (opt.highlightContainer ? opt.highlightContainer : "span")
                var highlightClass = (opt.highlightClass ? opt.highlightClass : "text-highlight")

                tokens.forEach((token) => {
                    modBody = modBody.replace(new RegExp('(' + token + ')(?!([^<]+)?>)', 'gi'), '<' + highlightContainer + ' class="' + highlightClass + '">$1</' + highlightContainer +'>');
                });
            }
            return modBody
        },
        ellipseString(theString, limit, addEllipsis, isHtml) {
            if (isHtml) {
                var noHtmlString = theString.replace(/(<([^>]+)>)/ig, '');
                var htmlBuffer = theString.length - noHtmlString.length;
                limit = limit + htmlBuffer;
            }

            if (!theString || theString.length <= limit) return theString

            if (theString.lastIndexOf(' ') > 0)
                return theString.substr(0, theString.lastIndexOf(' ', limit)) + (addEllipsis ? "..." : "")
            return theString.substr(0, limit) + (addEllipsis ? "..." : "")
        },
        toShortDateString(theDate) {
            try {
                var dt = this.toLuxonDate(theDate)
                if (dt && dt.isValid)
                    return dt.toLocaleString(DateTime.DATE_SIMPLE)//.replace(', 12:00:00 AM', '')
            }
            catch (error) {
                console.error(error);
            }
            return null
        },
        toShortTimeString(theDate) {
            try {
                var dt = this.toLuxonDate(theDate)
                if (dt && dt.isValid)
                    return dt.toLocaleString(DateTime.TIME_SIMPLE)
            }
            catch (error) {
                console.error(error);
            }
            return null
        },
        toYear(theDate) {
            try {
                var dt = this.toLuxonDate(theDate)
                if (dt && dt.isValid)
                    return dt.year
            }
            catch (error) {
                console.error(error);
            }
            return null
        },
        luxonDate(date) {
            var convDate = convDate = date ? DateTime.fromJSDate(new Date(date)).toFormat('yyyy-LL-dd') : DateTime.now().toFormat('yyyy-LL-dd')
            return convDate;
        },
        toLuxonDate(theDate) {
            var result = null
            switch (typeof theDate) {
                case 'string':
                    result = DateTime.fromISO(theDate)
                    break
                case 'object':
                    result = DateTime.fromJSDate(theDate)
                    break
            }
            //console.log(result.invalidReason)

            return result;
        },
        sinceDate(theDate) {
            try {
                var dt = this.toLuxonDate(theDate)
                if (dt && dt.isValid)
                    return dt.toRelative({ base: DateTime.local() })
            }
            catch (error) {
                console.error(error);
            }
            return null
        },
        toEventDate(theDate, showTime=false) {
            try {
                var dt = this.toLuxonDate(theDate)
                if (dt && dt.isValid) {
                    if (dt.hasSame(DateTime.now(), 'day')) {
                        return dt.toLocaleString(DateTime.TIME_SIMPLE)
                    }

                    if (showTime)
                        return dt.toLocaleString(DateTime.DATETIME_SHORT)
                    else
                        return dt.toLocaleString(DateTime.DATE_SIMPLE)
                }
                else
                    return dt.invalidReason
            }
            catch (error) {
                console.error(error)
            }
            return null
        },
        capitalizeFirst(text) {
            return (text ? text.charAt(0).toUpperCase() + text.slice(1) : "")
        },
        fromCamelCase(theString) {
            var result = theString;
            if (theString !== undefined && theString != null && theString.length > 0) {
                result = theString.replace(/([A-Z])/g, " $1")
                result = result.charAt(0).toUpperCase() + result.slice(1) // capitalize the first letter - as an example.
            }
            return result
        },
        userInitials(userName) {
            return (!userName ? null : (userName.indexOf('.') > 0 ? userName[0] + userName.split(".")[1][0] : (userName[2] ? userName[0] + userName[2] : userName.substring(0, 3))).toUpperCase())
        },
        numDigits(x) {
            return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1
        },
        scoreClass(factor) {
            var rtClass = 'danger'

            if (factor > 0.79)
                rtClass = 'success'
            else if (factor > 0.59)
                rtClass = 'info'
            else if (factor > 0.39)
                rtClass = 'warning'

            return rtClass
        },
        fetchLocal(key, defaultValue) {
            if (defaultValue == undefined) defaultValue = {}
            try {
                var val = localStorage.getItem(key)
                return val ? JSON.parse(val) : defaultValue
            }
            catch {
                return defaultValue
            }
        },
        saveLocal(key, val) {
            if (val === null)
                localStorage.removeItem(key)
            else {
                localStorage.setItem(key, JSON.stringify(val))
            }
        },
        changeIsSignificant(newVal, oldVal, delta = 0.0001) {
            return (Math.abs(newVal - oldVal) / oldVal) > delta
        }
    }
}