<template>
    <div>
        <term-picker :termKey="termKey"
                     v-model="filterValue"
                     :label="label"
                     :valueFieldName="valueFieldName"
                     :textFieldName="textFieldName"
                     :forceSelection="!canSelectText"
                     :canTextSelect="canSelectText"
                     :multiple="multiple"
                     :inpopup="inpopup"
                     @update:modelValue="updateSelectedTerms" />
    </div>
</template>

<script>
    import { useStore } from 'vuex'
    import { computed } from 'vue'
    import { searchFilterProps, searchFilterEmits, searchFilterSetup } from '../../composables/SearchTools.js'
    import _ from 'lodash'

    import TermPicker from './TermPicker.vue'

    export default {
        name: 'AutoCompleteFilter',
        components: { TermPicker },
        props: {
            ...searchFilterProps(),
            termKey: { type: String },
            label: { type: String },
            textFieldName: { type: String, default: 'label' },
            valueFieldName: { type: String, default: null },
            multiple: { type: Boolean, default: true },
        },
        emits: [...searchFilterEmits()],
        setup(props, { emit }) {
            // models
            const store = useStore()
            const { model, initialFilterState } = searchFilterSetup(props, emit)

            const filterValue = computed({
                get: () => {
                    var filterVal = (props.modelValue && props.modelValue.value ? JSON.parse(props.modelValue.value) : [])
                    if (props.valueFieldName && filterVal.length > 0) {
                        filterVal = []
                        var ids = JSON.parse(props.modelValue.value)
                        var labels = props.modelValue.valueName.split(",")
                        ids.forEach((id, idx) => {
                            var item = {}
                            item[props.valueFieldName] = id
                            item[props.textFieldName] = labels[idx]
                            filterVal.push(item)
                        })
                    }
                    return filterVal
                },
                set: (newVal) => {
                    var newModel = model.value ?? initialFilterState.value
                    if (newVal) {
                        if (!props.valueFieldName) {
                            newModel.value = JSON.stringify(newVal)
                            newModel.valueName = newVal.join(',')
                        }
                        else {
                            newModel.value = JSON.stringify(newVal.map((t) => { return t[props.valueFieldName] }))
                            newModel.valueName = newVal.map((t) => { return t[props.textFieldName] }).join(',')
                        }
                        newModel.hasValue = true
                    } else {
                        newModel.value = null
                        newModel.valueName = null
                        newModel.hasValue = false
                    }
                    model.value = newModel
                }
            })

            // computed
            const canSelectText = computed(() => props.valueFieldName == null)

            // methods
            const updateSelectedTerms = (newVal) => {
                _.noop(newVal)
                console.log('updateSelectedTerms', newVal)
                if(props.termKey == 'lists' && newVal && newVal[0] && newVal[0].id)
                     store.dispatch('search/setTermUrl', { termType: 'subsets', url: 'List/subsetSuggestions?listID=' + newVal[0].id + '&' })
            }
           
            return {
                // model
                model, filterValue, initialFilterState,
                // computed
                canSelectText,
                // methods
                updateSelectedTerms
            }
        }
    }
</script>

<style scoped lang="scss">
    
</style>
