export default {
    name: 'mqHelper',
    data() {
        return {
            smallscreens: ['smallscreen', 'mobile', 'min'],
            mqBreakpoints: { min: 375, mobile: 660, smallscreen: 960, tablet: 1024, laptop: 1200, desktop: 1440, xlarge: Infinity }
        }
    },
    computed: {
        isMobile() {
            return this.smallscreens.includes(this.$mq)
        },
        screenSizes() {
            return Object.keys(this.mqBreakpoints)
        }
    },
    methods: {
        thisScreenOrSmaller(screenSize) {
            if (this.screenSizes.includes(screenSize)) {
                var size = this.mqBreakpoints[screenSize]
                var hiddenScreens = this.screenSizes.filter((s) => { return this.mqBreakpoints[s] <= size })
                return hiddenScreens.includes(this.$mq)
            }
        },
        thisScreenOrLarger(screenSize) {
            if (this.screenSizes.includes(screenSize)) {
                var size = this.mqBreakpoints[screenSize]
                var visibleScreens = this.screenSizes.filter((s) => { return this.mqBreakpoints[s] >= size })
                return visibleScreens.includes(this.$mq)
            }
        }
    }
}