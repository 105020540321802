import _ from 'lodash'
import { useModelWrapper } from './ModelWrapper.js'
import { computed, ref, nextTick } from 'vue'

export const editorProps = () => ({
    editorID: { type: String },
    editing: { type: Boolean },
    inpopup: { type: Boolean, default: false },
    showHeader: { type: Boolean, default: true },
    showSave: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    advanced: { type: Boolean },
    errors: { type: Object, default: null },
    obj: { type: Object }
})
export const editorEmits = () => (['update:modelValue', 'update:editing', 'update:advanced', 'update:errors', 'dirtied', 'cleaned'])

export function editorSetup(props, emit, dirtiableProps, initializedModel) {
    // models
    const model = initializedModel ? initializedModel : useModelWrapper(props, emit, 'modelValue')
    const advancedMode = useModelWrapper(props, emit, 'advanced')
    const isEditing = useModelWrapper(props, emit, 'editing', (value) => {
        if (_.isArray(model.value)) {
            if (value) {
                model.value.forEach((item) => { editingPerItem.value[item.id] = value })
            }
            else
                editingPerItem.value = {}
        }
    })
    const errState = useModelWrapper(props, emit, 'errors')

    // data
    const showSpinLoader = ref(false)
    const baseModel = ref(_.cloneDeep(model.value))
    const savingEdits = ref(false)
    const expanded = ref({})
    const editingPerItem = ref({})

    //computed 
    const dirtyProps = computed(() => {
        if (!model.value)
            return {}
        if (dirtiableProps) {
            var dirtyObject = {}
            dirtiableProps.forEach((propName) => {
                dirtyObject[propName] = model.value[propName]
            })
            return dirtyObject
        }
        return model.value
    })
    const dirtyCount = computed(() => {
        return model.value ? model.value.filter((el) => { return el.dirty }).length : 0
    })
    const deletedCount = computed(() => {
        return model.value ? model.value.filter((el) => { return el.delete && el.id > 0 }).length : 0
    })
    const activeItems = computed(() => {
        return model.value ? model.value.filter((item) => { return !item.delete }) : []
    })

    // methods
    const onSave = (req, editorName, emitter, saveChanges) => {
        if (req.editorID == props.editorID && props.editorID) {
            {
                console.log("saveEditor received!", editorName, req)
                var objKey = props.obj ? props.obj.objectKey : null;
                saveChanges(req.suppressToastMessages).then((result) => {
                    console.log(result)
                    emitter.emit(req.complete, { objectKey: objKey, editorName: editorName, editorID: props.editorID, result: { status: 'success', message: null, changed: result.changed }, origin: editorName + '-editor' })

                }).catch(error => {
                    emitter.emit(req.complete, { objectKey: objKey, editorName: editorName, editorID: props.editorID, result: { status: 'fail', message: error }, origin: editorName + '-editor' })
                })
            }
        }
    }
    const rollbackModel = () => {
        model.value = _.cloneDeep(baseModel.value)
        if (_.isArray(model.value)) {
            editingPerItem.value = {}
            expanded.value = {}
        }

        nextTick(() => {
            if (_.isArray(model.value)) {
                model.value.forEach((item) => {
                    item.delete = false
                    item.dirty = false
                })
            }
            else
                model.value.dirty = false
        })
    }


    return {
        // models
        model, advancedMode, errState, isEditing,
        // data
        showSpinLoader, baseModel, savingEdits, expanded, editingPerItem,
        // computed
        dirtyProps, dirtyCount, deletedCount, activeItems,
        // methods
        onSave, rollbackModel
    }

}

export function getID(seed) {
    return 'el-' + _.random(0, (new Date() * 10000) + 621355968000000000) + seed
}
