<template>
    <div v-show="tagHTML" class="fullwidth"><h4>Preview:</h4></div>
    <div class="fullwidth" v-show="isMultipleTags">(multiple tags)</div>
    <h3 style='overflow: hidden;'><span v-html="tagHTML" /></h3>
    <form v-on:submit.prevent="onSave">
        <div class="fullwidth">
            <tag-picker v-if="fullEditor" ref="hashPicker" tagType="hashtag" :inpopup="inpopup" :showIcon="false" :multiple="false" :initialValue="hashTag" :allowText="false" :allowNewTag="true" @change="onHashChange" />
        </div>
        <div class="fullwidth margin-top-min">
            <tag-picker ref="atPicker" tagType="attag" :inpopup="inpopup" :showIcon="false" :multiple="fullEditor" :initialValue="tagValues" :allowText="fullEditor ? true : false" :allowNewTag="false" :placeholder="fullEditor ? 'Type @ or any other value' : ''" @change="onValueChange" />
        </div>
        <div class="flex-rows no-responsive fullwidth space-between margin-top-small">
            <btn ref="saveTags" v-if="showSave" el="button" title="Save" type="primary" @click.prevent="onSave">Save</btn>
        </div>
    </form>
</template>

<script>

    import TagPicker from './TagPicker.vue'
    import tagHelper from '../lib/tagHelper.js'

    import _ from 'lodash'
    import { mapGetters } from 'vuex'
    import { nextTick } from 'vue'

    export default {
        name: 'TagEditor',
        components: { TagPicker },
        emits: ['update:modelValue', 'tagsaved'],
        props: {
            modelValue: Object,
            inpopup: {
                type: Boolean,
                default: false
            },
            fullEditor: {
                type: Boolean,
                default: true
            },
            initialFocus: {
                type: String,
                default: 'hashtag'
            },
            showSave: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                tagModel: { name: '', values: [], userID: 0, date: new Date() },
                hashTag: null,
                tagValues: [],
                loading: true
            }
        },
        computed: {
            ...mapGetters({
                objectTypeIdToStringMap: 'search/objectTypeIdToStringMap',
                objectTypeStringToIdMap: 'search/objectTypeStringToIdMap',
                currentUserID: 'userInfo/currentUserID'
            }),
            isMultipleTags() {this.tagModel.values
                return ((!this.tagModel.name || this.tagModel.name == 'atTag') && this.tagModel.values.length > 1)  ? true : false
            },
            tagHTML() {
                var valsHtml = '';
                var vals = this.tagModel.values.map((val) => {
                    if (val.ownerID && val.ownerTypeID) {
                        valsHtml = valsHtml + (valsHtml ? ', ' : '') + tagHelper.tagUtils.buildAtTagSpan(val.value.slice(1), val.ownerID, val.ownerTypeID)
                        return { 'tagID': val.ownerID, 'tagName': val.value.slice(1), 'tagType': val.ownerTypeID }
                    }
                    else {
                        var cleanVal = val.value.replace(/\s+/g, ' ').trim()
                        valsHtml = valsHtml + (valsHtml ? ', ' : '') + cleanVal
                        return cleanVal
                    }
                });

                if (this.tagModel.name && this.tagModel.name != 'atTag') {
                    var cleanName = this.tagModel.name.replace(/\s+/g, ' ').trim()
                    return tagHelper.tagUtils.buildHashTagSpan(cleanName, vals)
                }
                else
                    return valsHtml;

            },
            tagText() {
                if (this.tagModel) {
                    if (this.fullEditor) {
                        var txt = this.tagModel.name
                        if (this.tagModel.values && this.tagModel.values.length > 0) {
                            var vals = this.tagModel.values.map((val) => val.value)
                            txt += ":" + vals.join(',')
                        }
                        return txt
                    }
                    else if (this.tagModel.values && this.tagModel.values.length > 0 && this.tagModel.values[0] && this.tagModel.values[0].ownerID) {
                        return this.tagModel.values[0].value
                    }
                }
                return null
            }
        },
        methods: {
            async onSave() {
                await nextTick();
                this.$emit('tagsaved', { tag: this.tagModel, html: this.tagHTML })
            },
            async onHashChange(hash) {
                console.log('onHashChange', hash, (hash[0] ? hash[0] : null), (hash[0] ? hash[0].name : ''))
                this.hashTag = hash[0] ? hash[0] : null
                this.tagModel.name = this.hashTag ? this.hashTag.name : ''
                this.$emit('update:modelValue', this.tagModel)

                await nextTick();
                if (this.hashTag && this.$refs.atPicker) this.$refs.atPicker.focus();
            },
            async onValueChange(values) {
                if (values) {
                    var vals = [...values]
                    this.tagValues = vals
                    this.tagModel.values = vals.map((tag) => {
                        console.log('onValueChange', tag)
                        if (tag.ownerTypeID && tag.ownerID)
                            return { value: tag.value, ownerID: tag.ownerID, ownerTypeID: tag.ownerTypeID }
                        else
                            return { value: tag.value }
                    })
                }
                else {
                    this.tagValues = []
                    this.tagModel.values = []
                }
                this.$emit('update:modelValue', this.tagModel)

                await nextTick();
                if (this.$refs.atPicker) this.$refs.atPicker.focus();
            }
        },
        mounted() {
            if (this.modelValue) {
                this.tagModel = _.cloneDeep(this.modelValue)

                //clean up model name to make sure it has #
                if (!this.tagModel.name.startsWith('#') && this.tagModel.name != 'atTag')
                    this.tagModel.name = '#' + this.tagModel.name;

                this.hashTag = this.tagModel.name != 'atTag' ? { name: this.tagModel.name } : { name: '#' }

                if (this.tagModel.values) {

                    //clean up model values to make sure they have @
                    this.tagModel.values = this.tagModel.values.filter((tag => tag.value));
                    this.tagModel.values.forEach((tag) => {
                        if (tag.ownerID && !tag.value.startsWith('@'))
                            tag.value = '@' + tag.value;
                    });

                    this.tagValues = this.tagModel.values.map((tag) => {
                        if (tag.ownerTypeID && tag.ownerID)
                            return { tagType: 'atTag', value: tag.value, ownerID: tag.ownerID, ownerTypeID: tag.ownerTypeID }
                        else
                            return { value: tag.value }
                    });

                }

                //set the values
                if (this.fullEditor) this.$refs.hashPicker.setSelectedTag(this.hashTag);
                this.$refs.atPicker.setSelectedTag(this.tagValues);
            }
            else {
                if (this.fullEditor) {
                    this.$refs.hashPicker.setSelectedTag({ name: '#' });

                    //this feels a little hacky but I haven't been able to figure out a better way to do it
                    //-using initialValue in the TagPicker or modelValue in the tagEditor breaks for multiple mode because it takes the text out of the input
                    //-going deeper into the autocompleter and giving the input component a value prop makes the autocompleter very buggy for some reason
                    this.$refs.atPicker.$refs.autocomplete.$refs.input.value = '@';
                }
                else
                    this.$refs.atPicker.setSelectedTag([{ value: '@' }]);
            }



            if (!this.tagModel.userID)
                this.tagModel.userID = this.currentUserID


            if (this.fullEditor && this.hashTag == null && this.initialFocus.toLowerCase() == 'hashtag') {
                if (this.$refs.hashPicker) this.$refs.hashPicker.focus();
            }
            else {
                if (this.$refs.atPicker) this.$refs.atPicker.focus();
            }
        }
    }
</script>

<style lang="scss">
    .suggest-hitcount{
        font-style: italic;
        font-weight: 600;
        color: forestgreen;
    }
    .newTagSuggestion{
        font-weight:300;
        color:dodgerblue
    }
</style>
