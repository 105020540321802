<template>
    <auto-complete v-model="model" :suggestions="filteredLists" @complete="search($event)" field="name" @item-select="$emit('selected')"  @item-unselect="$emit('unselected')" 
                   :multiple="multiple" :forceSelection="true" :appendTo="appendTo">
    </auto-complete>
</template>
<script>
    import _ from 'lodash'
    import { ref, watch } from 'vue'
    import { mapState } from 'vuex'

    import AutoComplete from '../PrimeVue/AutoComplete.vue'

    export default {
        name: 'BuyersListSelect',
        components: {
            AutoComplete
        },
        emits: ['update:modelValue', 'selected', 'unselected'],
        props: {
            modelValue: null,
            options: { type: Object, default: null },
            multiple: {
                type: Boolean,
                default: false
            },
            inpopup: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState({ buyersLists: state => state.lists.buyersListSummaries }),
            storeKey() {
                return this.options ? Object.keys(this.options).map((key) => { return key + "-" + this.options[key] }).join("_") : "all"
            },
            lists() {
                return this.buyersLists[this.storeKey]
            },
            appendTo: function () {
                return this.inpopup ? "self" : "body"
            }
        },
        methods: {           
            search(event) {
                var input = event.query.toLowerCase()
                if (input.length < 1) {
                    this.filteredLists = [...[]]
                }
                else {
                    this.filteredLists = this.lists.filter(list => {
                        return list.name.toLowerCase().startsWith(input) || list.description.toLowerCase().startsWith(input)
                    })
                }
            }
        },
        setup(props, context) {
            const model = ref(props.modelValue ? props.modelValue : null)
            const filteredLists = ref([])

            watch(model, (newModel, oldModel) => {
                _.noop(oldModel)
                context.emit('update:modelValue', newModel)
            }, { deep: true })


            return { model, filteredLists }
        }
    }
</script>
<style lang="scss">
</style>