import { getLocalStorageTools } from '../../composables/LocalStorageTools.js'

const { fetchLocal, saveLocal, saveLocalPromise } = getLocalStorageTools()
const localStoragePlugin = store => {
    store.fetchLocal = fetchLocal,
    store.saveLocal = saveLocal,
    store.saveLocalPromise = saveLocalPromise
}

export {
    localStoragePlugin
}
