import axios from 'axios'
import _ from 'lodash'

const state = () => ({
    events: {}
})

// getters
const getters = {
    eventLog(state) { return state.events }
}

// actions
const actions = {
    fetchEvents({ commit }, opt) {
        return new Promise((resolve, reject) => {
            if (!(opt && (opt.ownerKey || (opt.obj && opt.obj.objectKey)))) {
                reject({ fatal: false, message: 'Bad fetch event Options' })
                return
            }

            if (!opt.ownerKey)
                opt.ownerKey = opt.obj.objectKey
            if (!opt.limit)
               opt.limit = 99


            var eventLogState = { 'total': 0, 'events': [], 'ownerKey': opt.ownerKey, 'preparedEvents': [], 'groupedEvents': [], 'sessionEvents': [], 'fetched': false, 'exTime': 0.0 }
            console.log('event log fetch', opt.ownerKey)

            /*   Inner hits to be tested
             *   if (opt.obj && opt.obj.inner_hits && opt.obj.inner_hits.length > 0 && !opt.fetch) {
                eventLogState.total = opt.obj.inner_hits.length
                eventLogState.events = opt.obj.inner_hits
                eventLogState.preparedEvents = this.prepareEvents({ 'events': eventLogState.events, 'eventSource': 'events', 'fetchedEvents': false, 'obj': opt.obj })
                eventLogState.groupedEvents = this.groupEvents({ 'events': eventLogState.preparedEvents, 'eventView': 'category' })
                eventLogState.sessionEvents = this.groupEvents({ 'events': eventLogState.preparedEvents, 'eventView': 'session' })
                commit('SET_EVENTS', eventLogState)
                resolve({ 'fetched': false })
                return
            }*/

            axios.get('eventlog/Events/?ownerKey=' + encodeURIComponent(opt.obj.objectKey) + '&limit=' + opt.limit+ '&noViewed=' + (opt.noViewed ? 'true' : 'false') + '&noAggregates=true')
                .then(r => r.data)
                .then(results => {
                    if (results && results.resultsSet && results.resultsSet.total != 1) {
                        eventLogState.fetched = true
                        eventLogState.total = results.resultsSet.total
                        eventLogState.exTime = results.resultsSet.exTimes ? results.resultsSet.exTimes.total : -1
                        if (results.resultsSet.hits) {
                            results.resultsSet.hits.forEach((hit) => {
                                eventLogState.events.push(JSON.parse(hit.json))
                            })
                        }
                        eventLogState.preparedEvents = this.prepareEvents({ 'events': eventLogState.events, 'eventSource': 'events', 'fetchedEvents': true, 'obj': opt.obj })
                        eventLogState.groupedEvents = this.groupEvents({ 'events': eventLogState.preparedEvents, 'eventView': 'category' })
                        eventLogState.sessionEvents = this.groupEvents({ 'events': eventLogState.preparedEvents, 'eventView': 'session' })

                        commit('SET_EVENTS', eventLogState)
                        resolve({ 'fetched': true, 'total': eventLogState.total })
                    }
                    else {
                        resolve({ 'fetched': true, 'total': 0 })
                       // reject({ fatal: true, message: "events not found. id: " + opt.ownerKey })
                    }

                })
        })
    },
    searchEvents({ commit }, opt) {
        return new Promise((resolve, reject) => {

            _.noop(commit)
            if (!opt) {
                reject({ fatal: false, message: 'Bad search event Options' })
                return
            }

            var query = { skip: opt.skip ?? 0, limit: opt.limit ?? 10, criteria: {}, noAggregations: true }
            var ownerKey = this.buildOwnerKey(opt.obj)
            if (ownerKey) {
                query.criteria.ownerKeys = { key: 'ownerKeys', value: '["' + ownerKey + '"]' }                
            }
            console.log('event log search', ownerKey)

            var obj = this.buildOwnerObject(opt.obj)
            if (opt.criteria) {
                var udsp = { caseKey: "eventLog", dateRangeKey: opt.criteria.dateRangeKey ?? "AllTime", userDateSearchCase: 32784 }
                query.criteria.eventLog = { key: 'eventLog', value: JSON.stringify(udsp) }

                if (opt.criteria.eventType)
                    query.criteria.eventType = { key: 'eventType', value: JSON.stringify(opt.criteria.eventType) }
            }
            var eventLogState = { 'total': 0, 'events': [], 'ownerKey': opt.queryKey, 'query': query, 'preparedEvents': [], 'groupedEvents': [], 'sessionEvents': [], 'fetched': false, 'exTime': 0.0 }


            axios.post('eventlog/Events', query)
                .then(r => r.data)
                .then(resultsSet => {
                    if (resultsSet) {
                        eventLogState.fetched = true
                        eventLogState.total = resultsSet.total
                        eventLogState.exTime = resultsSet.exTimes ? resultsSet.exTimes.total : -1
                        if (resultsSet.hits) {
                            resultsSet.hits.forEach((hit) => {
                                eventLogState.events.push(JSON.parse(hit.json))
                            })
                        }
                        eventLogState.preparedEvents = this.prepareEvents({
                            'events': eventLogState.events, 'eventSource': 'events', 'fetchedEvents': true, 'obj': obj
                        })
                        if (opt.insertedEvents) {
                            eventLogState.preparedEvents = this.insertEvents(opt.insertedEvents, eventLogState.preparedEvents)
                            eventLogState.total = eventLogState.preparedEvents.length
                        }
                        eventLogState.groupedEvents = this.groupEvents({ 'events': eventLogState.preparedEvents, 'eventView': 'category' })
                        eventLogState.sessionEvents = this.groupEvents({ 'events': eventLogState.preparedEvents, 'eventView': 'session' })

                        commit('SET_EVENTS', eventLogState)
                        resolve({ 'fetched': true, 'total': eventLogState.total })
                        resolve(resultsSet)
                    }
                    else {
                        resolve({ 'fetched': true, 'total': 0 })
                        //reject({ fatal: true, message: "events not found. options: " + JSON.stringify(opt) })
                    }

                }).catch(function (error) {
                    reject({ fatal: true, message: error })
                })
        })
    }
}

// mutations
const mutations = {
    SET_EVENTS(state, opt) {
        state.events[this.encodeOwnerKey(opt.ownerKey)] = opt
    }
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
