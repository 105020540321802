<template>
    <template v-if="simpleMode">
        <div class="search-icon">
            <i class="far fa-search"></i>
        </div>
        <div class="search-input">
            <form v-on:submit.prevent="$emit('applyfilter')">
                <term-picker :termKey="simpleFieldName"
                             v-model="selectedTerms[simpleFieldName]"
                             placeholder="Search..."
                             textFieldName="value"
                             :forceSelection="false"
                             :canTextSelect="true"
                             :multiple="false"
                             :inpopup="true"
                             class="style-blank"
                             @item-select="onItemSelect"
                             @update:modelValue="updateSelectedTerms(simpleFieldName, $event)" 
                             :ref="(el)=>setItemRef(el,'phrasePicker')"
                             />
                <span ref="loseFocus" style="width: 1px; height:1px"></span>
            </form>
        </div>
    </template>
    <template v-else>
        <div v-for="(fldName, idx) in fieldNames" :key="fldName" :class="{'margin-top-min': idx != 0 }">
            <term-picker :termKey="fldName"
                         v-model="selectedTerms[fldName]"
                         :label="fields[fldName].label"
                         textFieldName="value"
                         :forceSelection="false"
                         :canTextSelect="true"
                         :inpopup="inpopup"
                         :multiple="fields[fldName].multiple"
                         :ref="(el)=>setItemRef(el, fldName + 'Picker')"
                         @update:modelValue="updateSelectedTerms(fldName, $event)" />
        </div>
    </template>
</template>

    <script>
        import { ref, computed, onBeforeUpdate, onMounted, onBeforeUnmount, nextTick, inject } from 'vue'
        import { searchFilterProps, searchFilterEmits, searchFilterSetup } from '../../composables/SearchTools.js'
        import { useItemRefs } from '../../composables/ModelWrapper.js'
        import _ from 'lodash'

        import TermPicker from './TermPicker.vue'

        export default {
            name: 'FullTextFilter',
            components: { TermPicker },
            props: {
                ...searchFilterProps(),
                filterKey: { type: String, default: 'fulltext' },
                simpleMode: { type: Boolean, default: false }
            },
            emits: [...searchFilterEmits()],
            setup(props, { emit }) {
                const emitter = inject("emitter")   // Inject event bus

                // model
                const { model, selectedValue, apply } = searchFilterSetup(props, emit)

                const { itemRefs, setItemRef, itemRefsReset } = useItemRefs()
                onBeforeUpdate(() => {
                    itemRefsReset()
                })

              // const
                const fieldObj = {
                    phrase: { label: 'Phrase Search', prefix: 'phrase', multiple: false },
                    mustMatch: { label: 'Must Match Words', prefix: ' Must Match', multiple: true },
                    mustNotMatch: { label: 'Must NOT Match Words', prefix: ' Must NOT Match', multiple: true },
                    shouldMatch: { label: 'Should Match Words', prefix: ' Should Match', multiple: true }
                }
                const simpleFieldName = 'phrase'

                const fieldNames = Object.keys(fieldObj)

                // data
                const fields = ref(fieldObj)

                // computed
                const selectedTerms = computed({
                    get: () => {
                        var terms = selectedValue.value ?? {}
                        fieldNames.forEach((fld) => {
                            if (!terms[fld])
                                terms[fld] = []
                            else if (!_.isArray(terms[fld]) && terms[fld].indexOf(']') > 0)
                                terms[fld] = JSON.parse(terms[fld])
                            else if (!_.isArray(terms[fld]))
                                terms[fld] = [terms[fld]]
                        })
                        return terms
                    },
                    set: (val) => {
                        console.log('selectedTerms-setter',val)
                        var valMod = _.cloneDeep(val)
                        if (valMod.phrase) {
                            var newPhraseVal = valMod.phrase
                            if (_.isArray(newPhraseVal))
                               newPhraseVal = valMod.phrase.join(" ")
                            valMod.phrase = newPhraseVal.replace('"', '')
                        }
                        var newModel =  model.value
                        newModel.value = JSON.stringify(valMod)
                        var valNames = []
                        valNames.push(termToString(val, 'phrase'))
                        valNames.push(termToString(val, 'mustMatch'))
                        valNames.push(termToString(val, 'mustNotMatch'))
                        valNames.push(termToString(val, 'shouldMatch'))
                        newModel.valueName =  valNames.filter((nm)=> nm && nm.length > 0).join(' | ')
                        newModel.hasValue = hasValue(valMod)
                        model.value = newModel
                    }
                })
                const hasValue = (newVal) => {
                    var termCount = 0
                    fieldNames.forEach((fld) => {
                        if (newVal[fld])
                            termCount += newVal[fld].length
                    })
                    return termCount > 0
                }

                //methods
                const termToString = (val, term) => {
                    console.log('termToString', val, term, val[term] )
                    return val[term] && val[term].length > 0 ? (fieldObj[term].prefix + ':' + (fieldObj[term].multiple ? val[term].join(',') : val[term])) : ''
                }
                const updateSelectedTerms = (fldName, newVal) => {
                    var terms = selectedTerms.value
                    terms[fldName] = newVal
                    selectedTerms.value = terms
                }
                const onItemSelect = () => {
                    console.log('FullTextFilter-onItemSelect')
                    apply()
                }
                const addTerm = (opt) => {
                    if (opt && opt.term) {
                        var fldName = opt.fieldName
                        var terms = selectedTerms.value
                        if (!fldName) {
                            fldName = 'mustMatch'
                            if (opt.term.trim().indexOf(' ') > 0 && !hasValue(terms)) {
                                fldName = 'phrase'                                
                            }
                        }
                        if (fldName == 'phrase')
                            terms.phrase = opt.term.trim()
                        else {
                            console.log('addTerm', terms[fldName].includes(opt.term), opt, terms[fldName])
                            if (terms[fldName].includes(opt.term)) {
                                console.log('ignoring term')
                                return // don't add an existing term
                            }
                            terms[fldName].push(opt.term.trim())
                        }
                        selectedTerms.value = terms
                        nextTick(() => {
                            emitter.emit('runSearch', { 'sender': model.value.key })
                        })
                    }
                }
                const applyFilter = (req) => {
                    console.log('applyFilter-fullTextfilter', req)
                    if (req.filter == 'term') {
                        addTerm({ term: req.filterVal })
                    }
                }
                onMounted(() => {
                    fields.value = _.cloneDeep(fieldObj)
                    emitter.on("applyFilter", applyFilter)
                })
                onBeforeUnmount(() => {
                    emitter.off("applyFilter", applyFilter)
                })

                return {
                    // tools
                    itemRefs, setItemRef, itemRefsReset,
                    // model
                    model, fields, fieldNames, simpleFieldName,
                    // computed
                    selectedTerms, hasValue,
                    // methods
                    apply, onItemSelect, termToString, updateSelectedTerms, addTerm,
                    focus
                }
            }
        }
    </script>

    <style scoped lang="scss">
    </style>
