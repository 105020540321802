<template>
    <template v-if="$route.name != 'Welcome'">
        <main-header></main-header>
    </template>
    <main-content>
        <router-view />
    </main-content>
</template>

<script>
    import MainHeader from './components/MainHeader.vue'
    import MainContent from './components/MainContent.vue'
    import { mapGetters } from 'vuex'
    import AuthHelper from './AuthHelper.js'
    import { inject } from 'vue'

    export default {
        name: 'App',
        components: {
            MainHeader, MainContent
        },
        setup () {
            const emitter = inject("emitter")   // Inject event bus     

            return { emitter}
        },
        data: function () {
            return {
                loadAsyncCalls: ['userInfo/fetchUsers'],
                loadUserCalls: ['userInfo/loadUser'],
                loadSearchCalls: ['search/loadSearchMaps', 'search/loadSearchDefinitions'],
                loadCalls: ['bookmarks/fetchBookmarks', 'userInfo/loadLayout', 'loadUserDashboards', 'letterwizard/loadEmailTemplates', 'lists/fetchBuyersLists']
            }
        },
        computed: {
            ...mapGetters({
                user: 'userInfo/currentUser'
            }),
            showMenus() {
                return this.$route.name != 'Welcome' || this.user 
            }
        },
        methods: {
            loadStoresPromise(loadCalls) {
                let promises = []
                if (loadCalls) {
                    console.log('loading stores')
                    for (let i = 0; i < loadCalls.length; i++) {
                        promises.push(new Promise((resolve, reject) => {
                            this.$store.dispatch(loadCalls[i]).then(() => {
                                console.log(loadCalls[i] + ' fetched')
                                this.emitter.emit('storeload', { call: loadCalls[i] })
                                resolve()
                            }).catch(error => {
                                reject(error)
                            })
                        })
                        )
                    }
                }
                return Promise.all(promises)
            }
        },
        beforeCreate() {
            console.log('beforeCreate App.vue: Authenticated?', AuthHelper.isAuthenticated())
        },
        created() {
            if (AuthHelper.isAuthenticated()) {
                this.$store.dispatch('initializeStores').then(() => {
                    console.log('initialize Stores')
                    this.loadStoresPromise(this.loadUserCalls).then(() => {
                        console.log('user stores loaded')
                        this.loadStoresPromise(this.loadSearchCalls).then(() => {
                            console.log('search stores loaded')
                            this.loadStoresPromise(this.loadCalls).then(() => {
                                console.log('all stores loaded')
                            }).catch(error => {
                                this.$toast.add({ severity: 'error', summary: 'Error loading User Stores', detail: error, life: 3000 })
                            })
                        }).catch(error => {
                            this.$toast.add({ severity: 'error', summary: 'Error loading Search Stores', detail: error, life: 3000 })
                        })
                    }).catch(error => {
                        this.$toast.add({ severity: 'error', summary: 'Error loading Vuex Stores', detail: error, life: 3000 })
                    })

                    console.log('initialize async Stores')
                    this.loadStoresPromise(this.loadAsyncCalls).then(() => {
                        console.log('async stores loaded')
                    })
                })
            }
        }
    }
</script>

<style lang="scss">
    @import url(https://fonts.googleapis.com/css?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400&display=swap);
    @import 'assets/sass/main.scss';
</style>