export default {
    data() {
        return {
            isShowing: false
        }
    },
    methods: {
        openPanel: function() {
            this.$refs.outerSidebar.openSidepanel();
        }
    }
};