<template>
    <div v-if="aggName && counts[aggName]" class="bnc-chart-container">
        <checkbox-list v-model="intervalList" idField="id" labelField="label" selectField="selected" class="list-horizontal distribute-evenly margin-top-min" :showAsBox="true" :multiple="multiCardCreate"></checkbox-list>
        <div :id="id" class="chartDiv"></div>
    </div>
    <div v-else>
        Graph Data unavailable.
    </div>

</template>

<script>
    import { ref, computed, inject, onMounted, watch } from 'vue'
    import { getSearchTools, searchAggregateSetup } from '../../composables/SearchTools.js'
    import { getID } from '../../composables/Editors.js'

    import _ from 'lodash'
    import * as am4core from "@amcharts/amcharts4/core"
    import * as am4charts from "@amcharts/amcharts4/charts"
    import am4themes_animated from "@amcharts/amcharts4/themes/animated"
    import CheckboxList from '../ui/forms/CheckboxList.vue'

    export default {
        name: 'AggregateGraph',
        props: {
            carddata: { type: Object },
            aggPanelName: { type: String },
            aggName: { type: String }
        },
        components: { CheckboxList },
        setup(props) {
            // tools
            const emitter = inject("emitter")   // Inject event bus 

            // Data
            const loading = ref(true)
            const hasData = ref(false)
            const activeInterval = ref(null)
            const id = ref(getID(props.aggName))

            // Computed
            const { searchMaps, aggDefs, aggPanelDefs } = getSearchTools()
            const panelDef = computed(() => aggPanelDefs.value && props.aggPanelName ? aggPanelDefs.value[props.aggPanelName] : null)
            const aggDef = computed(() => aggDefs.value && props.aggName ? aggDefs.value[props.aggName] : null)

            const intervals = computed(() => aggDef.value && aggDef.value.subKeys ? aggDef.value.subKeys : [])
            const intervalList = computed({
                get: () => intervals.value.map((interval) => { return { id: interval, label: interval + 's', selected: (interval == activeInterval.value) } }),
                set: (newVal) => {
                    activeInterval.value = newVal.filter((i) => i.selected)[0]
                }
            })
            const percentiles = computed(() => props.carddata ?
                (_.isString(props.carddata.percentiles) ? JSON.parse(props.carddata.percentiles) : props.carddata.percentiles) : null)

            const counts = computed(() => props.carddata ?
                (_.isString(props.carddata.counts) ? JSON.parse(props.carddata.counts) : props.carddata.counts) : {})

            const aggCounts = computed(() => aggDef.value ? counts.value[aggDef.value.key] : null)
            const aggMap = computed(() => aggDef.value && aggDef.value.map ? aggDef.value.map : (searchMaps.value ? searchMaps.value[aggDef.value.mapName] : searchMaps.value[aggDef.value.key]))

            const { queryID, query, docType, applyFilter } = searchAggregateSetup(props, emitter)

            const loadGraph = () => {
                am4core.useTheme(am4themes_animated);
                am4core.options.autoSetClassName = true;

                let chart = am4core.create(id.value, am4charts.XYChart);

                var data = [];
                var keyName = 'Date';
                var valName = aggDef.value ? aggDef.value.name : "Unknown";
                if (aggCounts.value) {
                    _.each(Object.keys(aggCounts.value), function (k) {
                        var obj = {};
                        obj[keyName] = aggMap.value && aggMap.value[k] ? aggMap.value[k] : k;
                        obj[valName] = aggCounts.value[k];

                        data.push(obj);
                    });
                }

                chart.data = data;

                let gradientOne = new am4core.LinearGradient();
                gradientOne.rotation = 90;
                gradientOne.addColor(am4core.color("#6980FF"));
                gradientOne.addColor(am4core.color("#96A7FF"));

                let gradientTwo = new am4core.LinearGradient();
                gradientTwo.rotation = 90;
                gradientTwo.addColor(am4core.color("#AA8BFF"));
                gradientTwo.addColor(am4core.color("#BCACFF"));

                let gradientThree = new am4core.LinearGradient();
                gradientThree.rotation = 90;
                gradientThree.addColor(am4core.color("#0096FF"));
                gradientThree.addColor(am4core.color("#BDE4FF"));

                var dropShadow = new am4core.DropShadowFilter();
                dropShadow.dx = 1;
                dropShadow.dy = 4;
                dropShadow.blur = 5;
                dropShadow.color = "#767E9F";
                dropShadow.opacity = 0.425;

                let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = keyName;
                categoryAxis.title.text = "";
                categoryAxis.marginTop = "8px";
                categoryAxis.background.cornerRadius = 10;
                categoryAxis.renderer.grid.template.location = 0;
                categoryAxis.renderer.minGridDistance = 10;
                categoryAxis.renderer.cellStartLocation = 0.35;
                categoryAxis.renderer.cellEndLocation = 0.65;
                categoryAxis.renderer.grid.template.disabled = true;
                categoryAxis.renderer.labels.template.fill = '#767E9F';
                categoryAxis.renderer.labels.template.fontSize = 11;
                categoryAxis.renderer.labels.template.fontWeight = 'bold';
                categoryAxis.id = 'category-axis';
                categoryAxis.background = new am4core.RoundedRectangle();
                categoryAxis.background.fill = '#F2F4FF';
                categoryAxis.background.cornerRadius(5, 5, 5, 5);
                categoryAxis.tooltip.background.fill = am4core.color("#FFF");
                categoryAxis.tooltip.label.fill = am4core.color("#767E9F");
                categoryAxis.tooltip.label.fontSize = 12;
                categoryAxis.tooltip.label.fontWeight = 'bold';
                categoryAxis.tooltip.background.strokeWidth = 0;
                categoryAxis.tooltip.background.cornerRadius = 3;
                categoryAxis.tooltip.background.pointerLength = 0;
                categoryAxis.tooltip.dy = 5;
                categoryAxis.tooltip.filters.push(dropShadow);

                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.title.text = "";
                valueAxis.renderer.grid.template.stroke = "#D1D7F0";
                valueAxis.renderer.grid.template.strokeOpacity = 0.75;
                valueAxis.renderer.grid.template.strokeWidth = 2;
                valueAxis.renderer.grid.template.strokeDasharray = "4,5";
                valueAxis.renderer.baseGrid.disabled = true;
                valueAxis.renderer.labels.template.fill = '#767E9F';
                valueAxis.renderer.labels.template.fontSize = 12;
                valueAxis.renderer.labels.template.fontWeight = 'bold';


                let series = chart.series.push(new am4charts.LineSeries());

                series.dataFields.valueY = valName;
                series.dataFields.categoryX = keyName;
                series.name = valName;
                series.tooltipText = "{name}: [bold]{valueY}[/]";
                series.stroke = "#2193F3";
                series.strokeWidth = 3;
                //series.tensionX = 0.8;
                series.stacked = true;
                series.fill = gradientThree;
                series.fillOpacity = 0.2;

                let bullets = series.bullets.push(new am4charts.CircleBullet());
                bullets.circle.radius = 5;
                bullets.circle.fill = am4core.color("#ffffff");
                bullets.circle.strokeWidth = 3;

                chart.cursor = new am4charts.XYCursor();

                //add title
                if (aggDef.value) {
                    let label = chart.chartContainer.createChild(am4core.Label);
                    label.text = aggDef.value.name;
                    label.align = "center";
                    label.marginTop = "10px";
                }

                // Add scrollbar
                chart.scrollbarX = new am4charts.XYChartScrollbar();
                chart.scrollbarX.series.push(series);

                function customizeGrip(grip) {
                    grip.icon.disabled = true;
                    grip.background.disabled = true;

                    var img = grip.createChild(am4core.Circle);
                    img.width = 15;
                    img.height = 15;
                    img.fill = am4core.color("#949CBC");
                    img.rotation = 45;
                    img.align = "center";
                    img.valign = "middle";

                    // Add vertical bar
                    var line = grip.createChild(am4core.Rectangle);
                    line.height = 60;
                    line.width = 3;
                    line.fill = am4core.color("#949CBC");
                    line.align = "center";
                    line.valign = "middle";

                }

                customizeGrip(chart.scrollbarX.startGrip);
                customizeGrip(chart.scrollbarX.endGrip);

                var scrollSeries = chart.scrollbarX.scrollbarChart.series.getIndex(0);
                scrollSeries.fillOpacity = 0;
                scrollSeries.strokeDasharray = "2,2";

                chart.scrollbarX.scrollbarChart.plotContainer.filters.clear();
            }

            // lifecycle
            onMounted(() => {
                loading.value = true
                hasData.value = false
                activeInterval.value = intervalList.value && intervalList.value[0] ? [intervalList.value[0].id] : []
                loadGraph()
            })

            watch(counts, () => {
                console.log('graph updating')
                loading.value = true
                hasData.value = false
                activeInterval.value = intervalList.value && intervalList.value[0] ? [intervalList.value[0].id] : []
                loadGraph()
            })

            return {
                // inject
                emitter,
                // getters
                searchMaps, aggDefs, aggPanelDefs,
                // Data
                loading, hasData, activeInterval, id,
                // Computed
                panelDef, aggDef, intervals, intervalList, aggCounts, aggMap,
                percentiles, counts, queryID, query, docType,
                // Methods
                applyFilter, loadGraph
            }
        }       
    };
</script>

<style scoped lang="scss">
    body {
        font-family: "Roboto", "Lucida Sans Unicode", "Lucida Grande", sans-serif;
        background-color: #f5f7ff;
    }

    div.chartDiv {
        width: 100%;
        height: 500px;
    }

    .bnc-chart-container {
        margin: 30px;
        padding: 30px;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 2px 4px 0 #e0e5fa;
    }
</style>