<template>
    <div class="record-views">
        <ul class="list-thumbnails">
            <li v-for="(u, idx) in latestViewUsers" :key="idx" class="user-initial" :title="updateUserName(u).name + ' (' + sinceDate(u.viewDate) + ')'">
                <span>{{ userInitials(u.name) }}</span>
            </li>
            <li v-if="viewExtraCount > 0" class="list-count" title="">{{ viewExtraCount }}+</li>
        </ul>
    </div>
</template>

<script>
    import { computed } from 'vue'
    import _sortBy from 'lodash/sortBy'
    import { getRecordTools } from '../../composables/RecordTools.js'
    import { getTextTools } from '../../composables/TextTools.js'
    import { getSearchTools } from '../../composables/SearchTools.js'

    export default {
        name: 'RecordViews',
        props: {
            'obj': { type: Object },
            'limit': { type: Number, default: 4 }
        },
        setup(props) {
            const { objectType } = getRecordTools(props)

            const viewUsers = computed(() => {
                if (props.obj && props.obj.users && objectType.value != 'comment') {
                    var flagUsers = props.obj.users.filter((flag) => { return flag.biCategory == 32 })
                    var sortedUsers = _sortBy(flagUsers, ['date']).reverse()
                    var vUsers = []
                    sortedUsers.forEach((u) => {
                        vUsers.push({ 'id': u.userID, 'name': u.userName, 'viewDate': u.date })
                    })
                    return vUsers
                }
                return []
            })
            const latestViewUsers = computed(() => viewUsers.value.slice(0, props.limit))
            const viewExtraCount = computed(() => viewUsers.value.length - props.limit)

            const { userInitials } = getTextTools()
            const { updateUserName } = getSearchTools()

            return {
                objectType, viewUsers, latestViewUsers, viewExtraCount,
                userInitials, updateUserName
            }
        }
    }
</script>

<style scoped lang="scss">
</style>