<template>
    <div v-show="show" :class="cellClasses"  @click="handleClick">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'TableCell',
        props: {
            title: { type: String, default: null },
            show: { type: Boolean, default: true },
            canExpand: { type: Boolean, default: true }
        },
        computed: {
            cellClasses() {
                return "table-cell " + (this.title ? this.title.toLowerCase() + ' ' : '') + (this.$attrs.class ? this.$attrs.class : '')
            }
        },
        methods: {
            handleClick(ev) {
                if (!this.canExpand)
                    ev.stopPropagation()
            }
        }
    }
</script>

<style scoped lang="scss">
</style>