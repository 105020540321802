<script>

    import CurrencyInput from './CurrencyInput.vue'
    import ScaledInput from './ScaledInput.vue'
    import { useModelWrapper } from '../../composables/ModelWrapper.js'

    import _ from 'lodash'
    import { h, ref, computed } from 'vue'

    export default {
        name: 'CurrencyInput',
        components: { CurrencyInput, ScaledInput },
        emits: ['update:modelValue', 'update:nativeValue', 'update:percentValue', 'focus'],
        props: {
            modelValue: Number,
            nativeValue: Number,
            percentValue: Number,
            revenueValue: Number,
            currency: Object,
            inputSize: { type: Number, default: 0 },
            showNative: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false },
            wrapper: Object
        },
        setup(props, { emit }) {
            const computingPercentage = ref(false)

            const currencyID = computed(() => props.currency && props.currency.id ? props.currency.id : 1)

            const toUSDRate = computed(() => (currencyID.value == 1 ? 1.0 :
                (props.currency && props.currency.toUSDRate ? props.currency.toUSDRate : 1.0)))

            const usdSideEffect = (newVal) => {
                if (!computingPercentage.value && props.revenueValue) {
                    computingPercentage.value = true
                    modelPercent.value = newVal / props.revenueValue
                    computingPercentage.value = false
                }
            }
            const percentSideEffect = (newVal) => {
                console.log('percentSideEffect', newVal, props.revenueValue, computingPercentage.value)
                if (!computingPercentage.value && props.revenueValue) {
                    computingPercentage.value = true
                    var usdVal = props.revenueValue * newVal
                    console.log('percentSideEffect', newVal, props.revenueValue, usdVal, toUSDRate.value)
                    modelUsd.value = usdVal
                    modelNative.value = usdVal / toUSDRate.value
                    computingPercentage.value = false
                }
            }

            const modelUsd = useModelWrapper(props, emit, 'modelValue', usdSideEffect)
            const modelNative = useModelWrapper(props, emit, 'nativeValue')
            const modelPercent = useModelWrapper(props, emit, 'percentValue', percentSideEffect)

            return { modelUsd, modelNative, modelPercent, computingPercentage }
        },
        watch: {
            "revenueValue": {
                handler(newVal) {
                    if (!this.computingPercentage && newVal && this.modelPercent) {
                        this.computingPercentage = true
                        this.modelUsd = newVal * this.modelPercent
                        this.computingPercentage = false
                    }
                    /*if (!this.computingPercentage && newVal && this.modelUsd) {
                        this.computingPercentage = true
                        this.modelPercent = this.modelUsd / newVal
                        this.computingPercentage = false
                    }*/
                }
            }
        },
        render() {
            //console.log('CurrencyPercentInput-render', this.modelUsd, this.modelNative, this.percentValue)
            var me = this
            const renderTag = function (tag) {
                if (tag) {
                    if (!tag.tag || !tag.tag.startsWith('$')) {
                        var tags = []
                        if (tag.children) {
                            tag.children.forEach((child) => {
                                var childTag = renderTag(child)
                                if (childTag)
                                    tags.push(childTag)
                            })
                        }
                        else if (tag.text)
                            tags = tag.text
                        return h(tag.tag ?? 'span', { class: tag.class }, tags)
                    }
                    else if (tag.tag == '$currency') {
                        return h(CurrencyInput, {
                            modelValue: me.modelUsd,
                            'onUpdate:modelValue': (value) => { me.modelUsd = value },
                            nativeValue: me.modelNative,
                            'onUpdate:nativeValue': (value) => { me.modelNative = value },
                            currency: me.currency,
                            scale: 6,
                            showNative: me.showNative,
                            precision: 2,
                            disabled: me.disabled,
                            inputSize: me.inputSize,
                            class: me.$attrs.class,
                            onFocus: $event => me.$emit('focus', $event)
                        })
                    }
                    else if (tag.tag == '$percent') {
                        return h(ScaledInput, {
                            modelValue: me.percentValue,
                            'onUpdate:modelValue': (value) => { me.modelPercent = value },
                            scale: -2,
                            suffix: '%',
                            disabled: me.disabled,
                            class: me.$attrs.class,
                            onFocus: $event => me.$emit('focus', $event)
                        })
                    }
                }
                return null
            }

            if (_.isArray(this.wrapper)) {
                var tags = []
                //console.log('render Array', this.wrapper)
                this.wrapper.forEach((tag) => {
                    //console.log('renderTag', tag)
                    tags.push(renderTag(tag))
                })
                return tags
            }
            else if (_.isObject(this.wrapper)) {
                //console.log('render Object', this.wrapper)
                return renderTag(this.wrapper)
            }
            else {
                // console.log('render No Wrapper')
                return [h(CurrencyInput, {
                    modelValue: this.modelUsd,
                    'onUpdate: modelValue': (value) => { me.modelUsd = value },
                    nativeValue: me.modelNative,
                    'onUpdate:nativeValue': (value) => { me.modelNative = value },
                    currency: this.currency,
                    scale: 6,
                    showNative: this.showNative,
                    precision: 2,
                    disabled: this.disabled,
                    inputSize: this.inputSize,
                    class: this.$attrs.class,
                    onFocus: $event => me.$emit('focus', $event)
                }),
                h(ScaledInput, {
                    modelValue: this.percentValue,
                    'onUpdate:modelValue': (value) => { me.percentValue = value },
                    scale: -2,
                    suffix: '%',
                    disabled: this.disabled,
                    class: this.$attrs.class,
                    onFocus: $event => me.$emit('focus', $event)
                }), h('h1', {}, 'Fred')]
            }
        }
    }
</script>
