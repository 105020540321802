<template>
    <section class="table-flex" :class="$attrs.class">
        <header class="table-head">
            <ul v-if="actualCellTitles">
                <li v-for="cellTitle in actualCellTitles" :key="cellTitle" :class="cellTitle.toLowerCase()">
                    <span>{{ cellTitle }}</span>
                </li>
            </ul>
        </header>
        <section class="table-body">
            <slot/>
        </section>
    </section>
</template>

<script>
    import { ref, computed, provide } from 'vue'

    export default {
        name: 'TableFlex',
        props: {
            colTitles: Array
        },
        setup(props) {

            const visibleCellTitles = ref([])

            const actualCellTitles = computed(() => {
                //console.log('visibleCellTitles', visibleCellTitles.value)
                return (props.colTitles ? props.colTitles : visibleCellTitles.value)
            })

            const setVisibleColumns = (cols) => {
                //console.log('setVisibleColumns', cols, visibleCellTitles.value)
                visibleCellTitles.value = cols
            }

            provide('setVisibleColumns', setVisibleColumns)

            return { visibleCellTitles, actualCellTitles, setVisibleColumns }
        }
    }
</script>

<style scoped lang="scss">
</style>