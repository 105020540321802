<template>
    <menu-vertical options="panel options medium">
        <menu-item label="New template" icon="plus" :href="adHocUrl" target="_blank" title="Send Email using new template" />
        <menu-item v-for="temp in emailTemplates" :key="temp.templateKey" :label="temp.name" icon="envelope" :href="adHocUrl + '&templateKey=' + temp.key" target="_blank" :title="temp.name" />
    </menu-vertical>
</template>

<script>
    import { computed } from 'vue'
    import { useStore } from 'vuex'
    import MenuItem from '../ui/menus/MenuItem.vue'
    import MenuVertical from '../ui/menus/MenuVertical.vue'

    export default {
        name: 'EmailTemplateMenu',
        components: {
            MenuItem, MenuVertical
        },
        props: {
            objectType: { type: String, default: 'company' },
            objectID: { type: Number, default: 0 }
        },
        setup(props) {

            const store = useStore()
            const emailTemplates = computed(() => store.getters['letterwizard/emailTemplates'])
            const objectKey = computed(() => props.objectType + '::' + props.objectID)
            const adHocUrl = computed(() => 'https://cmdm.berkerynoyes.com/letterwizard/blast/?' + props.objectType + 'ID=' + props.objectID )

            return { emailTemplates, objectKey, adHocUrl }
        }
    }
</script>

<style>
</style>