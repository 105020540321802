<template>
    <outer-sidebar :title="sidebarTitle" 
                   :footer="true" 
                   :showNavigator="true" 
                   :ignoreClickOutsideClasses="ignoreClickOutsideClasses"
                   :editing="isEditing"
                   :dirty="isDirty"
                   v-model="model"
                   @save="emitter.emit('saveEditsRecordBody', { editorID: this.editorID })"
                   @navigate="$emit('navigate', $event)" 
                   @titleclick="titleClick"
                   @titleeditclick="titleEditClick" 
                   ref="outerSidebar"
                   >
        <template #body>
            <record :key="objectKey"
                    v-model="detailsObj"
                    v-model:editing="isEditing"
                    v-model:dirty="isDirty"
                    v-model:selTab="selectedTab"
                    :editorID="editorID"
                    :cardDisplayType="4"
                    :recordContext="recordContext"
                    @editrequest="openEditMode($event)"
                    @editordone="editorDone($event)"
                    ref="modelRecord" />
        </template>
        <template #footer>
            <record-menu v-model:editing="isEditing"
                         :parentContext="parentContext"
                         :editorID="editorID"
                         :recordContext="recordContext"
                         :obj="detailsObj" />
        </template>
    </outer-sidebar>

</template>
<script>
    import { ref, computed, inject, provide } from 'vue'
    import { useStore } from 'vuex'
    import { useModelWrapper } from '../../composables/ModelWrapper.js'
    import { getRecordTools } from '../../composables/RecordTools.js'

    import OuterSidebar from '../ui/sidebars/OuterSidebar.vue'
    import Record from '../Records/Record.vue'
    import RecordMenu from "../Records/RecordMenu.vue"

    import { getMqTools } from '../../composables/MqTools.js'

    export default {
        name: 'RecordSidebar',
        props: {
            modelValue: { type: Boolean, default: false },
            detailsRequest: { type: Object },
            editorID: { type: String },
            parentContext: { type: String, default: '' },
        },
        components: {
            OuterSidebar, Record, RecordMenu
        },
        emits: ['navigate', 'update:modelValue'],
        setup(props, context) {
            // tools
            const store = useStore()
            const emitter = inject("emitter")
            const { isMobile } = getMqTools()


            // Data
            const recordContext = ref("sidebar")
            const fetching = ref(false)
            const isEditing = ref({})
            const isDirty = ref({})
            const viewTimer = ref(null)
            const model = useModelWrapper(props, context.emit, 'modelValue')
            const selectedTab = ref('')

            // Const
            const ignoreClickOutsideClasses = ['tox', 'modal', 'p-autocomplete-item']

            // Computed
            const detailsObj = computed(() => (props.detailsRequest && props.detailsRequest.obj) ? props.detailsRequest.obj : { 'name': 'No details Selected' })
            const { objectType, objectID, objectKey, objectName, formattedDate, recordTitle, titleURL, editURL } = getRecordTools(props, null, detailsObj)

            const user = computed(() => store.state.userInfo.user)
            const dashboardGroup = computed(() => store.getters['userInfo/dashboardGroup'])
        
            // Methods
            const titleClick = () => {
                window.open(titleURL.value, "_blank");
            }
            const titleEditClick = () => {
                window.open(editURL.value, "_blank");
            }

            const handleViewTimer = (set) => {
                clearTimeout(viewTimer.value)

                if (set) {
                    viewTimer.value = setTimeout(() => {
                        if (objectKey.value) {
                            store.dispatch('objectCache/viewObject', { 'objectKey': objectKey.value }).then(() => {
                                console.log(detailsObj.value.name + ", " + objectKey.value + ' Viewed')
                            }).catch(error => {
                                console.log(error)
                            })
                        }
                    }, 20000)
                }
            }

            // provide
            provide('query', computed(() => props.detailsRequest && props.detailsRequest.queryID ? { id: props.detailsRequest.queryID } : null))

            return {
                emitter,
                // Data
                fetching, isEditing, isDirty, viewTimer, model, recordContext, selectedTab, isMobile,
                // Const
                ignoreClickOutsideClasses,
                // Computed
                recordTitle, detailsObj, objectType, objectID, objectKey, objectName, formattedDate,
               user, dashboardGroup,
                // Methods
                titleClick, titleEditClick, handleViewTimer
            }
        },
        computed: {
            sidebarTitle() { return (this.isMobile ? this.ellipseString(this.recordTitle, 60, true) : this.recordTitle) + ' ' }
        },
        methods: {
            sidebarKeepContent() {
                return this.$refs.outerSidebar.sidebarKeepContent()
            },            
        },
        watch: {
            model(newVal) {
                console.log('Sidebar ' + (newVal ? 'open' : 'closed'))
                this.handleViewTimer(newVal)
            },
            detailsObj() {
                this.handleViewTimer(true);
            }
        }
    }
</script>
<style scoped lang="scss">
</style>