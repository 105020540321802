<template>
    <template v-if="isMobile">
        <template v-if="loggedIn">
            <menu-item label="My Profile" :href="'/Person/Details/' + personID" :level="level" />
            <menu-item label="My Preferences" href="/Account/UserFeatures" :level="level" />
            <menu-item v-show="!loggingOut" label="Log out" icon="sign-out" @click="logOut" />
            <spin-loader :showMe="loggingOut" />
        </template>
        <menu-item label="Privacy" href="https://berkerynoyes.com/aboutus/privacy-policy/" :level="level" />
        <menu-item label="Terms of Use" href="https://mandasoft.com/Home/TermsOfUse" :level="level" />
        <menu-item label="Create Support Issue" href="mailto://mis@berkerynoyes.com" :level="level" />
        <menu-item label="Clear Vuex Data Store" @click="clearVuexStore" :level="level" />
        <menu-item v-if="isAdmin" label="Clear .Net Core Cache" @click="clearDotNetCache" :level="level" />
        <menu-item :label="'Version ' + version + ' Vue'" :level="level" />
    </template>
    <template v-else>
        <div class="user-access flex-rows no-responsive vertical-align-items">
            <div v-if="loggedIn" class="user-fullname air-right">{{ userName }}</div>
            <template v-else>
                <a v-if="showLogin" href="/account/login" class="nav-link text-dark">Log in</a>
            </template>
            <dropdown type="thumbnail">
                <template v-if="loggedIn && user" #toggle>
                    <div class="flex-rows no-responsive">
                        <figure class="user-thumbnail">
                            <img :src="user.profileImage" @error="onImageLoad">
                        </figure>
                        <i class="far fa-chevron-down air-left-small"></i>
                    </div>
                </template>
                <template v-if="loggedIn" #header>
                    <h3>{{ firstName }} {{ lastName }}</h3>
                    <span class="margin-top">since {{ sinceYear }}</span>
                </template>
                <template #body>
                    <menu-vertical options="panel options medium">
                        <template v-if="loggedIn">
                            <menu-item label="My Profile" icon="user" :href="'/Person/details/' + personID" target="_blank"/>
                            <menu-item label="My Preferences" icon="cog" href="https://cmdm.berkerynoyes.com/Account/UserFeatures" target="_blank" />
                            <menu-item v-show="!loggingOut" label="Log out" icon="sign-out" @click="logOut" />
                            <spin-loader :showMe="loggingOut" />
                        </template>
                        <menu-item label="Privacy" icon="binoculars" href="https://berkerynoyes.com/aboutus/privacy-policy/" target="_blank" />
                        <menu-item label="Terms of Use" icon="info" href="https://mandasoft.com/Home/TermsOfUse" target="_blank" />
                        <menu-item label="Create Support Issue" icon="question-circle" href="mailto://mis@berkerynoyes.com" />
                        <menu-item label="Clear Vuex Store & Log out" icon="trash-alt" @click="clearVuexStore" />
                        <menu-item label="Clear .Net Core Cache & Log out" icon="bomb" @click="clearDotNetCache" />
                    </menu-vertical>
                </template>
            </dropdown>
        </div>
    </template>
</template>


<script>
    import { onMounted, onBeforeUnmount,  ref, computed, inject } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import { isOpenSetup } from '../../composables/isOpen.js'
    import { getTextTools } from '../../composables/TextTools'

    import MenuItem from '../ui/menus/MenuItem.vue'
    import MenuVertical from '../ui/menus/MenuVertical.vue'

    export default {
        name: "UserMenu",
        components: {
            MenuItem, MenuVertical
        },
        props: {
            level: { type: Number, default: 1 }
        },
        setup() {
            // tools
            const store = useStore()
            const router = useRouter()
            const emitter = inject("emitter")   // Inject event bus    

            // data
            const { isOpen } = isOpenSetup()
            const isExpanded = ref(false)
            const showLogin = ref(false)
            const loggingOut = ref(false)

            // computed
            const user = computed(() => store.getters['userInfo/currentUserInfo'])
            const loggedIn = computed(() => store.getters['userInfo/loggedIn'])

            const userInfo = computed(() => user.value ? user.value.userInfo : null)
            const sinceYear = computed(() => userInfo.value ? toYear(userInfo.value.entryDate) : null)
            const firstName = computed(() => userInfo.value ? userInfo.value.firstName : null)
            const lastName = computed(() => userInfo.value ? userInfo.value.lastName : null)
            const userName = computed(() => userInfo.value ? userInfo.value.userName : null)
            const personID = computed(() => userInfo.value ? userInfo.value.personID : null)
            const version = computed(() => userInfo.value ? userInfo.value.version : null)
            // This should be used sparingly rather check Admin on Server Side
            const isAdmin = computed(() => loggedIn.value && userInfo.value ? userInfo.value.isAdmin : false)

            // methods
            const { toYear } = getTextTools()
            const onImageLoad = (ev) => {
                console.log(ev)
                ev.target.src = '/assets/images/user_portrait_he.png'
            }
            const collapse = () => {
                isExpanded.value = false;
            }
            const toggle = () => {
                isExpanded.value = !isExpanded.value
            }
            const logOut = () => {
                loggingOut.value = true
                store.dispatch('userInfo/logOut').then(() => {
                    loggingOut.value = false
                    isOpen.value = false
                    router.push({ name: 'Welcome' })
                })
            }
            const clearVuexStore = () => {
                var timeout = setTimeout(logOut(), 15000);
                store.dispatch('destroyStores').then(() => {
                    clearTimeout(timeout);
                    //window.alert('All local storage of Vuex data has been cleared. You will have to log back in')
                    logOut()
                })
            }
            const clearDotNetCache = () => {
                store.dispatch('reloadDotNetCache').then(() => {
                    //window.alert('All .NET cache and local storage of Vuex data has been cleared. You will have to log back in')
                    logOut()
                })
            }
            const showTheLogin = (req) => {
                if (req.call == 'userInfo/loadUser') {
                    console.log('showTheLogin')
                    showLogin.value = true
                }
            }

            // lifecycle
            onMounted(() => {
                emitter.on("storeload", showTheLogin)
            })
            onBeforeUnmount(() => {
                emitter.off("storeload", showTheLogin)
            })

            return {
                //tools
                emitter,
                // data
                isOpen, isExpanded, showLogin, loggingOut,
                // computed
                user, loggedIn, userInfo, sinceYear, firstName, lastName, userName, personID, version, isAdmin,
                // methods  
                logOut, toYear, onImageLoad, collapse, toggle, clearDotNetCache, clearVuexStore, showTheLogin
            }
        },
        computed: {
            envDescription() {
                return this.isDebugMode ? 'debugging' : 'production'
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
