import { createApp } from 'vue'
import NextAlert from './NextAlert.vue'
import _ from 'lodash'

export default {
    install: (app, options) => {

        app.component("next-alert", NextAlert)

        const $baseAlert = (opt) => {
            return new Promise((resolve, reject) => {
                if (!opt) {
                    reject('bad params')
                    return
                }

                const nextAlertSfc = app.component('next-alert')
                const mountEl = document.createElement('div')
                document.body.appendChild(mountEl)


                var title
                var htmlMessage
                var actions
                //support object-mode
                if (opt.title && _.isObject(opt.title)) {
                    title = opt.title.title ?? ""
                    htmlMessage = opt.title.message ?? ''
                    actions = opt.title.choices ?? []
                }
                else {
                    title = opt.title ?? ''
                    htmlMessage = opt.htmlMessage ?? ''
                    actions = opt.actions ?? []
                }

                var alertType = opt.alertType
                var iconType = opt.iconType
                var yesText = opt.yesText
                var noText = opt.noText

                var nextAlertInstance = createApp(nextAlertSfc, {
                    // props
                    alertType, htmlMessage, iconType, title, yesText, noText, actions,
                    // events are passed as props here with on[EventName]
                    onClose(result) {
                        resolve(result)
                        if (mountEl.parentNode) mountEl.parentNode.removeChild(mountEl)
                        if (nextAlertInstance) {
                            nextAlertInstance.unmount()
                            nextAlertInstance = null
                        }
                    }
                })
                nextAlertInstance.mount(mountEl)
            })
        }
        const $nextAlert = (htmlMessage, iconType) => {
            return $baseAlert({alertType: 'alert', htmlMessage: htmlMessage, iconType: iconType })
        }

        const $nextConfirm = (title, htmlMessage, iconType, yesText, noText, actions) => {
            return $baseAlert({ alertType: 'confirm', title: title, htmlMessage: htmlMessage, iconType: iconType, yesText: yesText, noText: noText, actions: actions })
        }
        const $nextChoice = (title, htmlMessage, actions) => {
            return $baseAlert({ alertType: 'choice', title: title, htmlMessage: htmlMessage, actions: actions })
        }

        app.config.globalProperties.$baseAlert = $baseAlert
        app.config.globalProperties.$nextAlert = $nextAlert
        app.config.globalProperties.$nextConfirm = $nextConfirm
        app.config.globalProperties.$nextChoice = $nextChoice

        app.provide("baseAlert", $baseAlert)
        app.provide("nextAlert", $nextAlert)
        app.provide("nextConfirm", $nextConfirm)
        app.provide("nextChoice", $nextChoice)

        _.noop(options)
    }
}