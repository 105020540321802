<template>
    <table-row v-model="isExpanded" :expansion="true" :class="'financial10KInfo ' + financialAccuracyClass + ' ' + (isEditing ? 'is-editing' : 'not-editing')">
        <table-cell title="Year" :show="true">
            <h3>
                {{ model.id }}
                <span class="tag-accuracy" :class="financialAccuracyClass">{{ financialAccuracyInitial }}</span>
                <small>
                    <i v-show="model.dirty" class="fa fa-asterisk"></i>
                    <i v-show="isEditing" class="fa fa-edit hide"></i>
                </small>
                <span v-if="model.error" class="error-message">{{ model.error }}</span>
            </h3>
            <a class="button-secondary" href="">More details</a>
        </table-cell>
        <table-cell title="Revenue" :canExpand="false" :show="true">
            <span class="label">Revenue:</span>
            <div class="financials">
                <span class="flex-rows no-responsive">
                    <span class="value">
                        <currency-input v-model="model.revenueUSD" v-model:nativeValue="model.revenueNative"
                                        :currency="model.currency"
                                        :scale="6"
                                        :showNative="showNative"
                                        @focus="setEditing"
                                        :disabled="disabled" />
                    </span>
                    <span :class="revenueTrendIndicator"></span>
                </span>
                <span class="percentage">
                    <scaled-input v-model="model.revenueAnnualGrowth" :scale="-2" suffix='%' @focus="setEditing" />
                </span>
            </div>
        </table-cell>
        <table-cell title="Ebitda" :canExpand="false" :show="true">
            <span class="label" title="value-percentage">EBITDA:</span>
            <currency-percent-input v-model="model.ebitdaUSD" v-model:nativeValue="model.ebitdaNative" v-model:percentValue="model.ebitdaRevenuePercentage"
                                    :revenueValue="model.revenueUSD"
                                    :currency="currency"
                                    :showNative="showNative"
                                    @focus="setEditing"
                                    :disabled="disabled"
                                    :wrapper="{ tag: 'div', class: 'financials', children: [{ class: 'flex-rows no-responsive', children:[{ class: 'value', children:[ { tag: '$currency'} ] }]},{ class: 'percentage', children:[{ tag: '$percent'}] }]}" />
        </table-cell>
        <table-cell title="Net Income" :canExpand="false" :show="thisScreenOrLarger('tablet')">
            <span class="label">Net Income:</span>
            <currency-percent-input v-model="model.netIncomeUSD" v-model:nativeValue="model.netIncomeNative" v-model:percentValue="model.netIncomeRevenuePercentage"
                                    :revenueValue="model.revenueUSD"
                                    :currency="currency"
                                    :showNative="showNative"
                                    :disabled="disabled"
                                    @focus="setEditing"
                                    :wrapper="{ tag: 'div', class: 'financials', children: [{ class: 'flex-rows no-responsive', children:[{ class: 'value', children:[ { tag: '$currency'} ] }]},{ class: 'percentage', children:[{ tag: '$percent'}] }]}" />
        </table-cell>
        <table-cell title="Ebit" :canExpand="false" :show="thisScreenOrLarger('tablet')">
            <span class="label">EBIT:</span>
            <currency-percent-input v-model="model.ebitUSD" v-model:nativeValue="model.ebitNative" v-model:percentValue="model.ebitRevenuePercentage"
                                    :revenueValue="model.revenueUSD"
                                    :currency="currency"
                                    :showNative="showNative"
                                    @focus="setEditing"
                                    :disabled="disabled"
                                    :wrapper="{ tag: 'div', class: 'financials', children: [{ class: 'flex-rows no-responsive', children:[{ class: 'value', children:[ { tag: '$currency'} ] }]},{ class: 'percentage', children:[{ tag: '$percent'}] }]}" />
        </table-cell>
        <table-cell title="Emp" :canExpand="false" :show="true">
            <span class="label">Employees:</span>
            <span class="financials">
                <span class="value">
                    <scaled-input v-model="model.employeeSize" :size="5" :minFractionDigits="0" title="employees" @focus="setEditing" :disabled="disabled" />
                </span>
            </span>
        </table-cell>
        <table-cell title="Actions" :show="true">
            <btn type="icon-box" class="edit"><i class="fas fa-edit"></i></btn>
            <btn v-if="!disabled && isEditing" @click="showDeleteModal = true" type="icon-box"><i class="far fa-trash-alt"></i></btn>
            <btn type="icon-box"><i class="fas fa-chevron-down"></i></btn>
        </table-cell>
        <template #expansion>
            <div class="cell-section">
                <ul class="column list-table">
                    <li>
                        <span class="label">Accuracy:</span>
                        <span class="financial-field data flex-rows no-responsive">
                            <select name="financialAccuracy" v-model="model.financialAccuracyID" @focus="setEditing" :disabled="disabled">
                                <option v-for="accuracy in financialAccuracyArray" :key="accuracy.id" :value="accuracy.id">{{ accuracy.entry }}</option>
                            </select>
                            <span class="tag-accuracy" :class="financialAccuracyClass">{{ financialAccuracyInitial }}</span>
                        </span>
                    </li>
                    <li>
                        <span class="label">Currency:</span>
                        <span class="financial-field data flex-rows no-responsive">
                            <select name="currency" v-model="currencyID" @focus="setEditing" :disabled="disabled">
                                <option v-for="(curr, idx) in currencies" :key="idx" :value="curr.id" @change="onCurrencyChange">{{ curr.entry }}</option>
                            </select>
                            <ul v-if="currencyID != 1 && toUSDRate">
                                <li class="tag-default">{{ toUSDRate }} USD/{{ currencyISO }}</li>
                                <li class="tag-default"> {{ toShortDateString(rateDate) }}</li>
                            </ul>
                        </span>
                    </li>
                    <li>
                        <span class="label">Confidential:</span>
                        <span class="financial-field data">
                            <checkbox v-model="model.confidential" @focus="setEditing" :disabled="disabled" />
                        </span>
                    </li>
                    <li>
                        <span class="label">Public:</span>
                        <span class="financial-field data">
                            <checkbox v-model="model.isPublic" @focus="setEditing" :disabled="disabled" />
                        </span>
                    </li>
                    <li v-if="model.isPublic">
                        <span class="label">
                            <btn el="button" @click="setEditing(); $emit('tickersearch', model)" :icon="showTickerNeedsUpdate ? 'exclamation' : (model.tickerSymbol ? '' : 'question')" :type="showTickerNeedsUpdate ? 'error' : 'primary'"
                                 :title="(showTickerNeedsUpdate ? 'Ticker financial data possibly out of date. ' : '') + 'Click to ' + (model.tickerSymbol ? 'automatically update financials.' : 'look up public income statements.')">Ticker:</btn>
                        </span>
                        <span class="financial-field data">
                            <input size="5" type="text" v-model="model.tickerSymbol" @focus="setEditing" :disabled="disabled" autocomplete="new-password" />
                        </span>
                    </li>
                </ul>
                <ul class="column list-table" v-if="thisScreenOrSmaller('smallscreen')">
                    <li>
                        <span class="label">EBIT:</span>
                        <currency-percent-input v-model="model.ebitUSD" v-model:nativeValue="model.ebitNative" v-model:percentValue="model.ebitRevenuePercentage"
                                                :revenueValue="model.revenueUSD"
                                                :currency="currency"
                                                :showNative="showNative"
                                                @focus="setEditing"
                                                :disabled="disabled"
                                                :wrapper="{ tag: 'div', class: 'financials', children: [{ class: 'flex-rows no-responsive', children:[{ class: 'value', children:[ { tag: '$currency'} ] }]},{ class: 'percentage', children:[{ tag: '$percent'}] }]}" />
                    </li>
                    <li>
                        <span class="label">Net Income:</span>
                        <currency-percent-input v-model="model.netIncomeUSD" v-model:nativeValue="model.netIncomeNative" v-model:percentValue="model.netIncomeRevenuePercentage"
                                                :revenueValue="model.revenueUSD"
                                                :currency="currency"
                                                :showNative="showNative"
                                                :disabled="disabled"
                                                @focus="setEditing"
                                                :wrapper="{ tag: 'div', class: 'financials', children: [{ class: 'flex-rows no-responsive', children:[{ class: 'value', children:[ { tag: '$currency'} ] }]},{ class: 'percentage', children:[{ tag: '$percent'}] }]}" />
                    </li>
                </ul>
                <ul class="column list-table">
                    <li>
                        <span class="label">Tax Status:</span>
                        <span class="data">
                            <select name="taxStatus" v-model="model.taxStatusID" @focus="setEditing" :disabled="disabled">
                                <option v-for="taxStatus in taxStatusArray" :key="taxStatus.id" :value="taxStatus.id">{{ taxStatus.entry }}</option>
                            </select>
                        </span>
                    </li>
                    <li>
                        <span class="label">Fiscal Year End Month:</span>
                        <span class="data">
                            <select name="fiscalYearEndMonth" v-model="model.fiscalYearEndMonth" @focus="setEditing" :disabled="disabled">
                                <option v-for="fye in months" :key="fye.id" :value="fye.id">{{ fye.entry }}</option>
                            </select>
                        </span>
                    </li>
                    <li>
                        <span class="label">Last Edited:</span>
                        <span class="data">
                            <template v-if="model.changeDate">
                                {{ toShortDateString(model.changeDate) }} by {{ model.changeUserName }}
                            </template>
                            <template v-else>
                                {{ toShortDateString(model.entryDate) }} by {{ model.entryUserName }}
                            </template>

                        </span>
                    </li>
                    <li class="block">
                        <div class="entry-notes"> </div>
                        <span class="label">Reliability:</span>
                        <span class="fullwidth">
                            <textarea class="style-alt" data-type="textarea" v-model="model.description" rows="3" @focus="setEditing" :disabled="disabled"></textarea>
                        </span>
                    </li>
                    <li class="hide" v-if="!disabled && isEditing">
                        <span class="label hide"></span>
                        <span class="data align-left">
                            <btn el="button" title="Delete" @click="showDeleteModal = true" type="secondary" size="small"><i class="far fa-times"></i> Delete </btn>
                        </span>
                    </li>
                </ul>
            </div>
            <div class="cell-section" v-if="model.isPublic">
                <div style="flex: 0 0 100%;">
                    <h5>Public Financial Data</h5>
                </div>
                <ul class="list-table">
                    <li class="financials">
                        <span class="label">Stock Price:</span>
                        <span class="value">
                            <currency-input v-model="model.stockPriceYearEndUSD" v-model:nativeValue="model.stockPriceYearEndNative"
                                            :currency="currency"
                                            :scale="6"
                                            :showNative="showNative"
                                            :disable="true" />
                        </span>
                    </li>
                    <li class="financials">
                        <span class="label">Outstanding Shares:</span>
                        <span class="value">
                            <scaled-input v-model="model.outstandingShares" :disable="true" :scale="6" suffix=' shares[mm]' />
                        </span>
                    </li>
                    <li class="financials">
                        <span class="label">Market Cap:</span>
                        <span class="value">
                            <currency-input v-model="model.marketCapUSD" v-model:nativeValue="model.marketCapNative"
                                            :currency="currency"
                                            :scale="6"
                                            :showNative="showNative"
                                            :disable="true" />
                        </span>
                    </li>
                    <li class="financials">
                        <span class="label">Net Debt:</span>
                        <span class="value">
                            <currency-input v-model="model.netDebtUSD" v-model:nativeValue="model.netDebtNative"
                                            :currency="currency"
                                            :scale="6"
                                            :showNative="showNative"
                                            :disable="true" />
                        </span>
                    </li>
                    <li class="financials">
                        <span class="label">Preferred Equity:</span>
                        <span class="value">
                            <currency-input v-model="model.preferredEquityUSD" v-model:nativeValue="model.preferredEquityNative"
                                            :currency="currency"
                                            :scale="6"
                                            :showNative="showNative"
                                            :disable="true" />
                        </span>
                    </li>
                </ul>
                <ul class="list-table">
                    <li class="financials">
                        <span class="label">Enterprise Value:</span>
                        <span class="value">
                            <currency-input v-model="model.enterpriseValueUSD" v-model:nativeValue="model.enterpriseValueNative"
                                            :currency="currency"
                                            :scale="6"
                                            :showNative="showNative"
                                            :disable="true" />
                        </span>
                    </li>
                    <li class="financials">
                        <span class="label">Revenue Multiple:</span>
                        <span class="value">
                            <scaled-input v-model="model.revenueMultiple" suffix="x" :minFractionDigits="1" :disable="true" />
                        </span>
                    </li>
                    <li class="financials">
                        <span class="label">EBITDA Multiple:</span>
                        <span class="value">
                            <scaled-input v-model="model.ebitdaMultiple" suffix="x" :minFractionDigits="1" :disable="true" />
                        </span>
                    </li>
                    <li class="financials">
                        <span class="label">Ticker Fail Count:</span>
                        <span class="value">
                            <scaled-input suffix=" faults" v-model="model.tickerFailCount" :disable="true" />
                        </span>
                    </li>
                    <li class="financials">
                        <span class="label">Ticker Verify Date Count:</span>
                        <span v-if="model.tickerSymbolVerifyDate" class="value">
                            {{ toShortDateString(model.tickerSymbolVerifyDate) }} {{ toShortTimeString(model.tickerSymbolVerifyDate) }}
                        </span>
                    </li>
                </ul>
            </div>
        </template>
    </table-row>
    <choice-modal v-model:isOpen="currencyChoiceModalisOpen" title="Changing Currency" :question="currencyQuestion" :choices="currencyChoices" :cancelChoice="cancelChoice" @selected="conversionsSelected" />
    <modal ref="deleteModal" v-model="showDeleteModal" @save="deleteFinancial" :title="'Delete '+ model.id +' Financial?'" :actionbuttons="true" saveTitle="Delete"></modal>
</template>
<script>

    import { useModelWrapper } from '../../composables/ModelWrapper.js'
    import { nextTick, computed, inject } from 'vue'
    import { DateTime, Info } from 'luxon'
    import { mapGetters, useStore } from 'vuex'

    import Checkbox from '../ui/forms/Checkbox.vue'
    import TableCell from '../ui/tables/TableCell.vue'
    import TableRow from '../ui/tables/TableRow.vue'
    import CurrencyInput from './CurrencyInput.vue'
    import ScaledInput from './ScaledInput.vue'
    import CurrencyPercentInput from './CurrencyPercentInput.vue'
    import ChoiceModal from '../baseComponents/ChoiceModal.vue'
    
    export default {
        name: 'Financial10kEditor',
        components: {
            Checkbox, TableCell, TableRow, CurrencyInput, ScaledInput, ChoiceModal, CurrencyPercentInput
        },
        props: {
            modelValue: { type: Object },
            editing: { type: Boolean },
            ownerName: String,
            inpopup: {
                type: Boolean,
                default: false
            },
            showNative: {
                type: Boolean,
                default: false
            },
            advanced: {
                type: Boolean,
                default: false
            },
            expanded: {
                type: Boolean,
                default: false
            },
            showTickerNeedsUpdate: {
                type: Boolean,
                default: false
            },
            disabled: { type: Boolean, default: false },
            previousYear: { type: Function }
        },
        emits: ['delete', 'ownershipchange', 'tickersearch', 'update:modelValue', 'update:showNative', 'update:expanded', 'updateotheryear', 'update:editing'],
        setup(props, { emit }) {
            //const toast = useToast()

            const nextChoice = inject('nextChoice')
            const store = useStore()
            const defaultFinancial = computed(() => store.getters['financials/defaultFinancial'])
            const defaultCurrency = computed(() => store.getters['financials/defaultCurrency'])
            const currenciesObject = computed(() => store.getters['financials/currenciesObject'])

            const model = computed({
                get: () => props.modelValue ? props.modelValue : defaultFinancial.value,
                set: (val) => {
                    val.dirty = true
                    console.log('model-setter', val)
                    emit('update:modelValue', val)
                }
            })

            const currencyID = computed({
                get: () => props.modelValue && props.modelValue.currency ? props.modelValue.currency.id : defaultCurrency.value.id,
                set: (val) => {
                    model.value.currency = currenciesObject.value[val]
                    // a side effect in the watch will update the currency ID better
                }
            })
            const pctFields = ['ebitda', 'ebit', 'netIncome']

            const isExpanded = useModelWrapper(props, emit, 'expanded', (newVal) => {
                if (newVal) {
                    isEditing.value = true
                }
            })
            const isEditing = useModelWrapper(props, emit, 'editing')


            return {
                nextChoice, model, currencyID, defaultFinancial, defaultCurrency, currenciesObject, isExpanded, pctFields, isEditing
            }
        },
        data: function () {
            return {
                showDeleteModal: false,
                currencyChoiceModalisOpen: false,
                currencyQuestion: null, 
                currencyChoices: [],
                cancelChoice: null,
                rollingBackCurrency: false,
                months: Info.months().map((m, idx) => { return { 'id': idx + 1, 'entry': m } }),
                
            }
        },
        computed: {
            ...mapGetters({
                financialAccuracies: 'financials/financialAccuracies',
                financialAccuracyArray: 'financials/financialAccuracyArray',
                taxStatuses: 'financials/taxStatuses',
                taxStatusArray: 'financials/taxStatusArray',
                currencies: 'financials/currencies',
            }),
            financialAccuracyClass() {
                return this.model && this.model.financialAccuracyName ? this.model.financialAccuracyName.toLowerCase(): ''
            },
            financialAccuracyInitial() {
                return this.model && this.model.financialAccuracyName ? this.model.financialAccuracyName[0] : '?'
            },
            currencyFields() {
                var f = ['revenue', 'ebitda', 'ebit', 'netIncome']
                if (this.model.isPublic) {
                    f = f.concat(['stockPriceYearEnd', 'marketCap', 'netDebt', 'preferredEquity', 'enterpriseValue'])
                }

                return f
            },
            currencyISO() {
                if (this.currency && this.currency.isoCode)
                    return this.currency.isoCode
                else if (this.currencyID > 1 && this.currenciesObject[this.currencyID])
                    return this.currenciesObject[this.currencyID].isoCode
                else
                    return 'USD'
            },
            currency() {
                return this.model && this.model.currency ? this.model.currency : (this.currenciesObject[this.currencyID] ? this.currenciesObject[this.currencyID] : this.defaultCurrency)
            },
            yearEndDate() {
                return this.model ? DateTime.local(this.model.id, this.model.fiscalYearEndMonth).endOf('month') : DateTime.now().endOf('year')
            },
            toUSDRate() {
                return this.currency && this.currency.id != 1 ? this.currency.toUSDRate : null
            },
            rateDate() {
                return this.currency && this.currency.id != 1 ? this.currency.rateDate : null
            },
            revenueTrendIndicator() {
                return 'trend-indicator ' + (this.model.revenueAnnualGrowth < 0.0 ? 'down' : (this.model.revenueAnnualGrowth >= 0.1 ? 'up' : ''))
            },
            revenueUsdPreviousYear() {
                var prevYear = this.previousYear(this.model.id)
                return prevYear ? prevYear.revenueUSD : null
            },
            tickerEnteredYear() {
                return false
            },
            dirtyProps() {
                return {
                    isPublic: this.model.isPublic, confidential: this.model.confidential, currencyID: this.model.currency.id, revenueUSD: this.model.revenueUSD, ebitdaUSD:  this.model.ebitdaUSD,
                    ebitUSD: this.model.ebitUSD, netIncomeUSD: this.model.netIncomeUSD, employeeSize: this.model.employeeSize, financialAccuracyID: this.model.financialAccuracyID, taxStatusID: this.model.taxStatusID,
                    tickerSymbol: this.model.tickerSymbol, fiscalYearEndMonth: this.model.fiscalYearEndMonth, tickerSymbolVerifyDate: this.model.tickerSymbolVerifyDate, tickerFailCount:  this.model.tickerFailCount,
                    outstandingShares: this.model.outstandingShares, stockPriceYearEndUSD: this.model.stockPriceYearEndUSD, marketCapUSD: this.model.marketCapUSD, netDebtUSD: this.model.netDebtUSD,
                    preferredEquityUSD:this.model.preferredEquityUSD, description: this.model.description
                }
            }
        },
        methods: {

            deleteFinancial() {
                this.model.delete = true
                this.model.dirty = true
                this.$emit('delete', this.model)
                this.isEditing = true;
            },
            onCurrencyChange(newVal) {
                console.log('onCurrencyChange', newVal)
                this.$toast.add({ severity: 'info', summary: 'Currency Changed', detail: JSON.stringify(newVal), life: 3000 })
            },
            openCloseEditor(isOpen) {
                this.isExpanded = !!isOpen
            },
            setEditing() {
                this.isEditing = true
            },
            getLatestExchangeRate(id, suppressToastMessages) {
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('financials/fetchCurrencyRate', { currencyID: id, year: this.yearEndDate.year, month: this.yearEndDate.month, day: this.yearEndDate.day })
                        .then((rate) => {
                            this.model.currency = rate
                            resolve(rate)
                        }).catch(error => {
                            if (!suppressToastMessages)
                                this.$toast.add({ severity: 'error', summary: 'Error getting Currency Rate', detail: error.message, life: 3000 })
                            reject('Error getting Currency Rate: ' + error.message)
                        })
                })
            },
            convertToNewRatePrompt(newCurrency, oldCurrency) {
                this.currencyQuestion = 'You have changed the currency for [' + this.model.year + ']'
                    + ' from ' + oldCurrency.entry + ' to ' + newCurrency.entry + "" +
                    '<br>Are the current numbers already in ' + newCurrency.entry + '?'
                this.currencyChoices = [
                    { 'label': 'Yes, keep the numbers', 'value': { 'action': 'no-conversion', 'oldCurrency': oldCurrency, 'newCurrency': newCurrency } },
                    { 'label': 'No, convert to ' + newCurrency.entry, 'value': { 'action': 'conversion', 'oldCurrency': oldCurrency, 'newCurrency': newCurrency } }
                ]
                this.cancelChoice = { action: 'cancel', oldCurrency: oldCurrency, newCurrency: newCurrency }
                this.currencyChoiceModalisOpen = true
            },
            async conversionsSelected(choice) {
                console.log('Now using ' + choice.newCurrency.entry, choice);
                if (choice.action == 'cancel') {
                    this.rollingBackCurrency = true
                    this.currencyID = choice.oldCurrency.id
                    return
                }
                var dDirectRate = 1.0
                //dollar to other currencies
                if (choice.oldCurrency.id == 1) {
                    if (choice.action == 'conversion') {
                        dDirectRate = 1.0 / choice.newCurrency.toUSDRate
                        console.log('Converting: 1 ' + choice.oldCurrency.entry + ' = ' + dDirectRate + ' ' + choice.newCurrency.entry );
                        this.currencyFields.forEach((cf) => { this.model[cf + 'Native'] = this.model[cf + 'USD'] * dDirectRate })
                    }
                    else {
                        this.currencyFields.forEach((cf) => {
                            this.model[cf + 'Native'] = this.model[cf + 'USD']
                            this.model[cf + 'USD'] = this.model[cf + 'Native'] * choice.newCurrency.toUSDRate
                        })
                    }
                }
                //other currencies to other currencies
                else {
                    if (choice.action == 'conversion') {
                        dDirectRate = choice.oldCurrency.toUSDRate / choice.newCurrency.toUSDRate
                        console.log('Converting: 1 ' + choice.oldCurrency.entry + ' = ' + dDirectRate + ' ' + choice.newCurrency.entry);
                        this.currencyFields.forEach((cf) => { this.model[cf + 'Native'] = this.model[cf + 'USD'] * dDirectRate })
                    }
                    this.currencyFields.forEach((cf) => { this.model[cf + 'USD'] = this.model[cf + 'Native'] * choice.newCurrency.toUSDRate })
                }
                await nextTick()
                this.$emit('update:showNative', choice.newCurrency.id != 1)
            },
            updateRevenueAnnualGrowth(yearChoice, newVal) {
                if (yearChoice == this.model.id) {
                    this.model.revenueUSD = newVal
                    this.model.revenueNative = newVal / (this.toUSDRate ? this.toUSDRate : 1.0)
                }
                else if (yearChoice) {
                    this.$emit('updateotheryear', { 'field': 'revenue', 'val': newVal, 'year': yearChoice, 'thisYear': this.model.id })
                }
            }
        },
        watch: {
            "currencyID": {
                handler(val, oldVal) {
                    if (this.isEditing) {
                        this.model.currency = this.currenciesObject[val]
                        if (val != -1 && !this.rollingBackCurrency) {
                            this.getLatestExchangeRate(val).then((rate) => {
                                //this will "preview" the un-converted USD value in the input, and the prompt will make more sense 
                                //it will also allow currency -> currency conversions to work correctly
                                this.currencyFields.forEach((cf) => { this.model[cf + 'USD'] = this.model[cf + 'Native'] })
                                this.$emit('update:showNative', rate.id != 1)

                                //ask to keep the number or convert
                                this.convertToNewRatePrompt(rate, this.currenciesObject[oldVal])
                            })
                        }
                        this.rollingBackCurrency = false
                    }
                }
            },
            "model.financialAccuracyID": {
                handler(val) {
                    if (this.isEditing) {
                        this.model.financialAccuracyName = (val ? this.financialAccuracies[val] : null)
                    }
                }
            },
            "model.taxStatusID": {
                handler(val) {
                    if (this.isEditing) {
                        this.model.taxStatusName = (val ? this.taxStatuses[val] : null)
                    }
                }
            },
            "model.isPublic": {
                handler(val) {
                    if (this.isEditing) {
                        this.$emit('ownershipchange', { 'year': this.model.id, 'isPublic': val })
                        if (val)
                            this.$emit('tickersearch', this.model)
                    }
                }
            },
            "model.revenueAnnualGrowth": {
                handler(val, oldVal) {
                    if (this.isEditing) {
                        // debounce for changes that are math significant digits
                        if (val == null || !this.changeIsSignificant(val, oldVal)) return

                        var growthFactor = 1.0 + val
                        if (this.revenueUsdPreviousYear) {
                            var currentRev = this.revenueUsdPreviousYear * growthFactor
                            // If this watch triggered to set back the value what it should be, then exit
                            if (!this.changeIsSignificant(this.model.revenueUSD, currentRev)) return
                        }
                        var newVals = {}
                        if (this.revenueUsdPreviousYear)
                            newVals[this.model.id] = this.revenueUsdPreviousYear * growthFactor
                        if (this.model.revenueUSD) {
                            newVals[this.model.id - 1] = this.model.revenueUSD / growthFactor
                            newVals[this.model.id + 1] = this.model.revenueUSD * growthFactor
                        }

                        var numVals = Object.keys(newVals).length
                        if (numVals == 0)
                            return
                        if (numVals == 1) {
                            this.updateRevenueAnnualGrowth(+Object.keys(newVals)[0], newVals[Object.keys(newVals)[0]])
                        }
                        else {
                            var choices = Object.keys(newVals).map((year) => { return { text: year, res: { year: year, val: newVals[year] } } })
                            choices.unshift({ text: 'Cancel', res: { year: 0, val: null } });

                            this.nextChoice({ title: 'Updating Revenue', message: 'Which year do you wish to update?', choices: choices })
                                .then(choice => {
                                    if (choice.year && choice.val)
                                        this.updateRevenueAnnualGrowth(+choice.year, choice.val)
                                })
                        }
                    }
                }
            },
            "model.revenueUSD": {
                handler(val) {
                    if (this.isEditing) {
                        if (this.revenueUsdPreviousYear && val) {
                            this.model.revenueAnnualGrowth = (val - this.revenueUsdPreviousYear) / this.revenueUsdPreviousYear
                        }
                    }
                }
            },
            "revenueUsdPreviousYear": {
                handler(val) {
                    if (this.isEditing) {
                        if (this.model.revenueUSD && val) {
                            this.model.revenueAnnualGrowth = (this.model.revenueUSD - val) / val
                        }
                    }
                }
            },
            "dirtyProps": {
                handler(val, oldValue) {
                    var changed =[]
                    Object.keys(val).forEach((key) => {
                        if (val[key] != oldValue[key])
                            changed.push(key)
                    })

                    if (changed.length) {
                        console.log('model dirtyProps deep watch', this.model.id, changed)
                        this.model.dirty = true
                        this.$emit('validate')
                        
                    }
                },
                deep: true
            }
        }
    }
</script>
<style lang="scss">
    .error-message{
        font-style: italic;
        color: darkred;
    }
</style>