<template>
    <li class="list-item" :class="{name: name}">
        <template v-if="listType === 'table'">
            <span class="label">
                <slot name="label"></slot>
            </span>
            <span class="data">
                <slot name="content"></slot>
            </span>
        </template>
        <template v-else>
            <slot />
        </template>
    </li>
</template>

<script>export default {
        name: 'ListItem',
        props: {
            name: String,
            label: String,
            data: String
        },
        inject: ['type'],
        data: function () {
            return {

            }
        },
        computed: {
            listType() {
                return this.type
            },
        },
    }</script>

<style scoped lang="scss">
</style>