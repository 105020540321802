<template>
    <section class="page-content search-page">
        <section class="search-bar-container">
            <div class="search-bar box card">
                <div class="search-icon"><i class="far fa-search" aria-hidden="true"></i></div>
                <div class="search-input">
                    <form @submit.prevent>
                        <span class="p-autocomplete p-component p-inputwrapper fullwidth" aria-haspopup="listbox" aria-owns="pv_id_21_list" aria-expanded="false">
                            <input v-model="textInputModel" @keyup.enter="textInputed" class="p-autocomplete-input p-inputtext p-component style-blank" placeholder="Search..." type="search" autocomplete="new-password" role="searchbox" aria-autocomplete="list" aria-controls="pv_id_21_list">
                        </span>
                    </form>
                </div>
            </div>
        </section>
        <section class="page-section has-sidebar">
            <aside class="search-sidebar" :class="{ 'is-open': isMobileFiltersOpen }">
                <search-filters v-model="criteria"
                                v-model:advanced="advancedMode"
                                :resultsSet="resultsSet"
                                :EditCountSearch="runEditCountSearch"
                                @runsearch="runSearch"
                                @runpivotsearch="searchInSearch"
                                @resetquery="resetQuery"
                                :ref="(el)=>setItemRef(el,'searchFilters')" />
            </aside>
            <section class="search-results results-container card">
                <header class="search-results-header" :class="{ 'is-open': isMobileFiltersOpen }">
                    <div class="flex-rows justify-right no-responsive frame-horizontal-small">
                        <h2 class="search-results-title">
                            <span v-show="linkedCard" class="cardIndicator margin-right-xsmall" :title="linkedCardDashName"><i class="far fa-tachometer-alt-fast"></i></span>
                            <span class="queryName">{{ queryName }}</span>
                            <span class="search-results-count">{{ resultCountFormatted }}</span>
                        </h2>
                        <div class="search-status flex-rows no-responsive align-center">
                            <span v-show="loading">loading options</span>
                            <span v-show="searchRunning">searching</span>
                            <spin-loader :showMe="searchRunning || loading" />
                        </div>

                        <div v-if="hitCount > 0 && !isMobileFiltersOpen" class="place-right">
                            <dropdown ref="sortDropdown" :label="sortByLabel" type="icon" icon="fas fa-sort-alt" :useFontAwesome="true" direction="right" :title="'Sort: ' + sortByLabel">
                                <template #header>
                                </template>
                                <template #body>
                                    <sort-menu @sortselected="$refs.sortDropdown.closeTheDropDown()" :options="sortOptions" v-model="sortBy"></sort-menu>
                                </template>
                            </dropdown>
                        </div>
                        <div v-if="!isMobileFiltersOpen" class="cardOptions">
                            <card-options :dashboard="linkedDashboard"
                                          :card="linkedCard"
                                          :searchResultsSet="resultsSet"
                                          :searchDisplayType="displayModeCardMap[resultsViewModesRev[selectedResultTab]]"
                                          :query="query"
                                          :queryChanges="queryChanges"
                                          @saved="setQueryChanges(true)" />
                        </div>
                        <search-filters-toggle v-model="isMobileFiltersOpen"
                                               :criteria="resultSetCriteria"
                                               :advanced="advancedMode" />
                    </div>
                </header>
                <section class="search-results-viewer" ref="elSearchResults">
                    <section class="search-results-content">
                        <tabs v-model="selectedResultTab" options="boxtabs" :enableMobileSlider="true">
                            <tab v-if="hitCount == 0" title="No Listing">
                                <div class="margin-top">
                                    There are {{ resultCount > 0 ? "too many results" : "no results" }} matching your criteria. Try changing one of your terms or filters
                                    <!--<btn size="xsmall" type="secondary" @click="reloadPreviousSearch">Go Back</btn>-->
                                </div>
                            </tab>
                            <tab title="Details" v-if="hitCount > 0">
                                <records-list 
                                              :parentContext="'search'"
                                              :cardDisplayType="4"
                                              :carddata="resultsSet"
                                              :highlightText="fullTextTokens"
                                              @detailrequest="openSidebar" />
                            </tab>
                            <tab title="Table" v-if="hitCount > 0">
                                <records-list :cardDisplayType="2"
                                              :parentContext="'search'"
                                              :carddata="resultsSet"
                                              :highlightText="fullTextTokens"
                                              @detailrequest="openSidebar" />
                            </tab>
                            <tab title="Grid" v-if="hitCount > 0">
                                <records-list :cardDisplayType="9"
                                              :parentContext="'search'"
                                              :carddata="resultsSet"
                                              :highlightText="fullTextTokens"
                                              @detailrequest="openSidebar" />
                            </tab>
                            <tab title="Tag Cloud" v-if="tagCloudsData && tagCloudsData.length > 0">
                                <article v-for="tagCloudData in tagCloudsData" :key="tagCloudData.tagCloudName" class="card-body margin-bottom-xsmall">
                                    <div class="box border frame-small title-people">
                                        <h5>{{ tagCloudData.tagCloudName }}</h5>
                                        <tag-cloud :carddata="tagCloudData" />
                                    </div>
                                </article>
                            </tab>
                            <tab title="Statistics" v-if="resultCount > 0">
                                <section v-for="aggPanel in statsAggPanels" :key="aggPanel" class="card aggregate margin-top-xsmall margin-bottom-xsmall">
                                    <header class="card-header ">
                                        <h3 class="card-title frame-top-small">{{ aggPanelDefs[aggPanel].Value.name }}</h3>
                                    </header>
                                    <article class="card-body">                                    
                                        <aggregate-stats :carddata="resultsSet" :aggPanelName="aggPanel" />
                                    </article>
                                    <footer class="card-footer"><!----></footer>
                                </section>
                            </tab>
                            <tab title="Brands" v-if="hitCount > 0">
                                <div class="margin-top-xsmall">
                                    <records-list :cardDisplayType="3"
                                                  :carddata="resultsSet"
                                                  :highlightText="fullTextTokens"
                                                  @detailrequest="openSidebar" />
                                </div>
                            </tab>
                            <tab title="Graphs" v-if="hitCount > 0">
                                <section v-for="graphAgg in displayedGraphAggs" :key="graphAgg" class="card aggregate margin-top-xsmall margin-bottom-xsmall">
                                    <header class="card-header margin-bottom-small">
                                        <h3 class="card-title frame-top-small frame-bottom-small">{{ aggDefs[graphAgg].name }}</h3>
                                    </header>
                                    <article class="card-body">
                                        <aggregate-graph :carddata="resultsSet" :aggName="graphAgg" />
                                    </article>
                                    <footer class="card-footer"><!----></footer>
                                </section>
                            </tab>
                            <tab title="Maps" v-if="hitCount > 0">
                                <div class="margin-top-xsmall">
                                    <spin-loader :showMe="!geoResultsSet.fetched" />
                                    <geo-map :canApplyFilter="true" :carddata="geoResultsSet.hits && geoResultsSet.hits.length ? geoResultsSet : resultsSet" @detailrequest="openSidebar" @applygeofilter="applyFilter"></geo-map>
                                </div>
                            </tab>

                            <tab title="Criteria">
                                <div class="margin-top-xsmall">
                                    <btn title="Save" @click="saveCriteria">Save</btn>
                                    <textarea class="margin-top-xsmall" v-model="criteriaPretty" :rows="criteriaPrettyHeight"></textarea>
                                </div>
                            </tab>
                        </tabs>
                    </section>
                </section>
                <footer class="search-results-footer" v-show="pagerVisible">
                    <pager v-model="query" :total="resultCount" :maxPageChoices="5" :canChange="!searchRunning" @pagechanged="pageChanged" />
                    <scroll-to-top :containerRef="elSearchResults" placement="footer" />
                </footer>
            </section>
        </section>
    </section>
    <record-sidebar v-model="sidebarShowing"
                    parentContext="search"
                    :ref="(el)=>setItemRef(el,'outerSidebar')"
                    :editorID="editorID"
                    :detailsRequest="detailsRequest"
                    @navigate="handleNavigate" />
    <Toast />
</template>
<script>
    //import Modal from '../modals/Modal.vue'
    import Tabs from '../ui/tabs/Tabs.vue'
    import Tab from '../ui/tabs/Tab.vue'
   
    import Pager from '../ui/tables/Pager.vue'
    import SearchFiltersToggle from './SearchFiltersToggle.vue'
    import SearchFilters from './SearchFilters.vue'

    import CardOptions from '../Dashboards/CardOptions.vue'
    import AggregateStats from '../Aggregates/AggregateStats.vue'
    import AggregateGraph from '../Aggregates/AggregateGraph.vue'
    import TagCloud from '../Aggregates/TagCloud.vue'
    import GeoMap from '../Aggregates/GeoMap.vue'
    import RecordsList from '../Records/RecordsList.vue'
    import RecordSidebar from '../Records/RecordSidebar.vue'
    //import FullTextFilter from './FullTextFilter.vue'
    import SortMenu from '../Menus/SortMenu.vue'
    import ScrollToTop from '../ui/base/scroll-to-top.vue'

    import { ref, inject, computed, onMounted, onBeforeUnmount, onBeforeUpdate, provide, watch } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import _ from 'lodash'
    import { getID } from '../../composables/Editors.js'
    import { useToast, useItemRefs } from '../../composables/ModelWrapper.js'
    import { scrollToTop, scrollToElementTop } from '../../composables/ScrollTools.js'
    import { sidebarSetup } from '../../composables/SidebarTools.js'
    import { defaultFilterTemplate } from '../../composables/SearchTools.js'
    import { getLobTools } from '../../composables/LobTools.js'

    export default {
        name: 'Search',
        components: {
            Tabs, Tab, Pager, SearchFiltersToggle, SearchFilters,
            AggregateStats, AggregateGraph, TagCloud, GeoMap, RecordsList, CardOptions, RecordSidebar,
            SortMenu, ScrollToTop
        },
        props: {
            QueryString: Array
        },
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const toast = useToast()
            const emitter = inject("emitter")   // Inject event bus  
            const nextChoice = inject('nextChoice')
            const { itemRefs, setItemRef, itemRefsReset } = useItemRefs()
            onBeforeUpdate(() => {
                itemRefsReset()
            })

            const { detailsRequest, editorID, sidebarShowing, openSidebar, fetchDetailsRequest, sidebarNavigate } = sidebarSetup()
            const { defaultLobFilterVal, addNodeIDToFilter } = getLobTools()

            // data
            const model = ref(null)
            const elSearchResults = ref(null)
            const query = ref({ skip: 0, limit: store.state.search.hitLimit ?? 15 })
            const criteria = ref({})
            const isMobileFiltersOpen = ref(false)
            const loading = ref(false)
            const searchRunning = ref(false)
            const advancedMode = ref(false)
            const cardViewOverride = ref(false)
            const originalQuery = ref(null)
            const queryChanges = ref({})
            const linkedDashboard = ref(null)
            const customCriteriaEdits = ref("")
            const textInputModel =ref(null)
            const lockQueryID = ref(false)
            const showHits = ref('all');

            // const
            const resultsID = getID('results')

            //result view map and its reverse
            const resultsViewModes = {
                'details': 'Details',
                'table': 'Table',
                'brands': 'Brands',
                'aggregateStats': 'Statistics',
                'aggregateGraph': 'Graphs',
                'tagCloud': 'Tag Cloud',
                'geoMap': 'Maps',
                'grid': "Grid"
            }
            var tabsToViews = {}
            Object.keys(resultsViewModes).forEach((key) => { tabsToViews[resultsViewModes[key]] = key })
            const resultsViewModesRev = tabsToViews

            //display map and its reverse
            const displayModeCardMap = {
                "aggregateStats": 1,
                "table": 2,
                "brands": 3,
                "details": 4,
                "aggregateGraph": 5,
                "tagCloud": 6,
                "geoMap": 7,
                "grid": 9
            }
            var displayModes = {}
            Object.keys(displayModeCardMap).forEach((key) => { displayModes[displayModeCardMap[key]] = key })
            const displayModeCardMapRev = displayModes


            //get the view from the state
            const storedResultView = resultsViewModes[store.state.search.resultsViewMode] ?? 'Details';
            const resultsDisplayMode = ref(storedResultView)
            const selectedResultTab = ref(storedResultView)


            // state and getters
            const resultsSets = computed(() => {
                return store.state.search.resultsSets
            })
            const hitLimit = computed(() => store.state.search.hitLimit)
            const searchHistory = computed(() => store.state.search.searchHistory)
            const user = computed(() => store.getters['userInfo/currentUser'])
            const dashboardGroup = computed(() => store.getters['userInfo/dashboardGroup'])
            const emailTemplates = computed(() => store.getters['letterwizard/emailTemplates'])
            const panelDefs = computed(() => store.getters['search/panelDefs'])
            const filterDefs = computed(() => store.getters['search/filterDefs'])

            const aggPanelDefs = computed(() => store.getters['search/aggPanelDefs'])
            const aggDefs = computed(() => store.getters['search/aggDefs'])
            const sortDefs = computed(() => store.getters['search/sortDefs'])


            // computed
            const resultsSet = computed(() => resultsSets.value[resultsID] ? resultsSets.value[resultsID] : {})
            const geoResultsSet = computed(() => resultsSets.value[resultsID] && resultsSets.value[resultsID].geoMarkers ? resultsSets.value[resultsID].geoMarkers : {})
            const hitCount = computed(() => resultsSet.value && resultsSet.value.hits ? resultsSet.value.hits.length : 0)
            const resultCount = computed(() => resultsSet.value && resultsSet.value.total > -1 ? resultsSet.value.total : 0)
            const resultCountFormatted = computed(() => (resultCount.value > -1 ? " (" + resultCount.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ")" : null))
            const resultSetCriteria = computed(() => resultsSet.value && resultsSet.value.query ? resultsSet.value.query.criteria : null)
            const alerts = computed(() => resultSetCriteria.value && resultSetCriteria.value.alerts ? JSON.parse(resultSetCriteria.value.alerts) : null)

            const availableSorts = computed(() => resultsSet.value && resultsSet.value.availableSorts ? resultsSet.value.availableSorts : [])
            const sortOptions = computed(() => {
                return availableSorts.value.map((sKey) => {
                    /*if (sKey == 'default' || !sortDefs.value[sKey])
                        return null*/
                    var def = sortDefs.value[sKey]
                    return {
                        key: sKey,
                        type: 'Default',
                        label: def.name,
                        ascLabel: "(" + def.ascName + ")",
                        descLabel: "(" + def.descName + ")",
                        defaultAscending: def.defaultAscending
                    }
                })
            })
            const sortBy = computed({
                get: () => {
                    return {
                        key: query.value && query.value.selectedSort ? query.value.selectedSort : 'default',
                        asc: query.value && query.value.sortAscending ? query.value.sortAscending : false
                    }
                },
                set: (newVal) => {
                    if (query.value && newVal) {
                        var changed = (newVal.key != query.value.selectedSort || newVal.asc != query.value.sortAscending)
                        query.value.selectedSort = newVal.key
                        query.value.sortAscending = newVal.asc

                        if (changed) {
                            query.value.skip = 0;
                            runSearch();
                        }
                    }
                }
            })
            const sortLabel = (key, asc) => {
                if (sortDefs.value && sortDefs.value[key])
                    return asc ? sortDefs.value[key].ascName : sortDefs.value[key].descName
                else
                    return asc ? 'asc' : 'desc'
            }
            const sortByLabel = computed(() => {
                if (sortBy.value && sortDefs.value && sortDefs.value[sortBy.value.key] && sortDefs.value[sortBy.value.key].name && sortDefs.value[sortBy.value.key].name.toLowerCase() != 'default') {
                    return sortDefs.value[sortBy.value.key].name + ' (' + (sortBy.value.asc ? sortDefs.value[sortBy.value.key].ascName : sortDefs.value[sortBy.value.key].descName) + ')'
                }
                return 'Sort by'
            })
            const pivotRequest = computed(() => query.value && query.value.pivot ? query.value.pivot : null)

            const fullTextModel = computed({
                get: () => criteria.value && criteria.value['fulltext'] ? criteria.value['fulltext'] : null,
                set: (newVal) => { criteria.value['fulltext'] = newVal }
            })
            const fullTextFilterParameter = computed(() => resultSetCriteria.value ? resultSetCriteria.value.fulltext : null)
            const fullTextFilterValueName = computed(() => fullTextFilterParameter.value ? fullTextFilterParameter.value.valueName : null)
            const fullTextFilterValue = computed(() => fullTextFilterParameter.value && fullTextFilterParameter.value.value ? JSON.parse(fullTextFilterParameter.value.value) : null)
            const termText = computed(() => resultSetCriteria.value && resultSetCriteria.value.fulltext ? resultSetCriteria.value.valueName : null)
            const fullTextTokens = computed(() => {
                if (fullTextFilterValue.value) {
                    //console.log('fullTextTokens', fullTextFilterValue.value)
                    // tokenize search term
                    var tokens = new Array()
                    if (fullTextFilterValue.value.phrase)
                        tokens = fullTextFilterValue.value.phrase.split(' ')
                    if (fullTextFilterValue.value.mustMatch)
                        tokens = tokens.concat(fullTextFilterValue.value.mustMatch)
                    if (fullTextFilterValue.value.shouldMatch)
                        tokens = tokens.concat(fullTextFilterValue.value.shouldMatch)
                    var fullTextTokens = new Array()
                    tokens.forEach((token) => {
                        if (token) {
                            var newToken = token.replace(/"/g, '')
                            if (newToken)
                                fullTextTokens.push(newToken)
                        }
                    })
                    return fullTextTokens
                }
                else
                    return []
            })
          

            const criteriaWithValues = computed(() => {
                if (!criteria.value || !filterDefs.value)
                    return null
                var critValues = {}
                Object.keys(criteria.value).forEach((key) => {
                    var crit = criteria.value[key]
                    if (!crit.new || crit.hasValue)
                        critValues[key] = crit
                })
                return critValues
            })
            const criteriaPretty = computed({
                get: () => (criteriaWithValues.value ? JSON.stringify(criteriaWithValues.value, undefined, 4) : null),
                set: (newVal) => {
                    customCriteriaEdits.value = newVal
                }
            })
            const criteriaPrettyHeight = computed(() => 100)

            const displayMode = computed(() => query.value && query.value.displayMode ? query.value.displayMode : 0)
            const appliedFilters = computed(() => {
                if (filterDefs.value && criteria.value) {
                    var filters = Object.keys(criteria.value).map((crit) => criteria.value[crit])
                    return filters.filter((crit) => {
                        return !crit.new && filterDefs.value[crit.filterName] && filterDefs.value[crit.filterName].displayMode <= displayMode.value
                    })
                }
                return []
            })
            const appliedFiltersCount = computed(() => appliedFilters.value ? appliedFilters.value.length : 0)
            const linkedCard = computed(() => model.value && model.value.linkedCard && model.value.linkedCard.id != 0 ? model.value.linkedCard : null)
            const linkedCardName = computed(() => linkedCard.value && linkedCard.value != null ? linkedCard.value.name : null)
            const linkedCardDashName = computed(() => {
                if (linkedCard.value)
                    return (linkedCard.value.ownerID && linkedCard.value.ownerName
                        ? 'Card is in the "' + linkedCard.value.ownerName + '" dashboard'
                        : 'Card does not belong to any dashboard')
                return null
            })
            const queryChanged = computed(() => queryChanges.value ? (Object.keys(queryChanges.value).length > 0) : false)
            const cardChanged = computed(() => linkedCard.value && queryChanges.value ? Object.keys(queryChanges.value).length : 0)
            const queryName = computed(() => linkedCard.value && linkedCard.value.name ? linkedCard.value.name + (cardChanged.value ? '*' : '') : 'Search Results')
            const queryID = computed(() => query.value && query.value.id ? { id: query.value.id } : null)
            const pageTitle = computed(() => 'MandAsoft Search: ' + linkedCardName.value ? linkedCardName.value : (fullTextFilterValueName.value ?? 'adhoc'))

            const pagerVisible = computed(() => hitCount.value > 0 && ['Details', 'Table', 'Grid', 'Brands'].includes(selectedResultTab.value))

            const availableAggPanels = computed(() => resultsSet.value && resultsSet.value.availableAggPanels ? resultsSet.value.availableAggPanels : [])
            const statsAggPanels = computed(() => availableAggPanels.value && availableAggPanels.value.filter((key) => aggPanelDefs.value[key] && aggPanelDefs.value[key].Value && aggPanelDefs.value[key].Value.panelType == '1'))
            const significantTerms = computed(() => {
                if (!resultsSet.value && !resultsSet.value.significantTerms) return []
                //console.log('significantTerms', resultsSet.value.significantTerms)
                return _.isString(resultsSet.value.significantTerms) ? JSON.parse(resultsSet.value.significantTerms) : resultsSet.value.significantTerms

            })
            const requestedSignificantTermAggs = computed(() => {
                return aggPanelDefs.value ? Object.keys(aggPanelDefs.value).filter((key) => {
                    return aggPanelDefs.value[key] && aggPanelDefs.value[key].Value && aggPanelDefs.value[key].Value.panelType == '6'
                }) : []
            })
            const tagCloudsData = computed(() => {
                var tagClouds = []
                requestedSignificantTermAggs.value.forEach((key) => {
                    if (availableAggPanels.value.includes(key) && significantTerms.value[key]) {
                        tagClouds.push({
                            tagCloudName: key,
                            significantTerms: significantTerms.value,
                            query: resultsSet.value.query,
                        })
                    }
                })
                return tagClouds
            })
            const displayedGraphAggs = computed(() => {
                return aggDefs.value ? Object.keys(aggDefs.value).filter((key) => {
                    return resultsSet.value && resultsSet.value.counts && resultsSet.value.counts[key] && Object.keys(resultsSet.value.counts[key]).length > 1
                }) : []
            })
            const requestedGraphAggs = computed(() => {
                return aggDefs.value ? Object.keys(aggDefs.value).filter((key) => {
                    return resultsSet.value.query && resultsSet.value.query.selectedGraphAggs && resultsSet.value.query.selectedGraphAggs.includes(key)
                }) : []
            })
            const graphAggsData = computed(() => {
                var graphData = []
                requestedGraphAggs.value.forEach((key) => {
                    if (availableAggPanels.value.includes(key)) {

                        graphData.push({
                            graphName: key,
                            query: resultsSet.value.query,
                        })
                    }
                })
                return graphData
            })

            // methods
            const runSearch = (opt) => {
                return new Promise((resolve, reject) => {
                    if (!query.value) {
                        resolve()
                        return
                    }
                    if (!opt)
                        opt = {}

                    scrollToTop()

                    query.value.criteria = _.cloneDeep(criteriaWithValues.value)
                    query.value.displayMode = advancedMode.value ? 1 : 0;

                    query.value.selectedSignificantTerms = requestedSignificantTermAggs.value
                    query.value.detailAddress = null
                    if (linkedCard.value && !cardChanged.value)
                        query.value.dontRecord = false


                    //this will force the service to generate a new name for every interim query. We will update the query object after the call
                    var originalName = query.value.name
                    if (!opt.paging) {
                        query.value.name = ""
                    }

                    query.value.returnNativeESDocs = true
                    query.value.noAggregates = !!opt.paging

                    searchRunning.value = true

                    var linkedCardIDs = linkedCard.value ? { id: linkedCard.value.id, ownerID: linkedCard.value.ownerID } : null;
                    store.dispatch('search/runSearch', { query: query.value, overrideHistory: (!!opt.overrideHistory || !!opt.paging), id: resultsID, paging: opt.paging, linkedCard: linkedCardIDs })
                        .then((res) => {
                            scrollToElementTop(elSearchResults.value, 0)
                            if (res) {
                                if (!opt.paging) {
                                    query.value = _.cloneDeep(res.query)
                                    emitter.emit('mergeFilters', _.cloneDeep(query.value.criteria))

                                    if (linkedCard.value) {
                                        linkedCard.value.query = query.value
                                    }
                                    if (originalName)
                                        query.value.name = originalName
                                }

                                criteria.value = query.value.criteria;
                                advancedMode.value = query.value.displayMode ? true : false;


                                setQueryChanges(opt.isInitialSearch);

                                if (hitCount.value > 0 && selectedResultTab.value == 'No Listing')
                                    selectedResultTab.value = resultsDisplayMode.value
                                else if (hitCount.value == 0) {
                                    if (selectedResultTab.value != 'No Listing') {
                                        resultsDisplayMode.value = selectedResultTab.value
                                        selectedResultTab.value = 'No Listing'
                                    }
                                }
                            }
                            searchRunning.value = false
                            setFocusToFullText()
                            emitter.emit('runSearchComplete', { resultsSet: res })
                            console.log(res)
                            resolve()

                        }).catch(error => {
                            toast.add({ severity: 'error', summary: 'Error Running Search', detail: error, life: 5000 })
                            searchRunning.value = false
                            reject(error)
                        })
                })
            }
            const setQueryChanges = (reset) => {
                if (reset)
                    originalQuery.value = _.cloneDeep(query.value)

                queryChanges.value = detectQueryChanges(originalQuery.value, query.value)
            }

            const runEditCountSearch = (filterName) => {
                return new Promise((resolve, reject) => {
                    //console.log('runEditCountSearch', filterName, criteriaWithValues.value)
                    if (!filterName || !criteriaWithValues.value) {
                        reject('bad params')
                        return
                    }
                    searchRunning.value = true
                    store.dispatch('search/searchEditValues', { queryID: query.value.id, filterName: filterName, id: resultsID })
                        .then((results) => {
                            //console.log('searchEditValues-results', results)
                            resolve(results)
                            searchRunning.value = false
                        }).catch(error => {
                            toast.add({ severity: 'error', summary: 'Error Finding Edit Values', detail: error, life: 5000 })
                            reject(error)
                            searchRunning.value = false
                        })
                })
            }
            const fetchSearchVueModel = (queryString) => {
                // Runs just once at creation
                return new Promise((resolve, reject) => {
                    loading.value = true
                    if (!queryString)
                        queryString = props.QueryString;


                    console.log('fetchSearchVueModel', queryString)
                    store.dispatch('search/fetchSearchVueModel', { queryString: queryString, id: resultsID }).then((searchModel) => {
                        model.value = searchModel
                        console.log('fetchSearchVueModel-model', model.value)

                        if (searchModel.resultsSet) {
                            query.value = _.cloneDeep(searchModel.resultsSet.query)
                            criteria.value = _.cloneDeep(searchModel.resultsSet.query.criteria)
                            advancedMode.value = query.value.displayMode ? true : false;

                           // emitter.emit('mergeFilters', _.cloneDeep(searchModel.resultsSet.query.criteria))
                        }

                        initializeResultsViewMode()
                        query.value.skip = 0
                        query.value.limit = store.state.search.hitLimit ?? 15

                        fetchLinkedDashboard().then(() => {
                            loading.value = false
                            runSearch({ isInitialSearch: isEqualQueryString(queryString, props.QueryString) }).then(() => {
                                setFocusToFullText()
                            })
                        })
                    }).catch(error => {
                        toast.add({ severity: 'error', summary: 'Error loading Search Model', detail: error, life: 5000 })
                        loading.value = false
                        reject(error)
                    })
                })
            }
            const isEqualQueryString = (q1, q2) => {
                var q1L = _.cloneDeep(q1);
                var q2L = _.cloneDeep(q2);

                _.each(q1L, function (obj) {
                    if (obj['key']) {
                        obj['key'] = obj['key'].toLowerCase()
                        if (obj['value'])
                            obj['value'] = obj['value'].toLowerCase()
                    }
                })
                _.each(q2L, function (obj) {
                    if (obj['key']) {
                        obj['key'] = obj['key'].toLowerCase()
                        if (obj['value'])
                            obj['value'] = obj['value'].toLowerCase()
                    }
                })

                return _.isEqual(q1L, q2L)
            }
            const setFocusToFullText = () => {
                if (itemRefs.value && itemRefs.value.fullTextSearch)
                    itemRefs.value.fullTextSearch.focus()
            }
            const fetchLinkedDashboard = () => {
                return new Promise((resolve, reject) => {
                    if (linkedCard.value && linkedCard.value.ownerID) {
                        linkedDashboard.value = dashboardGroup.value && dashboardGroup.value.dashboards ? dashboardGroup.value.dashboards.find((dash) => { return dash.id == linkedCard.value.ownerID }) : null
                        if (linkedDashboard.value)
                            resolve()
                        else {
                            store.dispatch('dashboards/fetchDashboard', { id: linkedCard.value.ownerID }).then((dash) => {
                                linkedDashboard.value = dash
                                resolve()
                            }).catch(error => {
                                toast.add({ severity: 'error', summary: 'Error loading linked Dashboard', detail: error, life: 5000 })
                                reject()
                            })
                        }
                    }
                    else
                        resolve()
                })
            }
            const initializeResultsViewMode = () => {
                //prioritize the card's view
                if (linkedCard.value) {
                    cardViewOverride.value = true

                    var cardDisplayTypeName = displayModeCardMapRev[linkedCard.value.cardDisplayType]
                    if (cardDisplayTypeName && resultsViewModes[cardDisplayTypeName])
                        resultsDisplayMode.value = resultsViewModes[cardDisplayTypeName]
                }

                selectedResultTab.value = resultsDisplayMode.value
            }
            const reloadPreviousSearch = () => {
                // need to check if previous route is a search
                router.back()
            }
            const detectQueryChanges = (query, newQuery) => {
                var queryChanges = {};
                if (query && newQuery && query.criteria && newQuery.criteria) {
                    //compare the criterias
                    Object.keys(query.criteria).forEach((k) => {
                        var v = query.criteria[k]
                        if (queryChanges[k] == undefined) {
                            var crit = {
                                name: v.name ? v.name : v.key,
                                value: query.criteria[k] ? query.criteria[k].valueName : null,
                                newValue: newQuery.criteria[k] ? newQuery.criteria[k].valueName : null
                            }
                            if (crit.value != crit.newValue)
                                queryChanges[k] = crit
                        }
                    })

                    Object.keys(newQuery.criteria).forEach((k) => {
                        var v = newQuery.criteria[k]
                        if (queryChanges[k] == undefined) {
                            var crit = {
                                name: v.name ? v.name : v.key,
                                value: query.criteria[k] ? query.criteria[k].valueName : null,
                                newValue: newQuery.criteria[k] ? newQuery.criteria[k].valueName : null
                            }
                            if (crit.value != crit.newValue)
                                queryChanges[k] = crit
                        }
                    })

                    //compare the sort
                    if (query.selectedSort != newQuery.selectedSort || query.sortAscending != newQuery.sortAscending) {
                        queryChanges["Sort"] = {
                            name: "Sort",
                            value: (query.selectedSort ? query.selectedSort : 'default') + ' (' + sortLabel(query.selectedSort, query.sortAscending) + ')',
                            newValue: newQuery.selectedSort + ' (' + sortLabel(newQuery.selectedSort, newQuery.sortAscending) + ')'
                        }
                    }

                    //compare advanced mode
                    if (query.displayMode != newQuery.displayMode) {
                        queryChanges["Advanced"] = {
                            name: "Advanced Mode",
                            value: query.displayMode ? "" : null,
                            newValue: newQuery.displayMode ? "" : null
                        }
                    }
                }

                return queryChanges
            }
            const checkLOBHasValue = (parsedValue) => {
                var hasValue = (parsedValue.industry || parsedValue.audience || parsedValue.subject || parsedValue.attribute)
                if (!hasValue && parsedValue.children) {
                    parsedValue.children.forEach((child) => {
                        hasValue = checkLOBHasValue(child)
                        if (hasValue)
                            return true
                    })
                }
                return hasValue
            }
            const resetQuery = () => {
                query.value.sortAscending = false
                query.value.selectedSort = null
                criteria.value = {}
                runSearch()
            }
            const searchInSearchChoice = () => {
                nextChoice({ title: 'Search in Search', message: 'Which acq members to search on?', choices: [{ text: 'Search In Buyers', res: 'buyers' }, { text: 'Search In Sellers', res: 'sellers' }] })
                    .then(choice => {
                        if (choice) {
                            searchInSearch({ flipPath: choice + '.id', targetType: 'Company' });
                        }
                    })
            }
            const searchInSearch = (opt) => {
                if (opt.flipPath && opt.value) {
                    if (opt.value[0] == '[')
                        opt.value = JSON.parse(opt.value)[0];

                    searchRunning.value = true
                    store.dispatch('search/runSearchInSearch', { queryID: query.value.id, targetType: opt.value, sourceFieldPath: opt.flipPath })
                        .then((res) => {
                            setTimeout(function () {
                                searchRunning.value = false;
                                query.value.pivot = res.pivot;
                                criteria.value = res.newCriteria;
                                runSearch()
                            }, 2500);

                        }).catch((error) => {
                            searchRunning.value = false
                            toast.add({ severity: 'error', summary: 'Error Running Search in Search', detail: error, life: 5000 })
                        })
                }
            }

            const applyFilter = (req) => {
                console.log('applyFilter-search', req)
                if (req && req.filter && req.filterVal) {
                    switch (req.filter) {
                        case 'term':
                            break
                        case 'nodeID':
                            if (criteria.value['lobCombo'] && !criteria.value['lobCombo'].isEditing && !criteria.value['lobCombo'].new) {
                                var oldVal = JSON.parse(criteria.value['lobCombo'].value)
                                addNodeIDToFilter({ nodeID: req.filterVal, filterVal: oldVal }).then((newFilterVal) => {
                                    criteria.value['lobCombo'].value = JSON.stringify(newFilterVal)
                                    criteria.value['lobCombo'].hasValue = true
                                    runSearch()
                                }).catch(error => {
                                    toast.add({ severity: 'error', summary: 'Error Adding NodeID to Filter', detail: error, life: 5000 })
                                })
                            }
                            else
                                return // ignore the event and let the lobCombo filter handle this
                            break
                        default:
                            if (criteria.value[req.filter]) {
                                var oldFilter = criteria.value[req.filter]
                                oldFilter.value = req.filterVal
                                oldFilter.hasValue = true
                                criteria.value[req.filter] = oldFilter
                            }
                            else {
                                var newFilter = _.cloneDeep(defaultFilterTemplate)
                                newFilter.key = req.filter
                                newFilter.value = req.filterVal
                                newFilter.hasValue = true
                                criteria.value[req.filter] = newFilter
                            }
                            runSearch()
                            break
                    }
                }
            }            
            const handlePopstate = (ev) => {
                var current = ev.state.current;
                if (current && current.toLowerCase().startsWith('/search/vue')) {
                    var back = ev.state.back
                    var forward = ev.state.forward
                    var qsMap = mapQueryString(current)
                    _.noop('handlePopstate', qsMap, current, back, forward, ev)

                    //find the query in the search history
                    var histQuery;
                    if (qsMap.map.queryid && searchHistory.value[qsMap.map.queryid])
                        histQuery = searchHistory.value[qsMap.map.queryid];
                    else if (qsMap.map.queryID && searchHistory.value[qsMap.map.queryID])
                        histQuery = searchHistory.value[qsMap.map.queryID];

                    if (histQuery) {
                        //if we we hit back into a query that's part of our linkedCard, do runQuery instead of re-fetching the model
                        if (histQuery.linkedCard && linkedCard.value && histQuery.linkedCard.id == linkedCard.value.id && histQuery.linkedCard.ownerID == linkedCard.value.ownerID) {
                            query.value = _.cloneDeep(histQuery);
                            criteria.value = _.cloneDeep(histQuery.criteria);

                            lockQueryID.value = true;
                            runSearch().then(() => {
                                lockQueryID.value = false;
                            })
                        }
                        else
                            fetchSearchVueModel([{ key: 'queryid', value: histQuery.id + "" }]);
                    }
                    else
                        fetchSearchVueModel(qsMap.array);
                }
                else
                    console.log('!!!!handlePopstate->shouldNotBeSeen', ev, current)
            }
            const mapQueryString = (path) => {
                var qsMap = {}
                var qsArray = []
                var qString = path.indexOf('?') > -1 ? path.slice(path.indexOf('?') + 1) : null
                if (qString) {
                    qString.split('&').forEach((pm) => {
                        var pmSplit = pm.split('=')
                        if (pmSplit.length > 1) {
                            qsMap[pmSplit[0]] = pmSplit[1]
                            qsArray.push({ key: pmSplit[0], value: pmSplit[1] })
                        }
                    })
                }
                return { map: qsMap, array: qsArray, queryString: qString }
            }
            const searchHitsUpdate = (req) => {
                if (resultsSet.value && resultsSet.value.hits && resultsID) {
                    resultsSet.value.hits.forEach((hit, idx) => {
                        if (hit && hit.objectKey == req.objectKey) {
                            store.commit('search/UPDATE_SEARCHRESULT_HIT', { id: resultsID, hitIDX: idx, newHit: req.newObject })
                        }
                    })
                }
                if (detailsRequest.value && detailsRequest.value.obj && detailsRequest.value.obj.objectKey == req.objectKey)
                    detailsRequest.value.obj = req.newObject
            }
            const handleNavigate = (direction) => {
                var refSideBar = itemRefs.value && itemRefs.value.outerSidebar ? itemRefs.value.outerSidebar: null

                if (detailsRequest.value && detailsRequest.value.geoRequest && geoResultsSet.value && geoResultsSet.value.fetched)
                    sidebarNavigate(direction, refSideBar, getGeoHit, geoResultsSet.value ? geoResultsSet.value.hits.length : 0)
                else
                    sidebarNavigate(direction, refSideBar, getHit, resultsSet.value ? resultsSet.value.hits.length : 0)
            }
            const getHit = (listIndex) => {
                if (resultsSet.value && resultsSet.value.hits && listIndex >= 0 && listIndex < resultsSet.value.hits.length) {
                    return { 'obj': resultsSet.value.hits[listIndex], 'listIndex': listIndex, 'cardID': 0 }
                }
                else
                    return null
            }
            const getGeoHit = (listIndex) => {
                if (geoResultsSet.value && geoResultsSet.value.hits && listIndex >= 0 && listIndex < geoResultsSet.value.hits.length) {
                    return { 'obj': geoResultsSet.value.hits[listIndex], 'listIndex': listIndex, 'cardID': 0 }
                }
                else
                    return null
            }
            const removeFromQuery = (req) => {
                if (req && req.queryContext && req.queryContext.targetID == queryID.value.id && req.queryContext.targetTypeID == 18) {
                    if (req.obj && req.obj.objectKey) {
                        removeSearchEntry({ type: 'searchEntry', key: req.obj.objectKey })
                    }
                }
            }
            const removeSearchEntry = (opt) => {
                if (opt.type == 'sourceEntry' && pivotRequest.value) {
                    searchRunning.value = true
                    store.dispatch('search/removeSourceKey', {
                        key: opt.key,
                        sourceQueryID: pivotRequest.value.sourceQueryID,
                        targetDocumentType: pivotRequest.value.targetDocumentType
                    }).then((results) => {
                        _.noop(results)
                        searchRunning.value = false
                        runSearch()
                    }).catch(error => {
                        toast.add({ severity: 'error', summary: 'Error removing Search Entry', detail: error, life: 5000 })
                        searchRunning.value = false
                    })
                }
                else if (opt.type == 'searchEntry') {
                    var excludeKeysCrit = criteria.value.excludeKeys ?? _.cloneDeep(defaultFilterTemplate)
                    excludeKeysCrit.key = 'excludeKeys'
                    excludeKeysCrit.hasValue = true
                    var excludeKeys = (excludeKeysCrit.value ? JSON.parse(excludeKeysCrit.value) : [])
                    excludeKeys.push(opt.key)
                    excludeKeysCrit.value = JSON.stringify(excludeKeys)
                    excludeKeysCrit.hasValue = !!excludeKeys
                    criteria.value[excludeKeysCrit.key] = excludeKeysCrit
                    runSearch()
                }
            }
            const saveCriteria = () => {
                if (customCriteriaEdits.value) {
                    console.log('saveCriteria', customCriteriaEdits.value)
                    var newCrit = JSON.parse(customCriteriaEdits.value)
                    if (newCrit) {
                        criteria.value = newCrit
                    }
                }
            }
            const textInputed = () => {
                emitter.emit("applyFilter", { filter: 'term', filterVal: textInputModel.value })
                textInputModel.value = null
            }

            // watches
            watch(queryID, (newID, oldID) => {
                console.log('queryID-watch', newID, oldID)
                if (newID && queryChanged.value && !lockQueryID.value) {
                    router.push('/search/vue/?queryID=' + newID.id)

                    if (!linkedCard.value)
                        setQueryChanges(true)
                }
            })

            const pageChanged = (newVal) => {
                if (!searchRunning.value) {
                    query.value.skip = newVal.skip
                    query.value.limit = newVal.limit
                    store.commit('search/SET_HITLIMIT', newVal.limit)
                    runSearch({ paging: true })

                }
            }

            watch(selectedResultTab, (newVal) => {
                var viewMode = resultsViewModesRev[newVal];
                if (!cardViewOverride.value && viewMode)
                    store.commit('search/SET_RESULTSVIEWMODE', viewMode)
            })

            watch(() => props.QueryString, (newQueryString, oldQueryString) => {
                //we need this for cases where we want to go back to the base Search page
                if (oldQueryString && newQueryString && oldQueryString.length && !newQueryString.length) {
                    fetchSearchVueModel()
                    document.title = 'MandAsoft Search'
                }
            })

            // life cycle
            onMounted(() => {
                /*model.value = null
                elSearchResults.value = null
                query.value = {}
                criteria.value = {}
                isMobileFiltersOpen.value = false
                loading.value = false
                searchRunning.value = false
                resultsDisplayMode.value = 'Details'
                selectedResultTab.value = 'Details'
                advancedMode.value = false
                cardViewOverride.value = false
                originalQuery.value = null
                queryChanges.value = {}
                linkedDashboard.value = null
                customCriteriaEdits.value = {}
                textInputModel.value = null*/
                fetchSearchVueModel()
                document.title = 'MandAsoft - Search'
                window.addEventListener('popstate', handlePopstate)
                emitter.on("objectUpdate", searchHitsUpdate)
                emitter.on("removeFromQuery", removeFromQuery)
                emitter.on("runSearch", runSearch)
                emitter.on("applyFilter", applyFilter)
            })
            onBeforeUnmount(() => {
                window.removeEventListener('popstate', handlePopstate)
                emitter.off("objectUpdate", searchHitsUpdate)
                emitter.off("removeFromQuery", removeFromQuery)
                emitter.off("runSearch", runSearch)
                emitter.off("applyFilter", applyFilter)
            })
            provide('query', computed(() => query.value && query.value.id ? { id: query.value.id } : null))
            provide('highlightText', fullTextTokens)

            return {
                // data
                model, query, elSearchResults, criteria, isMobileFiltersOpen, loading, searchRunning, resultsDisplayMode,
                selectedResultTab, advancedMode, cardViewOverride, originalQuery, queryChanges, linkedDashboard, detailsRequest, showHits,
                sidebarShowing, customCriteriaEdits, textInputModel, 
                // const
                resultsViewModes, resultsViewModesRev, displayModeCardMap, displayModeCardMapRev, editorID, resultsID, defaultLobFilterVal,
                // tools
                emitter, itemRefs, setItemRef, itemRefsReset,
                // state and getters
                resultsSets, hitLimit, searchHistory, user,
                dashboardGroup, emailTemplates, panelDefs, filterDefs, aggPanelDefs, sortDefs, aggDefs,
                // computed
                geoResultsSet, resultsSet, hitCount, resultCount, resultCountFormatted, resultSetCriteria, alerts,
                criteriaWithValues, criteriaPretty, criteriaPrettyHeight,
                availableSorts, sortOptions, sortBy, sortByLabel, pivotRequest,
                fullTextModel, fullTextFilterParameter, fullTextFilterValue, fullTextFilterValueName, termText, fullTextTokens,
                displayMode, appliedFilters, appliedFiltersCount, pagerVisible,
                linkedCard, linkedCardName, linkedCardDashName, queryChanged, cardChanged, queryID, queryName, pageTitle,
                availableAggPanels, statsAggPanels, significantTerms, requestedSignificantTermAggs, tagCloudsData,
                requestedGraphAggs, graphAggsData, displayedGraphAggs,
                // methods
                sortLabel, runSearch, setQueryChanges, runEditCountSearch, fetchSearchVueModel, fetchLinkedDashboard, initializeResultsViewMode,
                reloadPreviousSearch, detectQueryChanges, checkLOBHasValue, resetQuery, saveCriteria, pageChanged,
                applyFilter, setFocusToFullText, handlePopstate, mapQueryString, addNodeIDToFilter, textInputed,
                openSidebar, fetchDetailsRequest, searchHitsUpdate, sidebarNavigate, getHit, getGeoHit, handleNavigate, removeFromQuery, removeSearchEntry, searchInSearchChoice, searchInSearch,
            }
        },
        watch: {
            "pageTitle": {
                handler(val) {
                    //console.log('pageTitle-watch', val)
                    document.title = val
                }
            }
        }
    }
    
</script>
<style scoped lang="scss">
    .back-to-top {
        display: none !important;
    }
</style>