<template>
    <div>
        <h5> CMDM has found {{size}} stored {{ size > 1 ? elementType + 's' : elementType }}</h5>
        <p>Click on the "Load" button to recover a {{ elementType }}, or on the "Delete" button to remove it from the recovery cache.</p>
        <template v-if="storedElements">
            <div class="box border storedElementsContainer">
                <div class="storedElement box-section frame-vertical-min frame-horizontal-small flex-rows no-responsive" v-for="(element, id) in sortedStoredElements" :key="id">
                    <div class="">
                        <h4 v-if="element.title">
                            {{ element.title }}
                        </h4>
                        <div :title="element.text">
                            {{ element.text && element.text.length > 50 ? element.text.slice(0, 50) + ' ...' : element.text.slice(0, 50)  }}
                        </div>
                        <p class="type-note">{{ element.savedOn }}</p>
                    </div>
                    <div class="place-right">
                        <btn type="icon" size="medium" icon="edit" @click="loadElement(id)" />
                        <span class="frame-horizontal-min hide-on-mobile"></span>
                        <btn type="icon" size="medium" icon="trash-alt" @click="confirmDeleteElement(id)" />
                    </div>
                </div>
            </div>
        </template>
        <div class="flex-rows no-responsive fullwidth space-between margin-top-small">
            <btn type='secondary' @click="confirmDeleteAllElements()">Delete All</btn>
            <btn @click="closeStoreElements()">Close</btn>
        </div>
    </div>
</template>

<script>
    import { inject } from 'vue'
    import { mapGetters } from 'vuex'
    import storeHelper from '../lib/storeHelper.js'
    import _ from 'lodash'

    export default {
        name: 'StoreElements',
        emits: ['close', 'selected'],
        props: {
            elementType: {
                type: String,
                default: 'object'
            },
        },
        data: function () {
            return {
                storedElements: storeHelper.storeUtils.storeGetElements()
            }
        },
        setup() {
            const nextConfirm = inject('nextConfirm')

            return { nextConfirm }
        },
        computed: {
            ...mapGetters({
                objectTypeIdToStringMap: 'search/objectTypeIdToStringMap',
                objectTypeStringToIdMap: 'search/objectTypeStringToIdMap',
                currentUserID: 'userInfo/currentUserID'
            }),
            size: function () {
                return Object.keys(this.storedElements).length;
            },
            sortedStoredElements: function () {
                var me = this;
                var keys = Object.keys(this.storedElements).reverse();
                var sortedStore = {};
                _.each(keys, function (key) {
                    sortedStore[key] = me.storedElements[key];
                });

                return sortedStore;
            }
        },
        methods: {
            loadElement: function (id) {
                var me = this;
                var element = me.storedElements[id];
                if (element) {
                    this.$emit('selected', element);
                    me.closeStoreElements();
                }
            },
            confirmDeleteElement: function (id) {
                var me = this;
                if (id) {
                    this.nextConfirm("Delete", "Delete the cached " + me.elementType + "?", "warning").then((res) => {
                       if (res) {
                           storeHelper.storeUtils.deleteElement(id);
                           me.storedElements = storeHelper.storeUtils.storeGetElements();

                           if (me.size == 0)
                               me.closeStoreElements();
                       }
                    });
                }
            },
            confirmDeleteAllElements: function () {
                var me = this;
                me.nextConfirm("Delete", "Delete all cached " + me.elementType + "s?", "warning").then((response) => {
                    if (response) {
                        storeHelper.storeUtils.deleteAllElements();
                        me.closeStoreElements();
                    }
                });
            },
            closeStoreElements: function () {
               this.$emit('close');
            }
        },
        created() {
            var me = this;
            storeHelper.storeUtils.initStorage(me.elementType);
        }
    }
</script>

<style lang="scss">
    .storedElementsContainer {
        overflow-y:scroll;
        max-height: 600px
    }
</style>
