<template>
    <div>
        <header v-if="showHeader" class="section-header flex-rows margin-top margin-bottom-small">
            <h3>Lines of Business</h3>
            <span v-if="dirtyCount"> {{ dirtyCount + (dirtyCount > 1 ? ' lobs' : ' lob') }} changed</span>
            <i v-show="isEditing" class="fa fa-edit hide"></i>
            <spin-loader :showMe="showSpinLoader" />
            <span class="place-right flex-rows no-responsive">
                <btn el="button" title="Add LOB" @click="addLOB" type="primary"><i class="far fa-plus-circle"></i> Add LOB</btn>
            </span>
        </header>
        <table-flex name="table-new" :colTitles="['LOBs']">
            <template v-for="(lob, idx) in model" :key="lob.id">
                <lob-editor v-if="!lob.delete && (lob.active || advancedMode)"
                            v-model="model[idx]"
                            :inpopup="inpopup"
                            :advanced="advancedMode"
                            v-model:expanded="expanded[lob.id]"
                            v-model:editing="editingPerItem[lob.id]"
                            :disabled="disabled"
                            @primarychange="onPrimaryChange({index: idx, node: $event})"
                            @validate="validateModel"
                            @update:editing="isEditing = true" />
            </template>
        </table-flex>
        <div class="flex-rows no-responsive fullwidth margin-top-small">
            <checkbox v-model="advancedMode" label="Advanced Mode" :showAsSwitch="true" />
            <btn v-if="showSave && !disabled" el="button" title="Save" @click="saveChanges(false)" type="primary">Save</btn>
        </div>
    </div>
</template>
<script>

    import { ref, computed, nextTick, inject, provide, onMounted, onBeforeUnmount } from 'vue'
    import { useStore } from 'vuex'
    import _ from 'lodash'
    import { useToast } from '../../composables/ModelWrapper.js'
    import { editorProps, editorEmits, editorSetup } from '../../composables/Editors.js'

    import LobEditor from './LobEditor.vue'
    import TableFlex from '../ui/tables/TableFlex.vue'
    import Checkbox from '../ui/forms/Checkbox.vue'   

    export default {
        name: 'LobsEditor',
        components: {
            LobEditor, TableFlex, Checkbox
        },
        props: {
            ...editorProps(),
            modelValue: { type: Array }
        },
        emits: [...editorEmits()],
        setup(props, { emit }) {
            // Tools
            const toast = useToast()
            const emitter = inject("emitter")   // Inject event bus  
            const store = useStore()

            // models, data, computed from common setup
            const getModel = () => {
                return _.cloneDeep(props.modelValue)
            }
            const model = ref(getModel())
            const { advancedMode, errState, showSpinLoader, baseModel, expanded, isEditing, editingPerItem, savingEdits, activeItems, dirtyCount, deletedCount, onSave, rollbackModel } = editorSetup(props, emit, null, model)

            // data
            const primaryChanging = ref(false)
            // TBD: should have it look up ownerTypes
            var oKey = props.obj ? (props.obj.objectKey ? props.obj.objectKey : (ownerType.value + '::' + ownerID.value)) : null
            const ownerKey = ref(oKey)

            // computed
            const objectTypeIdToStringMap = computed(() => store.getters['search/objectTypeIdToStringMap'])
            const iconMap = computed(() => store.state.ais.iconMap)
            const defaultLOB = computed(() => store.state.ais.defaultLOB)


            const lobTypeID = computed(() => {
                if (model.value && model.value.length > 0)
                    return model.value[0].lobTypeID
                return 1
            })
            const ownerID = computed(() => {
                if (model.value && model.value.length > 0)
                    return model.value[0].ownerID
                return props.obj ? props.obj.id : null
            })
            const ownerType = computed(() => {
                return props.obj && props.objectType ? props.objectType : (lobTypeID.value ? objectTypeIdToStringMap[lobTypeID.value] : 'company')
            })
           

            // Methods
            const addLOB = () => {
                //create lob
                var primLOB = _.find(model.value, function (l) { return l.primaryLOB })
                var newLOB = primLOB ? _.cloneDeep(primLOB) : _.cloneDeep(defaultLOB.value)

                //massage new lob fields
                newLOB.id = nextID(model.value.map((lob) => lob.id))
                newLOB.primaryLOB = false
                newLOB.lobTypeID = lobTypeID.value
                newLOB.ownerID = ownerID.value
                newLOB.dirty = true

                // add lob to model
                model.value.push(newLOB)
                nextTick(() => {
                    //update component fields
                    expanded.value[newLOB.id] = true
                    editingPerItem.value[newLOB.id] = true
                    isEditing.value = true
                })
            }
            const nextID = (ids) => {
                var minID = _.min(ids)
                if (minID > 0)
                    return 0
                return minID - 1
            }


            const saveChanges = (suppressToastMessages) => {
                return new Promise((resolve, reject) => {
                    console.log('saveChanges', dirtyCount.value, deletedCount.value)
                    if ((dirtyCount.value + deletedCount.value) == 0) {
                        //if (!suppressToastMessages)
                          //  toast.add({ severity: 'info', summary: 'LOBs not changed', detail: 'not saving', life: 3000 })
                        resolve({ changed: false })
                        return
                    }

                    if (errState.value && errState.value.errorCount) {
                        if (!suppressToastMessages)
                            toast.add({ severity: 'error', summary: 'LOBs not valid', detail: errState.value.errorCount + ' invalid LOBs', life: 3000 })
                        reject('LOBs not valid: ' + errState.value.errorCount + ' invalid LOBs')
                        return
                    }
                    else {
                        showSpinLoader.value = true
                        store.dispatch('ais/saveLOBs', model.value).then((results) => {
                            showSpinLoader.value = false
                            model.value = _.cloneDeep(results.lobs)

                            if (!suppressToastMessages)
                                toast.add({ severity: 'info', summary: 'LOBs Saved', life: 3000 })
                            resolve({ changed: true })

                        }).catch(error => {
                            showSpinLoader.value = false
                            if (!suppressToastMessages)
                                toast.add({ severity: 'error', summary: 'Error saving LOBs', detail: error.message, life: 3000 })
                            reject('Error saving LOBs: ' + error.message)
                        })
                    }
                })
            }

            const saveHandler = (req) => {
                onSave(req, 'lobs', emitter, saveChanges)
            }
            const rollbackHandler = (req) => {
                if (req.editorID == props.editorID) {
                    rollbackModel()
                }
            }
            const onPrimaryChange = (ev) => {
                nextTick(() => {
                    if (!primaryChanging.value) {
                        primaryChanging.value = true
                        if (ev.node.primaryLOB) {
                            for (var idx = 0; idx < model.value.length; idx++) {
                                if (!model.value[idx].delete && (model.value[idx].active || advancedMode.value)) {
                                    if (model.value[idx].primaryLOB && model.value[idx].id != ev.node.id) {
                                        model.value[idx].primaryLOB = false
                                        break;
                                    }
                                }
                            }
                        }
                        else if (!_.find(model.value, function (l) { return l.primaryLOB })) {
                            var lobToSet = _.orderBy(model.value, ['active', 'verified', 'important'], ['desc', 'desc', 'desc'])[0]
                            for (var jdx = 0; jdx < model.value.length; jdx++) {
                                if (model.value[jdx].id == lobToSet.id) {
                                    model.value[jdx].primaryLOB = true
                                    break
                                }
                            }
                        }
                        primaryChanging.value = false

                    }
                })
            }
            const validateModel = () => {
                errState.value  = null
                var errorCount = 0
                var allErrorMessages = [];

                model.value.forEach((lob, idx) => {
                    if (!lob.delete) {
                        var errMessages = []
                        if (!lob.audienceID) errMessages.push('missing audience')
                        if (!lob.industryID) errMessages.push('missing industry')
                        if (!lob.subjectID) errMessages.push('missing subject')
                        if (!lob.attributeID) errMessages.push('missing attribute')
                        if (errMessages.length > 0) {
                            errorCount++
                            allErrorMessages.push('lob(' + (idx + 1) + '): ' + errMessages.join(', '))
                            lob.error = errMessages.join(', ')
                        }
                        else
                            lob.error = null
                    }

                })
                errState.value  = errorCount ? { errorCount: errorCount, errorMessages: allErrorMessages } : null
                return errState.value
            }
            const fetch = (opt) => {
                console.log('fetchLOBs', opt)
                showSpinLoader.value = true
                return new Promise((resolve, reject) => {
                    store.dispatch('ais/fetchLOBSet', { 'ownerID': opt.ownerID, 'lobTypeID': opt.lobTypeID })
                        .then((results) => {
                            showSpinLoader.value = false
                            if (results && results.lobs) {
                                model.value = _.cloneDeep(results.lobs)
                            }
                            console.log('loadedlobs', results.lobs)
                            resolve()
                        }).catch(error => {
                            if (!opt.suppressToastMessages)
                                toast.add({ severity: 'error', summary: 'Error fetching LOBs', detail: error, life: 3000 })
                            showSpinLoader.value = false
                            reject()
                        })
                })
            }

            // lifecycle
            onMounted(() => {
                model.value = getModel()
                primaryChanging.value = false
                ownerKey.value = props.obj ? (props.obj.objectKey ? props.obj.objectKey : (ownerType.value + '::' + ownerID.value)) : null
                emitter.on("saveEditor", saveHandler);
                emitter.on("rollbackModel", rollbackHandler)
            })
            onBeforeUnmount(() => {
                emitter.off("saveEditor", saveHandler);
                emitter.off("rollbackModel", rollbackHandler)
            })

            provide('primaryChanging', computed(() => primaryChanging.value))

            return {
                // tools
                emitter,
                // models
                model, advancedMode, isEditing, errState,
                // data
                showSpinLoader, baseModel, savingEdits, expanded, editingPerItem, ownerKey, primaryChanging,
                // computed
                dirtyCount, deletedCount, activeItems, lobTypeID,
                ownerType, ownerID, objectTypeIdToStringMap, iconMap, defaultLOB, 
                // methods
                addLOB, nextID, saveChanges, onSave, saveHandler, rollbackHandler, onPrimaryChange, getModel,
                validateModel, fetch
            }

        },       
        watch: {
            "modelValue": {
                handler() {
                    this.model = this.getModel();
                }
            },
            "model": {
                handler() {
                    this.editingPerItem = {}
                    this.expanded = {}
                    nextTick(() => {
                        this.model.forEach((item) => {
                            item.dirty = false
                            item.delete = false;
                        })
                        this.baseModel = _.cloneDeep(this.model)
                    })
                }
            },
            "dirtyCount": {
                handler(newValue, oldValue) {
                    if (!oldValue && newValue > 0 && !this.showSpinLoader) {
                        console.log('dirtyCount Watch', newValue, this.showSpinLoader)
                        this.$emit('dirtied', newValue)
                    }
                    else if (oldValue && newValue == 0) {
                        this.errState = null
                        this.$emit('cleaned', newValue)
                    }
                }
            }
        }
    }
</script>
<style scoped lang="scss">
</style>
