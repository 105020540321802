<template>
    <dropdown type="box" :direction="direction">
        <template #toggle>
            <span class="margin-right-min">{{ title }}:</span>
            <span class="badge-default medium" :class="totalScoreClass">{{ toPercentText(totalScorePercent) }}</span>
        </template>
        <template #body>
            <div class="progression-bars completeness frame-small">
                <div class="column labels">
                    <template v-for="subScore in subScores" :key="subScore.title">
                        <span class="content">
                            <span class="label" v-if="subScore.title != undefined" @click="onClick(subScore.action)">
                                {{ subScore.title }}
                            </span>
                        </span>
                    </template>
                </div>
                <div class="column data">
                    <template v-for="subScore in subScores" :key="subScore.title">
                        <span class="content">
                            <span class="bar" v-if="subScore.title != undefined" @click="onClick(subScore.action)">
                                <span class="bar-marker" :class="scoreClass(subScore.factor)" :style="'width:' + toPercentText(subScore.factor)"></span>
                            </span>
                        </span>
                    </template>
                </div>
                <div class="column badges">
                    <template v-for="subScore in subScores" :key="subScore.title">
                        <span class="content">
                            <span class="badge-default small" :class="scoreClass(subScore.factor)" v-if="subScore.title != undefined" @click="onClick(subScore.action)">
                                {{ toPercentText(subScore.factor) }}
                            </span>
                        </span>
                    </template>
                </div>
            </div>
        </template>
    </dropdown>

</template>
<script>
    export default {
        name: 'ScoreDropdown',
        props: {
            'title': { type: String, default:'Completeness' },
            'totalScorePercent': {
                type: Number,
                default: 0
            },
            'subScores': {
                type: Array
            },
            direction: {
                type: String,
                default: 'left-up'
            }
        },
        emits: ['actionrequest'],
        computed: {
            totalScoreClass() {
                return this.scoreClass(this.totalScorePercent)
            }           
        },
        methods: {
            toPercentText(score) {
                return (score * 100).toFixed(0) + '%'
            },
            onClick(action) {
                this.$emit('actionrequest', action)
            }
        }
    }
</script>
<style scoped lang="scss">
    
    span.text-danger {
        color: darkred
    }
    span.text-warning {
        color: gold
    }
    span.text-success {
        color: forestgreen
    }
    span.text-info {
        color: dodgerblue
    }

    a:any-link.text-danger i::before { color: darkred;}
  
    a:any-link.text-warning i::before {
        color: gold;
    }

   
    a:any-link.text-success i::before {
        color: forestgreen;
    }

    a:any-link.text-info i::before {
        color: dodgerblue;
    }

</style>
