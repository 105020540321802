<template>
    <ul class="list-blocks" :class="[name,directionClass,optionClasses]">
        <slot />
    </ul>
</template>

<script>
    export default {
        name: 'ListBlocks',
        props: {
            name: String,
            options: String,
            direction: {
                type: String,
                default: 'vertical'
            }
        },
        data: function () {
            return {
                
            }
        },
        provide: {
            type: 'blocks'
        },
        computed: {
            directionClass() {
                if (this.direction === 'horizontal') {
                    return this.direction
                } else {
                    return null;
                }
            },
            optionClasses() {
                if (this.options === 'single-box' || this.options === 'box' || this.options === 'boxed') {
                    return 'single-box'
                } else {
                    return null
                }
            }
        }
    }
</script>

<style scoped lang="scss">
</style>