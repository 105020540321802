<template>
    <div v-if="readOnly" class="flagsDisplay">
        <template v-for="fName in flagNames" :key="fName">
            <span class="button-icon" v-if="flagStates[fName].allFlagCount" :title="flagStates[fName].description">
                <i :class="flagIconOverride[fName] ? flagIconOverride[fName]  : flagStates[fName].icon"></i>
                <span class="count">{{ flagStates[fName].allFlagCount }}</span>
            </span>
        </template>
    </div>
    <template v-else>
        <ul class="menu-horizontal">
            <template v-for="fName in flagNames" :key="fName">
                <li v-if="flagStates[fName].showAlways || flagStates[fName].flags.length > 0" @click="toggleFlag(fName)" :title="flagStates[fName].description">
                    <span class="button-icon">
                        <i :class="flagIconOverride[fName] ? flagIconOverride[fName]  : flagStates[fName].icon"></i>
                        <span v-if="flagStates[fName].allFlagCount > 1" class="count">{{ flagStates[fName].allFlagCount }}</span>
                    </span>
                </li>
            </template>
            <li v-if="queryContext" title="Exclude from search" @click="sendEventBus('removeFromQuery', {'obj': obj, 'queryContext': queryContext })">
                <span class="button-icon">
                    <i class="fa fa-trash-alt"></i>
                </span>
            </li>
        </ul>
        <flag-modal :ref="(el)=>setItemRef(el,'theFlagModal')" :flagItem="obj" :flagAction="flagAction" v-model="latestFlag" :readOnly="flagModalReadOnly" :bookmarkContext="bookmarkContext"/>
    </template>
    

</template>

<script>
    import { nextTick, ref, computed, inject, onBeforeUpdate } from 'vue'
    import { useStore } from 'vuex'
    import { getRecordTools } from '../../composables/RecordTools.js'
    import { useItemRefs } from '../../composables/ModelWrapper.js'
    import _ from 'lodash'

    import FlagModal from '../Comment/FlagModal.vue'

    export default {
        name: 'RecordFlags',
        components: { FlagModal },
        emits: ['toggle-flag'],      
        props: {
            'obj': { type: Object },
            'canThumb': { type: Boolean, default: true },
            'bookmarkContext': { type: Object, default: null },
            'recordContext': { type: String, default: 'details' },
            'parentContext': { type: String, default: '' }
        },
        setup(props, { emit }) {
            const store = useStore()
            const { setItemRef, itemRefs, itemRefsReset } = useItemRefs()
            onBeforeUpdate(() => {
                itemRefsReset()
            })
            const emitter = inject("emitter")

            // const
            const bookmarkTypes = ['company', 'acquisition', 'person']
            const activeFlagContexts = ['sidebar', 'pin-card', 'detailspage', 'details', 'table']
            const validFlagActions = ['bellAction', 'flagAction', 'thumbsUp', 'thumbsDown', 'bookmark']
            const bellEditGroups = [2, 10, 9]

            // data
            const flagAction = ref('')
            const latestFlag = ref(null)
            const flagModalReadOnly = ref(false)
            const flagIconOverride = ref({ 'bellAction': null, 'flagAction': null, 'thumbsUp': null, 'thumbsDown': null, 'bookmark': null })

            // computed
            const queryContext = computed(() => props.parentContext == 'search' ? props.bookmarkContext : null)
            const currentUserBookmarkListID = computed(() => store.getters['userInfo/currentUserBookmarkListID'])
            const currentUserID = computed(() => store.getters['userInfo/currentUserID'])
            const currentUser = computed(() => store.getters['userInfo/currentUser'])
            const flags = computed(() => store.getters['flags/flags'])
            const objectTypeStringToIdMap = computed(() => store.getters['search/objectTypeStringToIdMap'])

            const { objectType } = getRecordTools(props)
            const objFlags = computed(() => props.obj && props.obj.users ? props.obj.users : [])           
            const canEditBells = computed(() => currentUser.value && currentUser.value.groups && currentUser.value.groups.filter((g) => { return bellEditGroups.includes(g.id) }))
            const readOnly = computed(() => !activeFlagContexts.includes(props.recordContext))
            const flagStates = computed(() => {               
                var fStates = {
                    bellAction: {
                        showAlways: false,
                        flags: objFlags.value ? descending(objFlags.value.filter((flag) => {
                            return flag.biCategory == flags.value.bellAction.biCategory
                                && (flag.userID == currentUserID.value || canEditBells.value)
                        })) : [],
                        allFlagCount: objFlags.value ? objFlags.value.filter((flag) => { return flag.biCategory == flags.value.bellAction.biCategory }).length : 0,
                        baseIcon: 'bell',
                        description: null
                    },
                    thumbsUp: {
                        showAlways: false,
                        flags: objFlags.value ? descending(objFlags.value.filter((flag) => { return flag.biCategory == flags.value.thumbsUp.biCategory && flag.userID == currentUserID.value && flag.value == flags.value.thumbsUp.value })) : [],
                        allFlagCount: objFlags.value ? objFlags.value.filter((flag) => { return flag.biCategory == flags.value.thumbsUp.biCategory && flag.value == flags.value.thumbsUp.value }).length : 0,
                        baseIcon: 'thumbs-up',
                        description: null
                    },
                    thumbsDown: {
                        showAlways: false,
                        flags: objFlags.value ? descending(objFlags.value.filter((flag) => { return flag.biCategory == flags.value.thumbsDown.biCategory && flag.userID == currentUserID.value && flag.value == flags.value.thumbsDown.value })) : [],
                        allFlagCount: objFlags.value ? objFlags.value.filter((flag) => { return flag.biCategory == flags.value.thumbsDown.biCategory && flag.value == flags.value.thumbsDown.value }).length : 0,
                        baseIcon: 'thumbs-down',
                        description: null
                    },
                    flagAction: {
                        showAlways: false,
                        flags: objFlags.value ? descending(objFlags.value.filter((flag) => { return flag.biCategory == flags.value.flagAction.biCategory && flag.userID == currentUserID.value })) : [],
                        allFlagCount: objFlags.value ? objFlags.value.filter((flag) => { return flag.biCategory == flags.value.flagAction.biCategory }).length : 0,
                        baseIcon: 'flag',
                        description: null
                    },
                    bookmark: {
                        showAlways: bookmarkTypes.includes(objectType.value),
                        flags: objFlags.value ? descending(objFlags.value.filter((flag) => { return flag.biCategory == 2048 && flag.userID == currentUserID.value && flag.listID == currentUserBookmarkListID.value })) : [],
                        allFlagCount: objFlags.value ? objFlags.value.filter((flag) => { return flag.biCategory == 2048 }).length : 0,
                        baseIcon: 'bookmark',
                        description: 'Bookmark'
                    }
                }

                Object.keys(fStates).forEach((name) => {
                    fStates[name].icon = (fStates[name].flags.length ? 'fas' : 'far') + ' fa-' + fStates[name].baseIcon
                    fStates[name].description = fStates[name].flags.length ? fStates[name].flags[0].description : fStates[name].description
                })
                return fStates
            })
            const flagNames = computed(() => flagStates.value ? Object.keys(flagStates.value) : [])

            // methods
            const descending = (inFlags) => {
                return inFlags && inFlags.length ? _.sortBy(inFlags, function (flg) { return flg.date }).reverse() : []
            }
            const toggleFlag = (flagName) => {
                if (validFlagActions.includes(flagName) && itemRefs.value && itemRefs.value.theFlagModal) {
                    flagAction.value = flagName
                    if (flagStates.value[flagName].flags && flagStates.value[flagName].flags.length > 0) {
                        latestFlag.value = flagStates.value[flagName].flags[0]
                        flagModalReadOnly.value = true
                    }
                    else {
                        latestFlag.value = null
                        flagModalReadOnly.value = false
                    }

                    flagIconOverride.value[flagName] = (flagModalReadOnly.value ? 'far' : 'fas') + ' fa-' + flagStates.value[flagName].baseIcon
                    nextTick(() => {
                        itemRefs.value.theFlagModal.showTheModal()
                    })
                }
                else
                    emit('toggle-flag', { 'obj': props.obj, 'flag': flagName })
            }
            const sendEventBus = (eventName, more) => {
                var req = { }
                if (more && _.isObject(more)) {
                    Object.keys(more).forEach((key) => { req[key] = more[key] })
                }
                emitter.emit(eventName, req)
            }
           
            return {
                // inject
                setItemRef, itemRefs, itemRefsReset,
                // const
                bookmarkTypes, activeFlagContexts, validFlagActions, bellEditGroups,
                // data
                flagAction, latestFlag, flagModalReadOnly, flagIconOverride,
                // computed
                currentUserBookmarkListID, currentUserID, currentUser, flags, objectTypeStringToIdMap,
                objectType, objFlags, canEditBells, readOnly, flagStates, flagNames, queryContext,
                // methods
                descending, toggleFlag, sendEventBus
            }
        },       
        mounted: function(){
            var me = this;
            _.each(me.flagIconOverride, function (val, key) {
                // YIKES this Vue 2.0 code
                me.$watch('flagStates.'+key+'.flags.length', function () {
                    me.flagIconOverride[key] = null
                });
            });
        },
    }
    </script>
    <style lang="scss">
     
    </style>
