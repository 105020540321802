<template>
    <modal v-model="model" :title="title" :actionbuttons="true" :saveTitle="'Save'" @save="save">
        <template #body>
            <section class="box options">
                <div class="margin-bottom">
                    <h4>List</h4>
                    <term-picker :termKey="'lists'"
                                 v-model="listIDs"
                                 :valueFieldName="'id'"
                                 :textFieldName="'label'"
                                 :forceSelection="false"
                                 :canTextSelect="false"
                                 :multiple="false"
                                 :inpopup="inpopup"
                                 @update:modelValue="listSelected" />
                    <div v-show="isNewList">A new List will be created</div>
                </div>
                <div class="margin-bottom">
                    <h4>Subset</h4>
                    <term-picker :termKey="'subsets'"
                                 v-model="subsetIDs"
                                 :valueFieldName="'id'"
                                 :textFieldName="'label'"
                                 :forceSelection="false"
                                 :canTextSelect="false"
                                 :multiple="false"
                                 :inpopup="inpopup" />
                    <div v-show="isNewSubset">A new Subset will be created</div>
                </div>
                <div class="margin-bottom">
                    <h4>Options</h4>
                    <fieldset class="checkbox margin-top-small">
                        <input v-model="deepSave" name="deepSave" :id="'saveResults-deepSave'" type="checkbox">
                        <label :for="'saveResults-deepSave'">
                            Find Primary Contacts
                            <span class="ui-hint help" title="Checking this option will add one Primary Contact for every Company saved."></span>
                        </label>
                    </fieldset>
                    <fieldset class="checkbox margin-top-small hide">
                        <input v-model="autoPop" name="autoPop" :id="'saveResults-autoPop'" type="checkbox">
                        <label :for="'saveResults-autoPop'">
                            Autopopulate List Daily
                            <span class="ui-hint help" title="Checking this will re-run the save daily, adding any new results to the list."></span>
                        </label>
                    </fieldset>
                </div>
            </section>
        </template>
        </modal>
</template>

<script>
    import { useStore } from 'vuex'
    import { ref, computed } from 'vue'
    import { useToast, useModelWrapper } from '../../composables/ModelWrapper.js'
    import TermPicker from '../Search/TermPicker.vue'

    export default {
        name: 'SaveToList',
        components: { TermPicker },
        emits: ['save', 'update:modelValue'],
        props: {
            title: { type: String },
            modelValue: { type: Boolean, default: false },
            loading: Boolean,
        },
        setup(props, { emit }) {
            const store = useStore()
            const model = useModelWrapper(props, emit)
            const toast = useToast()

            //data
            const listIDs = ref([])
            const subsetIDs = ref([])
            const deepSave = ref(false)
            const autoPop = ref(false)

            // computed
            const linkedCardDashName = computed(() => {
                if (props.card)
                    return (props.card.ownerID && props.card.ownerName
                        ? 'Card is in the "' + props.card.ownerName + '" dashboard'
                        : 'Card does not belong to any dashboard')
                return null
            })

            const isNewList = computed(() => {
                return listIDs.value && listIDs.value[0] ? !listIDs.value[0].id : false
            })
            const isNewSubset = computed(() => {
                return subsetIDs.value && subsetIDs.value[0] ? !subsetIDs.value[0].id : false
            })

            // methods
            const listSelected = () => {
                var listID = listIDs.value && listIDs.value[0] && listIDs.value[0].id ? listIDs.value[0].id : 0;
                store.dispatch('search/setTermUrl', { termType: 'subsets', url: 'List/subsetSuggestions?listID=' + listID + '&' })
            }
            const save = () => {
                var listID = listIDs.value && listIDs.value[0] && listIDs.value[0].id ? listIDs.value[0].id : 0;
                var listName = listIDs.value && listIDs.value[0] ? (listIDs.value[0].id ? listIDs.value[0].label : listIDs.value[0]) : '';
                var subsetID = subsetIDs.value && subsetIDs.value[0] && subsetIDs.value[0].id ? subsetIDs.value[0].id : 0;
                var subsetName = subsetIDs.value && subsetIDs.value[0] && subsetIDs.value[0] ? (subsetIDs.value[0].id ? subsetIDs.value[0].label : subsetIDs.value[0]) : '';

                if (listID || listName) {
                    var saveOptions = {
                        listID: listID,
                        listName: listName,
                        subsetID: subsetID,
                        subsetName: subsetName,
                        deepSave: deepSave.value,
                        autoPop: autoPop.value
                    };
                    console.log(saveOptions);
                    emit('save', saveOptions);
                    model.value = false
                }
                else
                    toast.add({ severity: 'error', summary: 'No List selected', detail: '', life: 5000 })
            }


            return {
                model, listIDs, subsetIDs, deepSave, autoPop,
                // computed
                linkedCardDashName, 
                // methods
                save, listSelected, isNewList, isNewSubset
            }
        },
        watch: {
            "model": {
                handler(newVal, oldVal) {
                    if (newVal && !oldVal) {
                        this.listIDs = []
                        this.subsetIDs = []
                        this.autoPop = false;
                        this.deepSave = false;
                    }
                }
            },
            "deepSave": {
                handler(newVal) {
                    if (newVal && this.autoPop) {
                        this.autoPop = false;
                    }
                }
            },
            "autoPop": {
                handler(newVal) {
                    if (newVal && this.deepSave) {
                        this.deepSave = false;
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
</style>