<template>
    <div class="search-navigation grow-full">
        <form v-if="navigatorEnabled" class="search-form" v-on:submit.prevent="null">
            <auto-complete v-model="selectedChoice"
                           :ref="(el)=>setItemRef(el,'navi')"
                           :controlID="navigatorID"
                           :suggestions="navigatorResults"
                           @complete="search($event)"
                           field="label"
                           @item-select="choiceSelected"
                           @focus="autocompleteFocused"
                           :completeOnFocus="true" 
                           scrollHeight="500px"
                           :canTextSelect="true"
                           :minLength="0"
                           @textselect="textSelect"
                           class="navigation"
                           panelClass="navigation">
                <template #item="slotProps">
                    <span class="cmdmSearchPerson search-hint" :class="slotProps.item.type == 'HitCount' && slotProps.item.label == '0 Hits' ? 'tt-noresults' : ''">
                        <i class="cmdmSearchIcon fa" :class="(slotProps.item.type == 'company' ? 'fa-university' : (slotProps.item.type == 'person' ? 'fa-user' : '' ))"></i>
                        <span class="label" v-html="slotProps.item.type == 'HitCount' ? slotProps.item.label : highlightLabel(slotProps.item.label)"></span>
                    </span>
                </template>
            </auto-complete>
            <i class="search-icon far fa-search"></i>
        </form>
    </div>
</template>

<script>
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import { ref, computed, nextTick, inject, onBeforeUpdate } from 'vue'
    import { getTextTools } from '../../composables/TextTools.js'
    import { getID } from '../../composables/Editors.js'
    import { useItemRefs } from '../../composables/ModelWrapper.js'

    import AutoComplete from '../PrimeVue/AutoComplete.vue'

    export default {
        name: 'NavigatorBox',
        components: {
            AutoComplete
        },       
        emits: ['selected', 'notmatched', 'textselect'],
        setup(props, { emit, attrs }) {
            // tools
            const store = useStore()
            const router = useRouter()
            const emitter = inject("emitter")   // Inject event bus   
            const { itemRefs, setItemRef, itemRefsReset } = useItemRefs()
            onBeforeUpdate(() => {
                itemRefsReset()
            })

            // data
            const navigatorID = ref(getID('navi'))
            const selectedChoice = ref([])

            // computed
            const isFocused = computed(() => true)
            const userLayout = computed(() => store.state.userInfo.userLayout)
            const navigatorResults = computed(() => isFocused.value ? store.state.search.navigatorResults : [])

            const navigatorEnabled = computed(() => userLayout.value ? userLayout.value.navigatorEnabled : false)
            const isBetaUser = computed(() => userLayout.value ? userLayout.value.iBetaUser > 0 : false)
            const straightToEditMode = computed(() => userLayout.value ? userLayout.value.iStraightToEditMode > 0 : false)
            const hasSelectedListener = computed(() => attrs && attrs.selected)

            // methods
            const { decorateHighlights } = getTextTools()
            const search = (event) => {
                //console.log(event.query)
                store.dispatch('search/navigatorSearch', { 'term': event.query })
            }
            const choiceSelected = (event) => {
               if (hasSelectedListener.value) {
                   emit('selected', selectedChoice.value)
                }
                else if (event.value && event.value.type == 'HitCount') {
                   selectedChoice.value = null
                }
               else if (selectedChoice.value && selectedChoice.value.id) {
                   if (!straightToEditMode.value)
                       router.push({ name: 'DetailsPage', params: { objectType: selectedChoice.value.type, id: selectedChoice.value.id } })
                    else
                       router.push('/' + selectedChoice.value.type + '/edit/' + selectedChoice.value.id)
                }

                //select the choice on the autocompleter
               nextTick(() => {
                   emitter.emit('doAction', { controlID: navigatorID.value, actions: ['focus', 'select'] })
               })
            }
            const textSelect = (term) => {
                emitter.emit("applyFilter", { filter: 'term', filterVal: term })
            }
            const autocompleteFocused = (event) => {
                event.target.select()
            }
            const highlightLabel = (label) => {
                if (itemRefs.value.navi && itemRefs.value.navi.inputValue)
                    return decorateHighlights({
                        body: label,
                        tokens: [itemRefs.value.navi.inputValue],
                        highlightContainer: 'strong',
                        highlightClass: ' '
                    })
                else
                    return label
            }

            return {
                // tools
                emitter, itemRefs, setItemRef, itemRefsReset,
                // data
                navigatorID, selectedChoice,
                // computed
                userLayout, navigatorResults, navigatorEnabled, isBetaUser, straightToEditMode, hasSelectedListener,
                // methods
                decorateHighlights, search, choiceSelected, textSelect, autocompleteFocused, highlightLabel
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
