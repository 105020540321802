<template>
    <modal class="customAlert" v-model="modalOpen" :title="title ?? ' '" @close="handleButton(alertType == 'alert' ? true : false)" :icon="icon" :hasClose="false">
        <template #body>
            <div v-if="htmlMessage" v-html="htmlMessage"></div>
        </template>
        <template #footer>
            <btn v-if="confirmText" @click="handleButton(true)" :class="confirmClass">{{ confirmText }}</btn>
            <btn v-if="cancelText" type="secondary"  @click="handleButton(false)"> {{ cancelText }}</btn>
            <template v-for="(action, idx) in otherActions" :key="idx">
                <btn type="secondary" @click="handleButton(action.res)"> {{ action.text }}</btn>
            </template>
        </template>
    </modal>
</template>

<script>
    import { ref, computed, onMounted } from 'vue'
    import modal from '../../components/ui/modals/Modal.vue'
    import Btn from '../../components/ui/base/Btn.vue'

    export default {
        name: 'NextAlert',
        components: { modal, Btn },
        emits: 'close',
        props: {
            alertType: { type: String, default: 'alert' },
            htmlMessage: { type: String },
            iconType: { type: String },
            title: { type: String, default: ' ' },
            yesText: { type: String, default: 'Yes' },
            noText: { type: String, default: 'No' },
            actions: { type: Array }
        },
        setup(props, { emit }) {

            // data
            const modalOpen = ref(true)

            // computed
            const icon = computed(() => props.icon ?? (
                props.alertType == 'alert' ? 'exclamation' : 'question'))
            const confirmText = computed(() =>
                props.alertType == 'alert' ? 'Ok' : (props.alertType == 'confirm' ? (props.yesText ?? 'Yes') : null))
            const cancelText = computed(() =>
                props.alertType == 'alert' ? null : (props.alertType == 'confirm' ? (props.noText ?? 'No') : null))
            const confirmClass = computed(() => props.alertType == 'alert' ? 'center' : '')
            const otherActions = computed(() => props.actions ?? [])

            // methods            
            const handleButton = (res) => {
                modalOpen.value = false
                emit('close', res)
            }

            // lifecycle
            onMounted(() => {
                modalOpen.value = true
            })

            return {
                // data
                modalOpen,
                // computed
                icon, confirmText, cancelText, otherActions, confirmClass,
                // methods
                handleButton
            }
        }
    }
</script>

<style lang="scss">
    .customAlert {
        z-index: 999 !important;
    }
</style>
