<template>
    <span v-show="!displayNativeInput">
        <currency-input-simple v-model="modelUsd" :options="optionsUsd" :disabled="disabled" :title="noteUsd" :size="inputSize" :class="$attrs.class"  @focus="$emit('focus',$event)" />
    </span>
    <span v-show="currencyID != 1">
        <template v-if="displayNativeInput">
            <currency-input-simple v-model="modelNative" :options="optionsNative" :disabled="disabled" :title="noteNative" :size="inputSize" :class="$attrs.class"  @focus="$emit('focus',$event)"/>
        </template>
    </span>
</template>

<script>

    //import _ from 'lodash'

    import CurrencyInputSimple from './CurrencyInputSimple.vue'
    import { computed, ref } from 'vue'
    import { useStore } from 'vuex'

    export default {
        name: 'CurrencyInput',
        components: { CurrencyInputSimple },
        emits: ['update:modelValue', 'update:nativeValue', 'focus'],
        props: {
            modelValue: Number,
            nativeValue: Number,
            currency: Object,
            scale: { type: Number, default: 0 },
            precision: { type: Number, default: 2 },
            inputSize: { type: Number, default: 0 },
            showNative: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false }
        },
        setup(props, { emit }) {

            const store = useStore()
            const currenciesObject = computed(() => store.getters['financials/currenciesObject'])

            const multiplier = computed(() => Math.pow(10, props.scale))
            const currencyID = computed(() => props.currency && props.currency.id ? props.currency.id : 1)

            const toUSDRate = computed(() => (currencyID.value == 1 ? 1.0 :
                (props.currency && props.currency.toUSDRate ? props.currency.toUSDRate : 1.0)))

            var convertingCurrency = false

            const precisionUsd = ref(props.precision)
            const precisionNative = ref(props.precision)
            const getPrecisionNeeded = (theNumber) => {
                if (!theNumber) return props.precision
                var unScaledNum = theNumber * multiplier.value

                var testNum = unScaledNum
                var sameCount = 0

                while (testNum == unScaledNum) {
                    sameCount++;
                    var factor = Math.pow(10, sameCount);
                    testNum = Math.round(unScaledNum / factor) * factor
                    //console.log('testing', sameCount, factor, unScaledNum, testNum)
                }
                var trailingZeros = sameCount - 1
                //console.log('trailingZeros', trailingZeros)
                var testPrecision = props.scale - trailingZeros
                //console.log('testPrecision', testPrecision)
                return props.precision > testPrecision ? props.precision : testPrecision
            }

            const modelUsd = computed({
                get: () => props.modelValue && props.modelValue != 0.0 ? (props.modelValue / multiplier.value) : null,
                set: (usdVal) => {
                    if (!convertingCurrency) {
                        convertingCurrency = true
                        var scaledModel = props.modelValue && props.modelValue != 0.0 ? (props.modelValue / multiplier.value) : null
                        console.log('updateModelUsd', props.modelValue, scaledModel, usdVal, props.modelNative, toUSDRate.value)
                        if ((usdVal ?? 0.0).toFixed(2) != (scaledModel ?? 0.0).toFixed(2)) {
                            var nativeVal = (usdVal / toUSDRate.value)
                            emit('update:modelValue', usdVal && usdVal != 0.0 ? usdVal * multiplier.value : null)
                            emit('update:nativeValue', nativeVal && nativeVal != 0.0 ? nativeVal * multiplier.value : null)
                            precisionUsd.value = getPrecisionNeeded(usdVal)
                            precisionNative.value = getPrecisionNeeded(nativeVal)
                        }
                        convertingCurrency = false
                    }
                }
            })
            const modelNative = computed({
                get: () => props.nativeValue && props.nativeValue != 0.0 ? (props.nativeValue / multiplier.value) : null,
                set: (nativeVal) => {
                    if (!convertingCurrency) {
                        convertingCurrency = true
                        var scaledModel = props.nativeValue && props.nativeValue != 0.0 ? (props.nativeValue / multiplier.value) : null
                        console.log('updateModelNative', nativeVal, scaledModel, toUSDRate.value)
                        if ((nativeVal ?? 0.0).toFixed(2) != (scaledModel ?? 0.0).toFixed(2)) {
                            var usdVal = (nativeVal * toUSDRate.value)
                            emit('update:nativeValue', nativeVal && nativeVal != 0.0 ? nativeVal * multiplier.value : null)
                            emit('update:modelValue', usdVal && usdVal != 0.0 ? usdVal * multiplier.value : null)
                            precisionUsd.value = getPrecisionNeeded(usdVal)
                            precisionNative.value = getPrecisionNeeded(nativeVal)
                        }
                        convertingCurrency = false
                    }
                }
            })

            return {
                multiplier, modelUsd, modelNative, toUSDRate, convertingCurrency, currenciesObject, precisionUsd, precisionNative, currencyID
            }
        },
        /*watch: {
            "modelValue": {
                handler(newVal) {
                    console.log('usdValChanged', newVal)
                    //nextTick()
                }
            }
        },*/
        computed: {          
            displayNativeInput() {
                return this.showNative && (this.currencyID != 1)
            },            
            currencyISO() {
                if (this.currency && this.currency.isoCode)
                    return this.currency.isoCode
                else if (this.currencyID > 1 && this.currenciesObject[this.currencyID])
                    return this.currenciesObject[this.currencyID].isoCode
                else
                    return 'USD'
            },
            rateDate() {
                return (this.currency && this.currency.rateDate ? this.currency.rateDate : null)
            },
            optionsUsd() {
                return {'currency': 'USD', 'autoDecimalDigits': false, 'precision': this.precisionUsd, 'hideCurrencySymbolOnFocus': false, 'hideGroupingSeparatorOnFocus': false, hideNegligibleDecimalDigitsOnFocus: true }
            },
            optionsNative() {
                return { 'currency': this.currencyISO, 'autoDecimalDigits': false, 'precision': this.precisionNative, 'hideCurrencySymbolOnFocus': false, 'hideGroupingSeparatorOnFocus': false, hideNegligibleDecimalDigitsOnFocus: true }
            },
            noteUsd() {
                return 'USD'
            },
            noteNative() {
                if (this.currency && this.currency.entry)
                    return this.currency.entry
                else if (this.currencyID > 1 && this.currenciesObject[this.currencyID])
                    return this.currenciesObject[this.currencyID].entry
                else
                    return 'Native'
            }
        }
    }
</script>
