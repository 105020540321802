<template>
    <modal v-model="isOpenModel" 
           class="medium" :icon="flagIcon" :saveTitle="saveTitle" :actionbuttons="true" :loading="loading"
           @save="setUnsetFlag" @cancel="resetState" >
        <template #header>
            <template v-if="flagDef && flagItem">
                <tag type="hashtag">{{ flagDef.hashtag }}</tag>
                <h3 class="modal-title margin-top-min grid-12-12">{{ flagItem.name }}</h3>
            </template>
            <template v-else>
                <h3 class="modal-title">Invalid Parameters</h3>
            </template>
        </template>

        <template #body>
            <section class="box">
                <span v-show="hasErrors && errorChecked" class="error-text">{{ hasErrors }}</span>
                <span class="type-note">
                    <span v-if="readOnly" class="user-text margin-right-min">{{ model.userName }}</span>
                    <span>{{ toShortDateString(model.date) }}</span>
                </span>
                <section class="box-body">
                    <input v-if="flagAction=='flagAction'" v-model="model.reminderDate" type="date" :min="luxonDate()" :disabled="readOnly" />
                    <user-select v-if="flagAction=='shareAction'" v-model="selectedUsers" :multiple="true" :inpopup="true" :disabled="readOnly" />
                    <template v-if="readOnly">
                        <span class="type-medium">{{ model.description ? model.description : '' }}</span>
                    </template>
                    <template v-else>
                        <textarea v-model="model.description" class="style-alternate margin-top-small flag-comment" placeholder="Add a note" />
                    </template>
                </section>
            </section>
            <section v-if="clearingBell" class="box options margin-top-small">
                <h5>{{ bellDispositionTag }}</h5>
                <radio-list v-model="bellDisposition" :radioValues="bellDispositions" :labels="bellDispositions" size="xsmall" />
                <input class="style-alt" type="text" v-model="customDisposition" :disabled="bellDisposition != 'Custom'" autocomplete="new-password" />
                {{customDisposition}}
            </section>
        </template>
    </modal>
</template>
<script>
    import { inject, ref, computed, onMounted } from 'vue'
    import { useModelVirtualWrapper, useToast } from '../../composables/ModelWrapper.js'
    import { getTextTools } from '../../composables/TextTools.js'
    import { useStore } from 'vuex'
    import _ from 'lodash'

    import UserSelect from '../baseComponents/UserSelect.vue'
    import RadioList from '../ui/forms/RadioList.vue'
    import Tag from '../ui/tags/Tag.vue'
    
    export default {
        name: 'FlagModal',
        components: {
            UserSelect, RadioList, Tag
        },
        emits: ['update:modelValue', 'flag-changed', 'update:isOpen'],
        props: {
            multiFlag: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false },
            modelValue: { type: Object },
            isOpen: { type: Boolean, default: undefined },
            flagItem: { type: Object },
            flagAction: { type: String, default: 'shareAction' },
            bookmarkContext: { type: Object },
            suppressToast: { type: Boolean, default: false },
        },
        setup(props, { emit }) {
            // tools 
            const store = useStore()
            const toast = useToast()
            const emitter = inject("emitter")   // Inject event bus  
            // const
            const blankFlag = {
                biCategory: 0,
                ownerID: null,
                ownerTypeID: null,
                date: null, userID: 0,
                description: '',
                userName: null,
                value: null,
                targetID: null,
                targetName: null,
                targetTypeID: null,
                reminderDate: null,
                listID: null,
                subsetID: null,
                ownerParentID: null,
                ownerParentTypeID: null
            }
            const bellDispositions = ['Completed', 'CantDo', 'MoreInfo', 'OnHold', 'Custom']

            // model
            const modelVirtual = ref(blankFlag)
            const model = useModelVirtualWrapper(props, emit, 'modelValue', modelVirtual)
            const isOpenVirtual = ref(false)
            const isOpenModel = useModelVirtualWrapper(props, emit, 'isOpen', isOpenVirtual)
           
            // data
            const loading = ref(false)
            const selectedUsers = ref(null)
            const errorChecked = ref(false)
            const bellDisposition = ref('Completed')
            const customDisposition = ref('Custom')

            // computed
            const currentUserBookmarkListID = computed(() => store.getters['userInfo/currentUserBookmarkListID'])
            const currentUserID = computed(() => store.getters['userInfo/currentUserID'])
            const flags = computed(() => store.getters['flags/flags'])
            const objectTypeStringToIdMap = computed(() => store.getters['search/objectTypeStringToIdMap'])

            const flagDef = computed(() => flags.value && props.flagAction && flags.value[props.flagAction] ? flags.value[props.flagAction] : null)
            const bellLabels = computed(() => bellDispositions.map((bd) => { return bd != 'Custom' ? '#' + bd : bd }))
            const bellDispositionTag = computed(() => '#ResearchNotes:' + (bellDisposition.value != 'Custom' ? bellDisposition.value : customDisposition.value))
            const bellDispositionTagObject = computed(() => {
                return { name: '#ResearchNotes', values: [{ value: bellDisposition.value != 'Custom' ? '#' + bellDisposition.value : customDisposition.value }] }
            })
            const modalTitle = computed(() => flagDef.value && props.flagItem ? '#' + flagDef.value.hashtag + (flagDef.value.value ? ':' + flagDef.value.value : '') + ' ' + props.flagItem.name : 'Invalid Params')
            const saveTitle = computed(() => flagDef.value ? (!props.readOnly ? flagDef.value.titleset : 'Clear #' + flagDef.value.hashtag) : 'invalid')
            const flagIcon = computed(() => flagDef.value ? flagDef.value.fa_icon.substr(3) : null)
            const shareTargets = computed(() => selectedUsers.value ? selectedUsers.value.map((u) => ({ id: u.id, name: u.name, objectTypeID: 8 })) : null)
            const clearingBell = computed(() => props.readOnly && props.flagAction == 'bellAction')            
            const hasErrors = computed(() => {
                var errors = null
                switch (props.flagAction) {
                    case 'shareAction':
                        errors = !shareTargets.value ? 'missing place to share' : null
                        break
                    case "thumbsUp":
                    case "thumbsDown":
                    case "bellAction":
                        errors = !model.value.description ? 'missing comment' : null
                        break
                    case "flagAction":
                        errors = !model.value.reminderDate ? 'missing reminder date' : null
                        break
                    case "bookmark":
                        errors = !currentUserBookmarkListID.value ? 'missing list ID' : null
                        break
                    default:
                        errors = 'invalid flag action'
                        break
                }
                return errors
            })

            // methods
            const { luxonDate, toShortDateString } = getTextTools()
            const objectUpdate = (obj) => {
                emitter.emit('objectUpdate', { objectKey: obj.objectKey, newObject: obj })
            }
            const resetState = () => {
                if (props.modelValue)
                    model.value = props.modelValue
                else {
                    var newModel = _.cloneDeep(blankFlag)
                    if (flagDef.value && props.flagItem) {
                        newModel.ownerID = props.flagItem.id
                        newModel.ownerTypeID = objectTypeStringToIdMap.value[props.flagItem.objectType]
                        newModel.ownerParentID = props.flagItem.ownerID
                        newModel.date = new Date()
                        newModel.value = flagDef.value.value
                        newModel.biCategory = flagDef.value.biCategory
                        newModel.reminderDate = luxonDate()
                    }
                    model.value = newModel
                }
                selectedUsers.value = null
                errorChecked.value = false
            }
            const showTheModal = () => {
                resetState()
                if (props.flagAction == 'bookmark')
                    setUnsetFlag()
                else
                    isOpenModel.value = true
            }
            const commentWithTags = () => {
                var comment = model.value.description ? model.value.description : ''
                if (!comment && props.flagAction == 'shareAction')
                    comment = 'Shared with ' + shareTargets.value.map((t) => t.name).join(", ")
                // add tag
                comment += '\r\n ' + modalTitle.value
                if (props.flagAction == 'shareAction')
                    comment += ':' + shareTargets.value.map((t) => '@' + t.name).join(",")

                if (props.flagAction == 'bookmark')
                    comment = 'Bookmarked'

                return comment
            }
            const closeTheModal = () => {
                isOpenModel.value = false
            }
            const flagItemFetch = () => {
                if (props.flagItem && props.flagItem.objectKey) {
                    console.log('flagItemFetch')
                    store.dispatch('objectCache/fetchObject', { objectKey: props.flagItem.objectKey}).then((res) => {
                        console.log('flagItemFetched succeeded')
                        objectUpdate(res.obj)

                    }).catch(errorFetch => {
                        console.log('flagItemFetched failed', errorFetch)
                    })
                }
            }
            const setUnsetFlag = () => {
                if (loading.value && !props.multiFlag) return;

                errorChecked.value = true
                if (props.readOnly || !hasErrors.value) {
                    var flag = _.cloneDeep(model.value)
                    flag.description = commentWithTags()
                    flag.shareTargets = shareTargets.value

                    if (props.flagAction == 'bookmark') {
                        flag.listID = props.bookmarkContext && props.bookmarkContext.listID ? props.bookmarkContext.listID : currentUserBookmarkListID.value
                        flag.targetID = props.bookmarkContext && props.bookmarkContext.targetID ? props.bookmarkContext.targetID : null
                        flag.targetTypeID = props.bookmarkContext && props.bookmarkContext.targetTypeID ? props.bookmarkContext.targetTypeID : null
                        flag.reminderDate = null;
                    }

                    var req = { remove: props.readOnly, flag: flag }

                    if (props.flagAction == 'bellAction') req.commentHTML = JSON.stringify(bellDispositionTagObject.value)

                    if (props.flagAction == 'bookmark') {
                        req.createContext = flag.listID ? false : true;
                        req.updateUserContext = flag.listID ? false : true;
                        req.findFlagByListOverride = flag.targetID && !req.remove ? false : true;
                        //if we are removing the flag just ignore the targetID and remove it via listID
                    }

                    var actionDescription = props.readOnly ? ('Clearing ' + flagDef.value.title) : flagDef.value.titleclear;

                    loading.value = true
                    console.log('setUnsetFlag', flag)
                    store.dispatch('flags/flag', req).then((flag) => {
                        loading.value = false
                        if (!props.suppressToast) 
                            toast.add({ severity: 'success', summary: actionDescription, detail: props.flagItem.objectType, life: 3000 })

                        emit('flag-changed', { removed: props.readOnly, flag: flag, flagAction: props.flagAction })
                        closeTheModal()

                        flagItemFetch()
                        if (props.flagAction == 'bookmark')
                            store.dispatch('bookmarks/fetchBookmarks')

                    }).catch(error => {
                        loading.value = false
                        toast.add({ severity: 'error', summary: 'Error ' + actionDescription, detail: error, life: 5000 })
                    })
                }
            }


            // lifecycle
            onMounted(() => {
                model.value = (props.modelValue ? props.modelValue : _.cloneDeep(blankFlag))
                emit('update:modelValue', model.value)
                loading.value = false
                selectedUsers.value = null
                errorChecked.value = false
                bellDisposition.value = 'Completed'
                customDisposition.value = 'Custom'
            })

            return {
                // models
                modelVirtual, model, isOpenVirtual, isOpenModel,
                // const
                blankFlag, bellDispositions,
                // computed 
                currentUserBookmarkListID, currentUserID, flags, objectTypeStringToIdMap,
                flagDef, bellLabels, bellDispositionTag, bellDispositionTagObject,
                modalTitle, saveTitle, flagIcon, shareTargets, clearingBell, hasErrors,
                // data
                loading, selectedUsers, errorChecked, bellDisposition, customDisposition, 
                // methods
                objectUpdate, resetState, luxonDate, toShortDateString, showTheModal, commentWithTags, flagItemFetch,
                setUnsetFlag
            }
        },
        watch: {
            "isOpen": {
                handler(newVal, oldVal) {
                    if (newVal && !oldVal) {
                        this.resetState();
                        if (this.flagAction == 'bookmark')
                            this.setUnsetFlag()
                        else
                            this.isOpenModel = true
                    }
                }
            }
        }
    }
</script>
<style scoped lang="scss">
    .error-text {
        color: firebrick;
        font-style: italic
    }
    .flag-comment {
        resize: both;
        overflow: auto;
    }
</style>
