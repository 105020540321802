<template>
    <template v-for="(owner,idx) in owners" :key="owner.objectType + '::' + owner.id">
        <a :class="owner.objectType + ' event-label'" :href="getTitleURL(owner)" target="_blank">{{ owner.name }}</a>
        <span v-if="idx < owners.length - 1" class="dot-sep">&nbsp;<i class="fas fa-circle" style="font-size: .50em;">&nbsp;&nbsp;</i> </span>
    </template>
</template>

<script>
    import { computed } from 'vue'
    import { getObjectTools } from '../../composables/ObjectTools.js'
    import { getRecordTools } from '../../composables/RecordTools.js'

    export default {
        name: 'RecordOwners',
        props: {
            'obj': { type: Object },
            'ignoredTypes': { type: Array },
            'includedTypes': { type: Array }
        },
        setup(props) {
            const { objectType, objectIDToObjectTypeMap } = getObjectTools(props)
            const { getTitleURL } = getRecordTools(props)
            const owners = computed(() => {
                var owners = props.obj && props.obj.owners ? props.obj.owners.map((o) => { return { id: o.ownerID, name: o.ownerName, objectType: objectIDToObjectTypeMap.value[o.ownerTypeID], objectTypeID: o.ownerTypeID, active: o.ownerActive } }) : []

                if (props.includedTypes && props.includedTypes.length) {
                    owners = owners.filter((o) => props.includedTypes.includes(o.objectType));
                }

                if (props.ignoredTypes && props.ignoredTypes.length) {
                    owners = owners.filter((o) => !props.ignoredTypes.includes(o.objectType));
                }

                return owners;
            })
            return { objectType, objectIDToObjectTypeMap, owners, getTitleURL }
        }
    }
</script>

<style lang="scss">
</style>