<template>
    <div class="search-filters-toggle" @click="toggle" :class="{ 'is-active': model }">
        <div class="icon-inactive">
            <i class="fas fa-filter"></i>
            <span class="filters-count searchbar-applied-filters-count">{{ appliedFiltersCount }}</span>
        </div>
        <div class="icon-active">
            <i class="fal fa-times"></i>
        </div>
    </div>
</template>

<script>

    import { useStore } from 'vuex'
    import { computed } from 'vue'
    import { useModelWrapper } from '../../composables/ModelWrapper.js'

    export default {
        name: 'SearchFiltersToggle',
        emits: ['update:modelValue'],
        props: {
            modelValue: { type: Boolean, default: false },
            criteria: { type: Object },
            advanced: { type: Boolean, default: false }
        },
        setup(props, { emit }) {
            // tools
            const store = useStore()

            // models
            const model = useModelWrapper(props, emit, 'modelValue')

            // computed
            const filterDefs = computed(() => store.getters['search/filterDefs'])
            const displayMode = computed(() => props.advanced ? 1 : 0)
            const appliedFilters = computed(() => {
                if (filterDefs.value && props.criteria) {
                    var filters = Object.keys(props.criteria).map((crit) => props.criteria[crit])
                    return filters.filter((crit) => {
                        return filterDefs.value[crit.filterName] && filterDefs.value[crit.filterName].displayMode <= displayMode.value
                    })
                }
                return []
            })
            const appliedFiltersCount = computed(() => appliedFilters.value.length)

            // methods
            const toggle = () => {
                model.value = !model.value
            }
            return {
                // models
                model,
                // computed
                filterDefs, displayMode, appliedFilters, appliedFiltersCount,
                // methods
                toggle
            }
        }
    }
</script>

<style scoped lang="scss">
    
</style>
