<template>
    <outer-sidebar ref="outerSidebar" size="small" :title="!fetching ? bookmarkText + ' ' + subsetGroupText : ''"
                   :footer="true"
                   :showNavigator="false"
                   :ignoreClickOutsideClasses="ignoreClickOutsideClasses"
                   @titleclick="titleClick">
        <template #body>
            <div class="fullwidth">
                <template v-if="!loading && !fetching">
                    <ul class="menu-horizontal event-categories cat-filters margin-bottom-min">
                        <li :class="'event-item category ' + (bookmarkView == 'all' ? 'is-on' : 'is-off')" title="All Bookmarks" @click="bookmarkView = 'all'">
                            <span class="event-label">All Bookmarks</span>
                        </li>
                        <li :class="'event-item category ' + (bookmarkView == 'groups' ? 'is-on' : 'is-off')" title="Grouped Bookmarks" @click="bookmarkView = 'groups'">
                            <span class="event-label">Grouped Bookmarks</span>
                        </li>
                        <li v-show="subsetGroups.length > 1" class="place-right">
                            <div class="flex-rows">
                                <div class="place-right flex-rows nowrap no-responsive">
                                    <dropdown ref="sortDropdown" type="icon" icon="fas fa-sort-alt" :useFontAwesome="true" direction="right" :title="'Sort: ' + sortByLabel(sortOptions, sortBy) + (sortBy.asc ? ' (asc)' : ' (desc)')">
                                        <template #header>
                                            <h3>Sort groups by: </h3>
                                        </template>
                                        <template #body>
                                            <sort-menu @sortselected="$refs.sortDropdown.closeTheDropDown()" :options="sortOptions" v-model="sortBy"></sort-menu>
                                        </template>
                                    </dropdown>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <accordion options="panels" size="medium" v-if="subsetGroups.length">
                        <template v-for="(group, qidx) in sortedSubsetGroups" :key="qidx">
                            <accordion-panel icon="list" v-model="openSubsetsModel[group.objectKey]" :title="group.shortName" :optionstitle="group.members.length.toString()">
                                <bookmark-group v-model="selectedItemsModel"
                                                :group="group"
                                                @select="selectItems"
                                                @unselect="unselectItems"
                                                @delete="deleteSelected">
                                </bookmark-group>
                            </accordion-panel>
                        </template>
                    </accordion>

                    <div v-else>
                        No bookmarks found
                    </div>
                </template>
                <spin-loader :showMe="loading || fetching" />
            </div>
        </template>
        <template #footer>
            <template v-if="!fetching">
                <btn type="secondary" @click="$refs.outerSidebar ? $refs.outerSidebar.model = false : null">Cancel</btn>
                <div v-if="selectedItems.length"> {{ selectedItemsText }} </div>
                <menu-item title="Save">
                    <dropdown type="button" direction="up-right" v-model="dropDownState" label="Save...">
                        <template #header>
                            <h3>Save Options:</h3>
                        </template>
                        <template #body>
                            <spin-loader :showMe="loading" />
                            <menu-vertical v-if="!loading" options="panel options medium">
                                <menu-item v-if="selectedItems.length" :label="'Save Selected (' + selectedItems.length + ') to new Card...'" @click="openModal('saveToNewCardModal')" />
                                <menu-item v-if="selectedItems.length" :label="'Save Selected (' + selectedItems.length + ') to existing Card...'" @click="openModal('saveToCardModal')" />
                                <menu-item v-if="bookmarkItems.length" :label="'Share All (' + bookmarkItems.length + ') bookmarks...'" @click="openShareModal()" />
                                <menu-item v-if="selectedItems.length" label="Advanced Options">
                                    <template #children>
                                        <menu-item label="Save to List..." @click="openModal('saveToListModal')"></menu-item>
                                    </template>
                                </menu-item>
                            </menu-vertical>
                        </template>
                    </dropdown>
                </menu-item>
            </template>
        </template>
    </outer-sidebar>
    <flag-modal ref="flagModal" :flagItem="flag.flagItem" :flagAction="flag.flagAction" :readOnly="flag.delete" :bookmarkContext="flag.bookmarkContext" :multiFlag="true" :suppressToast="true"/>
    <dashboard-select-modal v-model="modalState['saveToNewCardModal']" :showCardCreate="true" :showSnapshotCheck="false" :actionLabel="'Save'" :actionToLabel="'to'" title="Save to New Card" @dash-selected="saveSelected"></dashboard-select-modal>
    <dashboard-select-modal v-model="modalState['saveToCardModal']" :showDashCreate="false" :showSnapshotCheck="false" :showCardSelect="true" cardFilter="list" :actionToLabel="''" title="Save to Existing Card" @dash-selected="saveSelected"></dashboard-select-modal>
    <save-to-list-modal v-model="modalState['saveToListModal']" title="Save to List..." @save="saveSelectedToList" />
</template>
<script>
    import OuterSidebar from '../ui/sidebars/OuterSidebar.vue'
    import MenuItem from '../ui/menus/MenuItem.vue'
    import MenuVertical from "../ui/menus/MenuVertical.vue"
    import Accordion from '../ui/accordions/Accordion.vue'
    import FlagModal from '../Comment/FlagModal.vue'
    import SpinLoader from '../baseComponents/SpinLoader.vue'
    import DashboardSelectModal from '../Modals/DashboardSelectModal.vue'
    import BookmarkGroup from './BookmarkGroup.vue'
    import AccordionPanel from '../ui/accordions/AccordionPanel.vue'
    import formatText from '../../mixins/formatText.js'

    import { ref, nextTick, inject } from 'vue'
    import { mapState, mapGetters } from 'vuex'
    import _ from 'lodash'
    import SortMenu from '../Menus/SortMenu.vue'
    import sortHelper from '../../mixins/sortHelper.js'
    import { objectUpdateFunction } from '../../composables/ObjectTools.js'
    import SaveToListModal from '../Modals/SaveToListModal.vue'

    const allSubsetTemplate = {
        id: 'all',
        objectKey: 'subset::all',
        icon: 'list',
        name: "All Bookmarks",
        shortName: "All Bookmarks",
        queries: [],
        ignoreSort: true
    }

    export default {
        name: 'bookmarksSidebar',
        emits: ['open-cart'],
        mixins: [formatText, sortHelper],
        components: {
            MenuItem, MenuVertical, Accordion, FlagModal, SpinLoader, DashboardSelectModal, OuterSidebar, BookmarkGroup, AccordionPanel, SortMenu, SaveToListModal
        },
        props: {
            initialQueryIDs: { type: Array, default: Array.of("all") },
            fetching: { type: Boolean, default: false }
        },
        setup() {
            const nextConfirm = inject('nextConfirm')
            // data
            const bookmarkView = ref('all');
            const ignoreClickOutsideClasses = ['tox', 'modal', 'p-autocomplete-panel']
            const loading = ref(false)
            const modalState = ref({ 'saveToNewCardModal': false, 'saveToCardModal': false, 'saveToListModal': false })
            const dropDownState = ref('closed')

            const flag = ref({
                flagItem: {},
                flagAction: '',
                delete: false,
                bookmarkContext: {}
            })
            const selectedItemsModel = ref({})
            const openSubsetsModel = ref({})

            const bookmarkSortByNew = (group) => {
                if (group.members) {
                    var topMember = _.sortBy(group.members, (d) => new Date(d.date) * -1)[0];
                    if (topMember)
                        return new Date(topMember.date);
                }
                else
                    return new Date()
            }

            const sortOptions = ref([
                { label: 'Type', key: 'default', type: 'Default', ascLabel: '(Searches/Cards first)', descLabel: '(Other first)' },
                { label: 'Name', key: 'name', type: 'String' },
                { label: 'Bookmark Date', key: 'membersDate', type: 'Function', sort: bookmarkSortByNew, ascLabel: '(oldest to recent)', descLabel: '(recent to oldest)' },
                { label: 'Number of Bookmarks', key: 'members', type: 'Length' },
            ])
            const sortBy = ref({ key: 'membersDate', type: 'Function', sort: bookmarkSortByNew, asc: false })

            // methods

            const resetState= () => {
                selectedItemsModel.value = {}
            }
            const { objectUpdate } = objectUpdateFunction()

            return {
                nextConfirm,
                // data
                ignoreClickOutsideClasses, loading, flag, selectedItemsModel, openSubsetsModel, sortOptions, sortBy, bookmarkView, modalState, dropDownState,
                // methods
                objectUpdate, bookmarkSortByNew, resetState
            }
        },
        data: function () {
            return {
            }
        },
        computed: {
            ...mapState({
                listID: state => state.userInfo.user.userInfo.activeFlagListID,
                user: state => state.userInfo.user,
                bookmarks: state => state.bookmarks ? state.bookmarks.bookmarks : { bookmarks: [], subsets: [] },
            }),
            ...mapGetters({
                dashboardGroup: 'userInfo/dashboardGroup',
            }),
            selectedItemsText() {
                return this.selectedItems.length ? (this.selectedItems.length + (!this.isMobile ? ' bookmark' + (this.selectedItems.length == 1 ? '' : 's') : '') + ' selected') : '';
            },
            selectedGroupsText() {
                var me = this;
                var groups = _.filter(this.querySubsetGroups, function (qg) {
                    return _.filter(qg.members, function (item) {
                        return me.selectedItemsModel[item.objectKey]
;                    }).length > 0;
                });

                return groups.length > 0 ? (groups.length + ' group' + (groups.length == 1 ? '' : 's')) : '';
            },
            bookmarkText() {
                return this.bookmarkItems.length + ' bookmark' + (this.bookmarkItems.length == 1 ? '' : 's');
            },
            subsetGroupText() {
                return this.querySubsetGroups.length ? ('(' + this.querySubsetGroups.length + ' search' + (this.querySubsetGroups.length == 1 ? '' : 'es') + ')') : '';
            },
            querySubsetGroups() {
                return _.filter(this.subsetGroups, function (q) { return q.queries.length });
            },
            selectedItems: function () {
                var me = this;
                return _.filter(this.bookmarkItems, function (i) {
                    return me.selectedItemsModel[i.objectKey];
                });
            },
            bookmarkItems: function () {
                var me = this;
                var members = [];

                _.each(me.bookmarks.members, function (m) {
                    var member = _.cloneDeep(m);
                    if (!member.subsets)
                        member.subsets = [];

                    member.shortName = me.ellipseString(member.name, 25, true);
                    member.selected = false;
                    member.type = member.memberKey.split('::')[0];
                    member.objectKey = member.memberKey;

                    members.push(member);
                });

                return members;
                
            },
            sortedSubsetGroups: function () {
                return this.sortItems(this.subsetGroups, this.sortBy);
            },
            subsetGroups: function () {
                return this.subsetViews[this.bookmarkView];
            },
            subsetViews: function () {
                var me = this;
                var subsets = [];
                var allMembers = [];

                //do bookmark groups
                if (me.bookmarks.subsets.length) {

                    _.each(me.bookmarks.subsets, function (s) {
                        //only work with the subsets that have members
                        var members = _.filter(me.bookmarkItems, function (m) { return m.subsets.includes(s.id) });
                        if (members.length) {
                            var subset = _.cloneDeep(s);
                            subset.members = _.cloneDeep(members);

                            //make sure it has a queries field
                            if (!subset.queries) subset.queries = [];

                            //name it "Other" if it's not a subset with queries
                            subset.name = subset.queries.length ? subset.name : 'Other';
                            subset.shortName = me.ellipseString(subset.name, 50, true);

                            //set the query criteria from its first query
                            if (subset.queries.length) {
                                var q = subset.queries[0];
                                subset.queryID = q.id;
                                subset.criteria = q.criteria;
                                subset.icon = 'search';
                            }
                            else {
                                subset.noQueryDescription = "These bookmarks did not originate in a Search."
                                subset.icon = 'question';
                            }

                            //save context for the members array
                           /* _.each(subset.members, function (m) {
                                var member = _.cloneDeep(m);
                                if (subset.queryID) member.queryID = subset.queryID;
                                if (subset.criteria) member.subsetCriteria = subset.criteria;
                                if (subset.noQueryDescription) member.noQueryDescription = "This bookmark did not originate in a Search."

                                allMembers.push(member);
                            });*/
                            subsets.push(subset);
                        }
                    });

                    //sort the subsets by date, with the non query subsets at the bottom
                    subsets = _.sortBy(_.sortBy(subsets, function (q) { me.bookmarkSortByNew(q) * -1 }), function (q) { return !q.queries.length ? 1 : -1 });
                }


                //do the all group
                var allSubset = _.cloneDeep(allSubsetTemplate)
                allSubset.members = allMembers.length ? allMembers : _.cloneDeep(this.bookmarkItems);


                return { 'all': [allSubset], 'groups': subsets }
            },
            dashboard() {
                if (this.dashboardGroup && this.dashboardGroup.dashboards) {
                    var id = this.$route.params.id;
                    var dashboard = _.find(this.dashboardGroup.dashboards, function (d) { return d.id == id });
                    if (dashboard)
                        return dashboard;
                    else
                        return this.dashboardGroup.dashboards[0]
                }
                else
                    return null;
            }
        },
        methods: {
            openShareModal: async function () {
                this.flag = {
                    flagItem: { name: 'Bookmarks', id: this.listID, objectType: 'list' },
                    flagAction: 'shareAction',
                    delete: false
                };

                await nextTick();

                this.dropDownState = 'closed';
                this.$refs['flagModal'].showTheModal();
            },
            openModal: function (name) {
                this.dropDownState = 'closed'
                this.modalState[name] = true
            },
            titleClick: function () {
                if (this.listID)
                    console.log('go to list page')
            },
            saveSelectedToList: function (opt) {
                var me = this;
                opt.items = _.cloneDeep(me.selectedItems);
                _.each(opt.items, function (i) { i.description = "" });

                me.loading = true;
                me.$store.dispatch('bookmarks/addItemsToList', opt).then((result) => {
                    me.loading = false;
                    me.$toast.add({ severity: 'info', summary: 'Selected Items Added to List' + (result.subsets[0] ? ' and Subset' : ''), life: 3000 })
                    window.open('https://cmdm.berkerynoyes.com/List/Details/' + result.id + (result.subsets[0] ? '?subsetID=' + result.subsets[0].id : ''));

                }).catch(error => {
                    this.loading = false;
                    this.$toast.add({ severity: 'error', summary: 'Error Adding Selected Items to List', detail: error, life: 5000 })
                });
            },
            saveSelected: function (dash) {
                var me = this;
                if (dash) {
                    var optArray = [];
                    _.each(dash.cards, function (card) {
                        var opt = {
                            items: _.cloneDeep(me.selectedItems),
                            cardID: card.id,
                            cardName: card.name,
                            cardDisplayType: card.cardDisplayType,
                            dashboardID: dash.id,
                            dashboardName: dash.name,
                        };
                        _.each(opt.items, function (i) { i.description = "" });
                        optArray.push(opt);
                    });

                    me.loading = true;
                    me.$store.dispatch('bookmarks/addToDashboardCards', optArray).then((result) => {
                        me.loading = false;
                        me.$toast.add({ severity: 'info', summary: 'Selected Items Saved', life: 3000 })

                       window.open("/dashboard/" + result.ownerID, "_blank");

                    }).catch(error => {
                        this.loading = false;
                        this.$toast.add({ severity: 'error', summary: 'Error Saving Selected Items', detail: error, life: 5000 })
                    });
                }
            },
            deleteSelected: function (selItem) {
                var me = this;
                var items = selItem ? [selItem] : _.cloneDeep(me.selectedItems);

                this.nextConfirm('Delete selected bookmarks?', 'You have ' + this.selectedItemsText + (this.selectedGroupsText ? ' selected in ' + this.selectedGroupsText : '') + '.').
                    then(async function (res) {
                    if (res) {
                        me.loading = true;
                        for (var i = 0; i < items.length; i++) {
                            var item = items[i];
                            if (item) {
                                me.flag = {
                                    flagItem: { id: item.id, objectType: item.type, objectKey: item.type + '::' + item.id },
                                    flagAction: 'bookmark',
                                    delete: true
                                    // bookmarkContext: { listID: item.listID, targetID: item.query.id, targetTypeID: 18 }
                                };
                                await nextTick();
                                me.$refs['flagModal'].showTheModal();
                            }
                        }
                    }
                });
            },
            selectItems: function (group) {
                this.selectUnselectItems(group, true);
            },
            unselectItems: function (group) {
                this.selectUnselectItems(group, false);
            },
            selectUnselectItems: function (group, action) {
                var me = this;
                _.each(group.members, function (item) {
                    me.selectedItemsModel[item.objectKey] = action;
                });
            }
        },
        watch: {
            bookmarks() {
                var me = this;
                me.loading = false;

                //open the top one if no other one is open
                if (this.sortedSubsetGroups.length && _.filter(this.sortedSubsetGroups, function (sub) { return me.openSubsetsModel[sub.objectKey] }).length == 0)
                    me.openSubsetsModel[this.sortedSubsetGroups[0].objectKey] = true;
            }
        },
        mounted() {
            var me = this;
            me.dropDownState = 'closed'
            me.modalState = { 'saveToNewCardModal': false, 'saveToCardModal': false, 'saveToListModal': false };

            me.openSubsetsModel = { };
             //go through the subsets, find the queries, open the subset
            _.each(me.bookmarks.subsets, function (s) {
                if (s.queries) {
                    var openQueries = _.filter(s.queries, function (q) { return me.initialQueryIDs.includes(q.id); });
                    if (openQueries.length)
                        me.openSubsetsModel[s.objectKey] = true;
                }
            });

            //always open the all subset
            me.openSubsetsModel[allSubsetTemplate.objectKey] = true;
        }
    }
</script>

    <style scoped lang="scss">
    </style>
