<template>
    <template v-if="filterList && filterList.length > 0">
        <radio-list v-model="radioModel" :radioValues="radioValues" :labels="labels" size="xsmall" />
    </template>
</template>

<script>
    import { computed } from 'vue'
    import { searchFilterProps, searchFilterEmits, searchFilterSetup } from '../../composables/SearchTools.js'

    import RadioList from '../ui/forms/RadioList'

    export default {
        name: 'RadioListFilter',
        components: { RadioList },
        props: {
            ...searchFilterProps()
        },
        emits: [...searchFilterEmits()],
        setup(props, { emit }) {

            const { model, settingValue, filterList } = searchFilterSetup(props, emit)

            // computed
            const radioValues = computed(() => filterList.value ? filterList.value.map((item) => { return item.id }) : [])
            const labels = computed(() => filterList.value ? filterList.value.map((item) => { return item.label }) : [])
            const radioModel = computed({
                get: () => {
                    var selected = filterList.value ? filterList.value.find((item) => { return item.selected }) : null
                    return selected ? selected.id : null
                },
                set: (newVal) => {
                    if (filterList.value && model.value) {
                        settingValue.value = true
                        var selected = filterList.value.find((item) => { return item.id == newVal })
                        if (selected)
                            selected.selected = true
                        model.value.value = selected ? selected.id : null
                        model.value.valueName = selected ? selected.entry : null
                        model.value.hasValue = true
                        emit('applyfilter', model.value)
                        settingValue.value = false
                    }
                }
            })

            return {
                // model
                model,
                // data
                settingValue,
                // computed
                filterList, radioValues, labels,radioModel,
            }
        }
    }
</script>

<style scoped  lang="scss">
    
</style>
