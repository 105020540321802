import _ from 'lodash'
export default {
    methods: {
        sortItems: function (items, sortBy) {
            var indexedItems = _.each(items, function (item, idx) { item.originalIndex = idx; })
            var ignoredItems = _.filter(indexedItems, function (item) { return item.ignoreSort; });

            var sortedItems = _.filter(indexedItems, function (item) { return !item.ignoreSort; });
            switch (sortBy.type) {
                case 'Default':
                    sortedItems = sortBy.asc ? sortedItems : sortedItems.reverse();
                    break;
                case 'Function':
                    if (_.isFunction(sortBy.sort))
                        sortedItems = _.orderBy(sortedItems, (item) => sortBy.sort(item), [(sortBy.asc ? 'asc' : 'desc')]);
                    break;
                case 'Date':
                    sortedItems = _.orderBy(sortedItems, (item) => new Date(item[sortBy.key]), [(sortBy.asc ? 'asc' : 'desc')]);
                    break;
                case 'Length':
                    sortedItems = _.orderBy(sortedItems, (item) => (item[sortBy.key] ? item[sortBy.key].length : 0), [(sortBy.asc ? 'asc' : 'desc')]);
                    break;
                case 'String':
                    sortedItems = _.orderBy(sortedItems, (item) => item[sortBy.key].toLowerCase(), [(sortBy.asc ? 'asc' : 'desc')]);
                    break;
                default:
                    sortedItems = _.orderBy(sortedItems, (item) => item[sortBy.key], [(sortBy.asc ? 'asc' : 'desc')]);
            }

            _.each(ignoredItems, function (item) {
                sortedItems.splice(item.originalIndex, 0, item);
            });

            return sortedItems;
        },
        sortByLabel: function (sortOptions, sortBy) {
            var sort = _.find(sortOptions, function (sort) { return sort.key == sortBy.key });
            return sort ? sort.label : '';
        },
    }
}