const authCookie = 'cmdmCoreVue'

export default {
    isAuthenticated: function () {
       // console.log(authCookie, this.getCookieValue(authCookie))
        return this.getCookieValue(authCookie) != null
    },
    getCookieValue: function(cookieName) {
        if (document.cookie) {
            var cookieElement = document.cookie.split('; ').find(row => row.startsWith(cookieName + '='))
            if (cookieElement) {
                return cookieElement.split('=')[1]
            }
        }
        return null
    }
}
