<template>
    <div class="dashItemInfo clickable-card" :class="listIndex == 0 ? 'first-query-item' :''"  @click="detailRequested">
        <div class="grid-item icon-logo" :class="eventIconClass">
            <record-image-simple :obj="obj" />
        </div>
    </div>
</template>

<script>
    import { computed } from 'vue'
    import { getRecordTools } from '../../composables/RecordTools.js'
    import RecordImageSimple from './RecordImageSimple.vue'

    export default {
        name: 'RecordImage',
        props: {
            'obj': { type: Object },
            'listIndex': { type: Number }
        },
        components: {
            RecordImageSimple
        },
        emits: ['detailrequest'],
        setup(props, { emit }) {

            const { objectType, recordTitle, eventType } = getRecordTools(props)
            const eventIconClass = computed(() => 'icon-' + eventType.value)
            const detailRequested = () => {
                emit('detailrequest', { 'obj': props.obj, 'listIndex': props.listIndex })
            }

            return {
                objectType, recordTitle, eventType, eventIconClass,
                detailRequested
            }
        }
    }
</script>

<style scoped lang="scss">
    
</style>