import { createWebHistory, createRouter } from "vue-router"
import AuthHelper from '../AuthHelper.js'

import Welcome from "@/components/Welcome.vue"
import NotFound from "@/components/NotFound.vue"
import Dashboard from "@/components/Dashboards/Dashboard.vue"
import DetailsPage from "@/components/DetailsPage/DetailsPage.vue"
import Search from "@/components/Search/Search.vue"

import Guide from "@/components/ui/guides/Guide.vue"
import SearchGuide from "@/components/ui/guides/SearchGuide.vue"
import TreeGuide from "@/components/ui/guides/TreeGuide.vue"
import FinancialsGuide from "@/components/ui/guides/FinancialsGuide.vue"
import BookmarksGuide from "@/components/ui/guides/BookmarksGuide.vue"
import ChartGuide from "@/components/ui/guides/ChartGuide.vue"

const commentOwnerTypes = { 1: 'company', 2: 'acquisition', 3: 'person' }
const commentOwnerTypeIDs = [1, 2, 3]
var homeCount = 0

const routes = [
    {
        path: '/',
        alias: '/Home/Index',
        name: 'home',
        redirect: to => {
            console.log(to, '#c' + homeCount++)
            return '/dashboard/home';
        }
    },
    {
        path: '/vue/login',
        name: "login",
        beforeEnter(to) {
            var url = "/Account/login/"
            if (to.query) {
                url += '?' + Object.keys(to.query).map((key) => { return key + '=' + to.query[key] }).join("&")
            }
            window.location.href = url
        }
    },
    {
        path: "/Welcome",
        name: "Welcome",
        component: Welcome
    },
    {
        path: "/dashboard/:id",
        name: "Dashboard",
        component: Dashboard,
        props: (route) => {
            const id = Number.parseInt(route.params.id, 10)
            if (Number.isNaN(id)) {
                return 0
            }
            return { id }
        }
    },
    {
        path: "/:objectType/detailspage/:id",
        alias: "/:objectType/details/:id",
        name: "DetailsPage",
        component: DetailsPage,
        beforeEnter(to) {
            if (to && to.params && to.params.objectType && to.params.id) {
                if (!['company', 'acquisition', 'person'].includes(to.params.objectType.toLowerCase()))
                    window.location.href = 'https://cmdm.berkerynoyes.com/' + to.params.objectType + "/details/" + to.params.id;
            }
        },
        props: (route) => {
            const id = Number.parseInt(route.params.id, 10)
            if (Number.isNaN(id)) {
                return 0
            }

            const objectType = route.params.objectType ? route.params.objectType.toLowerCase() : 'company'
            const editMode = !(!route.query.editMode)
            const commentID = Number.isNaN(Number.parseInt(route.query.commentID)) ? 0 : Number.parseInt(route.query.commentID)
            const personID = Number.isNaN(Number.parseInt(route.query.personID)) ? 0 : Number.parseInt(route.query.personID)

            return { objectType, id, editMode, commentID, personID }
        }
    },
    {
        path: "/:objectType/edit/:id",
        name: "EditPage",
        beforeEnter(to) {
            if (to && to.params && to.params.objectType && to.params.id)
                window.location.href = 'https://cmdm.berkerynoyes.com/' + to.params.objectType + "/edit/" + to.params.id;
        },
    },
    {
        path: "/comment/create",
        name: "CommentCreate",
        component: DetailsPage,
        props: (route) => {
            const id = Number.parseInt(route.query.OwnerID, 10)
            if (Number.isNaN(id)) {
                return 0
            }

            const objectType = commentOwnerTypeIDs.includes(route.query.OwnerTypeID) ? commentOwnerTypes[route.query.OwnerTypeID].toLowerCase() : 'company'
            const editMode = true

            return { objectType, id, editMode }
        }
    },
    {
        path: "/Search/vue",
        alias: "/Search",
        name: "Search",
        component: Search,
        props: (route) => {
            const QueryString = (route.query ? Object.keys(route.query).map((key) => {
                return { key: key, value: route.query[key] ? route.query[key] : "" }
            }) : null)

            return { QueryString }
        }
    },
    {
        path: "/Guide",
        name: "Guide",
        component: Guide
    },
    { path: "/Guide/Financials", name: "FinancialsGuide", component: FinancialsGuide },
    { path: '/Guide/Search', name: "SearchGuide", component: SearchGuide },
    { path: '/Guide/Bookmarks', name: "BookmarksGuide", component: BookmarksGuide },
    { path: '/Guide/Chart', name: "ChartGuide", component: ChartGuide },
    {
        path: '/Guide/Lobs', name: "TreeGuide",
        component: TreeGuide,
        props: () => {
            const treeName = 'industry'
            return { treeName }
        }
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        props: (route) => {
            var page = route.fullPath;
            return { page }
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to, from) => {
    if (to.name == 'Welcome') return true

    var isAuthenticated = AuthHelper.isAuthenticated()
    if (isAuthenticated)
        return true
    else {
        console.log(from)
        window.location.href = '/Account/login?returnUrl=' + encodeURIComponent(to.fullPath)
        return true
    }
})



export default router;